import React, { useState, useEffect, useRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

import Loader from '../../Loader'
import { hasPermission } from '../../../../utils'
import responsiveSelect from './ResponsiveSelect'
import Label from './Label'


const ResponsiveCreatableSelect = responsiveSelect(CreatableSelect)

const CreateSelectInput = props => {
  const handleChange = v => {
    const { form, field, multi } = props
    if (v) { // Used for unsetting select
      let vals = []
      if (Array.isArray(v)) {
        if (v.length > 0) { // Array value with values
          v.forEach(i => {
            if (Array.isArray(i.value)) {
              vals.push(i.value[0])
            } else {
              vals.push(i.value)
            }
          })
        } else {
          vals = v
        }
      } else if (multi) {
        vals.push(v.value.trim())
      } else if (v) {
        vals = v.value.trim()
      } else if (v.length) {
        vals = v.trim()
      } else { vals = null }

      form.setFieldValue(field.name, vals).then(() => {
        form.setFieldTouched(field.name)
      })
    } else {
      form.setFieldValue(field.name, null).then(() => {
        form.setFieldTouched(field.name)
      })
    }
  }

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (props.permissions && !hasPermission(props.permissions, props.user.permissions)) { return false }
    if (inputValue.trim().length === 0 || selectOptions.find(option => option.name === inputValue)) { return false }
    return true
  }

  const loadIndicator = () => <Loader inline />

  const { field, form, label, multi,
    noclear, defaultOptions, placeholder, id } = props

  const [ vals, setVals ] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    if (field.value) {
      setVals(multi ? field.value.map(v => ({ value: v, label: v })) : { value: field.value, label: field.value })
    } else {
      setVals(null)
    }
  }, [ field.value ])


  // Generate the current value/s from the cache
  if (!field.name) { return null }
  return (
    <div
      id={id}
      className={`selectinput asyncselectinput form-group ${field.name}`}
      ref={ref}>
      <Label htmlFor={field.name}>{label}</Label>
      <div className="forminput">
        <ResponsiveCreatableSelect
          key={`resp-create-select-${field.name}`}
          className="react-select"
          classNamePrefix="react-select"
          isMulti={multi}
          isClearable={noclear ? false : true}
          options={props.options}
          form={form}
          field={field}
          name={field.name}
          id={field.name}
          defaultOptions={defaultOptions !== false} // Load data by default or not
          onChange={handleChange}
          value={vals}
          placeholder={placeholder}
          cacheOptions
          components={{
            LoadingIndicator: loadIndicator
          }}
          isValidNewOption={isValidNewOption}
          onBlur={e => {
            if (form) {
              form.setFieldTouched(field.name)
              delete form.errors[field.name]
              form.handleBlur(e)
            }
          } } // This is used for error message
        />
        <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
      </div>
    </div>
  )
}

CreateSelectInput.propTypes = {
  form: PropTypes.object.isRequired,
  endpoint: PropTypes.object,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  cache: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  error: PropTypes.object,
  multi: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  noclear: PropTypes.bool,
  params: PropTypes.string,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  labelformat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  labelgrouper: PropTypes.string,
  labelseparator: PropTypes.string,
  permissions: PropTypes.array,
  options: PropTypes.array,
  user: PropTypes.object
}

export default CreateSelectInput
