import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { Button } from '../ui/Button'


const Card = props => {
  const [ open, setOpen ] = useState(!props.collapsed)

  const toggleCard = o => setOpen(!o)

  useEffect(() => {
    if (!open !== props.collapsed) {
      setOpen(!props.collapsed)
    }
    return () => { }
  }, [ props.collapsed ])

  const background = props.background ? ' withbg' : ''
  const openstr = open ? ' open' : ' closed'
  const visible = props.hidden ? ' hidden' : ''
  const defaultBodyClasses = props.collapsable ? 'cardbody withtoggle' : 'cardbody'
  const { classes, style, collapseIcons } = props
  let { body } = props
  if (typeof body === 'function') {
    body = body()
  }
  const closeIcon = getIn(collapseIcons, 'close') ? collapseIcons.close : '#icon24-Minus'
  const openIcon = getIn(collapseIcons, 'open') ? collapseIcons.open : '#icon24-Plus'
  if (!body) { return null }
  return (
    <div id={props.id} className={`card${background}${openstr}${visible}${classes ? ` ${classes}` : ''}`} style={style}>
      {props.header &&
      <div className="cardhead">
        <div className="ccontent">{props.header}</div>
        {props.collapsable &&
          <Button
            icon={`${open ? closeIcon : openIcon}`}
            type="button"
            className="btn btn-none btn-icon-24 btn-icon-only"
            onClick={() => toggleCard(open)}
          />
        }
      </div>
      }
      {props.body && props.collapsable &&
      <div className={defaultBodyClasses}>
        <div className="ccontent">{body}</div>
      </div>
      }
      {props.body && !props.collapsable &&
        <div className={defaultBodyClasses}>
          <div className={classNames('ccontent', props.bodyclass)}>{body}</div>
        </div>
      }
      {props.footer &&
      <div className="cardfoot">
        <div className="ccontent">{props.footer}</div>
      </div>
      }
    </div>
  )
}

Card.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
    PropTypes.node
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
    PropTypes.node
  ]),
  bodyclass: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
    PropTypes.node
  ]),
  classes: PropTypes.string,
  id: PropTypes.string,
  toggle: PropTypes.object,
  style: PropTypes.object,
  background: PropTypes.bool,
  collapsable: PropTypes.bool,
  collapseIcons: PropTypes.object,
  collapsed: PropTypes.bool,
  hidden: PropTypes.bool,
  onCardToggle: PropTypes.func
}

export default Card
