import * as yup from 'yup'

import { decimalPlaces } from '../utils'
import messages from './messages.json'


yup.addMethod(yup.mixed, 'float', function (min, max) { // Make sure we get 1-digit floats, or NaN
  // eslint-disable-next-line
  return this.transform(function (value) {
    return (value !== undefined && String(value).trim() !== '') ? Number(value).toFixed(1) : NaN
  }).test(function (value) {
    if (min !== undefined && Number(value) < min) {
      return new yup.ValidationError(`Enter a valid number greater than ${min}`, value, this.path)
    }
    if (max !== undefined && Number(value) > max) {
      return new yup.ValidationError(`Enter a valid number less than ${max}`, value, this.path)
    }
    return true
  })
})

yup.addMethod(yup.mixed, 'step', function (step = 0.1) { // Make sure value is a multiple of 'step' - can be any number
  return this.test(function (value) {
    if (value && isNaN(value)) { return new yup.ValidationError(messages.number, value, this.path) }
    const places = decimalPlaces(step)
    const multiplier = Math.pow(10, places)
    const inv = 1.0 / step
    if (value && ((value * multiplier) % (step * multiplier) !== 0)) {
      const min = (Math.floor(value * inv) / inv).toFixed(1)
      const max = (Math.ceil(value * inv) / inv).toFixed(1)
      return new yup.ValidationError(`Enter a valid number. The two nearest numbers are ${min} and ${max}`, value, this.path)
    }
    return true
  })
})

const isValidStep = yup.mixed().float(0, 30).step(0.5)

const profile = yup.object({
  status: yup.string().oneOf([ 'Active', 'Inactive', 'Non-Compliant', 'No-Consent', null ]).required(messages.required).nullable(),
  contact: yup.number().integer().min(1).nullable().required(messages.required),
  profile_type: yup.string().oneOf([
    'Buyer',
    'Tenant'
  ], messages.invalid).nullable().required(messages.required),
  areas: yup.array().of(yup.number().typeError('Please select a valid option').integer().min(1)).required(messages.required),
  suburbs: yup.array().of(yup.number().typeError('Please select a valid option').integer().min(1)),
  agent: yup.number().typeError('Please select a valid option').integer().min(1).required(messages.required),
  bedrooms: isValidStep.nullable(),
  bathrooms: isValidStep.nullable(),
  garages: isValidStep.nullable(),
  carports: isValidStep.nullable(),
  zoning: yup.string().oneOf([
    'Industrial',
    'Commercial',
    'Retail',
    'Agricultural',
    'Mixed Use',
    null
  ], messages.invalid).nullable(),
  listing_model: yup.string().oneOf([
    'residential',
    'commercial',
    'project'
  ], messages.invalid).nullable().required(messages.required),
  property_types: yup.array().of(yup.string().max(150).oneOf([
    'Airport Hanger',
    'Apartment',
    'Apartment Block',
    'Bar/Pub',
    'Bed & Breakfast',
    'Building',
    'Bungalow',
    'Business',
    'Business Centre',
    'Chalet',
    'Club',
    'Cluster',
    'Co-working Space',
    'Compound',
    'Contract',
    'Detached',
    'Duet',
    'Duplex',
    'Equestrian Property',
    'Factory',
    'Farm',
    'Flat',
    'Freehold',
    'Freestanding',
    'Full Floor',
    'Garden Cottage',
    'Gated Estate',
    'Golf Estate',
    'Guest House',
    'Guesthouse',
    'Half Floor',
    'Hotel',
    'Hotel Room',
    'House',
    'Industrial Yard',
    'Investment',
    'Labour Camp',
    'Leaseback',
    'Lodge',
    'Maisonette',
    'Medical Centre',
    'Medical Suite',
    'Mini Factory',
    'Office',
    'Package Home',
    'Penthouse',
    'Place of Worship',
    'Restaurant',
    'Retail',
    'Retirement Unit',
    'Room',
    'Sectional Title',
    'Semi Detached',
    'Service Station',
    'Serviced Office',
    'Shopping Centre',
    'Showroom',
    'Simplex',
    'Small Holding',
    'Staff Accommodation',
    'Storage Unit',
    'Studio Apartment',
    'Townhouse',
    'Training Facility',
    'Units',
    'Vacant Land',
    'Villa',
    'Warehouse',
    'Workshop',
    null
  ], messages.invalid)).nullable()
})

export const profiles = () => profile
export const profilematches = () => yup.object({
  profile: yup.number().integer().min(1).required(messages.required),
  from: yup.number().integer().required(messages.required),
  selected: yup.array().of(yup.number().integer().min(1)).required(messages.required),
  listing_model: yup.string().oneOf([
    'residential',
    'commercial'
  ], messages.invalid).required(messages.required),
  subject: yup.string().required(messages.required),
  message: yup.string().required(messages.required)
})
