/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { MINUSER, UI, SETTINGS } from '../selectors'
import * as actions from '../actions'
import { withCustomRouter } from '../components/withCustomRouter'
import Home from '../components/Home'


function mapStateToProps(state) {
  return {
    user: MINUSER(state),
    ui: UI(state),
    settings: SETTINGS(state)
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Home)))
