/* eslint-disable new-cap */
import { getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import ModelActions from '../../components/common/ModelActions'
import Step from '../../components/common/Step'
import { withStep } from '../../components/common/StepComponent'
import Card from '../../components/common/Card'
import Detail from '../../components/common/PrintPage'
import log from '../../logging'
import { ADDONS, CACHE, CACHEDMODELID, CONFIGS, MODELALERTS, MODELLEADS, PORTALS, SELECTED, UI, MINUSER, SETTINGS } from '../../selectors'
import { chunkArray, hasAddons, hasPermission, parseURL, uniqueArray, breakpoint } from '../../utils'
import Loader from '../../components/common/Loader'


class PrintPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      note: null,
      scrollTop: null,
      selectedGroup: {},
      currentGroup: {},
      showActions: breakpoint.matches,
      show_note: false,
      offset: 0
    }
    this.toggleActions = this.toggleActions.bind(this)
    this.timers = {}
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleActions)
    this.props.actions.dismissNotice() // Remove any notices on mount
    this.root = document.getElementById('wrapper')
    if (this.props.model && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    }
    const model = this.props.match.params.log ? `syndication${this.props.match.params.log}` : this.props.match.params.model
    new Promise((resolve, reject) => { // Fetch the singleton data
      this.props.actions.fetchOne(model, this.props.modelid, resolve, reject, false, true)
    }).then(() => {
      this.setState({ fetching: false }, () => {
        this.fetchRelated() // Fetch the related data
      })
    }).catch(e => {
      log.error(e)
      this.setState({ fetching: false })
      // There was an error fetching data, take us back to the list view
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    })
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.model || (getIn(prevProps.model, 'id') !== this.props.model.id)) && !this.state.fetching) {
      const model = this.props.match.params.log ? `syndication${this.props.match.params.log}` : this.props.match.params.model
      new Promise((resolve, reject) => {
        this.setState({ fetching: true })
        this.props.actions.fetchOne(model, this.props.modelid, resolve, reject)
      }).then(() => {
        this.setState({ fetching: false })
        this.fetchRelated() // Fetch the related data
        const note_data = [ model, this.props.modelid ]
        this.fetchNotes({
          obj_model__and__obj_id__or: note_data,
          relations__related_model__and__relations__related_id__or: note_data,
          order_by: '-created',
          meta_fields: [ 'files' ]
        })
      }).catch(e => {
        log.error(e)
        this.setState({ fetching: false })
        // There was an error fetching data, take us back to the list view
        const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
        this.props.actions.registerRedirect(redirect)
      })
    }
    if (this.props.model && Object.keys(this.props.cache.settings).length > 1 && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      log.error(this.props.model)
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    }
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      setTimeout(() => {
        this.forceUpdate()
      }, 100)
    }
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + 66 + 64
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  fetchRelated() { // Fetch related model data
    const { model, cache, configs, config } = this.props
    const relatedModels = config.fields.filter(f => f.modelname)
    // The below commented code is for FieldArray related data. This is
    // mostly returned in the meta key in the response so is not necessary
    // at the moment, but could be in future.
    // const faInputs = config.fields.filter(f => f.input === 'FieldArray')
    // faInputs.forEach(fai => { // Fetch related data in FieldArrays too
    //   const arrayFields = fai.fields.filter(f => f.modelname)
    //   arrayFields.forEach(af => { af.faname = fai.name })
    //   relatedModels = relatedModels.concat(arrayFields)
    // })
    for (const a of relatedModels) {
      let fetch = false
      if (!configs[a.modelname]) { continue } // Skip if the config is incorrect
      // if (a.faname) {
      //   if (model[a.faname] && model.meta[a.faname]) {
      //     const fdata = model.meta[a.faname].map(d => d[a.name])
      //   }
      // } else {
      const fname = model[a.relatedField] ? a.relatedField : a.name
      const fdata = model[fname]
      // }
      if (fdata) { // Ensure correct config
        if (cache[a.modelname]) { // Data is already cached for this model
          if (Array.isArray(fdata)) {
            fetch = (fdata.filter(id => !(id in cache[a.modelname])).length > 0) // Field is an array and one of the values is not in cache
          } else if (!(fdata in cache[a.modelname])) { // Related field not in cache
            fetch = true
          }
        } else if (Array.isArray(fdata) && fdata.length) {
          fetch = true
        } else if (!Array.isArray(fdata)) {
          fetch = true
        }
      }

      if (fetch) { // The data is not cached and must be fetched
        if (Array.isArray(fdata) && fdata.length > 1) { // There is more than one to fetch, do search
          const groups = chunkArray(fdata.filter(p => p), 50) // Split array into groups of 50
          const metafields = configs[a.modelname].fields.filter(f => f.metafield)
            .map(m => (m.modelname ? m.modelname : m.name))
          while (groups.length) { // Loop over groups and fetchMany
            let group = groups.pop()
            // eslint-disable-next-line
            new Promise((resolve, reject) => {
              if (group.length && group[0].id) { group = group.map(g => g.id) }
              const params = { id__in: uniqueArray(group), meta_fields: metafields }
              const values = {
                modelname: a.modelname,
                all: true,
                params: { ...params, limit: group.length }
              }
              if (configs[a.modelname] && configs[a.modelname].endpoint.read) {
                return this.props.actions.fetchMany({ values, resolve, reject })
              }
              return model.meta[a.modelname]
            }).catch(e => log.error(e))
          }
        } else if (configs[a.modelname] && configs[a.modelname].endpoint.read) {
          this.props.actions.fetchOne(a.modelname, fdata)
        }
      }
    }
  }

  renderRelated(modelname, relatedField, fieldName, returnarray = false) {
    // Renders related field data from a foreign model by id
    let data = null
    const newdata = []
    const field = this.props.config.fields.find(f => f.name === relatedField)
    if (this.props.cache[modelname]) {
      if (Array.isArray(this.props.model[relatedField])) {
        this.props.model[relatedField].forEach(id => {
          if (this.props.cache[modelname][id]) {
            const cached_data = this.props.cache[modelname][id]
            if (Array.isArray(fieldName) && cached_data) {
              const oneof = fieldName.map(name => {
                if (cached_data[name]) { return cached_data[name] }
                return null
              }).filter(name => name)
              newdata.push(oneof.join(field.labelseparator || ' '))
              if (newdata.length) { data = newdata }
            } else {
              newdata.push(this.props.cache[modelname][id][fieldName])
              if (newdata.length && !returnarray) {
                data = newdata.join(', ')
              } else {
                data = newdata
              }
            }
          }
        })
      } else if (this.props.cache[modelname][this.props.model[relatedField]]) {
        if (Array.isArray(fieldName)) {
          const id = this.props.cache[modelname][this.props.model[relatedField]]
          if (id) {
            fieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { data = newdata }
          }
        } else {
          data = this.props.cache[modelname][this.props.model[relatedField]][fieldName]
        }
      }
    }
    return data
  }

  parseMeta(model, metaField) {
    let newdata = []
    const meta = model.meta[metaField.name]
    if (Array.isArray(metaField.optionlabel)) {
      if (meta) {
        if (Array.isArray(meta)) {
          newdata = meta.map(m => {
            const d = []
            metaField.optionlabel.forEach(name => {
              if (getIn(m, name)) {
                d.push(m[name])
              }
            })
            if (d.length) {
              return d.join(metaField.labelseparator || ' ')
            }
            return null
          })
        } else {
          metaField.optionlabel.forEach(name => {
            if (getIn(meta, name)) {
              newdata.push(meta[name])
            }
          })
          if (newdata.length) {
            newdata = newdata.join(metaField.labelseparator || ' ')
          }
        }
      }
    } else if (Array.isArray(meta)) {
      newdata = meta.map(m => {
        const d = []
        if (getIn(m, metaField.optionlabel)) {
          d.push(m[metaField.optionlabel])
        }
        if (d.length) {
          return d.join(metaField.labelseparator || ' ')
        }
        return null
      }).join(', ')
    } else if (getIn(model.meta, `${metaField.name}.${metaField.optionlabel}`)) {
      newdata = model.meta[metaField.name][metaField.optionlabel]
    }
    return newdata
  }

  // Listings and contacts
  fetchAlerts({ params = {}, resolve, reject }) {
    return this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'alerts',
        params
      },
      resolve,
      reject
    })
  }

  fetchRecentMatches({ params = {}, resolve = null, reject = null }) {
    return this.props.actions.fetchMatches({
      modelname: this.props.config.modelname,
      suffix: 'recent/',
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchOldMatches({ params = {}, resolve = null, reject = null }) {
    return this.props.actions.fetchMatches({
      modelname: this.props.config.modelname,
      suffix: 'old/',
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchMatches({ params, resolve, reject }) {
    return this.props.actions.fetchMatches({
      noloader: true,
      modelname: this.props.config.modelname,
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchHighlights({ params = {}, resolve = null, reject = null }) {
    const values = {
      modelname: this.props.config.modelname,
      id: this.props.modelid,
      resolve,
      reject,
      params
    }
    return this.props.actions.fetchHighlights(values)
  }

  highlightMatch({ match, resolve, reject }) {
    this.props.actions.highlightMatch({
      match,
      modelid: this.props.modelid,
      modelname: this.props.config.modelname,
      resolve,
      reject
    })
  }

  unhighlightMatch({ match, resolve, reject }) {
    this.props.actions.unhighlightMatch({
      match,
      modelid: this.props.modelid,
      modelname: this.props.config.modelname,
      resolve,
      reject
    })
  }

  // Branches / Teams / Agents
  fetchLeadsBreakdown(data = {}) {
    this.props.actions.getLeadsBreakdown(data)
  }

  // Contacts
  fetchProfiles({ params = {}, resolve, reject }) {
    this.props.actions.fetchMany({
      values: {
        noloader: true,
        modelname: 'profiles',
        params: {
          contact: this.props.modelid,
          ...params
        }
      },
      resolve,
      reject
    })
  }

  // Contacts
  fetchProfileMatches(id, resolve = null, reject = null) {
    this.props.actions.fetchProfileMatches(
      id,
      resolve,
      reject
    )
  }

  fetchLeads({ params, resolve, reject }) {
    this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'leads',
        params: {
          ...params
        }
      },
      resolve,
      reject
    })
  }

  fetchListings({ params = {}, resolve, reject }) {
    const promises = [
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'residential',
            params: {
              owners__overlap__or: [ this.props.modelid ],
              tenant__or: this.props.modelid,
              meta_fields: [ 'agent', 'branch' ],
              ...params
            }
          },
          resolve: res,
          reject, rej
        })
      }),
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'commercial',
            params: {
              owners__overlap__or: [ this.props.modelid ],
              tenant__or: this.props.modelid,
              meta_fields: [ 'agent' ],
              ...params
            }
          },
          resolve: res,
          reject, rej
        })
      })
    ]
    Promise.allSettled(promises).then(results => {
      let combined = []
      let hasMore = null
      results.forEach(r => {
        if (r.hasMore) {
          hasMore = r.hasMore
        }
        combined = [ ...combined, ...r.value.options ]
      })
      resolve({ options: combined, hasMore })
    })
  }

  fetchSubscribers({ params = {}, resolve, reject }) {
    this.props.actions.fetchMany({
      values: {
        noloader: true,
        modelname: 'subscribers',
        endpoint: {
          read: '/mashup/api/v1/mailing-list'
        },
        searchkey: '',
        params: {
          contact: this.props.modelid,
          ...params
        }
      },
      resolve,
      reject
    })
  }

  // All models
  fetchActivity(params = {}, resolve = null, reject = null) {
    const { modelname, id, ...other_params } = params
    const values = {
      modelname: modelname ? modelname : this.props.config.servicename,
      id: id ? id : this.props.modelid,
      resolve,
      reject,
      params: other_params
    }
    this.props.actions.fetchActivity(values)
  }

  // All models
  fetchFeedLogs({ params = {}, resolve = null, reject = null }) {
    const { modelname, portal, listing_id, ...other_params } = params
    const values = {
      modelname,
      portal,
      listing_id,
      resolve,
      reject,
      params: other_params
    }
    this.props.actions.fetchFeedLogs(values)
  }

  fetchNotes(params = {}) {
    if (this.props.config.modelname !== 'groups') {
      const values = {
        modelname: 'notes',
        modellist: true,
        noloader: true,
        params
      }
      this.props.actions.fetchMany({ values })
    }
  }

  switchComponent(model = false) {
    const s = {}
    if (model) {
      s.component = Detail
    } else {
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model)
      const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
      this.props.actions.registerRedirect({ pathname: redirect, search })
      s.component = () => React.createElement('div', {}, 'Not Found')
    }
    return s
  }

  hasViewPermission() {
    const { user, model, config, addons } = this.props
    if (config.addons) { return hasAddons(config.addons, addons) } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.view.permissions
    if (this.props.user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true }
    const hasViewOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    if (model && hasPermission(hasViewOwnPermissions, this.props.user.permissions)) {
      switch (config.modelname) {
        case 'residential':
        case 'commercial':
        case 'projects':
        case 'holiday':
          if ([ model.agent, model.agent_2, model.agent_3, model.agent_4 ].indexOf(user.agent.id) === -1) {
            return false
          }
          return true
        case 'users':
          if (model.agent !== user.agent.id) { // Agent doesn't match
            return false
          }
          return true
        case 'leads':
          if (model.agent === user.agent.id) { // Agent is lead agent
            return true
          }
          if (!model.agent && hasPermission([ 'leads_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'leads_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) {
            // User is allowed to view associated contacts leads
            return true
          }
          // Agent not lead agent
          return false
        case 'contacts':
          if (!model.introduction_agent && hasPermission([ 'contacts_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.associated_agents &&
            model.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts
            return true
          } else if (model.introduction_agent && model.introduction_agent === user.agent.id) {
            return true
          }
          return false
        case 'subscribers':
          if (
            hasPermission([ 'mailing_list_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts subscribers
            return true
          } else if (model.meta.contact && model.meta.contact.introduction_agent === user.agent.id) {
            return true
          }
          return false
        case 'profiles':
          if (!model.agent && hasPermission([ 'profiles_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'profiles_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts profiles
            return true
          } else if (model.meta.contact && model.meta.contact.introduction_agent !== user.agent.id) {
            return true
          }
          return false
        default:
          return true
      }
    }
    const hasViewAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (hasPermission(hasViewAllPermissions, user.permissions)) { return true }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  render() {
    if (!this.props.settings) { return null }
    if (!this.props.model && !this.state.fetching) { return null }
    if (this.state.fetching) { return (<Loader />) }
    const single = this.switchComponent(this.props.config.modelname)
    const p = { ...this.props }
    p.actions = {
      fetchLeadsBreakdown: this.fetchLeadsBreakdown.bind(this),
      fetchLeads: this.fetchLeads.bind(this),
      fetchProfiles: this.fetchProfiles.bind(this),
      fetchActivity: this.fetchActivity.bind(this),
      fetchFeedLogs: this.fetchFeedLogs.bind(this),
      fetchListings: this.fetchListings.bind(this),
      fetchSubscribers: this.fetchSubscribers.bind(this),
      fetchNotes: this.fetchNotes.bind(this),
      fetchMatches: this.fetchMatches.bind(this),
      fetchRecentMatches: this.fetchRecentMatches.bind(this),
      fetchOldMatches: this.fetchOldMatches.bind(this),
      fetchAlerts: this.fetchAlerts.bind(this),
      fetchHighlights: this.fetchHighlights.bind(this),
      highlightMatch: this.highlightMatch.bind(this),
      unhighlightMatch: this.unhighlightMatch.bind(this),
      fetchProfileMatches: this.fetchProfileMatches.bind(this),
      fetchRelated: this.fetchRelated.bind(this),
      renderRelated: this.renderRelated.bind(this),
      parseMeta: this.parseMeta.bind(this),
      fetchListingHistory: this.props.actions.fetchListingHistory,
      exportData: this.props.actions.exportData,
      notifyUser: this.props.actions.notifyUser,
      createModel: this.props.actions.createModel,
      toggleWideSidebar: this.props.actions.toggleWideSidebar,
      fetchPortalLogs: this.props.actions.fetchPortalLogs,
      sendActivation: this.props.actions.sendActivation, // Resend user activation
      sendReset: this.props.actions.sendReset, // Agent single send reset
      syndicatePortalItem: this.props.actions.syndicatePortalItem,
      fetchOne: this.props.actions.fetchOne, // Used for related data such as notes
      fetchMany: this.props.actions.fetchMany, // User for search stuff like agents in branch
      updateModel: this.props.actions.updateModel, // Used for archiving leads
      alertAgentPropertyLead: this.props.actions.alertAgentPropertyLead, // Allows agent to alert contact manually
      downloadImages: this.props.actions.downloadImages,
      deleteModel: this.props.actions.deleteModel,
      registerRedirect: this.props.actions.registerRedirect,
      createLeadInteraction: this.props.actions.createLeadInteraction,
      updateLeadInteraction: this.props.actions.updateLeadInteraction,
      fetchViewingFeedback: this.props.actions.fetchViewingFeedback,
      fetchTemplateConfig: this.props.actions.fetchTemplateConfig,
      updateTemplatePreview: this.props.actions.updateTemplatePreview
    }
    return (
      <div id="content" className="content">
        <div className="viewhead details">
          <div className="action-bar">
            <ModelActions
              modelname={p.config.modelname}
              singleton={true}
              singular={p.config.singular}
              modelid={p.model.id}
            />
            { p.selected && p.selected.length > 1 && document.querySelector('.stepper') &&
            ReactDOM.createPortal(
              <Card
                background
                body={
                  <Step
                    stepPage={this.props.steps.stepPage}
                    next={this.props.steps.next}
                    previous={this.props.steps.previous}
                    selected={this.props.steps.selected}
                    modelid={this.props.model.id}
                  />
                }
              />,
              document.querySelector('.stepper')
            )
            }
          </div>
        </div>
        <div className={`view details ${p.config.modelname}`}>
          <div className="viewcontent">
            <single.component {...p} currentGroup={this.state.currentGroup} />
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  let model = false
  const modelname = ownProps.match.params.log ? `syndication${ownProps.match.params.log}` : ownProps.match.params.model
  let leads = Map({})
  let alerts = Map({})

  const configs = CONFIGS(state)
  const cache = CACHE(state)
  const selected = SELECTED(state, modelname)
  const ui = UI(state)
  const user = MINUSER(state)
  const portals = PORTALS(state)
  const addons = ADDONS(state)


  const minuser = Map({
    id: user.get('id'),
    agent: user.get('agent'),
    selected: user.get('selected'),
    permissions: user.get('permissions')
  })

  if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
    leads = MODELLEADS(state)
    alerts = MODELALERTS(state)
  }
  if ([ 'profiles', 'contacts' ].includes(modelname)) {
    alerts = MODELALERTS(state)
  }
  const tab = ownProps.match.params.tab || 'toolbox'
  const profiletype = ownProps.match.params.profiletype || 'buyer'
  if (modelname && cache) {
    model = CACHEDMODELID(state, modelname, ownProps.match.params.id)
  }

  const settings = SETTINGS(state)

  return {
    modelid: parseInt(ownProps.match.params.id, 10),
    selected,
    model,
    leads,
    alerts,
    tab,
    profiletype,
    config: configs.get(modelname),
    configs: configs,
    cache,
    user: minuser,
    ui,
    portals,
    settings,
    addons
  }
}

PrintPage.propTypes = {
  routing: PropTypes.object,
  ui: PropTypes.object,
  modelid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  match: PropTypes.object,
  leads: PropTypes.object,
  tab: PropTypes.string,
  profiletype: PropTypes.string,
  configs: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  portals: PropTypes.object,
  steps: PropTypes.object,
  settings: PropTypes.object,
  routeConfig: PropTypes.object,
  addons: PropTypes.array
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, actions, match } = ownProps
  return ({ ...stateProps, routeConfig, actions, match })
}

export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(withStep(PrintPage)))
