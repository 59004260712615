import Dexie from 'dexie'

import packageinfo from '../package.json'


function getAllWords(text) {
  const allWordsIncludingDups = text.split(' ')
  const wordSet = allWordsIncludingDups.reduce((prev, current) => {
    prev[current] = true
    return prev
  }, {})
  return Object.keys(wordSet)
}

// if (process.env.REACT_APP_TEST === 'unit' || process.env.REACT_APP_TEST === 'e2e' ) {
//   Dexie.dependencies.indexedDB = indexedDB
//   Dexie.dependencies.IDBKeyRange = IDBKeyRange
// }

const db = new Dexie('Locations')

db.version(packageinfo.idbversion).stores({
  suburbs: 'id, suburb, area, [area+suburb], area_suburb, *area_words, *suburb_words',
  areas: 'id, area, *area_words',
  provinces: 'id, province, *province_words',
  countries: 'id, country, *country_words'
})
db.open().catch(e => {
  console.error(`Open failed: ${e.stack}`)
})

// Add hooks that will index location words
db.suburbs.hook('creating', (primKey, obj) => {
  if (typeof obj.suburb === 'string') {obj.suburb_words = getAllWords(obj.suburb)}
})
db.areas.hook('creating', (primKey, obj) => {
  if (typeof obj.area === 'string') {obj.area_words = getAllWords(obj.area)}
})
db.provinces.hook('creating', (primKey, obj) => {
  if (typeof obj.provinces === 'string') {obj.province_words = getAllWords(obj.provinces)}
})
db.countries.hook('creating', (primKey, obj) => {
  if (typeof obj.country === 'string') {obj.country_words = getAllWords(obj.country)}
})


export default db
