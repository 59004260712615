import * as yup from 'yup'
import messages from './messages.json'


export const regions = () => yup.object({
  live: yup.string().oneOf([ 'true', 'false' ], messages.invalid).required(messages.required),
  area: yup.number().typeError('Please select a valid option').integer().positive().required(messages.required),
  suburb: yup.number().typeError('Please select a valid option').integer().positive().nullable(),
  map_x_position: yup.string().nullable(),
  map_y_position: yup.string().nullable(),
  description: yup.string().required(messages.required),
  branches: yup.array().of(yup.number().typeError('Please select a valid option').integer().positive()).nullable(),
  meta_tags: yup.string().nullable(),
  seo_page_title: yup.string().nullable(),
  video_url: yup.string().max(150).nullable(),
  images: yup.array().of(yup.number().integer().positive()).nullable()
})
