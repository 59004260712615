/* eslint-disable no-new */
import classNames from 'classnames'
// import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


class ContactReferrals extends React.Component {
  constructor(props) {
    super(props)
    this.state = { }
  }


  render() {
    const { user, modelid, cache, actions, configs } = this.props
    return (
      <Card
        bodyclass={classNames('no-top-padding')}
        background
        header={
          <>
            <h3>Contact Referrals</h3>
          </>
        }
        body={
          <SimpleTable
            paginated
            config={configs.referrals}
            action={actions.fetchReferrals}
            currency={cache.settings[user.agent.site.id].default_currency}
            params={{
              contact: false,
              created_contact: modelid,
              order_by: '-created',
              meta_fields: [ 'sending_branch', 'recipient_branch' ]
            }}
            parser={data => {
              data.options = data.options.map(d => {
                const referral = { ...d }
                if (referral.recipient_branch === referral.sending_branch) {
                  referral.type = 'Internal'
                } else if (
                  Array.isArray(user.agent.branches) &&
                  user.agent.branches.includes(referral.recipient_branch)
                ) {
                  referral.type = 'Incoming'
                } else if (
                  Array.isArray(user.agent.branches) &&
                  user.agent.branches.includes(referral.sending_branch)
                ) {
                  referral.type = 'Outgoing'
                } else {
                  referral.type = 'External'
                }
                return referral
              })
              return data
            }}
            header={[
              {
                label: 'Date Created',
                name: 'created',
                orderable: true,
                format: 'datetime'
              },
              {
                label: 'Type',
                name: 'type',
                orderable: true
              },
              {
                label: 'Status',
                name: 'status',
                orderable: true
              },
              {
                label: 'From Branch',
                name: 'sending_branch',
                orderable: true,
                modelname: 'branches',
                optionlabel: [ 'name' ],
                link: '/secure/:site/branches/:meta.sending_branch.id'
              },
              {
                label: 'To Branch',
                name: 'recipient_branch',
                orderable: true,
                modelname: 'branches',
                optionlabel: [ 'name' ],
                link: '/secure/:site/branches/:meta.recipient_branch.id'
              },
              {
                label: 'Leads Manager',
                name: 'meta.recipient_branch.meta.branch.leads_manager',
                orderable: true,
                modelname: 'agents',
                optionlabel: [ 'first_name', 'last_name' ],
                link: '/secure/:site/agents/:meta.recipient_branch.meta.branch.leads_manager'
              },
              {
                label: 'Referral Type',
                name: 'referral_type',
                orderable: true
              }
            ]}
            user={user}
            rowActions={(row, data) => (
              <>
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/referrals/${data.id}`} title="View Referral" type="button" />
              </>
            )}
          />
        }
      />
    )
  }
}

ContactReferrals.propTypes = {
  user: PropTypes.object,
  model: PropTypes.object,
  modelid: PropTypes.number,
  config: PropTypes.object,
  configs: PropTypes.object,
  actions: PropTypes.object,
  profileType: PropTypes.string,
  fetchReferrals: PropTypes.func,
  cache: PropTypes.object,
  profiletype: PropTypes.string,
  currency: PropTypes.string
}

export default ContactReferrals
