import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getIn } from 'formik'
import isEqual from 'react-fast-compare'
import Card from '../Card'
import { valueFormat } from '../../../utils'


const LeadLogItem = ({ interaction }) => {
  let category
  if (getIn(interaction, 'meta.communication.communication_type')) {
    category = getIn(interaction, 'meta.communication.communication_type')
  } else if (getIn(interaction, 'meta.viewing')) {
    category = 'Viewing'
  } else if (getIn(interaction, 'meta.offer')) {
    category = 'Offer'
  } else if (getIn(interaction, 'meta.archive')) {
    category = 'Archived'
  } else if (getIn(interaction, 'meta.rental_application')) {
    category = 'Rental Application'
  }
  return (
    <div className="lead-log-item">
      <div className={classNames('lead-log-item-icon', { Contacted: interaction.communication, Viewing: interaction.viewing, Offer: interaction.offer, Archived: interaction.archive, 'Rental-Application': interaction.rental_application })}></div>
      <div className="lead-log-details">
        <h5>{category}</h5>
        {valueFormat('datetime', interaction.created)}
      </div>
    </div>
  )
}

LeadLogItem.propTypes = {
  interaction: PropTypes.object
}


class LeadLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interactions: [],
      has_more: false
    }
    this.fetchAgain = this.fetchAgain.bind(this)
  }

  componentDidMount() {
    const { config, model, actions, lead } = this.props
    if (lead) {
      new Promise((resolve, reject) => actions.fetchViewingFeedback({
        modelname: config.modelname,
        action: 'interactions',
        modelid: model.id,
        params: {
          get_all: 1,
          lead: lead.id,
          order_by: '-created'
        },
        resolve,
        reject
      })).then(r => {
        this.setState({ interactions: r, has_more: !!r.next })
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { actions, config, model, lead } = this.props
    if (prevState.interaction !== this.state.interaction) {
      this.setState({ config: getIn(this.configs, this.state.interaction, {}) })
    }
    if (!isEqual(prevProps.lead, this.props.lead)) {
      this.setState({ interactions: [], has_more: false })
      if (lead) {
        new Promise((resolve, reject) => actions.fetchViewingFeedback({
          modelname: config.modelname,
          action: 'interactions',
          modelid: model.id,
          params: {
            get_all: 1,
            lead: lead.id,
            order_by: '-created'
          },
          resolve,
          reject
        })).then(r => {
          this.setState({ interactions: r, has_more: !!r.next })
        }).catch(e => console.error(e))
      }
    }
  }

  fetchAgain() {
    const { actions, config, model } = this.props
    new Promise((resolve, reject) => actions.fetchViewingFeedback({
      modelname: config.modelname,
      action: 'interactions',
      modelid: model.id,
      params: {
        order_by: '-created'
      },
      resolve,
      reject
    })).then(r => {
      this.setState({ interactions: r.results, has_more: !!r.next })
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
  }

  render() {
    return (
      <Card
        background={true}
        classes="lead-log-details"
        bodyclass="no-top-padding"
        header={<h3>Activity Stream</h3>}
        body={(
          <div className="lead-log-timeline timeline">
            <div className="lead-log-timeline-bar timeline-bar"></div>
            {this.state.interactions.length ? (
              this.state.interactions.map(interaction => <LeadLogItem key={`log-${interaction.id}`} interaction={interaction} />)
            ) : null}
            <div className="lead-log-item">
              <div className={classNames('lead-log-item-icon New')}></div>
              <div className="lead-log-details">
                <h5>New Lead</h5>
                {valueFormat('datetime', this.props.lead.created)}
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

LeadLog.propTypes = {
  interaction: PropTypes.object,
  model: PropTypes.object,
  config: PropTypes.object,
  lead: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object
}

export default LeadLog
