/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { APP, CLEANUSER, UI } from '../selectors'

import { doLogin, selectAgent, loadToke, applyRetoke, registerRedirect } from '../actions'

import Login from '../components/Login'


const mapStateToProps = state => ({
  app: APP(state),
  user: CLEANUSER(state),
  ui: UI(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ doLogin, selectAgent, loadToke, applyRetoke, registerRedirect }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Login))
