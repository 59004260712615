import * as yup from 'yup'

import { decimalPlaces } from '../utils'
import messages from './messages.json'


yup.addMethod(yup.mixed, 'float', function (min, max) { // Make sure we get 1-digit floats, or NaN
  // eslint-disable-next-line
  return this.transform(function (value) {
    return (value !== undefined && String(value).trim() !== '') ? Number(value).toFixed(1) : NaN
  }).test(function (value) {
    if (min !== undefined && Number(value) < min) {
      return new yup.ValidationError(`Enter a valid number greater than ${min}`, value, this.path)
    }
    if (max !== undefined && Number(value) > max) {
      return new yup.ValidationError(`Enter a valid number less than ${max}`, value, this.path)
    }
    return true
  })
})

yup.addMethod(yup.mixed, 'step', function (step = 0.1) { // Make sure value is a multiple of 'step' - can be any number
  return this.test(function (value) {
    if (value && isNaN(value)) { return new yup.ValidationError(messages.number, value, this.path) }
    const places = decimalPlaces(step)
    const multiplier = Math.pow(10, places)
    const inv = 1.0 / step
    if (value && ((value * multiplier) % (step * multiplier) !== 0)) {
      const min = (Math.floor(value * inv) / inv).toFixed(1)
      const max = (Math.ceil(value * inv) / inv).toFixed(1)
      return new yup.ValidationError(`Enter a valid number. The two nearest numbers are ${min} and ${max}`, value, this.path)
    }
    return true
  })
})

// Implement moreThan for date types:
yup.addMethod(yup.date, 'moreThan', function(more, message) {
  return this.test({
    message,
    name: 'moreThan',
    exclusive: true,
    params: { more },
    skipAbsent: true,
    test(value) {
      return value > this.resolve(more)
    }
  })
})

const isValidStep = yup.mixed().float(0, 995.5).step(0.5)


export const holiday = () => yup.object({
  status: yup.string().required(messages.required),
  listing_type: yup.string().required(messages.required),
  branch: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  team: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  agent_2: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_3: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_4: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  owners: yup.array().of(yup.number().typeError(messages.invalid)
    .positive(messages.positive).integer().nullable()).nullable(),
  tenant: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  marketing_heading: yup.string().max(100).nullable(),
  establishment_name: yup.string().min(1).max(100).required(messages.required),
  establishment_notes: yup.string().required(messages.required),
  description: yup.string().min(1).required(messages.required),
  location: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  property_type: yup.string().trim().oneOf([
    'Farm',
    'Apartment',
    'Duplex',
    'Chalet',
    'Flat',
    'Garden Cottage',
    'House',
    'Simplex',
    'Townhouse',
    'Villa'
  ], messages.invalid).required(messages.required),
  unit_number: yup.string().trim()
    .when('property_type', (property_type, schema) => ([
      'Apartment',
      'Flat',
      'Townhouse'
    ].includes(property_type) ? yup.string().required(messages.required) : schema.nullable())),
  complex_name: yup.string().trim()
    .when('property_type', (property_type, schema) => ([
      'Apartment',
      'Flat',
      'Townhouse'
    ].includes(property_type) ? yup.string().required(messages.required) : schema.nullable())),
  building_name: yup.string().max(60).nullable(),
  street_number: yup.string().max(10).nullable().required(messages.required),
  street_name: yup.string().max(100).nullable().required(messages.required),
  lightstone_id: yup.number().typeError(messages.invalid).integer().positive()
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  matterport_id: yup.string().max(100).nullable(),
  virtual_tour: yup.string().url(messages.url).max(200).nullable(),
  external_link_name: yup.string().max(20).nullable(),
  external_link_url: yup.string().url(messages.url).max(200).nullable(),
  bedrooms: isValidStep.required(messages.required),
  sleeps: isValidStep.required(messages.required),
  king_beds: isValidStep.required(messages.required),
  queen_beds: isValidStep.required(messages.required),
  double_beds: isValidStep.required(messages.required),
  bunk_beds: isValidStep.required(messages.required),
  single_beds: isValidStep.required(messages.required),
  bathrooms: isValidStep.required(messages.required),
  baths: yup.number().typeError(messages.invalid).integer().min(0).max(20).required(messages.required),
  showers: yup.number().typeError(messages.invalid).integer().max(20).required(messages.required),
  hand_showers: yup.number().typeError(messages.invalid).integer().max(20).required(messages.required),
  lounge: isValidStep.required(messages.required),
  dining_room: isValidStep.required(messages.required),
  garden: yup.string().trim().oneOf([
    'Private',
    'Communal',
    'No'
  ], messages.invalid),
  braai: yup.string().trim().oneOf([
    'Both Gas and Charcoal',
    'Built-In',
    'Charcoal - Built-in',
    'Charcoal - Portable',
    'Communal',
    'Gas - Built-in',
    'Gas - Portable',
    'None',
    'Portable',
    'Private'
  ], messages.invalid),
  pool: yup.string().trim().oneOf([
    'Communal',
    'None',
    'Pool with net',
    'Private',
    'Splash pool',
    'Splash pool with net'
  ], messages.invalid),
  jacuzzi: yup.string().trim().oneOf([
    'Private',
    'Communal',
    'No'
  ], messages.invalid),
  undercover_parking_bays: yup.number()
    .typeError(messages.invalid).integer().min(0).max(20).required(messages.required),
  open_parking_bays: yup.number().typeError(messages.invalid).integer().min(0).max(20).required(messages.required),
  garages: yup.number().typeError(messages.invalid).integer().min(0).max(20).required(messages.required),
  peak_season: yup.number().typeError(messages.invalid).moreThan(0, messages.invalid).required(messages.required),
  semi_season: yup.number().typeError(messages.invalid).moreThan(0, messages.invalid).required(messages.required),
  low_season: yup.number().typeError(messages.invalid).moreThan(0, messages.invalid).required(messages.required),
  out_of_season: yup.number().typeError(messages.invalid).moreThan(0, messages.invalid).required(messages.required),
  corporate_rate: yup.number()
    .typeError(messages.invalid).moreThan(0, messages.invalid).transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  deposit: yup.number()
    .typeError(messages.invalid).moreThan(0, messages.invalid).transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  floor: yup.string().trim().oneOf([
    'N/A',
    'Single Storey',
    'Double Storey',
    'Triple Storey',
    'Ground Floor',
    '1st Floor',
    '2nd Floor',
    '3rd Floor',
    '4th Floor',
    '5th Floor',
    '6th Floor',
    '7th Floor',
    '8th Floor',
    '9th Floor',
    '10th Floor',
    '11th Floor',
    '12th Floor',
    '13th Floor',
    '14th Floor',
    '15th Floor',
    '16th Floor',
    '17th Floor',
    '18th Floor',
    '19th Floor',
    '20th Floor',
    '21st Floor',
    '22nd Floor',
    '23rd Floor',
    '24th Floor',
    '25th Floor',
    '26th Floor',
    '27th Floor',
    '28th Floor',
    '29th Floor',
    '30th Floor',
    '31st Floor',
    '32nd Floor',
    '33rd Floor',
    '34th Floor',
    '35th Floor',
    '36th Floor',
    '37th Floor',
    '38th Floor',
    '39th Floor',
    '40th Floor',
    '41st Floor',
    '42nd Floor',
    '43rd Floor',
    '44th Floor',
    '45th Floor',
    '46th Floor',
    '47th Floor',
    '48th Floor',
    '49th Floor',
    '50th Floor'
  ], messages.invalid).nullable().required(messages.required),
  sea_views: yup.string().trim().oneOf([
    'None',
    'Partial',
    'Side View',
    'Full Frontal',
    'Breaker Sea View',
    'Distant',
    'Good',
    'Full Frontal Sea View',
    'Distant Sea View',
    'Partial Sea View',
    'Forest View',
    'Mountain View',
    'Inland View',
    'Golf Course View',
    'View over Zimbali',
    'Sea and Forest Views',
    'Golf Course and Sea Views',
    'Driving Range Views'
  ], messages.invalid),
  cleaning_service: yup.string().trim().oneOf([
    'Monday - Friday',
    'Monday - Saturday',
    'Once a Day',
    'Once a Week',
    'Twice a Week',
    'Three times a Week',
    'On Request',
    'Not Serviced'
  ], messages.invalid),
  special_offer_start_date: yup.date().typeError(messages.invalid).when('special_offer', {
    is: true,
    then: yup.date().typeError(messages.invalid).required(messages.required),
    otherwise: yup.date().nullable()
  }),
  special_offer_end_date: yup.date().typeError(messages.invalid).when('special_offer', {
    is: true,
    then: yup.date().typeError(messages.invalid).required(messages.required).moreThan(yup.ref('special_offer_start_date'), 'Start date must be before end date.'),
    otherwise: yup.date().nullable()
  })
}).test(
  'display_on_website', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      listing_images,
      display_on_website
    } = schema
    if (display_on_website) {
      if (!listing_images || (listing_images && !listing_images.length)) {
        return new yup.ValidationError('Listings require at least one image to display on websites.', display_on_website, 'display_on_website')
      }
    }
    return true
  }
)

