/* eslint-disable new-cap */
import classNames from 'classnames'
import merge from 'deepmerge'
import { Field, Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { withCustomRouter } from '../../../withCustomRouter'
import tagconfig from '../../../../config/tag.json'
import log from '../../../../logging'
import { CACHE, PERMISSIONS, SETTINGS, SITE, MINUSER, UI, CONFIGS } from '../../../../selectors'
import { handleSubmitError, parseURL, updateSearchParms, breakpoint } from '../../../../utils'
import { Button } from '../../../ui/Button'
import ChallengeModal from '../../modals/ChallengeModal'
import CustomForm from '../CustomForm'
import SimpleTable from '../../simpletable/SimpleTable'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import { Scrollbar } from '../../../ui/Scrollbars'
import PageJump from '../../datatable/PageJump'
import Step from '../../Step'
import AdvancedSearch from '../../search/AdvancedSearch'
import QueryBuilder from '../../QueryBuilder'
import AsyncSelect from './AsyncSelect'
import InlineSelect from './InlineSelect'
import Select from './Select'
import TextInput from './Text'


class TagManager extends React.Component {
  constructor(props) {
    super(props)
    const can_add_user = props.user.permissions.includes('tags_own_add') || props.user.permissions.includes('is_prop_data_user')
    const can_add_branch = props.user.permissions.includes('tags_branch_add') || props.user.permissions.includes('is_prop_data_user')
    const can_add_agency = props.user.permissions.includes('tags_agency_add') || props.user.permissions.includes('is_prop_data_user')
    const can_add = [ can_add_user, can_add_branch, can_add_agency ].some(a => a)
    let level = 'User'
    if (can_add_branch) {
      level = 'Branch'
    }
    if (can_add_agency) {
      level = 'Agency'
    }
    const qs = new QueryBuilder(props.location.search)
    const params = qs.getAllArgs(false)
    this.state = {
      searching: false,
      desktop: breakpoint.matches,
      tags: props.cache.tags ? Object.keys(props.cache.tags).map(k => props.cache.tags[k]) : [],
      fields: tagconfig.config.fields,
      label: undefined,
      level,
      errors: {},
      touched: {},
      can_add_user,
      can_add_branch,
      can_add_agency,
      can_add,
      delete: false,
      count: 0,
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0),
      term: getIn(qs, 'params.term', ''),
      input: getIn(qs, 'params.term', ''),
      params
    }
    this.isEnter = this.isEnter.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.setFieldValue = this.setFieldValue.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.fetchBranches = this.fetchBranches.bind(this)
    this.fetchAgents = this.fetchAgents.bind(this)
    this.updateTag = this.updateTag.bind(this)
    this.deleteTag = this.deleteTag.bind(this)
    this.createTag = this.createTag.bind(this)
    this.stepPage = this.stepPage.bind(this)
    this.changelimit = this.changelimit.bind(this)
    this.toggleDesktop = this.toggleDesktop.bind(this)
    this.toggleAdvanced = this.toggleAdvanced.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount() {
    const can_add_user = this.props.user.permissions.includes('tags_own_add') || this.props.user.permissions.includes('is_prop_data_user')
    const can_add_branch = this.props.user.permissions.includes('tags_branch_add') || this.props.user.permissions.includes('is_prop_data_user')
    const can_add_agency = this.props.user.permissions.includes('tags_agency_add') || this.props.user.permissions.includes('is_prop_data_user')
    const can_add = [ can_add_user, can_add_branch, can_add_agency ].some(a => a)
    this.setState({ can_add, can_add_agency, can_add_branch, can_add_user })

    breakpoint.addEventListener('change', this.toggleDesktop)
    const qs = new QueryBuilder(this.props.location.search)
    if (!isEqual({
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0)
    }, {
      limit: this.state.limit,
      offset: this.state.offset
    })) {
      this.setState({
        limit: getIn(qs, 'params.limit', 20),
        offset: getIn(qs, 'params.offset', 0)
      })
    }
    this.root = document.getElementById('wrapper')
  }

  componentDidUpdate(prevProps, prevState) {
    const qs = new QueryBuilder(this.props.location.search)
    if (this.props.location?.search !== prevProps.location.search) {
      this.setState({
        params: qs.getAllArgs(false)
      })
    }

    if (this.state.editing && !isEqual(this.state.editing, prevState.editing)) {
      this.props.toggleWideSidebar('show-tag-manager')
    }
    if (!isEqual({
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0)
    }, {
      limit: this.state.limit,
      offset: this.state.offset
    })) {
      this.setState({
        limit: getIn(qs, 'params.limit', 20),
        offset: getIn(qs, 'params.offset', 0)
      })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleDesktop)
  }

  toggleDesktop(e) {
    if (e.matches && !this.state.desktop) {
      this.setState({ desktop: true, show: false })
    } else if (!e.matches && this.state.desktop) {
      this.setState({ desktop: false })
    }
  }

  changelimit(e) {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
  }

  fetchBranches(branches, resolve, reject) {
    if (branches.length) {
      const values = {
        modelname: 'branches',
        conflicts: true,
        params: {
          get_all: 1,
          id__in: branches
        }
      }
      this.props.fetchMany({ values, resolve, reject })
    } else if (resolve) {
      resolve([])
    }
  }

  fetchAgents(agents, resolve, reject) {
    if (agents.length) {
      const values = {
        modelname: 'agents',
        conflicts: true,
        params: {
          get_all: 1,
          id__in: agents
        }
      }
      this.props.fetchMany({ values, resolve, reject })
    } else if (resolve) {
      resolve([])
    }
  }

  updateTag(tag) {
    const { updateModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'tags',
        ...tag
      }
      updateModel({ values, resolve, reject })
    }).then(() => {
      this.setState({ term: '', searching: true, editing: null })
      this.props.toggleWideSidebar()
      this.tags.fetchData()
    }).catch(e => {
      log.error(e)
    })
  }

  createTag(values, actions) {
    const { createModel } = this.props
    return new Promise((resolve, reject) => {
      values.modelname = 'tags'
      createModel({ values, resolve, reject })
    }).then(() => {
      actions.setSubmitting(false)
      this.setState({ label: '', level: this.state.level, branch_id: undefined, term: '', searching: true })
      this.tags.fetchData()
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  deleteTag(tag, confirmation) {
    const { deleteModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'tags',
        selected: [ tag ]
      }
      if (confirmation) {
        return deleteModel({ values, resolve, reject })
      }
      return reject('')
    }).then(() => {
      this.setState({ term: '', searching: true, delete: null })
      this.tags.fetchData()
    }).catch(e => {
      if (e) {
        this.setState({ delete: null })
      }
    })
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.setState({ searching: true, term: this.state.input })
    } // continue typing
    return true
  }

  calculateRecords(response) {
    let from = 0
    let to = 0
    if (response.params) {
      const { offset, limit } = response.params
      const count = Number(response.count)
      from = Number(offset) + 1 || 1
      to = (from - 1 + limit) > count ? count : from - 1 + limit
    }
    return { from, to }
  }

  stepPage(dir) { // Step through result pages
    const { count, params } = this.state.model
    const { limit } = params
    const url = this.state.model[dir]
    let offset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { offset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        offset = Math.floor(count / limit) * limit
        break
      default:
        offset = 0
    }
    updateSearchParms('offset', offset)
  }

  updateInput(e) {
    this.setState({ input: e.target.value })
  }

  setFieldValue(name, value) {
    const state = merge({}, this.state)
    state[name] = value
    this.setState(state)
  }

  toggleAdvanced() {
    this.setState({ advanced: !this.state.advanced })
  }

  handleReset() { // Handles reset of the advanced search form
    const params = {
      ...this.props.config.params,
      term: 0
    }
    this.props.config.advsearch.forEach(group => {
      group.forEach(k => {
        let fname = k.name
        if (k.verb) { fname = `${fname}__${k.verb}` }
        params[fname] = 0
      })
    })
    updateSearchParms(params)
  }

  render() {
    const { user, ui } = this.props
    const branch_field = this.state.fields.find(f => f.name === 'branch_id')
    if (branch_field.extraparams) { // Used for form submission
      branch_field.params = parseURL(
        branch_field.extraparams,
        this.state,
        this.props.user ? this.props.user.agent : false,
        this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false,
        this.props.cache
      )
    }
    const strlimit = this.state.limit.toString(10)
    return (
      <div className="tag-manager">
        {this.state.can_add &&
              <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={this.createTag}
              >{({ values, isSubmitting, handleSubmit }) => (
                  <CustomForm
                    component="div"
                    onChange={() => {
                      this.setState({ ...values })
                    }}
                    render={() => (
                      <div className="tags-form tablesearch">
                        <div className="field col-lg-12 required">
                          <Field
                            id="tags-label"
                            name="label"
                            label="Tag"
                            placeholder="Tag"
                            component={TextInput}
                            className="label"
                            disabled={isSubmitting ? true : false}
                            bounce={true}
                          />
                        </div>
                        {this.state.level === 'Branch' &&
                        <div className="field col-lg-12 required">
                          <Field
                            id="tags-branch"
                            name="branch_id"
                            placeholder="Select a Branch"
                            fetchMany={this.props.fetchMany}
                            cacheDelta={this.props.cacheDelta}
                            label={branch_field.label}
                            modelname={branch_field.modelname}
                            optionlabel={branch_field.optionlabel}
                            cache={this.props.cache.branches}
                            params={branch_field.params}
                            component={AsyncSelect}
                            className="branch_id"
                          />
                        </div>
                        }
                        <div className="field col-lg-12 required">
                          <Field
                            id="tags-level"
                            name="level"
                            label="Level"
                            placeholder="Select a Category"
                            component={Select}
                            defaultvalue="Agency"
                            className="level"
                            options={this.state.fields.find(f => f.name === 'level').options.filter(o => {
                              if (o.value === 'Agency' && this.state.can_add_agency) {
                                return true
                              }
                              if (o.value === 'Branch' && this.state.can_add_branch) {
                                return true
                              }
                              if (o.value === 'User' && this.state.can_add_user) {
                                return true
                              }
                              return false
                            })}
                          />
                        </div>
                        <div className="search-buttons" style={{ paddingRight: 0 }}>
                          <Button
                            id="keyword-search-btn"
                            tabIndex="-1"
                            type="button"
                            icon="#icon24-Plus"
                            onClick={handleSubmit}
                            disabled={this.state.searching}
                            className="btn btn-primary btn-icon-16 btn-icon-left"
                          >
                          Add
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </Formik>
        }
        <h3>Tag List</h3>
        <div className="tags-search">
          {this.state.advanced ? (
            <AdvancedSearch
              key='adv-search-tag'
              customform={true}
              model={{
                params: this.state.params
              }}
              user={this.props.user}
              match={this.props.match}
              config={this.props.config}
              configs={this.props.configs}
              cache={this.props.cache}
              toggleAdvanced={this.toggleAdvanced}
              handleReset={this.handleReset}
              actions={{
                fetchMany: this.props.fetchMany,
                cacheDelta: this.props.cacheDelta
              }}
            />
          ) : (
            <Formik
              initialValues={{ model: 'tag' }}
              enableReinitialize={true}
              onSubmit={values => {
                updateSearchParms({
                  ...this.state.params,
                  term: values.term
                })
              }}
            >{(
                <>
                  <CustomForm
                    component="div"
                    render={() => (
                      <div className="search-fields">
                        <div className="input-group keyword-search">
                          <div className="form-group term">
                            <div className="forminput">
                              <input
                                id="tag-search"
                                ref={el => { this.el = el }}
                                type="search"
                                placeholder="Keyword Search"
                                className="form-control input-group-suffix"
                                onBlur={this.isEnter}
                                onKeyDown={this.isEnter}
                                onChange={this.updateInput}
                                value={this.state.input}
                              />
                              <Button icon="#icon24-Search" type="button" disabled={this.state.searching} onClick={() => this.setState({ searching: true, term: this.state.input })} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
                            </div>
                          </div>
                        </div>
                        {!this.state.term &&
                              <Button
                                id="keyword-search-btn"
                                tabIndex="-1"
                                type="button"
                                onClick={this.toggleAdvanced}
                                disabled={this.state.searching}
                                className="btn btn-subtle"
                              >
                                Advanced Search
                              </Button>
                        }
                        {this.state.searched &&
                              <div className="reset-group">
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={() => {this.setState({ term: '', input: '', searched: false }, () => this.setState({ searching: true }))}}
                                  disabled={this.state.searching}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset Filters
                                </Button>
                              </div>
                        }
                        <div className="page-tools">
                          <PageJump
                            params={{ offset: this.state.offset, limit: this.state.limit, count: this.state.count }}
                            endpoint={getIn(tagconfig.config, 'endpoint')}
                            modelname={getIn(tagconfig.config, 'modelname')}
                            count={getIn(this.state, 'count')}
                          />

                          <div className="page-buttons">
                            <Step
                              stepPage={this.stepPage}
                              next={getIn(this.state, 'next')}
                              previous={getIn(this.state, 'previous')}
                            />

                            {this.state.desktop && <div className="record-count">
                              {getIn(this.state, 'records.to') ? getIn(this.state, 'records.from') : 0} - {getIn(this.state, 'records.to')} of {getIn(this.state, 'count')}
                            </div> }
                            {this.state.desktop && <InlineSelect
                              id="field-limit"
                              name="limit"
                              className="inline-select meta-limit"
                              classNamePrefix="inline"
                              defaultValue={{ value: this.state.limit, label: strlimit }}
                              selectedValue={(getIn(this.state, 'model.params')) ? Number(getIn(this.state, 'model.params.limit')) : ''}
                              autosize={true}
                              options={[
                                { value: 20, label: '20' },
                                { value: 50, label: '50' },
                                { value: 100, label: '100' }
                              ]}
                              onChange={e => this.changelimit(e, getIn(this.props, 'params'))}
                            />}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </>
              )}
            </Formik>
          )}
          <div className="tagsbody">
            <SimpleTable
              user={user}
              config={tagconfig.config}
              action={({ params, resolve }) => {
                new Promise((res1, rej1) => {
                  const values = {
                    modelname: 'tags',
                    modellist: true,
                    term: this.state.term,
                    trigram: true,
                    params: {
                      related: true,
                      ...params,
                      ...this.state.params
                    },
                    delete: null,
                    editing: null
                  }
                  this.props.fetchMany({
                    values,
                    resolve: res1,
                    reject: rej1
                  })
                }).then(data => {
                  this.setState({
                    model: data,
                    next: data.next,
                    previous: data.previous,
                    count: data.count,
                    records: this.calculateRecords(data)
                  })
                  const rows = data.index.map(i => getIn(this.props, `cache.tags.${i}`))
                  const fetches = [
                    new Promise((res2, rej2) => {
                      const branches = rows.filter(o => o.branch_id).map(o => o.branch_id)
                      return this.fetchBranches(branches, res2, rej2)
                    }).then(branch_data => {
                      rows.forEach(d => {
                        if (d.branch_id) {
                          d.meta.branch = branch_data.find(b => b.id === d.branch_id)
                        }
                      })
                      return data
                    }), new Promise((res3, rej3) => {
                      const agents = rows.filter(o => o.agent_id).map(o => o.agent_id)
                      return this.fetchAgents(agents, res3, rej3)
                    }).then(agent_data => {
                      rows.forEach(d => {
                        if (d.agent_id) {
                          d.meta.agent = agent_data.find(b => b.id === d.agent_id)
                        }
                      })
                      return data
                    })
                  ]
                  Promise.allSettled(fetches).then(() => {
                    resolve({ options: rows })
                    this.setState({ searching: false, searched: !!this.state.term })
                  }).catch(e => console.error(e))
                })
              }}
              params={{
                limit: this.state.limit,
                offset: this.state.offset,
                meta_fields: [ 'branch', 'agent', 'relations' ],
                term: this.state.term,
                related: true
              }}
              parser={data => data}
              header={[
                {
                  label: 'Tag',
                  name: 'label',
                  orderable: true
                },
                {
                  label: 'Level',
                  name: 'level'
                },
                {
                  label: 'Created',
                  name: 'created',
                  format: 'datetime'
                },
                {
                  label: 'Listings',
                  children: [
                    {
                      label: 'RL',
                      name: 'residential.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'CL',
                      name: 'commercial.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'PL',
                      name: 'projects.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'HL',
                      name: 'holiday.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    }
                  ]
                },
                {
                  label: 'Clients',
                  children: [
                    {
                      label: 'C',
                      name: 'contacts.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'L',
                      name: 'leads.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'P',
                      name: 'profiles.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'S',
                      name: 'subscribers.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    }
                  ]
                },
                {
                  label: 'Media',
                  children: [
                    {
                      label: 'Assets',
                      name: 'assets.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    },
                    {
                      label: 'Documents',
                      name: 'documents.length',
                      classes: [ 'text-right' ],
                      orderable: false,
                      format: 'number'
                    }
                  ]
                }
              ]}
              getClass={el => {
                this.tags = el
              }}
              rowActions={(row, data) => {
                let can_edit = false
                let can_delete = false
                switch (data.level) {
                  case 'User':
                    if (user.permissions.includes('tags_own_update') || user.permissions.includes('is_prop_data_user')) {
                      can_edit = true
                    }
                    if (user.permissions.includes('tags_own_delete') || user.permissions.includes('is_prop_data_user')) {
                      can_delete = true
                    }
                    break
                  case 'Branch':
                    if (user.permissions.includes('tags_branch_update') || user.permissions.includes('is_prop_data_user')) {
                      can_edit = true
                    }
                    if (user.permissions.includes('tags_branch_delete') || user.permissions.includes('is_prop_data_user')) {
                      can_delete = true
                    }
                    break
                  case 'Agency':
                    if (user.permissions.includes('tags_agency_update') || user.permissions.includes('is_prop_data_user')) {
                      can_edit = true
                    }
                    if (user.permissions.includes('tags_agency_delete') || user.permissions.includes('is_prop_data_user')) {
                      can_delete = true
                    }
                    break
                  default:
                    can_edit = false
                    can_delete = false
                    break
                }
                return (
                  <>
                    {can_edit ? (
                      <Button
                        icon="#icon16-Edit"
                        className="btn btn-none btn-icon-only btn-icon-16"
                        title="Edit Tag"
                        onClick={() => {
                          this.setState({ editing: data })
                        }} type="button"
                      >
                      </Button>
                    ) : null }
                    {can_delete ? (
                      <Button
                        icon="#icon16-Bin"
                        className="btn btn-none btn-icon-only btn-icon-16"
                        title="Delete Tag"
                        onClick={() => this.setState({ delete: data.id }) }
                        type="button"
                      >
                      </Button>
                    ) : null }
                  </>
                )
              }}
            />

            {(this.root && this.state.delete) ? (
              ReactDOM.createPortal(
                <Formik
                  initialValues={{
                    record_id: [ this.state.delete ]
                  }}
                  enableReinitialize={true}
                  validateOnChange={false}
                  validateOnBlur={true}
                >{ formik => (
                    <ChallengeModal
                      isLoading={ui?.isLoading}
                      action={'deleteModels'}
                      challenges={[
                        {
                          text: (
                            <span>
                              You&apos;re about to delete
                              <span> {formik.values.record_id.length.toString()} </span> tag.
                              Please confirm your action by typing <code>CONFIRM</code> in the field below.
                            </span>
                          ),
                          value: () => 'CONFIRM',
                          action: 'deleteModels'
                        },
                        {
                          text: (
                            <span>
                              You&apos;re about to delete
                              <span> {formik.values.record_id.length.toString()} </span> tag.
                              Please confirm your action by typing <code>DELETE</code> in the field below.
                            </span>
                          ),
                          value: () => 'DELETE',
                          action: 'deleteModels'
                        }
                      ]}
                      visible={this.state.delete}
                      buttons={(({ valid, value }) => (
                        <div className="modal-buttons">
                          <Button className={classNames('btn', 'btn-primary', { disabled: !valid })} type="submit" onClick={valid ? () => {
                            formik.setFieldTouched('challenge', true, false)
                            if (valid) {
                              this.deleteTag(this.state.delete, valid)
                            } else {
                              formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                            }
                          } : null}>Confirm</Button>
                          <Button className="btn btn-white" type="submit" onClick={() => {
                            this.setState({ delete: false }, () => { formik.setFieldError('challenge', null) })
                          }}>Cancel</Button>
                        </div>
                      ))}
                    />
                  )}
                </Formik>, this.root)
            ) : null }
            <WideSidebar sidebar={'show-tag-manager'}>
              <div id="contact-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar contact-creator-sidebar">
                <div className="wide-sidebar-pane">
                  <div className="wide-sidebar-heading">
                    <h4>Edit Tag</h4>
                    <Button
                      type="button"
                      icon="#icon24-X-Large"
                      className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                      onClick={() => {
                        this.setState({ editing: null }, () => {
                          this.props.toggleWideSidebar()
                        })
                      }}
                    />
                  </div>
                  <Scrollbar
                    style={{ height: 'calc(100vh - 218px)' }}
                    renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                  >
                    <div className="wide-sidebar-content">
                      <Formik
                        initialValues={{
                          id: getIn(this.state, 'editing.id'),
                          label: getIn(this.state, 'editing.label'),
                          branch_id: getIn(this.state, 'editing.branch_id'),
                          level: getIn(this.state, 'editing.level')
                        }}
                        enableReinitialize={true}
                        onSubmit={this.updateTag}
                      >{({ values, isSubmitting, handleSubmit }) => (
                          <CustomForm
                            component="div"
                            onChange={() => {
                              this.setState({ ...values })
                            }}
                            render={() => (
                              <div className="editgroup">
                                <div className="input-group">
                                  <div className="field col-lg-12 required">
                                    <Field
                                      id="tags-label"
                                      name="label"
                                      label="Tag"
                                      _value={getIn(values, 'label')}
                                      defaultValue={getIn(values, 'label')}
                                      placeholder="Tag"
                                      component={TextInput}
                                      className="label"
                                      disabled={isSubmitting ? true : false}
                                      bounce={true}
                                    />
                                  </div>
                                </div>
                                {this.state.level === 'Branch' &&
                                      <div className="input-group">
                                        <div className="field col-lg-12 required">
                                          <Field
                                            id="tags-branch"
                                            name="branch_id"
                                            _value={getIn(values, 'branch_id')}
                                            placeholder="Select a Branch"
                                            fetchMany={this.props.fetchMany}
                                            label={branch_field.label}
                                            modelname={branch_field.modelname}
                                            optionlabel={branch_field.optionlabel}
                                            cache={this.props.cache.branches}
                                            params={branch_field.params}
                                            component={AsyncSelect}
                                            className="branch_id"
                                          />
                                        </div>
                                      </div>
                                }
                                <div className="input-group">
                                  <div className="field col-lg-12 required">
                                    <Field
                                      id="tags-level"
                                      name="level"
                                      label="Level"
                                      _value={getIn(values, 'level')}
                                      placeholder="Select a tag level"
                                      component={Select}
                                      defaultvalue="Agency"
                                      className="level"
                                      options={this.state.fields.find(f => f.name === 'level').options.filter(o => {
                                        if (o.value === 'Agency' && this.state.can_add_agency) {
                                          return true
                                        }
                                        if (o.value === 'Branch' && this.state.can_add_branch) {
                                          return true
                                        }
                                        if (o.value === 'User' && this.state.can_add_user) {
                                          return true
                                        }
                                        return false
                                      })}
                                    />
                                  </div>
                                </div>
                                <div className="wide-sidebar-footer">
                                  <Button
                                    id="keyword-search-btn"
                                    tabIndex="-1"
                                    type="button"
                                    icon="#icon24-Save"
                                    onClick={handleSubmit}
                                    disabled={this.state.searching}
                                    className="btn btn-primary btn-icon-16 btn-icon-left"
                                  >
                                      Save
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        )}
                      </Formik>
                    </div>
                  </Scrollbar>
                </div>
              </div>
            </WideSidebar>
          </div>
        </div>
      </div>
    )
  }
}

TagManager.propTypes = {
  user: PropTypes.object,
  cache: PropTypes.object,
  fields: PropTypes.object,
  updateTag: PropTypes.func,
  deleteTag: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  cacheDelta: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  location: PropTypes.object,
  ui: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = state => { // Pass only minimal data to the FieldGroup component
  const modelname = 'tags'

  const site = SITE(state)
  const ui = UI(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])
  const configs = CONFIGS(state)

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
  }
  tagconfig.config.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        mincache[mn] = cache.get(mn)
      })
    }
    if (field.input === 'LocationSelect') { mincache.set('branches', cache.get('branches')) }
  })

  return {
    configs,
    cache: mincache,
    user: minuser,
    ui
  }
}


export default withCustomRouter(connect(mapStateToProps, null)(withImmutablePropsToJS(TagManager)))
