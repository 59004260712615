/* eslint-disable new-cap */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ErrorMessage } from 'formik'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CONFIG } from '../../../../selectors'
import { Button } from '../../../ui/Button'
import log from '../../../../logging'
import Loader from '../../Loader'
import Label from './Label'


class UrlOrUploadInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }
    this.chooseFile = this.chooseFile.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.fetchFile = this.fetchFile.bind(this)
  }

  async chooseFile(e) {
    const { field, modelname } = this.props
    try {
      const el = e.target
      if (el.type === 'file') {
        const values = {
          file: el.files,
          model: modelname,
          field: field.name,
          filetype: 'image'
        }
        await new Promise((resolve, reject) => {
          this.setState({ uploading: true })
          this.props.uploadFile({ values, resolve, reject })
        }).then(r => {
          this.setState({ value: r.file, uploading: false }, () => {
            this.fetchFile()
          })
        })
      } else {
        this.setState({ value: el.value })
      }
    } catch (er) {
      log.error(er)
    }
  }

  fetchFile() {
    this.props.form.setFieldValue(this.props.field.name, this.state.value)
  }

  removeFile() {
    this.props.form.setFieldValue(this.props.field.name, null)
  }

  render() {
    const { field, form, label, id } = this.props
    const { name, value } = field
    const { errors } = form
    return (
      <div id={id} className="url-or-upload">
        <div>
          <div className="form-group">
            {label &&
            <Label htmlFor={name} error={errors[name]} style={{ position: 'relative', left: 0, top: 0, margin: '0 0 10px 0' }}>
              {label}
            </Label>
            }
          </div>
          {value ? (
            <div className="form-group">
              <div className="inlineload" style={{ flex: '1 1 100%', display: 'flex' }}>
                <img src={value} style={{ width: '100%', height: 90, objectFit: 'contain', objectPosition: '0 0' }} />
                <div style={{ marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button type="button" className="btn btn-primary" onClick={this.removeFile}>Remove file</Button>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.uploading ? (
            <div className="form-group">
              <div className="inlineload" style={{ flex: '1 1 100%', display: 'flex' }}>
                <Loader inline />
              </div>
            </div>
          ) : null}
          {(!value && !this.state.uploading) ? (
            <>
              <div className="form-group" style={{ margin: '0 0 10px 0' }}>
                {label &&
                  <Label htmlFor={name} error={errors[name]}>
                    URL
                  </Label>
                }
                <div className="forminput">
                  <input
                    id={`${name}-upload`}
                    className='form-control input-group-suffix'
                    type="url"
                    onChange={this.chooseFile}
                    value={this.state.value}
                  />
                  <div className="input-group-addon">
                    <Button type="button" onClick={this.fetchFile} className="btn btn-primary" style={{ borderRadius: '0 4px 4px 0' }}>Fetch</Button>
                  </div>
                </div>
                <ErrorMessage render={msg => <div className="error">{msg}</div>} name={name} />
              </div>
              <div className="form-group">
                {label &&
                  <Label htmlFor={name} error={errors[name]} style={{ position: 'relative', left: 0 }}>
                    OR
                  </Label>
                }
                <div className="uploadwrap" style={{ flex: '1 1 100%' }}>
                  <Button type="button" className="btn btn-primary" style={{ position: 'relative' }}>
                    <input
                      id={`${name}-upload`}
                      type="file"
                      className="btn"
                      style={{ top: 0 }}
                      onChange={this.chooseFile}
                      {...this.props.form.field}
                    />
                    Upload a file
                  </Button>
                </div>
                <ErrorMessage render={msg => <div className="error">{msg}</div>} name={name} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

UrlOrUploadInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  config: PropTypes.object,
  classes: PropTypes.string,
  modelname: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  preview: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  config: CONFIG(state, ownProps.modelname)
})

export default connect(mapStateToProps, null)(withImmutablePropsToJS(UrlOrUploadInput))
