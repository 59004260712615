import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { Button } from '../ui/Button'
import { breakpoint } from '../../utils'


const Step = props => {
  const [ desktop, setDesktop ] = useState(breakpoint.matches)
  const [ mounted, setMounted ] = useState(true)

  const toggleDesktop = e => {
    if (e.matches && mounted) {
      setDesktop(true)
    } else {
      setDesktop(false)
    }
  }

  useEffect(() => {
    breakpoint.addEventListener('change', toggleDesktop)
    return () => {
      window.removeEventListener('change', toggleDesktop)
      setMounted(false)
    }
  }, [])

  if (props.next === false && props.previous === false) { return null }
  return (
    <div className="pagestep">
      {props.selected?.length && props.mergeable ? (
        <div className="stepmergemsg">
          Click the Merge Primary button above to make the currently viewed profile the primary one
          which will be enriched from the others. All other selected profiles will be deactivated.
          This cannot be undone.
        </div>
      ) : null
      }
      {props.selected && props.selected.length > 1 &&
      <div className="stepposition">
        {props.editmode ? 'Editing ' : 'Viewing '} record {props.selected.indexOf(props.modelid) + 1} of {props.selected.length}
      </div>
      }
      {desktop && <Button
        type="button"
        icon="#icon16-First"
        onClick={() => (((props.previous === false) || (props.previous === null)) ? () => {} : props.stepPage('first'))}
        className={`firstpage soft btn btn-subtle btn-icon-16 ${((props.previous === false) || (props.previous === null)) && 'disabled'}`}
      />}
      <Button
        type="button"
        icon="#icon16-ChevronLeft"
        onClick={() => (((props.previous === false) || (props.previous === null)) ? () => {} : props.stepPage('previous'))}
        className={`prevpage soft btn btn-subtle btn-icon-16 ${((props.previous === false) || (props.previous === null)) && 'disabled'}`}
      />
      <Button
        type="button"
        icon="#icon16-ChevronRight"
        onClick={() => ((!props.next) ? () => {} : props.stepPage('next'))}
        className={`nextpage soft btn btn-subtle btn-icon-16 ${!props.next && 'disabled'}`}
      />
      {desktop && <Button
        type="button"
        icon="#icon16-Last"
        onClick={() => ((!props.next) ? () => {} : props.stepPage('last'))}
        className={`lastpage soft btn btn-subtle btn-icon-16 ${!props.next && 'disabled'}`}
      />}
    </div>
  )
}

Step.propTypes = {
  stepPage: PropTypes.func,
  modelid: PropTypes.number,
  editmode: PropTypes.bool,
  mergeable: PropTypes.bool,
  next: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, PropTypes.string ]),
  previous: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, PropTypes.string ]),
  selected: PropTypes.array
}

export default Step
