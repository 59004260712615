import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { isEmpty } from '../utils'
import { forgot } from '../validate/forgot'
import TextInput from './common/forms/inputs/Text'
import Footer from './Footer'
import { Button } from './ui/Button'


const Forgot = props => {
  let loading = false
  if (props.ui.form && props.ui.form.isSubmitting) { loading = true }
  if (props.ui.isLoading) { loading = true }

  return (
    <div className="wrapper login">
      {loading ? <div className='isloading block'><div className="loader"></div></div> : null}
      <div className="content">
        <div className="content-wrapper">
          <div className="login-box">
            <div className="pd-logo"></div>
            <h3>Reset your login by<br />entering your email below</h3>
            <Formik
              initialValues={{
                username: ''
              }}
              validationSchema={forgot}
              validateOnBlur={true}
              validateOnChange={false}
              onSubmit={(values, actions) => new Promise((resolve, reject) => props.sendReset({
                values,
                resolve,
                reject
              })).then(() => {
                actions.setSubmitting(false)
                actions.setStatus({
                  type: 'success',
                  msg: 'Reset link sent - check your email'
                })
              }).catch(e => {
                actions.setSubmitting(false)
                if (e.message) { // JS error
                  actions.setStatus({
                    type: 'error',
                    msg: e.message
                  })
                } else if (e.raw) { // Services error
                  actions.setStatus({
                    type: 'error',
                    msg: e.raw.username || e.raw.detail
                  })
                } else if (e.error) { // Services error
                  actions.setStatus({
                    type: 'error',
                    msg: e.error
                  })
                }
              }) }
            >{({ errors, status, isSubmitting }) => (
                <Form>
                  <div className="login-box-body">
                    <Field
                      label="Email"
                      type="text"
                      name="username"
                      placeholder="eg. joe@myproperties.com"
                      component={TextInput}
                      id="username"
                      bounce={true}
                    />
                    <div className="login-box-buttons">
                      <Link to="/login" className="forgot float-right">Back to login</Link>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={ isSubmitting || !isEmpty(errors) }
                      >
                        Request Reset
                      </Button>
                    </div>
                    <div className={status ? `${status.type} message` : 'message'}>
                      <div className="msg warn">{status && status.msg}</div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="login-box-footer">
            <a href="http://support.propdata.net/" target="_blank" rel="noopener noreferrer">Need Help? Click here for Support</a>
            <Footer />
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    </div>
  )
}

Forgot.propTypes = {
  ui: PropTypes.object,
  sendReset: PropTypes.func
}

export default Forgot
