/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { MINUSER, UI, APP, ADDONS } from '../selectors'
import { toggleNav, toggleMenu, toggleAlerts, doLogout } from '../actions'

import Header from '../components/Header'


const mapStateToProps = state => (
  {
    ui: UI(state),
    app: APP(state),
    user: MINUSER(state),
    addons: ADDONS(state)
  }
)

const mapDispachToProps = dispatch => bindActionCreators({ toggleNav, toggleMenu, toggleAlerts, doLogout }, dispatch)

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(Header))
