import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import Select from 'react-select'
import isEqual from 'react-fast-compare'

import { responsiveSelect } from './ResponsiveSelect'


const ResponsiveSelect = responsiveSelect(Select)

const InlineSelect = props => {
  const {
    selectedValue,
    defaultValue,
    field,
    form,
    onChange,
    id,
    className,
    name,
    prefix,
    suffix,
    touched,
    error,
    options,
    style,
    components
  } = props

  const getSelectedOption = useCallback(v => {
    for (const option of options) {
      if (option.value === v) { return option }
    }
    return null
  }, [ options ])

  let defaultvalue = defaultValue
  if (defaultValue !== '' && typeof defaultValue === 'string') { defaultvalue = JSON.parse(defaultValue) }

  const [ val, setVal ] = useState()

  useEffect(() => {
    const new_val = ![ null, undefined, '' ].includes(selectedValue) ? getSelectedOption(selectedValue) : defaultvalue
    if (!isEqual(new_val, val)) {
      setVal(new_val)
    }
  }, [ defaultvalue, selectedValue ])

  return (
    <div
      id={id}
      className={classNames('inlineselectinput', name, 'form-group')}
    >
      <div className={classNames('forminput', className)}>
        {prefix}
        <ResponsiveSelect
          style={style}
          id={id}
          form={form}
          field={field}
          className={`react-select${prefix ? ' input-group-prefix' : ''}${suffix ? ' input-group-suffix' : ''}`}
          classNamePrefix="react-select"
          options={options}
          name={name}
          value={val}
          onChange={onChange}
          arrowRenderer={() => ''}
          placeholder={''}
          components={components ? components : undefined}
        />
        {suffix}
        {!!error &&
        touched && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

InlineSelect.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object,
  field: PropTypes.object,
  options: PropTypes.array,
  name: PropTypes.string,
  style: PropTypes.object,
  classes: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.object,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  user: PropTypes.object,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  prefix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  components: PropTypes.object
}

InlineSelect.displayName = 'InlineSelect'
export default InlineSelect

