import PropTypes from 'prop-types'


class Column {
  constructor(props) {
    if (!props.name) {
      throw new Error(`Column 'name' is not defined in props: ${JSON.stringify(props)}`)
    }
    this.name = props.name
    if (props.name.indexOf('[') !== -1) {
      this.name = JSON.parse(props.name)
    }
    if (props.children) {
      this.children = props.children.map(col => new Column(col))
    }
  }

  export() {
    let name = this.name
    if (Array.isArray(name)) {
      name = JSON.stringify(this.name)
    }
    return {
      ...this,
      name
    }
  }

  extend(props) {
    return { ...this, ...props }
  }
}

Column.propTypes = {
  name: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
  children: PropTypes.array
}

export default Column
