/* eslint-disable new-cap */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { AGENT, PERMISSIONS } from '../../../selectors'
import { valueFormat, hasPermission } from '../../../utils'
import TooltipContext from './TooltipContext'


const ContactPopup = ({ children, contact, can_view_contact }) => {
  const active = contact.status || contact.active ? 'Active' : 'Inactive'
  const contact_types = getIn(contact, 'contact_types', []) || []

  return can_view_contact ? (
    <TooltipContext
      className="popup contact-popup"
      options={{ followCursor: false, arrow: 'bottom-left', shiftY: -5, shiftX: -80 }}
      title={null}
      content={
        <div className="contact-cards">
          <div className="listing-contacts-card">
            <div className='listing-contacts-details'>
              <div className="listing-contacts-label">{contact_types.join(', ')}</div>
              <h3 className="listing-contacts-name"><Link className='has-link' to={`/secure/${contact.site}/contacts/${contact.id}`}>{`${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''}`}</Link></h3>
              <div className={classNames('listing-contacts-status', { active: contact.status || contact.active })}>{active}</div>
              <div className='listing-contacts-meta'>
                {contact.cell_number && <div><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Phone" /></svg>{valueFormat('tel', contact.cell_number)}</div>}
                <div><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Envelope" /></svg>{valueFormat('mailto', contact.email)}</div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </TooltipContext>
  ) : children
}

ContactPopup.propTypes = {
  contact: PropTypes.object,
  can_view_contact: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}


const mapStateToProps = (state, ownProps) => {
  const agent = AGENT(state)
  const permissions = PERMISSIONS(state)
  const { contact } = ownProps

  let can_view_contact = false
  if (contact.introduction_agent === agent.getIn([ 'id' ])) {
    can_view_contact = true
  } else if (permissions.includes('is_prop_data_user')) {
    can_view_contact = true
  } else if (hasPermission([ 'contacts_view' ], permissions.toJS()) && agent.getIn([ 'branches' ]).includes(contact.branch)) {
    can_view_contact = true
  }
  return ({
    can_view_contact
  })
}

export default connect(mapStateToProps, null)(ContactPopup)
