/* eslint-disable new-cap */
import merge from 'deepmerge'
import { ErrorMessage, Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import log from '../../../../logging'
import { CONFIGS } from '../../../../selectors'
import { capitalize, groupBy, overwriteMerge, uniqueArray, valueFormat } from '../../../../utils'
import { Button } from '../../../ui/Button'
import HelpText from '../../HelpText'
import CustomForm from '../CustomForm'
import TextInput from './Text'


class AssociationsTable extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h5 className="formlabel recordselector-heading">
          {this.props.heading}
        </h5>
        <div className="datatable contactselector">
          <div className="datatable-headers" data-columns="3">
            <div>Type</div>
            <div>Name</div>
            <div></div>
          </div>
          <div className="datatable-body" data-columns="3">
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AssociationsTable.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string
}

class Record extends React.Component {
  render() {
    const { type, modelname, value, record, selected, readonly } = this.props
    const action = selected ? this.props.unlinkAssociation : this.props.linkAssociation
    return (
      <div className="datatable-row">
        <div><span className="tablecell">{capitalize(type)}</span></div>
        <div>
          <span className="tablecell">
            <Button component={NavLink} to={`/secure/${modelname}/${record}`} title={`View ${capitalize(type)}`}>
              {value}
            </Button>
          </span>
        </div>
        <div>
          {!readonly &&
            <span
              className="tablecell select-contact"
              onClick={() => action(modelname, record)}
            >
              <svg viewBox="0 0 32 32"><use href={selected ? '/images/icons-16.svg#icon16-X-Large' : '/images/icons-16.svg#icon16-Plus'}/></svg>
            </span>
          }
        </div>
      </div>
    )
  }
}

Record.propTypes = {
  type: PropTypes.string,
  modelname: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  record: PropTypes.number,
  selected: PropTypes.bool,
  readonly: PropTypes.bool,
  unlinkAssociation: PropTypes.func,
  linkAssociation: PropTypes.func
}

class AssociationsInput extends React.Component {
  /* This component is at the moment only used for showing conflicting
  * serviced locations in the branch model. It can be extended in the future
  * if the developer finds a use case, however for now this is the single
  * place it is used.
  */
  constructor(props) {
    super(props)

    this.state = {
      term: '',
      searching: false,
      associations: {},
      selected: null,
      recommendations: null,
      open: false,
      init: false
    }
    this.isEnter = this.isEnter.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.lookupAssociations = this.lookupAssociations.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.linkAssociation = this.linkAssociation.bind(this)
    this.unlinkAssociation = this.unlinkAssociation.bind(this)
    this.loadRecommendations = this.loadRecommendations.bind(this)
    this.loadAssociations = this.loadAssociations.bind(this)
  }

  componentDidMount() {
    const { field, recommendations } = this.props
    const { selected } = this.state
    const new_selected = selected ? merge({}, selected) : {}
    if (field.value && field.value.length && (!selected || !Object.keys(new_selected).length)) {
      if (field.value) {
        const group_relations = groupBy(field.value, 'related_model')
        const check_selected = {}
        Object.keys(group_relations).forEach(k => {
          check_selected[k] = group_relations[k].map(rel => rel.related_id)
        })
        if (!isEqual(selected, check_selected)) {
          this.setState({ selected: check_selected }, () => {
            this.loadRecommendations()
            this.loadAssociations()
          })
        }
      }
    }
    const selected_changed = this.state.selected || !field.value || !field.value.length
    if (recommendations.length && !this.state.init && !this.state.searching && selected_changed) {
      this.loadRecommendations()
    }
    if (this.props.related) {
      this.loadAssociations()
    }
  }

  componentDidUpdate(prevProps) {
    const { field, recommendations } = this.props
    const { selected } = this.state
    const new_selected = selected ? merge({}, selected) : {}
    if (
      !isEqual(prevProps.field.value, field.value) ||
      (field.value && field.value.length && (!selected || !Object.keys(new_selected).length))
    ) {
      if (field.value) {
        const group_relations = groupBy(field.value, 'related_model')
        const check_selected = {}
        Object.keys(group_relations).forEach(k => {
          check_selected[k] = group_relations[k].map(rel => rel.related_id)
        })
        if (!isEqual(selected, check_selected)) {
          this.setState({ selected: check_selected }, () => {
            this.loadRecommendations()
            this.loadAssociations()
          })
        }
      }
    }
    const selected_changed = this.state.selected || !field.value || !field.value.length
    if (recommendations.length && !this.state.init && !this.state.searching && selected_changed) {
      this.loadRecommendations()
    }
    if (!prevProps.related && this.props.related) {
      this.loadAssociations()
    }
  }

  loadRecommendations() {
    const { recommendations, fetchMany } = this.props
    const { selected } = this.state
    if (this.state.recommendations) {
      this.setState({ associations: this.state.recommendations, init: true, searching: false })
    }
    this.setState({ searching: true })
    const recommended = {}
    recommendations.forEach(rec => {
      if (rec.value) {
        let ids = rec.value
        const { modelname } = rec
        if (selected && Object.keys(selected).length && selected[modelname] && selected[modelname].length) {
          ids = ids.filter(v => !selected[modelname].includes(v))
        }
        const combined_ids = recommended[modelname] ? recommended[modelname].concat(ids) : [].concat(ids)
        recommended[modelname] = uniqueArray(combined_ids)
      }
    })
    const lookups = Object.keys(recommended).map(modelname => {
      const values = {
        modelname,
        conflicts: true,
        params: {
          id__in: recommended[modelname].filter(a => a)
        }
      }
      if ([ 'profiles', 'leads' ].includes(modelname)) {
        values.params.meta_fields = [ 'contact' ]
      }
      return new Promise((resolve, reject) => {
        if (recommended[modelname].length) {
          return fetchMany({ values, resolve, reject })
        }
        return resolve([])
      }).then(r => {
        const response = {}
        response[modelname] = r
        return response
      }).catch(e => (
        log.error(e)
      ))
    })
    Promise.allSettled(lookups).then(results => {
      let associations = this.state.associations
      results.forEach(result => {
        associations = merge(associations, result.value, { arrayMerge: overwriteMerge })
      })
      this.setState({ associations, recommendations: associations, init: true, searching: false, recommended: true })
    })
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire lookup contact action here on enter
      return this.lookupAssociations(e)
    } // continue typing
    return true
  }

  updateInput(e) {
    this.setState({ term: e.target.value })
  }

  loadAssociations() {
    const { fetchMany, related } = this.props
    const { selected } = this.state
    if (selected) {
      Object.keys(selected).map(modelname => {
        const values = {
          modelname,
          conflicts: true,
          params: {
            id__in: selected[modelname]
          }
        }
        if ([ 'profiles', 'leads' ].includes(modelname)) {
          values.params.meta_fields = [ 'contact' ]
        }
        return new Promise((resolve, reject) => fetchMany({ values, resolve, reject })).then(r => {
          const response = {}
          response[modelname] = r
          return response
        }).catch(e => (
          log.error(e)
        ))
      })
    }
    if (related && origin.related_model) {
      const origin = related.relations.find(r => r.id === related.origin)
      const values = {
        modelname: origin.related_model,
        conflicts: true,
        params: {
          id__in: [ origin.related_id ]
        }
      }
      if ([ 'profiles', 'leads' ].includes(origin.related_model)) {
        values.params.meta_fields = [ 'contact' ]
      }
      return new Promise((resolve, reject) => fetchMany({ values, resolve, reject })).then(r => {
        const response = {}
        response[origin.related_model] = r
        return response
      }).catch(e => (
        log.error(e)
      ))
    }
    return null
  }

  handleSubmit(values, formikbag) {
    this.lookupAssociations(values.term)
    formikbag.setSubmitting(false)
  }

  lookupAssociations(term) {
    const { fetchMany, associations: models } = this.props
    const lookups = models.map(modelname => {
      const values = {
        modelname,
        conflicts: true,
        term
      }
      if ([ 'profiles', 'leads' ].includes(modelname)) {
        values.params = { meta_fields: [ 'contact' ] }
      }
      if (this.state.selected && this.state.selected[modelname]) {
        values.params = {
          id__in__not: this.state.selected[modelname]
        }
      }

      return new Promise((resolve, reject) => fetchMany({ values, resolve, reject })).then(r => {
        const response = {}
        response[modelname] = r
        return response
      }).catch(e => (
        log.error(e)
      ))
    })
    Promise.allSettled(lookups).then(results => {
      let associations = this.state.associations || {}
      results.forEach(result => {
        if (result.value) {
          associations = merge(associations, result.value, { arrayMerge: overwriteMerge })
        }
      })
      this.setState({ associations, recommended: false })
    })
    return null
  }

  unlinkAssociation(type, record) {
    const { form, field } = this.props
    const { selected, recommendations } = this.state
    selected[type] = selected[type].filter(c => c !== record)
    let associations = {}
    if (recommendations) {
      associations = merge({}, recommendations)
      Object.keys(selected).forEach(t => {
        associations[t] = associations[t] ? associations[t].filter(c => !selected[t].includes(c.id)) : []
      })
    }
    const val = []
    if (selected) {
      Object.keys(selected).forEach(t => {
        selected[t].forEach(v => {
          const exists = field.value ? field.value
            .find(fv => fv.related_model === t && fv.related_id === v && fv.id) : null
          const vt = {
            related_model: t,
            related_id: v
          }
          if (exists) {
            vt.id = exists.id
          }
          val.push(vt)
        })
      })
    }
    form.setFieldValue(field.name, val).then(() => {
      form.setFieldTouched(field.name)
    })
    this.setState({ selected, associations, recommended: true })
  }

  linkAssociation(type, record) {
    const { form, field } = this.props
    let { selected, recommendations } = this.state
    if (!selected) {
      selected = {}
    }
    if (!recommendations) {
      recommendations = {}
    }
    if (!selected[type]) {
      selected[type] = []
    }
    let associations = {}
    selected[type] = uniqueArray([ ...selected[type], record ])
    if (recommendations) {
      associations = merge({}, recommendations)
      Object.keys(selected).forEach(t => {
        associations[t] = associations[t] ? associations[t].filter(c => !selected[t].includes(c.id)) : []
      })
    }
    const val = []
    if (selected) {
      Object.keys(selected).forEach(t => {
        selected[t].forEach(v => {
          const exists = field.value ? field.value
            .find(fv => fv.related_model === t && fv.related_id === v && fv.id) : null
          const vt = {
            related_model: t,
            related_id: v
          }
          if (exists) {
            vt.id = exists.id
          }

          val.push(vt)
        })
      })
    }
    form.setFieldValue(field.name, val).then(() => {
      form.setFieldTouched(field.name)
    })
    this.setState({ selected, associations, term: '', recommended: true })
  }

  toggleCreator() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { id, cache, related, match, configs, ...props } = this.props
    const { field } = props
    const { name } = field
    let orig_value
    let orig_field
    let orig_cached
    let origin = {}
    if (related) {
      origin = related.relations.find(r => r.id === related.origin)
      if (origin) {
        orig_field = configs[origin.related_model].fields
          .find(f => isEqual(f.name, configs[origin.related_model].singlekey))
        orig_cached = cache[origin.related_model] ? cache[origin.related_model][origin.related_id] : null
      }
      if (orig_cached && orig_field) {
        orig_value = orig_cached[orig_field.name]
        if (Array.isArray(orig_field.name)) {
          orig_value = orig_field.name.map(f => orig_cached[f]).join(' ')
        }
      }
    }
    let showcurrent = true
    if (related && match && match.params && origin) {
      showcurrent = parseInt(match.params.id, 10) !== origin.related_id && match.model !== origin.related_model
    }
    return (
      <Formik
        initialValues={{ term: null }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >{ formik => (
          <CustomForm
            component={'div'}
            render={() => (
              <div id={id} className={`form-group associations-lookup ${props.classes ? props.classes : ''}`}>
                {!(this.state.selected && this.state.selected.length > 0) && !this.props.readonly &&
                    <div className="input-group">
                      <Field
                        component={TextInput}
                        name="term"
                        id="term"
                        type="text"
                        suffix={(
                          <Button
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            icon="#icon24-Search"
                            onClick={formik.handleSubmit}
                            disabled={formik.isSubmitting}
                            className="btn btn-none input-group-addon btn-icon btn-icon-24"
                          />
                        )}
                      />
                      {props.help &&
                        <span className="help-text">
                          {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
                        </span>
                      }
                      <ErrorMessage component="div" className="error" name={name} />
                    </div>
                }

                {Object.keys(this.state.associations)
                  .some(type => this.state.associations[type].length) && !this.props.readonly &&
                    <AssociationsTable heading={this.state.recommended ? 'Recommended' : 'Results'}>
                      {Object.keys(this.state.associations).map(type => {
                        const records = this.state.associations[type]
                        if (records.length) {
                          return records.map((record, ridx) => {
                            const displayfield = configs[type].fields
                              .find(f => isEqual(f.name, configs[type].singlekey))
                            if (!displayfield) { return null }
                            let value = record[displayfield.name]
                            if ([ 'profiles', 'leads' ].includes(type)) {
                              value = `${record.meta.contact.first_name}${record.meta.contact.last_name !== null ? ` ${record.meta.contact.last_name}` : ''} (${record.meta.contact.email})`
                            } else if ([ 'contacts' ].includes(type)) {
                              value = `${record.first_name}${record.last_name !== null ? ` ${record.last_name}` : ''} (${record.email})`
                            } else if (Array.isArray(displayfield.name)) {
                              value = displayfield.name.map(f => record[f]).join(' ')
                            }
                            return (
                              <Record
                                key={`${type}-${ridx}`}
                                type={configs[type].singular}
                                modelname={type}
                                value={valueFormat(displayfield.format, displayfield.format === 'listing_popup' ? record : value, displayfield)}
                                record={record.id}
                                linkAssociation={this.linkAssociation}
                              />
                            )
                          })
                        }
                        return null
                      })}
                    </AssociationsTable>
                }

                {this.state.selected && Object.keys(this.state.selected)
                  .some(type => this.state.selected[type].length) &&
                    <AssociationsTable heading={`${this.props.readonly ? '' : 'Selected'}`}>
                      {Object.keys(this.state.selected).map(type => {
                        const records = this.state.selected[type]
                        if (records.length) {
                          return records.map((record, ridx) => {
                            const displayfield = configs[type].fields
                              .find(f => isEqual(f.name, configs[type].singlekey))
                            if (!displayfield) { return null }
                            const cached = cache[type] ? cache[type][record] : null
                            if (!cached) { return null }
                            let value = cached[displayfield.name]
                            if ([ 'profiles', 'leads' ].includes(type)) {
                              value = `${cached.meta.contact.first_name}${cached.meta.contact.last_name !== null ? ` ${cached.meta.contact.last_name}` : ''} (${cached.meta.contact.email})`
                            } else if ([ 'contacts' ].includes(type)) {
                              value = `${cached.first_name}${cached.last_name !== null ? ` ${cached.last_name}` : ''} (${cached.email})`
                            } else if (Array.isArray(displayfield.name)) {
                              value = displayfield.name.map(f => cached[f]).join(' ')
                            }

                            if (
                              match
                              && match.params
                              && parseInt(match.params.id, 10) === record
                              && match.params.model === type
                            ) {
                              // hide current record if part of relations
                              return null
                            }
                            return (
                              <Record
                                key={`${type}-${ridx}`}
                                type={configs[type].singular}
                                modelname={type}
                                value={valueFormat(displayfield.format, displayfield.format === 'listing_popup' ? cached : value, displayfield)}
                                readonly={this.props.readonly}
                                record={cached.id}
                                selected={true}
                                unlinkAssociation={this.unlinkAssociation}
                              />
                            )
                          })
                        }
                        return null
                      })}
                      {(related && orig_cached && orig_field && showcurrent) ? (
                        <Record
                          key={`${origin.related_model}-orig`}
                          type={configs[origin.related_model].singular}
                          modelname={origin.related_model}
                          value={valueFormat(orig_field.format, orig_field.format === 'listing_popup' ? orig_cached : orig_value, orig_field)}
                          readonly={true}
                          record={orig_cached.id}
                          selected={true}
                          unlinkAssociation={this.unlinkAssociation}
                        />
                      ) : null}
                    </AssociationsTable>
                }
              </div>
            )}
          />
        )
        }
      </Formik>
    )
  }
}

AssociationsInput.propTypes = {
  id: PropTypes.string.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  associations: PropTypes.array.isRequired,
  classes: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  create: PropTypes.bool,
  cache: PropTypes.object,
  configs: PropTypes.object,
  related: PropTypes.object,
  match: PropTypes.object,
  readonly: PropTypes.bool,
  recommendations: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  config: PropTypes.object
}


const mapStateToProps = state => ({
  configs: CONFIGS(state)
})

export default connect(mapStateToProps, null)(withImmutablePropsToJS(AssociationsInput))
