import merge from 'deepmerge'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'

import { logEvent, updateSearchParms } from '../../../utils'
import { Button } from '../../ui/Button'
import CheckInput from '../forms/inputs/Check'
import TextInput from '../forms/inputs/Text'
import TooltipContext from '../tooltips/TooltipContext'


const SimpleSearch = props => {
  const [ mine, setMine ] = useState(false)
  const [ visible, setVisible ] = useState(false)

  const { model, config } = props
  let params = new URLSearchParams(props.location.search)

  const cleanedparams = merge({}, model.params)
  const defaultparams = merge({}, config.params)
  const sortparms = [ 'order_by', 'limit', 'meta_fields', 'order_by_related', 'offset' ]
  sortparms.forEach(p => {
    delete cleanedparams[p]
    delete defaultparams[p]
  })
  const advanced = !isEqual(cleanedparams, defaultparams)
  const search = new URLSearchParams(config.params).toString()
  const link = `${config.modelactions.list.link}?${search}`
  const modelname = config.modelname.replace('syndication', '')
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        term: params.get('search'),
        related: params.get('related') ? params.get('related') : false
      }}
      onSubmit={(values, form) => {
        updateSearchParms({ search: values.term, offset: 0, related: values.related })
        logEvent('KEYWORD_SEARCH', { modelname: props.config.modelname, values })
        form.setSubmitting(false)
      }}
    >{({ isSubmitting }) => {
        const Search = config.search_hint ? (
          <TooltipContext
            visible={visible}
            options={{ followCursor: false, capWidth: true, arrow: 'bottom', shiftY: -8 }}
            className="heading-wrapper"
            content={(<><strong>Search Across:</strong> {config.search_hint}</>)}
          >
            <Field
              id="term"
              type="search"
              name="term"
              placeholder="Keyword Search"
              component={TextInput}
              suffix={(
                <Button icon="#icon24-Search" type="submit" disabled={isSubmitting} className="input-group-addon btn btn-icon-24 btn-none" />
              )}
              onFocus={() => setVisible(true)}
              onBlur={() => setVisible(false)}
              onChange={e => setVisible(!e.target.value)}
              className="term input-group-suffix"
              disabled={isSubmitting ? true : false}
            />
          </TooltipContext>
        ) : (
          <Field
            id="term"
            type="search"
            name="term"
            placeholder="Keyword Search"
            component={TextInput}
            suffix={(
              <Button icon="#icon24-Search" type="submit" disabled={isSubmitting} className="input-group-addon btn btn-icon-24 btn-none" />
            )}
            className="term input-group-suffix"
            disabled={isSubmitting ? true : false}
          />
        )
        return (
          <Form>
            <div className="search-fields">
              <div className="input-group keyword-search">
                {Search}
                {props.user.agent.id ? (
                  <CheckInput
                    field={{
                      name: 'mine',
                      value: mine
                    }}
                    form={{
                      handleChange: () => {},
                      handleBlur: () => {},
                      setFieldValue: async (field, value) => {
                        setMine(value)
                        params = {}
                        if (value) {
                          const agent_id = props.user.agent.id
                          if ([ 'residential', 'commercial', 'projects', 'holiday', 'valuations' ].includes(modelname)) {
                            [ 'agent__in__or', 'agent_2__in__or', 'agent_3__in__or', 'agent_4__in__or' ].forEach(v => {
                              params[v] = agent_id
                            })
                          }
                          if ([ 'leads', 'profiles' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'contacts' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'subscribers' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'agents' ].includes(modelname)) {
                            params.id = agent_id
                          }
                          if ([ 'branches' ].includes(modelname)) {
                            params.id__in = props.user.agent.branches
                          }
                          if (Object.keys(params).length) {
                            updateSearchParms(params)
                          }
                        } else {
                          const agent_id = null
                          if ([ 'residential', 'commercial', 'projects', 'holiday', 'valuations' ].includes(modelname)) {
                            [ 'agent__in__or', 'agent_2__in__or', 'agent_3__in__or', 'agent_4__in__or' ].forEach(v => {
                              params[v] = agent_id
                            })
                          }
                          if ([ 'leads', 'profiles' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'contacts' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'subscribers' ].includes(modelname)) {
                            params.contact_agents__in = agent_id
                          }
                          if ([ 'agents' ].includes(modelname)) {
                            params.id = agent_id
                          }
                          if ([ 'branches' ].includes(modelname)) {
                            params.id__in = agent_id
                          }
                          if (Object.keys(params).length) {
                            updateSearchParms(params)
                          }
                        }
                        return true
                      },
                      setFieldTouched: async () => {},
                      errors: {},
                      touched: {}
                    }}
                    id="simple-search-mine"
                    label={`Show only my ${[ 'residential', 'commercial', 'projects', 'holiday' ].includes(modelname) ? 'listings' : props.config.plural}`}
                    name="mine"
                    component={CheckInput}
                  />
                ) : null}
              </div>
              <div className="input-group advanced-search search-buttons">
                {props.config.advsearch &&
                <Button icon={advanced ? '#icon16-Check' : null} type="button" onClick={props.toggleAdvanced} className={`btn btn-subtle${advanced ? '  btn-icon-16 btn-icon-left btn-icon-success' : ''}`}>
                  Advanced Search
                </Button>
                }
                {advanced &&
                  <Button component={NavLink} to={link} activeClassName="" title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
                    Reset filters
                  </Button>
                }
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}


SimpleSearch.propTypes = {
  config: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  advanced: PropTypes.bool,
  toggleAdvanced: PropTypes.func
}

export default SimpleSearch
