import React from 'react'
import PropTypes from 'prop-types'


class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    this.tooltip = React.createRef()
  }

  render() {
    const { style, title, content, className } = this.props
    return (
      <div ref={this.tooltip} className={`tc-tooltip${className}`} style={style}>
        {title &&
          <h3 className={'tc-tooltip-title'}>{title}</h3>
        }
        <div className={'tc-tooltip-content'}>
          {content}
        </div>
      </div>
    )
  }
}

Tooltip.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

export default Tooltip
