/* eslint-disable new-cap */
import { List, fromJS } from 'immutable'

import config from '../config/config.json' // This must be imported separately
import log from '../logging'


export default (newstate = fromJS(config.defaultState.selected), action) => {
  switch (action.type) {
    case 'SELECT_ALL_SUCCESS': {
      try {
        const selected = newstate.hasIn([ action.modelname ])
        if (!selected) {
          newstate = newstate.setIn([ action.modelname ], List())
        }
        return newstate.setIn([ action.modelname ], action.selected)
      } catch (e) {
        log.error(e)
        break
      }
    }

    case 'SELECT_NONE':
      try {
        return fromJS(config.defaultState.selected)
      } catch (e) {
        log.error(e)
        break
      }

    case 'UNSELECT_AGENT':
      try {
        return fromJS(config.defaultState.selected)
      } catch (e) {
        log.error(e)
        break
      }

    case 'SELECT_ONE_SUCCESS': {
      try {
        let selected = newstate.getIn([ action.modelname ])
        if (action.select) { // The singleton was selected
          if (selected) { // Already some selected
            if (selected.indexOf(parseInt(action.id, 10)) === -1) {
              // The selected item is not in the selected list - put it in
              selected = selected.push(parseInt(action.id, 10))
              // Order ascending
              selected = selected.sort((a, b) => a - b)
              newstate = newstate.setIn([ action.modelname ], selected)
            }
          } else { // None previously selected, create the new array
            newstate = newstate.setIn([ action.modelname ], List([ parseInt(action.id, 10) ]))
          }
        } else { // Already some selected but not action select ie. de-selection
          const idx = selected.indexOf(action.id)
          selected = selected.delete(idx)
          if (selected.size === 0) {
            newstate = newstate.deleteIn([ action.modelname ])
          } else {
            newstate = newstate.setIn([ action.modelname ], selected)
          }
        }
        break
      } catch (e) {
        log.error(e)
        break
      }
    }

    default:
      return newstate
  }
  return newstate
}
