import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import log from '../../../../logging'
import { Button } from '../../../ui/Button'
import RadioInput from '../../forms/inputs/Radio'


const Preference = ({ preference, form, modelname, deletePreference }) => (
  <div
    className={'preference-item'}
  >
    <Field
      name={'preference'}
      id={`preference-${preference.id}`}
      value={String(preference.id)}
      label={preference.name}
      component={RadioInput}
      handleChange={e => {
        form.handleChange(e)
        setTimeout(form.submitForm, 100)
      }}
    />
    {(preference.id > 0) &&
      <Button icon="#icon16-X-Small" type="button" className="column-remover btn btn-text btn-icon-24" onClick={() => {
        // eslint-disable-next-line no-alert
        const confirmation = window.confirm('Are you sure you want to delete this view?')
        if (!confirmation) {
          return
        }
        new Promise((resolve, reject) => deletePreference({ id: preference.id, modelname, resolve, reject }))
          .catch(e => {
            log.error(e.message)
          })
      }}>
      </Button>
    }
  </div>
)


Preference.propTypes = {
  preference: PropTypes.object,
  form: PropTypes.object,
  modelname: PropTypes.string,
  deletePreference: PropTypes.func
}

export default Preference
