import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import Tip from '../Tip'
import SnippetModal from '../../modals/SnippetModal'
import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import CleverComposeSidebar from '../../../../containers/CleverComposeSidebar'
import { slugify } from '../../../../utils'
import InputCounter from './InputCounter'
import Label from './Label'


const TextAreaInput = ({
  field: {
    name,
    value,
    ...field
  },
  form: {
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    values
  },
  cache,
  label,
  readonly,
  disabled,
  rtl,
  lang,
  id,
  defaultValue,
  onlyValue,
  ...props
}) => {
  const [ snippet, setSnippet ] = useState(false)
  const { portals } = props

  const [ tvalue, setValue ] = useState(![ undefined, null ].includes(props._value) ? props._value : '')
  const t = useRef()
  const elm = useRef(null)
  const rootRef = useRef(null)
  const rootel = useRef(null)

  const debounced = useDebouncedCallback(v => {
    setValue(v)
    elm.current.value = v
    setFieldValue(name, v).then(() => {
      if (!Object.keys(touched).length) { setFieldTouched(name) }
    })
  }, 300)

  const onChange = e => {
    setValue(![ undefined, null ].includes(e.target.value) ? e.target.value : '')
    clearTimeout(t.current)
    t.current = setTimeout(() => {
      handleChange(e)
    }, 300)
  }

  useEffect(() => {
    if (rootel.current) {
      rootRef.current = ReactDOM.createRoot(rootel.current)
    }
    return () => {
      setTimeout(() => {
        rootRef.current?.unmount()
      })
    }
  }, [])

  useEffect(() => {
    if (defaultValue !== tvalue) {
      elm.current.value = ![ undefined, null ].includes(defaultValue) ? defaultValue : tvalue || ''
      setValue(![ undefined, null ].includes(defaultValue) ? defaultValue : '')
    }
  }, [ defaultValue ])

  useEffect(() => {
    if (value !== tvalue) {
      const value_to_set = ![ undefined, null ].includes(value) ? value : tvalue || ''
      elm.current.value = value_to_set
      setValue(value_to_set)
    }
  }, [ props.aidx ])

  if (onlyValue) {
    return (
      <div id={id} className={classNames('textareainput', 'form-group', { 'has-copy-link': props.copy, 'has-counter': props.counter, rtl }, props.classes, name)}>
        <Label htmlFor={name}>
          {label}
        </Label>
        <div ref={elm} className="forminput">
          <div className="form-control domstring" dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      </div>
    )
  }
  return (
    <div id={id} className={classNames('textareainput', 'form-group', { 'has-copy-link': props.copy, 'has-counter': props.counter, rtl }, props.classes, name)}>
      <Label htmlFor={name}>
        {label}
      </Label>
      <div className="forminput">
        {props.prefix}
        <textarea
          id={`input-${slugify(id)}`}
          name={name}
          ref={elm}
          lang={lang ? lang : undefined}
          readOnly={readonly}
          disabled={disabled}
          {...field}
          onBlur={handleBlur}
          onChange={onChange}
          onKeyDown={ e => {
            if (e.keyCode === 32 && e.ctrlKey === true && props.fetchMany) {
              setSnippet(true)
              // eslint-disable-next-line no-console
              if (!rootRef.current?._internalRoot) {
                rootRef.current = ReactDOM.createRoot(rootel.current)
              }
              rootRef.current.render(
                <SnippetModal
                  rootRef={rootRef.current}
                  parentref={elm}
                  name={name}
                  value={value}
                  modelname={props.modelname}
                  location={props.location}
                  onSelect={debounced}
                  cursorpos={e.target.selectionStart}
                  setSnippet={setSnippet}
                  position={elm.current.selectionStart}
                  fetchMany={props.fetchMany}
                />
              )
            }
          }}
          // value={value} // Cannot set value otherwise debounce breaks
          className="form-control"
          defaultValue={tvalue === 0 || tvalue ? tvalue : ''}
        ></textarea>
        <div ref={rootel} id={`input-${slugify(name)}-snippet-modal`} className={`snippet-modal${snippet ? ' showing' : ''}`}></div>
        {}
        <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, label)}</div> } />
      </div>
      {props.counter &&
        <InputCounter parentRef={elm} {...props.counter} />
      }
      {[ 'residential', 'commercial', 'holiday' ].includes(props.modelname) && portals && !!portals.find(p => [ 1, 2 ].includes(p.id)) && name === 'description' &&
        <Tip heading='Tip' text='If you intend to feed this listing to Private Property and Property24, please ensure that no contact information is included in your description. Your listing will be blocked.' />
      }
      {props.clevercompose && getIn(cache, `branches.${values.branch}.openai_api_key`) ? (
        <div className="clevercompose-field">
          <Button type="button" className="btn btn-grey" onClick={() => {
            props.toggleWideSidebar('clevercompose-sidebar')
          }}
          >CleverCompose</Button>
          <WideSidebar sidebar="clevercompose-sidebar">
            <CleverComposeSidebar
              field={field}
              key={`dlookup-${field.name}`}
              siblingform={{ values, debounced, setFieldValue, setFieldTouched }}
            />
          </WideSidebar>
        </div>
      ) : null}
    </div>
  )
}

TextAreaInput.propTypes = {
  form: PropTypes.object.isRequired,
  prefix: PropTypes.node,
  aidx: PropTypes.number,
  classes: PropTypes.string,
  copy: PropTypes.string,
  modelname: PropTypes.string,
  field: PropTypes.object.isRequired,
  portals: PropTypes.array,
  counter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  fetchMany: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  location: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  cache: PropTypes.object,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  clevercompose: PropTypes.bool,
  rtl: PropTypes.bool,
  _value: PropTypes.string,
  defaultValue: PropTypes.string,
  lang: PropTypes.string,
  id: PropTypes.string,
  onlyValue: PropTypes.bool
}

export default React.memo(TextAreaInput)
