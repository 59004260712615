import PropTypes from 'prop-types'
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { iOS } from './utils'
import { TreeItem } from './TreeItem'


const animateLayoutChanges = ({ isSorting, wasDragging }) =>
  (isSorting || wasDragging ? false : true)

export default function SortableTreeItem({ id, depth, ...props }) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform
  } = useSortable({
    id,
    animateLayoutChanges
  })
  const style = {
    transform: CSS.Translate.toString(transform)
  }

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners
      }}
      {...props}
    />
  )
}

SortableTreeItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  depth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
