import React from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import { valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import log from '../../logging'
import SingleUser from './SingleUser'


const sendActivation = ({ agent, actions }) => {
  const email = getIn(agent, 'meta.user.email', getIn(agent, 'meta.pending_user.email'))
  const agent_id = getIn(agent, 'id')
  new Promise((resolve, reject) => {
    const values = { email, agent_id }
    return actions.sendActivation({ values, resolve, reject })
  }).catch(e => {
    log.error(e)
  })
}


const sendReset = ({ agent, actions }) => {
  const username = getIn(agent, 'meta.user.email', getIn(agent, 'meta.pending_user.email'))
  new Promise((resolve, reject) => {
    const values = { username }
    return actions.sendReset({ values, resolve, reject })
  }).catch(e => {
    log.error(e)
  })
}

const AgentCard = ({ title, agent, user, bigNumber, showLinks, actions }) => (agent ? (
  <div className="agent-card">
    {title && <h3>{title}</h3>}
    <SingleUser model={agent} />
    <div className="agent-details">
      <h4>{agent.first_name} {agent.last_name}</h4>
      {bigNumber ? (
        <>
          <div>{agent.meta.designation.designation}</div>
          <h1>{agent.cell_number}</h1>
        </>
      ) : (
        <>
          <div>{agent.cell_number}</div>
          <div>{valueFormat('mailto', agent.email)}</div>
        </>
      )}
      {showLinks ? (
        <div className="agent-links">
          {agent.user ? (
            <Button type="button" className="form-control-static btn btn-reset btn-primary" onClick={() => sendReset({ agent, actions })}>Reset Password</Button>
          ) : null}
          {agent.pending_user && user.permissions.includes('user_groups_view') && (user.permissions.includes('users_update') || user.permissions.includes('users_add')) ? (
            <Button type="button" className="form-control-static btn btn-reset btn-primary" onClick={() => sendActivation({ agent, actions })}>Resend Activation</Button>
          ) : null}
        </div>
      ) : null }
    </div>
  </div>
) : null)

AgentCard.propTypes = {
  title: PropTypes.string,
  agent: PropTypes.object,
  user: PropTypes.object,
  bigNumber: PropTypes.bool,
  showLinks: PropTypes.bool,
  actions: PropTypes.object
}

export default AgentCard
