/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, updateModel, fetchMany, notifyUser } from '../actions'
import { CONFIG, MINUSER } from '../selectors'
import ContactCreatorSidebar from '../components/ContactCreatorSidebar'


function mapStateToProps(state) {
  return {
    user: MINUSER(state),
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache'),
    config: CONFIG(state, 'contacts')
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    createModel,
    updateModel,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ContactCreatorSidebar))
