
import React from 'react'
import { getDisplayName } from '../../utils'


export function withDelay(WidgetComponent) {
  return class DelayedComponent extends WidgetComponent {
    static displayName = `DelayedComponent(${getDisplayName(WidgetComponent)})`

    constructor(props) {
      super(props)
      this.state = {
        visible: false
      }
      this.timer = null
    }

    componentDidMount() {
      const { delay } = this.props
      this.timer = setTimeout(() => {
        this.setState({ visible: true })
      }, delay || 0)
    }

    componentWillUnmount() {
      clearTimeout(this.timer)
    }

    render() {
      if (!this.state.visible) { return null }
      return <WidgetComponent {...this.props} />
    }
  }
}

export default withDelay
