/* eslint-disable new-cap */
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Link } from 'react-router-dom'
import { CONFIGS } from '../../../selectors'
import { capitalize, groupBy, resolveError, valueFormat } from '../../../utils'
import * as validate from '../../../validate'
import { Button } from '../../ui/Button'
import WideSidebar from '../../ui/sidebar/WideSidebar'
import { Scrollbar } from '../../ui/Scrollbars'
import Card from '../Card'
import CustomForm from '../forms/CustomForm'
import FieldGroup from '../forms/FieldGroup'
import NoteActivity from '../NoteActivity'
import SingleUser from '../SingleUser'
import Tag from '../Tag'


class NoteView extends React.Component {
  constructor(props) {
    super(props)
    const initvals = {
      modelname: 'notes'
    }
    const fields = []
    props.notesconfig.config.fields.forEach(field => {
      if (field.name === 'category') {
        initvals.category = 'Comment'
      }
      field = { ...field, edit: false }
      if (field.name === 'content') {
        if (typeof props.comment === 'object') {
          initvals.content = props.comment.content
          initvals.id = props.comment.id
        }
        field.label = null
        field.placeholder = 'Comment'
        field.edit = true
        fields.push(field)
      }
    })
    this.state = {
      initvals,
      fields,
      offset: 0,
      comment: props.note,
      relations: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFiles = this.setFiles.bind(this)
    this.loadAssociations = this.loadAssociations.bind(this)
    this.toggleNoteActivity = this.toggleNoteActivity.bind(this)
    this.deleteNote = this.deleteNote.bind(this)
  }

  componentDidMount() {
    const { note } = this.props
    this.root = document.getElementById('wrapper')
    const initvals = {
      modelname: 'notes',
      obj_model: 'comments',
      obj_id: getIn(note, 'id')
    }
    const fields = []
    this.props.notesconfig.config.fields.forEach(field => {
      if (field.name === 'category') {
        initvals.category = 'Comment'
      }
      field = { ...field, edit: false }
      if (field.name === 'content') {
        if (typeof this.props.comment === 'object') {
          initvals.content = this.props.comment.content
          initvals.id = this.props.comment.id
        }
        field.label = null
        field.placeholder = 'Comment'
        field.edit = true
        fields.push(field)
      }
    })
    this.setState({ initvals, fields })
    this.loadAssociations()
  }

  componentDidUpdate(prevProps) {
    const { note } = this.props
    const { note: old_note } = prevProps
    if (getIn(note, 'id') !== getIn(old_note, 'id')) {
      const initvals = {
        modelname: 'notes',
        obj_model: 'comments',
        obj_id: getIn(note, 'id')
      }
      const fields = []
      this.props.notesconfig.config.fields.forEach(field => {
        if (field.name === 'category') {
          initvals.category = 'Comment'
        }
        field = { ...field, edit: false }
        if (field.name === 'content') {
          if (typeof this.props.comment === 'object') {
            initvals.content = this.props.comment.content
            initvals.id = this.props.comment.id
          } else {
            initvals.content = ''
          }
          field.label = null
          field.placeholder = 'Comment'
          field.edit = true
          fields.push(field)
        }
      })
      this.setState({ initvals, fields })
    }
    if (note && !isEqual(note, old_note)) {
      const relations = getIn(note, 'relations', [])
      if (relations.length) {
        this.loadAssociations()
      }
    }
    if (this.el) {
      const offset = this.el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  deleteNote(note, comment) {
    new Promise((resolve, reject) => this.props.actions.deleteModel({
      values: {
        modelname: 'notes',
        selected: [ note.id ]
      },
      resolve,
      reject
    })).then(() => {
      if (comment) {
        return new Promise((resolve, reject) => this.props.actions.fetchOne('notes', this.props.note.id, resolve, reject))
      }
      return this.props.actions.unsetNote()
    }).catch(() => {})
  }

  toggleNoteActivity(note) {
    this.setState({ note, activity: !this.state.activity })
    this.props.actions.toggleWideSidebar('show-activity-sidebar')
  }

  setFiles(newfiles) {
    this.setState({ newfiles })
  }

  loadAssociations() {
    const { note, configs, match } = this.props
    if (!note) { return }
    const related = note.relations.length ? groupBy(note.relations.filter(r => r.related_model !== getIn(match, 'params.model') && r.related_id !== parseInt(getIn(match, 'params.id'), 10)), 'related_model') : null
    if (related) {
      const fetches = Object.keys(related).filter(k => k && k !== 'undefined').map(modelname => {
        const values = {
          modelname: modelname,
          conflicts: true,
          params: {
            id__in: related[modelname].map(r => r.related_id)
          }
        }
        if ([ 'profiles', 'leads' ].includes(modelname)) {
          values.params.meta_fields = [ 'contact' ]
        }
        return new Promise((resolve, reject) => this.props.actions.fetchMany({ values, resolve, reject })).then(r => {
          const mapped_relations = r.map(obj => {
            const orig_field = configs[modelname].fields.find(f => isEqual(f.name, configs[modelname].singlekey))
            const orig_cached = obj
            if (orig_cached && orig_field) {
              let orig_value = orig_cached[orig_field.name]
              if (Array.isArray(orig_field.name)) {
                orig_value = orig_field.name.map(f => orig_cached[f]).join(' ')
              }
              const rel = {
                label: `${orig_value} (${capitalize(configs[modelname].singular)})`,
                modelname: modelname,
                id: obj.id
              }
              return rel
            }
            return null
          })
          return mapped_relations
        }).catch(e => (
          console.error(e)
        ))
      })
      let relations = []
      Promise.allSettled(fetches).then(results => {
        results.forEach(r => { relations = relations.concat(r.value) })
        this.setState({ relations })
      })
    }
  }

  handleSubmit(values, actions) {
    Object.keys(values).forEach(k => {
      const field = this.props.notesconfig.config.fields.find(f => f.name === k)
      if (field && field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }
    })
    delete values.created
    delete values.modified
    return new Promise((resolve, reject) => this.props.actions.createModel({ values, resolve, reject })).then(r => {
      const { field, siblingform } = this.props
      actions.setSubmitting(false)
      actions.setTouched({})
      actions.resetForm()
      if (siblingform) {
        siblingform.setFieldValue(field.name, r.id).then(() => {
          siblingform.setFieldTouched(field.name)
        })
      }
      new Promise((resolve, reject) => {
        const params = {
          modelname: 'notes',
          meta_fields: [ 'files' ],
          params: {
            obj_model: this.props.note.obj_model,
            obj_id: this.props.note.obj_id,
            id: this.props.note.id
          },
          offset: 0,
          limit: 10
        }
        this.setState({ loading: true })
        return this.props.actions.fetchMany({ values: params }, resolve, reject)
      }).then(() => {
        this.fetchAgents()
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
      this.setState({ initvals: { ...this.state.initvals, content: '' } })
    }).catch(e => {
      actions.setSubmitting(false)
      actions.setTouched({})
      this.props.actions.notifyUser({ title: 'Error', body: resolveError(e, this.props.notesconfig.config.fields) || e.error, type: 'error' })
    })
  }

  render() {
    const { user, note, model, actions, config, notesCount } = this.props
    if (!note && notesCount) {
      return (
        <div ref={el => (this.el = el)} className="notes-view no-notes">
          <span>Select a note to view more details.</span>
        </div>
      )
    } else if (!note && !notesCount) {
      return (
        <div ref={el => (this.el = el)} className="notes-view no-notes">
          <span>No notes here yet.</span>
        </div>
      )
    }
    const created = valueFormat('datetime', note.created)
    let dated = false
    if (note.note_date) {
      dated = ''
      if (note.status && note.status === 'Scheduled') {
        dated = 'Due '
      }
      dated += valueFormat('date', note.note_date)
      if (note.start) {
        dated += ` ${note.start.slice(0, -3)}`
        if (note.end) { dated += ` - ${note.end.slice(0, -3)}` }
      }
    }
    const modified = valueFormat('datetime', note.modified)
    const agent = getIn(this.props.cache.agents, note.agent_id, getIn(this.props.note.meta, 'agent_id'))
    return (
      <div className="notes-view" ref={el => (this.el = el)}>
        <Scrollbar
          style={{ height: `calc(100vh - ${this.state.offset}px)` }}
          innerRef={el => { this.index = el }}
        >
          <Card
            background
            body={(
              <div className="notes-view-notes">
                <div className="notes-view-note">
                  {note.agent_id !== null && agent &&
                    <div className="notes-view-agent">
                      <SingleUser small={true} model={agent} />
                    </div>
                  }
                  {note.user_id !== null && !note.agent_id &&
                    <div className="notes-view-agent">
                      <div className="single-user agent" title="System User">
                        <div className='thumb small avatar'>
                          <div className="thumbimg">
                            <svg viewBox="0 0 24 24">
                              <use href="/images/icons-24.svg#icon24-Cog" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className='notes-view-body'>
                    <div className="notes-view-top">
                      {note.agent_id !== null && agent &&
                        <div className="notes-view-author">
                          {note.user_id === user.id ? 'You' : `${agent.first_name} ${agent.last_name}`}
                        </div>
                      }
                      {note.user_id !== null && !note.agent_id &&
                        <div className="notes-view-author">
                          System User
                        </div>
                      }
                      <div className="notes-view-date">
                        <div>Created {created}</div>
                        {dated ? <div>Due {dated}</div> : null }
                      </div>
                    </div>
                    <div className="notes-summary-tag-name">
                      <Tag key={`notes-${note.category}`} value={`${note.category}`} />
                      {note.status &&
                      <Tag className={note.status} key={`notes-${note.status}`} value={`${note.status === 'Scheduled' && (new Date()) > (new Date(`${note.note_date}T${note.start}`)) ? 'Overdue' : note.status}`} />
                      }
                    </div>
                    <div className="notes-view-content">
                      {valueFormat('linebreakdomstring', note.content)}
                    </div>
                    <div className="notes-view-bottom">
                      {note.user_id === user.id && <Button title="Edit" className="btn btn-text btn-icon-only" type='button' onClick={() => this.props.actions.editNote(note)}>Edit</Button>}
                      {note.user_id === user.id && <Button title="Delete" className="btn btn-text btn-icon-only" type='button' onClick={() => this.deleteNote(note)}>Delete</Button>}
                      {modified !== created && <Button title="View Activity" className="btn btn-text btn-icon-only" type='button' onClick={() => this.toggleNoteActivity(note)}>View Activity</Button>}
                    </div>
                    {note.files && note.files.length ? (
                      <div className="notes-view-attachments">
                        <div className="icon">
                          <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Paperclip" /></svg>
                        </div>
                        <div className="list">
                          {note.meta.files && note.meta.files.map(file => {
                            let filename = file.caption
                            if (!filename) {
                              if (typeof file.file === 'string') {
                                filename = file.file.split('/').pop()
                              } else if (file.filename) {
                                filename = file.filename
                              }
                            }
                            return <div key={`file-${file.id}`}><a className='has-link' href={file.file} target="_blank" rel='noopener noreferrer'>{filename}</a></div>
                          })}
                        </div>
                      </div>
                    ) : null}
                    { note.private ? <div className="notes-view-private-footer">
                      <div className="icon">
                        <svg viewBox="0 0 16 16"><use href="/images/icons-16.svg#icon16-EyeClosed" /></svg>
                      </div>
                      <div className="list">Private</div>
                    </div> : null }
                  </div>
                </div>
                {note.comments?.length ? (
                  note.comments.map((comment, cidx) => {
                    const comment_created = valueFormat('datetime', comment.created)
                    const comment_modified = valueFormat('datetime', comment.modified)
                    const comment_agent = getIn(this.props.cache.agents, comment.agent_id)
                    const agent_block = comment_agent?.id ? (
                      <div className="notes-view-agent">
                        <SingleUser small={true} model={comment_agent} />
                      </div>
                    ) : (
                      <div className="notes-view-agent">
                        <div className="single-user agent" title="System User">
                          <div className='thumb small avatar'>
                            <div className="thumbimg">
                              <svg viewBox="0 0 24 24">
                                <use href="/images/icons-24.svg#icon24-Cog" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    return (
                      <div key={`note-comment-${cidx}`} className="notes-view-comment">
                        <div className="notes-view-body">
                          <div className="notes-view-top">
                            {comment.agent_id !== null && comment_agent &&
                              <div className="notes-view-author">
                                {comment.user_id === user.id ? 'You' : `${comment_agent.first_name} ${comment_agent.last_name}`}
                              </div>
                            }
                            <div className="notes-view-date">
                              {comment_created}
                            </div>
                          </div>
                          <div className="notes-view-content">
                            {valueFormat('linebreakdomstring', comment.content)}
                          </div>
                          <div className="notes-view-bottom">
                            {comment.user_id === user.id && <Button title="Edit Comment" className="btn btn-text btn-icon-only" type='button' onClick={() => this.props.actions.editNote(comment, comment)}>Edit</Button>}
                            {comment.user_id === user.id && <Button title="Edit Comment" className="btn btn-text btn-icon-only" type='button' onClick={() => this.deleteNote(comment, true)}>Delete</Button>}
                            {comment_modified !== comment_created && <Button title="View Activity" className="btn btn-text btn-icon-only" type='button' onClick={() => this.toggleNoteActivity(comment)}>View Activity</Button>}
                          </div>
                          {comment.files && comment.files.length ? (
                            <div className="notes-view-attachments">
                              <div className="icon">
                                <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Paperclip" /></svg>
                              </div>
                              <div className="list">
                                {note.meta.files.map(file => {
                                  let filename = file.caption
                                  if (!filename) {
                                    if (typeof file.file === 'string') {
                                      filename = file.file.split('/').pop()
                                    } else if (file.filename) {
                                      filename = file.filename
                                    }
                                  }
                                  return <div key={`file-${file.id}`}><a className='has-link' href={file.file} target="_blank" rel='noopener noreferrer'>{filename}</a></div>
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {agent_block}
                      </div>
                    )
                  })
                ) : null }
                <WideSidebar sidebar="show-activity-sidebar">
                  <NoteActivity
                    associations={config.associations || []}
                    actions={actions}
                    model={this.state.note}
                    modelid={model.id}
                    comment={this.state.comment}
                    readOnly={this.state.associations}
                    match={this.props.match}
                    modelconfig={config}
                    config={this.props.notesconfig}
                    toggleNoteActivity={this.toggleNoteActivity}
                  />
                </WideSidebar>
                {this.state.relations.length ? (
                  <div className="notes-view-relations">
                    <h4>Associations</h4>
                    <div className="notes-view-relations-list">
                      {this.state.relations.map((r, ridx) =>
                        (
                          <Link key={`key-relation-${ridx}`} to={{ pathname: `/secure/${note.site}/${r.modelname}/${r.id}` }} className="inner-item" tabIndex={0}>
                            <div className="notes-view-relation">{r.label}</div>
                          </Link>
                        ))}
                    </div>
                  </div>
                ) : null}
                <div className="notes-view-form">
                  <Formik
                    initialValues={this.state.initvals}
                    enableReinitialize
                    validationSchema={validate[this.props.notesconfig.config.modelname]}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={this.handleSubmit}
                  >{ formik => {
                      this.form = formik
                      return (
                        <CustomForm
                          component="div"
                          render={() => (
                            <div className="notes-view-form-content">
                              { Object.keys(this.props.notesconfig.config.fieldgroups).map((group, gidx) => (
                                <FieldGroup
                                  key={`fs-${gidx}`}
                                  groupname={group}
                                  group={this.props.notesconfig.config.fieldgroups[group]}
                                  gidx={gidx}
                                  classes={this.props.notesconfig.config.fieldgroups[group].classes}
                                  fields={this.state.fields.filter(field => field.group === group).map(f => {
                                    const newfield = merge({}, f)
                                    if (newfield.createnew) { newfield.createnew = false }
                                    if (newfield.twin) { newfield.readonly = true }
                                    if ([ 'note', 'images' ].includes(f.name)) {
                                      newfield.setFiles = this.setFiles
                                      newfield.newfiles = this.state.newfiles
                                    }
                                    if (f.name === 'relations') {
                                      f.match = this.props.match
                                      f.related = this.props.note
                                    }
                                    return newfield
                                  })}
                                  modelname={this.props.config.modelname}
                                  modelid={this.props.note ? this.props.note.id : null}
                                  match={this.props.match}
                                  required={this.state.required}
                                  columns
                                  collapsed={this.state.collapsed}
                                  render={({ renderFieldGroup, hidden }) => {
                                    if (hidden) { return null }
                                    return (
                                      <fieldset className="editgroup">
                                        {renderFieldGroup(group)}
                                      </fieldset>
                                    )
                                  }}
                                />
                              ))}
                              {!this.props.readOnly &&
                                <div className="notes-view-form-footer">
                                  <Button
                                    onClick={formik.submitForm} // Required for type="button"
                                    type="button" // This cannot be submit otherwise sibling form is submitted
                                    disabled={formik.isSubmitting}
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              }
                            </div>
                          )}
                        />
                      )
                    }}
                  </Formik>
                </div>
              </div>
            )}
          />
        </Scrollbar>
      </div>
    )
  }
}

NoteView.propTypes = {
  readOnly: PropTypes.bool,
  notesconfig: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  note: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  siblingform: PropTypes.object,
  field: PropTypes.object,
  comment: PropTypes.object,
  editNote: PropTypes.func,
  notesCount: PropTypes.number
}

const mapStateToProps = state => ({
  configs: CONFIGS(state)
})


export default connect(mapStateToProps, null)(withImmutablePropsToJS(NoteView))
