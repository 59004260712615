import React from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { ErrorMessage, Field } from 'formik'

import HelpText from '../../HelpText'
import Select from './Select'
import Text from './Text'


class CountryCodePhoneInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: props.options
    }
  }

  render() {
    const {
      field: {
        name,
        value,
        ...field
      },
      form: {
        touched,
        handleBlur,
        handleChange,
        setFieldTouched
      },
      label,
      className,
      type,
      id,
      country_code_default,
      ...props
    } = this.props
    let val = value
    const prefix = getIn(this.props.form.values, 'country_code')
    if (prefix && !val) { val = prefix }
    return (
      <div id={id} className={`form-group country-code-phone ${name} ${props.classes ? props.classes : ''}`}>
        <div className="input-group">
          { this.state.options &&
            <Field
              id='country_code'
              name='country_code'
              placeholder='Code'
              label="Country Code" // If set to tel then mobile phones show number selector when editing
              classes="col-lg-4"
              defaultvalue={country_code_default}
              options={this.state.options}
              component={Select}
              match={this.props.match}
            />
          }
          <Field
            id={name}
            name={name}
            label={label} // If set to tel then mobile phones show number selector when editing
            type={type} // If set to tel then mobile phones show number selector when editing
            placeholder={props.placeholder}
            classes="col-lg-8"
            className={`form-control ${className ? className : ''}`}
            {...field}
            onBlur={handleBlur}
            onChange={ e => {
              if (!Object.keys(touched).length) { setFieldTouched(field.name) }
              handleChange(e)
            }}
            defaultValue={props.defaultValue}
            _value={val || ''}
            value={val || ''}
            component={Text}
          />
          <ErrorMessage component="div" className="error" name={name} />
        </div>
        {props.help &&
          <span className="help-text">
            {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
          </span>
        }
      </div>
    )
  }
}

CountryCodePhoneInput.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  country_code_default: PropTypes.string
}

export default CountryCodePhoneInput
