/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'


import BarGraph from '../ui/graphs/BarGraph'
import { fetchBranchStatistics, fetchListingAnalysis, fetchMany } from '../../actions'
import date_options from '../../config/date-options.json'
import { SETTINGS, MINUSER } from '../../selectors'
import { hasPermission, textToDate, valueFormat, buildOptionLabel, getRandomColor } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import Loader from '../common/Loader'
import withDelay from './withDelay'


const renderColorfulLegendText = value => <span style={{ color: '#5A6F89' }}>{value}</span>

const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}


const KEYS = [
  'rfs_ac_value',
  'rfs_pe_value',
  'rfs_va_value'
]

const KEY_LABELS = [
  'Valuation',
  'For Sale',
  'To Let',
  'Pending',
  'Sold',
  'Rented'
]

const MANDATE_TYPES = [
  'Sole',
  'Open',
  'Company Listing',
  'Shared',
  'Expired',
  'MLS',
  'PLN',
  'Referral',
  'Auction'
]

class ListingsValueWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'LAST_365_DAYS')
    const period = option.value
    const { start, end, days } = textToDate(option.value)
    this.state = {
      count: 0,
      negative: false,
      period,
      days,
      branches: [],
      agents: [],
      listing_stats: [],
      mandate_type: '',
      mandate_types: MANDATE_TYPES.map(m => ({ value: m.slice(0, 2).toLowerCase(), label: m })),
      branch_id: props.branches && props.branches.length === 1 ? props.branches[0] : '',
      listing_model: '',
      keys: [ ...KEYS ],
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      loading: true,
      totals: {}
    }
    this.filterListingsBreakdown = this.filterListingsBreakdown.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user, branches } = this.props
    let agent_branches = []
    if (!hasPermission([
      'apply_to_all_branches'
    ], user.permissions, null, user.agent.id)) {
      agent_branches = [ ...user.agent.branches ]
    }
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: 'branches',
        optionvalue: 'id',
        optionlabel: 'name',
        fields: [ 'id', 'name', 'statistics' ],
        active: 1,
        all: true,
        conflict: true,
        params: {
          id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
          order_by: 'name',
          meta_fields: [ 'statistics' ]
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(r => {
      if (this._is_mounted) {
        const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
        this.setState({ branches: r.options, branch_options })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
    this.filterListingsBreakdown()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.branch_id !== this.state.branch_id
      || prevState.mandate_type !== this.state.mandate_type
      || prevState.total !== this.state.total
    ) {
      this.filterListingsBreakdown()
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  filterListingsBreakdown() {
    if (this._is_mounted) {
      this.setState({
        loading: true,
        totals: {}
      })
      const { branch_id } = this.state
      const { sitestats } = this.props
      let { statistics } = this.props
      if (branch_id) {
        statistics = getIn(this.state.branches.find(b => b.id === parseInt(branch_id, 10)), 'meta.statistics')
      }
      const listingstats = [
        [ 'rfs_ac', 'RFS' ],
        [ 'rtl_ac', 'RTL' ],
        [ 'cfs_ac', 'CFS' ],
        [ 'ctl_ac', 'CTL' ],
        [ 'rd', 'RD' ],
        [ 'cd', 'CD' ],
        [ 're', 'RE' ],
        [ 'ce', 'CE' ],
        [ 'h_ac', 'HL' ]
      ]

      const listing_stats = []
      listingstats.forEach(stat => {
        const avg = sitestats.active_branches ? Math.round(sitestats[stat[0]] / sitestats.active_branches) : 0
        listing_stats.push(branch_id ? {
          name: stat[1],
          Branch: getIn(statistics, stat[0], 0),
          'Company Avg': avg
        } : {
          name: stat[1],
          Franchise: getIn(statistics, stat[0], 0),
          'Company Avg': avg
        })
      })
      this.setState({ listing_stats }, () => {
        this.setState({ loading: false })
      })
    }
  }

  render() {
    const { listing_stats, branch_id } = this.state
    const datakeys = branch_id ? [ 'Branch', 'Company Avg' ] : [ 'Franchise', 'Company Avg' ]
    return (
      <Card
        id="listing-value-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding"
        background
        header={
          <>
            <h3>Listings Breakdown</h3>
            <div className="details-section-buttons min-flex tablemeta">
              {this.state.branches.length > 1 ? (
                <div className="filter-branch">
                  <InlineSelect
                    id="branch_id"
                    name="branch_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options.map(b => buildOptionLabel({ optionlabel: 'name' }, b)) ]}
                    defaultValue={{ label: 'All Branches', value: '' }}
                    selectedValue={getIn(this.state, 'branch_id')}
                    onChange={e => {
                      this.setState({ branch_id: e.value })
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        }
        body={
          !this.state.loading ? (
            <div className={`flex-container flex-quality${[ 'residential', 'commercial' ].includes(this.state.listing_model) ? '' : ' flex-3'}`}>
              <BarGraph data={listing_stats} dataKeys={datakeys} dataColors={[ getRandomColor(1), getRandomColor(0) ]}>
                {({ width }) => (
                  <>
                    <Legend verticalAlign={'top'} align={'right'} wrapperStyle={{ paddingBottom: 20 }} iconType="circle" iconSize={9} formatter={renderColorfulLegendText} />
                    <CartesianGrid vertical={false} stroke="#F3F5F8" />
                    <XAxis axisLine={false} dataKey="name" />
                    <YAxis axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
                    <Tooltip cursor={{ fill: '#FAFBFD' }} />
                    {datakeys.map((key, idx) => (
                      <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={[ getRandomColor(1), getRandomColor(0) ][idx]} radius={[ 10, 10, 0, 0 ]} />
                    ))}
                  </>
                )}
              </BarGraph>
            </div>
          ) : <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
        }
      />
    )
  }
}

ListingsValueWidget.propTypes = {
  actions: PropTypes.object,
  currency: PropTypes.string,
  user: PropTypes.object,
  statistics: PropTypes.object,
  sitestats: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const site = SETTINGS(state)
  const currency = site.get('default_currency')
  return ({
    user,
    currency
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, fetchBranchStatistics, fetchListingAnalysis }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(ListingsValueWidget)))
