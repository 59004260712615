import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, FieldArray } from 'formik'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import merge from 'deepmerge'
import classNames from 'classnames'

import { Button } from '../../../ui/Button'
import { useCustomCompareMemo } from '../../../../utils'
import Label from './Label'


const DragHandle = listeners => <Button {...listeners} type="button" icon="#icon24-HandleVertical" className="btn btn-icon-16 btn-text btn-icon-only drag-handle" />

const SortableInput = props => { // Use a class component to focus on mount
  const {
    val,
    idx,
    field,
    form,
    insert,
    remove,
    maxLength,
    setVals,
    vals
  } = props

  const { name } = field
  const { handleBlur } = form

  const refinput = useRef()
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: val })
  const style = {
    transform: CSS.Translate.toString(transform)
  }
  useEffect(() => {
    refinput.current?.focus()
  }, [])

  const [ tvalue, setValue ] = useState(![ undefined, null ].includes(val) ? val : '')
  const b = useRef()

  const onChange = e => {
    setValue(![ undefined, null ].includes(e.target.value) ? e.target.value : '')
  }

  const onBlur = e => {
    setValue(![ undefined, null ].includes(e.target.value) ? e.target.value : '')
    if (b.current) {clearTimeout(b.current)}
    // @ts-ignore
    b.current = setTimeout(() => {
      handleBlur(e)
    }, 150)
  }
  useEffect(() => {
    vals[idx] = tvalue
    setVals(vals)
  }, [ tvalue ])

  useEffect(() => {
    setValue(val)
  }, [ `${val}-${idx}` ])

  return (
    <div {...attributes} style={style} ref={setNodeRef} className="form-control sortable-text">
      <DragHandle {...listeners} />
      <input
        ref={refinput}
        id={`${name}-${idx}`}
        type="text"
        name={`${name}.${idx}`}
        onBlur={onBlur}
        onKeyDown={e => { // Monitor for backspace or delete
          if (e.key === 'Enter') { // Monitor for enter key
            e.preventDefault()
            insert(idx + 1, '') // Add a sortable input
          }
          if (e.key === 'Backspace' || e.key === 'Delete') {
            if (refinput.current.value === '') {
              e.preventDefault()
              remove(idx) // Remove the sortable input
              const refinputs = document.querySelectorAll(`input[name^="${name}"]`)
              if (refinputs[idx - 1]) { // Focus on prior input
                refinputs[idx - 1].focus()
              } else if (refinputs[idx + 1]) { // No prior? Focus on next
                refinputs[idx + 1].focus()
              }
            }
          }
        }}
        onChange={onChange}
        value={tvalue || ''}
        maxLength={maxLength}
      />
      <ErrorMessage component="div" className="error" name={name} />
    </div>
  )
}

SortableInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  insert: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  val: PropTypes.string,
  idx: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  setVals: PropTypes.func,
  vals: PropTypes.array
}

const SortableList = ({
  items,
  field,
  form,
  insert,
  remove,
  maxLength,
  setVals
}) => (
  <div className="forminput">
    <div className="arrayaction">
      <Button type="button" icon="#icon16-Plus" className="btn btn-grey btn-icon btn-icon-left btn-icon-16" onClick={() => {
        insert(!Array.isArray(items) ? 0 : items.length + 1, '')
      }}>Add Feature</Button>
    </div>
    <SortableContext
      strategy={verticalListSortingStrategy}
      items={items || []}
    >
      {items && items.map((val, idx) =>
        <SortableInput
          key={`${field.name}-${val}-${idx}`}
          val={val}
          vals={items}
          setVals={setVals}
          idx={idx}
          index={idx}
          field={field}
          form={form}
          insert={insert}
          remove={remove}
          maxLength={maxLength}
        />
      )}
    </SortableContext>
  </div>
)
SortableList.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  insert: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  items: PropTypes.array,
  maxLength: PropTypes.number,
  setVals: PropTypes.func
}

const SortableTexts = props => {
  const [ vals, setVals ] = useState(props.field.value || [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    props.form.setFieldValue(props.field.name, vals).then(() => {
      props.form.setFieldTouched(props.field.name, true)
    })
  }, [ useCustomCompareMemo(vals) ])

  useEffect(() => {
    setVals(props.defaultValue || [])
  }, [ useCustomCompareMemo(props.defaultValue) ])

  const onDragEnd = event => {
    const oldIndex = event.active.data.current.sortable?.index
    const newIndex = event.over.data.current.sortable?.index

    const newval = merge([], vals)
    const el = newval[oldIndex]
    newval.splice(oldIndex, 1)
    newval.splice(newIndex, 0, el) // Switch the 2 inputs
    setVals(newval)
  }
  const { field, form, label, classes, id, maxlen } = props
  return (
    <FieldArray
      name={field.name}
      render={() => (
        <div id={id} className={classNames('sortabletextinput', 'form-group', classes, field.name)}>
          <Label htmlFor={field.name}>{label}</Label>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[ restrictToVerticalAxis ]}
            onDragEnd={onDragEnd}
          >
            <div className="sortable-text-list">
              <SortableList
                items={vals}
                field={field}
                form={form}
                insert={() => {
                  setVals([ ...vals, '' ])
                }}
                remove={idx => {
                  const vals_copy = vals.slice()
                  vals_copy.splice(idx, 1)
                  setVals(vals_copy)
                }}
                setVals={setVals}
                maxLength={maxlen}
              />
            </div>
          </DndContext>
        </div>
      )}
    />
  )
}

SortableTexts.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  defaultValue: PropTypes.array,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
  maxlen: PropTypes.number,
  id: PropTypes.string
}

export default SortableTexts
