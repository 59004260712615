import React from 'react'


const SectionHeading = heading => (
  (heading.label) ? (
    <div className={`section-heading${heading.hot ? ' hot' : ''}${heading.classes ? ` ${heading.classes}` : ''}`}>
      <h4 className="section-heading-content">{heading.label}</h4>
    </div>
  ) : <div className={`section-heading${heading.hot ? ' hot' : ''}${heading.classes ? ` ${heading.classes}` : ''}`} />
)

export default SectionHeading
