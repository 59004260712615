/* eslint-disable no-use-before-define */
/* eslint-disable no-process-env */
/* eslint-disable new-cap */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { pushAlert, pushMessage, unregisterWebsocket, notifyUser, connectWebsocket, disconnectWebsocket } from '../actions'
import { SOCKETSETTING, TOKEN, WS, ALERTS, MESSAGES, SITE, WS_CONNECT } from '../selectors'


const readyStateMap = {
  [0]: 'Connecting',
  [1]: 'Open',
  [2]: 'Closing',
  [3]: 'Closed',
  [-1]: 'Uninstantiated'
}

const Websocket = ({ server, token, ws: connected, msg, alerts, messages, actions, channel }) => {
  const [ attempts, setAttempts ] = useState(1)
  const [ reconnect, setReconnect ] = useState(true)
  const [ readyState, setReadyState ] = useState('Uninstantiated')
  const [ messageQueue, setMessageQueue ] = useState([])
  const [ missed_heartbeats, setMissedHeartHeats ] = useState(false)
  const ws = useRef(null)
  let timeout = 3000
  const maxRetries = 5
  let timeoutID
  let heartbeat
  const onOpen = useCallback(() => {
    if (ws.current && readyState !== readyStateMap[ws.current.readyState]) {
      setReadyState(readyStateMap[ws.current.readyState])
    }
    if (attempts !== 1) {
      setAttempts(1)
    }
    if (!reconnect) {
      setReconnect(true)
    }
    if (missed_heartbeats !== 0) {
      setMissedHeartHeats(0)
    }
    clearTimeout(timeoutID)
    clearInterval(heartbeat)
    sendHeartBeat()
    heartbeat = setInterval(() => {
      sendHeartBeat()
    }, 35000) // Beat every 35 seconds
    const sendQueue = [ ...messageQueue ]
    while (sendQueue.length) {
      sendMessage(sendQueue.pop())
    }
  }, [ ws, messageQueue, readyState, attempts, reconnect, missed_heartbeats, timeoutID, heartbeat ])

  const onClose = useCallback(() => {
    if (missed_heartbeats !== 0) {
      setMissedHeartHeats(0)
    }
    if (token && token !== 'false' && attempts <= maxRetries && ws.current) { // Don't retry if no token ie. timeout
      timeout = generateInterval(attempts)
      clearTimeout(timeoutID)
      timeoutID = setTimeout(() => {
        const ready = readyStateMap[ws.current.readyState]
        setReadyState(ready)
        setAttempts(attempts + 1)
        if (reconnect && ready === 'Closed') {
          connectWebsocketInstance()
        }
      }, timeout)
    } else {
      disconnectWebsocketInstance()
    }
  }, [ ws.current, missed_heartbeats, timeoutID, reconnect ])

  const onError = useCallback(e => {
    // Do not do anything here.
    // Sockets always fire both the close and error events on error.
    console.error(e)
  })

  const onMessage = useCallback(e => {
    clearTimeout(timeoutID)
    // Executes when a new message is received from the socket server
    if (e.data) { // Message should always be JSON with a data key
      let r
      try {
        r = JSON.parse(e.data)
      } catch (error) {
        return
      }
      if (!r) { return } // What the?

      if (r.action === 'beat') { // We have a heartbeat!
        setMissedHeartHeats(0)
        return
      }

      if (r.action === 'notify') { // We have a received a notification
        let status = 'error'
        if (r.status === 200) { status = 'success' }
        actions.notifyUser({ title: r.response.title, body: r.response.detail, dismiss: true, type: status })
        return
      }

      if (r.status === 202 && alerts.findIndex(a => a.callback_id === r.callback_id) > -1) {
        r.text = r.response.detail
        actions.pushAlert({ payload: r })
      }

      if (r.status === 200 && alerts.findIndex(a => a.callback_id === r.callback_id) > -1) {
        // Successful generation occurred to an item in state
        r.text = r.response.detail
        if (r.name) {
          if (r.response.detail) {
            actions.notifyUser({ title: r.name, body: r.response.detail, dismiss: true, type: 'success' })
          } else {
            actions.notifyUser({ title: r.name, body: 'Your request completed successfully', dismiss: true, type: 'success' })
          }
        } else {
          actions.notifyUser({ title: 'Generation successful', body: 'Click the alerts button above to download your file.', dismiss: true, type: 'success' })
        }
      }

      if (r.status >= 400 && alerts.findIndex(a => a.callback_id === r.callback_id) > -1) {
        // Error occurred
        if (r.name) {
          if (r.response.detail) {
            actions.notifyUser({ title: r.name, body: r.response.detail, dismiss: true, type: 'error' })
          } else {
            actions.notifyUser({ title: r.name, body: 'There was an error processing your request.', dismiss: true, type: 'error' })
          }
        } else {
          actions.notifyUser({ title: 'Generate error', body: 'There was an error generating your file.', dismiss: true, type: 'error' })
        }
      }

      if (r && r.callback_id && messages.findIndex(a => a.callback_id === r.callback_id) === -1) {
        if (
          alerts.findIndex(a => a.callback_id === r.callback_id) > -1 &&
          alerts.find(a => a.callback_id === r.callback_id).resolve && r.status === 200
        ) {
          alerts.find(a => a.callback_id === r.callback_id).resolve(r)
        }
        let payload = r
        if (r.payload) {
          let created = new Date().toISOString()
          if (r.created) { created = new Date(r.created).toISOString() }
          payload = {
            show: true,
            callback_id: r.callback_id,
            name: r.label,
            model: r.payload.model,
            text: 'Requesting...',
            created,
            filters: { ...r.filters },
            template: r.payload.template
          }
        }
        actions.pushAlert({ payload })
      } else if (r && r.callback_id && messages.findIndex(a => a.callback_id === r.callback_id) > -1) {
        if (
          messages.findIndex(a => a.callback_id === r.callback_id) > -1 &&
          messages.find(a => a.callback_id === r.callback_id).resolve && r.status === 200
        ) {
          messages.find(a => a.callback_id === r.callback_id).resolve(r)
        }
        actions.pushMessage({ payload: r })
      }
    }
  }, [ timeoutID, messages ])

  const sendMessage = useCallback(message => {
    if (readyState === 'Open') {
      ws.current.send(message)
    } else {
      setMessageQueue([ ...messageQueue, message ])
    }
  }, [ ws.current, messageQueue ])

  const disconnectWebsocketInstance = useCallback(() => {
    clearTimeout(timeoutID)
    clearInterval(heartbeat)
    ws.current = null
    setReadyState('Uninstantiated')
    setReconnect(false)

    actions.unregisterWebsocket()
  }, [ ws ])

  const connectWebsocketInstance = useCallback(() => {
    if (ws.current) { ws.current.close() }
    let websocket
    if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
      websocket = new WebSocket(`${server.stage}/${token}/`, channel)
    } else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
      websocket = new WebSocket(`${server.live}/${token}/`, channel)
    } else {
      const host = window.location.protocol === 'https' || process.env.REACT_APP_SSL === 'True' ? `wss://${window.location.host}/pdms-api/sockets` : `wss://${window.location.host}/pdms-api/sockets`
      websocket = new WebSocket(`${host}/${token}/`, channel)
    }

    ws.current = websocket
    setReadyState(readyStateMap[websocket.readyState])
  }, [ ws, token ])

  const sendHeartBeat = useCallback(() => {
    try {
      setMissedHeartHeats(missed_heartbeats + 1)
      if (missed_heartbeats >= 3) { throw new Error('Too many missed heartbeats.') }
      const heartbeat_msg = {
        action: 'beat',
        callback_id: uuidv4(),
        created: new Date().toISOString(),
        token: token,
        payload: {}
      }
      sendMessage(JSON.stringify(heartbeat_msg))
    } catch (e) {
      actions.notifyUser({ title: 'Unable to communicate with server', body: 'Please refresh your page.', dismiss: true, type: 'error' })
      disconnectWebsocketInstance()
    }
  }, [ missed_heartbeats ])

  const generateInterval = useCallback(k =>
    /* Interval increases exponentially based on the number of attemps (k).
       * EG.  k = 1 = 1000 milliseconds
       *      k = 2 = 3000 milliseconds
       *      k = 3 = 7000 milliseconds
       *      k = 4 = 15000 milliseconds
       *      k = 5 = 30000 milliseconds
      */
    Math.min(30, (Math.pow(2, k) - 1)) * 1000
  )

  useEffect(() => {
    if (token && token !== 'false' && !ws.current) {
      connectWebsocketInstance()
    }
    return () => {
      disconnectWebsocketInstance()
    }
  }, [])

  useEffect(() => {
    if (ws.current) {
      ws.current.addEventListener('open', onOpen)
      ws.current.addEventListener('close', onClose)
      ws.current.addEventListener('error', onError)
      ws.current.addEventListener('message', onMessage)
    }
    return () => {
      if (ws.current) {
        ws.current.removeEventListener('open', onOpen)
        ws.current.removeEventListener('close', onClose)
        ws.current.removeEventListener('error', onError)
        ws.current.removeEventListener('message', onMessage)
        ws.current.close()
      }
    }
  }, [ ws ])

  useEffect(() => {
    if (readyState === 'Open') {
      clearTimeout(timeoutID)
      if (!connected) {
        actions.connectWebsocket()
      }
    } else if (connected && readyState === 'Closed') {
      actions.disconnectWebsocket()
    }
  }, [ timeoutID, readyState, connected ])

  useEffect(() => {
    if (msg && msg !== -1) {
      // Websocket ready, send message
      sendMessage(msg)
    }
  }, [ msg ])


  return null
}


Websocket.propTypes = {
  token: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  server: PropTypes.object.isRequired,
  onMessage: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  reconnect: PropTypes.bool,
  messages: PropTypes.array,
  channel: PropTypes.string,
  maxRetries: PropTypes.number,
  actions: PropTypes.object,
  alerts: PropTypes.array,
  children: PropTypes.node,
  ws: PropTypes.bool,
  msg: PropTypes.any,
  site: PropTypes.object
}

const mapStateToProps = state => ({
  site: SITE(state),
  server: SOCKETSETTING(state),
  token: TOKEN(state),
  ws: WS_CONNECT(state),
  msg: WS(state),
  alerts: ALERTS(state),
  messages: MESSAGES(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    pushAlert,
    pushMessage,
    connectWebsocket,
    disconnectWebsocket,
    unregisterWebsocket,
    notifyUser
  },
  dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Websocket))
