import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip, Legend, BarChart, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'
import { getTextWidth } from '../../../utils'
import { ResponsiveContainer } from './ResizeContainer'


const renderColorfulLegendText = value => <span style={{ color: '#5A6F89' }}>{value}</span>


export const longestLabelLength = function(data, dataKeys) {
  const length = data ? (
    data
      .map(c => {
        const bigger = Math.max(...dataKeys.map(k => c[k]))
        return (getTextWidth(bigger.toString()) * 1.2) + 5
      })
      .reduce((acc, cur) => (cur > acc ? cur : acc), 0)
  ) : 60
  return length
}

const BarGraph = ({ data, dataKeys, dataColors, children, height: graphHeight }) => (
  <ResponsiveContainer width="100%" height={graphHeight ? graphHeight : 250} minWidth="0">{({ width, height }) => {
    const interval = (width < 750 && data.length > 15) ? parseInt(
      data.length / parseInt(data.length * (width / 750), 10
      ), 10) : 0
    return children ? (
      <BarChart width={750} height={graphHeight ? graphHeight : 250} data={data}>
        {[ ...children({ width, height }).props.children ]}
      </BarChart>
    ) : (
      <BarChart width={750} height={graphHeight ? graphHeight : 250} data={data}>
        <Legend wrapperStyle={{ position: 'absolute', top: 0, right: 0, width: 'auto', display: 'inline-block', transform: 'translateY(-40px)' }} iconType="circle" iconSize={9} formatter={renderColorfulLegendText} />
        <CartesianGrid vertical={false} stroke="#F3F5F8" />
        <XAxis interval={interval} axisLine={false} dataKey="name" />
        <YAxis width={longestLabelLength(data, dataKeys)} axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
        <Tooltip cursor={{ fill: '#FAFBFD' }} />
        {dataKeys.map((key, idx) => (
          <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={dataColors[idx]} radius={[ 10, 10, 0, 0 ]} />
        ))}
      </BarChart>
    )
  }}
  </ResponsiveContainer>
)

BarGraph.propTypes = {
  data: PropTypes.array,
  dataKeys: PropTypes.array,
  dataColors: PropTypes.array,
  children: PropTypes.func,
  height: PropTypes.number
}

export default BarGraph
