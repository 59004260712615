import PropTypes from 'prop-types'
import React from 'react'
import { formatDistanceToNow } from 'date-fns'

import { NavLink } from 'react-router-dom'
import { getIn } from 'formik'
import { isConditional, valueFormat, sortBy, generateAddress } from '../../utils'
import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import DealStage from '../common/DealStage'
import DealSteps from '../common/DealSteps'
import DealCommissionSlip from '../common/DealCommissionSlip'
import FinancialStructure from '../common/FinancialStructure'
import Card from '../common/Card'
import ContactItem from '../common/ContactItem'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'


const Deal = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    match,
    settings,
    routeConfig
  } = props

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      <Tab tab="dashboard" label="Dashboard" noScroll>
        <div className="grid-container grid-1">
          <DealStage {...props} />
        </div>
        <div className="grid-container grid-3">
          <Card
            classes="grid-col-1"
            bodyclass="no-top-padding"
            background
            header={
              <h3>Deal overview</h3>
            }
            body={() => {
              let agent = `System User on ${valueFormat('date', model.created)}`
              if (model.user === user.id) {
                agent = `You on ${valueFormat('date', model.created)}`
              } else if (model.agent) {
                agent = <NavLink className="has-link" to={`/secure/${model.site}/agents/${model.agent}`}>{getIn(model, 'meta.agent.first_name')} {getIn(model, 'meta.agent.last_name')} on {valueFormat('date', model.created)}</NavLink>
              }
              return (
                <div className="deal-columns">
                  <div className="grid-container grid-2">
                    <div className="deal-columns--label">Deal ID</div>
                    <div className="deal-columns--value">{model.id}</div>
                  </div>
                  <div className="grid-container grid-2">
                    <div className="deal-columns--label">Listing ID</div>
                    <div className="deal-columns--value"><NavLink className="has-link" to={`/secure/${model.site}/${model.model_type}/${model.model_id}`}>{getIn(model, 'meta.listing.web_ref')}</NavLink></div>
                  </div>
                  <div className="grid-container grid-2">
                    <div className="deal-columns--label">Created by</div>
                    <div className="deal-columns--value">{agent}</div>
                  </div>
                  <div className="grid-container grid-2">
                    <div className="deal-columns--label">Purchase Price</div>
                    <div className="deal-columns--value large">{valueFormat('currency', model.sale_price, { currency: settings.default_currency })}</div>
                  </div>
                  <div className="grid-container grid-2">
                    <div className="deal-columns--label">Total Commission</div>
                    <div className="deal-columns--value"></div>
                  </div>
                </div>
              )
            }}
          />
          <Card
            classes="grid-col-1"
            bodyclass="no-top-padding"
            background
            header={
              <h3>Suspensive conditions expiring soon</h3>
            }
            body={() => {
              const suspensive_conditions = sortBy([ ...model.suspensive_conditions, ...model.other_conditions ], 'expiry').filter(condition => !condition.date_met)
              const options = config.fields.find(f => f.name === 'suspensive_conditions').fields.find(f => f.name === 'subject_to').options
              return (
                <div className="deal-columns">
                  {suspensive_conditions.map(condition => (
                    <div key={`condition-${condition.id}`} className="grid-container grid-3">
                      <div className="deal-columns--label"><strong>{condition.subject_to ? options.find(o => o.value === condition.subject_to).label : condition.name}</strong></div>
                      <div className="deal-columns--value">{formatDistanceToNow(new Date(condition.expiry).getTime(), { addSuffix: true })}</div>
                      <div className="deal-columns--value">{valueFormat('date', condition.expiry)}</div>
                    </div>
                  ))}
                </div>
              )
            }}
          />
          <Card
            classes="grid-col-1 primary-bg lead-interaction-details"
            bodyclass="no-top-padding"
            background
            header={
              <h3>Property</h3>
            }
            body={() => {
              const listing = getIn(cache, `${model.model_type}.${model.model_id}`)
              if (!listing) {
                return null
              }
              const listing_agents = [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].filter(f => getIn(listing, f)).map(f => `${getIn(listing, `meta.${f}.first_name`)} ${getIn(listing, `meta.${f}.last_name`)}`)
              return (
                <>
                  <div>
                    <strong>Address</strong>
                    <div>{generateAddress(listing)}</div>
                  </div>
                  <div>
                    <strong>Branch</strong>
                    <div>{getIn(listing, 'meta.branch.name')}</div>
                  </div>
                  <div>
                    <strong>Listing Agents</strong>
                    <div>{listing_agents.join(', ')}</div>
                  </div>
                  <div>
                    <strong>Mandate Signed</strong>
                    <div>{valueFormat('date', listing.mandate_start_date)}</div>
                  </div>
                </>
              )
            }}
          />
        </div>
        <div className="grid-container grid-3" style={{ paddingBottom: 60 }}>
          <Card
            classes="grid-col-1"
            background
            header={
              <h3>Sellers</h3>
            }
            body={() => {
              const contacts = [
                'sellers'
              ]
              return (
                <div className="listing-contacts-list">
                  {contacts.filter(f => model[f] !== null).map((f, fid) => {
                    const field = config.fields.find(fe => fe.name === f)
                    if (!field) { return null }
                    field.label = 'Seller'
                    if (Array.isArray(model[f])) {
                      return model[f].map((v, vid) => <ContactItem
                        key={`lc-${fid}-${vid}`}
                        field={field}
                        value={v}
                        index={vid}
                        model={model}
                        cache={cache}
                      />)
                    }
                    return <ContactItem
                      key={`lc-${fid}`}
                      field={field}
                      value={model[f]}
                      model={model}
                      cache={cache}
                    />
                  })}
                </div>
              )
            }}
          />
          <Card
            classes="grid-col-1"
            background
            header={
              <h3>Buyers</h3>
            }
            body={() => {
              const contacts = [
                'buyers'
              ]
              return (
                <div className="listing-contacts-list">
                  {contacts.filter(f => model[f] !== null).map((f, fid) => {
                    const field = config.fields.find(fe => fe.name === f)
                    if (!field) { return null }
                    field.label = 'Buyer'
                    if (Array.isArray(model[f])) {
                      return model[f].map((v, vid) => <ContactItem
                        key={`lc-${fid}-${vid}`}
                        field={field}
                        value={v}
                        index={vid}
                        model={model}
                        cache={cache}
                      />)
                    }
                    return <ContactItem
                      key={`lc-${fid}`}
                      field={field}
                      value={model[f]}
                      model={model}
                      cache={cache}
                    />
                  })}
                </div>
              )
            }}
          />
          <Card
            classes="grid-col-1"
            background
            header={
              <h3>Agents</h3>
            }
            body={() => {
              const contacts = [
                'selling_agents'
              ]
              return (
                <div className="listing-contacts-list">
                  {contacts.filter(f => model[f] !== null).map((f, fid) => {
                    const field = config.fields.find(fe => fe.name === f)
                    if (!field) { return null }
                    if (Array.isArray(model[f])) {
                      return model[f].map((v, vid) => <ContactItem
                        key={`lc-${fid}-${vid}`}
                        field={field}
                        value={v}
                        index={vid}
                        model={model}
                        cache={cache}
                      />)
                    }
                    return <ContactItem
                      key={`lc-${fid}`}
                      field={field}
                      value={model[f]}
                      model={model}
                      cache={cache}
                    />
                  })}
                </div>
              )
            }}
          />
        </div>
      </Tab>

      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>

      <Tab handleUpdate={props.actions.handleUpdate} tab="stage" label="Stage" noScroll>
        <>
          <DealStage {...props} />
          <DealSteps {...props} />
        </>
      </Tab>

      <Tab tab="commission-slip" label="Commission Slip" noScroll>
        <FinancialStructure {...props} />
        <DealCommissionSlip {...props} />
      </Tab>

      <Tab tab="documents" label="Documents" noScroll>
        <MediaGallery
          media={[
            {
              items: model.meta.seller_documents,
              mediatype: 'documents',
              fieldname: 'seller_documents',
              plural: 'seller documents',
              label: 'Seller Documents'
            }, {
              items: model.meta.buyer_documents,
              mediatype: 'documents',
              fieldname: 'buyer_documents',
              plural: 'buyer documents',
              label: 'Buyer Documents'
            }, {
              items: model.meta.sale_agreement_documents,
              mediatype: 'documents',
              fieldname: 'sale_agreement_documents',
              plural: 'sale agreement documents',
              label: 'Sale Agreement Documents'
            }
          ]}
          actions={{
            downloadImages: actions.downloadImages,
            isConditional
          }}
          config={config}
          user={user}
          model={model}
          modelname={config.modelname}
        />
      </Tab>

      <Tab tab="notes" label="Notes / Actions">
        <Notes
          config={config}
          model={model}
          user={user}
          match={match}
          actions={actions}
          cache={cache}
        />
      </Tab>

      <Tab tab="activity" label="Activity">
        <Activity
          model={model}
          cache={cache}
          config={config}
          events={model.activity}
          user={user}
          settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
          agents={cache && cache.agents ? cache.agents : {}}
          fetchActivity={actions.fetchActivity}
          fetchOne={actions.fetchOne}
          fetchMany={actions.fetchMany}
        />
      </Tab>

    </HorizontalTabs>

  )
}

Deal.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  routeConfig: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  settings: PropTypes.object
}

export default Deal
