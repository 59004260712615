import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import CustomForm from '../forms/CustomForm'

import { logEvent, updateSearchParms } from '../../../utils'


class PageJump extends React.Component {
  constructor() {
    super()
    this.isEnter = this.isEnter.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.calculatePages = this.calculatePages.bind(this)
    this.state = {
      page: 1,
      pages: 1
    }
  }

  componentDidUpdate() {
    this.calculatePages()
  }

  calculatePages() {
    if (this.props.params) {
      const { offset, limit } = this.props.params
      const { count } = this.props
      let page = parseInt(this.state.page, 10)
      const pages = Math.ceil(count / limit)
      if (offset && limit) {
        page = Math.floor(offset / limit) + 1
      } else {
        page = 1
      }
      if (page !== this.state.page || pages !== this.state.pages) {
        this.setState({ page, pages })
      }
    }
  }

  goToPage(values) {
    if (this.props.params) {
      const { count } = this.props
      const offset = (Math.max(values.page, 1) - 1) * this.props.params.limit
      if (offset <= count) {
        updateSearchParms('offset', offset)
        logEvent('PAGE_JUMP', { modelname: this.props.modelname, page: values.page })
      }
    }
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.goToPage({ page: e.target.value })
    } // continue typing
    return true
  }

  render() {
    if (this.props.count) {
      return (
        <div className="pagejump">
          <Formik
            enableReinitialize={true}
            onSubmit={this.goToPage}
            initialValues={{
              page: this.state.page
            }}
          >{ () => (
              <CustomForm
                component="div"
                render={() => (
                  <>
                    <span>Page</span>
                    <div className="form-group page">
                      <div className="forminput">
                        <Field
                          component="input"
                          id="page"
                          name="page"
                          type="number"
                          className="form-control form-control-lg page-jump"
                          onKeyDown={this.isEnter}
                          min={1}
                        />
                      </div>
                    </div>
                    <span>of {this.state.pages}</span>
                  </>
                )}
              />
            )}
          </Formik>
        </div>
      )
    }
    return null
  }
}

PageJump.propTypes = {
  params: PropTypes.object,
  count: PropTypes.number,
  modelname: PropTypes.string,
  endpoint: PropTypes.object
}

export default PageJump
