/* eslint-disable new-cap */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { getIn } from 'formik'
import isEqual from 'react-fast-compare'


import SingleUser from '../common/SingleUser'
import { CONFIGS } from '../../selectors'
import { valueFormat, isConditional } from '../../utils'
import Card from './Card'


class ActivityEvent extends React.Component {
  constructor(props) {
    super(props)
    this.shouldLoadUser = this.shouldLoadUser.bind(this)
    this.formatValue = this.formatValue.bind(this)
    this.getChanges = this.getChanges.bind(this)
    this.processResults = this.processResults.bind(this)
    this.isConditional = isConditional.bind(this)
    this.state = {
      changes: [],
      result: null
    }
  }

  componentDidMount() {
    this.shouldLoadUser()
    this.getChanges()
    if (!this.props.hasEvents) { this.props.setHasEvents() } // Activate timeline bar
    const { event } = this.props

    if (event && event.diff) {
      Object.keys(event.diff).filter(key => ![ 'statistics' ].includes(key)).forEach(field => {
        if (field === 'relations') {
          const changes = event.diff[field]
          const modeldiff = {}
          changes.filter(c => c).forEach(c => {
            c.forEach(v => {
              if (v.related_model && !modeldiff[v.related_model]) {
                modeldiff[v.related_model] = []
              }
              if (v.related_model && v.related_id) {
                modeldiff[v.related_model].push(v.related_id)
              }
            })
          })
        }
      })
    }
    this.props.worker.addEventListener('message', this.processResults)
  }

  componentDidUpdate(prevProps) {
    this.shouldLoadUser()
    if (this.state.result) {
      this.processResults(this.state.result)
    }
    if (!isEqual(this.props.cache, prevProps.cache) && this.state.result) {
      this.processResults(this.state.result)
    }
  }

  componentWillUnmount() {
    this.props.worker.removeEventListener('message', this.processResults)
  }

  processResults(result) {
    if (result.data.id !== this.props.event.id) {
      return
    }
    if (!this.state.result) {
      this.setState({ result: { data: { changes: result.data.changes, id: result.data.id } } })
    }
    const changes = result.data.changes.map((change, cidx) => {
      if (!change.length) { return null }
      return change.map(({ kind, lhs, rhs, label, conf, array }, ccidx) => this.processChanges({
        kind,
        lhs: this.formatValue(lhs, conf),
        rhs: this.formatValue(rhs, conf),
        label: conf.tablelabel ? conf.tablelabel : label,
        key: `change-${cidx}-${ccidx}`,
        array
      }))
    })
    if (!isEqual(this.state.changes, changes)) {
      this.setState({ changes })
    }
  }

  shouldLoadUser() {
    const { event, agents, user } = this.props
    if (!user && agents) {
      if (!agents[event.agent_id] && event.agent_id) {
        this.props.fetchOne('agents', event.agent_id)
      }
    }
  }

  getChanges() {
    const { model, event, user: curuser, cache, configs } = this.props
    let { config } = this.props
    // We need to send the worker the fieldconfigs dict as the worker cannot access
    // this which is required by isConditional in order to decide which field actually
    // changed as some fields have the same name. ie. price and rental price.
    const fieldconfigs = {}
    if (!event.diff) { return }
    if (event.diff.variables) { // Handle page variables exception
      Object.keys(event.diff.variables[0]).forEach(k => {
        event.diff[k] = []
        event.diff[k][0] = event.diff.variables[0][k]
        event.diff[k][1] = event.diff.variables[1][k]
      })
      delete event.diff.variables
    }
    if (Object.keys(event.diff).length === 1) {
      if (event.model === 'contactconsent') {
        fieldconfigs[Object.keys(event.diff)[0]] = configs.contactconsent.fields.find(f => {
          Object.keys(event.diff).includes(f.name)
        })
      } else {
        fieldconfigs[Object.keys(event.diff)[0]] = config.fields.find(f => f.name === Object.keys(event.diff)[0])
      }
    } else { // More than one change in the diff
      Object.keys(event.diff).forEach(field => {
        const namedconfigs = config.fields.filter(f => f.name === field)
        const mockform = {
          touched: { },
          values: { ...model }
        }
        if (event.model === 'contactconsent') {
          fieldconfigs[field] = configs.contactconsent.fields.find(f => f.name === field)
        } else if (namedconfigs.length > 1) {
          fieldconfigs[field] = namedconfigs.find(f => this.isConditional(f, 'edit', false, mockform, curuser))
        } else {
          fieldconfigs[field] = config.fields.find(f => f.name === field)
        }
      })
    }
    let portal
    let globalportal
    if (event.model === 'portalconfig') {
      const portal_id = event.obj_id
      portal = model.meta.portals.find(p => p.id === portal_id)
      if (portal) {
        globalportal = cache.settings[curuser.agent.site.id]?.portals?.global?.find(p => p.id === portal.portal)
      }
    }
    if (event.model === 'comment') {
      config = configs.notes
    }
    this.props.worker.postMessage({
      model, event, user: curuser, cache, configs, config, fieldconfigs, globalportal
    })
  }

  processChanges({ kind, lhs, rhs, label, key, array }) {
    if (kind === 'N' || [ null, '', undefined ].includes(lhs)) {
      if (!rhs) { return null }
      if (array) {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;<code>{Array.isArray(rhs) ? rhs.join(', ') : rhs}</code> was added</span>
          </div>
        )
      }
      if (getIn(rhs, 'type') === 'ul') {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;was set to <pre>{Array.isArray(rhs) ? rhs.join(', ') : rhs}</pre></span>
          </div>
        )
      }
      if (rhs === true) {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;was checked</span>
          </div>
        )
      }
      if (rhs === false) {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;was unchecked</span>
          </div>
        )
      }
      return (
        <div key={key}>
          <strong>{label}</strong>
          <span>&nbsp;was set to <code>{Array.isArray(rhs) ? rhs.join(', ') : rhs}</code></span>
        </div>
      )
    } else if (kind === 'N' || [ null, '', undefined ].includes(rhs)) {
      if (!lhs) { return null }
      if (array) {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;<code>{Array.isArray(rhs) ? rhs.join(', ') : rhs}</code> was removed</span>
          </div>
        )
      }
      if (getIn(lhs, 'type') === 'ul') {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;<pre>{Array.isArray(lhs) ? lhs.join(', ') : lhs}</pre>&nbsp;was removed</span>
          </div>
        )
      }
      return (
        <div key={key}>
          <strong>{label}</strong>
          <span>&nbsp;<code>{Array.isArray(lhs) ? lhs.join(', ') : lhs}</code>&nbsp;was removed</span>
        </div>
      )
    } else if (kind === 'E') {
      if (array) {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;<code>{lhs}</code> was removed and <code>{rhs}</code> was added</span>
          </div>
        )
      }
      if (getIn(lhs, 'type') === 'ul' || getIn(rhs, 'type') === 'ul') {
        return (
          <div key={key}>
            <strong>{label}</strong>
            <span>&nbsp;was updated from <pre>{lhs}</pre> to <pre>{rhs}</pre></span>
          </div>
        )
      }
      return (
        <div key={key}>
          <strong>{label}</strong>
          <span>&nbsp;was updated from <code>{lhs}</code> to <code>{rhs}</code></span>
        </div>
      )
    }
    return null
  }

  formatValue(value, field) {
    const { settings, cache } = this.props
    let val = value
    if (!field) { return val }
    if (field.format && ![ 'profile_photo', 'listing_popup', 'contact_popup', 'image', 'whatsapp', 'domstring', 'stage' ].includes(field.format)) {
      if ([ 'currency', 'currencyabbr', 'price_range', 'listing_popup' ].includes(field.format)) {
        field.currency = settings.currency
      }
      val = valueFormat(field.format, value, field)
    }
    if (field.format === 'link' && field.link) {
      val = value
    }
    if (value && field.modelname && cache[field.modelname]) {
      if (Array.isArray(value)) {
        val = value.map(v => {
          const related_model = getIn(cache, `${field.modelname}.${v}`)

          if (!related_model) {
            return v
          }
          if (Array.isArray(field.optionlabel)) {
            const vals = field.optionlabel.map(l => related_model[l])
            return vals.join(field.labelseparator || ', ')
          } else if (field.optionlabel) {
            return related_model[field.optionlabel]
          }
          return null
        }).filter(v => v).join(', ')
      } else {
        const related_model = getIn(cache, `${field.modelname}.${value}`)
        if (!related_model) { return value }
        if (Array.isArray(field.optionlabel)) {
          const vals = field.optionlabel.map(l => related_model[l])
          val = vals.join(field.labelseparator || ', ')
        } else if (field.optionlabel) {
          val = related_model[field.optionlabel]
        }
      }
    }
    if (field.input === 'FieldArray' && typeof val === 'object') {
      val = JSON.stringify(val)
    }
    return val
  }

  render() {
    const { event, user: curuser, date, newmonth, cache } = this.props
    const { changes } = this.state
    if (!event || !event.diff) { return null }
    if (!changes.filter(c => c).length) { return null }
    return (
      <div className="timeline-month" key={`tdate-${date}`}>
        {newmonth &&
          <h2 className="offset-1 col-11 timeline-heading">{date}</h2>
        }
        <div className="timeline-event">
          <div className="timeline-event-day col-1"></div>
          <div className="timeline-event-card col-auto">
            <div className="timeline-event-card-header">
              {valueFormat('datetime', event.created)}
            </div>
            <Card
              background
              body={
                <div className="timeline-event-card-body">
                  <div className="agent">
                    {event.agent && cache.agents[event.agent] ? (
                      <SingleUser small model={cache.agents[event.agent]} />
                    ) : null}
                    {!event.agent ? (
                      <div className="single-user agent">
                        <div className='thumb small avatar'>
                          <div className="thumbimg">
                            <svg viewBox="0 0 24 24">
                              <use href="/images/icons-24.svg#icon24-Cog" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="timeline-event-card-right">
                    <div className="timeline-event-card-changes">
                      {changes}
                    </div>
                    <div className="timeline-event-card-footer">
                      {!event.agent ?
                        <div className="noteauthor">{event.activity_type === 'new-item' ? `Created by system${event.user ? ` (UID ${event.user})` : ''}` : `Changes made by system${event.user ? ` (UID ${event.user})` : ''}`}</div>
                        : null}
                      {event.agent && curuser.agent.id === event.agent ?
                        <div className="noteauthor">{event.activity_type === 'new-item' ? 'Created by you' : 'Changes made by you'}</div>
                        : null}
                      {event.agent && cache.agents[event.agent] && curuser.agent.id !== event.agent ? (
                        <div className="noteauthor">{
                          event.activity_type === 'new-item' ? `Created by ${cache.agents[event.agent].full_name} (UID ${event.user})` : `Changes made by ${cache.agents[event.agent].full_name} (UID ${event.user})`
                        }</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

ActivityEvent.propTypes = {
  model: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  event: PropTypes.object,
  newmonth: PropTypes.bool,
  settings: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  agents: PropTypes.object,
  date: PropTypes.string,
  fetchOne: PropTypes.func,
  fetchMany: PropTypes.func,
  hasEvents: PropTypes.bool,
  setHasEvents: PropTypes.func,
  worker: PropTypes.object
}

const mapStateToProps = state => ({
  configs: CONFIGS(state)
})

export default connect(mapStateToProps, null, null)(withImmutablePropsToJS(ActivityEvent))
