import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import { Scrollbar } from '../ui/Scrollbars'
import { Button } from '../ui/Button'
import ContextMenu from '../../containers/ContextMenu'
import { isInViewport } from '../../utils'


const ModelActions = props => {
  const [ scroll, setScroll ] = useState(false)
  const [ inView, setInView ] = useState([])
  const [ outView, setOutView ] = useState([])
  const [ scrollLeft, setScrollLeft ] = useState(0)
  const el = useRef()
  const scroller = useRef()


  const scrollLeftStart = () => {
    if (!el.current) { return }
    const inner = el.current.querySelectorAll('.action-list')[0]
    const elements = Array.prototype.slice.call(inner.children)
    if (outView.length) {
      const firstInView = Math.min(...inView)
      const prevOutView = Math.max(0, firstInView - 1)
      if (firstInView > prevOutView) {
        const el1 = elements[prevOutView]
        const parent = el1.offsetParent.getBoundingClientRect()
        let scrollTo = el1.getBoundingClientRect().left - 66
        if (parent.left) {
          scrollTo = 0 - parent.left - el1.getBoundingClientRect().left + 66
        }
        if (prevOutView === 0) { scrollTo -= 9999 }
        setScrollLeft(scrollTo)
      }
    }
  }

  const scrollRightStart = () => {
    if (!el.current) { return }
    const inner = el.current.querySelectorAll('.action-list')[0]
    const elements = Array.prototype.slice.call(inner.children)
    if (outView.length) {
      const lastInView = Math.max(...inView)
      const nextOutView = Math.min(Math.max(...outView), lastInView + 1)
      if (lastInView < nextOutView) {
        const el1 = elements[lastInView]
        const el2 = elements[nextOutView]
        let scrollTo = el2.getBoundingClientRect().left - el1.getBoundingClientRect().left
        if (nextOutView === Math.max(...outView)) {
          scrollTo += 20
        }
        setScrollLeft(scrollTo)
      }
    }
  }

  const handleUpdate = values => {
    const { scrollWidth, clientWidth, scrollLeft: newScrollLeft } = values
    if (clientWidth < scrollWidth && !scroll) {
      setScroll(true)
    } else if (clientWidth >= scrollWidth && scroll) {
      setScroll(false)
    }
    if (el.current && scroll) {
      const inner = el.current.querySelectorAll('.action-list')[0]
      const elements = Array.prototype.slice.call(inner.children)
      const newInView = []
      const newOutView = []
      elements.map((el1, idx) => {
        const in_view = isInViewport(el1, 20)
        if (!in_view && !newOutView.includes(idx)) {
          newOutView.push(idx)
        } else if (in_view && !newInView.includes(idx)) {
          newInView.push(idx)
        }
      })
      if (!newInView.length && newOutView.length) {
        const first = newOutView.shift()
        newInView.push(first)
      }
      const inViewEqual = isEqual(inView.sort(), newInView.sort())
      const outViewEqual = isEqual(outView.sort(), newOutView.sort())
      if (!inViewEqual || !outViewEqual) {
        setInView(newInView)
        setOutView(newOutView)
      }
    }
    if (scrollLeft !== null) {
      const newLeft = newScrollLeft + scrollLeft
      scroller.current.view.scrollTo({
        top: 0,
        left: newLeft,
        behavior: 'smooth'
      })
      setScrollLeft(null)
    }
  }

  return (
    <div ref={el} className={`actionswrap${scroll ? ' has-scroll' : ''}`}>
      {scroll &&
        <div className="for-scroll scroll-left" onMouseDown={scrollLeftStart} onTouchStart={scrollLeftStart}>
          <Button type="button" icon="#icon24-ChevronLeft" className="btn btn-icon-24 btn-white btn-icon-only" onMouseDown={scrollLeftStart} onTouchStart={scrollLeftStart} />
        </div>
      }
      <Scrollbar
        innerRef={scroller}
        autoHeight
        onUpdate={values => handleUpdate(values) }
      >
        <div className="action-list">
          <ContextMenu
            actions={props.actions}
            parent='model-actions'
            redirectSchema={props.redirectSchema}
            handleSubmit={props.handleSubmit}
            extras={props.extras}
            modelname={props.modelname}
            route={props.route}
          />
          {props.children}
        </div>
      </Scrollbar>
      {scroll &&
        <div className="for-scroll scroll-right" onMouseDown={scrollRightStart} onTouchStart={scrollRightStart}>
          <Button type="button" icon="#icon24-ChevronRight" className="btn btn-icon-24 btn-white btn-icon-only" onMouseDown={scrollRightStart} onTouchStart={scrollRightStart} />
        </div>
      }
    </div>
  )
}

ModelActions.propTypes = {
  selected: PropTypes.object,
  actions: PropTypes.object,
  config: PropTypes.object,
  modelname: PropTypes.string,
  user: PropTypes.object,
  redirectSchema: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleSubmit: PropTypes.func,
  extras: PropTypes.object,
  route: PropTypes.string
}

export default ModelActions
