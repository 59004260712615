const exampleTheme = {
  list: {
    nested: {
      listitem: 'nestedListItem'
    }
  },
  text: {
    bold: 'font-bold',
    italic: 'italic',
    underline: 'underline',
    strikethrough: 'line-through',
    underlineStrikethrough: 'line-through underline'
  }
}

export default exampleTheme
