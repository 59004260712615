import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getIn } from 'formik'
import React from 'react'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share'

import WhatsAppButton from '../common/WhatsAppButton'

import { slugify, valueFormat } from '../../utils'
import { QRCodeShareButton } from './Button'


export const iconMap = {
  facebook_url: '#social-Facebook',
  twitter_url: '#social-Twitter',
  linkedin_url: '#social-LinkedIn',
  instagram_url: '#social-Instagram',
  pinterest_url: '#social-Pinterest',
  youtube_url: '#social-YouTube',
  whatsapp_url: '#social-Whatsapp',
  qr_code_url: '#social-QRCode'
}

const SocialMedia = ({ model, sharing, ...props }) => {
  let urls
  const tools = {
    facebook_url: FacebookShareButton,
    twitter_url: TwitterShareButton,
    linkedin_url: LinkedinShareButton,
    whatsapp_url: WhatsAppButton,
    qr_code_url: QRCodeShareButton
  }
  if (sharing) {
    urls = {
      facebook_url: valueFormat('url', model.meta.url.website),
      twitter_url: valueFormat('url', model.meta.url.website),
      linkedin_url: valueFormat('url', model.meta.url.website),
      whatsapp_url: valueFormat('url', model.meta.url.website),
      qr_code_url: valueFormat('url', model.meta.url.website)
    }
  }
  return sharing ? (
    <div {...props} className={classNames('social-icons', props.className)}>
      {Object.keys(tools).map(key => {
        const url = getIn(urls, key)
        const icon = iconMap[key]

        let path = '/images/icons-16.svg'

        if (icon && icon.includes('#icon24')) {
          path = '/images/icons-24.svg'
        } else if (icon && icon.includes('#glyph')) {
          path = '/images/glyphs.svg'
        } else if (icon && icon.includes('#social')) {
          path = '/images/social.svg'
        }
        const Tool = getIn(tools, key)
        return (
          <Tool className={classNames('social-icon', slugify(key))} key={`social-${key}`} title={model.web_ref} url={url} target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 32 32"><use href={`${path}${icon}`} /></svg>
          </Tool>
        )
      })}
    </div>
  ) : (
    <div {...props} className={classNames('social-icons', props.className)}>
      {Object.keys(iconMap).map(key => {
        const url = getIn(model, key)
        const icon = iconMap[key]

        let path = '/images/icons-16.svg'

        if (icon && icon.includes('#icon24')) {
          path = '/images/icons-24.svg'
        } else if (icon && icon.includes('#glyph')) {
          path = '/images/glyphs.svg'
        } else if (icon && icon.includes('#social')) {
          path = '/images/social.svg'
        }
        return url ? (
          <a key={`social-${key}`} className={classNames('social-icon', slugify(key))} href={url} target="_blank" rel="nofollow noreferrer">
            <svg viewBox="0 0 32 32"><use href={`${path}${icon}`} /></svg>
          </a>
        ) : null
      })}
    </div>
  )
}

SocialMedia.propTypes = {
  model: PropTypes.object,
  sharing: PropTypes.bool,
  className: PropTypes.any,
  site: PropTypes.object
}

export default SocialMedia
