/* eslint-disable new-cap */
import { getIn, Map, List, is } from 'immutable'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'
import { hasPermission } from './utils'


export const APP = createSelector(state => getIn(state, [ 'app' ]), app => app)

export const SOCKETSETTING = createSelector(APP, app => getIn(app, [ 'socket' ]))

export const FRESHDESKID = createSelector(APP, app => getIn(app, [ 'freshdesk_id' ]))

export const HUBID = createSelector(APP, app => getIn(app, [ 'hubspot_id' ]))

export const USER = createSelector(state => state.get('user'), user => user)

export const CONFIGS = createSelector(USER, user => getIn(user, [ 'configs' ], Map({})))

export const SELECTED_ALL = createSelector(state => state.get('selected'), selected => selected)

export const CONFIG = createCachedSelector(
  [
    CONFIGS,
    (state, modelname) => modelname
  ],
  (configs, modelname) => getIn(configs, [ modelname ], Map({}))
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const MODELROUTE = createCachedSelector(
  [
    CONFIGS,
    (state, modelname) => modelname
  ],
  (configs, modelname) => getIn(configs, [ 'routes', modelname ], Map({}))
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const MODELPARAMS = createCachedSelector(
  [
    CONFIGS,
    (state, modelname) => modelname
  ],
  (configs, modelname) => getIn(configs, [ modelname, 'params' ], Map({}))
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const CLEANUSER = createCachedSelector(
  USER,
  user => user.delete('configs')
)(
  state => getIn(state, [ 'user', 'id' ], 0).toString()
)

export const LOADUSER = createCachedSelector(
  USER,
  user => {
    const userid = getIn(user, [ 'user', 'id' ])
    const agent = getIn(user, [ 'agent' ])
    const agents = getIn(user, [ 'agents' ])
    const alerts = getIn(user, [ 'alerts' ])
    const permissions = getIn(user, [ 'permissions' ])
    const auth = getIn(user, [ 'auth' ])
    return Map({
      id: userid,
      auth: auth,
      agent: agent,
      agents: agents,
      alerts: alerts,
      permissions: permissions
    })
  }
)(
  state => getIn(state, [ 'user', 'id' ], 0).toString()
)

export const MINUSER = createCachedSelector(
  USER,
  user => Map({
    id: getIn(user, [ 'id' ]),
    email: getIn(user, [ 'email' ]),
    auth: getIn(user, [ 'auth' ]),
    agent: getIn(user, [ 'agent' ]),
    agents: getIn(user, [ 'agents' ]),
    alerts: getIn(user, [ 'alerts' ]),
    permissions: getIn(user, [ 'permissions' ]),
    preferences: getIn(user, [ 'preferences' ])
  })
)(
  state => getIn(state, [ 'user', 'id' ], 0).toString()
)

export const FIELDGROUP_USER = createCachedSelector(
  MINUSER,
  user => Map({
    id: getIn(user, [ 'id' ]),
    email: getIn(user, [ 'email' ]),
    permissions: getIn(user, [ 'permissions' ]),
    agent: {
      id: getIn(user, [ 'agent', 'id' ]),
      site: getIn(user, [ 'agent', 'site' ]),
      branches_allowed: user.getIn([ 'agent', 'branches_allowed' ]),
      meta: {
        integrations: user.getIn([ 'agent', 'meta', 'integrations' ])
      }
    }
  })
)(
  state => getIn(state, [ 'user', 'id' ], 0).toString()
)

export const AGENT = createSelector(USER, user => getIn(user, [ 'agent' ], Map()))

export const TOKEN = createSelector(USER, user => getIn(user, [ 'token' ]))

export const AUTH = createSelector(USER, user => getIn(user, [ 'auth' ]))

export const WS = createSelector(USER, user => getIn(user, [ 'ws' ]))

export const ALERTS = createSelector(USER, user => getIn(user, [ 'alerts' ]))

export const PERMISSIONS = createSelector(USER, user => getIn(user, [ 'permissions' ]))

export const SITE = createSelector(USER, user => {
  const agent = user.get('agent') ? user.get('agent') : Map({})
  return agent ? getIn(agent, [ 'site' ], Map()) : Map({})
})

export const SITE_ID = createSelector(SITE, site => site.get('id'))

export const SETTINGS = createCachedSelector(
  [
    state => getIn(state, [ 'cache', 'settings' ]),
    (state, siteid) => (siteid ? siteid : getIn(state, [ 'user', 'agent', 'site', 'id' ]))
  ],
  (settings, siteid) => {
    const sitesettings = getIn(settings, [ `${siteid}` ])
    return sitesettings ? sitesettings : Map({})
  }
)(
  (_state_, siteid) => `${siteid || 0}`
)

export const CURRENCY = createSelector(SETTINGS, settings => getIn(settings, [ 'default_currency' ]))

export const ADDONS = createSelector(
  [
    SETTINGS,
    state => CONFIG(state, 'settings')
  ],
  (settings, config) => {
    const fields = getIn(config, [ 'fields' ]) || List()
    return fields.filter(f => [ 'Billable Add-Ons', 'Integrated Module Settings' ].includes(f.get('group')) && settings.get(f.get('name'))).map(f => f.get('name'))
  }
)


export const PREFERENCES = createCachedSelector(
  [
    state => state,
    (state, modelname) => modelname
  ],
  (state, modelname) => {
    const user_prefs = getIn(state, [ 'user', 'preferences', modelname ], Map())
    const config = CONFIG(state, modelname)
    const fields = config.get('fields')
    const permissions = getIn(state, [ 'user', 'permissions' ])
    return user_prefs.get('column_preferences', List()).map(f => {
      if (f.has('children')) {
        f = f.set('children', f.get('children').map(c => fields.find(fe => is(fe.get('name'), c.get('name')))))
        f = f.set('children', f.get('children').filter(x => x !== undefined))
      }
      const props = fields.find(fe => (
        is(fe.get('name'), f.get('name')) &&
        !fe.get('protected') &&
        (
          !fe.get('permissions') ||
          (fe.get('permissions') && hasPermission(fe.get('permissions').toJS(), permissions.toJS()))
        )
      ))
      f = props ? f.merge(props) : f
      return f
    }).filter(f => f && (
      !f.get('permissions') ||
      (f.get('permissions') && hasPermission(f.get('permissions').toJS(), permissions.toJS()))
    ))
  }
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const MESSAGES = createSelector(state => getIn(state, [ 'user', 'messages' ]), messages => messages)

export const FIELDS = createCachedSelector(
  [
    CONFIGS,
    (state, modelname) => modelname
  ],
  (configs, modelname) => {
    const fields = getIn(configs, [ modelname, 'fields' ])
    return fields ? fields : List()
  }
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const LISTINGPOPUPFIELDS = createCachedSelector(
  [
    CONFIGS,
    (state, modelname) => modelname
  ],
  (configs, modelname) => {
    const popup_fields = [
      'bedrooms',
      'floor_size',
      'bathrooms',
      'land_size',
      'garages',
      'zoning',
      'sleeps',
      'pool'
    ]
    const fields = getIn(configs, [ modelname, 'fields' ])
    return fields ? fields.filter(f => popup_fields.includes(f.get('name'))) : List()
  }
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const SELECTED = createCachedSelector(
  [
    SELECTED_ALL,
    (state, modelname) => modelname
  ],
  (selected, modelname) => getIn(selected, [ modelname ]) || List([])
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const UI = createSelector(state => getIn(state, [ 'ui' ]), ui => (ui ? ui : Map({})))

export const SIDEBAR = createSelector(UI, ui => ui.get('sidebar'))

export const NAV = createSelector(UI, ui => ui.get('nav'))

export const REDIRECT = createSelector(UI, ui => ui.get('redirect'))

export const LOADING = createSelector(UI, ui => ui.get('isLoading'))

export const NOTICES = createSelector(UI, ui => ui.get('notices'))

export const MODELLEADS = createSelector(UI, ui => ui.get('leads'))

export const MODELALERTS = createSelector(UI, ui => ui.get('alerts'))

export const WS_CONNECT = createSelector(UI, ui => ui.get('ws'))

export const MODELNAME = createSelector(UI, ui => ui.get('modelname'))

export const MODELID = createSelector(UI, ui => ui.get('modelid'))

export const MODELACTION = createSelector(UI, ui => ui.get('modelaction'))

export const MODELSEARCH = createSelector(UI, ui => ui.get('modelsearch'))

export const MODELS = createSelector(state => state.get('models'), models => models)

export const MODEL = createCachedSelector(
  [
    MODELS,
    (state, modelname) => modelname
  ],
  (models, modelname) => getIn(models, [ modelname ])
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const CACHE = createSelector(state => state.get('cache'), cache => cache)

export const CACHEDMODEL = createCachedSelector(
  [
    CACHE,
    (state, modelname) => modelname
  ],
  (cache, modelname) => getIn(cache, [ modelname ], Map({}))
)(
  (_state_, modelname) => (modelname ? modelname : 'undefined')
)

export const CACHEDMODELID = createCachedSelector(
  [
    CACHE,
    (state, modelname) => modelname,
    (state, modelname, id) => id
  ],
  (cache, modelname, id) => getIn(cache, [ modelname, `${id}` ])
)(
  (_state_, modelname, id) => (modelname ? `${modelname}-${id}` : `undefined-${id}`)
)

export const PORTALS = createSelector(CACHE, cache => getIn(cache, [ 'portals' ]))

export const GLOBALPORTALS = createSelector(SITE, site => getIn(site, [ 'portals', 'global' ]))

export const ROUTER = createSelector(state => state.get('router'), router => (router ? router : Map({})))

export const LOCATION = createSelector(ROUTER, router => router.location)

export const SEARCH = createSelector(ROUTER, router => router.location && router.location.search || '')

export const SESSION_EXPIRES = createSelector(USER, user => getIn(user, [ 'expires' ]))
