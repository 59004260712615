import { eachDayOfInterval, endOfDay, endOfWeek, endOfMonth, isWithinInterval, startOfDay, startOfWeek, startOfMonth, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { Tooltip, Legend, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'

import { generateAddress, valueFormat, getRandomColor, textToDate, hasAddons, hasPermission, parseURL, breakpoint, getTextWidth } from '../../utils'
import BarGraph, { longestLabelLength } from '../ui/graphs/BarGraph'
import date_options from '../../config/date-options.json'
import { Button } from '../ui/Button'
import InlineSelect from './forms/inputs/InlineSelect'
import Loader from './Loader'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


class CustomOption extends React.Component {
  render() {
    const { head, sub } = this.props.data
    return <components.Option
      {...this.props}
    >
      <div className="customopt">
        <div>
          {head}
          <span className="sub">{sub}</span>
        </div>
      </div>
    </components.Option>
  }
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload } = this.props
    if (payload.value === 'auto') { return payload.value }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {valueFormat('day', new Date(payload.value).toString())}
        </text>
      </g>
    )
  }
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
}

class ViewingFeedback extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'LAST_30_DAYS')
    const period = option.value
    const { start, end, days } = textToDate(option.value)
    this.state = {
      period,
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      days,
      showActions: breakpoint.matches,
      data: {
        dates: []
      },
      loading: true
    }
    this.getDate = this.getDate.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.renderCustomizedLegend = this.renderCustomizedLegend.bind(this)
  }

  componentDidMount() {
    const { config, model, actions } = this.props
    const { current, limit } = this.state
    breakpoint.addEventListener('change', this.toggleActions)
    new Promise((resolve, reject) => actions.fetchViewingFeedback({
      modelname: config.modelname,
      action: 'viewing-summary',
      modelid: model.id,
      params: {
        viewing__viewing_date__gte: current,
        viewing__viewing_date__lte: limit
      },
      resolve,
      reject
    })).then(r => {
      const dates = Object.keys(r.dates).map(day => ({ Date: day, Viewings: r.dates[day] }))
      this.setState({ data: { ...r, dates }, loading: false })
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config, model, actions } = this.props
    const { current, limit } = this.state
    if (this.state.period !== prevState.period) {
      this.setState({ loading: true })
      new Promise((resolve, reject) => actions.fetchViewingFeedback({
        modelname: config.modelname,
        action: 'viewing-summary',
        modelid: model.id,
        params: {
          viewing__viewing_date__gte: current,
          viewing__viewing_date__lte: limit
        },
        resolve,
        reject
      })).then(r => {
        const dates = Object.keys(r.dates).map(day => ({ Date: day, Viewings: r.dates[day] }))
        this.setState({ data: { ...r, dates }, loading: false })
      }).catch(() => {
        this.setState({ loading: false })
      })
    }
  }

  componentWillUnount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  getTicks(startDate, endDate, num) {
    let ticks = eachMonthOfInterval({ start: startDate, end: endDate })
    if (num < 1) {
      ticks = eachDayOfInterval({ start: startDate, end: endDate })
    } else if (Math.ceil(num) < 10) {
      ticks = eachWeekOfInterval({ start: startDate, end: endDate })
    }
    return ticks.map(t => t.getTime())
  }

  fillTicksData(_ticks, data, num) {
    const ticks = [ ..._ticks ]
    const filled = ticks.map(currentTick => {
      const tick_data = { date: currentTick, created: new Date(currentTick) }
      const label = 'Viewings'
      const total = data.filter(it => {
        let start = startOfMonth(currentTick)
        let end = endOfMonth(currentTick)
        if (num < 1) {
          start = startOfDay(currentTick)
          end = endOfDay(currentTick)
        } else if (Math.ceil(num) < 10) {
          start = startOfWeek(currentTick)
          end = endOfWeek(currentTick)
        }
        return isWithinInterval(new Date(it.Date), { start, end })
      }).map(it => it.Viewings).reduce((prevValue, currentValue) => prevValue + parseFloat(currentValue), 0)
      tick_data[label] = parseInt(total, 10)
      return tick_data
    })
    return filled
  }

  longestLabelLength(data, dataKeys) {
    const length = data ? (
      data
        .map(c => {
          const bigger = Math.max(...dataKeys.map(k => c[k]))
          const val = valueFormat('currency', bigger, { currency: this.props.currency })
          return (getTextWidth(val.toString()) * 1.2) + 5
        })
        .reduce((acc, cur) => (cur > acc ? cur : acc), 0)
    ) : 60
    return length
  }

  getDate(date, day_count) {
    if (day_count <= 31) {
      return valueFormat('day', date)
    } else if (day_count <= 93) {
      return valueFormat('daymonth', date)
    }
    return day_count >= 180 ? valueFormat('shortmonthyear', date) : valueFormat('shortdate', date)
  }

  renderCustomizedLegend() {
    const { data } = this.state
    const total = data.yes + data.no + data.maybe
    return (
      <div className="customized-legend">
        <span className="legend-total">
          <span className="legend-count">{total}</span>
          <span className="legend-label">Viewings</span>
        </span>
      </div>
    )
  }

  render() {
    const { showActions } = this.state
    const { user, model, actions, config, addons, configs, app } = this.props
    const dataKeys = [ 'Viewings' ]
    const { current: start_date, limit: end_date, data: stats, days: day_count } = this.state
    const num = (day_count / 365) * 12
    const ticks = this.getTicks(
      new Date(start_date),
      new Date(end_date),
      num
    )
    const filledData = this.fillTicksData(ticks, stats.dates, num)
    let seller_feedback_button
    if (hasAddons([ 'seller_feedback_report_addon' ], addons) && [ 'residential', 'commercial' ].includes(model.model)) {
      if (
        model.listing_type === 'For Sale'
        && hasPermission([ `listings_${model.model}_for_sale_generate_seller_feedback_report` ], user.permissions)
        && (hasPermission([ `listings_${model.model}_for_sale_update` ], user.permissions)
        || (
          hasPermission([ `listings_${model.model}_for_sale_update_own` ], user.permissions)
          && [ model.agent, model.agent_2, model.agent_3, model.agent_4 ].includes(user.agent.id))
        )
      ) {
        seller_feedback_button = <Button className="btn btn-subtle" component={NavLink} to='./seller-feedback'>Edit Report</Button>
      } else if (
        model.listing_type === 'To Let'
        && hasPermission([ `listings_${model.model}_to_let_generate_seller_feedback_report` ], user.permissions)
        && (hasPermission([ `listings_${model.model}_to_let_update` ], user.permissions)
        || (
          hasPermission([ `listings_${model.model}_to_let_update_own` ], user.permissions)
          && [ model.agent, model.agent_2, model.agent_3, model.agent_4 ].includes(user.agent.id))
        )
      ) {
        seller_feedback_button = <Button className="btn btn-subtle" component={NavLink} to='./landlord-feedback'>Edit Report</Button>
      } else {
        const url_pattern = configs['seller-feedback'].endpoint.public
        const url = parseURL(url_pattern, model, user)
        let service
        if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
          service = app.reports.stage
        } else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
          service = app.reports.live
        } else {
          service = app.reports.dev
        }
        seller_feedback_button = <Button className="btn btn-subtle" target="_blank" component={'a'} href={`${service}${url}`}>View Report</Button>
      }
    }
    return (
      <div className="grid-container grid-4 grid-rows">
        <Card
          classes="grid-col-3 grid-row-3"
          bodyclass="bar-graph viewing-feedback-graph"
          background
          header={
            <>
              <h3>Viewings</h3>
              <div className="details-section-buttons min-flex tablemeta">
                <div className="filter-date-range">
                  <InlineSelect
                    id="period"
                    name="period"
                    className="inline-select"
                    classNamePrefix="inline"
                    defaultValue={date_options.find(o => o.value === 'LAST_30_DAYS')}
                    selectedValue={getIn(this.state, 'period')}
                    options={date_options.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW'))}
                    onChange={e => {
                      const period = e.value
                      const { start, end, days } = textToDate(period)
                      const current = valueFormat('shortdate', start.toString())
                      const limit = valueFormat('shortdate', end.toString())
                      this.setState({ period, current, limit, days })
                    }}
                    components={{
                      Option: CustomOption
                    }}
                  />
                </div>
                {seller_feedback_button}
              </div>
            </>
          }
          body={
            !this.state.loading ? (
              <div className="stats">
                <BarGraph data={filledData} height={180} dataKeys={dataKeys}>{() => (
                  <>
                    <Legend wrapperStyle={{ width: showActions ? 'auto' : '100%', height: showActions ? '100%' : null, top: 0, right: 0 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={'top'} align="right" content={this.renderCustomizedLegend} />
                    <CartesianGrid vertical={false} stroke="#F3F5F8" />
                    <XAxis axisLine={false} dataKey="date" tickFormatter={date => this.getDate(date, day_count)} />
                    <YAxis allowDecimals={false} width={longestLabelLength(filledData, dataKeys)} axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
                    <Tooltip cursor={{ fill: '#FAFBFD' }} formatter={value => valueFormat('number', value)} labelFormatter={this.getDate} />
                    {dataKeys.map((key, idx) => (
                      <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={getRandomColor(1)} radius={[ 10, 10, 0, 0 ]} />
                    ))}
                  </>
                )}
                </BarGraph>
              </div>
            ) : (
              <div className="empty flex-container" style={{ height: 180 }}><Loader inline className="large" /></div>
            )
          }
        />
        <Card
          classes="grid-col-1"
          bodyclass="stats-card-mini viewing-feedback-card"
          background
          body={
            <div className="flex-container justify-between">
              <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-VeryHappy" /></svg> Interested</div><span>{stats.yes}</span>
            </div>
          }
        />
        <Card
          classes="grid-col-1"
          bodyclass="stats-card-mini viewing-feedback-card"
          background
          body={
            <div className="flex-container justify-between">
              <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-Happy" /></svg> Possibly Interested</div><span>{stats.maybe}</span>
            </div>
          }
        />
        <Card
          classes="grid-col-1"
          bodyclass="stats-card-mini viewing-feedback-card"
          background
          body={
            <div className="flex-container justify-between">
              <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-Sad" /></svg> Not Interested</div><span>{stats.no}</span>
            </div>
          }
        />
        <Card
          classes="grid-col-4 maxcard"
          bodyclass="viewing-feedback-list no-top-padding"
          background
          header={
            <h3>Interested</h3>
          }
          body={
            <SimpleTable
              paginated
              config={{
                endpoint: {
                  read: '/mashup/api/v1/leads/viewing-feedback/'
                }
              }}
              action={(params, resolve, reject) => actions.fetchViewingFeedback({
                modelname: config.modelname,
                action: 'viewing-feedback',
                modelid: model.id,
                resolve,
                reject,
                ...params
              })}
              header={[
                {
                  name: 'meta.viewing.viewing_date',
                  label: 'Viewing Date'
                },
                {
                  name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                  label: 'Agent',
                  link: '/secure/:site/agents/:meta.lead.agent/details'
                },
                {
                  name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                  label: 'Concerns'
                },
                {
                  name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                  label: 'Contact',
                  link: '/secure/:site/contacts/:contact/details'
                },
                {
                  name: 'meta.viewing.feedback',
                  label: 'Feedback'
                }
              ]}
              user={user}
              params={{
                limit: 100,
                viewing__interested: 'Yes',
                meta_fields: [ 'lead', 'contact' ]
              }}
              getClass={tbl => {
                this.highlight_table = tbl
              }}
              parser={data => {
                const newData = {
                  options: data.results.map(l => ({
                    ...l,
                    address: generateAddress(l)
                  })),
                  hasMore: !!data.next
                }
                return newData
              }}
              rowActions={(row, data) => (
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
              )}
            />
          }
        />
        <Card
          classes="grid-col-4 maxcard"
          bodyclass="viewing-feedback-list no-top-padding"
          background
          header={
            <h3>Possibly Interested</h3>
          }
          body={
            <SimpleTable
              paginated
              config={{
                endpoint: {
                  read: '/mashup/api/v1/leads/viewing-feedback/'
                }
              }}
              action={(params, resolve, reject) => actions.fetchViewingFeedback({
                modelname: config.modelname,
                action: 'viewing-feedback',
                modelid: model.id,
                resolve,
                reject,
                ...params
              })}
              header={[
                {
                  name: 'meta.viewing.viewing_date',
                  label: 'Viewing Date'
                },
                {
                  name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                  label: 'Agent',
                  link: '/secure/:site/agents/:meta.lead.agent/details'
                },
                {
                  name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                  label: 'Concerns'
                },
                {
                  name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                  label: 'Contact',
                  link: '/secure/:site/contacts/:contact/details'
                },
                {
                  name: 'meta.viewing.feedback',
                  label: 'Feedback'
                }
              ]}
              user={user}
              params={{
                limit: 100,
                viewing__interested: 'Maybe',
                meta_fields: [ 'lead', 'contact' ]
              }}
              getClass={tbl => {
                this.highlight_table = tbl
              }}
              parser={data => {
                const newData = {
                  options: data.results.map(l => ({
                    ...l,
                    address: generateAddress(l)
                  })),
                  hasMore: !!data.next
                }
                return newData
              }}
              rowActions={(row, data) => (
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
              )}
            />
          }
        />
        <Card
          classes="grid-col-4 maxcard"
          bodyclass="viewing-feedback-list no-top-padding"
          background
          header={
            <h3>Not Interested</h3>
          }
          body={
            <SimpleTable
              paginated
              config={{
                endpoint: {
                  read: '/mashup/api/v1/leads/viewing-feedback/'
                }
              }}
              action={(params, resolve, reject) => actions.fetchViewingFeedback({
                modelname: config.modelname,
                action: 'viewing-feedback',
                modelid: model.id,
                resolve,
                reject,
                ...params
              })}
              header={[
                {
                  name: 'meta.viewing.viewing_date',
                  label: 'Viewing Date'
                },
                {
                  name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                  label: 'Agent',
                  link: '/secure/:site/agents/:meta.lead.agent/details'
                },
                {
                  name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                  label: 'Concerns'
                },
                {
                  name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                  label: 'Contact',
                  link: '/secure/:site/contacts/:contact/details'
                },
                {
                  name: 'meta.viewing.feedback',
                  label: 'Feedback'
                }
              ]}
              user={user}
              params={{
                limit: 100,
                viewing__interested: 'No',
                meta_fields: [ 'lead', 'contact' ]
              }}
              getClass={tbl => {
                this.highlight_table = tbl
              }}
              parser={data => {
                const newData = {
                  options: data.results.map(l => ({
                    ...l,
                    address: generateAddress(l)
                  })),
                  hasMore: !!data.next
                }
                return newData
              }}
              rowActions={(row, data) => (
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
              )}
            />
          }
        />
      </div>
    )
  }
}

ViewingFeedback.propTypes = {
  currency: PropTypes.string,
  model: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  actions: PropTypes.object,
  settings: PropTypes.object,
  cache: PropTypes.object,
  recent_match_count: PropTypes.number,
  older_match_count: PropTypes.number,
  loading_matches: PropTypes.bool,
  unhighlightMatch: PropTypes.func,
  highlightMatch: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  fetchProfileMatches: PropTypes.func,
  fetchRecentMatches: PropTypes.func,
  fetchOldMatches: PropTypes.func,
  fetchHighlights: PropTypes.func,
  addons: PropTypes.array,
  app: PropTypes.object,
  fetchMatches: PropTypes.func
}

export default ViewingFeedback
