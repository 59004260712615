import React from 'react'
import PropTypes from 'prop-types'
import { Field, Formik, getIn } from 'formik'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'

import classNames from 'classnames'
import notesconfig from '../../../config/note.json'
import { uniqueArray, breakpoint } from '../../../utils'
import { history } from '../../../store'
import { Scrollbar } from '../../ui/Scrollbars'
import { Button } from '../../ui/Button'
import CustomForm from '../forms/CustomForm'
import TextInput from '../forms/inputs/Text'
import InlineSelect from '../forms/inputs/InlineSelect'
import DateInput from '../forms/inputs/Date'
import Loader from '../Loader'
import log from '../../../logging'
import NoteSummary from './NoteSummary'


const status_field = notesconfig.config.fields.find(f => f.name === 'status')
class NotesIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: [],
      offset: 0,
      advanced: false,
      loading: false,
      showActions: breakpoint.matches
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchAgents = this.fetchAgents.bind(this)
    this.togglePinNote = this.togglePinNote.bind(this)
    this.togglePrivateNote = this.togglePrivateNote.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
  }

  componentDidMount() {
    this.form.handleSubmit()
    this.fetchAgents()
    breakpoint.addEventListener('change', this.toggleActions)
  }

  componentDidUpdate(prevProps, prevState) {
    const { config, model, actions, match } = this.props
    if (prevState.offset !== this.state.offset) {
      new Promise((resolve, reject) => {
        const params = {
          modelname: 'notes',
          params: {
            meta_fields: [ 'files', 'agent_id' ],
            obj_model: config.modelname,
            obj_id: model.id,
            offset: this.state.offset,
            limit: 10
          }
        }
        this.setState({ loading: true })
        return this.props.actions.fetchMany(params, resolve, reject)
      }).then(r => {
        this.fetchAgents()
        this.setState({ more: r.next ? true : false, loading: false })
      }).catch(() => {
        this.setState({ more: false, loading: false })
      })
    }
    if (!this.state.init || !isEqual(prevState.notes, this.state.notes)) {
      this.setState({ init: true })
    }
    if (this.el) {
      const offset = this.el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    if (!isEqual(this.props.cache.notes, prevProps.cache.notes)) {
      this.form.handleSubmit()
      this.fetchAgents()
    }
    if (!prevState.advanced && this.state.advanced) {
      new Promise((resolve, reject) => actions.fetchMany({
        noloader: true,
        values: {
          modelname: 'notes',
          conflicts: true,
          trigram: true,
          search: true,
          get_all: true,
          endpoint: {
            read: `/mashup/api/v1/notes/${match.params.model}/${match.params.id}`
          },
          params: {
            meta_fields: [ 'files', 'agent_id' ],
            fields: [ 'created' ]
          }
        }, resolve, reject })
      ).then(r => {
        const dates_enabled = r.map(note => (new Date(note.created)))
        this.setState({ enabled: dates_enabled })
      })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  togglePinNote(note) {
    const { actions } = this.props

    const values = {
      modelname: notesconfig.config.modelname,
      id: note.id,
      pinned: !note.pinned
    }

    return new Promise((resolve, reject) => {
      actions.updateModel({ values, resolve, reject })
    }).then(() => {
      this.form.handleSubmit()
      this.fetchAgents()
    }).catch(e => {
      log.error(e)
    })
  }

  togglePrivateNote(note) {
    const { actions } = this.props

    const values = {
      modelname: notesconfig.config.modelname,
      id: note.id,
      private: !note.private
    }

    return new Promise((resolve, reject) => {
      actions.updateModel({ values, resolve, reject })
    }).then(() => {
      const { notes } = this.state
      const updatedNotes = notes.map(n => ({
        ...n,
        private: true
      }))
      this.setState({ notes: updatedNotes })
    }).catch(e => {
      log.error(e)
    })
  }

  handleSubmit(values, formik) {
    const { actions, match } = this.props
    const { fetchMany } = actions
    formik.setSubmitting(true)
    // eslint-disable-next-line no-unused-vars
    const { params, ...rest } = values
    new Promise((resolve, reject) => {
      this.setState({ loading: true, searching: true })
      fetchMany({
        noloader: true,
        values: {
          modelname: 'notes',
          conflicts: true,
          trigram: true,
          endpoint: {
            read: `/mashup/api/v1/notes/${match.params.model}/${match.params.id}`
          },
          params: {
            meta_fields: [ 'files', 'agent_id' ],
            ...rest
          }
        }, resolve, reject })
    }).then(r => {
      formik.setSubmitting(false)
      this.fetchAgents()
      this.setState({ loading: false, notes: r, searched: Object.keys(rest).length !== 3, searching: false }, () => {
        if (!getIn(match, 'params.record_id') && this.state.showActions && getIn(this.state, 'notes.0.id')) {
          history.push({
            pathname: `/secure/${match.params.site}/${match.params.model}/${match.params.id}/notes/${getIn(this.state, 'notes.0.id')}`
          })
        }
      })
    }).catch(() => {
      formik.setSubmitting(false)
      this.setState({ loading: false })
    })
  }

  fetchAgents() {
    const { notes } = this.state
    let newagents = notes ? notes.map(n => n.agent_id) : false
    if (newagents && newagents.length) {
      newagents = new Promise((resolve, reject) => {
        const params = {
          modelname: 'agents',
          params: {
            id__in: uniqueArray(newagents),
            meta_fields: [ 'image' ]
          }
        }
        this.props.actions.fetchMany({ values: params, resolve, reject })
      })
    }
  }

  render() {
    const { config, model, user, visible, match } = this.props
    return (
      <div className="notes-index">
        <Formik
          initialValues={{
            modelname: 'notes',
            conflicts: true,
            trigram: true,
            params: {
              relations__related_model__and__relations__related_id__or: [ config.modelname, model.id ],
              order_by: '-created',
              meta_fields: [ 'files', 'agent_id' ]
            }
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            this.form = formik
            return (
              <CustomForm
                component={'div'}
                onChange={changes => {
                  if (changes.includes('created__date') || changes.includes('private') || changes.includes('status')) {
                    formik.submitForm()
                  }
                }}
                render={() => (
                  <div className="keyword-search" ref={el => { this.el = el }}>
                    <Button
                      type="button" // This cannot be submit otherwise sibling form is submitted
                      icon="#icon16-Search"
                      onClick={() => {
                        this.setState({ advanced: !this.state.advanced })
                      }}
                      disabled={formik.isSubmitting}
                      className="btn btn-grey btn-icon-left btn-icon-16 advanced-toggle"
                    >Search & Filter</Button>
                    {this.state.searched ? (
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button" // This cannot be submit otherwise sibling form is submitted
                        icon="#icon16-Refresh"
                        onClick={() => {
                          formik.resetForm()
                          formik.handleSubmit()
                        }}
                        disabled={this.state.searching}
                        className="btn btn-grey btn-icon-left btn-icon-right btn-icon btn-icon-16"
                      />
                    ) : null}
                    <Button
                      type="button" // This cannot be submit otherwise sibling form is submitted
                      icon="#icon16-Plus"
                      onClick={() => {
                        this.props.actions.toggleWideSidebar('show-notes-sidebar')
                      }}
                      disabled={formik.isSubmitting}
                      className="btn btn-round btn-red btn-icon-left btn-icon-16"
                    >New</Button>
                    {this.state.advanced ? (
                      <>
                        <div className="form-group">
                          <InlineSelect
                            id="status"
                            name="status"
                            className="inline-select"
                            classNamePrefix="inline"
                            prefix={<div className="input-group-addon">Status: </div>}
                            options={[ { label: 'All', value: '' }, ...status_field.options ]}
                            defaultValue={{ label: 'All', value: '' }}
                            selectedValue={formik.values.status}
                            onChange={e => {
                              formik.setFieldValue('status', e.value, false).then(() => {
                                formik.setFieldTouched('status', true)
                              })
                            }}
                          />
                          <Field
                            component={DateInput}
                            name="created__date"
                            id="created__date"
                            button
                            enabled={this.state.enabled}
                            position='below right'
                          />
                          <div className="form-group button-only">
                            <div className="forminput input-only">
                              <Button
                                id="keyword-private-btn"
                                tabIndex="-1"
                                type="button" // This cannot be submit otherwise sibling form is submitted
                                icon="#icon16-EyeClosed"
                                onClick={() => {
                                  formik.setFieldValue('private', formik.values.private !== 1 ? 1 : 0, false).then(() => {
                                    formik.setFieldTouched('private', true)
                                  })
                                }}
                                className={classNames([ 'btn btn-icon btn-icon-only btn-icon-16 input-group-addon' ], { 'btn-subtle': !formik.values.private, 'btn-primary': formik.values.private })}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            name="term"
                            id="term"
                            placeholder="Keyword Search"
                            type="search"
                            _value={formik.values.term} // Enables reseting value back to null
                            form_el={this.el}
                            show_search
                            suffix={(
                              <Button
                                type="button" // This cannot be submit otherwise sibling form is submitted
                                icon="#icon24-Search"
                                onClick={formik.handleSubmit}
                                disabled={formik.isSubmitting}
                                className="btn btn-none input-group-addon btn-icon btn-icon-24"
                              />
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              />
            )
          }}
        </Formik>
        {visible ? (
          <div ref={el => (this.el = el)}className="notes-index-list">
            <Scrollbar
              style={{ height: `calc(100vh - ${this.state.offset}px)` }}
              innerRef={el => { this.index = el }}
            >
              {this.state.notes.length ? (
                <div className="notes-index-list-inner">
                  {this.state.notes.map((note, nidx) => (
                    <NoteSummary
                      key={`note-${nidx}`}
                      note={note}
                      match={this.props.match}
                      togglePinNote={this.togglePinNote}
                      togglePrivateNote={this.togglePrivateNote}
                      user={user}
                      agent={getIn(this.props.cache.agents, note.agent_id, getIn(note, 'meta.agent_id'))}
                      nidx={nidx}
                    />
                  ))}
                </div>
              ) : (
                <div className="no-notes">
                  <div className="no-notes-wrapper">
                    {this.state.searched ? (
                      <span>No notes found.</span>
                    ) : (
                      <span>{this.state.loading ? (
                        <Loader inline={true} />
                      ) : (
                        'No notes here yet.'
                      )}</span>
                    )}
                  </div>
                </div>
              )}
            </Scrollbar>
          </div>
        ) : (
          <div className="back-to-leads">
            <Button component={NavLink} to={`/secure/${match.params.site}/${match.params.model}/${match.params.id}/notes`} type="button" className="btn btn-none btn-icon-16 btn-icon-left" icon="#icon16-ChevronLeft">Back to Notes</Button>
          </div>
        )}
      </div>
    )
  }
}

NotesIndex.propTypes = {
  notes: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  visible: PropTypes.bool
}

export default NotesIndex
