import merge from 'deepmerge'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import { logEvent, overwriteMerge, updateSearchParms } from '../../../utils'
import Body from './Body'
import Foot from './Foot'
import Head from './Head'


class DataTable extends React.Component {
  constructor(props) {
    super(props)
    this.triggerKeyboardShift = this.triggerKeyboardShift.bind(this)
    this.selectOne = this.selectOne.bind(this)
    this.toggleOrder = this.toggleOrder.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.calculateGrouping = this.calculateGrouping.bind(this)
    this.calculateRows = this.calculateRows.bind(this)
    this.calculateFooterRows = this.calculateFooterRows.bind(this)
    this.state = {
      columns: [],
      rows: [],
      footer_rows: [],
      shifting: false,
      lastSelected: false,
      selectedAll: false
    }
    this._is_mounted = true
  }

  componentDidMount() {
    window.addEventListener('keydown', this.triggerKeyboardShift)
    window.addEventListener('keyup', this.triggerKeyboardShift)
    if (this.props.tableconfig.column_preferences) {
      const columns = []
      const rows = []
      const footer_rows = []
      this.props.tableconfig.column_preferences.map(column => this.calculateGrouping(columns, column))
      if (!isEqual(columns, this.state.columns)) {
        this.calculateRows(this.props.tableconfig.column_preferences, 1, rows)
        this.calculateFooterRows(this.props.tableconfig.column_preferences, 1, footer_rows)
        this.setState({ columns, rows, footer_rows })
      }
    }
  }

  componentDidUpdate() {
    if (this.props.tableconfig.column_preferences) {
      const columns = []
      const rows = []
      const footer_rows = []
      this.props.tableconfig.column_preferences.map(column => this.calculateGrouping(columns, column))
      if (!isEqual(columns, this.state.columns)) {
        this.calculateRows(this.props.tableconfig.column_preferences, 1, rows)
        this.calculateFooterRows(this.props.tableconfig.column_preferences, 1, footer_rows)
        this.setState({ columns, rows, footer_rows })
      }
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    window.removeEventListener('keydown', this.triggerKeyboardShift)
    window.removeEventListener('keyup', this.triggerKeyboardShift)
  }

  triggerKeyboardShift(e) {
    if (e.key === 'Shift') {
      if (e.type === 'keydown') { this.setState({ shifting: true }) } else { this.setState({ shifting: false }) }
    }
  }

  selectOne(data) { // Shift or single click select?
    if (!this._is_mounted) { return }
    if (this.state.shifting && this.state.lastSelected) {
      const lastidx = this.props.model.index.findIndex(a => a === this.state.lastSelected)
      const selectedidx = this.props.model.index.findIndex(a => a === data.id)
      let implied = []
      if (lastidx < selectedidx) { // Goes both ways!
        implied = this.props.model.index.slice(lastidx, selectedidx)
      } else {
        implied = this.props.model.index.slice(selectedidx, lastidx)
      }
      implied.forEach(i => {
        this.props.actions.selectOne({ ...data, id: i })
      })
      this.props.actions.selectOne(data)
      this.setState({ lastSelected: data.id, selectedAll: false })
    } else {
      this.props.actions.selectOne(data)
      this.setState({ lastSelected: data.id, selectedAll: false })
    }
  }

  toggleOrder(col, e) {
    const el = e.target.closest('th')
    let key = col.name
    // Fields which reference other models need to be ordered by their key
    const field = this.props.config.fields.find(f => isEqual(f.name, col.name))
    if (field && field.modelname && field.orderkey) {
      key = `${key}__${field.orderkey}`
    } else if (field.container && field.container === 'stats') {
      key = `statistics__${key}`
    }
    if (Array.isArray(col.name)) { key = col.name[0] }
    if (field && field.orderby) {
      key = field.orderby
    }
    if (el.classList.contains('orderedby')) {
      if (!el.classList.contains('reversed')) {
        if (Array.isArray(field.orderby)) {
          key = field.orderby.map(k => `-${k}`).join(',')
        } else {
          key = `-${key}`
        }
        el.classList.add('reversed')
      } else {
        el.classList.remove('reversed')
      }
    } else {
      this.props.resetPage()
      el.classList.add('orderedby')
    }
    updateSearchParms('order_by', key)
    logEvent('CHANGE_ORDER_BY', { modelname: this.props.config.modelname, column: key })
  }

  toggleSelectAll(e) {
    const t = e.target
    if (t.checked) {
      this.setState({ selectedAll: true })
      this.props.actions.selectAll(this.props.config.modelname)
    } else {
      this.setState({ selectedAll: false })
      this.props.actions.selectNone(this.props.config.modelname)
    }
  }

  calculateGrouping(columns, object, groupArray = []) {
    if (Array.isArray(object)) { return }
    const column = merge({}, object, { arrayMerge: overwriteMerge })
    let groupingArray = groupArray || []
    if (column.children && Array.isArray(column.children) && column.children.length) {
      groupingArray = groupingArray.push(column.label)
      const children = column.children.map(c => this.calculateGrouping(columns, c, groupingArray))
      column.children = children
    }
    column.nesting = groupingArray
    columns.push(column)
    // eslint-disable-next-line consistent-return
    return column
  }

  calculateRows(columns, count = 1, rows) {
    columns.forEach(col => {
      const column = merge({}, col, { arrayMerge: overwriteMerge })
      if (!rows[count - 1]) {
        rows[count - 1] = []
      }
      rows[count - 1].push(column)
      if (column.children) {
        this.calculateRows(column.children, count + 1, rows)
      }
    })
    return count
  }

  calculateFooterRows(columns, count = 1, rows) {
    columns.forEach(col => {
      const column = merge({}, col, { arrayMerge: overwriteMerge })
      if (column.children) {
        let children = column.children
        children = children.map(c => merge({ nested: column.name }, c))
        column.children = children
        this.calculateRows(children, count, rows)
        if (!rows[count]) {
          rows[count] = []
        }
        rows[count].push(column)
      } else {
        if (!rows[count - 1]) {
          rows[count - 1] = []
        }
        rows[count - 1].push(column)
      }
    })
  }

  render() {
    return (

      <div className="datatablewrap">
        <div className="datatablewrap-inner">
          <table cellPadding="0" cellSpacing="0" border="0" className={`datatable ${this.props.config.modelname}tbl`}>

            <Head
              modelname={this.props.config.modelname}
              params={(this.props.model) ? this.props.model.params : null}
              results={(this.props.model) ? this.props.model.results : null}
              orderby={(this.props.model) ? this.props.model.orderby : null}
              selectAll={this.selectAll}
              selectedAll={this.state.selectedAll}
              selectable={this.props.selectable}
              columns={this.state.columns}
              rows={this.state.rows}
              resetPage={this.props.resetPage}
              toggleSelectAll={this.toggleSelectAll}
              toggleOrder={this.toggleOrder}
            />

            <Body
              selected={this.props.selected}
              config={this.props.config}
              tableconfig={this.props.tableconfig ? this.props.tableconfig.column_preferences : {} }
              columns={this.state.columns}
              cache={this.props.cache}
              currency={this.props.currency}
              region={this.props.region}
              portals={this.props.portals}
              domains={this.props.domains}
              model={this.props.model}
              selectOne={this.selectOne}
              updateModel={this.props.actions.updateModel}
              selectable={this.props.selectable}
              user={this.props.user}
              redirectSchema={this.props.redirectSchema}
              contextMenu
              doubleClick
            />

            <Foot
              modelname={this.props.config.modelname}
              params={(this.props.model) ? this.props.model.params : null}
              results={(this.props.model) ? this.props.model.results : null}
              orderby={(this.props.model) ? this.props.model.orderby : null}
              selectAll={this.selectAll}
              selectedAll={this.state.selectedAll}
              selectable={this.props.selectable}
              columns={this.state.columns}
              rows={this.state.footer_rows}
              resetPage={this.props.resetPage}
              toggleSelectAll={this.toggleSelectAll}
              toggleOrder={this.toggleOrder}
            />

          </table>
          <div style={{ clear: 'both' }} />
        </div>
      </div>

    )
  }
}

DataTable.propTypes = {
  actions: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  portals: PropTypes.object,
  domains: PropTypes.array,
  tableconfig: PropTypes.object,
  resetPage: PropTypes.func,
  selectable: PropTypes.bool,
  userperms: PropTypes.array,
  meta: PropTypes.bool,
  advanced: PropTypes.bool,
  cache: PropTypes.object,
  config: PropTypes.object,
  selected: PropTypes.array,
  redirectSchema: PropTypes.func,
  findStatus: PropTypes.func,
  currency: PropTypes.string,
  region: PropTypes.string
}

export default DataTable
