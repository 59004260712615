import { Form } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import { updateAdditionalParams } from '../../../utils'
import log from '../../../logging'

/*
 * Custom wrapper for Formik Form which allows us to set the form context
 * inside the formik render method.
*/
class WatcherForm extends React.PureComponent {
  /*
  Component used in Advanced Search to monitor changes that affect
  the listing count or locations.
  */
  constructor(props) {
    super(props)
    this.state = { validated: false, watch_fields: [] }
    this.fetchCount = this.fetchCount.bind(this)
    this.fetching = false
    this.timer = null
  }

  componentDidMount() {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.fetchCount, 200) // get listing count on mount
    const { advsearch, modelname } = this.props.config
    if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) { // Only interested in listings
      const watch_fields = advsearch[0].map(field => `${field.name}__${field.verb}`)
      this.setState({ watch_fields })
    }
  }

  componentDidUpdate(prevProps) {
    const { advsearch, modelname } = this.props.config
    if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) { // Only interested in listings
      if (!isEqual(prevProps.values, this.props.values)) {
        Object.keys(this.props.values).forEach(k => {
          const old_val = prevProps.values[k]
          const new_val = this.props.values[k]
          if (!isEqual(old_val, new_val) && this.state.watch_fields.includes(k)) {
            this.state.watch_fields.filter((f, fidx) => fidx > this.state.watch_fields.indexOf(k)).forEach(f => {
              this.props.formik.setFieldValue(f, null, false)
            })
          }
        })
        clearTimeout(this.timer)
        this.timer = setTimeout(this.fetchCount, 200) // debounce count call
        const location_params = []
        let location_field = null
        // eslint-disable-next-line no-labels
        loop1: // using a label to allow us to break out of a nested forloop
        for (const group of advsearch) { // Figure out which fields come before the Location Select. Any changes should cause locations to reset.
          for (const field of group) {
            // eslint-disable-next-line no-labels
            if (field.name === 'location' || field.name === 'suburbs') { location_field = `${field.name}${field.verb ? `__${field.verb}` : ''}`; break loop1 }
            location_params.push(`${field.name}${field.verb ? `__${field.verb}` : ''}`)
          }
        }
        if (location_params.length && location_field) { // Did any "pre-location" fields update?
          const location_values = {
            modelname,
            params: {
              get_count: 1
            }
          }
          const prev_location_values = { params: { get_count: 1 } }
          Object.keys(this.props.values).forEach(name => {
            if (location_params.includes(name)) {
              location_values.params[name] = this.props.values[name]
              prev_location_values.params[name] = prevProps.values[name]
            }
          })
          if (!isEqual(prev_location_values.params, location_values.params)) {
            this.props.updateLocations(location_values, location_field, this.props.formik) // fetch active listing locations matching criteria
          }
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  fetchCount() {
    const { values } = this.props
    const { advsearch, modelname, endpoint } = this.props.config
    if (values && [ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) { // Only interested in listings
      const bool_fields = []
      const vals = { ...values }
      Object.keys(vals).forEach(vk => { // figure out what's changed in the form
        if (vals[vk] === null) { delete vals[vk]} // Delete null vals
        advsearch.forEach(group => {
          group.forEach(field => { // Remove explicit false vals
            if (field.name === vk && field.explicit && vals[vk] === false) { delete vals[vk] }
            if (field.name === vk && field.valsasbools) { bool_fields.push(field.name) }
          })
        })
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(modelname)) {
          if (vals.agent__in__or) {
            [ 'agent__in__or', 'agent_2__in__or', 'agent_3__in__or', 'agent_4__in__or' ].forEach(v => {
              vals[v] = vals.agent__in__or
            })
          }
        }
      })
      const payload = {
        modelname,
        endpoint: { ...endpoint }, // not anymore
        params: { get_count: 1, ...vals }
      }
      payload.params = updateAdditionalParams(payload.params, bool_fields)
      new Promise((resolve, reject) => this.props.fetchMany({ values: payload, resolve, reject }))
        .then(r => this.props.updateCount(r.result)).catch(e => log.error(e)) // fetch count of listings matching criteria
    } else {
      this.props.updateCount(true) // Return true count to enable search button for non-listings models
    }
  }

  render() {
    return (
      <Form>{this.props.render()}</Form>
    )
  }
}

WatcherForm.propTypes = {
  values: PropTypes.object,
  formik: PropTypes.object,
  config: PropTypes.object,
  modelname: PropTypes.string,
  cache: PropTypes.object,
  user: PropTypes.object,
  models: PropTypes.object,
  limit: PropTypes.number,
  fetchMany: PropTypes.func,
  updateCount: PropTypes.func,
  updateLocations: PropTypes.func,
  render: PropTypes.func
}

export default WatcherForm
