/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { NavLink } from 'react-router-dom'
import { fetchMany } from '../../actions'
import date_options from '../../config/date-options.json'
import { MINUSER } from '../../selectors'
import { textToDate, valueFormat, hasPermission, uniqueArray } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import Loader from '../common/Loader'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const ExpiringSoonWidget = props => {
  const { user, actions } = props

  const abortController = useRef(new AbortController())
  const option = date_options.find(o => o.value === 'NEXT_7_DAYS')
  const { start, end } = textToDate(option.value)
  const { start: previous_start, end: previous_end } = textToDate(option.value, true)
  const agentoptions = [ { first_name: 'All', last_name: 'Agents', id: '' } ]
  if (user.agent.id) {
    agentoptions.push({ first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id })
  }

  const [ agentid, setAgentId ] = useState('')
  const [ change, setChange ] = useState(false)
  const [ count, setCount ] = useState(0)
  const [ current, setCurrent ] = useState(valueFormat('shortdate', start.toString()))
  const [ limit, setLimit ] = useState(valueFormat('shortdate', end.toString()))
  const [ listingoptions, setListingOptions ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ mounted, setMounted ] = useState(true)
  const [ negative, setNegative ] = useState(false)
  const [ period, setPeriod ] = useState(option.value)
  const [ prevcount, setPrevCount ] = useState(0)
  const [ previous, setPrevious ] = useState(valueFormat('shortdate', previous_start.toString()))
  const [ prevlimit, setPrevLimit ] = useState(valueFormat('shortdate', previous_end.toString()))
  const [ selectedlistingtype, setSelectedListingType ] = useState('')
  const [ selectedmodelname, setSelectedModelName ] = useState('')
  const [ selectedmodel, setSelectedModel ] = useState('')
  const [ agents, setAgents ] = useState(agentoptions)
  let agent_branches
  if (!hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)) {
    agent_branches = [ ...user.agent.branches ]
  }

  const filterListings = useCallback(() => {
    setLoading(true)
    const listings = [
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: selectedmodelname || 'residential',
          endpoint: {
            read: '/listings/api/v1/listings/expiring-listings'
          },
          status: 'Active',
          signal: abortController.current.signal,
          params: {
            model: selectedmodelname,
            listing_type: selectedlistingtype,
            agents__in: agentid,
            date_from: current,
            date_to: limit,
            get_count: 1
          }
        },
        resolve,
        reject
      })).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    ]
    const prev_listings = [
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: selectedmodelname || 'residential',
          endpoint: {
            read: '/listings/api/v1/listings/expiring-listings'
          },
          status: 'Active',
          signal: abortController.current.signal,
          params: {
            model: selectedmodelname,
            listing_type: selectedlistingtype,
            agents__in: agentid,
            date_from: previous,
            date_to: prevlimit,
            get_count: 1
          }
        },
        resolve,
        reject
      })).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    ]
    Promise.allSettled(prev_listings).then(results => {
      if (mounted) {
        const new_prev_count = results.map(r => getIn(r, 'value.result', 0)).reduce((prevVal, currVal) => prevVal + currVal, 0)
        setPrevCount(new_prev_count)
      }
    })
    Promise.allSettled(listings).then(results => {
      if (mounted) {
        const new_count = results.map(r => getIn(r, 'value.result', 0)).reduce((prevVal, currVal) => prevVal + currVal, 0)
        setCount(new_count)
      }
    })
    Promise.allSettled([ ...prev_listings, ...listings ]).then(() => {
      if (mounted) {
        let new_change = count / 100
        if (prevcount) {
          new_change = Math.round((count - prevcount) / prevcount * 100)
        }
        if (new_change) {
          setChange(new_change)
          setNegative((count - prevcount) < 0)
        }
        setLoading(false)
      }
    })
  })

  useEffect(() => {
    const listing_options = [ { label: 'All Listing Types', value: '' } ]
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
    }
    if (!user.permissions.includes('is_prop_data_user') && mounted) {
      setAgentId(user.agent.id)
    }
    setListingOptions(listing_options)
    filterListings()
    return () => { // CWU
      setMounted(false)
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    filterListings()
  }, [ period, agentid, listingoptions ])
  return (
    <Card
      id="expiring-soon-widget"
      classes="primary-bg grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <strong>Listings Expiring Soon</strong>
          <div className="percentage-change">
            {change ? valueFormat('percent_change', change, { negative: negative, reverse: true }) : null}
          </div>
        </>
      }
      body={(
        <>
          {loading ? (
            <div className="empty flex-container" style={{ height: 48 }}><Loader inline className={'white'}/></div>
          ) : (
            <h1>{ selectedmodelname ? <NavLink to={`/secure/${user.agent.site.id}/${selectedmodelname}/?status__in=Active${selectedlistingtype ? `&listing_type__in=${selectedlistingtype}` : ''}${selectedlistingtype === 'To Let' ? `&tenant_lease_ends__gte=${current}&tenant_lease_ends__lte=${limit}` : ''}${selectedlistingtype === 'For Sale' ? `&mandate_end_date__gte=${current}&mandate_end_date__lte=${limit}` : ''}${agentid ? `&agents__in=${agentid}` : ''}&order_by=-created`}>{count}</NavLink> : count}</h1>
          )}
          <div className="stats-filters">
            <InlineSelect
              id="listing_model"
              name="listing_model"
              className="inline-select"
              classNamePrefix="inline"
              options={listingoptions}
              defaultValue={{ label: 'All Listing Types', value: '' }}
              selectedValue={selectedmodel}
              onChange={e => {
                let new_selected_modelname = ''
                let new_selected_listing_type = ''
                if ([ 'residential_to_let', 'residential_for_sale' ].includes(e.value)) { new_selected_modelname = 'residential' }
                if ([ 'commercial_to_let', 'commercial_for_sale' ].includes(e.value)) {new_selected_modelname = 'commercial'}
                if (e.value === 'holiday') {new_selected_modelname = 'holiday'}
                if (e.value === 'projects') {new_selected_modelname = 'projects'}
                if (e.value.indexOf('for_sale') !== -1) {new_selected_listing_type = 'For Sale'} else {new_selected_listing_type = 'To Let'}
                new_selected_listing_type = new_selected_modelname && [ 'commercial', 'residential' ].includes(new_selected_modelname) ? new_selected_listing_type : ''
                setSelectedModel(e.value)
                setSelectedModelName(new_selected_modelname)
                setSelectedListingType(new_selected_listing_type)
              }}
            />
            <AsyncInlineSelect
              id="agent_id"
              name="agent_id"
              className="inline-select"
              classNamePrefix="inline"
              defaultValue={user.agent.id ? { first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id } : { first_name: 'All', last_name: 'Agents', id: '' }}
              options={agents}
              form={{ values: { branches__overlap: agent_branches, agent_id: agentid } }}
              modelname="agents"
              labelseparator=" "
              fetchMany={actions.fetchMany}
              optionlabel={[ 'first_name', 'last_name' ]}
              noclear
              watch={[ 'branches__overlap' ]}
              params={{
                branches__overlap: agent_branches,
                active: 1,
                order_by: 'first_name,last_name',
                fields: 'id,first_name,last_name,statistics'
              }}
              field={{ value: agentid }}
              onLoad={e => {
                setAgents(uniqueArray([ ...agents, ...e ], 'id'))
              }}
              onChange={e => setAgentId(e.value)}
            />
            <InlineSelect
              id="period"
              name="period"
              className="inline-select"
              classNamePrefix="inline"
              defaultValue={date_options.find(o => o.value === 'NEXT_7_DAYS')}
              selectedValue={period}
              options={date_options}
              onChange={e => {
                const { start: new_start, end: new_end } = textToDate(e.value)
                const { start: new_previous_start, end: new_previous_end } = textToDate(period, true)
                setPeriod(e.value)
                setCurrent(valueFormat('shortdate', new_start.toString()))
                setLimit(valueFormat('shortdate', new_end.toString()))
                setPrevious(valueFormat('shortdate', new_previous_start.toString()))
                setPrevLimit(valueFormat('shortdate', new_previous_end.toString()))
              }}
              components={{ Option: CustomOption }}
            />
          </div>
        </>
      ) }
    />
  )
}


ExpiringSoonWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  return ({
    user
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ExpiringSoonWidget))
