import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import Select, { components } from 'react-select'
import { reduceGroupedOptions } from 'react-select-async-paginate'

import db from '../../../../db'
import log from '../../../../logging'
import { buildOptionLabel, composeOptionLabel, groupBy, uniqueArray } from '../../../../utils'
import Loader from '../../Loader'
import QueryBuilder from '../../QueryBuilder'
import { withResponsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const PER_PAGE = 50

const ResponsiveAsyncPaginate = withResponsiveSelect(Select)

const CustomOption = props => {
  const { head, sub, img } = props.data
  const { count } = props
  return <components.Option {...props} >
    <div className="customopt">
      {img && <img src={img} alt="" />}
      <div>
        {head} {count > 0 && <span className="tag tag-count">{count}</span>}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number
}

class LocationSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.defaultReduceOptions = this.defaultReduceOptions.bind(this)
    this.loadData = this.loadData.bind(this)
    this.shouldLoadMore = this.shouldLoadMore.bind(this)
    this.loadIndicator = this.loadIndicator.bind(this)
    this.customOption = this.customOption.bind(this)
    this.calculateReset = this.calculateReset.bind(this)
    this.state = {
      hasMore: false,
      vals: null,
      init: false,
      reset: false,
      inputValue: ''
    }
    this.getSuburbOptions = this.getSuburbOptions.bind(this)
    this.getAreaOptions = this.getAreaOptions.bind(this)
    this.getProvinceOptions = this.getProvinceOptions.bind(this)
    this.getQueryParams = this.getQueryParams.bind(this)
    this.fetch = null
    this._is_mounted = true
  }

  componentDidMount() {
    const { optionlabel, labelseparator, optionvalue, field, modelname } = this.props
    let querydb = modelname
    if (querydb === 'locations') {
      querydb = 'suburbs'
    }
    let vals
    if (field.value) {
      db[querydb].where(optionvalue ? optionvalue : 'id').anyOf(field.value).toArray().then(results => {
        const cache = {}
        results.forEach(o => (cache[o.id] = o))
        vals = composeOptionLabel(cache, field.value, optionlabel, labelseparator, optionvalue)
        this.setState({ vals })
      })
    } else {
      this.setState({ vals: null })
    }
    this.calculateReset(this.props)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.field.value, prevProps.field.value)) {
      const { optionlabel, labelseparator, optionvalue, field, modelname } = this.props
      let querydb = modelname
      if (querydb === 'locations') {
        querydb = 'suburbs'
      }
      let vals
      if (field.value) {
        const ids = Array.isArray(field.value) ? field.value : [ field.value ]
        db[querydb].where(optionvalue ? optionvalue : 'id').anyOf(ids).toArray().then(results => {
          const cache = {}
          // eslint-disable-next-line no-return-assign
          results.forEach(o => cache[o.id] = o)
          vals = composeOptionLabel(cache, field.value, optionlabel, labelseparator, optionvalue)
          this.setState({ vals })
        })
      } else if (!field.value && this.state.vals) {
        this.setState({ vals: null })
      }
    }
    this.calculateReset(prevProps)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  calculateReset(prevProps) {
    const { values } = this.props.form
    const { optionfilters, force_filter } = this.props
    const state = { reset: false }
    const prevState = { reset: this.state.reset }
    if (
      this.props.params !== prevProps.params ||
      !isEqual(force_filter, prevProps.force_filter) ||
      !isEqual(values[optionfilters], prevProps.form.values[optionfilters]) ||
      !isEqual(this.props.form.status, prevProps.form.status)
    ) {
      state.reset = true
    }
    if (!isEqual(state, prevState)) {
      this.setState(state)
    }
  }

  handleChange(v) {
    const { multi, form, field, dependents } = this.props
    let vals = []
    let province_country = []
    let area_suburb = []
    if (v) { // Is there a value? Used for clearing select
      if (Array.isArray(v)) {
        if (v.length > 0) { // Array value with values
          v.forEach(i => {
            if (Array.isArray(i.value)) {
              vals.push(i.value[0])
            } else {
              vals.push(i.value)
            }
          })
        } else {
          vals = v
        }
      } else if (multi) {
        if (v.value) { vals.push(v.value) }
      } else if (v.value) {
        vals = v.value
      } else if (v !== '') {
        vals = v
      } else {
        vals = null
      }
      form.setFieldValue(field.name, vals).then(() => {
        form.setFieldTouched(field.name)
      })
      if (vals && !Array.isArray(vals)) {
        province_country = v.sub.split(',')
        area_suburb = v.head.split(',')
        form.setFieldValue('_province', province_country[0], false)
        form.setFieldValue('_country', province_country[1], false)
        form.setFieldValue('_area', area_suburb[0], false)
        form.setFieldValue('_suburb', area_suburb[1], false)
      }
      if (dependents) { // Unset any dependents
        dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) })
      }
    } else { // Clear the select as there is no value
      form.setFieldValue(field.name, null).then(() => {
        form.setFieldTouched(field.name)
      })
    }
  }

  defaultReduceOptions(prevOptions, loadedOptions) {
    return prevOptions.concat(loadedOptions)
  }

  getSuburbOptions(location, search, queryparams) {
    const conditions = []
    if (search && search.length >= 3) {
      const { suburb, area } = location
      conditions.push(
        suburb.toLowerCase().includes(search.toLowerCase()) ||
        area.toLowerCase().includes(search.toLowerCase())
      )
    }

    if (queryparams.area__province__country__id__in) {
      conditions.push(queryparams.area__province__country__id__in.includes(location.country_id))
    }
    if (queryparams.area__province__id__in) {
      conditions.push(queryparams.area__province__id__in.includes(location.province_id))
    }
    if (queryparams.area__id__in) {
      conditions.push(queryparams.area__id__in.includes(location.area_id))
    }
    if (queryparams.province__country__id__in) {
      conditions.push(queryparams.province__country__id__in.includes(location.country_id))
    }
    if (queryparams.province__id__in) {
      conditions.push(queryparams.province__id__in.includes(location.province_id))
    }
    if (queryparams.country__id__in) {
      conditions.push(queryparams.country__id__in.includes(location.country_id))
    }
    if (queryparams.id__in) {
      conditions.push(queryparams.id__in.includes(location.id))
    }
    if (this.props.force_filter && !Array.isArray(this.props.force_filter)) {
      const count = this.props.force_filter[parseInt(location.id, 10)]
      conditions.push(count > 0)
    }
    return conditions.every(e => e)
  }

  getProvinceOptions(location, search, queryparams) {
    const conditions = []
    if (search && search.length >= 3) {
      const { province } = location
      conditions.push(province.toLowerCase().startsWith(search.toLowerCase()))
    }
    if (queryparams.country__country__in) {
      conditions.push(queryparams.country__country__in.includes(location.country))
    }
    if (queryparams.country__id__in) {
      conditions.push(queryparams.country__id__in.includes(location.country_id))
    }
    if (queryparams.id__in) {
      conditions.push(queryparams.id__in.includes(location.id))
    }
    if (this.props.force_filter && !Array.isArray(this.props.force_filter)) {
      const count = this.props.force_filter[parseInt(location.id, 10)]
      conditions.push(count > 0)
    }
    return conditions.every(e => e)
  }

  getAreaOptions(location, search, queryparams) {
    const conditions = []
    if (search && search.length >= 3) {
      const { area } = location
      conditions.push(area.toLowerCase().startsWith(search.toLowerCase()))
    }
    if (queryparams.province__country__country__in) {
      conditions.push(queryparams.province__country__country__in.includes(location.country))
    }
    if (queryparams.province__country__id__in) {
      conditions.push(queryparams.province__country__id__in.includes(location.country_id))
    }
    if (queryparams.id__in) {
      conditions.push(queryparams.id__in.includes(location.id))
    }
    if (this.props.force_filter && !Array.isArray(this.props.force_filter)) {
      const count = this.props.force_filter[parseInt(location.id, 10)]
      conditions.push(count > 0)
    }
    return conditions.every(e => e)
  }

  getCountryOptions(location, search, queryparams) {
    const conditions = []
    if (search && search.length >= 3) {
      const { country } = location
      conditions.push(country.toLowerCase().startsWith(search.toLowerCase()))
    }
    if (queryparams.id__in) {
      conditions.push(queryparams.id__in.includes(location.id))
    }
    return conditions.every(e => e)
  }

  async parseOptions(options) {
    const { labelgrouper } = this.props
    const o = [] // Build the option label for asyncselect and asynccreateselects
    if (labelgrouper && options && options.length) { // Labels are grouped
      const groups = groupBy(options, labelgrouper)
      for (const group in groups) {
        if (group !== '') {
          const g = { label: group, options: [] }
          groups[group].forEach(res => { g.options.push(buildOptionLabel(this.props, res)) })
          o.push(g)
        }
      }
    } else if (options && options.length) {
      options.forEach(res => { o.push(buildOptionLabel(this.props, res)) })
    }
    return o
  }

  getQueryParams(prevOptions, additional) {
    const { params } = this.props
    let queryparams = {}
    if (params) {
      const query = new QueryBuilder(params)
      queryparams = query.getAllArgs()
    }
    queryparams.page = (additional && additional.page) ? additional.page + 1 : 1
    if (!prevOptions.length) { queryparams.page = 1 }
    if (queryparams.area__province__country__id__in && !Array.isArray(queryparams.area__province__country__id__in)) {
      queryparams.area__province__country__id__in = [ queryparams.area__province__country__id__in ]
    }
    if (queryparams.area__province__id__in && !Array.isArray(queryparams.area__province__id__in)) {
      queryparams.area__province__id__in = [ queryparams.area__province__id__in ]
    }
    if (queryparams.area__id__in && !Array.isArray(queryparams.area__id__in)) {
      queryparams.area__id__in = [ queryparams.area__id__in ]
    }
    if (queryparams.province__country__id__in && !Array.isArray(queryparams.province__country__id__in)) {
      queryparams.province__country__id__in = [ queryparams.province__country__id__in ]
    }
    if (queryparams.province__id__in && !Array.isArray(queryparams.province__id__in)) {
      queryparams.province__id__in = [ queryparams.province__id__in ]
    }
    if (queryparams.country__id__in && !Array.isArray(queryparams.country__id__in)) {
      queryparams.country__id__in = [ queryparams.country__id__in ]
    }
    if (queryparams.id__in && !Array.isArray(queryparams.id__in)) {
      queryparams.id__in = [ queryparams.id__in ]
    }
    return queryparams
  }

  async loadData(search, prevOptions, additional) {
    const { modelname } = this.props
    this.count += 1
    try {
      let querydb = modelname
      if (querydb === 'locations') {
        querydb = 'suburbs'
      }
      let options = null
      // Dexie Collections are shared instances
      // Calls to `.count()` and `.sortBy` alter the collection,
      // so we instantiate 2 seperate collection calls
      let counter = null
      const queryparams = this.getQueryParams(prevOptions, additional)
      if (search) {
        if (querydb === 'suburbs') {
          options = db[querydb].orderBy('area_suburb').filter(location => this.getSuburbOptions(location, search, queryparams))
          counter = db[querydb].filter(location => this.getSuburbOptions(location, search, queryparams))
        } else if (querydb === 'areas') {
          options = db[querydb].orderBy('area').filter(location => this.getAreaOptions(location, search, queryparams))
          counter = db[querydb].filter(location => this.getAreaOptions(location, search, queryparams))
        } else if (querydb === 'provinces') {
          options = db[querydb].orderBy('province').filter(location => this.getProvinceOptions(location, search, queryparams))
          counter = db[querydb].filter(location => this.getProvinceOptions(location, search, queryparams))
        } else if (querydb === 'countries') {
          options = db[querydb].orderBy('country').filter(location => this.getCountryOptions(location, search, queryparams))
          counter = db[querydb].filter(location => this.getCountryOptions(location, search, queryparams))
        }
      } else {
        options = db[querydb]
          // eslint-disable-next-line no-nested-ternary
          .orderBy(querydb === 'suburbs' ? 'area_suburb' : querydb === 'areas' ? 'area' : querydb === 'provinces' ? 'province' : querydb === 'countries' ? 'country' : 'suburb').clone()
          .filter(location => {
            if (querydb === 'suburbs') {
              return this.getSuburbOptions(location, search, queryparams)
            }
            if (querydb === 'areas') {
              return this.getAreaOptions(location, search, queryparams)
            }
            if (querydb === 'provinces') {
              return this.getProvinceOptions(location, search, queryparams)
            }
            if (querydb === 'countries') {
              return this.getCountryOptions(location, search, queryparams)
            }
            return false
          })
        counter = db[querydb]
          .filter(location => {
            if (querydb === 'suburbs') {
              return this.getSuburbOptions(location, search, queryparams)
            }
            if (querydb === 'areas') {
              return this.getAreaOptions(location, search, queryparams)
            }
            if (querydb === 'provinces') {
              return this.getProvinceOptions(location, search, queryparams)
            }
            if (querydb === 'countries') {
              return this.getCountryOptions(location, search, queryparams)
            }
            return false
          })
      }
      if (!options) {
        return { options: [], hasMore: false, additional }
      }
      let page_options = []
      if (querydb === 'suburbs') {
        page_options = await options.offset((queryparams.page - 1) * PER_PAGE).limit(PER_PAGE).toArray()
      }
      if (querydb === 'areas') {
        page_options = await options.offset((queryparams.page - 1) * PER_PAGE).limit(PER_PAGE).toArray()
      }
      if (querydb === 'provinces') {
        page_options = await options.offset((queryparams.page - 1) * PER_PAGE).limit(PER_PAGE).toArray()
      }
      if (querydb === 'countries') {
        page_options = await options.offset((queryparams.page - 1) * PER_PAGE).limit(PER_PAGE).toArray()
      }
      const total = await counter.count()
      const results = uniqueArray(page_options, 'id')
      const o = await this.parseOptions(results)
      if (this.props.updateTemplates) {
        this.props.updateTemplates(o)
      }
      const left = (total - ((parseInt(queryparams.page, 10) - 1) * PER_PAGE))
      return { options: o, hasMore: left > 0, additional: queryparams }
    } catch (e) {
      if (e.error !== 'The operation was aborted. ') {
        log.error(e)
      }
    }
    return { options: [], hasMore: false, additional }
  }

  shouldLoadMore(scrollHeight, clientHeight, scrollTop) {
    const bottomBorder = (scrollHeight - clientHeight) / 2
    return bottomBorder < scrollTop
  }

  loadIndicator() {
    return <Loader inline />
  }

  customOption(props) {
    let count = null
    if (this.props.force_filter && !Array.isArray(this.props.force_filter)) {
      count = this.props.force_filter[parseInt(props.value, 10)]
    }
    if (props.selectProps.labelformat) { return <CustomOption {...props} count={count} /> }
    return <components.Option {...props} />
  }

  render() {
    const { field, label, form, classes, labelformat, placeholder,
      noclear, multi, labelgrouper, id } = this.props
    const { name } = field
    if (!field.name) { return null }
    return (
      <div
        id={id}
        className={`selectinput asyncselectinput form-group ${classes ? classes : ''}`}
        ref={el => { // This may need to be refactored for performance sakes
          if (!el) {return}
          this.el = el
        }
        }>
        {label && label.length > 0 &&
          <Label htmlFor={field.name}>
            {label}
          </Label>
        }
        <div className="forminput">
          <ResponsiveAsyncPaginate
            key={`as-${name}`}
            debounceTimeout={300}
            className={'react-select'}
            classNamePrefix="react-select"
            isMulti={multi}
            name={field.name}
            inputId={field.name}
            form={form}
            field={field}
            isClearable={noclear ? false : true}
            loadOptions={this.loadData}
            isDisabled={this.props.readonly || this.props.disabled ? true : false}
            defaultOptions
            cache={false}
            onChange={this.props.onChange || this.handleChange}
            value={this.state.vals}
            labelformat={labelformat}
            shouldLoadMore={this.shouldLoadMore}
            reduceOptions={labelgrouper ? reduceGroupedOptions : this.defaultReduceOptions}
            placeholder={placeholder}
            noOptionsMessage={ () => 'No locations found' }
            components={{
              LoadingIndicator: this.loadIndicator,
              Option: this.customOption
            }}
            cacheUniqs={[ this.state.reset ]}
            additional={{ offset: 0 }}
          />
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </div>
    )
  }
}

LocationSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  force_filter: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  optionfilters: PropTypes.string,
  limit: PropTypes.object,
  noclear: PropTypes.bool,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  dependents: PropTypes.array,
  querymodel: PropTypes.string,
  modelname: PropTypes.string,
  labelgrouper: PropTypes.string,
  labelformat: PropTypes.object,
  labelseparator: PropTypes.string,
  placeholder: PropTypes.string,
  optionvalue: PropTypes.string,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  updateTemplates: PropTypes.func,
  params: PropTypes.string,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired
}

export default LocationSelectInput
