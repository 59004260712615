import PropTypes from 'prop-types'
import React from 'react'

import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'


const Application = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    match,
    routeConfig
  } = props

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >
      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>
      <Tab tab="notes" label="Notes" noScroll>
        <Notes
          config={config}
          model={model}
          user={user}
          match={match}
          actions={actions}
          cache={cache}
        />
      </Tab>
      <Tab tab="activity" label="Activity">
        <Activity
          model={model}
          cache={cache}
          config={config}
          events={model.activity}
          user={user}
          settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
          agents={cache && cache.agents ? cache.agents : {}}
          fetchActivity={actions.fetchActivity}
          fetchOne={actions.fetchOne}
          fetchMany={actions.fetchMany}
        />
      </Tab>
    </HorizontalTabs>
  )
}

Application.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Application
