import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Lead from '../reports/LeadActivity'
import Default from '../reports/DefaultActivity'


const ModelReport = props => {
  useEffect(() => {
    props.actions.selectNone()
  }, [])

  const switchComponent = (model = false) => {
    const s = {}
    switch (model) {
      case 'leads':
        s.component = Lead
        break
      default:
        s.component = Default
    }
    return s
  }

  const report = switchComponent(props.config.modelname)
  return React.createElement(report.component, { ...props })
}

ModelReport.propTypes = {
  actions: PropTypes.object,
  config: PropTypes.object
}

export default ModelReport
