import classNames from 'classnames'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import template from 'lodash/template'

import { valueFormat, handleSubmitError } from '../utils'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'


const CreditCheckSidebar = ({ actions, config, field, match, model }) => {
  const [ auth, setAuth ] = useState({})
  const [ modules, setModules ] = useState([])
  const [ contact, setContact ] = useState({})
  const [ report, setReport ] = useState()
  const [ loading, setLoading ] = useState(false)
  const form = useRef(null)

  const handleSubmit = (values, formikBag) => new Promise((resolve, reject) => {
    setLoading(true)
    const vals = { ...values } // Make a copy as we are mutating
    Object.keys(vals).forEach(k => {
      const valfield = config.fields.find(f => f.name === k)
      if (valfield && valfield.input === 'Float') { vals[k] = vals[k] ? parseFloat(vals[k]).toFixed(1) : vals[k] }
      if (vals[k] === null || vals[k] === '') { delete vals[k] }
    })
    if (!getIn(vals, 'AuthToken')) {
      actions.loginCreditCheck({ values: vals, resolve, reject }) // Perform the lookup
    } else if (!getIn(vals, 'modules')) {
      const module_values = {
        ...vals,
        token: getIn(vals, 'AuthToken'),
        username: getIn(vals, 'LoginCode'),
        password: getIn(vals, 'Password'),
        id_number: getIn(vals, 'id_number')
      }
      setContact({ ...vals })
      actions.fetchCreditCheckModules({ values: module_values, resolve, reject }) // Perform the lookup
    } else {
      actions.fetchCreditCheck({ values: {
        ...vals,
        ...contact,
        token: getIn(vals, 'AuthToken'),
        username: getIn(vals, 'LoginCode'),
        password: getIn(vals, 'Password')
      }, resolve, reject }) // Perform the lookup
    }
  }).then(r => {
    setLoading(false)

    if (!getIn(values, 'AuthToken')) {
      setAuth(r)
    } else if (!getIn(values, 'modules')) {
      setModules(r)
    } else {
      setReport(r)
    }
  }).catch(e => {
    console.error(e)
    handleSubmitError(e, formikBag, form.current)
    setLoading(false)
  })

  return (
    <div id="credit-check-sidebar" className="credit-check-sidebar wide-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={{
            ...auth,
            ...contact,
            ...getIn(model, 'lease_application.first_applicant.meta.contact'),
            modules_available: modules,
            address_line_1: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.address_line_1'),
            address_line_2: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.address_line_2'),
            city: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.city'),
            province: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.province'),
            country: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.country'),
            postal_code: getIn(model, 'lease_application.first_applicant.meta.contact.physical_address.postal_code'),
            employer: getIn(model, 'lease_application.first_applicant.meta.contact.current_employer'),
            occupation: getIn(model, 'lease_application.first_applicant.meta.contact.occupation'),
            application: getIn(model, 'id'),
            contact: getIn(model, 'lease_application.first_applicant.contact')
          }}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            form.current = formik
            return (
              <CustomForm
                render={() => (
                  <div className="wide-sidebar-pane">
                    <div className="wide-sidebar-heading">
                      <h4>Credit Check</h4>
                      <Button
                        type="button"
                        icon="#icon24-X-Large"
                        className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                        onClick={() => { actions.toggleWideSidebar() }}
                      />
                    </div>
                    <Scrollbar
                      style={{ height: 'calc(100vh - 218px)' }}
                      renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                    >
                      <div className="wide-sidebar-content">
                        {report ? (
                          <div className="credit-report">
                            <h5>Reports Summary</h5>
                            <div className="report-details">
                              <h3 className="section-heading">Personal Information</h3>
                              <div className="report-information">
                                <div>
                                  <strong>Full Name: </strong><span>{getIn(report, 'summary.Full Name')}</span>
                                </div>
                                <div>
                                  <strong>ID Number: </strong><span>{getIn(report, 'summary.NationalId')}</span>
                                </div>
                                <div>
                                  <strong>Cell Number: </strong><span>{getIn(model, 'lease_application.first_applicant.meta.contact.cell_number')}</span>
                                </div>
                              </div>
                            </div>
                            <div className='report-modules'>
                              <h3 className="section-heading">Included Reports</h3>
                              <div className="report-tags">
                                {formik.values?.modules?.map(module => (
                                  <div key={`module-${module}`} className="tag-label">{module}</div>
                                ))
                                }
                              </div>
                            </div>
                            <div className='report-summary'>
                              {Object.keys(report.summary).filter(label => ![ 'Full Name', 'NationalId' ].includes(label)).map(label => {
                                const val = report.summary[label]
                                return (
                                  <div key={`summary-${label}`}>
                                    <strong>{label}: </strong><span>{val}</span>
                                  </div>
                                )
                              })}
                            </div>
                            <div className='report-buttons'>
                              <Button
                                component="a"
                                href={report.ReportURL}
                                target="_blank"
                                className="btn btn-grey"
                              >
                              View on TPN
                              </Button>
                              <Button
                                component="a"
                                href={report.PdfURL}
                                icon="#icon16-ArrowRight"
                                target="_blank"
                                className="btn btn-primary btn-icon-16 btn-icon-right"
                              >
                              Get PDF Report
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <>
                            { Object.keys(config.fieldgroups).map((group, gidx) => (
                              <FieldGroup
                                key={`fs-${gidx}`}
                                groupname={group}
                                group={config.fieldgroups[group]}
                                gidx={gidx}
                                classes={config.fieldgroups[group].classes}
                                fields={config.fields.filter(f => f.group === group).map(f => {
                                  if (f.name === 'modules' && modules.length) {
                                    f.options = modules.map(m => {
                                      const compiler = template(f.option_template)
                                      const htmlString = compiler({ option: m })
                                      return {
                                        value: m.id,
                                        label: valueFormat('domstring', htmlString)
                                      }
                                    })
                                  }
                                  return f
                                })}
                                creator={field?.name}
                                config={config}
                                match={match}
                                columns
                                render={({ renderFieldGroup, hidden }) => {
                                  if (hidden) { return null }
                                  return (
                                    <fieldset className={classNames('editgroup', config.fieldgroups[group].classes)}>
                                      <h5>{group}</h5>
                                      {renderFieldGroup(group)}
                                    </fieldset>
                                  )
                                }}
                              />
                            ))}
                            <div className="wide-sidebar-footer">
                              {(formik.values?.modules?.length) ? (
                                <Button
                                  onClick={() => {
                                    if (formik.values.modules.length) {
                                      setModules([])
                                    }
                                  }} // Required for type="button"
                                  type="button" // This cannot be submit otherwise sibling form is submitted
                                  disabled={loading}
                                  className="btn btn-primary"
                                >
                                Back
                                </Button>
                              ) : null}
                              <Button
                                onClick={formik.submitForm} // Required for type="button"
                                type="button" // This cannot be submit otherwise sibling form is submitted
                                disabled={loading}
                                className="btn btn-primary"
                              >
                                {getIn(auth, 'AuthToken') ? 'Submit' : 'Sign In'}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </Scrollbar>
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

CreditCheckSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  readOnly: PropTypes.bool,
  comment: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  note: PropTypes.object,
  modelid: PropTypes.number,
  match: PropTypes.object,
  model: PropTypes.object,
  sidebar: PropTypes.string,
  modelconfig: PropTypes.object
}

export default CreditCheckSidebar
