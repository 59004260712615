import PropTypes from 'prop-types'
import React from 'react'


const DetailsSection = ({ heading, children, ...props }) => {
  let display = false
  if (children && Array.isArray(children) && children.filter(c => c).length) {
    display = true
  } else if (children && !Array.isArray(children)) {
    display = true
  }
  return display ? (
    <div className={`details-section ${props.className ? props.className : ''}`}>
      {heading &&
      <div className="row">
        <h2 className="col details-section-heading">{heading}</h2>
      </div>
      }
      {children}
    </div>
  ) : null
}

DetailsSection.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

export default DetailsSection
