import FileSaver from 'file-saver'
import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'

import { slugify, valueFormat } from '../utils'


const Alerts = ({ alerts, actions }) => (

  <div id="alerts-sidebar" className="alerts-sidebar sidebar-right">
    <div className="alerts-container">
      <div className="alerts-heading">
        <h3>Alerts</h3>
        <div className="close-window">
          <button type="button" className="btn btn-alert-close-x"
            onClick={() =>
              actions.toggleAlerts()
            }
          ><svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-X-Large"></use></svg></button>
        </div>
      </div>
      <div className="alert-content">

        {alerts.length > 0 ? (
          alerts.map(a => {
            const ext = getIn(a, 'response.file', '').split('/').pop().split('.').pop()
            return (
              <div className="alert-item" key={`alert-${a.callback_id}`}>
                {a.model &&
                <div className="alert-detail domain">{a.model}</div>
                }
                <h4>{a.name}</h4>
                <div className="alert-detail">Requested: {valueFormat('datetime', a.created)}</div>
                <div className="alert-detail">Status: {a.text}</div>
                <div className="alert-action">
                  <div>
                    {a.response && a.response.file &&
                    <button
                      type='button'
                      target="_blank"
                      rel="noopener noreferrer"
                      // eslint-disable-next-line react/no-unknown-property
                      download={`${a.model}-${slugify(a.name)}-${slugify(valueFormat('datetime', a.created))}.${ext}`}
                      onClick={() => {
                        FileSaver.saveAs(a.response.file, `${a.model}-${slugify(a.name)}-${slugify(valueFormat('datetime', a.created))}.${ext}`)
                      }}
                      className="btn btn-primary">
                        Download
                    </button>}
                  </div>
                  <div>
                    <button type="button" className="btn btn-alert-close" onClick={() => actions.dismissAlert({ callback_id: a.callback_id })}>Dismiss</button>
                  </div>
                </div>
              </div>
            )
          })

        ) : (

          <div className="alert-item noalerts">
            <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Megaphone" /></svg>
            <h4>You have no alerts</h4>
            <p>There are no pending alerts for this session.</p>
          </div>

        )}
      </div>
    </div>
  </div>
)

Alerts.propTypes = {
  alerts: PropTypes.array,
  actions: PropTypes.object
}

export default Alerts
