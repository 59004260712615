import { agents } from './agents'
import { contacts } from './contacts'
import { residential } from './residential'
import { commercial } from './commercial'


const regions = {
  agents,
  contacts,
  residential,
  commercial
}
export default regions
