/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'
import { bindActionCreators } from 'redux'
import jwt_decode from 'jwt-decode'

import Canvassing from '../components/Canvassing'
import { MINUSER, AGENT, CONFIG, SEARCH } from '../selectors'
import * as all_actions from '../actions'
import QueryBuilder from '../components/common/QueryBuilder'


class CanvassingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.load = this.load.bind(this)
    this.message = this.message.bind(this)
    this.state = {
      loading: true,
      origin: ''
    }
  }


  componentDidMount() {
    window.addEventListener('message', this.message, false)

    const { location } = this.props
    const qs = new QueryBuilder(location.search)
    if (qs.getParam('token')) {
      const storedtoken = qs.getParam('token')
      const decoded = jwt_decode(storedtoken)
      this.load(storedtoken, decoded.agent_id, decoded.site_id)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.message)
  }

  message(event) {
    if (event.data.type === 'TOKEN') {
      const decoded = jwt_decode(event.data.data.token)
      const origin = event.data.data.origin
      this.load(event.data.data.token, decoded.agent_id, decoded.site_id, origin)
    }
  }

  load(storedtoken, agent, site, origin) {
    const { statetoken, actions } = this.props
    if (
      storedtoken && // We have a token
      [ null, undefined, '', 'null', 'undefined', false ].includes(statetoken) && // But there is no state auth yet
      ![ null, undefined, '', 'null', 'undefined', false ].includes(storedtoken)) { // Load token from storage if we aren't authenticated
      new Promise((resolve, reject) => { // Get a token
        actions.loadToke({ token: storedtoken, agent, site, redirect: this.state.redirect, resolve, reject }) // This also calls selectAgent on success
      }).then(() => { // redirect after authentication
        if (this) { this.setState({ loading: false, origin }) }
      }).catch(() => {})
    } else {
      this.setState({ loading: false, origin })
    }
  }

  render() {
    if (this.state.loading) { return <div>Loading</div> }
    return <Canvassing {...this.props} origin={this.state.origin} />
  }
}

function mapStateToProps(state) {
  const user = MINUSER(state)
  const agent = AGENT(state)
  const config = CONFIG(state, 'canvassing')
  const search = SEARCH(state)
  return {
    user: Map({
      id: user.get('id'),
      email: user.get('email'),
      is_prop_data_user: user.get('is_prop_data_user'),
      is_superuser: user.get('is_superuser')
    }),
    agent,
    config,
    search
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(all_actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(CanvassingContainer))
