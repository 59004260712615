import PropTypes from 'prop-types'
import React from 'react'
import * as Sentry from '@sentry/react'

import packageinfo from '../../../../package.json'


class AppError extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { throwTestError } = this.props
    return (
      <Sentry.ErrorBoundary fallback={({ eventId }) => (
        <div className="main">
          <div className="view viewerror container-fluid">
            <div className="row">
              <div className="col">
                <div className="errorboundary err">
                  <svg viewBox="0 0 576 512"><path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/></svg>
                  <h3>Something went wrong.</h3>
                  <p>Your web browser has already alerted our support team and informed them of the problem.</p>
                  <h4>Version: {packageinfo.version}</h4>
                  <p>Event ID: {eventId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      >
        {throwTestError ?
          <>
            {null}
          </>
          :
          <>
            {this.props.children}
          </>
        }
      </Sentry.ErrorBoundary>
    )
  }
}

AppError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  throwTestError: PropTypes.bool
}

export default AppError
