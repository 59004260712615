import * as yup from 'yup'
import messages from './messages.json'


export const teams = () => yup.object({
  name: yup.string().trim().max(150).required(messages.required),
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  branch: yup.number().typeError('Please select a valid option').integer().positive().required(messages.required),
  email: yup.string().email(messages.email).required(messages.required),
  agents: yup.mixed().when('active', (active, schema) => (active ? yup.array().typeError('Please select a valid option').of(yup.number().typeError('Please select a valid option').positive().integer()).required(messages.required) : schema.nullable())),
  team_leader: yup.mixed().when('active', (active, schema) => (active ? yup.number().typeError('Please select a valid option').positive().integer().required(messages.required) : schema.nullable())),
  facebook_url: yup.string().url(messages.url).max(200).nullable(),
  twitter_url: yup.string().url(messages.url).max(200).nullable(),
  linkedin_url: yup.string().url(messages.url).max(200).nullable(),
  blog_url: yup.string().url(messages.url).max(200).nullable(),
  youtube_url: yup.string().url(messages.url).max(200).nullable()
}).test('team_leader', // Needs to be present in branches
  schema => {
    const { team_leader, agents, active } = schema
    if (active && agents) {
      if (!agents.includes(team_leader)) {
        return new yup.ValidationError(messages.invalid, team_leader, 'team_leader')
      }
    }
    return true
  }
)

export const teams_activity = () => yup.object({
  created__date__gte: yup.string().required(messages.required),
  created__date__lte: yup.string().required(messages.required)
})
