/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { logEvent, handleSubmitError, resolveError, valueFormat } from '../utils'
import { Scrollbar } from './ui/Scrollbars'
import { Button } from './ui/Button'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import Loader from './common/Loader'


const VacancyProResult = props => {
  const { result, settings, user, toggleWideSidebar } = props
  const [ showContact, setShowContact ] = useState(false)
  let available_from_text = ''
  if (result.available_from === 'Immediate') {
    available_from_text = 'Immediate Availability'
  } else if (result.available_from === 'One Months Notice') {
    available_from_text = 'Available in 1 Month'
  } else {
    available_from_text = `Available - ${result.available_from}`
  }
  return (
    <div className="vacancy-wrapper">
      <div className="vacancy-image">
        <img alt={result.title} src={result.property_image} />
      </div>
      <div className="vacancy-data">
        <div className="vacancy-meta">
          <h5 dangerouslySetInnerHTML={{ __html: result.title }} />
          <span className="vacancy-address">{result.address}</span>
          <div className="vacancy-featured meta-group">
            <span><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Landsize" /></svg>{result.size} m{String.fromCodePoint(178)}</span>
            <strong>{result.property_type}</strong>
          </div>
          <div className="vacancy-featured meta-group company-user-detail">
            <div><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Company" /></svg>{result.agency}</div>
            <div className="vacancy-agent-detail">
              <svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-User" /></svg>
              <div className="vacancy-agent-fullname">
                <span>{result.agent}</span><br/>
              </div>
              {showContact ? (
                <div className="vacancy-show-contact-detail">
                  <span><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Phone" /></svg>{result.agent_mobile.replace(/["']/g, '')}</span><br/>
                  <span className="vacancy-agent-email">{result.agent_email}</span>
                </div>
              ) : (
                <div className="vacancy-show-agent-contact-detail">
                  <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Phone" /></svg>
                  <Button
                    type="button"
                    className="btn btn-none btn-red-text"
                    onClick={() => {
                      setShowContact(true)
                      logEvent('VACANCYPRO_VIEW_CONTACT_DETAIL_SUCCESS',
                        { id: result.id,
                          address: result.address,
                          broker_name: result.agent,
                          site: settings.site,
                          user: user && user.agent ? `${user.agent.first_name} ${user.agent.last_name}` : '',
                          company_name: result.agency
                        }
                      )
                    }}
                  >
                    Show Contact Details
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="vacancy-price">
          <div>
            <h5>{available_from_text}</h5>
            <h5>{valueFormat('currency', result.price, { currency: 'ZAR' })}</h5>
            <span>{valueFormat('currency', result.price / result.size, { currency: 'ZAR' })} per m{String.fromCodePoint(178)}</span>
          </div>
          <Button
            type="button"
            className="btn btn-primary"
            component={Link}
            to={`/secure/${settings.site}/commercial/add?model=vacancypro&id=${result.id}`}
            onClick={() => {
              logEvent('VACANCYPRO_SELECTED_VACANCY', result)
              toggleWideSidebar()
            }}
          >
            List Now
          </Button>
        </div>
      </div>
    </div>
  )
}

VacancyProResult.propTypes = {
  result: PropTypes.object,
  settings: PropTypes.object,
  user: PropTypes.object,
  toggleWideSidebar: PropTypes.func
}


class VacancyProSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      initvals: {},
      config: {
        fieldgroups: {
          'Keyword Search': { classes: 'keyword-search' },
          Features: {}
        },
        fields: [
          {
            name: 'keyword',
            edit: true,
            group: 'Keyword Search',
            placeholder: 'Search building name, street or keyword',
            suffix: <Button icon="#icon24-Search" type="button" onClick={() => this.form.submitForm() } className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
          },
          {
            name: 'suburb',
            label: 'Suburb',
            group: 'Features',
            input: 'AsyncSelect',
            modelname: 'locations',
            edit: true,
            labelseparator: ', ',
            optionlabel: [ 'area', 'suburb' ],
            labelformat: {
              head: ':area, :suburb',
              sub: ':province, :country'
            },
            endpoint: {
              read: '/locations/api/v1/locations/vacancy-pro-populated-suburbs/'
            },
            required: true,
            labelgrouper: 'country',
            extraparams: 'order_by=area__province__country__country,area__province__province,area__area,suburb&trigram_fields=suburb,area__area&area__province__country__id__in=:supported_countries',
            cols: 'lg-6'
          },
          {
            name: 'type',
            input: 'Select',
            group: 'Features',
            edit: true,
            label: 'Property Type',
            required: true,
            options: [
              { value: 'Office', label: 'Office' },
              { value: 'Retail', label: 'Retail' },
              { value: 'Industrial', label: 'Industrial' }
            ],
            cols: 'lg-6'
          },
          {
            name: 'min_size',
            edit: true,
            group: 'Features',
            label: 'Min Size',
            required: true,
            cols: 'lg-6',
            suffix: <div className="input-group-addon btn btn-icon-24 btn-none">m{String.fromCodePoint(178)}</div>
          },
          {
            name: 'max_size',
            edit: true,
            group: 'Features',
            label: 'Max Size',
            required: true,
            cols: 'lg-6',
            suffix: <div className="input-group-addon btn btn-icon-24 btn-none">m{String.fromCodePoint(178)}</div>
          },
          {
            name: 'min_price',
            edit: true,
            group: 'Features',
            label: 'Min Price',
            input: 'Currency',
            cols: 'lg-6'
          },
          {
            name: 'max_price',
            edit: true,
            group: 'Features',
            label: 'Max Price',
            input: 'Currency',
            cols: 'lg-6'
          }
        ]
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
  }

  componentDidMount() {
    if (this.el && this.props.sidebar !== 'show-vacancy-sidebar') {
      setTimeout(() => {
        this.props.actions.toggleWideSidebar('show-vacancy-sidebar')
      }, 50)
    }
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  handleSubmit(values, actions) {
    return new Promise((resolve, reject) => {
      const vals = { ...values } // Make a copy as we are mutating
      Object.keys(vals).forEach(k => {
        const field = this.state.config.fields.find(f => f.name === k)
        if (field && field.input === 'Float') { vals[k] = vals[k] ? parseFloat(vals[k]).toFixed(1) : vals[k] }
        if (vals[k] === null || vals[k] === '') { delete vals[k] }
      })
      logEvent('VACANCYPRO_LOOKUP', { ...values })
      this.props.actions.fetchVacancyPro(vals, resolve, reject) // Perform the lookup
    }).then(r => {
      actions.setSubmitting(false)
      this.setState({ results: r })
    }).catch(e => {
      this.handleSubmitError(e, actions, this.form)
      this.props.actions.notifyUser({ title: 'Error', body: resolveError(e, this.state.config.fields), type: 'error' })
      actions.setSubmitting(false)
    })
  }

  render () {
    const { user, settings, actions } = this.props
    return (
      <div id="deeds-lookup-sidebar" ref={el => { this.el = el }} className="deeds-lookup-sidebar wide-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={{
              ...this.state.initvals
            }}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={() => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>vacancyPro Lookup</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          { Object.keys(this.state.config.fieldgroups).map((group, gidx) => (
                            <FieldGroup
                              card={false}
                              key={`g-${gidx}`}
                              group={this.state.config.fieldgroups[group]}
                              groupname={group}
                              gidx={gidx}
                              fields={this.state.config.fields}
                              config={this.state.config}
                              creator={this.props.field && this.props.field.name}
                              modelname={'vacancypro'}
                              match={this.props.match}
                              required={this.state.required}
                              columns
                              collapsed={false}
                              render={({ renderFieldGroup, hidden }) => {
                                if (hidden) { return null }
                                return (
                                  <fieldset className={classNames('editgroup', this.state.config.fieldgroups[group].classes)}>
                                    <h5>{group}</h5>
                                    {renderFieldGroup(group)}
                                  </fieldset>
                                )
                              }}
                            />
                          ))}
                          <div className="wide-sidebar-footer">
                            <Button
                              onClick={formik.submitForm} // Required for type="button"
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              disabled={formik.isSubmitting}
                              className="btn btn-primary">{formik.isSubmitting ? <Loader inline /> : 'Search'}</Button>
                          </div>
                          {(this.state.results && this.state.results.length) ? (
                            <fieldset className="editgroup">
                              <h5>Results</h5>
                              <div className="vacancy-list">
                                {this.state.results.map((result, midx) =>
                                  <VacancyProResult
                                    key={`vacancy-${midx}`}
                                    result={result}
                                    settings={settings}
                                    user={user}
                                    toggleWideSidebar={actions.toggleWideSidebar}
                                  />
                                )}
                              </div>
                            </fieldset>
                          ) : null }
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

VacancyProSidebar.propTypes = {
  field: PropTypes.object,
  toggleLookup: PropTypes.func,
  sidebar: PropTypes.string,
  ownerfield: PropTypes.string,
  actions: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  settings: PropTypes.object
}

export default VacancyProSidebar
