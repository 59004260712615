import React from 'react'
import PropTypes from 'prop-types'
import { Formik, getIn } from 'formik'

import Card from '../common/Card'
import ModelActions from '../common/ModelActions'
import CustomForm from '../common/forms/CustomForm'
import * as validate from '../../validate'
import withReport from './ReportComponent'


class DefaultActivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      initvals: {},
      template_cache: {},
      templates: [],
      template_configs: [],
      fields: props.config.reports.fields
    }
  }

  componentDidMount() {
    const { modelname } = this.props.config
    this.fetchTemplates()
    if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
      // Fetch locations automatically for listings models
      const params = { get_count: 1, status_in: 'Active' }
      new Promise((resolve, reject) => this.props.actions.fetchLocations(
        { values: { modelname, params }, resolve, reject })
      ).then(locations => this.setState({ ...locations }))
    }
  }

  componentDidUpdate() {
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + 66
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  render() {
    const { config, actions } = this.props
    const { template_config: selected_config, template_configs } = this.state
    return (
      <Formik
        validationSchema={validate[`${config.modelname}_activity`]}
        initialValues={this.state.initvals}
        enableReinitialize
        onSubmit={(values, form) => {
          const { template, ...vals } = values
          Object.keys(vals).forEach(k => {
            // Remove empty values
            if ([ null, undefined ].includes(vals[k])) {
              delete vals[k]
            }
            if (Array.isArray(vals[k]) && !vals[k].length) {
              delete vals[k]
            }
          })
          setTimeout(() => {
            form.setSubmitting(false) // Set to false here so form can be submitted again
          }, 300)
          return new Promise((resolve, reject) => {
            const params = {
              params: vals,
              args: {
                action: 'report',
                template
              },
              modelname: config.modelname,
              label: template_configs.find(f => f.name === template).display_name,
              resolve,
              reject
            }
            return actions.exportData(params)
          }).then(() => {
            form.setSubmitting(false)
          }).catch(() => {
            form.setSubmitting(false)
          })
        }}
      >{formik => {
          this.form = formik
          const template = this.state.templates.find(e => formik.values.template === e.value)
          return (
            <div id="content" className="content">
              <div className="viewhead details">
                <div className="action-bar">
                  <ModelActions
                    touched={formik.touched}
                    errors={formik.errors}
                    isSubmitting={formik.isSubmitting}
                    redirectSchema={this.redirectSchema}
                    form={formik}
                    modelname={config.modelname}
                    extras={{
                      save: {
                        label: 'Generate Report',
                        menu: null,
                        redirect: null,
                        icon: '#icon16-Download',
                        action: 'submitForm',
                        className: 'btn-round btn-red'
                      }
                    }}
                    statusmsg={formik.status ? formik.status.msg : false}
                  />
                </div>
              </div>
              <div className={`view details reports ${config.modelname}`}>
                <div className="viewcontent">
                  <div className="report-form">
                    <Card
                      background={true}
                      header={
                        <h2>Report Generator</h2>
                      }
                      body={
                        <CustomForm
                          onChange={() => {
                            if (this.state.initvals.template && this.form && !formik.touched.template) {
                              formik.setFieldTouched('template') // enable button as soon as templates are loaded
                            }
                            if (template) {
                              const template_config = this.state.template_cache[template.value]
                              if ((!selected_config && template_config) || (getIn(selected_config, 'name') !== template.value)) {
                                const { fields, initvals } = this.initModel(template_config)
                                this.setState({
                                  template_config,
                                  fields,
                                  initvals: { ...formik.values, ...initvals }
                                })
                              }
                            }
                          }}
                          render={ () => (
                            <div className="search-fields">
                              {this.addVerb(formik)}
                            </div>
                          )}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Formik>
    )
  }
}

DefaultActivity.propTypes = {
  report: PropTypes.string,
  model: PropTypes.string,
  config: PropTypes.object,
  actions: PropTypes.object,
  models: PropTypes.object,
  cache: PropTypes.object
}

export default withReport(DefaultActivity)
