/* eslint-disable new-cap */
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { handleSubmitError, isConditional } from '../utils'
import { CACHE, CONFIG, SETTINGS, SITE, MINUSER } from '../selectors'
import CustomForm from './common/forms/CustomForm'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
import FieldComponent from './common/forms/FieldComponent'


class ListingFiltersSidebar extends React.Component {
  constructor(props) {
    super(props)
    const property_types = props.config.fields.find(f => f.name === 'property_type')
    this.state = {
      initvals: { ...props.autofill, active: true },
      fields: [
        {
          name: 'distance__lte',
          label: 'Radius from Property',
          input: 'Select',
          edit: true,
          options: [
            {
              value: 1,
              label: '1km'
            },
            {
              value: 3,
              label: '3km'
            },
            {
              value: 5,
              label: '5km'
            },
            {
              value: 10,
              label: '10km'
            },
            {
              value: 15,
              label: '15km'
            },
            {
              value: 20,
              label: '20km'
            },
            {
              value: 25,
              label: '25km'
            }
          ]
        },
        {
          ...property_types,
          name: 'property_type__in',
          label: 'Property Types',
          input: 'Select',
          edit: true,
          multi: true
        }
      ]
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.isConditional = isConditional.bind(this)
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  handleSubmit(values) {
    const { params } = this.props
    Object.keys(values).forEach(k => {
      params[k] = values[k]
    })
    this.props.actions.updateParams(params)
    this.props.actions.toggleWideSidebar()
  }

  render () {
    const { cache, user, data, params } = this.props
    return (
      <div id="contact-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar contact-creator-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={{
              distance__lte: params.distance__lte,
              property_type__in: params.property_type__in,
              distance_latlong: `${data.lightstone_data.propertyDetails.cadastrePoint.coordinates[1].toString()},${data.lightstone_data.propertyDetails.cadastrePoint.coordinates[0].toString()}`
            }}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={() => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>Filter Properties</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          <div className='input-group'>
                            {this.state.fields.map((f, fidx) => <FieldComponent field={f} form={formik} key={`lfs-${fidx}`} user={user} cache={cache} />)}
                          </div>
                          <div className="wide-sidebar-footer">
                            <Button
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              onClick={formik.submitForm}
                              disabled={formik.isSubmitting}
                              className="btn btn-primary"
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

ListingFiltersSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  sidebar: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func,
  data: PropTypes.object,
  params: PropTypes.object
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let config
  const { modelname } = ownProps
  const site = SITE(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state)
  const user = MINUSER(state)
  const cache = CACHE(state)
  if (modelname === 'residential') {
    config = CONFIG(state, 'residential')
  }
  if (modelname === 'commercial') {
    config = CONFIG(state, 'commercial')
  }

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
  }

  return {
    user,
    cache: mincache,
    settings,
    config
  }
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ListingFiltersSidebar))
