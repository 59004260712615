/* eslint-disable new-cap */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import date_options from '../../config/date-options.json'
import { PORTALS, MINUSER } from '../../selectors'
import { hasPermission, textToDate, valueFormat, buildOptionLabel } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import SimpleTable from '../common/simpletable/SimpleTable'
import { Button } from '../ui/Button'
import withDelay from './withDelay'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class SyndicationWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'LAST_30_DAYS')
    const period = option.value
    const { start, end } = textToDate(option.value)
    this.state = {
      count: 0,
      prev_count: 0,
      period,
      negative: false,
      branches: [],
      branch_options: [],
      listing_options: [],
      active_portals: [],
      listing_model: '',
      portal_stats: {},
      branch_id: '',
      p24_status: 'failed',
      pp_status: 'failed',
      loading: true,
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      hide: false
    }
    this.filterLogs = this.filterLogs.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    this._is_mounted = true
    const { actions, user, portals } = this.props
    if (portals) {
      const active_portals = Object.keys(portals).map(pid => portals[pid]).filter(p => [ 'Property24', 'Private Property' ].includes(p.meta.portal.name))
      if (!active_portals.length) {
        this.setState({ hide: true })
      } else {
        this.setState({ active_portals })
      }
    }
    const listing_options = []
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own',
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential', label: 'Residential Listings' })
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own',
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial', label: 'Commercial Listings' })
    }
    if (hasPermission([
      'listings_projects_view',
      'listings_projects_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'project', label: 'Project Listings' })
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'holiday', label: 'Holiday Listings' })
    }
    this.setState({ listing_options })
    let branches
    if (!hasPermission([
      'apply_to_all_branches'
    ], user.permissions, null, user.agent.id)) {
      branches = user.agent.branches
    }
    new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'branches',
        optionvalue: 'id',
        optionlabel: 'name',
        fields: [ 'id', 'name' ],
        active: 1,
        all: true,
        select: true,
        params: {
          id__in: branches,
          order_by: 'name'
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(r => {
      if (this._is_mounted) {
        const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
        this.setState({ branches: r.options, branch_options })
      }
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
    let branch_id = this.state.branch_id
    if (user.agent) {
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && user.agent.branches.length === 1
        && !branch_id
      ) {
        branch_id = user.agent.branches[0]
      }
    }
    if (branch_id !== this.state.branch_id) {
      this.setState({ branch_id })
    }
    this.filterLogs()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branch_id !== this.state.branch_id
      || prevState.listing_model !== this.state.listing_model
      || prevState.period !== this.state.period
      || !isEqual(prevState.active_portals, this.state.active_portals)) {
      this.filterLogs()
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  filterLogs() {
    const { actions } = this.props
    const { branch_id, listing_model, active_portals } = this.state
    const params = {
      active: 1,
      order_by: '-modified',
      get_count: 1
    }
    if (branch_id) {
      if (listing_model) {
        params[`${listing_model}__branch`] = branch_id
      } else {
        params.listing__branch = branch_id
      }
    }
    if (listing_model) {
      params[`${listing_model}__isnull`] = 'false'
      params[`${listing_model}__status`] = 'Active'
    } else {
      params.listing__status = 'Active'
    }
    const { current, limit } = this.state
    const counts = active_portals.map(p => new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'portal-configs',
        endpoint: {
          read: '/listings/api/v1/portal-configs/status-counts'
        },
        params: {
          ...params,
          portal: p.portal,
          modified__date__gte: current,
          modified__date__lte: limit
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(result => ({ portal: p.portal, counts: result })).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }))

    Promise.allSettled(counts.flat()).then(results => {
      const portal_stats = {}
      results.forEach(result => {
        if (!result.value) { return }
        portal_stats[result.value.portal] = result.value.counts
      })
      if (this._is_mounted) {
        this.setState({ portal_stats })
      }
    })
  }

  render() {
    const { actions, user } = this.props
    const { branch_id, listing_model, hide, active_portals,
      portal_stats, p24_status, pp_status, current, limit } = this.state

    if (hide) {
      return null
    }

    const p24 = active_portals.find(p => p.meta.portal.name === 'Property24')

    const pp = active_portals.find(p => p.meta.portal.name === 'Private Property')

    const init_params = {
      active: 1,
      order_by: '-modified'
    }
    if (branch_id) {
      init_params.residential__branch__or = branch_id
      init_params.commercial__branch__or = branch_id
      init_params.project__branch__or = branch_id
      init_params.holiday__branch__or = branch_id
    }
    if (listing_model) {
      init_params[`${listing_model}__isnull`] = 'false'
    }
    return (
      <Card
        id="syndication-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding syndication-widget"
        background
        header={
          <>
            <h3>Syndication</h3>
            <div className="details-section-buttons min-flex tablemeta">
              <div className="filter-branch">
                <InlineSelect
                  id="branch_id"
                  name="branch_id"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                  defaultValue={{ label: 'All Branches', value: '' }}
                  selectedValue={getIn(this.state, 'branch_id')}
                  onChange={e => {
                    this.setState({ branch_id: e.value })
                  }}
                />
              </div>
              <div className="filter-listing-model">
                <InlineSelect
                  id="listing_model"
                  name="listing_model"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Listing Types', value: '' }, ...this.state.listing_options ]}
                  defaultValue={{ label: 'All Listing Types', value: '' }}
                  selectedValue={getIn(this.state, 'listing_model')}
                  onChange={e => {
                    this.setState({ listing_model: e.value })
                  }}
                />
              </div>
              <div className="filter-date-range">
                <InlineSelect
                  id="period"
                  name="period"
                  className="inline-select"
                  classNamePrefix="inline"
                  defaultValue={date_options.find(o => o.value === 'LAST_30_DAYS')}
                  selectedValue={getIn(this.state, 'period')}
                  options={date_options.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW'))}
                  onChange={e => {
                    const period = e.value
                    const { start, end } = textToDate(period)
                    if (this._is_mounted) {
                      this.setState({ period, current: valueFormat('shortdate', start.toString()), limit: valueFormat('shortdate', end.toString()) })
                    }
                  }}
                  components={{
                    Option: CustomOption
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          <div className={classNames('flex-container', { 'flex-1': active_portals.length === 1, 'flex-2': active_portals.length === 2 })}>
            {p24 ? (
              <>
                <div className='syndication-summary syndication-property24'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/property24.png`} alt="Property 24" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-failures">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ p24_status: 'failed' })
                        }} className={classNames({ active: p24_status === 'failed' })}>
                          <strong>FAILURES</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${p24.portal}.failed`, 0))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-pending">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ p24_status: 'pending' })
                        }} className={classNames({ active: p24_status === 'pending' })}>
                          <strong>PENDING</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${p24.portal}.pending`, 0))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-success">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ p24_status: 'success' })
                        }} className={classNames({ active: p24_status === 'success' })}>
                          <strong>SUCCESS</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${p24.portal}.success`, 0) + getIn(portal_stats, `${p24.portal}.withdrawn`, 0))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <SimpleTable
                    config={{}}
                    action={({ params, resolve, reject }) => new Promise((res, rej) => actions.fetchMany({
                      noloader: true,
                      values: {
                        modelname: 'portal-configs',
                        endpoint: {
                          read: '/listings/api/v1/portal-configs'
                        },
                        signal: this.AbortController.signal,
                        params: {
                          ...params,
                          meta_fields: [ 'residential', 'commercial', 'project', 'holiday' ],
                          portal: p24.portal
                        }
                      },
                      resolve: res,
                      reject: rej
                    })).then(r => {
                      resolve(r)
                    }).catch(e => {
                      reject(e)
                    })}
                    params={{
                      ...init_params,
                      fields: [
                        'id',
                        'modified',
                        'last_message',
                        'residential',
                        'commercial',
                        'project',
                        'holiday',
                        'unit_number',
                        'complex_name',
                        'building_name',
                        'street_number',
                        'street_name',
                        'model'
                      ],
                      feed_status: p24_status,
                      modified__date__gte: current,
                      modified__date__lte: limit,
                      limit: 20
                    }}
                    parser={data => {
                      data.options = data.options.map(o => {
                        o.listing = null
                        if (getIn(o, 'residential')) {
                          o.listing = getIn(o, 'residential')
                          o.meta.listing = getIn(o.meta, 'residential')
                        } else if (getIn(o, 'commercial')) {
                          o.listing = getIn(o, 'commercial')
                          o.meta.listing = getIn(o.meta, 'commercial')
                        } else if (getIn(o, 'project')) {
                          o.listing = getIn(o, 'project')
                          o.meta.listing = getIn(o.meta, 'project')
                        } else if (getIn(o, 'holiday')) {
                          o.listing = getIn(o, 'holiday')
                          o.meta.listing = getIn(o.meta, 'holiday')
                        }
                        return o
                      })
                      return data
                    }}
                    header={[
                      {
                        label: 'Address',
                        name: [
                          'listing.unit_number',
                          'listing.complex_name',
                          ',',
                          'listing.building_name',
                          ',',
                          'listing.street_number',
                          'listing.street_name'
                        ],
                        container: 'meta',
                        orderable: false,
                        link: '/secure/:meta.listing.model/:listing'
                      },
                      {
                        label: 'Reason',
                        name: 'last_message',
                        orderable: false
                      },
                      {
                        label: 'Date',
                        name: 'modified',
                        orderable: false,
                        format: 'datetime'
                      }
                    ]}
                    user={user}
                  />
                  <div className="list-actions">
                    {listing_model ? (
                      <Button component={NavLink} className="btn btn-subtle" to={`/secure/syndication/${listing_model}`}>View entire log</Button>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
            {pp ? (
              <>
                <div className='syndication-summary syndication-private-property'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/private-property.png`} alt="Private Property" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-failures">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ pp_status: 'failed' })
                        }} className={classNames({ active: pp_status === 'failed' })}>
                          <strong>FAILURES</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${pp.portal}.failed`, 0))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-pending">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ pp_status: 'pending' })
                        }} className={classNames({ active: pp_status === 'pending' })}>
                          <strong>PENDING</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${pp.portal}.pending`, 0))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-success">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ pp_status: 'success' })
                        }} className={classNames({ active: pp_status === 'success' })}>
                          <strong>SUCCESS</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${pp.portal}.success`, 0) + getIn(portal_stats, `${pp.portal}.withdrrawn`, 0))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <SimpleTable
                    config={{}}
                    action={({ params, resolve, reject }) => new Promise((res, rej) => actions.fetchMany({
                      noloader: true,
                      values: {
                        modelname: 'portal-configs',
                        endpoint: {
                          read: '/listings/api/v1/portal-configs'
                        },
                        signal: this.AbortController.signal,
                        params: {
                          ...params,
                          meta_fields: [ 'residential', 'commercial', 'project', 'holiday' ],
                          portal: pp.portal
                        }
                      },
                      resolve: res,
                      reject: rej
                    })).then(r => {
                      resolve(r)
                    }).catch(e => {
                      reject(e)
                    })}
                    params={{
                      ...init_params,
                      feed_status: pp_status,
                      modified__date__gte: current,
                      modified__date__lte: limit,
                      limit: 20
                    }}
                    parser={data => {
                      data.options = data.options.map(o => {
                        o.listing = null
                        if (getIn(o, 'residential')) {
                          o.listing = getIn(o, 'residential')
                          o.meta.listing = getIn(o.meta, 'residential')
                        } else if (getIn(o, 'commercial')) {
                          o.listing = getIn(o, 'commercial')
                          o.meta.listing = getIn(o.meta, 'commercial')
                        } else if (getIn(o, 'project')) {
                          o.listing = getIn(o, 'project')
                          o.meta.listing = getIn(o.meta, 'project')
                        } else if (getIn(o, 'holiday')) {
                          o.listing = getIn(o, 'holiday')
                          o.meta.listing = getIn(o.meta, 'holiday')
                        }
                        return o
                      })
                      return data
                    }}
                    header={[
                      {
                        label: 'Address',
                        name: [
                          'listing.unit_number',
                          'listing.complex_name',
                          ',',
                          'listing.building_name',
                          ',',
                          'listing.street_number',
                          'listing.street_name'
                        ],
                        container: 'meta',
                        orderable: false,
                        link: '/secure/:meta.listing.model/:listing'
                      },
                      {
                        label: 'Reason',
                        name: 'last_message',
                        orderable: false
                      },
                      {
                        label: 'Date',
                        name: 'modified',
                        orderable: false,
                        format: 'datetime'
                      }
                    ]}
                    user={user}
                  />
                  <div className="list-actions">
                    {listing_model ? (
                      <Button component={NavLink} className="btn btn-subtle" to={`/secure/syndication/${listing_model}`}>View entire log</Button>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        }
      />
    )
  }
}


SyndicationWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  portals: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const portals = PORTALS(state)
  return ({
    user,
    portals
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(SyndicationWidget)))
