import * as yup from 'yup'
import messages from './messages.json'


export const reset = () => yup.object({
  password: yup.string().required(messages.required),
  password_again: yup.string().required(messages.required)
}).test(
  'password_again',
  schema => {
    const {
      password,
      password_again
    } = schema
    if (password_again && password_again !== password) {
      return new yup.ValidationError('Passwords do not match', password_again, 'password_again')
    }
    return true
  }
)
