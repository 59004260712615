import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'

import { getRandomColor, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import AgentCard from '../common/AgentCard'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import MetaDetail from '../common/MetaDetail'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import BarGraph from '../ui/graphs/BarGraph'
import PieChart from '../ui/graphs/PieChart'
import SocialMedia from '../ui/SocialMedia'


class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: false,
      pie_chart: null
    }
    this.getSourcesStat = this.getSourcesStat.bind(this)
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      this.props.actions.fetchLeadsBreakdown(
        {
          modelname: this.props.config.modelname,
          params: {
            active__in: '1',
            team__in: `${this.props.model.id}`
          },
          resolve,
          reject
        }
      )
    }).then(r => {
      this.getSourcesStat(r.leads.source_counts, 'lead_sources')
    }).catch(() => {})
  }

  async getSourcesStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    const array_data = Object.keys(data)
      .map(k => ({ source: k, value: data[k] }))
      .sort((a, b) => (b.value - a.value))
    this[`${state_key}_fetching`] = true
    const total = array_data.reduce((prevValue, nextValue) => prevValue + nextValue.value, 0)
    const top_9 = array_data.slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = source.source
      const count = source.value
      if (count) {
        slices.push({
          name: `${source_key} (${Math.round(count / total * 100)}%)`,
          value: count,
          percentage: count / top_9_total,
          colour: getRandomColor(slices.length)
        })
      }
    })
    const other_totals = total - top_9_total
    if (other_totals) {
      slices.unshift({
        name: `Other (${Math.round(other_totals / total * 100)}%)`,
        value: other_totals,
        percentage: 0,
        percentage_100: 0,
        colour: getRandomColor(slices.length)
      })
    }
    this.setState({ pie_chart: slices })
    return slices
  }

  render_address (address, type) {
    if (!address) { return false }
    const newaddress = [ ]
    let c = 0
    for (const line of address.split(',')) {
      newaddress.push(React.createElement('div', { key: `${type}-${c}` }, line.trim()))
      c += 1
    }
    return newaddress
  }

  render() {
    const {
      actions,
      model,
      cache,
      user,
      match,
      config,
      routeConfig
    } = this.props

    const listingstats = [
      [ 'rfs_ac', 'RFS' ],
      [ 'rtl_ac', 'RTL' ],
      [ 'cfs_ac', 'CFS' ],
      [ 'ctl_ac', 'CTL' ],
      [ 'rd', 'RD' ],
      [ 'cd', 'CD' ],
      [ 're', 'RE' ],
      [ 'ce', 'CE' ],
      [ 'h_ac', 'HL' ]
    ]

    const { statistics } = model.meta
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = getIn(cache, `settings.${selected_site}.meta.statistics`, {})

    const listing_stats = []
    let listing_count = 0
    let listing_count_avg = 0
    listingstats.forEach(stat => {
      const avg = sitestats.active_teams ? Math.round(sitestats[stat[0]] / sitestats.active_teams) : 0
      listing_stats.push({ name: stat[1], Team: statistics[stat[0]] || 0, 'Company Avg': avg })
      listing_count += statistics[stat[0]]
      listing_count_avg += avg
    })

    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={this.props.match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        <Tab tab="dashboard" label="Dashboard">
          <div className="grid-container">
            <div className="grid-rows grid-col-4 grid-container">
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Listings</strong>
                }
                body={
                  <>
                    <h1>{listing_count}</h1>
                    <span>Company Avg: {listing_count_avg}</span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Leads</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_electronic_leads}</h1>
                    <span>
                      Company Avg: {sitestats.active_teams ? (
                        Math.round(sitestats.active_electronic_leads / sitestats.active_teams)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Profiles</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_profiles}</h1>
                    <span>
                      Company Avg: {sitestats.active_profiles ? (
                        Math.round(sitestats.active_profiles / sitestats.active_profiles)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Team Members</strong>
                }
                body={
                  <>
                    <h1>{statistics.members}</h1>
                    <span></span>
                  </>
                }
              />
            </div>
            <Card
              classes="grid-col-3"
              bodyclass="bar-graph"
              background
              header={
                <h3>Listings Breakdown</h3>
              }
              body={
                <BarGraph data={listing_stats} dataKeys={[ 'Team', 'Company Avg' ]} dataColors={[ getRandomColor(1), getRandomColor(0) ]} />
              }
            />
            <Card
              classes="grid-col-1"
              bodyclass="pie-chart"
              background
              header={
                <h3>Leads Breakdown</h3>
              }
              body={this.state.pie_chart ? (
                <PieChart data={this.state.pie_chart} />
              ) : null
              }
            />
            <Card
              classes="primary-bg grid-col-1 no-grow"
              bodyclass="flex-container justify-center"
              background
              body={() => {
                const agent = cache.agents[model.team_leader] || model.meta.team_leader
                return agent ? (
                  <AgentCard title="Team Leader" agent={agent} />
                ) : null
              }}
            />
            <Card
              classes="grid-col-3"
              bodyclass="flex-container grid-3"
              background
              body={() => {
                if (![ model.agents ].some(f => f)) {
                  return null
                }
                return (
                  <>
                    {model.agents.filter(a => a !== model.team_leader).map((fid, fidx) => {
                      const agent = cache.agents[fid] || model.meta.agents[fidx]
                      return (
                        <AgentCard key={`member-${fidx}`} title="Member" agent={agent} />
                      )
                    })}
                  </>
                )
              }}
            />
            <Card
              classes="grid-col-4"
              background
              header={
                <h3>Contact Details</h3>
              }
              body={() => {
                const { map_image } = model.meta
                return (
                  <div className="input-group gap-20 space-evenly">
                    {map_image && <div className="branchmap">
                      {valueFormat('image', { file: map_image.file, width: 1024, height: 768 })}
                      <div className="marker-dot"></div>
                    </div>}
                    <div className="contact-details no-wrap">
                      {[ 'name', 'email' ].map((f, fidx) => {
                        const field = config.fields.find(fe => fe.name === f)
                        let val = model[field.name]
                        if (field.modelname || field.metafield) {
                          if (field.optionlabel) {
                            val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                          }
                        }
                        return (
                          <MetaDetail
                            className={'dashboard-meta'}
                            key={`f${fidx}`}
                            label={field.label}
                            value={val}
                            format={field.format}
                            field={field}
                          />
                        )
                      }
                      )}
                    </div>
                    <div className="address-details">
                      {model.physical_address && <div className="branch-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Physical Address</div>
                          <span>{valueFormat('domstring', model.physical_address)}</span>
                        </div>
                      </div>}
                      {model.postal_address && <div className="branch-postal-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Postal Address</div>
                          <span>{valueFormat('domstring', model.postal_address)}</span>
                        </div>
                      </div>}
                    </div>
                    <SocialMedia model={model} />
                  </div>
                )
              }}
            />
          </div>
        </Tab>


        <Tab tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="media" label="Media">
          <MediaGallery
            media={[
              {
                items: model.meta.images,
                mediatype: 'image',
                fieldname: 'images',
                plural: 'images',
                label: 'Images'
              }, {
                items: model.meta.documents,
                mediatype: 'documents',
                fieldname: 'documents',
                plural: 'documents',
                label: 'Documents'
              }
            ]}
            actions={{
              downloadImages: actions.downloadImages,
              isConditional: this.isConditional
            }}
            config={config}
            model={model}
            modelname={config.modelname}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>
      </HorizontalTabs>
    )
  }
}

Team.propTypes = {
  selected: PropTypes.array,
  model: PropTypes.object,
  actions: PropTypes.object,
  title: PropTypes.string,
  config: PropTypes.object,
  cache: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Team
