import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { resolveError, valueFormat } from '../../utils'
import validate from '../../validate'
import { Button } from '../ui/Button'
import { Scrollbar } from '../ui/Scrollbars'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'


class NoteCreator extends React.Component {
  constructor(props) {
    super(props)
    let initvals = {}
    const fields = []
    props.config.fields.forEach(field => {
      if (field.twin && props.siblingform.values[field.name]) {
        initvals[field.name] = props.siblingform.values[field.name]
      }
      if (props.note && !props.comment && !props.readOnly) {
        initvals = { ...props.note }
      }
      if (props.comment) {
        if (field.name === 'category') {
          initvals.category = 'Comment'
        }
        field = { ...field, edit: false }
        if (field.name === 'content') {
          if (typeof props.comment === 'object') {
            initvals.content = props.comment.content
            initvals.id = props.comment.id
          }
          field.label = 'Comment'
          field.edit = true
        }
      }
      if (props.readOnly) {
        field = { ...field, edit: false }
        if (field.name === 'relations') {
          initvals.relations = props.note.relations
          field.edit = true
          field.readonly = true
        }
      }
      fields.push(field)
    })
    this.state = {
      initvals,
      fields
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFiles = this.setFiles.bind(this)
  }

  componentDidMount() {
    let initvals = {}
    const fields = []
    this.props.config.fields.forEach(field => {
      if (field.twin && this.props.siblingform.values[field.name]) {
        initvals[field.name] = this.props.siblingform.values[field.name]
      }
      if (this.props.note && !this.props.comment) {
        initvals = { ...this.props.note }
      }
      if (this.props.comment) {
        initvals.relations = [ { related_model: 'comments', related_id: this.props.note ? this.props.note.id : null } ]
        if (field.name === 'category') {
          initvals.category = 'Comment'
        }
        field = { ...field, edit: false }
        if (field.name === 'content') {
          if (typeof this.props.comment === 'object') {
            initvals.content = this.props.comment.content
            initvals.id = this.props.comment.id
          }
          field.label = 'Comment'
          field.edit = true
        }
      }
      if (this.props.readOnly) {
        field = { ...field, edit: false }
        if (field.name === 'relations') {
          initvals.relations = this.props.note ? this.props.note.relations : []
          field.edit = true
          field.readonly = true
        }
      }
      fields.push(field)
    })
    this.setState({ initvals, fields })
  }

  setFiles(newfiles) {
    this.setState({ newfiles })
  }

  handleSubmit(values, actions) {
    Object.keys(values).forEach(k => {
      const field = this.props.config.fields.find(f => f.name === k)
      if (field && field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }
    })
    delete values.created
    delete values.modified
    if (values.obj_model === 'offers') {
      values.obj_model = 'leadinteractions'
    }
    return new Promise((resolve, reject) => {
      if (
        (this.props.note && this.props.note.id && !this.props.comment) ||
        (this.props.comment && this.props.comment.id)
      ) {
        return this.props.actions.updateModel({ values, resolve, reject })
      }
      return this.props.actions.createModel({ values, resolve, reject })
    }).then(r => {
      const { field, siblingform } = this.props
      actions.setSubmitting(false)
      actions.setTouched({})
      actions.resetForm()
      if (siblingform) {
        siblingform.setFieldValue(field.name, r.id).then(() => {
          siblingform.setFieldTouched(field.name)
        })
      }
      if (this.props.comment) {
        this.props.actions.fetchOne(this.props.config.modelname, values.obj_id)
      }
      if (this.props.actions.unsetNote) { this.props.actions.unsetNote() }
      this.props.actions.toggleWideSidebar()
    }).catch(e => {
      actions.setSubmitting(false)
      actions.setTouched({})
      this.props.actions.notifyUser({ title: 'Error', body: resolveError(e, this.props.config.fields) || e.error, type: 'error' })
    })
  }

  render () {
    const initvals = {
      ...this.state.initvals,
      obj_model: this.props.comment ? this.props.config.modelname : this.props.modelconfig.modelname,
      obj_id: this.props.comment && this.props.note ? this.props.note.id : this.props.modelid,
      modelname: this.props.config.modelname,
      endpoint: this.props.config.endpoint
    }
    let heading = 'Add Note / Action'
    if (this.props.note && !this.props.comment && !this.props.readOnly) {
      heading = 'Edit Note / Action'
    } else if (this.props.note && this.props.comment && !this.props.readOnly) {
      heading = 'Edit Comment'
    } else if (this.props.note && !this.props.comment && this.props.readOnly) {
      heading = 'Associations'
    }
    return (
      <div id="note-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar note-creator-sidebar">
        <div className="wide-sidebar-container">
          <div className="notes-form">
            <Formik
              initialValues={initvals}
              validationSchema={getIn(validate, `default.${this.props.config.modelname}`)}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
            >{ formik => {
                this.form = formik
                return (
                  <CustomForm
                    render={() => (
                      <div className="wide-sidebar-pane">
                        <div className="wide-sidebar-heading">
                          <h3>{heading}</h3>
                          <Button
                            type="button"
                            icon="#icon24-X-Large"
                            className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                            onClick={() => {
                              if (this.props.actions.unsetNote) { this.props.actions.unsetNote() }
                              this.props.actions.toggleWideSidebar()
                            }}
                          />
                        </div>
                        <Scrollbar
                          style={{ height: 'calc(100vh - 218px)' }}
                          renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                        >
                          <div className="wide-sidebar-content">
                            {this.props.comment &&
                                <div className="note-original">
                                  {valueFormat('domstring', this.props.note ? this.props.note.content : '')}
                                </div>
                            }
                            { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                              <FieldGroup
                                key={`fs-${gidx}`}
                                groupname={group}
                                group={this.props.config.fieldgroups[group]}
                                gidx={gidx}
                                classes={this.props.config.fieldgroups[group].classes}
                                fields={this.state.fields.filter(field => field.group === group).map(f => {
                                  const newfield = merge({}, f)
                                  if (newfield.createnew) { newfield.createnew = false }
                                  if (newfield.twin) { newfield.readonly = true }
                                  if ([ 'note', 'images' ].includes(f.name)) {
                                    newfield.setFiles = this.setFiles
                                    newfield.newfiles = this.state.newfiles
                                  }
                                  if (f.name === 'relations') {
                                    f.match = this.props.match
                                    f.related = this.props.note
                                  }
                                  return newfield
                                })}
                                modelname={this.props.modelconfig.modelname}
                                modelid={this.props.note ? this.props.note.id : null}
                                match={this.props.match}
                                required={this.state.required}
                                columns
                                collapsed={this.state.collapsed}
                                render={({ renderFieldGroup, hidden }) => {
                                  if (hidden) { return null }
                                  return (
                                    <fieldset className="editgroup">
                                      <h4>{group}</h4>
                                      {renderFieldGroup(group)}
                                    </fieldset>
                                  )
                                }}
                              />
                            ))}
                            {!this.props.readOnly &&
                                <div className="wide-sidebar-footer">
                                  <Button
                                    onClick={formik.submitForm} // Required for type="button"
                                    type="button" // This cannot be submit otherwise sibling form is submitted
                                    disabled={formik.isSubmitting}
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </Button>
                                </div>
                            }
                          </div>
                        </Scrollbar>
                      </div>
                    )}
                  />
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

NoteCreator.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  readOnly: PropTypes.bool,
  comment: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  note: PropTypes.object,
  modelid: PropTypes.number,
  match: PropTypes.object,
  sidebar: PropTypes.string,
  modelconfig: PropTypes.object
}

export default NoteCreator
