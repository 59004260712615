import * as yup from 'yup'

import { agent_validation } from '../../agents'


const ae_agent = yup.object({
  race: null
})

export const agents = () => agent_validation.concat(ae_agent)
