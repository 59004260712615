/* eslint-disable no-new */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import Card from './Card'
import InlineSelect from './forms/inputs/InlineSelect'
import SimpleTable from './simpletable/SimpleTable'


class ContactProfiles extends React.Component {
  constructor(props) {
    super(props)
    this.filterListingModel = this.filterListingModel.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.archiveModel = this.archiveModel.bind(this)
    this.listing_models = [
      { value: 'residential', label: 'Residential' },
      { value: 'commercial', label: 'Commercial' }
    ]
    this.state = {
      listing_model: null,
      options: [
        { value: 'residential', label: 'Residential' },
        { value: 'commercial', label: 'Commercial' }
      ]
    }
  }

  filterListingModel(listing_model) {
    const value = this.listing_models.find(option => option.value === listing_model.value)
    this.setState({ listing_model: { ...value } })
  }

  getOptions(profiles) {
    let { listing_model } = this.state
    let current_profiles = profiles.filter(profile => profile.listing_model === 'residential' &&
      profile.contact === this.props.modelid)
    if (current_profiles.length && !listing_model) {
      listing_model = { value: 'residential', label: 'Residential' }
    }
    if (!current_profiles.length) {
      current_profiles = profiles.filter(profile => profile.listing_model === 'commercial' &&
        profile.contact === this.props.modelid)
      if (current_profiles.length && !listing_model) {
        listing_model = { value: 'commercial', label: 'Commercial' }
      }
    }
    const models = current_profiles.map(profile => profile.listing_model)
    const active_models = current_profiles.filter(profile => profile.status !== 'Inactive').map(profile => profile.listing_model)

    const options = this.listing_models.filter(option => models.includes(option.value))
    const active_options = this.listing_models.filter(option => active_models.includes(option.value))
    let default_model = getIn(this.state, 'default_model', listing_model)
    if (active_models.length === 1) {
      default_model = active_options[0]
    } else if (options.length) {
      default_model = listing_model
    }
    this.setState({ default_model, listing_model, options })
  }

  archiveModel(modelname, id, callback = () => {}) {
    const values = {
      modelname,
      id: id,
      status: 'Inactive'
    }
    return new Promise((resolve, reject) => {
      this.props.actions.updateModel({ values, resolve, reject })
    }).then(callback)
  }

  render() {
    const { user, modelid, cache, actions, configs, profileType, model } = this.props
    return (
      <Card
        bodyclass={classNames(profileType, 'no-top-padding')}
        background
        header={
          <>
            <h3>{profileType === 'buyer' ? 'Buyer' : 'Tenant'} Profiles</h3>
            {this.state.options.length > 1 && this.state.default_model &&
            <div className="details-section-buttons tablemeta">
              <div className="profile-listing-model">
                <InlineSelect
                  id="listing_model"
                  name="listing_model"
                  className="inline-select"
                  classNamePrefix="inline"
                  prefix={<div className="input-group-addon">Listing Type: </div>}
                  defaultValue={this.state.default_model}
                  selectedValue={getIn(this.state, 'listing_model.value')}
                  options={this.state.options}
                  onChange={e => {
                    this.filterListingModel(e)
                  }}
                />
              </div>
            </div>
            }
          </>
        }
        body={
          <SimpleTable
            paginated
            config={configs.profiles}
            action={actions.fetchProfiles}
            currency={cache.settings[user.agent.site.id].default_currency}
            params={{
              contact: modelid,
              profile_type__icontains: profileType === 'buyer' ? 'Buyer' : 'Tenant',
              order_by: '-created',
              meta_fields: [ 'agent', 'contact', 'statistics' ]
            }}
            parser={data => {
              data.options = data.options.map(d => {
                const profile = { ...d }
                if (!d.agent) {
                  profile.agent = getIn(profile, 'meta.contact.introduction_agent')
                  profile.meta.agent = getIn(profile, 'meta.contact.meta.introduction_agent')
                }
                if (!profile.agent) {
                  profile.agent = getIn(model, 'introduction_agent')
                  profile.meta.agent = getIn(model, 'meta.introduction_agent')
                }
                let range = ''
                if (profile.listing_model.toLowerCase() === 'residential') {
                  range = [ profile.price_from, profile.price_to ]
                } else {
                  const p_from = profile.floor_size_from
                  const p_to = profile.floor_size_to
                  range = [ p_from, p_to ]
                }
                profile.range = range
                return profile
              })
              this.getOptions(data.options)
              return data
            }}
            header={[
              {
                label: 'Date Added',
                name: 'created',
                orderable: true,
                format: 'datetime'
              },
              {
                label: 'Status',
                name: 'status',
                orderable: true
              },
              {
                label: 'Branch',
                name: 'meta.contact.meta.branch',
                order_by: 'branch__name',
                orderable: true,
                modelname: 'branches',
                optionlabel: [ 'name' ],
                link: '/secure/:site/branches/:meta.branch'
              },
              {
                label: 'Agent',
                name: 'agent',
                orderable: true,
                modelname: 'agents',
                optionlabel: [ 'first_name', 'last_name' ],
                link: '/secure/:site/agents/:agent'
              },
              {
                label: 'Areas',
                name: 'areas',
                orderable: true,
                modelname: 'location',
                optionlabel: [ 'area' ]
              },
              {
                label: 'Suburbs',
                name: 'suburbs',
                orderable: true,
                modelname: 'location',
                optionlabel: [ 'suburb' ],
                labelseparator: ', '
              },
              {
                label: 'Property Types',
                name: 'property_types',
                orderable: true
              },
              {
                label: getIn(this.state, 'listing_model.value', 'residential') === 'residential' ? 'Price Range' : 'Size Range',
                name: getIn(this.state, 'listing_model.value', 'residential') === 'residential' ? [ 'price_from', 'price_to' ] : [ 'floor_size_from', 'floor_size_to' ],
                format: getIn(this.state, 'listing_model.value', 'residential') === 'residential' ? 'price_range' : 'size_range',
                orderable: false
              },
              {
                name: 'meta.statistics.matches',
                label: 'Matches',
                classes: 'text-right',
                link: '/secure/:site/profiles/:id/matches',
                format: 'number',
                orderable: true
              },
              {
                label: 'Marketing',
                name: 'receive_email_marketing',
                format: 'yesno',
                orderable: true
              }
            ]}
            user={user}
            rowActions={(row, data) => (
              <>
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/profiles/${data.id}`} title="View Profile" type="button" />
                {data.status !== 'Inactive' ? (
                  <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.archiveModel('profiles', data.id, () => {
                    data.status = 'Inactive'
                    row.forceUpdate()
                  })} title="Archive Profile" type="button" />
                ) : null}
              </>
            )}
          />
        }
      />
    )
  }
}

ContactProfiles.propTypes = {
  user: PropTypes.object,
  model: PropTypes.object,
  modelid: PropTypes.number,
  config: PropTypes.object,
  configs: PropTypes.object,
  actions: PropTypes.object,
  profileType: PropTypes.string,
  fetchProfiles: PropTypes.func,
  cache: PropTypes.object,
  profiletype: PropTypes.string,
  currency: PropTypes.string
}

export default ContactProfiles
