import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import HelpText from './../../HelpText'
import OnShowHistoryButton from './OnShowHistoryButton'
import Label from './Label'


export const handleCheck = (e, field, form, props) => {
  if (props.readonly) { return null }
  const { dependents, copyto, copyfrom } = props
  if (dependents) { dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) }) }
  form.setFieldValue(field.name, e.target.checked).then(() =>
    form.setFieldTouched(field.name, true, true)
  )
  if (copyto) {
    if (!copyfrom) {
      copyto.forEach(copy => { form.setFieldValue(copy, e.target.checked, false) })
    } else if (e.target.checked) {
      copyto.forEach(copy => { form.setFieldValue(copy, getIn(form, `values.${copyfrom}`), false) })
    }
  }
  if (props.linkedfield) {
    const domain = props.domain
    const fictitious_email = e.target.checked ? [ form.values.cell_number, domain ].join('@') : ''
    form.setFieldValue(props.linkedfield, fictitious_email, false)
  }

  // form.handleBlur(e)
  return e
}

const CheckInput = ({ field, form, label, id, ...props }) => {
  const [ defaultVal ] = useState(field.value)
  const error = form.errors[field.name]
  let classes = `checkinput form-group ${props.classes ? props.classes : field.name}`
  if (props.inlinetip) { classes += ' inlinetip' }
  const el = useRef({ checked: !!field.value }) // Need to set initial state here
  return (
    <React.Fragment>
      <div id={id} className={classes}>
        <div className="forminput">
          {props.prefix}
          <label className={classNames('checkcontainer', { 'input-group-prefix': props.prefix, 'input-group-suffix': props.suffix || (field.name === 'on_show' && form.values.id), switch: props.switch, readonly: props.readonly })}>
            <div>
              <input
                id={field.name}
                className={getIn(el.current, 'checked') ? 'on' : 'off'}
                ref={el}
                {...field}
                defaultChecked={defaultVal}
                onChange={e => handleCheck(e, field, form, props)}
                disabled={props.disabled || props.readonly ? 'disabled' : ''}
                type="checkbox"
              />

              {props.switch ? (
                <span className="slider round"><span className="onoff"></span></span>
              ) : (
                <span className={`checkmark ${field.name}`}>
                  {getIn(el.current, 'checked') ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
                </span>
              )
              }
            </div>

            {props.help &&
              <span className="help-text">
                {props.help.map((component, hid) => (<HelpText key={`ht-${field.name}-${hid}`} {...component} />))}
              </span>
            }
          </label>
          <Label className="formlabel" htmlFor={field.name}>{label}</Label>
          {props.suffix}

          { (field.name === 'on_show' && form.values.id && form.initialValues.on_show_events && form.initialValues.on_show_events.length > 0) ? (
            <OnShowHistoryButton
              form={form}
              modelname={props.modelname}
              modelid={form.values.id}
            />
          ) : null}
        </div>
      </div>

      {error && // Fix for errors on mobi being squashed up
        <div id={`${id}-error`} className={`${classes} error`}>
          <ErrorMessage component="div" className="error" name={field.name} />
        </div>
      }
    </React.Fragment>
  )
}

CheckInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  action: PropTypes.string,
  help: PropTypes.array,
  modelname: PropTypes.string,
  id: PropTypes.string.isRequired,
  inlinetip: PropTypes.bool,
  switch: PropTypes.bool,
  defaultvalue: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]),
  readonly: PropTypes.bool,
  classes: PropTypes.string,
  prefix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  linkedfield: PropTypes.string
}

export default CheckInput
