/* eslint-disable new-cap */
import merge from 'deepmerge'
import { Field, Formik } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import vehicleconfig from '../../../../config/vehicle.json'
import AdvancedSearch from '../../search/AdvancedSearch'
import QueryBuilder from '../../QueryBuilder'
import { CACHE, PERMISSIONS, SETTINGS, SITE, MINUSER } from '../../../../selectors'
import { handleSubmitError, isConditional, updateSearchParms, getSearchParam } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SimpleTable from '../../simpletable/SimpleTable'
import CustomForm from '../CustomForm'
import Card from '../../Card'
import FieldGroup from '../FieldGroup'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import VehicleEditSidebar from '../../../../containers/VehicleEditSidebar'
import TextInput from './Text'


class VehicleManager extends React.Component {
  constructor(props) {
    super(props)
    const qs = new QueryBuilder(this.props.location.search)
    const params = qs.getAllArgs(false)
    this.state = {
      params,
      fields: vehicleconfig.config.fields,
      errors: {},
      touched: {},
      advanced: false
    }
    this.isConditional = isConditional.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.setFieldValue = this.setFieldValue.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.fetchVehicles = this.fetchVehicles.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.toggleAdvanced = this.toggleAdvanced.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.createVehicle = this.createVehicle.bind(this)
    this.deleteVehicle = this.deleteVehicle.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const qs = new QueryBuilder(this.props.location.search)
      this.setState({
        params: qs.getAllArgs(false)
      })
    }
  }

  fetchVehicles({ resolve, reject, params }) {
    const { fetchMany } = this.props
    const values = {
      modelname: 'vehicles',
      conflicts: true,
      params
    }
    return fetchMany({ values, resolve, reject })
  }

  editVehicle(data, list) {
    this.setState({ edit: data, list }, () => {
      this.toggleEdit()
    })
  }

  createVehicle(values, actions) {
    const { createModel } = this.props
    return new Promise((resolve, reject) => {
      values.modelname = 'vehicles'
      createModel({ values, resolve, reject })
    }).then(() => {
      this.vehicles.fetchData()
      actions.resetForm({
        values: {
          branding: '',
          registration_number: '',
          branding_date: '',
          branding_status: '',
          branding_status_date: ''
        }
      })
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  deleteVehicle(vehicle, callback) {
    const { deleteModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'vehicles',
        selected: [ vehicle.id ]
      }
      // eslint-disable-next-line no-alert
      const confirmation = confirm('Are you sure you want to delete this vehicle?')
      if (confirmation) {
        return deleteModel({ values, resolve, reject })
      }
      return reject('')
    }).then(r => {
      callback(r)
    })
  }

  toggleEdit() {
    this.props.toggleWideSidebar('show-vehicle-manager')
  }

  updateInput(e) {
    this.setState({ term: e.target.value })
  }

  setFieldValue(name, value) {
    const state = merge({}, this.state)
    state[name] = value
    this.setState(state)
  }

  addVerb() {
    const { fields } = this.props.config
    if (fields) {
      const report_fields = fields.filter(field => field.edit).map((field, ind) => ({
        ...field,
        name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
        key: `adv-${field.name}-${ind}`,
        edit: true,
        updateVehicles: field.name === 'vehicle' ? this.updateVehicles : null
      }))
      return <FieldGroup
        card={false}
        match={this.props.match}
        groupname="Record Details"
        config={{
          fields
        }}
        fields={report_fields}
      />
    }
    return null
  }

  toggleAdvanced() {
    this.setState({ advanced: !this.state.advanced })
  }

  handleReset() { // Handles reset of the advanced search form
    const params = {
      ...this.props.config.params,
      term: 0
    }
    this.props.config.advsearch.forEach(group => {
      group.forEach(k => {
        let fname = k.name
        if (k.verb) { fname = `${fname}__${k.verb}` }
        params[fname] = 0
      })
    })
    updateSearchParms(params)
  }

  render() {
    return (
      <div className="vehicle-manager">
        <Card
          header={
            <h3>Branded Vehicles</h3>
          }
          background
          body={
            <Formik
              initialValues={{}}
              enableReinitialize={true}
              onSubmit={this.createVehicle}
            >{formik => (
                <CustomForm
                  component="div"
                  onChange={() => {
                    this.setState({ ...formik.values })
                  }}
                  render={() => (
                    <div className="search-fields">
                      {this.addVerb(formik)}

                      <div className="search-buttons" style={{ paddingRight: 0 }}>
                        <Button
                          id="keyword-search-btn"
                          tabIndex="-1"
                          type="button"
                          icon="#icon24-Plus"
                          onClick={formik.submitForm}
                          disabled={this.state.searching}
                          className="btn btn-grey btn-icon-16 btn-icon-left"
                        >
                          Add Vehicle
                        </Button>
                      </div>
                    </div>
                  )}
                />
              )}
            </Formik>
          }
        />
        <Card
          header={
            <h3>Branded Vehicle Details</h3>
          }
          background
          body={
            <div className="vehicles-search">
              {this.state.advanced ? (
                <AdvancedSearch
                  key='adv-search-vehicle'
                  customform={true}
                  model={{
                    params: this.state.params
                  }}
                  user={this.props.user}
                  match={this.props.match}
                  config={this.props.config}
                  configs={this.props.configs}
                  cache={this.props.cache}
                  toggleAdvanced={this.toggleAdvanced}
                  handleReset={this.handleReset}
                  actions={{
                    fetchMany: this.fetchVehicles
                  }}
                />
              ) : (
                <Formik
                  initialValues={{ model: 'vehicle' }}
                  enableReinitialize={true}
                  onSubmit={values => {
                    updateSearchParms({
                      ...this.state.params,
                      term: values.term
                    })
                  }}
                >{formik => (
                    <>
                      <CustomForm
                        component="div"
                        render={() => (
                          <div className="search-fields">
                            <div className="field col-lg-4">
                              <Field
                                id="vehicle-search"
                                name="term"
                                placeholder="Keyword Search"
                                component={TextInput}
                                className="term"
                                bounce={true}
                                show_search
                                suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                              />
                            </div>
                            {(getSearchParam('term') || Object.keys(this.state.params).length !== 0) &&
                              <div className="reset-group">
                                <strong>This list is filtered</strong>
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={this.handleReset}
                                  disabled={this.state.searching}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset
                                </Button>
                              </div>
                            }
                            {!this.state.params.term &&
                              <Button
                                id="keyword-search-btn"
                                tabIndex="-1"
                                type="button"
                                onClick={this.toggleAdvanced}
                                disabled={this.state.searching}
                                className="btn btn-subtle"
                              >
                                Advanced Search
                              </Button>
                            }
                          </div>
                        )}
                      />
                    </>
                  )}
                </Formik>
              )}
              <div className="templatesbody">
                <SimpleTable
                  config={this.props.config}
                  user={this.props.user}
                  paginated
                  header={[
                    {
                      label: 'Status',
                      name: 'branding_status',
                      orderable: true
                    },
                    {
                      label: 'Status Date',
                      name: 'branding_status_date',
                      orderable: true
                    },
                    {
                      label: 'Agents',
                      name: 'agents',
                      link: '/secure/:site/agents/?id__in=:agents',
                      labelseparator: ' ',
                      optionlabel: [ 'first_name', 'last_name' ],
                      metafield: true
                    },
                    {
                      label: 'Brand Type',
                      name: 'branding',
                      orderable: true
                    },
                    {
                      label: 'Registration Number',
                      name: 'registration_number',
                      orderable: true
                    },
                    {
                      label: 'Branding Date',
                      name: 'branding_date',
                      orderable: true
                    }
                  ]}
                  getClass={el => {
                    this.vehicles = el
                  }}
                  updateModel={({ values, resolve, reject, data, callback }) => {
                    const results = data.map(r => ({ ...r }))
                    this.props.updateModel({ values, resolve, reject })
                    results.forEach(d => {
                      if (d.front_end) {
                        d.front_end = false
                      }
                    })
                    results.forEach(d => {
                      if (d.id === values.id) {
                        d.front_end = values.front_end
                      }
                    })
                    callback(results)
                  }}
                  action={this.fetchVehicles}
                  params={{
                    ...this.state.params,
                    meta_fields: [ 'agents' ]
                  }}
                  parser={response => {
                    const data = {
                      options: response
                    }
                    return data
                  }}
                  rowActions={(row, data) => (
                    <>
                      <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editVehicle(data, this.vehicles)} title="Edit Vehicle" type="button" />
                      <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteVehicle(data, () => {this.vehicles.fetchData()})} title="Delete Vehicle" type="button" />
                    </>
                  )}
                />
              </div>
            </div>
          }
        />
        <WideSidebar sidebar={'show-vehicle-manager'}>
          <VehicleEditSidebar
            key="vehicle-manager-edit"
            toggleLookup={this.toggleEdit}
            vehicle={this.state.edit}
            config={this.props.config}
            list={this.state.list}
            match={this.props.match}
          />
        </WideSidebar>
      </div>
    )
  }
}

VehicleManager.propTypes = {
  user: PropTypes.object,
  cache: PropTypes.object,
  fields: PropTypes.object,
  config: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  configs: PropTypes.array,
  match: PropTypes.object,
  updatetemplate: PropTypes.func,
  deletetemplate: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  bulkEditModel: PropTypes.func
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let modelname = ownProps.modelname ? ownProps.modelname : null
  let modelid = ownProps.modelid ? ownProps.modelid : null

  if (ownProps.match && ownProps.match.params) {
    modelname = modelname ? modelname : ownProps.match.params.model
    modelid = modelid ? modelid : ownProps.match.params.id
  }

  const site = SITE(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    if (ownProps.match) {
      mincache = mincache.mergeDeepIn([ modelname, modelid ], cache.getIn([ modelname, modelid ])) // We need the current model data in cache too
    } else { // Pass entire cache if no id
      mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
    }
  }
  vehicleconfig.config.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        mincache[mn] = cache.get(mn)
      })
    }
    if (field.input === 'LocationSelect') { mincache.set('branches', cache.get('branches')) }
  })

  return {
    cache: mincache,
    user: minuser
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(VehicleManager))
