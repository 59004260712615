/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, fetchCleverCompose, notifyUser } from '../actions'
import CleverComposeSidebar from '../components/CleverComposeSidebar'


function mapStateToProps(state, ownProps) {
  return {
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache'),
    siblingform: ownProps.siblingform
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    fetchCleverCompose,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(CleverComposeSidebar))
