/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React from 'react'
import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { SETTINGS, SITE } from '../../../../selectors'
import { Button } from '../../../ui/Button'
// import { default as PDMSFieldArray } from './FieldArray'


// class TranslatableTextAreaInput extends PDMSFieldArray {
//   constructor(props) {
//     super(props)
//     this.state = {
//       ...this.state,
//       languages: []
//     }
//   }

//   render() {
//     const { id, copy, counter, classes, field, allowdelete, form, settings } = this.props
//     const { name, value } = field
//     if (!settings.meta.allowed_translations || !settings.meta.allowed_translations.length) { return null }
//     return (
//       <FieldArray
//         name={name}
//         render={({ insert, remove }) => (
//           <div id={id} className={classNames('translatabletextareainput', 'form-group', { 'has-copy-link': copy, 'has-counter': counter }, classes, name)}>
//             {!value || !value.length ? (
//               <div className="arrayaction">
//                 <div className="input-group">
//                   <div className="arrayaction-button col-lg-6">
//                     <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
//                       insert(!Array.isArray(value) ? 0 : value.length + 1, this.state.template)
//                     }}>Add Translation</Button>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <>
//                 {value && Array.isArray(value) && value.map((f, idx) => (
//                   <fieldset className="input-group col-lg-12" key={`fset-${idx}` }>
//                     {this.renderArrayFields(idx)}
//                     <div className="arrayaction">
//                       <div className="input-group">
//                         <div className="arrayaction-button col-lg-6">
//                           {value.length < settings.meta.allowed_translations.length &&
//                             <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
//                               insert(!Array.isArray(value) ? 0 : value.length + 1, this.state.template)
//                             }}>Add Translation</Button>}
//                           {(allowdelete && value.length >= 1) ? (
//                             <Button icon="#icon16-Bin" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
//                               remove(idx)
//                             }}>Remove Translation</Button>
//                           ) : null}
//                         </div>
//                       </div>
//                     </div>
//                   </fieldset>
//                 ))}
//               </>
//             )}
//           </div>
//         )}
//       />
//     )
//   }
// }

const TranslatableTextAreaInput = () => <div />

TranslatableTextAreaInput.propTypes = {
  form: PropTypes.object.isRequired,
  prefix: PropTypes.node,
  classes: PropTypes.string,
  copy: PropTypes.string,
  modelname: PropTypes.string,
  field: PropTypes.object.isRequired,
  portals: PropTypes.array,
  counter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  translatable: PropTypes.bool,
  fetchMany: PropTypes.func,
  location: PropTypes.number,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  allowdelete: PropTypes.bool,
  required: PropTypes.bool,
  show_required: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  const site = SITE(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)

  return {
    settings
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(TranslatableTextAreaInput))
