/* eslint-disable no-process-env */
import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import { valueFormat, capitalize, sortBy } from '../../utils'
import { Button } from '../ui/Button'
import DetailsSection from './DetailsSection'


const BranchSyndicationTable = props => (
  <div className="listbody">
    <table className="datatable">
      <thead>
        <tr>
          <th className="column-feedstatus">Status</th>
          <th className="column-datetime">Date & Time</th>
          <th className="column-portalref">Portal Ref</th>
          <th className="column-reason">Reason</th>
        </tr>
      </thead>
      <tbody>
        {props.children}
      </tbody>
    </table>
  </div>
)

BranchSyndicationTable.propTypes = {
  children: PropTypes.node
}


class BranchSyndication extends React.Component {
  constructor(props) {
    super(props)
    const { portals } = props
    const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree' ].includes(portals[pk].meta.portal.slug)).map(pk => {
      portals[pk].index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portals[pk].meta.portal.slug)
      return portals[pk]
    })
    const sorted = sortBy(active, 'index')
    this.state = {
      active: sorted
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.portals, this.props.portals)) {
      const { portals } = this.props
      const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree' ].includes(portals[pk].meta.portal.slug)).map(pk => {
        portals[pk].index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portals[pk].meta.portal.slug)
        return portals[pk]
      })
      const sorted = sortBy(active, 'index')
      this.setState({ active: sorted })
    }
  }

  render() {
    const { model, modelname, settings } = this.props
    return (
      <DetailsSection className="syndication">
        <div className="portals">
          {this.state.active.map((portal, pidx) => {
            const log = model.portals ? model.portals.find(p => p.portal === portal.portal) : null
            if (!log) { return null }
            return (
              <div id={`${portal.meta.portal.slug}-log`} className="portal-log" key={`portal-logs-${pidx}`}>
                <div className="portal-log-meta">
                  <div key={`portal-${portal.meta.portal.slug}`} className={`portal ${portal.active && 'portalon'}`}>
                    <img src={`${process.env.PUBLIC_URL}/portals/${portal.meta.portal.slug}.png`} alt={portal.meta.portal.name} />
                  </div>
                  {!settings.is_eos3 &&
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      onClick={() => this.props.syndicatePortalItem({
                        portal: portal.portal,
                        item_id: model.id,
                        item_type: modelname
                      })}>
                      <span className="label">Sync Again</span>
                    </Button>
                  }
                </div>
                <div className="portal-log-table">
                  <BranchSyndicationTable props={this.props}>
                    { log ? (
                      <tr>
                        <td className="column-feedstatus"><span title={capitalize(log.feed_status)} className="tablecell">{valueFormat('feedstatus', log.feed_status)}</span></td>
                        <td><span className="tablecell">{valueFormat('datetime', log.modified)}</span></td>
                        <td><span className="tablecell">{log.reference}</span></td>
                        <td><span className="tablecell" title={log.last_message}>{log.last_message}</span></td>
                      </tr>
                    ) : (
                      <tr><td colSpan="6"><span className="tablecell">There are no logs to show for this portal.</span></td></tr>
                    )
                    }
                  </BranchSyndicationTable>
                </div>
              </div>
            )
          }) }
        </div>
      </DetailsSection>
    )
  }
}

BranchSyndication.propTypes = {
  model: PropTypes.object,
  modelname: PropTypes.string,
  cache: PropTypes.object,
  portals: PropTypes.object,
  settings: PropTypes.object,
  site: PropTypes.object,
  syndicatePortalItem: PropTypes.func,
  fetchPortalLogs: PropTypes.func
}

export default BranchSyndication
