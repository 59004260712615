import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ListingsDeals = ({ configs, user, modelid, actions, modelname }) => {
  const parms = {
    order_by: '-created',
    limit: 100,
    model_type__model: modelname,
    model_id: modelid,
    meta_fields: [ 'sellers', 'buyers' ]
  }

  const abortController = useRef(new AbortController())

  return (
    <Card
      bodyclass="alerts-sent no-top-padding"
      background
      header={
        <h3>Deals</h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.deals}
          action={({ params, resolve, reject }) => {
            new Promise((res, rej) => actions.fetchMany({
              noloader: true,
              values: {
                modelname: 'deals',
                select: true,
                signal: abortController.current.signal,
                params
              },
              resolve: res,
              reject: rej
            })).then(r => {
              resolve(r)
            }).catch(e => {
              reject(e)
            })
          }}
          params={parms}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Deal Status',
              name: 'status',
              group: 'Record Details',
              orderable: true
            },
            {
              label: 'Sale Date',
              name: 'sale_date',
              group: 'Sale Details',
              input: 'Date',
              orderable: true,
              format: 'date',
              classes: [
                'text-right'
              ]
            },
            {
              name: 'sellers',
              label: 'Sellers',
              group: 'Seller Details',
              deedsfield: 'sellers',
              multi: true,
              format: 'contact_popup',
              orderby: 'sellers__first_name',
              input: 'ContactLookup',
              viewonly: true,
              required: true,
              modelname: 'contacts',
              link: '/secure/:site/contacts/?id__in=:sellers',
              directLink: '/secure/:site/contacts/:id',
              optionlabel: [
                'first_name',
                'last_name'
              ],
              labelseparator: ' '
            },
            {
              name: 'buyers',
              label: 'Buyers',
              group: 'Buyer Details',
              deedsfield: 'sellers',
              multi: true,
              format: 'contact_popup',
              orderby: 'sellers__first_name',
              input: 'ContactLookup',
              viewonly: true,
              required: true,
              modelname: 'contacts',
              link: '/secure/:site/contacts/?id__in=:buyers',
              directLink: '/secure/:site/contacts/:id',
              optionlabel: [
                'first_name',
                'last_name'
              ],
              labelseparator: ' '
            },
            {
              name: 'sale_status',
              label: 'Stage',
              group: 'Sale Details',
              format: 'deal-stage'
            }
          ]}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${data.site}/deals/${data.id}`} title="View Deal" type="button" />
            </>
          )}
        />
      }
    />
  )
}

ListingsDeals.propTypes = {
  permissions: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  currency: PropTypes.string,
  cache: PropTypes.object,
  alerts: PropTypes.object,
  fetchAlerts: PropTypes.func,
  updateModel: PropTypes.func,
  configs: PropTypes.object,
  user: PropTypes.object,
  modelid: PropTypes.number,
  actions: PropTypes.object,
  modelname: PropTypes.string
}

export default ListingsDeals
