import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import add from 'date-fns/add'
import classnames from 'classnames'
import { Button } from '../../ui/Button'


const Banner = ({ type, id, heading, children, dismissable, expires }) => {
  const [ visible, setVisible ] = useState(true)
  useEffect(() => {
    if (expires) {
      const banner = localStorage.getItem(`banner-${id}`)
      if (banner) {
        const data = JSON.parse(banner)
        const now = new Date()
        if (new Date(data.expires) >= now) {
          setVisible(false)
        }
      }
    }
  }, [])

  const dismissBanner = () => {
    setVisible(false)
    if (expires) {
      const expiry = add(new Date(), expires)
      localStorage.setItem(`banner-${id}`, JSON.stringify({ expires: expiry }))
    }
  }

  if (!visible) { return null }

  return (
    <div className={classnames('banner', type)}>
      <div className="banner-heading">{heading ? <h3>{heading}</h3> : null}{dismissable ? (<div className="banner-heading-buttons">
        <Button
          type="button"
          icon="#icon24-X-Large"
          className="btn btn-none btn-icon btn-icon-24 btn-banner-close"
          onClick={dismissBanner}
        />
      </div>) : null}</div>
      <div className="banner-body">{children}</div>
    </div>
  )
}

export default Banner

Banner.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dismissable: PropTypes.bool,
  expires: PropTypes.object
}
