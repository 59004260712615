import * as yup from 'yup'

import messages from './messages.json'


const address = yup.object({
  address_line_1: yup.string().max(100).nullable(),
  address_line_2: yup.string().max(100).nullable(),
  city: yup.string().max(100).nullable(),
  province: yup.string().max(100).nullable(),
  country: yup.string().max(100).nullable(),
  postal_code: yup.string().max(100).nullable()
})


export const contact = props => yup.object({
  status: yup.string().oneOf([ 'Active', 'Inactive', 'Non-Compliant', 'No-Consent', null ]).required(messages.required).nullable(),
  title: yup.string().max(75).nullable(),
  id_number: yup.string().max(13).nullable(),
  first_name: yup.string().max(75).required(messages.required),
  last_name: yup.string().max(75).nullable(),
  email: yup.string().email(messages.email).max(254).required(messages.required),
  cell_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  home_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  work_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  fax_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  company: yup.string().max(100).nullable(),
  postal_address: address.nullable(),
  physical_address: address.nullable(),
  source: yup.string().trim().oneOf([
    'Ananzi',
    'Bayut',
    'Board',
    'Canvassing',
    'Dubizzle',
    'Email Campaign',
    'Facebook',
    'FindHomes',
    'Flyer / Pamphlet',
    'Google Display',
    'Google Search',
    'Gumtree',
    'Houza',
    'ImmoAfrica',
    'Instagram',
    'JamesEdition',
    'LinkedIn',
    'ListGlobally',
    'Microsite',
    'Mobi Site',
    'Offr',
    'Pamphlet',
    'Partner',
    'Personal Contact',
    'Phone In',
    'Previous Client',
    'Print Ad',
    'Private Property',
    'Promotion',
    'Propertiez.co.za',
    'Property 24',
    'Property Central',
    'Property Finder',
    'Property Portal',
    'Radio Ad',
    'Referral',
    'Showhouse',
    'Social Media',
    'Spouse / Partner',
    'TV Ad',
    'UnderOneRoof',
    'Valuation',
    'Walk-in',
    'Website',
    'Other',
    null
  ], messages.invalid).nullable(),
  marriage_status: yup.string().trim().oneOf([
    'ANC',
    'COP',
    'Single',
    'Divorced',
    'Foreign Law',
    'Common Law',
    'Tribal Law',
    null
  ], messages.invalid).nullable(),
  entity_type: yup.string().trim().oneOf([
    'Bank Repo',
    'Closed Corporation',
    'Natural Person',
    'Pty Ltd',
    'Trust',
    'Other',
    'LLC',
    'PLLC',
    'Partnership',
    'Sole proprietor',
    null
  ], messages.invalid).nullable(),
  entity_number: yup.string().max(100).nullable(),
  entity_name: yup.string().max(100).nullable(),
  introduction_agent: yup.number().integer().positive().nullable(),
  associated_agents: yup.array().of(yup.number().integer().positive()).nullable(),
  branch: yup.number().typeError('Please select a valid option').integer().positive().required(messages.required),
  spouse: yup.number().typeError('Please select a valid option').integer().positive().nullable(),
  contact_types: yup.array().of(yup.string().oneOf([
    'Attorney',
    'Buyer',
    'General Enquiry',
    'Seller',
    'Tenant',
    'Insurance Contact',
    'Landlord',
    'Property Owner'
  ], messages.invalid)).required(messages.required).nullable()
}).test({
  message: 'Entity name is required',
  test: schema => {
    const { entity_name, entity_type } = schema
    if (entity_type && entity_type !== 'Natural Person' && !entity_name) {
      return new yup.ValidationError(messages.required, entity_name, 'entity_name')
    }
    return true
  }
}).test({
  message: 'Primary agent is required',
  test: schema => {
    const { introduction_agent } = schema
    if (props && props.siblingform && props.siblingform.values.modelname === 'leads') {
      if (!introduction_agent) {
        return new yup.ValidationError(messages.required, introduction_agent, 'introduction_agent')
      }
      return true
    }
    return true
  }
})
export const contacts = props => contact(props)

