/* eslint-disable new-cap */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { matchPath } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { MODELPARAMS } from '../../../selectors'


const MenuItem = ({ children, className, icon, to, active, search }) => {
  let path = '/images/icons-16.svg'

  if (icon && icon.includes('#icon24')) {
    path = '/images/icons-24.svg'
  } else if (icon && icon.includes('#glyph')) {
    path = '/images/glyphs.svg'
  } else if (icon && icon.includes('#social')) {
    path = '/images/social.svg'
  }

  return (
    <li
      className={`menu-item${className ? ` ${className}` : ''}${active ? ' active' : ''}`}
      aria-current={active}
    >
      <NavLink to={{ pathname: to, search }} className="inner-item" tabIndex={0} role="button">
        {icon ? (
          <span className="menu-icon-wrapper">
            <span className="menu-icon"><svg viewBox="0 0 24 24"><use href={`${path}${icon}`} /></svg></span>
          </span>
        ) : null}

        <span className="menu-item-content">{children}</span>
      </NavLink>
    </li>
  )
}

MenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  search: PropTypes.string,
  active: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  let modelname = ownProps.model
  if (ownProps.model === 'syndication') {
    modelname = `syndication${ownProps.log}`
  }
  const params = MODELPARAMS(state, modelname)
  const match = matchPath(location.pathname, { path: ownProps.to, exact: ownProps.exact })
  let active = false
  if (match) {
    if (ownProps.exact && match.isExact) {
      active = true
    } else if (!ownProps.exact && active !== !!match) {
      active = !!match
    }
  }
  const search = new URLSearchParams(params.toJS()).toString()
  return {
    active,
    search
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // eslint-disable-next-line no-unused-vars
  const { children, className, icon, to } = ownProps
  return ({ ...stateProps, children, className, icon, to })
}

export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(React.memo(MenuItem)))

// MenuItem.whyDidYouRender = true
