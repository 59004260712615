import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { getIn } from 'formik'

import { valueFormat } from '../../utils'
import UserPhoto from './UserPhoto'


const ContactItem = ({ field, value, index, model, cache }) => {
  const related_model = getIn(cache, `${getIn(field, 'modelname', getIn(field, 'inner.modelname'))}.${value}`)
  if (!related_model) { return null }
  let { label } = field
  if (index === 0) { label = `${label} 1`}

  return (
    <div className="listing-contacts-item">
      <UserPhoto link={`/secure/${model.site}/${getIn(field, 'modelname', getIn(field, 'inner.modelname'))}/${related_model.id}`} small model={related_model} tooltip={`${related_model.first_name ? related_model.first_name : ''} ${related_model.last_name ? related_model.last_name : ''}`} />
      <div className='listing-contacts-details'>
        <h3 className="listing-contacts-name"><NavLink className='has-link' to={`/secure/${model.site}/${getIn(field, 'modelname', getIn(field, 'inner.modelname'))}/${related_model.id}`}>{`${related_model.first_name ? related_model.first_name : ''} ${related_model.last_name ? related_model.last_name : ''}`}</NavLink></h3>
        <div className="listing-contacts-label">{label}</div>
      </div>
      <div className='listing-contacts-meta'>
        {related_model.cell_number && <><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Phone" /></svg>{valueFormat('tel', related_model.cell_number)}</>}
      </div>
    </div>
  )
}

ContactItem.propTypes = {
  field: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  index: PropTypes.number,
  model: PropTypes.object,
  cache: PropTypes.object
}

export default ContactItem
