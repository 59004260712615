import React from 'react'
import PropTypes from 'prop-types'
import { Formik, getIn } from 'formik'
import classNames from 'classnames'

import Card from '../common/Card'

import ModelActions from '../common/ModelActions'
import CustomForm from '../common/forms/CustomForm'
import PieChart from '../ui/graphs/PieChart'
import StackedBarGraph from '../ui/graphs/StackedBarGraph'
import MetaDetail from '../common/MetaDetail'
import db from '../../db'
import { getRandomColor, groupBy, sortBy, valueFormat } from '../../utils'
import * as validate from '../../validate'
import withReport from './ReportComponent'


class LeadActivity extends React.Component {
  constructor(props) {
    super(props)
    this.addCharts = this.addCharts.bind(this)
    this.getSourcesStat = this.getSourcesStat.bind(this)
    this.getCountryStat = this.getCountryStat.bind(this)
    this.getProvinceStat = this.getProvinceStat.bind(this)
    this.getListingTypeStat = this.getListingTypeStat.bind(this)
    this.getAreaStat = this.getAreaStat.bind(this)
    this.getSuburbStat = this.getSuburbStat.bind(this)
    this.getPropertyTypeStat = this.getPropertyTypeStat.bind(this)
    this.renderRelated = this.props.actions.renderRelated.bind(this)
    this.state = {
      offset: 0,
      initvals: null,
      templates: null,
      template_cache: {},
      template_config: null,
      template_configs: [],
      fields: null,
      presenting: false,
      areas: [],
      suburbs: [],
      filters: null,
      lead_sources: null,
      lead_location_country: null,
      lead_location_province: null,
      buyer_listing_type: null,
      buyer_province: null,
      buyer_area: null,
      buyer_suburb: null,
      buyer_property_type: null,
      tenant_listing_type: null,
      tenant_province: null,
      tenant_area: null,
      tenant_suburb: null,
      tenant_property_type: null,
      seller_listing_type: null,
      seller_province: null,
      seller_area: null,
      seller_suburb: null,
      seller_property_type: null,
      landlord_listing_type: null,
      landlord_province: null,
      landlord_area: null,
      landlord_suburb: null,
      landlord_property_type: null,
      report: {}
    }
  }

  componentDidMount() {
    this.fetchTemplates()
  }

  componentDidUpdate() {
    const { template_config: selected_config } = this.state
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + 66
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    if (this.state.presenting) {
      if (selected_config.name !== 'leads-analysis-report') {
        this.setState({ report: {}, presenting: false })
      }
      const { leads, buyer, tenant, seller, landlord } = this.state.report
      if (leads) {
        if (this.state.lead_sources === null) {
          this.getSourcesStat(leads.source_counts, 'lead_sources')
        }
        if (this.state.lead_location_country === null) {
          this.getCountryStat(leads.area_counts, 'lead_location_country')
        }
        if (this.state.lead_location_province === null) {
          this.getProvinceStat(leads.area_counts, 'lead_location_province')
        }
      }
      if (buyer) {
        if (this.state.buyer_listing_type === null) {
          this.getListingTypeStat(buyer.listing_types, 'buyer_listing_type', 'For Sale')
        }
        if (this.state.buyer_province === null) {
          this.getProvinceStat(buyer.area_counts, 'buyer_province')
        }
        if (this.state.buyer_area === null) {
          this.getAreaStat(buyer.area_counts, 'buyer_area')
        }
        if (this.state.buyer_suburb === null) {
          this.getSuburbStat(buyer.suburb_counts, 'buyer_suburb')
        }
        if (this.state.buyer_property_type === null) {
          this.getPropertyTypeStat(buyer.property_types, 'buyer_property_type')
        }
      }
      if (tenant) {
        if (this.state.tenant_listing_type === null) {
          this.getListingTypeStat(tenant.listing_types, 'tenant_listing_type', 'To Let')
        }
        if (this.state.tenant_province === null) {
          this.getProvinceStat(tenant.area_counts, 'tenant_province')
        }
        if (this.state.tenant_area === null) {
          this.getAreaStat(tenant.area_counts, 'tenant_area')
        }
        if (this.state.tenant_suburb === null) {
          this.getSuburbStat(tenant.suburb_counts, 'tenant_suburb')
        }
        if (this.state.tenant_property_type === null) {
          this.getPropertyTypeStat(tenant.property_types, 'tenant_property_type')
        }
      }
      if (seller) {
        if (this.state.seller_listing_type === null) {
          this.getListingTypeStat(seller.listing_types, 'seller_listing_type', 'For Sale')
        }
        if (this.state.seller_province === null) {
          this.getProvinceStat(seller.area_counts, 'seller_province')
        }
        if (this.state.seller_area === null) {
          this.getAreaStat(seller.area_counts, 'seller_area')
        }
        if (this.state.seller_suburb === null) {
          this.getSuburbStat(seller.suburb_counts, 'seller_suburb')
        }
        if (this.state.seller_property_type === null) {
          this.getPropertyTypeStat(seller.property_types, 'seller_property_type')
        }
      }
      if (landlord) {
        if (this.state.landlord_listing_type === null) {
          this.getListingTypeStat(landlord.listing_types, 'landlord_listing_type', 'To Let')
        }
        if (this.state.landlord_province === null) {
          this.getProvinceStat(landlord.area_counts, 'landlord_province')
        }
        if (this.state.landlord_area === null) {
          this.getAreaStat(landlord.area_counts, 'landlord_area')
        }
        if (this.state.landlord_suburb === null) {
          this.getSuburbStat(landlord.suburb_counts, 'landlord_suburb')
        }
        if (this.state.landlord_property_type === null) {
          this.getPropertyTypeStat(landlord.property_types, 'landlord_property_type')
        }
      }
    }
    if (this.state.filters && this.state.filters.profile__suburbs__overlap) {
      const ids = this.state.filters.profile__suburbs__overlap
      const options = db.suburbs
        .where('id').anyOf(ids).toArray()
      options.then(o => {
        const suburbs = { ...this.state.suburbs }
        o.forEach(a => { suburbs[a.id] = a })
        this.setState({ suburbs })
      })
    }
  }

  async getSourcesStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const total = data.map(source => source[Object.keys(source).pop()]).reduce((a, b) => a + b, 0)
    const top_9 = data.filter(source => !Object.keys(source).includes('Other')).slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      if (count) {
        slices.push({
          name: `${source_key}`,
          value: count,
          percentage: count / total * 100,
          colour: getRandomColor(slices.length)
        })
      }
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    this.setState({ ...state })
    return slices
  }

  async getCountryStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const ids = []
    const total = data.map(s => {
      const aid = Object.keys(s).pop()
      ids.push(parseInt(aid, 10))
      return s[Object.keys(s).pop()]
    }).reduce((a, b) => a + b, 0)

    const options = await db.areas
      .where('id').anyOf(ids).toArray()

    const countries = groupBy(options, 'country')
    let country_data = Object.keys(countries).map(country => {
      const areas = countries[country]
      const count = areas
        .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
        .reduce((a, b) => a + b, 0)
      const cdata = {}
      cdata[`${country}`] = count
      return cdata
    })
    country_data = sortBy(country_data, null, (a, b) => {
      const A = a[Object.keys(a)]
      const B = b[Object.keys(b)]
      if (A < B) { return -1 }
      if (A > B) { return 1 } // names must be equal
      return 0
    })
    country_data.reverse()

    const top_9 = country_data.slice(0, 9)

    let top_9_total = 0
    const slices = []
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })
    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    this.setState({ ...state })
    return slices
  }

  async getProvinceStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const ids = []
    const total = data.map(s => {
      const aid = Object.keys(s).pop()
      ids.push(parseInt(aid, 10))
      return s[Object.keys(s).pop()]
    }).reduce((a, b) => a + b, 0)

    const options = await db.areas
      .where('id').anyOf(ids).toArray()

    const provinces = groupBy(options, 'province')
    let province_data = Object.keys(provinces).map(province => {
      const areas = provinces[province]
      const count = areas
        .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
        .reduce((a, b) => a + b, 0)
      const cdata = {}
      cdata[`${province}`] = count
      return cdata
    })
    province_data = sortBy(province_data, null, (a, b) => {
      const A = a[Object.keys(a)]
      const B = b[Object.keys(b)]
      if (A < B) { return -1 }
      if (A > B) { return 1 } // names must be equal
      return 0
    })
    province_data.reverse()

    const top_9 = province_data.slice(0, 9)

    let top_9_total = 0
    const slices = []
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    this.setState({ ...state })
    return slices
  }

  async getAreaStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const ids = []
    const total = data.map(s => {
      const aid = Object.keys(s).pop()
      ids.push(parseInt(aid, 10))
      return s[Object.keys(s).pop()]
    }).reduce((a, b) => a + b, 0)

    const options = await db.areas
      .where('id').anyOf(ids).toArray()
    const area_groups = groupBy(options, 'area')
    let area_data = Object.keys(area_groups).map(area => {
      const areas = area_groups[area]
      const count = areas
        .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
        .reduce((a, b) => a + b, 0)
      const cdata = {}
      cdata[`${area}`] = count
      return cdata
    })
    area_data = sortBy(area_data, null, (a, b) => {
      const A = a[Object.keys(a)]
      const B = b[Object.keys(b)]
      if (A < B) { return -1 }
      if (A > B) { return 1 } // names must be equal
      return 0
    })
    area_data.reverse()

    const top_9 = area_data.slice(0, 9)

    let top_9_total = 0
    const slices = []
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    const areas = { ...this.state.areas }
    options.forEach(a => { areas[a.id] = a })
    this.setState({ ...state, areas })
    return slices
  }

  async getSuburbStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const ids = []
    const total = data.map(s => {
      const aid = Object.keys(s).pop()
      ids.push(parseInt(aid, 10))
      return s[Object.keys(s).pop()]
    }).reduce((a, b) => a + b, 0)

    const options = await db.suburbs
      .where('id').anyOf(ids).toArray()

    const suburb_groups = groupBy(options, 'suburb')
    let area_data = Object.keys(suburb_groups).map(suburb => {
      const areas = suburb_groups[suburb]
      const count = areas
        .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
        .reduce((a, b) => a + b, 0)
      const cdata = {}
      cdata[`${suburb}`] = count
      return cdata
    })
    area_data = sortBy(area_data, null, (a, b) => {
      const A = a[Object.keys(a)]
      const B = b[Object.keys(b)]
      if (A < B) { return -1 }
      if (A > B) { return 1 } // names must be equal
      return 0
    })
    area_data.reverse()

    const top_9 = area_data.slice(0, 9)

    let top_9_total = 0
    const slices = []
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    const suburbs = { ...this.state.suburbs }
    options.forEach(a => { suburbs[a.id] = a })
    this.setState({ ...state, suburbs })
    return slices
  }

  async getListingTypeStat(data, state_key, type) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const slices = []
    const total = data.map(s => s[Object.keys(s).pop()]).reduce((a, b) => a + b, 0)
    const top_9 = data.slice(0, 9)
    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      let label = source_key
      if (source_key === 'residential' && type === 'For Sale') {
        label = 'Residential For Sale'
      } else if (source_key === 'commercial' && type === 'For Sale') {
        label = 'Commercial For Sale'
      }
      slices.push({
        name: `${label}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    this.setState({ ...state })
    return slices
  }

  async getPropertyTypeStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || data.legth === 0) {
      return null
    }
    if (!Array.isArray(data)) {
      data = Object.keys(data).map(k => {
        const newObj = {}
        newObj[k] = data[k]
        return newObj
      })
    }
    this[`${state_key}_fetching`] = true
    const total = data.map(source => source[Object.keys(source).pop()]).reduce((a, b) => a + b, 0)
    const top_9 = data.slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = Object.keys(source).pop()
      const count = source[source_key]
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    })

    const other_totals = total - top_9_total
    if (other_totals) {
      slices.push({
        name: 'Other',
        value: other_totals,
        percentage: other_totals / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
    const state = {}
    state[`${state_key}`] = slices
    this.setState({ ...state })
    return slices
  }

  addCharts() {
    if (!Object.keys(this.state.report).length) { return null }
    return (
      <React.Fragment>
        {(this.state.lead_sources || (this.state.report.leads && this.state.report.leads.telephonic_count)) &&
        <Card
          header={
            <h3 className="piechart-section-heading">Lead Source</h3>
          }
          background
          body={
            <div className="piechart-columns">
              {this.state.lead_sources && this.state.lead_sources.length > 0 &&
                <div className="piechart-column">
                  <h4>Electronic Lead Sources</h4>
                  <div className="pie-chart">
                    <PieChart width={250} legend={data => (
                      <div className="stacked-legend">
                        <ul className="recharts-default-legend">
                          {data.map((entry, i) => {
                            const color = entry.inactive ? this.props.inactiveColor : entry.colour
                            const className = classNames({
                              'recharts-legend-item': true,
                              [`legend-item-${i}`]: true,
                              inactive: entry.inactive
                            })
                            const viewBox = '0 0 19 2'
                            const svgStyle = { display: 'block', marginTop: 2, marginBottom: 2 }
                            return (
                              <li className={className} key={`legend-${i}`}>
                                <div className="stacked-legend-header">
                                  <strong>{entry.name}: </strong>
                                  <span>{entry.value}</span>
                                </div>
                                <svg width={19} height={2} viewBox={viewBox} style={svgStyle}>
                                  <rect
                                    stroke="none"
                                    fill={color}
                                    width={19}
                                    height={2}
                                    className="recharts-legend-icon"
                                  />
                                </svg>
                                <div className="stacked-legend-percentage">
                                  {valueFormat('number', entry.percentage)}%
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )} data={this.state.lead_sources} />
                  </div>
                </div>
              }
              {this.state.report.leads.telephonic_count > 0 &&
                <div className="piechart-column">
                  <h4>YOUR PROP DATA WEBSITE<br />TELEPHONIC LEADS*</h4>
                  <span className="big-stat">{this.state.report.leads.telephonic_count}</span>
                  <span className="telephonic-note">* the above Telephonic Leads only pertain to your Prop Data Website and do not include any portal related telephonic leads from Property 24 or Private Property.</span>
                </div>
              }
            </div>
          }
        />
        }
        {(
          (this.state.lead_location_country && this.state.lead_location_country.length)
          || (this.state.lead_location_province && this.state.lead_location_province.length)
        ) ? (
            <Card
              header={
                <h3 className="piechart-section-heading">Lead Location</h3>
              }
              background
              body={
                <div className="piechart-columns">
                  {this.state.lead_location_country && this.state.lead_location_country.length > 0 &&
                  <div className="piechart-column">
                    <h4>Country</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.lead_location_country}
                    />
                  </div>
                  }
                  {this.state.lead_location_province && this.state.lead_location_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Province'}
                      data={this.state.lead_location_province}
                    />
                  </div>
                  }
                </div>
              }
            />
          ) : null}
        {(this.state.buyer_listing_type || this.state.buyer_province || this.state.buyer_area ||
          this.state.buyer_suburb || this.state.buyer_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Buyers</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {this.state.buyer_listing_type && this.state.buyer_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Listing Type'}
                      data={this.state.buyer_listing_type}
                    />
                  </div>
                }
                {this.state.buyer_province && this.state.buyer_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Province'}
                      data={this.state.buyer_province}
                    />
                  </div>
                }
                {this.state.buyer_area && this.state.buyer_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Area'}
                      data={this.state.buyer_area}
                    />
                  </div>
                }
                {this.state.buyer_suburb && this.state.buyer_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Suburb'}
                      data={this.state.buyer_suburb}
                    />
                  </div>
                }
                {this.state.buyer_property_type && this.state.buyer_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Property Type'}
                      data={this.state.buyer_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(this.state.tenant_listing_type || this.state.tenant_province || this.state.tenant_area ||
          this.state.tenant_suburb || this.state.tenant_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Tenants</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {this.state.tenant_listing_type && this.state.tenant_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.tenant_listing_type}
                    />
                  </div>
                }
                {this.state.tenant_province && this.state.tenant_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.tenant_province}
                    />
                  </div>
                }
                {this.state.tenant_area && this.state.tenant_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.tenant_area}
                    />
                  </div>
                }
                {this.state.tenant_suburb && this.state.tenant_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.tenant_suburb}
                    />
                  </div>
                }
                {this.state.tenant_property_type && this.state.tenant_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.tenant_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(this.state.seller_listing_type || this.state.seller_province || this.state.seller_area ||
          this.state.seller_suburb || this.state.seller_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Sellers</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {this.state.seller_listing_type && this.state.seller_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.seller_listing_type}
                    />
                  </div>
                }
                {this.state.seller_province && this.state.seller_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.seller_province}
                    />
                  </div>
                }
                {this.state.seller_area && this.state.seller_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.seller_area}
                    />
                  </div>
                }
                {this.state.seller_suburb && this.state.seller_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.seller_suburb}
                    />
                  </div>
                }
                {this.state.seller_property_type && this.state.seller_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.seller_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(this.state.landlord_listing_type || this.state.landlord_province || this.state.landlord_area ||
          this.state.landlord_suburb || this.state.landlord_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Landlords</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {this.state.landlord_listing_type && this.state.landlord_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.landlord_listing_type}
                    />
                  </div>
                }
                {this.state.landlord_province && this.state.landlord_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.landlord_province}
                    />
                  </div>
                }
                {this.state.landlord_area && this.state.landlord_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.landlord_area}
                    />
                  </div>
                }
                {this.state.landlord_suburb && this.state.landlord_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.landlord_suburb}
                    />
                  </div>
                }
                {this.state.landlord_property_type && this.state.landlord_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={this.state.landlord_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
      </React.Fragment>
    )
  }

  render() {
    const { config } = this.props
    if (!this.state.templates || !this.state.initvals) { return null }
    const { fields, template_config: selected_config, template_configs } = this.state
    if (!fields) { return null }
    if (!this.state.presenting) {
      return (
        <Formik
          validationSchema={validate[`${this.props.config.modelname}_activity`]}
          initialValues={this.state.initvals}
          enableReinitialize
          onSubmit={(values, form) => {
            const { template, ...vals } = values
            Object.keys(vals).forEach(k => {
              // Remove empty values
              if ([ null, undefined ].includes(vals[k])) {
                delete vals[k]
              }
              if (Array.isArray(vals[k]) && !vals[k].length) {
                delete vals[k]
              }
            })
            setTimeout(() => {
              form.setSubmitting(false) // Set to false here so form can be submitted again
            }, 300)
            return new Promise((resolve, reject) => {
              const params = {
                params: vals,
                args: {
                  action: 'report',
                  template
                },
                modelname: config.modelname,
                label: template_configs.find(f => f.name === template).display_name,
                resolve,
                reject
              }
              if (template === 'leads-analysis-report') {
                this.props.actions.getLeadsAnalysisData(params)
              } else {
                this.props.actions.exportData(params)
              }
            }).then(r => {
              this.setState({ presenting: template === 'leads-analysis-report', report: r.response, filters: vals })
              form.setSubmitting(false)
            }).catch(() => {
              form.setSubmitting(false)
            })
          }}
        >{formik => {
            this.form = formik
            const template = this.state.templates.find(e => formik.values.template === e.value)
            return (
              <div id="content" className="content">
                <div className="viewhead details">
                  <div className="action-bar">
                    <ModelActions
                      touched={formik.touched}
                      errors={formik.errors}
                      isSubmitting={formik.isSubmitting}
                      redirectSchema={this.redirectSchema}
                      form={formik}
                      modelname={config.modelname}
                      extras={{
                        save: {
                          label: 'Generate Report',
                          menu: null,
                          redirect: null,
                          icon: '#icon16-Download',
                          action: 'submitForm',
                          className: 'btn-round btn-red'
                        }
                      }}
                      statusmsg={formik.status ? formik.status.msg : false}
                    />
                  </div>
                </div>
                <div className={`view details reports ${config.modelname}`}>
                  <div className="viewcontent">
                    <div className="report-form">
                      <Card
                        background={true}
                        header={
                          <h2>Report Generator</h2>
                        }
                        body={
                          <CustomForm
                            model={this.props.model ? true : false}
                            onChange={() => {
                              if (this.state.initvals.template && this.form && !formik.touched.template) {
                                formik.setFieldTouched('template') // enable button as soon as templates are loaded
                              }
                              if (template) {
                                const template_config = this.state.template_cache[template.value]
                                if ((!selected_config && template_config) || (getIn(selected_config, 'name') !== template.value)) {
                                  const { fields: newfields, initvals } = this.initModel(template_config)
                                  this.setState({
                                    template_config,
                                    fields: newfields,
                                    initvals: { ...formik.values, ...initvals }
                                  })
                                }
                              }
                            }}
                            render={ () => (
                              <div className="search-fields row">
                                {this.addVerb(formik)}
                              </div>
                            )}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </Formik>
      )
    }

    return (
      <div id="content" className="content">
        <div className="viewhead details">
          <div className="action-bar">
            <ModelActions
              redirectSchema={this.redirectSchema}
              modelname={config.modelname}
              extras={{
                save: {
                  label: 'Generate Report',
                  menu: null,
                  redirect: null,
                  icon: '#icon16-Download',
                  action: 'submitForm',
                  className: 'btn-round btn-red'
                }
              }}
            />
          </div>
        </div>
        <div ref={el => { this.report_result = el }} className={`view details reports ${config.modelname}`}>
          <div className="viewcontent">
            <div className="report-form">
              <Card
                background={true}
                header={
                  <h3>{selected_config.display_name}</h3>
                }
                body={(
                  <>
                    <div className="input-group">
                      <MetaDetail
                        label="Lead Status"
                        className="col-lg-12"
                        value={this.state.filters.status__in}
                      />
                      <MetaDetail
                        label="Lead Type"
                        className="col-lg-12"
                        value={this.state.filters.lead_type__in && this.state.filters.lead_type__in.map(val => this.props.config.fields.find(f => f.name === 'lead_type').options.find(o => o.value === val).label).join(', ')}
                      />
                      <MetaDetail
                        label="Date From"
                        format="date"
                        className="col-lg-12"
                        value={this.state.filters.created__date__gte}
                      />
                      <MetaDetail
                        label="Date To"
                        format="date"
                        className="col-lg-12"
                        value={this.state.filters.created__date__lte}
                      />
                      <MetaDetail
                        label="Branch(es)"
                        className="col-lg-12"
                        value={this.renderRelated('branch__in', 'branches', 'branch', 'name')}
                      />
                      <MetaDetail
                        label="User(s)"
                        className="col-lg-12"
                        value={this.renderRelated('agent__in', 'agents', 'agent', [ 'first_name', 'last_name' ], { labelseparator: ' ' })}
                      />
                      <MetaDetail
                        label="Area(s)"
                        className="col-lg-12"
                        value={this.renderRelated('profile__areas__overlap', 'areas', null, 'area')}
                      />
                      <MetaDetail
                        label="Suburb(s)"
                        className="col-lg-12"
                        value={this.renderRelated('profile__suburbs__overlap', 'suburbs', { labelseparator: ', ' }, [ 'suburb', 'area' ])}
                      />
                    </div>
                  </>
                )}
              />
              {this.addCharts()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LeadActivity.propTypes = {
  report: PropTypes.string,
  model: PropTypes.string,
  config: PropTypes.object,
  actions: PropTypes.object,
  models: PropTypes.object,
  cache: PropTypes.object,
  addons: PropTypes.array,
  inactiveColor: PropTypes.string
}

export default withReport(LeadActivity)
