import * as yup from 'yup'
import messages from './messages.json'


export const referrals = () => yup.object({
  status: yup.string().trim().oneOf([
    'Scheduled',
    'Pending',
    'Accepted',
    'Declined'
  ], messages.invalid).required(messages.required),
  referral_date: yup.string().required(messages.required),
  referral_type: yup.string().trim().oneOf([
    'Client wants to buy',
    'Client wants to sell',
    'Client wants to buy and sell',
    'Client wants to rent',
    'Client wants to lease',
    'Bank distressed sale'
  ], messages.invalid).required(messages.required).nullable(),
  sending_branch: yup.number().strict().typeError(
    messages.invalid
  ).positive().integer().required(messages.required).nullable(),
  sending_branch_manager: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  sending_agent: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  sending_leads_manager: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  // recipients: yup.array().of(yup.object({
  //   recipient_agent: yup.number().typeError(messages.invalid).positive().integer(),
  //   recipient_branch: yup.number().typeError(messages.invalid).positive().integer()
  // })).required(messages.required).nullable(),
  recipient_branch_manager: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  recipient_referral_manager: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  recipient_leads_manager: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  buyer_listing_type: yup.string().trim().oneOf([
    'Residential For Sale',
    'Commercial For Sale',
    'Residential To Let',
    'Commercial To Let'
  ], messages.invalid).nullable(),
  seller_listing_type: yup.string().trim().oneOf([
    'Residential For Sale',
    'Commercial For Sale'
  ], messages.invalid).nullable(),
  buyer_price_from: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  buyer_price_to: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  buyer_must_sell: yup.boolean().nullable(),
  buyer_property_listed: yup.boolean().nullable(),
  seller_property_listed: yup.boolean().nullable(),
  seller_property_listed_sold: yup.boolean().nullable(),
  // seller_possession_date: yup.string().url(messages.url).max(200).nullable(),
  // buyer_possession_date: yup.string().url(messages.url).max(200).nullable(),
  buyer_cash_buyer: yup.boolean().nullable(),
  buyer_financed: yup.boolean().nullable(),
  buyer_areas: yup.array(yup.number().typeError(messages.invalid).positive().integer()),
  buyer_suburbs: yup.array(yup.number().typeError(messages.invalid).positive().integer()).nullable()
})
