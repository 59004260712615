/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { Label, Legend, Pie, PieChart, Surface, Symbols, Text } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany, getLeadsBreakdown } from '../../actions'
import date_options from '../../config/date-options.json'
import { CONFIG, MINUSER } from '../../selectors'
import { getRandomColor, hasPermission, textToDate, valueFormat, breakpoint, buildOptionLabel, getTextWidth } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'
import withDelay from './withDelay'


const RADIAN = Math.PI / 180
const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class LeadStatusWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'LAST_30_DAYS')
    const period = option.value
    const { start, end } = textToDate(option.value)
    this.state = {
      count: 0,
      negative: false,
      period,
      branches: [],
      branch_options: [],
      agents: [],
      listing_options: [],
      showActions: breakpoint.matches,
      branch_id: props.branches.length === 1 ? props.branches[0] : '',
      agent_id: '',
      status: 'Active',
      show_agents: true,
      listing_model: '',
      statistics: [],
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      loading: true
    }
    this.toggleActions = this.toggleActions.bind(this)
    this.filterLeads = this.filterLeads.bind(this)
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
    this.renderCustomizedLegend = this.renderCustomizedLegend.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user, branches } = this.props
    if (hasPermission([
      'leads_view'
    ], user.permissions, null, user.agent.id)) {
      if (!branches || branches.length > 1) {
        let agent_branches = []
        if (!hasPermission([
          'apply_to_all_branches'
        ], user.permissions, null, user.agent.id)) {
          agent_branches = [ ...user.agent.branches ]
        }
        new Promise((resolve, reject) => actions.fetchMany({
          values: {
            modelname: 'branches',
            optionvalue: 'id',
            optionlabel: 'name',
            fields: [ 'id', 'name' ],
            active: 1,
            all: true,
            select: true,
            params: {
              id__in: [ ...agent_branches, ...branches ],
              order_by: 'name'
            },
            signal: this.AbortController.signal
          },
          resolve,
          reject
        })).then(r => {
          if (this._is_mounted) {
            const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
            this.setState({ branches: r.options, branch_options })
          }
        }).catch(e => {
          if (e.status !== 408) { console.error(e) }
        })
      }
    } else {
      this.setState({ show_agents: false })
    }
    this.filterLeads()
    breakpoint.addEventListener('change', this.toggleActions)
    let agent_id = this.state.agent_id
    if (user.agent) {
      if (!agent_id && getIn(user, 'agent.id')) {
        agent_id = user.agent.id
      }
    }
    if (agent_id !== this.state.agent_id) {
      this.setState({ agent_id })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.status !== this.state.status
      || prevState.branch_id !== this.state.branch_id
      || prevState.agent_id !== this.state.agent_id
    ) {
      this.filterLeads()
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    breakpoint.removeEventListener('change', this.toggleActions)
    this.AbortController.abort()
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  filterLeads() {
    const { actions, status_field, branches } = this.props
    const { agent_id, branch_id } = this.state
    this.setState({ loading: true })
    let params = {
      contact__branch: branch_id
    }
    if (!branch_id && branches) {
      params = {
        contact__branch__in: branches
      }
    }
    const promises = status_field.options.map((o, oid) =>
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'leads',
          params: { get_count: 1, status: o.value, agent: agent_id, ...params },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => (
        { label: o.value, value: r.result, fill: getRandomColor(oid) }
      )).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    )
    Promise.allSettled(promises).then(results => {
      if (this._is_mounted) {
        const statistics = results.map(r => r.value)
        this.setState({ loading: false, statistics })
      }
    })
  }

  getColour(value) {
    if (value < 65) {
      return '#FF6464'
    }
    if (value < 80) {
      return '#FFA564'
    }
    return '#73C677'
  }

  renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent }) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const value = (percent * 100).toFixed(0)
    let scale = radius / 99.6
    const width = getTextWidth(value, `${12 * scale}px Poppins`)
    const max = (radius - 5) * 2
    if (width > max) {
      const offset = max / width
      scale *= offset
    }
    if (scale < 0.6) {
      return null
    }
    if (value > 0) {
      return (
        <Text x={x} y={y} fontFamily="Poppins" fontSize={12 * scale} fill="white" textAnchor="middle" verticalAnchor='middle'>
          {`${value}%`}
        </Text>
      )
    }
    return null
  }

  renderCustomizedLegend(props) {
    const { payload, chartWidth, chartHeight, layout } = props
    const { user, branches } = this.props
    return (
      <div className="customized-legend-vertical" style={{ width: layout === 'vertical' ? chartWidth - chartHeight : chartWidth }}>
        {
          payload.map((entry, eid) => {
            const { payload: data, color } = entry
            if (data.label === 'No Leads Found') {
              return null
            }
            return (
              <span key={`overlay-${data.label}-${eid}`} className="legend-item">
                <Surface width={10} height={10} viewBox={{
                  x: 0,
                  y: 0,
                  width: 10,
                  height: 10
                }}>
                  <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                </Surface>
                <span className="legend-label"><NavLink to={`/secure/${user.agent.site.id}/leads/?status=${data.label}${branches ? `&contact__branch__in=${branches}` : ''}`}>{data.label}</NavLink></span>
                <span className="legend-count">{valueFormat('number', data.value)}</span>
              </span>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { user } = this.props
    const { statistics: data, showActions, branch_id, branches } = this.state
    return (
      <Card
        id="lead-source-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding"
        background
        header={
          <>
            <h3>Lead Status</h3>
            <div className="details-section-buttons min-flex tablemeta">
              {(!branches || branches.length > 1) ? (
                <div className="filter-branch">
                  <InlineSelect
                    id="branch_id"
                    name="branch_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                    defaultValue={{ label: 'All Branches', value: '' }}
                    selectedValue={getIn(this.state, 'branch_id')}
                    onChange={e => {
                      this.setState({ branch_id: e.value })
                    }}
                  />
                </div>
              ) : null}
              {this.state.show_agents ? (
                <div className="filter-agent">
                  <AsyncInlineSelect
                    id="agent_id"
                    name="agent_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    defaultValue={{ first_name: 'All', last_name: 'Agents', id: '' }}
                    options={user.agent.id ? [ { first_name: 'All', last_name: 'Agents', id: '' }, { ...user.agent } ] : [ { first_name: 'All', last_name: 'Agents', id: '' } ]}
                    form={{}}
                    modelname="agents"
                    labelseparator=" "
                    fetchMany={this.props.actions.fetchMany}
                    optionlabel={[ 'first_name', 'last_name' ]}
                    noclear
                    params={{
                      branches__overlap: branch_id ? [ branch_id ] : branches.map(b => b.id),
                      active: 1,
                      order_by: 'first_name,last_name',
                      fields: 'id,first_name,last_name'
                    }}
                    field={{
                      value: getIn(this.state, 'agent_id')
                    }}
                    onChange={e => {
                      const agent_id = e.value
                      this.setState({ agent_id })
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        }
        body={
          !this.state.loading ? (
            <ResponsiveContainer width={'100%'} height={240} debounce={1} legendWrap={!showActions}>
              <PieChart cx="50%" cy="50%" margin={0}>
                <Legend wrapperStyle={{ width: showActions ? null : '100%', height: showActions ? '100%' : null, bottom: showActions ? null : 1 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={showActions ? 'top' : 'bottom'} align="right" content={this.renderCustomizedLegend} data={data} />
                <Pie
                  startAngle={90}
                  endAngle={-270}
                  data={[ { value: 100, label: 'No Leads Found' } ]} dataKey="value" innerRadius="66%" outerRadius="100%" fill="#F3F5F8" isAnimationActive={false} label={false}
                >
                  {!data.length ?
                    <Label fontFamily='Poppins' position="center" content={test => {
                      if (!test.viewBox.innerRadius) { return null }
                      let scale = test.viewBox.innerRadius / 79.2
                      const width = getTextWidth('0', `${34 * scale}px Poppins`)
                      const max = (test.viewBox.innerRadius - 5) * 2
                      if (width > max) {
                        const offset = max / width
                        scale *= offset
                      }
                      return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">0</Text>
                    }}></Label> : null}
                </Pie>
                <Pie
                  angleAxisId={0}
                  minAngle={1}
                  background={{ fill: '#ffffff' }}
                  clockWise
                  startAngle={90}
                  endAngle={-270}
                  innerRadius="66%"
                  outerRadius="100%"
                  isAnimationActive={false}
                  data={data}
                  labelLine={false}
                  strokeWidth={0}
                  label={this.renderCustomizedLabel}
                  dataKey="value"
                >
                  <Label fontFamily='Poppins' position="center" content={test => {
                    if (!test.viewBox.innerRadius) { return null }
                    let scale = test.viewBox.innerRadius / 79.2
                    const total = data.map(d => d.value).reduce((a, b) => a + b, 0)
                    const width = getTextWidth(valueFormat('number', total), `${34 * scale}px Poppins`)
                    const max = (test.viewBox.innerRadius - 5) * 2
                    if (width > max) {
                      const offset = max / width
                      scale *= offset
                    }
                    return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">{valueFormat('number', total)}</Text>
                  }}></Label>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
        }
      />
    )
  }
}

LeadStatusWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  status_field: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'leads')
  const status_field = config.get('statusField')
  return ({
    user,
    status_field
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, getLeadsBreakdown }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(LeadStatusWidget)))
