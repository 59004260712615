import { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { $generateHtmlFromNodes } from '@lexical/html'


import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'


export function useLazyEffect(
  effect,
  deps = [],
  wait = 300
) {
  const cleanUp = useRef()
  const effectRef = useRef()
  effectRef.current = useCallback(effect, deps)
  const lazyEffect = useCallback(
    debounce(() => (cleanUp.current = effectRef.current?.()), wait),
    []
  )
  useEffect(lazyEffect, deps)
  useEffect(() => () =>
    (cleanUp.current instanceof Function ? cleanUp.current() : undefined), [])
}

// eslint-disable-next-line react/prop-types
export default function HTMLTransformerPlugin({ field, defaultValue, form }) {
  const [ editor ] = useLexicalComposerContext()
  const [ value, setValue ] = useState(field.value || defaultValue || '')
  const timer = useRef()

  useEffect(() => {
    if (value !== field.value && value !== defaultValue) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        form.setFieldValue(field.name, value || '').then(() => {
          form.setFieldTouched(field.name, true)
        })
      }, 150)
    }
  }, [ value ])

  editor.registerUpdateListener(({ editorState }) => {
    // The latest EditorState can be found as `editorState`.
    // To read the contents of the EditorState, use the following API:
    editorState.read(() => {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      let htmlString = $generateHtmlFromNodes(editor, null)
      // fix bug where clearing the field results in'<p><br></p>'
      if (htmlString === '<p><br></p>') {
        htmlString = ''
      }
      htmlString = htmlString.replace(/( style="white-space: pre-wrap;"| dir="ltr")/gi, '').replace(/(\s<p)/gi, '<p')
      setValue(htmlString)
    })
  })
  return null
}

HTMLTransformerPlugin.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  defaultValue: PropTypes.string
}
