
import {
  $isParagraphNode,
  $isTextNode,
  ParagraphNode,
  TextNode,
  isHTMLElement
} from 'lexical'


const wrapElementWith = (element, tag) => {
  const el = document.createElement(tag)
  el.appendChild(element)
  return el
}

export const htmlSerializationConfig = {
  export: new Map([
    [
      TextNode,
      (_, node) => {
        if (!$isTextNode(node) || !node.__text) {return { element: null }}

        let element = document.createTextNode(node.__text)

        if (node.hasFormat('bold')) {
          element = wrapElementWith(element, 'strong')
        }
        if (node.hasFormat('italic')) {
          element = wrapElementWith(element, 'em')
        }

        return { element }
      }
    ],

    [
      ParagraphNode,
      (editor, node) => {
        if (!$isParagraphNode(node)) {return { element: null }}

        const { element } = node.exportDOM(editor)

        if (element && isHTMLElement(element)) {
          // Remove empty paragraphs
          if (node.isEmpty()) {return { element: null }}

          element.removeAttribute('class')
          element.removeAttribute('dir')
        }

        return {
          element
        }
      }
    ]
  ])
}

