import { formatDistanceToNow } from 'date-fns'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '../../ui/Button'


class LeadSummary extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    const { lead, match, model } = this.props
    return (
      <Button component={NavLink} to={`/secure/${match.params.model}/${match.params.id}/leads/${lead.id}`} className={classNames('leads-summary', { 'new-lead': lead.stage === 'New' }, { active: match.params.record_id === lead.id })}>
        <div className="leads-summary-top">
          <div className="leads-summary-status">
            {lead.status === 'Inactive' ? (
              <span className={classNames('tag', 'rounded', 'Archived')}>Archived</span>
            ) : (
              <span className={classNames('tag', 'rounded', lead.stage.replace(/\s/gi, '-'))}>{[ 'To Let', 'Holiday Letting' ].includes(model.listing_type) && lead.stage === 'Sold' ? 'Rented' : lead.stage}{lead.stage === 'New' ? ' lead' : ''}</span>
            )}
          </div>
          <div className="leads-summary-date">
            {formatDistanceToNow(new Date(lead.created).getTime(), { addSuffix: true }).toLocaleUpperCase()}
          </div>
        </div>
        <div className="leads-summary-body">
          {lead.meta.contact.first_name} {lead.meta.contact.last_name}
        </div>
      </Button>
    )
  }
}

LeadSummary.propTypes = {
  lead: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  togglePinNote: PropTypes.func
}

export default LeadSummary
