import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { capitalize } from '../utils'
import { Button } from './ui/Button'


const Notice = props => {
  const [ active, setActive ] = useState(false)
  const timer = useRef(false)
  const [ mounted, setMounted ] = useState(false)
  const { notice, actions } = props
  const { title, body, type, link, force, dismiss = false } = notice

  const performDismiss = () => {
    setActive(false)
  }

  useEffect(() => {
    setActive(true)
    setMounted(true)
    if (!dismiss && !force) {
      timer.current = setTimeout(() => performDismiss(), 4000)
    }
    return () => {
      if (timer.current) { clearTimeout(timer.current) }
    }
  }, [ ])

  useEffect(() => {
    if (!active && mounted) {
      if (timer.current) { clearTimeout(timer.current) } // Manually dismissed auto dismissed notice - clear timeout
      timer.current = setTimeout(() => actions.dismissNotice(notice.idx), 600)
    }
  }, [ active ])

  return (
    <div
      className={`notice-content${type ? ` ${type}` : ''}${active ? ' active' : ''}`}
      onClick={ typeof dismiss === 'boolean' && dismiss ? () => null : () => performDismiss()}
    >
      <h5>{title ? title : capitalize(type)}</h5>
      {typeof link !== 'string' && body ? (
        <p>{body}</p>
      ) : null
      }
      <div className="notice-buttons">
        {link
          ? <>
            { typeof link === 'string' ? (
              <Button component="a" className="btn btn-text-only has-link" href={link}>{body}</Button>
            ) : (
              <Button type="button" className="btn btn-round btn-green" onClick={() => {
                actions[link.action](link.args)
              } }>{link.args.label}</Button>
            ) }
          </>
          : null
        }
        { typeof dismiss === 'boolean' ? (
          <>
            {dismiss ? (
              <Button className="btn btn-round btn-red" onClick={() => performDismiss()}>Dismiss</Button>
            ) : (
              null
            )}
          </>
        ) : (
          <Button className="btn btn-round btn-red" onClick={() => actions[dismiss.action](dismiss.args)}>{dismiss.args.label}</Button>
        )}
      </div>
    </div>
  )
}

Notice.propTypes = {
  actions: PropTypes.object,
  dismissNotice: PropTypes.func,
  notice: PropTypes.object
}

const Notifier = ({ actions, notices }) => (
  <div className="notifier">
    {notices && notices.map((notice, idx) =>
      <Notice
        key={`notice-${idx}`}
        notice={notice}
        actions={actions}
      />
    )}
  </div>
)

Notifier.propTypes = {
  actions: PropTypes.object,
  notices: PropTypes.array
}

export default Notifier
