import PropTypes from 'prop-types'
import React from 'react'

import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ListingsAlerts = ({ configs, user, modelid, actions, modelname }) => {
  const params = {
    order_by: '-created',
    status: 'success',
    limit: 100,
    meta_fields: [ 'contact' ]
  }
  params[modelname] = modelid
  return (
    <Card
      bodyclass="alerts-sent no-top-padding"
      background
      header={
        <h3>Alerts Sent</h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.alerts}
          action={actions.fetchAlerts}
          params={params}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Contact',
              name: 'contact',
              group: 'Record Details',
              input: 'ContactLookup',
              orderby: 'contact__first_name',
              modelname: 'contacts',
              placeholder: 'Enter email, first name or last name',
              link: '/secure/:site/contacts/:contact',
              optionlabel: [ 'first_name', 'last_name' ],
              labelseparator: ' '
            },
            {
              label: 'Profile',
              name: 'profile',
              link: '/secure/:site/profiles/:profile',
              orderable: true
            },
            {
              label: 'Alert Type',
              name: 'alert_type',
              format: 'title'
            },
            {
              label: 'On Show Date',
              name: 'on_show_date',
              orderable: true
            },
            {
              label: 'Original Price',
              name: 'original_price',
              classes: [ 'text-right' ],
              orderable: true
            },
            {
              label: 'Reduced Price',
              name: 'reduced_price',
              classes: [ 'text-right' ],
              orderable: true
            }
          ]}
          user={user}
        />
      }
    />
  )
}

ListingsAlerts.propTypes = {
  permissions: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  currency: PropTypes.string,
  cache: PropTypes.object,
  alerts: PropTypes.object,
  fetchAlerts: PropTypes.func,
  updateModel: PropTypes.func,
  configs: PropTypes.object,
  user: PropTypes.object,
  modelid: PropTypes.number,
  actions: PropTypes.object,
  modelname: PropTypes.string
}

export default ListingsAlerts
