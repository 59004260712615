import React from 'react'
import { FieldArray, getIn } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Label from './Label'


class CheckGroupInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e, option) {
    const { usenames, usevalues, field, form, unique, multi } = this.props
    const target = e.currentTarget
    const fieldname = usenames ? option.name : field.name
    if (multi) {
      let current = getIn(form.values, fieldname, []) || []
      if (target.checked) {
        current = [ ...current, option.value ]
      } else {
        current = [ ...current ].filter(v => v !== option.value)
      }
      form.setFieldValue(field.name, current)
    } else if (unique) {
      if (target.checked) { form.setFieldValue(field.name, option.value) }
    } else if (target.checked) {
      form.setFieldValue(fieldname, !usevalues ? true : option.value)
        .then(() => form.setFieldTouched(fieldname)).then(() => {
          if (usenames) {
            form.setFieldValue(`${field.name}.${fieldname}`, !usevalues ? true : option.value)
          }
        })
    } else {
      form.setFieldValue(fieldname, !usevalues ? false : '')
        .then(() => form.setFieldTouched(fieldname)).then(() => {
          if (usenames) {
            form.setFieldValue(`${field.name}.${fieldname}`, !usevalues ? false : '')
          }
        })
    }
  }

  render() {
    const { field, form, label, options, id, usenames, multi } = this.props
    const { name, value } = field
    return (
      <div className="check-input-group checkinput form-group" id={id}>
        <Label htmlFor={name}>{label}</Label>
        {this.props.unique ? (
          <fieldset>
            {options.map((option, ind) => {
              let checked = option.value === value ? true : false
              if (multi) {
                const values = getIn(form.values, field.name, []) || []
                checked = values.includes(option.value)
              }
              return (
                <div className={classNames('checkinput', 'checkgroup', { checked: option.value === value ? true : false })} key={`${name}-${ind}`}>
                  <label className="checkcontainer">
                    <input
                      id={`${name}-${ind}`}
                      name={name}
                      type="checkbox"
                      onChange={ e => this.handleChange(e, option)}
                      onBlur={form.handleBlur}
                      className={checked ? 'on' : 'off'}
                      checked={checked}
                    />
                    <span className={`checkmark ${name}-${ind}`}>
                      <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg>
                    </span>
                  </label>
                  <Label htmlFor={`${name}-${ind}`}>{option.label}</Label>
                </div>
              )
            })}
          </fieldset>
        ) : (
          <FieldArray
            name={name}
            render={() => (
              <fieldset>
                {options.map((option, ind) => {
                  let checked = option.value === value ? true : false
                  if (usenames) {
                    checked = getIn(form.values, option.name, false)
                  }
                  if (multi) {
                    const values = getIn(form.values, field.name, []) || []
                    checked = values.includes(option.value)
                  }
                  return (
                    <div className={classNames('checkinput', 'checkgroup', { checked: option.value === value ? true : false })} key={`${name}-${ind}`}>
                      <label
                        className="checkcontainer"
                        key={usenames ? option.name : `${name}-${ind}`}
                      >
                        <input
                          id={usenames ? option.name : `${name}-${ind}`}
                          name={usenames ? option.name : `${name}-${ind}`}
                          type="checkbox"
                          onChange={ e => this.handleChange(e, option)}
                          onBlur={form.handleBlur}
                          className={checked ? 'on' : 'off'}
                          checked={checked}
                        />
                        <span className={usenames ? `checkmark ${option.name}` : `checkmark ${name}-${ind}`}>
                          <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg>
                        </span>
                      </label>
                      <Label className="formlabel" htmlFor={usenames ? `${option.name}` : `${name}-${ind}`}>{option.label}</Label>
                    </div>
                  )
                })}
              </fieldset>
            )}
          />
        )}
      </div>
    )
  }
}

CheckGroupInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  unique: PropTypes.bool,
  usenames: PropTypes.bool,
  usevalues: PropTypes.bool,
  multi: PropTypes.bool,
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default CheckGroupInput
