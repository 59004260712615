import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import { isConditional } from '../../utils'
import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import Notes from '../common/notes/Notes'
import Alerts from '../common/ProfileAlerts'
import Matches from '../common/ProfileMatches'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'


class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: false,
      match_count: 0,
      loading_matches: true,
      alert_count: 0,
      loading_alerts: true
    }
    this.isConditional = isConditional.bind(this)
  }

  componentDidMount() {
    new Promise((resolve, reject) => this.props.actions.fetchMatches({
      fields: [
        'id',
        'created',
        'score',
        'bedrooms',
        'bathrooms',
        'floor_size',
        'land_size',
        'garages',
        'unit_number',
        'complex_name',
        'area',
        'location',
        'model',
        'price',
        'listing_type',
        'property_type',
        'building_name',
        'street_number',
        'street_name',
        'listing_images',
        'branch',
        'agent',
        'agent_2',
        'agent_3',
        'agent_4',
        'portals',
        'web_ref',
        'reference',
        'portal'
      ],
      offset: 0,
      limit: 100
    }, resolve, reject)).then(r => {
      this.setState({ match_count: r.count, loading_matches: false })
    })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.alerts, this.props.alerts)) {
      this.setState({ alert_count: this.props.alerts.count })
    }
  }


  render() {
    const {
      model,
      modelid,
      config,
      configs,
      cache,
      actions,
      user,
      match,
      routeConfig
    } = this.props

    const matchescache = {}
    matchescache[`profiles${model.id}matches`] = cache[`profiles${model.id}matches`]
    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="matches" label="Matches">
          <Matches
            model={model}
            user={user}
            configs={configs}
            cache={cache}
            actions={actions}
          />
        </Tab>

        <Tab tab="alerts-sent" label="Alerts Sent">
          <Alerts
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>

    )
  }
}

Profile.propTypes = {
  actions: PropTypes.object,
  selected: PropTypes.array,
  model: PropTypes.object,
  routeConfig: PropTypes.object,
  modelid: PropTypes.number,
  title: PropTypes.string,
  tab: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  cache: PropTypes.object,
  stats: PropTypes.object,
  user: PropTypes.object,
  alerts: PropTypes.object
}

export default Profile
