/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { FieldArray, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '../../../ui/Button'
import FieldComponent from '../FieldComponent'
import { valueFormat } from '../../../../utils'
import Tip from '../Tip'
import useWatched from './useWatched'
// import { default as PDMSFieldArray } from './FieldArray'


const getNextDayOfTheWeek = (dayName, excludeToday = true, refDate = new Date()) => {
  const dayOfWeek = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ].indexOf(dayName.slice(0, 3).toLowerCase())
  if (dayOfWeek < 0) { return } // Invalid day given
  refDate.setHours(0, 0, 0, 0) // We don't need time
  refDate.setDate(refDate.getDate() + +!!excludeToday + (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  // eslint-disable-next-line consistent-return
  return refDate
}

const OnShowEventsInput = props => {
  const watched = useWatched(props, { fields: props.fields }, true)

  const populateWeekendDay = (day, push, remove) => {
    let { value } = props.field
    if (!Array.isArray(value)) { value = [] }
    let next_day = new Date()
    for (let i = 1; i <= 8; i++) {
      const start_date = next_day
      next_day = getNextDayOfTheWeek(day, true, start_date)
      const offset = next_day.getTimezoneOffset()
      next_day = new Date(next_day.getTime() - (offset * 60 * 1000))
      const formatted_day = next_day.toISOString().split('T')[0]
      const on_show_event = {
        on_show_date: formatted_day,
        on_show_start_time: '14:00',
        on_show_end_time: '17:00'
      }
      if (!value.find(e => e.on_show_date === formatted_day)) {
        push(on_show_event)
      }
    }
    value.forEach((v, vidx) => { // Clean up blank dated events
      if (v.on_show_date === null) { remove(vidx) }
    })
  }

  const renderArrayFields = idx => {
    const { errors, values } = props.form
    const outer = Array.isArray(watched[idx]) ? watched[idx].filter(field => {
      if (props.show_required) { return field._edit === 'true' && field._required === 'true' }
      if (props.required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    }) : null
    let current_group = []
    let count = 12
    if (outer) {
      const outer_hidden = outer.filter(field => field.input === 'Hidden').map((field, fidx) => (
        <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
      ))
      const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, fidx) => {
        if (field.name.indexOf('.price_per') > -1) {
          field.label = `Price Per ${valueFormat('unit', values.floor_size_measurement_type)}`
        }
        if (props.input === 'TranslatableTextArea') {
          if (field.name.indexOf('language') > -1 && field.input === 'Select') {
            const already_selected = getIn(props.form.values, props.field.name, []).map(v => v.language)
            const options = props.settings.meta.allowed_translations
              .map(lang => ({ value: lang.code, label: lang.name, isDisabled: already_selected.includes(lang.code) }))
            field.options = options
          }
          if (field.name.indexOf('content') > -1 && field.input === 'TextArea') {
            const language = getIn(props.form.values, field.name.replace('.content', '.language'), {})
            if (language) {
              const option = props.settings.meta.allowed_translations
                .find(lang => language === lang.code)
              field.label = field.label.replace('Translated', option.name)
              field.lang = option.code
              if (option.rtl) {
                field.rtl = true
              }
            }
          }
        }
        return (
          <div
            key={`field-${props.field.name}-${idx}-${fidx}`}
            className={classNames('field', { error: (field.name in errors), required: field._required === 'true' }, field.cols ? `col-${field.cols}` : '')}
          >
            <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
          </div>
        )
      })
      const groups = [ ...outer_hidden ]
      outer_visible.forEach((node, fidx) => {
        const classes = node.props && node.props.className ? node.props.className : ''
        const regex = /col(-[a-z]+)?-(\d+)/ig
        const matches = regex.exec(classes)
        current_group.push(node)
        if (matches) {
          const col = parseInt(matches.pop(), 10)
          count -= col
        }
        if (count <= 0 || fidx === outer.length - 1) {
          groups.push(<div key={`col-${idx}-${fidx}`} className='input-group'>{current_group}</div>)
          current_group = []
          count = 12
        }
      })
      return groups
    }
    return null
  }

  const { name, value } = props.field
  const { allowdelete, id, classes } = props
  return (
    <FieldArray
      name={name}
      render={({ push, insert, remove }) => (
        <div id={id} className={classNames('form-group', 'on_show_events', classes)}>
          <div className="arrayaction">
            <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
              populateWeekendDay('Saturday', push, remove)
            }}>Add On Show Every Saturday</Button>
            <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
              populateWeekendDay('Sunday', push, remove)
            }}>Add On Show Every Sunday</Button>
          </div>
          <Tip heading="Please Note" multi text="By clicking one, or both, of these buttons, you will automatically create as many showdays as there are Saturdays/Sundays in the next 60 days" />
          {value && Array.isArray(value) && value.map((f, idx) => {
            const event_date = new Date(f.on_show_date)
            const today = new Date()
            // yesterday.setDate(yesterday.getDate() -1)
            today.setHours(0, 0, 0, 0)
            if (event_date <= today && f.on_show_date !== null) { return null }
            return (
              <fieldset className="input-group col-lg-12" key={`fset-${idx}` }>
                {renderArrayFields(idx)}
                {(allowdelete && value.length >= 1) ? (
                  <div className="arrayaction">
                    <Button icon="#icon16-Minus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                      remove(idx)
                    }}>Remove Show Day</Button>
                  </div>
                ) : null}
              </fieldset>
            )
          }
          ) }
          <div className="arrayaction">
            <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
              insert(!Array.isArray(value) ? 0 : value.length + 1, {})
            }}>Add Show Day</Button>
          </div>
        </div>
      ) }
    />
  )
}

OnShowEventsInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  fields: PropTypes.array.isRequired,
  text_suffix: PropTypes.string,
  input: PropTypes.string,
  cache: PropTypes.object,
  settings: PropTypes.object,
  dragEnded: PropTypes.func,
  required: PropTypes.bool,
  allowdelete: PropTypes.bool,
  sortable: PropTypes.bool,
  show_required: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  multi: PropTypes.bool,
  max: PropTypes.number
}

export default OnShowEventsInput
