import React from 'react'
import { Redirect } from 'react-router'
import merge from 'deepmerge'

import { getDisplayName } from '../../utils'

/* StepComponent HOC which provides a
 * stepping mechanism for the relevant model. The step
 * data is contained in the local component state but
 * is populated from the redux store's selected items
*/


export function withStep(SteppedComponent) {
  return class StepComponent extends SteppedComponent {
    static displayName = `Stepped(${getDisplayName(SteppedComponent)})`

    constructor(props) {
      super(props)
      this.state = {
        new: false,
        next: false,
        previous: false,
        selected: props.selected,
        modelname: props.config.modelname,
        redirect: null
      }
      this.stepPage = this.stepPage.bind(this)
    }

    componentDidMount() {
      if (this.props.model) {
        this.configStep()
      }
    }

    componentDidUpdate(prevProps) {
      const { match, config } = this.props
      if (this.state.new || this.state.new !== false) {
        const n = this.state.selected[this.state.new]
        this.setState({ new: false, redirect: `/secure/${this.props.user.agent.site.id}/${config.modelname}/${n}/${match.params.tab}${match.params.profiletype ? `/${match.params.profiletype}` : ''}` })
      }
      if (!prevProps.model && this.props.model) {
        this.configStep()
      }
      if (this.state.redirect) {
        this.setState({ redirect: null })
      }
    }

    configStep() {
      const { selected } = this.props
      const newidx = false
      let nextidx = false
      let previdx = false
      if (!selected.length) {
        return
      } else if (selected.length) {
        const curidx = selected.indexOf(this.props.model.id)
        nextidx = curidx + 1 < selected.length ? curidx + 1 : false
        if (curidx !== 0) { previdx = curidx - 1 }
      }
      let newstate = merge({}, this.state)
      newstate = {
        ...newstate,
        new: newidx,
        next: nextidx,
        previous: previdx
      }
      const diff = Object.keys(newstate).reduce((rdiff, key) => {
        if (newstate[key] === this.state[key]) { return rdiff }
        return {
          ...rdiff,
          [key]: this.state[key]
        }
      }, {})
      if (Object.keys(diff).length) {
        this.setState(newstate)
      }
    }

    stepPage(dir) {
      let newidx = false
      let nextidx = false
      let previdx = false
      if (!this.state.selected) { return false }
      switch (dir) {
        case 'last':
          newidx = this.state.selected.length - 1
          previdx = this.state.selected.length - 2
          break
        case 'next':
          newidx = this.state.next
          nextidx = this.state.selected.length === newidx + 1 ? false : newidx + 1
          previdx = newidx - 1
          break
        case 'previous':
          newidx = this.state.previous
          previdx = newidx === 0 ? false : newidx - 1
          nextidx = newidx + 1
          break
        case 'first':
          newidx = 0
          nextidx = 1
          break
        default:
          newidx = 0
      }
      this.setState({
        new: newidx,
        next: nextidx,
        previous: previdx
      })
      return null
    }

    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} push />
      }
      return <SteppedComponent key={`details-${this.props.modelid}`} {...this.props } steps={{ ...this.state, stepPage: this.stepPage }} />
    }
  }
}

export default withStep
