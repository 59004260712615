import classNames from 'classnames'
import { getIn, Formik, Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import country_codes from '../../config/countrycodes.json'
import log from '../../logging'
import { capitalize, generateAddress, hasPermission, slugify, sortBy, uniqueArray, valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'
import CheckGroup from './forms/inputs/CheckGroup'
import WhatsAppButton from './WhatsAppButton'
import SelectInput from './forms/inputs/Select'
import CheckInput from './forms/inputs/Check'


class Matches extends React.PureComponent {
  constructor(props) {
    super(props)
    this.getContactNumber = this.getContactNumber.bind(this)
    this.alertAgentPropertyLead = this.alertAgentPropertyLead.bind(this)
    this.disableAlerts = this.disableAlerts.bind(this)
    this.state = {
      offset: 0,
      more: true,
      loading_highlights: true,
      loading_recent_matches: true,
      loading_older_matches: true,
      loading_more_recent_matches: false,
      loading_more_older_matches: false,
      recent_match_count: 0,
      old_match_count: 0,
      older: [],
      recent: [],
      highlights: []
    }
    this.AbortController = new AbortController()
    this._is_mounted = true

    const initial_fields = [
      {
        label: 'No.',
        name: 'idx',
        classes: 'text-right',
        orderable: false,
        format: 'number'
      },
      {
        label: <svg viewBox="0 0 32 32" className="btmstar"><use href="/images/glyphs.svg#glyph-Star" /></svg>,
        name: 'score',
        orderable: false,
        format: 'score'
      },
      {
        label: 'Date Added',
        name: 'created',
        orderable: false,
        format: 'datetime'
      },
      {
        label: 'Status',
        name: 'status',
        orderable: false
      },
      {
        label: 'Contact',
        optionlabel: [ 'first_name', 'last_name' ],
        name: 'contact',
        modelname: 'contact',
        labelseparator: ' ',
        metafield: true,
        orderable: false,
        link: '/secure/contacts/:contact',
        format: 'contact_popup',
        permissions: [
          'contacts_view',
          'contacts_view_own',
          'contacts_associated_agents_view'
        ],
        permission_key: {
          introduction_agent: [ 'contacts_view_own' ],
          associated_agents: [ 'contacts_associated_agents_view' ]
        }
      },
      {
        label: 'Branch',
        optionlabel: 'name',
        name: 'meta.contact.meta.branch',
        modelname: 'contact',
        labelseparator: ' ',
        metafield: true,
        orderable: false,
        link: '/secure/branches/:meta.contact.branch'
      },
      {
        label: 'Assigned To',
        name: 'assigned_agent',
        optionlabel: [ 'first_name', 'last_name' ],
        labelseparator: ' ',
        modelname: 'agent',
        metafield: true,
        orderable: false,
        link: '/secure/agents/:assigned_agent'
      },
      {
        label: 'Suburbs',
        name: 'suburbs',
        orderable: false,
        indicator: {
          value: props.model.location,
          type: 'in'
        },
        modelname: 'location',
        optionlabel: [ 'suburb' ],
        labelseparator: ', ',
        empty_value: 'All Suburbs'
      },
      {
        label: 'Property Types',
        name: 'property_types',
        orderable: false,
        indicator: {
          value: props.model.property_type,
          type: 'in'
        },
        empty_value: 'Any'
      }
    ]
    const residential_fields = [
      {
        label: 'Price Range',
        name: [ 'price_from', 'price_to' ],
        orderable: false,
        indicator: {
          value: props.model.price,
          type: 'range'
        },
        format: 'price_range'
      }
    ]
    const commercial_fields = [
      {
        label: 'Size Range',
        name: [ 'floor_size_from', 'floor_size_to' ],
        orderable: false,
        indicator: {
          value: props.model.floor_size,
          type: 'range'
        },
        format: 'size_range'
      }
    ]
    const end_fields = [
      {
        label: 'Alert Sent',
        name: 'alert_sent',
        format: 'yesno',
        orderable: false
      }
    ]
    this.all_fields = props.config.modelname === 'residential' ? (
      [ ...initial_fields, ...residential_fields, ...end_fields ]
    ) : (
      [ ...initial_fields, ...commercial_fields, ...end_fields ]
    )
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  disableAlerts(email) {
    // Any profiles for the same email address should not be allowed to receive
    // another alert for the same listing
    this.highlight_table.setState({ data: this.highlight_table.state.data.map(k => ({
      ...k,
      can_send_alert: k.meta.contact.email.toLowerCase() === email.toLowerCase(),
      alert_sent: k.meta.contact.email.toLowerCase() === email.toLowerCase() ? true : k.alert_sent
    })) })
    this.recent_table.setState({ data: this.recent_table.state.data.map(k => ({
      ...k,
      can_send_alert: k.meta.contact.email.toLowerCase() === email.toLowerCase(),
      alert_sent: k.meta.contact.email.toLowerCase() === email.toLowerCase() ? true : k.alert_sent
    })) })
    this.older_table.setState({ data: this.older_table.state.data.map(k => ({
      ...k,
      can_send_alert: k.meta.contact.email.toLowerCase() === email.toLowerCase(),
      alert_sent: k.meta.contact.email.toLowerCase() === email.toLowerCase() ? true : k.alert_sent
    })) })
  }

  alertAgentPropertyLead(event, profile, contactid, modelid, modelname) {
    event.preventDefault()
    const values = {
      profile: profile.id,
      contact: contactid,
      alert_type: 'manual-listing-match',
      status: 'pending'
    }
    const listing_type = `${modelname}`
    values[listing_type] = modelid
    return new Promise((resolve, reject) => {
      this.props.actions.alertAgentPropertyLead({ values, resolve, reject })
    }).then(r => {
      if (this._is_mounted) {
        this.disableAlerts(profile.meta.contact.email)
        log.debug(`Success: ${r}`)
      }
    }).catch(e => {
      // Notify
      log.error(e)
    })
  }

  notifyReferralRequest(event, contactid) {
    event.preventDefault()
    const values = { contact: contactid }
    return new Promise((resolve, reject) => {
      this.props.actions.notifyReferralRequest({ values, resolve, reject })
    }).then(r => {
      log.debug(`Success: ${r}`)
    }).catch(e => {
      log.error(e)
    })
  }

  getContactNumber(profile) {
    let contact_number = getIn(profile, 'meta.contact.cell_number')
    if (!contact_number && getIn(profile, 'meta.contact.work_number')) {
      contact_number = profile.meta.contact.work_number
    }
    if (!contact_number && getIn(profile, 'meta.contact.home_number')) {
      contact_number = profile.meta.contact.home_number
    }
    contact_number = contact_number && contact_number.match(/\d+/g) ? contact_number.match(/\d+/g).join('') : null
    if (contact_number && contact_number.startsWith('0')) {
      const base_country = (profile.meta.areas && profile.meta.areas.length) ? profile.meta.areas[0].country : 'South Africa'
      const country = country_codes.find(c => c.name === base_country)
      contact_number = contact_number.replace('0', country.dial_code)
    }
    return contact_number
  }

  parseData(data, highlights) {
    const { model, user, config } = this.props
    const newData = highlights ? {
      options: data.results.map(l => ({
        ...l,
        address: generateAddress(l)
      })),
      hasMore: !!data.next
    } : {
      options: data.results.filter(l => !this.state.highlights.includes(l.id)).map(l => ({
        ...l,
        address: generateAddress(l)
      })),
      hasMore: !!data.next
    }
    newData.options = newData.options.map(profile => {
      const min_profile = {}
      min_profile.id = profile.id
      min_profile.contact = profile.contact
      min_profile.meta = { ...profile.meta,
        areas: null,
        suburbs: profile.meta.suburbs?.filter((suburb, idx) => suburb.id === model.location || idx === 0),
        contact: {
          status: profile.meta.contact.status,
          first_name: profile.meta.contact.first_name,
          last_name: profile.meta.contact.last_name,
          cell_number: profile.meta.contact.cell_number,
          work_number: profile.meta.contact.work_number,
          home_number: profile.meta.contact.home_number,
          email: profile.meta.contact.email,
          introduction_agent: profile.meta.contact.introduction_agent,
          meta: {
            branch: profile.meta.contact.meta.branch,
            consent: profile.meta.contact.meta.consent,
            introduction_agent: profile.meta.contact.meta.introduction_agent
          },
          branch: profile.meta.contact.branch
        },
        lead: {
          agent: profile.meta.lead?.agent,
          meta: {
            agent: profile.meta.lead?.meta.agent
          }
        }
      }
      let agent = null
      let agent_data = null
      if (!agent && profile.agent && profile.meta.agent) {
        agent = profile.agent
        agent_data = profile.meta.agent
      }
      if (!agent && getIn(profile, 'meta.lead.meta.agent')) {
        agent = getIn(profile, 'meta.lead.agent')
        agent_data = getIn(profile, 'meta.lead.meta.agent')
      }
      if (!agent && getIn(profile, 'meta.contact.meta.introduction_agent')) {
        agent = getIn(profile, 'meta.contact.introduction_agent')
        agent_data = getIn(profile, 'meta.contact.meta.introduction_agent')
      }
      if (!profile.price_to) {
        min_profile.price_to = '0'
      }
      if (!profile.price_from) {
        min_profile.price_from = '0'
      }
      min_profile.assigned_agent = agent
      min_profile.meta.assigned_agent = agent_data
      let can_send = false
      if (profile.can_send_alert) { // no other alert has been sent to this profile
        if ([
          model.agent,
          model.agent_2,
          model.agent_3,
          model.agent_4
        ].filter(a => a).includes(user.agent.id)) { // listing belongs to current agent
          if (profile.agent && profile.agent === user.agent.id) { // profile has lead and lead belongs to current agent
            can_send = true
          } else if (profile.lead && profile.meta.lead && profile.meta.lead.agent === user.agent.id) {
            can_send = true
          } else if (
            (!profile.lead || (profile.lead && !profile.meta.lead.agent))
            || (!profile.agent || (profile.agent && !profile.meta.agent))
          ) {
            can_send = true
          }
        } else if (user.permissions.includes('is_prop_data_user')) {
          can_send = true
        } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
          can_send = true
        }
        if (![ 'Active', 'No-Consent', 'Non-Compliant' ].includes(profile.status)) {
          can_send = false
        }
        if (getIn(profile, 'meta.contact.first_name') === '[Redacted]') {
          can_send = false
          min_profile.last_name = ''
        }
      }
      min_profile.alert_sent = !profile.can_send_alert
      min_profile.can_send = can_send
      this.all_fields.forEach(field => {
        if (Array.isArray(field.name)) {
          field.name.forEach(fname => {
            if (
              !(fname in min_profile)
              && !getIn(min_profile, fname)
              && getIn(profile, fname)
            ) {
              min_profile[fname] = getIn(profile, fname)
            }
          })
        } else if (!(field.name in min_profile) && !getIn(min_profile, field.name) && getIn(profile, field.name)) {
          min_profile[field.name] = getIn(profile, field.name)
        }
      })
      return min_profile
    })
    if (this._is_mounted && newData.options) {
      const new_highlights = [ ...this.state.highlights, ...newData.options.map(p => p.id) ]
      if (highlights && !isEqual(new_highlights, this.state.highlights)) {
        this.setState({ highlights: new_highlights })
      }
    }
    return newData
  }

  render() {
    const { user, model, cache, config, configs, actions, addons } = this.props
    const webref = config.fields.find(f => f.name === 'web_ref')
    let price = null
    const settings = user ? cache.settings[user.agent.site.id] : {}
    const currency = settings.default_currency
    const enable_popia = settings.enable_popia
    if ([ 'residential', 'commercial' ].includes(config.modelname)) {
      webref.currency = currency
      price = config.fields.find(f => f.name === 'price')
      price.currency = currency
    } else if (config.modelname === 'project') {
      webref.currency = currency
      price = config.fields.find(f => f.name === 'property_types').fields.find(f => f.name === 'priced_from')
      price.currency = currency
    }
    const listing_image = getIn(model.meta, [ 'listing_images', 0, 'file' ])
    const map_image = getIn(model.meta, [ 'map_image', 'file' ])

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={() => { }}
      >{formikBag => (
          <div>
            <Card
              background
              header={
                <><h3 className="matches-header">Listing Details</h3>
                  { [ 'To Let', 'For Sale' ].includes(model.listing_type) && model.display_on_website ? <div className='profile-matches'>
                    <div className='hot-count'>
                      <svg className="btmstar hot" viewBox="0 0 32 32"><use href='/images/glyphs.svg#glyph-Star' /></svg>
                      <span>Hot ({model.meta.statistics.hot_matches})</span>
                    </div>
                    <div className='mild-count'>
                      <svg className="btmstar warm" viewBox="0 0 32 32"><use href='/images/glyphs.svg#glyph-Star' /></svg>
                      <span>Mild ({model.meta.statistics.warm_matches})</span>
                    </div>
                    <div className='cold-count'>
                      <svg className="btmstar cold" viewBox="0 0 32 32"><use href='/images/glyphs.svg#glyph-Star' /></svg>
                      <span>Cold ({model.meta.statistics.cold_matches})</span>
                    </div>
                  </div> : null }
                </>
              }
              bodyclass="matches-listing no-top-padding"
              body={
                <div className="matches-listing-details">
                  {listing_image && <div className="matches-listing-image" style={{ backgroundImage: `url('${valueFormat('image_url', listing_image)}')` }} />}
                  <div className="matches-listing-meta">
                    <div className="matches-listing-type">
                      {model.property_type}
                    </div>
                    <div className="matches-listing-price">
                      {valueFormat('currency', model.price, { currency })}
                    </div>
                    <div className="matches-listing-address">
                      {generateAddress(model, cache.locations, {
                        parts: [ 'complex', 'street', 'meta.location.suburb' ]
                      })}
                    </div>
                    <div className="matches-listing-heading">
                      {`${model.web_ref} ${capitalize(model.model)} ${model.listing_type}`}
                    </div>
                  </div>
                  {map_image && <div className="matches-listing-map">
                    {valueFormat('image', { file: map_image, width: 1024, height: 768 })}
                    <div className="marker-dot"></div>
                  </div>}
                </div>
              }
            />
            <Card
              background={true}
              bodyclass="no-top-padding"
              body={
                <>
                  <div className="match-filters form-row">
                    <div><h3>Filter Matches By</h3></div>
                    <div className="filters-container">
                      <div>
                        <Field
                          component={CheckGroup}
                          usenames
                          id="matches_on"
                          label="Matches on"
                          options={[
                            {
                              label: 'Property Type',
                              name: 'type_match'
                            },
                            {
                              label: 'Suburb',
                              name: 'suburb_match'
                            },
                            {
                              label: model.model === 'residential' ? 'Price Range' : 'Size Range',
                              name: 'criteria_match'
                            }
                          ]}
                        />
                      </div>
                      <div className="form-group check-input-group">
                        <Field
                          name="agent_only"
                          id="agent_only_field"
                          component={CheckInput}
                          label="Show only my profiles"
                        />
                      </div>
                      <div className="form-group score">
                        <Field
                          name="score"
                          id="score"
                          label="Matches score"
                          component={SelectInput}
                          multi
                          options={[
                            { value: 3, label: 'Hot Matches' },
                            { value: 2, label: 'Mild Matches' },
                            { value: 1, label: 'Cold Matches' }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <Card
              background
              header={
                <h3>Highlighted Matches</h3>
              }
              classes="maxcard"
              bodyclass="no-top-padding"
              body={
                <SimpleTable
                  paginated
                  config={configs.profiles}
                  action={actions.fetchMatches}
                  header={this.all_fields}
                  user={user}
                  params={{
                    limit: 100,
                    highlights: 1,
                    meta_fields: [ 'agent', 'lead', 'contact' ],
                    ...formikBag.values
                  }}
                  getClass={tbl => {
                    this.highlight_table = tbl
                  }}
                  parser={data => this.parseData(data, true)}
                  currency={currency}
                  rowActions={(row, data) => {
                    let can_view_lead = false
                    if (data.lead && data.meta.lead && data.meta.lead.agent === user.agent.id) { // profile has lead and lead belongs to current agent
                      can_view_lead = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_lead = true
                    } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
                      can_view_lead = true
                    }
                    let can_view_contact = false
                    if (data.contact && data.meta.contact && data.meta.contact.introduction_agent === user.agent.id) {
                      can_view_contact = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_contact = true
                    } else if (
                      [ 'residential', 'commercial' ].includes(model.model) &&
                      hasPermission([ 'contacts_view' ], user.permissions) &&
                      data.contact &&
                      data.meta.contact &&
                      (user.agent.branches.includes(data.meta.contact.branch) || user.permissions.includes('apply_to_all_branches'))
                    ) {
                      can_view_contact = true
                    }
                    let request_referral = false
                    if (settings.referral_match_requests && !can_view_contact && addons.includes('referrals_addon') && user.permissions.includes('referrals_add')) {
                      request_referral = true
                    }
                    const contact_number = this.getContactNumber(data)
                    return (
                      <>
                        <Button
                          icon="#glyph-Flag"
                          className={classNames('btn', 'btn-icon-only', 'btn-none', 'btn-icon-16', 'btn-glyph', 'btn-small', 'highlight')}
                          title="Unhighlight Match"
                          type="button"
                          onClick={() => {
                            new Promise((resolve, reject) => actions.unhighlightMatch({
                              match: data,
                              resolve,
                              reject
                            })).then(() => {
                              data.highlight = null
                              let { data: highlightdata } = this.highlight_table.state
                              highlightdata = highlightdata.filter(l => l.id !== data.id)
                              const now = new Date()
                              const then = new Date(data.created)
                              const days = new Date().setDate(now.getDate() - 90)
                              if (days < now - then) {
                                let { data: recentdata } = this.recent_table.state
                                recentdata.push(data)
                                recentdata = sortBy(recentdata, 'created')
                                recentdata = sortBy(recentdata, 'score')
                                recentdata.reverse()
                                this.recent_table.setState({ data: recentdata })
                                this.highlight_table.setState({ data: highlightdata })
                              } else {
                                let { data: olderdata } = this.older_table.state
                                olderdata.push(data)
                                olderdata = sortBy(olderdata, 'created')
                                olderdata = sortBy(olderdata, 'score')
                                olderdata.reverse()
                                this.older_table.setState({ data: olderdata })
                                this.highlight_table.setState({ data: highlightdata })
                              }
                            }).catch(e => console.error(e))
                          }}
                        />
                        {(!data.alert_sent && data.can_send && !request_referral) ? (
                          <Button
                            className="btn btn-red btn-small"
                            title="Send Alert"
                            type="button"
                            onClick={e => this.alertAgentPropertyLead(
                              e,
                              data,
                              data.contact,
                              model.id,
                              model.model
                            )}
                          >
                            Send Alert
                          </Button>
                        ) : null}
                        {request_referral ? (
                          <Button
                            className="btn btn-blue btn-small"
                            title="Request Referral"
                            type="button"
                            onClick={e => this.notifyReferralRequest(
                              e,
                              data.contact
                            )}
                          >
                            Request Referral
                          </Button>
                        ) : null}
                        {can_view_lead && contact_number && data.can_send && (enable_popia ? getIn(data, 'meta.contact.meta.consent.consent_messages') : true) &&
                          <WhatsAppButton
                            component={Button}
                            type="button"
                            resetButtonStyle={false}
                            className="btn btn-green btn-small"
                            separator=" : "
                            url={model.meta?.url.website}
                            phone={contact_number}
                            title={`Property Alert - ${model.web_ref}`}
                          >
                            Send WhatsApp
                          </WhatsAppButton>
                        }
                      </>
                    )
                  }}
                />
              }
            />
            <Card
              background
              header={
                <h3>90 days or less</h3>
              }
              classes="maxcard"
              bodyclass="no-top-padding"
              body={
                <SimpleTable
                  paginated
                  config={configs.profiles}
                  action={actions.fetchRecentMatches}
                  header={this.all_fields}
                  user={user}
                  params={{
                    limit: 100,
                    highlights: false,
                    meta_fields: [ 'agent', 'lead', 'contact' ],
                    ...formikBag.values
                  }}
                  getClass={tbl => {
                    this.recent_table = tbl
                  }}
                  parser={data => this.parseData(data, false)}
                  currency={currency}
                  rowActions={(row, data) => {
                    let can_view_lead = false
                    if (data.lead && data.meta.lead && data.meta.lead.agent === user.agent.id) { // profile has lead and lead belongs to current agent
                      can_view_lead = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_lead = true
                    } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
                      can_view_lead = true
                    }
                    let can_view_contact = false
                    if (data.contact && data.meta.contact && data.meta.contact.introduction_agent === user.agent.id) {
                      can_view_contact = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_contact = true
                    } else if (
                      [ 'residential', 'commercial' ].includes(model.model) &&
                      hasPermission([ 'contacts_view' ], user.permissions) &&
                      data.contact &&
                      data.meta.contact &&
                      (user.agent.branches.includes(data.meta.contact.branch) || user.permissions.includes('apply_to_all_branches'))
                    ) {
                      can_view_contact = true
                    }
                    let request_referral = false
                    if (settings.referral_match_requests && !can_view_contact && addons.includes('referrals_addon') && user.permissions.includes('referrals_add')) {
                      request_referral = true
                    }
                    const contact_number = this.getContactNumber(data)
                    return (
                      <>
                        <Button
                          icon="#glyph-Flag"
                          className={classNames('btn', 'btn-icon-only', 'btn-none', 'btn-icon-16', 'btn-glyph', 'btn-small')}
                          title="Highlight Match"
                          type="button"
                          onClick={() => {
                            new Promise((resolve, reject) => actions.highlightMatch({
                              match: data,
                              resolve,
                              reject
                            })).then(r => {
                              data.highlight = r.id
                              let { data: highlightdata } = this.highlight_table.state
                              highlightdata.push(data)
                              highlightdata = uniqueArray(highlightdata, 'id')
                              let { data: recentdata } = this.recent_table.state
                              recentdata = recentdata.filter(l => l.id !== data.id)
                              this.recent_table.setState({ data: recentdata })
                              this.highlight_table.setState({ data: highlightdata })
                            }).catch(e => console.error(e))
                          }}
                        />
                        {(!data.alert_sent && data.can_send && !request_referral) ? (
                          <Button
                            className="btn btn-red btn-small"
                            title="Send Alert"
                            type="button"
                            onClick={e => this.alertAgentPropertyLead(
                              e,
                              data,
                              data.contact,
                              model.id,
                              model.model
                            )}
                          >
                            Send Alert
                          </Button>
                        ) : null}
                        {request_referral ? (
                          <Button
                            className="btn btn-blue btn-small"
                            title="Request Referral"
                            type="button"
                            onClick={e => this.notifyReferralRequest(
                              e,
                              data.contact
                            )}
                          >
                            Request Referral
                          </Button>
                        ) : null}
                        {can_view_lead && contact_number && data.can_send && (enable_popia ? getIn(data, 'meta.contact.meta.consent.consent_messages') : true) &&
                          <WhatsAppButton
                            component={Button}
                            type="button"
                            resetButtonStyle={false}
                            className="btn btn-green btn-small"
                            separator=" : "
                            url={model.meta?.url.website}
                            phone={contact_number}
                            title={`Property Alert - ${model.web_ref}`}
                          >
                            Send WhatsApp
                          </WhatsAppButton>
                        }
                      </>
                    )
                  }}
                />
              }
            />
            <Card
              background
              header={
                <h3>Older than 90 days</h3>
              }
              classes="maxcard"
              bodyclass="no-top-padding"
              body={
                <SimpleTable
                  paginated
                  config={configs.profiles}
                  action={actions.fetchOldMatches}
                  header={this.all_fields}
                  user={user}
                  params={{
                    limit: 100,
                    highlights: false,
                    meta_fields: [ 'agent', 'lead', 'contact' ],
                    ...formikBag.values
                  }}
                  getClass={tbl => {
                    this.older_table = tbl
                  }}
                  parser={data => this.parseData(data, false)}
                  currency={currency}
                  rowActions={(row, data) => {
                    let can_view_lead = false
                    if (data.lead && data.meta.lead && data.meta.lead.agent === user.agent.id) { // profile has lead and lead belongs to current agent
                      can_view_lead = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_lead = true
                    } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
                      can_view_lead = true
                    }
                    let can_view_contact = false
                    if (data.contact && data.meta.contact && data.meta.contact.introduction_agent === user.agent.id) {
                      can_view_contact = true
                    } else if (user.permissions.includes('is_prop_data_user')) {
                      can_view_contact = true
                    } else if (
                      [ 'residential', 'commercial' ].includes(model.model) &&
                      hasPermission([ 'contacts_view' ], user.permissions) &&
                      data.contact &&
                      data.meta.contact &&
                      (user.agent.branches.includes(data.meta.contact.branch) || user.permissions.includes('apply_to_all_branches'))
                    ) {
                      can_view_contact = true
                    }
                    let request_referral = false
                    if (settings.referral_match_requests && !can_view_contact && addons.includes('referrals_addon') && user.permissions.includes('referrals_add')) {
                      request_referral = true
                    }
                    const contact_number = this.getContactNumber(data)
                    return (
                      <>
                        <Button
                          icon="#glyph-Flag"
                          className={classNames('btn', 'btn-icon-only', 'btn-none', 'btn-icon-16', 'btn-glyph', 'btn-small')}
                          title="Highlight Match"
                          type="button"
                          onClick={() => {
                            new Promise((resolve, reject) => actions.highlightMatch({
                              match: data,
                              resolve,
                              reject
                            })).then(r => {
                              data.highlight = r.id
                              let { data: highlightdata } = this.highlight_table.state
                              highlightdata.push(data)
                              highlightdata = uniqueArray(highlightdata, 'id')
                              let { data: olderdata } = this.older_table.state
                              olderdata = olderdata.filter(l => l.id !== data.id)
                              this.older_table.setState({ data: olderdata })
                              this.highlight_table.setState({ data: highlightdata })
                            }).catch(e => console.error(e))
                          }}
                        />
                        {(!data.alert_sent && data.can_send && !request_referral) ? (
                          <Button
                            className="btn btn-red btn-small"
                            title="Send Alert"
                            type="button"
                            onClick={e => this.alertAgentPropertyLead(
                              e,
                              data,
                              data.contact,
                              model.id,
                              model.model
                            )}
                          >
                            Send Alert
                          </Button>
                        ) : null}
                        {request_referral ? (
                          <Button
                            className="btn btn-blue btn-small"
                            title="Request Referral"
                            type="button"
                            onClick={e => this.notifyReferralRequest(
                              e,
                              data.contact
                            )}
                          >
                            Request Referral
                          </Button>
                        ) : null}
                        {can_view_lead && contact_number && data.can_send && (enable_popia ? getIn(data, 'meta.contact.meta.consent.consent_messages') : true) &&
                          <WhatsAppButton
                            component={Button}
                            type="button"
                            resetButtonStyle={false}
                            className="btn btn-green btn-small"
                            separator=" : "
                            url={model.meta?.url.website}
                            phone={contact_number}
                            title={`Property Alert - ${model.web_ref}`}
                          >
                            Send WhatsApp
                          </WhatsAppButton>
                        }
                      </>
                    )
                  }}
                />
              }
            />
          </div>
        )}
      </Formik>
    )
  }
}

Matches.propTypes = {
  currency: PropTypes.string,
  model: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  actions: PropTypes.object,
  addons: PropTypes.array,
  settings: PropTypes.object,
  cache: PropTypes.object,
  recent_match_count: PropTypes.number,
  older_match_count: PropTypes.number,
  loading_matches: PropTypes.bool,
  unhighlightMatch: PropTypes.func,
  highlightMatch: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  fetchProfileMatches: PropTypes.func,
  fetchRecentMatches: PropTypes.func,
  fetchOldMatches: PropTypes.func,
  fetchHighlights: PropTypes.func,
  fetchMatches: PropTypes.func
}

export default Matches

// Matches.whyDidYouRender = true
