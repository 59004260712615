import PropTypes from 'prop-types'
import React from 'react'

import VersionDiff from './VersionDiff'
import VersionIndex from './VersionIndex'


class VersionList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.model) {
      return null
    }
    return (
      <div className="version-list">
        <VersionIndex
          {...this.props}
          visible
        />
        <VersionDiff
          {...this.props}
        />
      </div>
    )
  }
}


export default VersionList

VersionList.propTypes = {
  model: PropTypes.object
}
