/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-process-env */
/* eslint-disable consistent-return */
import { getIn } from 'formik'
import TransportStream from 'winston-transport'
import { createLogger } from 'winston'
import merge from 'deepmerge'
import 'setimmediate'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import packageinfo from '../package.json'
// The import below is controversial for many reasons:
// 1. It seems to create a memory leak in the app, although this is yet to be proven
// 2. The author is a convicted murder living in Russia
// 3. The core-js library adds support for legacy browsers, which we specifically do not support
// import 'core-js/actual'

import { isEmpty } from './utils'


const errorHandler = err => {
  console.error(err)
}

export class SentryTransport extends TransportStream {
  constructor(opts) {
    super(opts)
    this.name = 'winston-sentry'
    this.tags = {}
    const options = merge({
      errorHandler,
      config: {
        dsn: process.env.SENTRY_DSN || '',
        logger: 'winston',
        captureUnhandledRejections: false
      },
      isClientInitialized: false,
      level: 'info',
      levelsMap: {
        silly: 'debug',
        verbose: 'debug',
        info: 'info',
        debug: 'debug',
        warn: 'warning',
        error: 'error'
      },
      name: 'winston-sentry',
      silent: false
    }, opts)

    options.config.integrations = [ new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }) ]

    this.level = options.level
    this.levelsMap = options.levelsMap

    if (options.tags) {
      this.tags = options.tags
    } else if (options.globalTags) {
      this.tags = options.globalTags
    } else if (options.config.tags) {
      this.tags = options.config.tags
    }

    if (options.extra) {
      options.config.extra = options.config.extra || {}
      options.config.extra = merge(options.config.extra, options.extra)
    }

    this.sentryClient = options.sentryClient

    if (!options.isClientInitialized) {
      this.sentryClient = this.sentryClient || Sentry

      this.sentryClient.init(options.config || {
        dsn: process.env.SENTRY_DSN || ''
      })
    }

    if (this.sentryClient) {
      this.sentryClient.configureScope(scope => {
        if (!Object.keys(this.tags).length) {
          Object.keys(this.tags).forEach(key => {
            scope.setTag(key, this.tags[key])
          })
        }
      })
    }
  }

  log (info, callback) {
    if (!this.sentryClient.getCurrentHub().getClient()) {
      // sentry isn't ready, don't log
      return null
    }
    const { level: infolevel, message, label, fingerprint, ...meta } = info
    const level = Object.keys(this.levelsMap).find(key => info.level.toString().includes(key))
    if (!level) {
      return callback(null, true)
    }

    setImmediate(() => {
      this.emit('logged', level)
    })

    if (this.silent) {
      return callback(null, true)
    }

    const context = {}
    context.level = this.levelsMap[level]
    const { user, tags, ...extra } = meta
    context.extra = extra
    context.fingerprint = [ fingerprint, process.env.NODE_ENV ]
    this.sentryClient.withScope(scope => {
      if (context.extra) {
        Object.keys(context.extra).forEach(key => {
          if (key === 'agent') { return }
          scope.setExtra(key, context.extra[key])
        })
      }

      if (tags && !isEmpty(tags)) {
        Object.keys(tags).forEach(key => {
          scope.setTag(key, tags[key])
        })
      }

      if (context.level === 'error' || context.level === 'fatal') {
        let err = null
        if (info instanceof Error) {
          err = info
        } else {
          err = new Error(message)
          if (info.stack) {
            err.stack = info.stack
          }
        }
        this.sentryClient.captureException(err)
        return callback(null, true)
      }
      this.sentryClient.captureMessage(message, context.level)
      return callback(null, true)
    })
  }
}

class BrowserConsole extends TransportStream {
  constructor(opts) {
    super(opts)
    const options = merge(opts || {}, {
      errorHandler,
      level: 'debug',
      levelsMap: {
        silly: 'debug',
        verbose: 'debug',
        info: 'info',
        debug: 'log',
        warn: 'warn',
        error: 'error'
      },
      name: 'console',
      silent: false
    })
    this.level = options.level
    this.errorHandler = options.errorHandler
    this.levelsMap = options.levelsMap
    this.name = options.name
    this.silent = options.silent
  }

  log(info, callback) {
    // (window as any).l = info;
    setImmediate(() => {
      this.emit('logged', info)
    })

    const { message, level } = info
    const mappedMethod = this.levelsMap[level]

    if (Object.getOwnPropertySymbols(info).length === 2) {
      console[mappedMethod](message)
    } else {
      let args = info[Object.getOwnPropertySymbols(info)[1]]
      args = args.length >= 1 ? args[0] : args
      if (args) {
        console[mappedMethod](message, args)
      } else {
        console[mappedMethod](message)
      }
    }

    callback()
  }
}

let config = {
  dsn: '',
  normalizeDepth: 3,
  release: packageinfo.version,
  tracesSampleRate: 1.0,
  beforeBreadcrumb: breadcrumb => (breadcrumb.category === 'console' ? null : breadcrumb)
}
if (process.env.REACT_APP_ENV === 'staging') {
  config = {
    dsn: 'https://accec10948e344e6b017117761775535@staging.sentry.propdata.net/2',
    normalizeDepth: 3,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: packageinfo.version,
    environment: 'staging',
    integrations: [ new Sentry.Replay() ],
    beforeBreadcrumb: breadcrumb => (breadcrumb.category === 'console' ? null : breadcrumb)
  }
} else if (process.env.NODE_ENV === 'production') {
  config = {
    dsn: 'https://98eaa157c10241a09c8a93148baa0e66@production.sentry.propdata.net/19',
    normalizeDepth: 3,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
    release: packageinfo.version,
    environment: 'production',
    integrations: [ new Sentry.Replay() ],
    beforeBreadcrumb: breadcrumb => (breadcrumb.category === 'console' ? null : breadcrumb)
  }
}

const logger = createLogger()

logger.add(new BrowserConsole({
  handleExceptions: true,
  level: process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV !== 'e2e' && process.env.REACT_APP_TEST !== 'unit' ? 'error' : 'debug'
}))

logger.add(new SentryTransport({
  handleExceptions: true,
  config
}))

logger.on('finish', () => {})
logger.on('end', () => {})
logger.on('error', () => {})
logger.on('data', () => {})
export default logger
