/* eslint-disable max-len */
import React from 'react'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'

import { parseURL, isConditional, hasPermission, hasAddons, breakpoint } from '../../utils'
import * as validate from '../../validate'
import { Button } from '../ui/Button'
import FieldGroup from './forms/FieldGroup'
import ModelActions from './ModelActions'
import CustomForm from './forms/CustomForm'
import ChallengeModal from './modals/ChallengeModal'


class ModelBulkEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      required: false,
      collapsed: false,
      redirect: false,
      confirm: false,
      showActions: breakpoint.matches,
      offset: 0
    }
    this.isConditional = isConditional.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.checkModal = this.checkModal.bind(this)
    this.fieldClasses = this.fieldClasses.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleActions)
    const { user, selected } = this.props
    if (!selected.length || !this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.list.path, { site: user.agent.site.id })
      this.props.actions.registerRedirect(redirect)
    }
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + tab_el.getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  componentDidUpdate() {
    const { user, selected } = this.props
    if (!selected.length || !this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.list.path, { site: user.agent.site.id })
      this.props.actions.registerRedirect(redirect)
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  hasPermission() {
    const { config, user, cache, addons, selected } = this.props
    if (!selected.length) { return false }
    const models = selected.map(select => cache[config.modelname][select])
    const agent_id = user.agent.id
    if (config.addons) { return hasAddons(config.addons, addons) } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.edit.permissions
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_update_own'))
    const hasEditPermissions = requiredPermissions.filter(perm => perm.endsWith('_update'))
    if (hasPermission(hasEditOwnPermissions, user.permissions)) {
      const all_perms = models.every(model => {
        switch (config.modelname) {
          case 'branches':
            break
          case 'residential':
          case 'commercial':
          case 'project':
          case 'holiday':
            if (![ model.agent, model.agent_2, model.agent_3, model.agent_4 ].every(agent => agent === agent_id)) {
              return false
            }
            return true
          case 'groups':
            break
          case 'agents':
            if (!hasPermission([ 'users_update' ], user.permissions) && !hasPermission([ 'users_update_own' ], user.permissions)) {
              return false
            }
            return true
          case 'leads':
            if (model.agent !== agent_id) { // Agent doesn't match
              if (hasPermission([ 'leads_contacts_associated_agents_update' ], user.permissions)) { // User is allowed to view associated contacts
                return model.meta.contact.associated_agents && !model.meta.contact.associated_agents.includes(agent_id)
              } else if (model.meta.contact && model.meta.contact.introduction_agent !== agent_id) {
                return false
              }
            }
            return true
          case 'contacts':
            if (hasPermission([ 'contacts_associated_agents_update' ], this.props.user.permissions) && model.associated_agents && model.associated_agents.includes(agent_id)) { // User is allowed to view associated contacts
              return true
            } else if (model.introduction_agent && model.introduction_agent !== agent_id) {
              return false
            }
            return true
          case 'subscribers':
            if (hasPermission([ 'mailing_list_contacts_associated_agents_update' ], user.permissions)) { // User is allowed to view associated contacts
              return model.meta.contact.associated_agents && !model.meta.contact.associated_agents.includes(agent_id)
            } else if (model.meta.contact && model.meta.contact.introduction_agent !== agent_id) {
              return false
            }
            return true
          case 'profiles':
            if (hasPermission([ 'profiles_contacts_associated_agents_update' ], user.permissions)) { // User is allowed to view associated contacts
              return model.meta.contact.associated_agents && !model.meta.contact.associated_agents.includes(agent_id)
            } else if (model.meta.contact && model.meta.contact.introduction_agent !== agent_id) {
              return false
            }
            return true
          default:
            return true
        }
        return true
      })
      return all_perms
    }
    if (hasPermission(hasEditPermissions, user.permissions)) { return true }
    return false
  }


  redirectSchema(schema) { this.setState({ redirect: schema }) }

  toggleCollapsed(e) {
    e.preventDefault()
    this.setState({ collapsed: !this.state.collapsed })
  }

  fieldClasses(field, errors) {
    let classes = 'field'
    if (field.name in errors) { classes += ' error' }
    return classes
  }

  checkModal() {
    if (!this.state.confirmed) {
      this.setState({ confirm: true })
    }
  }

  handleSubmit(values, actions) {
    Object.keys(values).forEach(k => {
      if (Array.isArray(values[k]) && k !== 'record_ids' && !this.props.config.fields.find(f => f.name === k).multi) {
        values[k] = values[k][0] // Arrays passed in will be mutated to objects if not configed as multi
      }
      const field = this.props.config.fields.find(f => f.name === k)
      if (field && field.input === 'Float') { // Convert floats to strings
        if (field && field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }
      }
      if ([ null ].includes(values[k])) {
        delete values[k]
      }
    })
    return new Promise((resolve, reject) => {
      this.props.actions.bulkEditModel({ values, resolve, reject })
    }).then(() => {
      this.props.actions.notifyUser({ title: 'Success!', body: `The ${this.props.config.plural} were updated.`, type: 'success' })
      const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
      this.props.actions.registerRedirect({ pathname: `/secure/${this.props.settings.id}/${this.props.config.modelname}/`, search })
    }).catch(() => {
      actions.setSubmitting(false)
      actions.setStatus({ type: 'error', msg: 'Error' })
    })
  }

  render() {
    const { config, ui } = this.props
    if (!this.props.selected) { return null }
    return (

      <Formik
        initialValues={{
          record_ids: this.props.selected,
          endpoint: this.props.config.endpoint,
          modelname: this.props.config.modelname
        }}
        validationSchema={validate[`${this.props.config.modelname}_bulkedit`]}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
      >{ formik => {
          this.form = formik
          return (
            <CustomForm
              ui={this.props.ui}
              cache={this.props.cache}
              handleSubmit={this.checkModal}
              id={this.props.id || 'content'}
              className={this.props.className}
              render={ () => (
                <>
                  <div className="viewhead details">
                    <ModelActions
                      touched={formik.touched}
                      errors={formik.errors}
                      isSubmitting={formik.isSubmitting}
                      redirectSchema={this.redirectSchema}
                      bulkedit={true}
                      form={formik}
                      actions={{
                        toggleRequired: this.toggleRequired,
                        toggleCollapsed: this.toggleCollapsed
                      }}
                      statusmsg={formik.status ? formik.status.msg : false}
                      modelname={this.props.config.modelname}
                    >
                      {this.state.showActions && (
                        <>
                          <div className="card-legend">
                            {config.legend &&
                            config.legend.map((legend, ind) => (<legend key={`leg-${ind}`} className={legend.className}>{legend.label}</legend>))
                            }
                          </div>
                          <span className="cardtoggle-required" >
                            <Button type="button" className="btn btn-none" onClick={this.toggleRequired}>
                              {this.state.required ? 'Show all fields' : 'Show required fields' }
                            </Button>
                            <Button type="button" className="btn btn-none" onClick={this.toggleCollapsed}>
                              {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                            </Button>
                          </span>
                        </>
                      )}
                    </ModelActions>
                  </div>
                  <div className="view model bulkedit">
                    <div className="viewcontent">
                      <div className='container-fluid'>
                        { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                          <FieldGroup
                            key={`fg-${gidx}`}
                            groupname={group}
                            group={this.props.config.fieldgroups[group]}
                            gidx={gidx}
                            classes={this.props.config.fieldgroups[group].classes}
                            fields={this.props.config.fields.filter(field => field.group === group && field.bulkedit === true).map(field => {
                              field.edit = true
                              if (field.input === 'Check') { // Cannot use checkboxes for bulk edit
                                field.input = 'Select'
                                field.format = 'choice'
                                field.options = [
                                  {
                                    value: 1,
                                    label: 'Yes'
                                  },
                                  {
                                    value: 0,
                                    label: 'No'
                                  }
                                ]
                              }
                              return field
                            })}
                            form={formik}
                            match={this.props.match}
                            required={false} // No required fields in bulk edit
                            bulkedit={true} // No required fields in bulk edit
                            collapsed={this.state.collapsed}
                          />
                        ))}
                      </div>
                    </div>
                    <ChallengeModal
                      handleSubmit={this.handleSubmit}
                      isLoading={ui?.isLoading}
                      action={'updateModels'}
                      challenges={[
                        {
                          text: (
                            <span>
                              You&apos;re about to update <span> {formik.values.record_ids.length.toString()} </span> {config.plural} that match the current filters. Please confirm your action by typing the number of {config.plural} you are about to edit in the field below.
                            </span>
                          ),
                          value: () => formik.values.record_ids.length.toString(),
                          action: 'updateModels'
                        },
                        {
                          text: (
                            <span>
                              You&apos;re about to update <span> {formik.values.record_ids.length.toString()} </span> {config.plural} that match the current filters. Please confirm your action by typing <code>UPDATE</code> in the field below.
                            </span>
                          ),
                          value: () => 'UPDATE',
                          action: 'updateModels'
                        }
                      ]}
                      visible={this.state.confirm}
                      buttons={(({ valid, value }) => (
                        <>
                          <Button className="btn btn-primary" type="submit" onClick={() => {
                            formik.setFieldTouched('challenge', true, false)
                            if (valid) {
                              this.setState({
                                confirmed: true,
                                confirm: false
                              }, () => {
                                this.handleSubmit(formik.values, formik)
                              })
                            } else {
                              formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                            }
                          }}>Confirm</Button>
                          <Button className="btn btn-none" type="submit" onClick={() => {
                            this.setState({ confirm: false }, () => { formik.setFieldError('challenge', null) })
                          }}>Cancel</Button>
                        </>
                      ))}
                    />
                  </div>
                </>
              )}
            />
          )
        }}
      </Formik>
    )
  }
}

ModelBulkEdit.propTypes = {
  model: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  selected: PropTypes.array,
  config: PropTypes.object,
  configs: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  cache: PropTypes.object,
  settings: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  match: PropTypes.object,
  ui: PropTypes.object,
  routeConfig: PropTypes.object,
  actions: PropTypes.object
}


export default ModelBulkEdit
