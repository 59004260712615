/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import * as sellerFeedback from '../config/seller-feedback.json'
import * as signatureConfig from '../config/signature.json'
import { CACHEDMODELID, CACHE, CONFIGS, ADDONS, GLOBALPORTALS, PORTALS, SELECTED, CONFIG, MINUSER } from '../selectors'
import { fetchOne, fetchMany, registerRedirect, fetchTemplateConfig, updateTemplatePreview, selectNone, updateModel, exportData, createModel, notifyUser, emailTemplate } from '../actions'
import HTMLPreview from '../components/HTMLPreview'
import { getSearchParam } from '../utils'


const mapStateToProps = (state, ownProps) => {
  const configs = CONFIGS(state)
  const model = CACHEDMODELID(state, ownProps.match.params.model, ownProps.match.params.id)
  const cache = CACHE(state)
  const globalportals = GLOBALPORTALS(state)
  const portals = PORTALS(state)
  const selected = SELECTED(state, ownProps.match.params.model)
  let config = CONFIG(state, ownProps.match.params.model)
  const user = MINUSER(state)
  const addons = ADDONS(state)

  let sourcemodel

  if ([ 'seller-feedback', 'landlord-feedback' ].includes(ownProps.match.params.action)) {
    config = sellerFeedback.default.config
  }

  if ([ 'signature' ].includes(ownProps.match.params.action)) {
    config = signatureConfig.default.config
  }

  const minuser = Map({
    agent: user.get('agent'),
    selected: user.get('selected'),
    permissions: user.get('permissions')
  })

  let mincache = Map({
    settings: cache.get('settings')
  })

  if ([ 'residential', 'commercial', 'holiday', 'project' ].some(m => ownProps.match.params.model.includes(m))) {
    mincache = mincache.set('teams', cache.get('teams'))
  } else if (ownProps.match.params.model.includes('pages')) { // URL params - adding based on another model ie. create listing from sell referral
    mincache = mincache.set('domains', cache.get('domains'))
  } else if (ownProps.match.params.model.includes('newsletter-templates')) { // URL params - adding based on another model ie. create listing from sell referral
    mincache = mincache.set('newsletter-templates', cache.get('newsletter-templates'))
  } else if (ownProps.location.search) { // URL params - adding based on another model ie. create listing from sell referral
    const sourcemodelname = getSearchParam('model')
    const sourcemodelid = getSearchParam('id')
    sourcemodel = CACHEDMODELID(state, sourcemodelname, sourcemodelid)
    if (sourcemodel) { sourcemodel = sourcemodel.set('model', sourcemodelname) }
  }

  return {
    modelname: ownProps.match.params.model,
    modelid: ownProps.match.params.id,
    match: ownProps.match,
    actions: ownProps.actions,
    model,
    config,
    configs,
    cache: mincache,
    selected,
    user: minuser,
    portals,
    globalportals,
    addons,
    sourcemodel
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    fetchOne,
    fetchMany,
    registerRedirect,
    fetchTemplateConfig,
    exportData,
    emailTemplate,
    notifyUser,
    selectNone,
    updateModel,
    createModel,
    updateTemplatePreview
  }, dispatch) })

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, location, className } = ownProps
  return ({ ...stateProps, routeConfig, location, className })
}

export default connect(mapStateToProps, mapDispachToProps, mergeProps)(withImmutablePropsToJS(HTMLPreview))
