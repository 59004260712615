/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useCustomCompareMemo } from '../../../utils'


export const formikUseField = WrappedComponent => props => {
  const [ field ] = useField(props)
  const formik = useFormikContext()

  const [ {
    form: formprops,
    field: fieldprops,
    props: rest
  }, setFormikProps ] = useState({ form: formik, field, props })
  useEffect(() => {
    setFormikProps({ form: formik, field, props })
    return () => {}
  }, [ useCustomCompareMemo(formik), useCustomCompareMemo(field), useCustomCompareMemo(props) ])

  return (
    <WrappedComponent
      {...rest}
      form={formprops}
      field={fieldprops}
    />
  )
}

