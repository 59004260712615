/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, fetchDeeds } from '../actions'
import { CONFIG } from '../selectors'
import DeedsLookupSidebar from '../components/DeedsLookupSidebar'


function mapStateToProps(state, ownProps) {
  return {
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache'),
    config: CONFIG(state, ownProps.deeds),
    siblingform: ownProps.siblingform,
    field: ownProps.field
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    fetchDeeds
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(DeedsLookupSidebar))
