/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { AUTH, REDIRECT, PERMISSIONS, AGENT, SESSION_EXPIRES, SETTINGS } from '../selectors'
import {
  selectNone,
  reToke,
  doLogout,
  renewToken,
  registerRedirect,
  unregisterRedirect,
  closeNav,
  closeMenu
} from '../actions'

import PrivateRoute from '../components/PrivateRoute'


const mapStateToProps = state => (
  {
    auth: AUTH(state),
    redirect: REDIRECT(state),
    settings: SETTINGS(state),
    expires: SESSION_EXPIRES(state),
    permissions: PERMISSIONS(state),
    agent: AGENT(state)
  }
)

const mapDispatchToProps = dispatch => bindActionCreators({
  selectNone,
  reToke,
  renewToken,
  doLogout,
  registerRedirect,
  unregisterRedirect,
  closeNav,
  closeMenu
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(PrivateRoute))
