// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogActions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
}

.DialogButtonsList {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 20px;
}

.DialogButtonsList button {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/forms/inputs/lexical/ui/Dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".DialogActions {\n  display: flex;\n  flex-direction: row;\n  justify-content: right;\n  margin-top: 20px;\n}\n\n.DialogButtonsList {\n  display: flex;\n  flex-direction: column;\n  justify-content: right;\n  margin-top: 20px;\n}\n\n.DialogButtonsList button {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
