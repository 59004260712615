import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  defaultAnimateLayoutChanges
} from '@dnd-kit/sortable'

import {
  flattenTree,
  removeChildrenOf,
  setProperty
} from './utils'
import SortableTreeItem from './SortableTreeItem'


const animateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

export default function SortableTree({
  collapsible,
  items: defaultItems,
  id: treeId,
  activeId,
  projected,
  indicator = false,
  indentationWidth = 50,
  removable,
  onRemove
}) {
  const [ items, setItems ] = useState(() => defaultItems)

  useEffect(() => {
    setItems(defaultItems)
  }, [ defaultItems ])
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) =>
        (collapsed && children.length ? [ ...acc, id ] : acc),
      []
    )

    return removeChildrenOf(
      flattenedTree,
      activeId ? [ activeId, ...collapsedItems ] : collapsedItems
    )
  }, [ activeId, items, defaultItems ])

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems
  ])

  function handleRemove(id) {
    onRemove(id)
  }

  function handleCollapse(id) {
    setItems(list =>
      setProperty(list, id, 'collapsed', value => !value)
    )
  }

  const { setNodeRef } = useSortable({
    id: treeId,
    data: {
      type: 'container',
      children: flattenedItems
    },
    animateLayoutChanges
  })
  return (
    <ul ref={setNodeRef}>
      <SortableContext id={treeId} items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(item => (
          <SortableTreeItem
            key={item.id}
            id={item.id}
            item={item}
            depth={item.id === activeId && projected ? projected.depth : item.depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(item.collapsed && item.children.length)}
            onCollapse={
              collapsible && item.children.length
                ? () => handleCollapse(item.id)
                : undefined
            }
            onRemove={removable ? () => handleRemove(item.id) : undefined}
          />
        ))}
      </SortableContext>
    </ul>
  )
}

SortableTree.propTypes = {
  collapsible: PropTypes.bool,
  items: PropTypes.array,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  activeId: PropTypes.string,
  projected: PropTypes.string,
  indicator: PropTypes.bool,
  indentationWidth: PropTypes.string,
  removable: PropTypes.bool,
  onRemove: PropTypes.func
}
