import React from 'react'
// import { getIn } from 'formik'
import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
// import isEqual from 'react-fast-compare'
import FieldComponent from '../FieldComponent'
import { Button } from '../../../ui/Button'
import { isConditional } from '../../../../utils'


class FieldListInput extends React.Component {
  // Single text field array input
  constructor(props) {
    super(props)
    this.form = props.form
    this.isConditional = isConditional.bind(this)
    this.fieldClasses = this.fieldClasses.bind(this)
    this.renderArrayFields = this.renderArrayFields.bind(this)
    this.state = {}
  }

  componentDidMount() {
    const { value, name } = this.props.field
    if (this.props.form.values && (!value || (Array.isArray(value) && !value.length))) {
      this.props.form.setFieldValue(name, [ '' ])
    }
  }


  fieldClasses(field, errors) {
    let classes = 'field'
    const required = field._required === 'true'
    if (required) {
      classes += ' required'
    }
    if (field.name in errors) { classes += ' error' }
    if (field.cols) { classes += ` col-${field.cols}`}
    return classes
  }


  renderArrayFields(idx) { // Ensure that there is at least a initial array
    const { errors } = this.form
    const { inner } = this.props
    const field = inner ? {
      ...inner,
      _value: this.props.field.value[idx],
      name: `${this.props.field.name}.${idx}`,
      label: idx === 0 ? inner.label : `${inner.label} ${idx + 1}`
    } : {
      _value: this.props.field.value[idx],
      name: `${this.props.field.name}.${idx}`,
      label: idx === 0 ? this.props.label : `${this.props.label} ${idx + 1}`,
      cols: 'lg-12'
    }
    return React.createElement('div', {
      key: `field-${this.props.field.name}-${idx}`,
      className: this.fieldClasses(field, errors)
    }, React.createElement(FieldComponent, {
      ...this.props,
      key: `fc-${this.props.field.name}-${idx}`,
      field,
      errors })
    )
  }


  render() {
    const { name, value } = this.props.field
    const { _required, allowdelete, controls } = this.props
    return (
      <FieldArray
        name={name}
        render={({ insert, remove }) => (
          <div id={this.props.id} className="fieldlist">
            <fieldset className="input-group">
              {value && Array.isArray(value) && value.map((f, idx) => (
                <React.Fragment key={`fset-${idx}`}>
                  {this.renderArrayFields(idx)}
                  {!controls && ((value.length > 1 && idx !== 0) || (value.length === 1 && idx === 0)) ? (
                    <div className="arrayaction col-lg-12">
                      {idx === value.length - 1 ? (
                        <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                          insert(!Array.isArray(value) ? 0 : value.length + 1, '')
                        }}>Add{this.props.text_suffix ? ` ${this.props.text_suffix}` : ''}</Button>
                      ) : null}
                      {(_required === 'true' && value.length === 1 || (allowdelete === false && idx === 0)) ? null : (
                        <Button icon="#icon16-X-Large" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                          remove(idx)
                        }}>Remove{this.props.text_suffix ? ` ${this.props.text_suffix}` : ''}</Button>
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              )) }
              {controls ? (
                <div className="arrayaction col-lg-12">
                  <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                    insert(!Array.isArray(value) ? 0 : value.length + 1, '')
                  }}>Add{this.props.text_suffix ? ` ${this.props.text_suffix}` : ''}</Button>
                  {(_required === 'true' && value && value.length === 1) ? null : (
                    <Button icon="#icon16-X-Large" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                      remove(value.length - 1)
                    }}>Remove{this.props.text_suffix ? ` ${this.props.text_suffix}` : ''}</Button>
                  )}
                </div>
              ) : null}
            </fieldset>
          </div>
        ) }
      />
    )
  }
}

FieldListInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  text_suffix: PropTypes.string,
  cache: PropTypes.object,
  inner: PropTypes.object,
  user: PropTypes.object.isRequired,
  _required: PropTypes.string,
  allowdelete: PropTypes.bool,
  controls: PropTypes.bool,
  show_required: PropTypes.bool,
  label: PropTypes.string
}

export default FieldListInput
