/* eslint-disable new-cap */
import { fromJS } from 'immutable'

import config from '../config/config.json'


export default (newstate = fromJS(config.defaultState.models), action) => {
  switch (action.type) {
    case 'UNSELECT_AGENT':
      return fromJS(config.defaultState.models)

    case 'INDEX_MODEL_SEARCH':
      Object.keys(action.delta).forEach(model => {
        newstate = newstate.set(model, fromJS(action.delta[model]))
      })
      return newstate

    case 'DO_LOGOUT':
    case 'TOKE_ERROR': { // Remove token from store and return default state
      newstate = fromJS(config.defaultState.models)
      return newstate
    }

    default:
      return newstate
  }
}
