import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'
import { NavLink } from 'react-router-dom'
import { hasPermission, valueFormat } from '../../../utils'
import Card from '../Card'
import { Button } from '../../ui/Button'


const LeadDetails = props => {
  const { lead, user, model, modelname } = props
  let can_view_contact = false
  if (getIn(lead, 'agent') === user.agent.id) { // lead has agent and lead belongs to current agent
    can_view_contact = true
  } else if (user.permissions.includes('is_prop_data_user')) {
    can_view_contact = true
  } else if (hasPermission([ 'leads_view' ], user.permissions)) {
    can_view_contact = true
  } else if (hasPermission([ 'leads_view_own' ], user.permissions)) {
    can_view_contact = true
  } else if (hasPermission([ 'profiles_contacts_associated_agents_view' ], user.permissions) && (getIn(lead, 'meta.contact.associated_contacts').includes(user.agent.id) || getIn(lead, 'meta.contact.introduction_agent§') === user.agent.id)) {
    can_view_contact = true
  }

  return (
    <Card
      background={true}
      classes="primary-bg lead-interaction-details"
      bodyclass="no-top-padding"
      header={can_view_contact ? (
        <NavLink to={`/secure/${getIn(lead, 'site')}/contacts/${getIn(lead, 'contact')}`}><h3>{getIn(lead, 'meta.contact.first_name')} {getIn(lead, 'meta.contact.last_name')}</h3></NavLink>
      ) : (
        <h3>{getIn(lead, 'meta.contact.first_name')} {getIn(lead, 'meta.contact.last_name')}</h3>
      )}
      body={(
        <>
          <div>
            <strong>Email</strong>
            <div>
              {can_view_contact ? valueFormat('mailto', getIn(lead, 'meta.contact.email')) : 'You do not have permission to view this contact'}
            </div>
          </div>
          <div>
            <strong>Cell Number</strong>
            <div>
              {can_view_contact ? valueFormat('contact_whatsapp_link', getIn(lead, 'meta.contact'), { modelname, model, name: 'cell_number' }, model, modelname) || '-' : 'You do not have permission to view this contact'}
            </div>
          </div>
          <div>
            <strong>Subscribed to Alerts</strong>
            <div>
              {can_view_contact ? valueFormat('yesno', getIn(lead, 'meta.profile.receive_email_marketing', false) || false) : 'You do not have permission to view this contact'}
            </div>
          </div>
          <Button component={NavLink} to={`/secure/${getIn(lead, 'site')}/leads/${getIn(lead, 'id')}`} className="btn btn-grey">View Lead Record</Button>
        </>
      )}
    />
  )
}

LeadDetails.propTypes = {
  lead: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  modelname: PropTypes.string,
  togglePinNote: PropTypes.func
}

export default LeadDetails
