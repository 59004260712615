import PropTypes from 'prop-types'
import React from 'react'


const HelpText = ({ type, content, props }) => React.createElement(type, props ? { ...props } : {}, content)

HelpText.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string,
  props: PropTypes.object
}

export default HelpText
