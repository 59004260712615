/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import isEqual from 'react-fast-compare'

import { NavLink } from 'react-router-dom'
import { fetchAgentStatistics, fetchBranchStatistics, fetchMany } from '../../actions'
import date_options from '../../config/date-options.json'
import { MINUSER } from '../../selectors'
import { hasPermission, textToDate, valueFormat, uniqueArray, sortBy } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import Loader from '../common/Loader'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const ActiveListingsWidget = props => {
  const abortController = useRef(new AbortController())
  const { actions, user, branch, agent, franchise } = props

  const option = date_options.find(o => o.value === 'LAST_30_DAYS')
  const { start, end } = textToDate(option.value)

  let curbranches
  if (user.agent && !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)) {
    curbranches = [ ...user.agent.branches ]
  }
  let initagent = agent ? agent.id : ''
  const allagents = [ { first_name: 'All', last_name: 'Agents', id: '' } ]
  if (user.agent.id && !agent) { // Dont default agent to logged in agent when viewing agent singleton
    if ((curbranches && branch && curbranches.includes(branch.id)) || (!branch && !franchise)) {
      initagent = user.agent.id
      allagents.push({ first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id })
    }
  }

  const [ agentid, setAgentId ] = useState(initagent)
  const [ agents, setAgents ] = useState(allagents)
  const [ change, setChange ] = useState(false)
  const [ count, setCount ] = useState(0)
  const [ current, setCurrent ] = useState(valueFormat('shortdate', start.toString()))
  const [ keys, setKeys ] = useState([])
  const [ allkeys, setAllKeys ] = useState([])
  const [ limit, setLimit ] = useState(valueFormat('shortdate', end.toString()))
  const [ listingoptions, setListingOptions ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ mounted, setMounted ] = useState(true)
  const [ negative, setNegative ] = useState(false)
  const [ period, setPeriod ] = useState(option.value)
  const [ selectedlistingtype, setSelectedListingType ] = useState('')
  const [ selectedmodelname, setSelectedModelName ] = useState('')
  const [ selectedlistingmodel, setSelectedListingModel ] = useState('')

  if (branch) { curbranches = [ branch.id ] }
  if (franchise) { curbranches = franchise.branches }

  const calculateStatistics = useCallback(r => {
    const total_current = keys.map(key => getIn(r.current, key, 0))
      .reduce((prevVal, currVal) => prevVal + currVal, 0)
    const total_previous = keys.map(key => getIn(r.previous, key, 0))
      .reduce((prevVal, currVal) => prevVal + currVal, 0)
    let new_change = total_current ? total_current / 100 : false
    if (total_previous) {
      new_change = ((total_current - total_previous) / total_previous) * 100
    }
    setCount(total_current)
    setChange(new_change)
    setNegative(total_current - total_previous < 0)
    setLoading(false)
  })

  const updateStatistics = useCallback(() => {
    if (!keys.length || (!current && !limit)) { return }
    const params = {
      start_date: current,
      end_date: limit,
      branch_id__in: curbranches,
      keys
    }
    if (agentid) {
      params.agent_id = agentid
      new Promise((resolve, reject) => actions.fetchAgentStatistics({
        period: 'daily',
        action: 'totals',
        signal: abortController.current.signal,
        params,
        resolve,
        reject
      })).then(r => {
        if (mounted) {
          calculateStatistics(r)
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    } else {
      new Promise((resolve, reject) => actions.fetchBranchStatistics({
        period: 'daily',
        action: 'totals',
        params,
        signal: abortController.current.signal,
        resolve,
        reject
      })).then(r => {
        if (mounted) {
          calculateStatistics(r)
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  })

  const calculatePerms = useCallback(() => {
    const new_keys = []
    const new_listingoptions = [ { label: 'All Listing Types', value: '' } ]
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('rfs_ac')
      new_listingoptions.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('rtl_ac')
      new_listingoptions.push({ value: 'residential_to_let', label: 'Residential To Let' })
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('cfs_ac')
      new_listingoptions.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('ctl_ac')
      new_listingoptions.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
    }
    if (hasPermission([
      'listings_projects_view',
      'listings_projects_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('rd_ac')
      new_keys.push('re_ac')
      new_keys.push('cd_ac')
      new_keys.push('ce_ac')
      new_listingoptions.push({ value: 'projects', label: 'Project Listings' })
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id)) {
      new_keys.push('h_ac')
      new_listingoptions.push({ value: 'holiday', label: 'Holiday Listings' })
    }
    return [ new_keys, new_listingoptions ]
  })

  useEffect(() => {
    const [ new_keys, new_listingoptions ] = calculatePerms()
    setKeys(new_keys)
    setAllKeys(new_keys)
    setListingOptions(new_listingoptions)
    return () => { // CWU
      setMounted(false)
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    let key
    switch (selectedmodelname) {
      case 'residential':
        key = selectedlistingtype === 'For Sale' ? 'rfs_ac' : 'rtl_ac'
        break
      case 'commercial':
        key = selectedlistingtype === 'For Sale' ? 'cfs_ac' : 'ctl_ac'
        break
      case 'projects':
        key = 'rd_ac,re_ac,cd_ac,ce_ac'
        break
      case 'holiday':
        key = 'h_ac'
        break
      default:
        key = ''
    }
    const new_keys = key ? key.split(',') : allkeys
    if (!isEqual(new_keys, keys)) { setKeys(new_keys) }
  }, [ selectedmodelname, selectedlistingtype ])

  useEffect(() => {
    updateStatistics()
  }, [ period, agentid, keys ])

  return (
    <Card
      id="active-listings-widget"
      classes={`primary-bg grid-col-1 grid-row-${branch || agent || franchise ? '2' : '1'}`}
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <strong>Active Listings</strong>
          <div className="percentage-change">
            {change ? valueFormat('percent_change', change, { negative }) : null}
          </div>
        </>
      }
      body={loading ? (
        <div className="empty flex-container" style={{ height: 70 }}><Loader inline className={'white'}/></div>
      ) : (
        <>
          <h1>{selectedmodelname ? <NavLink to={`/secure/${user.agent.site.id}/${selectedmodelname}/?status__in=Active${selectedlistingtype ? `&listing_type__in=${selectedlistingtype}` : ''}${agentid ? `&agents__in=${agentid}` : ''}${branch ? `&branch=${branch.id}` : ''}&order_by=-created`}>{count}</NavLink> : count}</h1>
          <div className="stats-filters">
            <InlineSelect
              id="listing_model"
              name="listing_model"
              className="inline-select"
              classNamePrefix="inline"
              options={listingoptions}
              defaultValue={{ label: 'All Listing Types', value: '' }}
              selectedValue={selectedlistingmodel}
              onChange={e => {
                let new_selectedmodelname = ''
                let new_selectedlistingtype = ''
                if ([ 'residential_to_let', 'residential_for_sale' ].includes(e.value)) { new_selectedmodelname = 'residential' }
                if ([ 'commercial_to_let', 'commercial_for_sale' ].includes(e.value)) {new_selectedmodelname = 'commercial'}
                if (e.value === 'holiday') {new_selectedmodelname = 'holiday'}
                if (e.value === 'projects') {new_selectedmodelname = 'projects'}
                if (e.value.indexOf('for_sale') !== -1) {new_selectedlistingtype = 'For Sale'} else {new_selectedlistingtype = 'To Let'}
                new_selectedlistingtype = new_selectedmodelname && [ 'commercial', 'residential' ].includes(new_selectedmodelname) ? new_selectedlistingtype : ''
                setSelectedModelName(new_selectedmodelname)
                setSelectedListingType(new_selectedlistingtype)
                setSelectedListingModel(e.value)
              }}
            />
            {!agent ? ( // Dont show agent selector on agent dashboard
              <AsyncInlineSelect
                id="agent_id"
                name="agent_id"
                className="inline-select"
                classNamePrefix="inline"
                defaultValue={user.agent.id ? { first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id } : { first_name: 'All', last_name: 'Agents', id: '' }}
                options={agents}
                form={{ values: { agent_id: agentid } }}
                modelname="agents"
                labelseparator=" "
                fetchMany={actions.fetchMany}
                optionlabel={[ 'first_name', 'last_name' ]}
                noclear
                params={{
                  branches__overlap: curbranches,
                  active: 1,
                  order_by: 'first_name,last_name',
                  fields: 'id,first_name,last_name'
                }}
                field={{ value: agentid || '', name: 'agent_id' }}
                onLoad={e => setAgents(sortBy(uniqueArray([ ...allagents, ...e ], 'id')), 'first_name')}
                onChange={e => { setAgentId(e.value) }}
              />
            ) : null }
            <InlineSelect
              id="period"
              name="period"
              className="inline-select"
              classNamePrefix="inline"
              defaultValue={date_options.find(o => o.value === 'LAST_30_DAYS')}
              selectedValue={period}
              options={date_options.filter(o => !o.value.includes('NEXT') && o.value !== 'TOMORROW' && o.value !== 'TODAY')}
              onChange={e => {
                // eslint-disable-next-line no-unused-vars
                const { start: new_start, end: new_end, days } = textToDate(e.value)
                const new_current = valueFormat('shortdate', new_start.toString())
                const new_limit = valueFormat('shortdate', new_end.toString())
                setPeriod(e.value)
                setCurrent(new_current)
                setLimit(new_limit)
              }}
              components={{ Option: CustomOption }}
            />
          </div>
        </>
      ) }
    />
  )
}

ActiveListingsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  branch: PropTypes.object,
  agent: PropTypes.object,
  franchise: PropTypes.object
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  return ({
    user
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, fetchBranchStatistics, fetchAgentStatistics }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ActiveListingsWidget))
