import React from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'
import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ContactSubscriptions = props => {
  const archiveModel = (modelname, id, callback = () => {}) => {
    const values = {
      modelname,
      id: id,
      status: 'Inactive'
    }
    return new Promise((resolve, reject) => {
      props.actions.updateModel({ values, resolve, reject })
    }).then(callback)
  }

  const { user, modelid, actions, configs } = props
  return (
    <Card
      bodyclass="subscriptions no-top-padding"
      background
      header={
        <h3>Subscriptions</h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.subscribers}
          action={actions.fetchSubscribers}
          params={{
            contact: modelid,
            order_by: '-created',
            meta_fields: [ 'agent', 'branch', 'contact' ]
          }}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Subscription Types',
              name: 'user_types',
              orderable: true
            },
            {
              label: 'Source',
              name: 'source',
              orderable: true
            },
            {
              label: 'Branch',
              name: 'meta.contact.meta.branch',
              order_by: 'contact__branch__name',
              orderable: true,
              modelname: 'branches',
              optionlabel: [ 'name' ],
              link: '/secure/:site/branches/:meta.contact.branch'
            },
            {
              label: 'Agent',
              name: 'agent',
              orderable: true,
              modelname: 'agents',
              optionlabel: [ 'first_name', 'last_name' ],
              link: '/secure/:site/agents/:agent'
            },
            {
              label: 'Status',
              name: 'status',
              orderable: true
            }
          ]}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/subscribers/${data.id}`} title="View Subscription" type="button" />
              {data.status !== 'Inactive' ? (
                <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => archiveModel('subscribers', data.id, () => {
                  data.status = 'Inactive'
                  row.forceUpdate()
                })} title="Archive Subscription" type="button" />
              ) : null}
            </>
          )}
        />
      }
    />
  )
}

ContactSubscriptions.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number
}

export default ContactSubscriptions
