import React from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import Card from '../../common/Card'
import CustomForm from '../forms/CustomForm'
import { logEvent } from '../../../utils'
import { formikConnect } from '../forms/customFormikConnect'


class SnippetModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snippets: [],
      searching: true,
      selected: false
    }
    this.searchSnippets = this.searchSnippets.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.keyPress = this.keyPress.bind(this)
    this.root = props.rootRef
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyPress)
    window.addEventListener('Escape', this.keyPress)
    if (!this.state.snippets.length) {
      new Promise((resolve, reject) => {
        const values = {
          modelname: 'snippets',
          params: {
            location: this.props.location,
            get_all: 1,
            meta_fields: [ 'area', 'suburb' ]
          }
        }
        this.props.fetchMany({ values, resolve, reject })
      }).then(r => {
        this.setState({ snippets: r.options, searching: false })
      })
    }
  }

  componentWillUnmount() {
    const { setSnippet } = this.props
    setSnippet(false)
    window.removeEventListener('keydown', this.keyPress)
    window.removeEventListener('Escape', this.keyPress)
  }

  searchSnippets() {
    const { onSelect, value, parentref, location, setSnippet } = this.props
    if (this.state.selected || this.state.selected === 0) {
      const s = this.state.snippets[this.state.selected]
      onSelect(value + s.content)
      setSnippet(false)
      parentref.current.focus()
      this.root?.unmount()
    } else {
      const { fetchMany } = this.props
      const values = {
        modelname: 'snippets',
        term: this.state.term,
        location: location,
        trigram: true,
        params: {
          location: this.props.location,
          meta_fields: [ 'area', 'suburb' ]
        }
      }
      new Promise((resolve, reject) => {
        fetchMany({ values, resolve, reject })
        this.setState({ searching: true })
      }).then(r => {
        this.setState({ searching: false, snippets: r.options, searched: !!this.state.term, selected: false })
      }).catch(() => {
        this.setState({ searching: false })
      })
      this.setState({ searching: false })
    }
  }

  updateInput(e) {
    this.setState({ term: e.target.value })
  }

  scrollParentToChild(parent, child) {
    const parentRect = parent.getBoundingClientRect()
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth
    }
    const childRect = child.getBoundingClientRect()
    const isViewable = (childRect.top >= parentRect.top) &&
      (childRect.bottom <= parentRect.top + parentViewableArea.height)
    if (!isViewable) {
      const scrollTop = childRect.top - parentRect.top
      const scrollBot = childRect.bottom - parentRect.bottom
      if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        parent.scrollTop += scrollTop
      } else {
        parent.scrollTop += scrollBot
      }
    }
  }

  keyPress(e) {
    const { parentref, setSnippet } = this.props
    if (this.el && this.el.current) { this.el.current.blur() }
    const parentel = document.getElementsByClassName('snippet-card')[0]
    if (e.key === 'ArrowDown') {
      if (this.state.snippets.length) {
        if (this.state.selected || this.state.selected === 0) {
          if (this.state.selected !== this.state.snippets.length - 1) {
            const selected = this.state.selected + 1
            this.setState({ selected })
            const childel = document.getElementsByClassName('snippet-option')[selected]
            this.scrollParentToChild(parentel, childel)
          }
        } else {
          this.setState({ selected: 0 })
          const childel = document.getElementsByClassName('snippet-option')[0]
          this.scrollParentToChild(parentel, childel)
        }
      }
    }
    if (e.key === 'ArrowUp') {
      if (this.state.snippets.length) {
        if (this.state.selected && this.state.selected !== 0) {
          const selected = this.state.selected - 1
          this.setState({ selected })
          const childel = document.getElementsByClassName('snippet-option')[selected]
          this.scrollParentToChild(parentel, childel)
        } else if (this.state.selected === 0) {
          const childel = document.getElementById('snippet-search')
          this.scrollParentToChild(parentel, childel)
          this.setState({ selected: false })
        }
      }
    }
    // This does not work cause Formik is dumb, so added it to the searchSnippets submit method above
    // if (e.key === 'Enter' && (this.state.selected || this.state.selected === 0)) {
    //   e.preventDefault()
    //   e.stopPropagation()
    //   const s = this.state.snippets[this.state.selected]
    //   onSelect(value + s.content)
    //   ReactDOM.unmountComponentAtNode(document.getElementById(`input-${id}-snippet-modal`))
    // }
    if (e.key === 'Escape') {
      setSnippet(false)
      this.root?.unmount()
      parentref.current.focus()
    }
  }

  render() {
    const { value, onSelect, editor, parentref, setSnippet, cursorpos } = this.props
    return (
      <div className="snippet-container">
        <Card
          background={true}
          showtoggles={false}
          classes="snippet-card"
          header={
            <Formik
              initialValues={{}}
              enableReinitialize={true}
              onSubmit={this.searchSnippets}
            >{() => (
                <CustomForm
                  component="div"
                  render={() => (
                    <input
                      id="snippet-search"
                      ref={el => { this.el = el }}
                      onClick={ () => this.setState({ selected: false })}
                      type="search"
                      autoFocus
                      autoComplete='off'
                      placeholder="Search for a snippet"
                      className="form-control input-group-suffix"
                      onChange={this.updateInput}
                      value={this.state.term}
                    />
                  )}
                />
              )}
            </Formik>
          }
          body={this.state.snippets.length ? this.state.snippets.map((s, i) =>
            <div
              title={s.name}
              onClick={event => {
                event.preventDefault()
                logEvent('SELECTED_SNIPPET', { id: s.id })
                if (!editor) {
                  if (parentref.current.selectionStart === parentref.current.selectionEnd) { // No selection
                    const prefix = value.substring(0, cursorpos)
                    const suffix = value.substring(cursorpos, value.length)
                    onSelect(prefix + s.content + suffix)
                  } else {
                    const prefix = value.substring(0, parentref.current.selectionStart)
                    const suffix = value.substring(parentref.current.selectionEnd, value.length)
                    onSelect(prefix + s.content + suffix)
                  }
                  setSnippet(false)
                  parentref.current.focus()
                  this.root?.unmount()
                } else {
                  onSelect(s.content)
                  this.root?.unmount()
                }
              }}
              key={`snippet-${s.id}`}
              className={`snippet-option${(this.state.selected || this.state.selected === 0) && this.state.selected === i ? ' selected' : ''}`}>{s.name}</div>
          ) : <div className="snippet-option">No snippets found</div>
          }
        />
      </div>
    )
  }
}

SnippetModal.propTypes = {
  name: PropTypes.string,
  setSnippet: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  fetchMany: PropTypes.func.isRequired,
  cache: PropTypes.object,
  position: PropTypes.number,
  location: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  rootRef: PropTypes.any,
  parentref: PropTypes.any,
  editor: PropTypes.bool,
  cursorpos: PropTypes.number
}

export default formikConnect(SnippetModal)
