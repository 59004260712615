/* PDMS UI v2.
 * Created by Julian Rachman and Matthew Faircliff circa 2018 / 2019.
 * All copyrights belong to Prop Data.
*/

/* This is a container component. Notice it does not contain any JSX,
 * nor does it import React. This component is **only** responsible for
 * wiring in the actions and state necessary to render a presentational
 * component, as are all containers in this application.
*/
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import AppError from '../components/common/errors/AppError'
import store, { history } from '../store'
import Routes from './Routes'

import '../css/animate.css'
import '../css/ui/framework.css'


if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENV !== 'staging') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
    titleColor: 'yellow',
    diffNameColor: 'white'
  })
}


const App = () => (
  <Provider store={store}>
    <AppError>
      <Router history={history}>
        <Routes />
      </Router>
    </AppError>
  </Provider>
)

export default App
