/* eslint-disable new-cap */
import { eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { Line, LineChart, CartesianGrid, Legend, Surface, Symbols, Tooltip, XAxis, YAxis } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchAgentStatistics, fetchBranchStatistics, fetchListingAnalysis, fetchMany } from '../../actions'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import date_options from '../../config/date-options.json'
import { MINUSER, CURRENCY } from '../../selectors'
import { hasPermission, textToDate, valueFormat, breakpoint, buildOptionLabel, uniqueArray, getTextWidth } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option {...props} >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

let worker

const renderCustomizedLegend = ((args, currency) => {
  const { payload, data } = args
  const avg_total = payload.reduce((prevValue, entry) => {
    const { dataKey } = entry
    const currentValue = getIn(data, dataKey, 0)
    return prevValue + parseFloat(currentValue)
  }, 0)
  return (
    <div className="customized-legend">
      <span className="legend-total">
        <span className="legend-label">Total</span>
        <span className="legend-count">{valueFormat('currencyabbr', avg_total, { currency: currency })}</span>
      </span>
      {
        payload.map((entry, eid) => {
          const { dataKey, color } = entry
          const total = getIn(data, dataKey, 0)
          return (
            <span key={`overlay-${dataKey}-${eid}`} className="legend-item">
              <Surface width={10} height={10} viewBox={{
                x: 0,
                y: 0,
                width: 10,
                height: 10
              }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              </Surface>
              <span className="legend-label">{dataKey}</span>
              <span className="legend-count">{valueFormat('currencyabbr', total, { currency: currency })}</span>
            </span>
          )
        })
      }
    </div>
  )
})

const longestLabelLength = (data, dataKeys, currency, font) => {
  const length = data ? (
    data
      .reduce((acc, cur) => {
        const bigger = Math.max(...dataKeys.map(k => cur[k]))
        const val = valueFormat('currency', bigger, { currency: currency })
        const curval = (getTextWidth(val.toString(), font) * 1.2) + 5
        return (curval > acc ? curval : acc)
      }, 0)
  ) : 60
  return length
}

const getTicks = (startDate, endDate, num) => {
  let new_ticks = eachMonthOfInterval({ start: startDate, end: endDate })
  if (num < 0.46) {
    new_ticks = eachDayOfInterval({ start: startDate, end: endDate })
  } else if (Math.ceil(num) < 10) {
    new_ticks = eachWeekOfInterval({ start: startDate, end: endDate })
  }
  return new_ticks
}


const getColour = key => {
  if (key.indexOf('fs_ac_') !== -1) {
    return '#70859E'
  } else if (key.indexOf('tl_ac_') !== -1) {
    return '#70859E'
  } else if (key.indexOf('_va_') !== -1) {
    return '#B2C2D4'
  } else if (key.indexOf('_so_') !== -1) {
    return '#FC495D'
  } else if (key.indexOf('_pe_') !== -1) {
    return '#10294D'
  }
  return '#FC495D'
}

const getDate = (date, day_count) => {
  if (day_count >= 180) { return valueFormat('monthyear', date) }
  return valueFormat('daymonth', date)
}

const ListingsValueWidget = props => {
  const abortController = useRef(new AbortController())
  const KEYS = [
    'rfs_ac_value',
    'rfs_pe_value',
    'rfs_va_value'
  ]

  const KEY_LABELS = [
    'Valuation',
    'For Sale',
    'To Let',
    'Pending',
    'Sold',
    'Rented'
  ]

  const MANDATE_TYPES = [
    'Sole',
    'Open',
    'Company Listing',
    'Shared',
    'Expired',
    'Exclusive',
    'MLS',
    'PLN',
    'Referral',
    'Auction'
  ]
  const { actions, user, currency, branches: branches_prop } = props

  const allagents = [ { first_name: 'All', last_name: 'Agents', id: '' } ]
  let initbranchid
  if (user.agent) {
    if (user.agent.id) {
      allagents.push({ first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id })
    }
    if (
      !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
      && user.agent.branches.length
    ) {
      initbranchid = user.agent.branches[0]
    }
  }

  const option = date_options.find(o => o.value === 'LAST_30_DAYS')
  const { start, end, days: mount_days } = textToDate(option.value)
  const [ { agentid, agents }, setAgentState ] = useState({
    agentid: user.agent.id ? user.agent.id : '',
    agents: allagents
  })
  const [ { branchid, branches, branchoptions }, setBranchState ] = useState({
    branchid: props.branches && props.branches.length === 1 ? props.branches[0] : '',
    branches: initbranchid ? initbranchid : [],
    branchoptions: []
  })
  const [ { keys, mandatetype, mandatetypes }, setInitial ] = useState({
    keys: [ ...KEYS ],
    mandatetype: '',
    mandatetypes: MANDATE_TYPES.map(m => ({ value: m, label: m }))
  })
  const [ { days, current, limit, period }, setTimes ] = useState({
    days: mount_days,
    current: valueFormat('shortdate', start.toString()),
    limit: valueFormat('shortdate', end.toString()),
    period: option.value
  })
  const [ { defaultmodel, listingmodel, listingoptions }, setListingState ] = useState({
    defaultmodel: '',
    listingmodel: '',
    listingoptions: []
  })
  const font = useRef(`${getComputedStyle(document.body).fontSize} ${getComputedStyle(document.body).fontFamily}`)
  const [ listingstats, setListingStats ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ mounted, setMounted ] = useState(true)
  const [ showactions, setShowActions ] = useState(breakpoint.matches)
  const [ totals, setTotals ] = useState({})
  const [ filledData, setFilledData ] = useState([])
  let filtertimout

  const toggleActions = (e => {
    if (e.matches && !showactions) {
      setShowActions(true)
    } else if (e.matches !== undefined && showactions) {
      setShowActions(false)
    }
  })

  const getLabel = useCallback(key => {
    let label
    if (key.indexOf('fs_ac_') !== -1) {
      label = 'For Sale'
    } else if (key.indexOf('tl_ac_') !== -1 || key.indexOf('h_ac_') !== -1) {
      label = 'To Let'
    } else if (key.indexOf('_va_') !== -1) {
      label = 'Valuations'
    } else if (key.indexOf('_so_') !== -1) {
      label = 'Sold'
    } else if (key.indexOf('_pe_') !== -1) {
      label = 'Pending'
    } else if (key.indexOf('_re_') !== -1) {
      label = 'Rented'
    }
    return label
  })

  useEffect(() => {
    let modelname
    let key
    let new_keys = [ ...KEYS ]
    let mandate_type = mandatetype
    const mandate_abbr = mandatetype.slice(0, 2).toLowerCase()
    let mandate_types = MANDATE_TYPES.map(m => ({ value: m, label: m }))
    switch (listingmodel) {
      case 'residential_for_sale':
        modelname = 'residential'
        key = 'rfs'
        new_keys = [ ...KEYS, 'rfs_so_value' ].map(k => {
          if (mandate_type) { return k.replace('rfs', key).replace('_value', `_${mandate_abbr}man_value`) }
          return k.replace('rfs', key)
        })
        break
      case 'residential_to_let':
        modelname = 'residential'
        key = 'rtl'
        new_keys = [ ...KEYS, 'rfs_re_value' ].map(k => {
          if (mandate_type) { return k.replace('rfs', key).replace('_value', `_${mandate_abbr}man_value`) }
          return k.replace('rfs', key)
        })
        break
      case 'commercial_for_sale':
        modelname = 'commercial'
        key = 'cfs'
        new_keys = [ ...KEYS, 'rfs_so_value' ].map(k => {
          if (mandate_type) { return k.replace('rfs', key).replace('_value', `_${mandate_abbr}man_value`) }
          return k.replace('rfs', key)
        })
        break
      case 'commercial_to_let':
        modelname = 'commercial'
        key = 'ctl'
        new_keys = [ ...KEYS, 'rfs_re_value' ].map(k => {
          if (mandate_type) { return k.replace('rfs', key).replace('_value', `_${mandate_abbr}man_value`) }
          return k.replace('rfs', key)
        })
        break
      case 'holiday':
        modelname = 'holiday'
        key = 'h'
        new_keys = [ 'h_ac_value' ]
        mandate_type = ''
        mandate_types = []
        break
      default:
        modelname = null
    }
    if (!modelname) { return }
    keys.sort((a, b) => {
      const a_label = getLabel(a)
      const b_label = getLabel(b)
      return KEY_LABELS.indexOf(b_label) - KEY_LABELS.indexOf(a_label)
    })
    setInitial({
      keys: new_keys,
      mandatetype: mandate_type,
      mandatetypes: mandate_types
    })
  }, [ listingmodel ])

  const filterListingModel = useCallback(() => {
    if (!loading) { setLoading(true) }
    setTotals({})
    let action = actions.fetchBranchStatistics
    if (agentid) { action = actions.fetchAgentStatistics }
    let agent_ids
    if (![ null, undefined ].includes(agentid)) {
      agent_ids = [ agentid ]
    } else if (agents.filter(a => a.id).length) {
      agent_ids = agents.filter(a => a.id).map(a => a.id)
    }
    const promise_1 = new Promise((resolve, reject) => action({
      period: 'daily',
      action: 'daily/days',
      params: {
        start_date: current,
        end_date: limit,
        keys,
        branch_id__in: branchid ? [ branchid ] : branches_prop,
        agent_id__in: agent_ids ? agent_ids : null
      },
      resolve,
      reject
    })).then(r => {
      const listing_stats = r.map(d => ({ ...d, date: new Date(d.date).getTime() }))
      listing_stats.sort((A, B) => {
        if (A.date < B.date) { return 1 }
        if (A.date > B.date) { return -1 }
        return 0
      })
      return listing_stats
    })
    const promise_2 = new Promise((resolve, reject) => actions.fetchListingAnalysis({
      params: {
        created__lte: limit,
        branch__in: branchid ? [ branchid ] : branches_prop,
        agents__in: agentid ? [ agentid ] : null,
        fields: keys,
        mandate_type: mandatetype
      },
      resolve,
      reject
    })).then(r => {
      const new_totals = {}
      keys.forEach(k => {
        const label = getLabel(k)
        const value = getIn(r, k, 0)
        new_totals[label] = value
      })
      return new_totals
    })

    Promise.allSettled([ promise_1, promise_2 ]).then(results => {
      setLoading(false)
      setListingStats(results[0].value)
      setTotals(results[1].value)
    }).catch(() => {
      setLoading(false)
    })
  })

  useEffect(() => {
    const listing_options = []
    let default_model
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
      if (!default_model) {
        default_model = { value: 'residential_for_sale', label: 'Residential For Sale' }
      }
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
      if (!default_model) {
        default_model = { value: 'residential_to_let', label: 'Residential To Let' }
      }
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
      if (!default_model) {
        default_model = { value: 'commercial_for_sale', label: 'Commercial For Sale' }
      }
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
      if (!default_model) {
        default_model = { value: 'commercial_to_let', label: 'Commercial To Let' }
      }
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'holiday', label: 'Holiday Listings' })
      if (!default_model) {
        default_model = { value: 'holiday', label: 'Holiday Listings' }
      }
    }
    if (mounted) {
      setListingState({
        defaultmodel: default_model,
        listingmodel: default_model ? default_model.value : '',
        listingoptions: listing_options
      })
    }
    if (!branches_prop || branches_prop.length > 1) {
      let agent_branches = []
      if (!hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)) {
        agent_branches = [ ...user.agent.branches ]
      }
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'branches',
          optionvalue: 'id',
          optionlabel: 'name',
          fields: [ 'id', 'name' ],
          active: 1,
          select: true,
          signal: abortController.current.signal,
          params: {
            id__in: branches_prop ? [ ...agent_branches, ...branches_prop ] : agent_branches,
            order_by: 'name'
          }
        },
        resolve,
        reject
      })).then(r => {
        if (mounted) {
          const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
          setBranchState({
            branchid,
            branches: branches_prop,
            branchoptions: branch_options
          })
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'agents',
          optionvalue: 'id',
          optionlabel: [ 'first_name', 'last_name' ],
          fields: [ 'id', 'first_name', 'last_name' ],
          active: 1,
          select: true,
          signal: abortController.current.signal,
          params: {
            branches__overlap: branches_prop ? [ ...agent_branches, ...branches_prop ] : agent_branches,
            order_by: 'first_name,last_name'
          }
        },
        resolve,
        reject
      })).then(r => {
        if (mounted) {
          setAgentState({
            agentid,
            agents: uniqueArray([ ...allagents, ...r.options ], 'id')
          })
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
    breakpoint.addEventListener('change', toggleActions)
    return () => { // CWU
      setMounted(false)
      breakpoint.removeEventListener('change', toggleActions)
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    clearTimeout(filtertimout)
    filtertimout = setTimeout(filterListingModel, 300)
  }, [ period, branchid, mandatetype, listingmodel, agentid, limit, listingmodel, keys.join(',') ])

  useEffect(() => {
    let agent_branches = []
    if (!hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)) {
      agent_branches = [ ...user.agent.branches ]
    }
    let branches__overlap = branches_prop ? [ ...agent_branches, ...branches_prop ] : agent_branches
    if (branchid) { branches__overlap = [ branchid ] }
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: 'agents',
        optionvalue: 'id',
        optionlabel: [ 'first_name', 'last_name' ],
        fields: [ 'id', 'first_name', 'last_name' ],
        active: 1,
        select: true,
        signal: abortController.current.signal,
        params: {
          branches__overlap,
          order_by: 'first_name,last_name'
        }
      },
      resolve,
      reject
    })).then(r => {
      if (mounted) {
        setAgentState({
          agentid,
          agents: uniqueArray([ ...allagents, ...r.options ], 'id')
        })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
  }, [ branchid ])

  useEffect(() => {
    if (!worker) {
      worker = new Worker(new URL('./ListingValueWidget.worker.js', import.meta.url))
    }
    const num = (days / 365) * 12
    const ticks = getTicks(new Date(current), new Date(limit), num)
    worker.postMessage({ ticks, listingstats, num, keys })
    worker.addEventListener('message', r => {
      setFilledData(r.data)
    })
    return () => {
      worker?.terminate()
      worker = null
    }
  }, [ days, current, limit, listingstats, keys.join(',') ])

  return (
    <Card
      id="listing-value-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <h3>Listings Value</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <div className="filter-branch">
              <InlineSelect
                id="branch_id"
                name="branch_id"
                className="inline-select"
                classNamePrefix="inline"
                options={[ { label: 'All Branches', value: '' }, ...branchoptions ]}
                defaultValue={{ label: 'All Branches', value: '' }}
                selectedValue={branchid}
                onChange={e => {
                  setBranchState({
                    branchid: e.value,
                    branches,
                    branchoptions
                  })
                  setAgentState({
                    agentid: '',
                    agents
                  })
                }}
              />
            </div>
            <div className="filter-agent">
              <AsyncInlineSelect
                id="agent_id"
                name="agent_id"
                className="inline-select"
                classNamePrefix="inline"
                defaultValue={user.agent.id ? { first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id } : { first_name: 'All', last_name: 'Agents', id: '' }}
                options={agents}
                form={{ values: { agent_id: agentid, branch_id: branchid } }}
                watch={[ 'branch_id' ]}
                modelname="agents"
                labelseparator=" "
                fetchMany={actions.fetchMany}
                optionlabel={[ 'first_name', 'last_name' ]}
                noclear
                params={{
                  branches__overlap: branchid ? [ branchid ] : branches,
                  active: 1,
                  order_by: 'first_name,last_name',
                  fields: 'id,first_name,last_name'
                }}
                field={{ value: agentid || '', name: 'agent_id' }}
                onLoad={e => setAgentState({
                  agentid,
                  agents: uniqueArray([ ...allagents, ...e ], 'id')
                })}
                onChange={e => setAgentState({
                  agentid: e.value,
                  agents
                })}
              />
            </div>
            {mandatetypes.length ? (
              <div className="filter-mandate-type">
                <InlineSelect
                  id="mandate_type"
                  name="mandate_type"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'Mandate Type', value: '' }, ...mandatetypes ]}
                  defaultValue={{ label: 'Mandate Type', value: '' }}
                  selectedValue={mandatetype}
                  onChange={e => setInitial({ keys, mandatetype: e.value, mandatetypes })}
                />
              </div>
            ) : null}
            <div className="filter-listing-model">
              <InlineSelect
                id="listing_model"
                name="listing_model"
                className="inline-select"
                classNamePrefix="inline"
                options={listingoptions}
                defaultValue={defaultmodel || ''}
                selectedValue={listingmodel}
                onChange={e =>
                  setListingState({
                    defaultmodel,
                    listingmodel: e.value,
                    listingoptions
                  })
                }
              />
            </div>
            <div className="filter-date-range">
              <InlineSelect
                id="period"
                name="period"
                className="inline-select"
                classNamePrefix="inline"
                defaultValue={date_options.find(o => o.value === 'LAST_30_DAYS')}
                selectedValue={period}
                options={date_options.filter(o => !o.value.includes('NEXT') || !o.value === 'TOMORROW' || !o.value === 'TODAY' || !o.value === 'YESTERDAY')}
                onChange={e => {
                  const { start: new_start, end: new_end, days: new_days } = textToDate(e.value)
                  setTimes({
                    days: new_days,
                    current: valueFormat('shortdate', new_start.toString()),
                    limit: valueFormat('shortdate', new_end.toString()),
                    period: e.value
                  })
                }}
                components={{ Option: CustomOption }}
              />
            </div>
          </div>
        </>
      }
      body={
        !loading ? (
          <div className={`flex-container flex-quality${[ 'residential', 'commercial' ].includes(listingmodel) ? '' : ' flex-3'}`}>
            <ResponsiveContainer width="100%" height={250} minWidth="0" legendWrap={!showactions}>{() => (
              <LineChart width={750} height={250} data={filledData}>
                <Legend wrapperStyle={{ width: showactions ? 'auto' : '100%', height: showactions ? '100%' : null, top: 0, left: 0 }} iconType="circle" iconSize={9} layout={showactions ? 'vertical' : 'horizontal'} verticalAlign={'top'} align="left" content={args => renderCustomizedLegend(args, currency)} data={totals} />
                <CartesianGrid vertical={false} stroke="#F3F5F8" />
                <XAxis
                  dataKey="date"
                  hasTick
                  padding="no-gap"
                  tickFormatter={date => getDate(date, days)}
                  tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                  tickLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                  axisLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                />
                <YAxis
                  width={longestLabelLength(filledData, keys.map(key => getLabel(key)), currency, font.current)}
                  hasTick
                  axisLine={false}
                  domain={[ 'dataMin', 'dataMax' ]}
                  tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                  tickLine={{ stroke: 'none', strokeWidth: 1 }}
                  tickCount={9}
                  tickFormatter={value => valueFormat('currency', value, { currency: currency })}
                />
                <Tooltip cursor={{ fill: '#FAFBFD' }} formatter={value => valueFormat('currency', value, { currency: currency })} labelFormatter={getDate} />
                {keys.map((key, idx) => (
                  <Line key={`bar-${idx}`} dataKey={getLabel(key)} strokeWidth={2} isAnimationActive={false} stroke={getColour(key)} />
                ))}
              </LineChart>
            )}
            </ResponsiveContainer>
          </div>
        ) : <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
      }
    />
  )
}

ListingsValueWidget.propTypes = {
  actions: PropTypes.object,
  currency: PropTypes.string,
  user: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const currency = CURRENCY(state)
  return ({
    user,
    currency
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMany,
    fetchBranchStatistics,
    fetchAgentStatistics,
    fetchListingAnalysis
  }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ListingsValueWidget))
