/* eslint-disable new-cap */
import { differenceInCalendarDays } from 'date-fns'
import { getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import date_options from '../../config/date-options.json'
import { CONFIG, MINUSER } from '../../selectors'
import { capitalize, hasPermission, sortBy, textToDate, valueFormat, buildOptionLabel } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import SimpleTable from '../common/simpletable/SimpleTable'
import withDelay from './withDelay'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class ExpiringListingsWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'NEXT_30_DAYS')
    const period = option.value
    const { start, end } = textToDate(option.value)
    const { start: previous_start, end: previous_end } = textToDate(period, true)
    this.state = {
      count: 0,
      prev_count: 0,
      negative: false,
      branches: [],
      branch_options: [],
      listing_options: [],
      listing_model: '',
      branch_id: '',
      agent_id: '',
      loading: true,
      period,
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      previous: valueFormat('shortdate', previous_start.toString()),
      prev_limit: valueFormat('shortdate', previous_end.toString())
    }
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user } = this.props
    const listing_options = []
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
    }
    this.setState({ listing_options })
    let branches
    if (!hasPermission([
      'apply_to_all_branches'
    ], user.permissions, null, user.agent.id)) {
      branches = user.agent.branches
    }
    new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'branches',
        optionvalue: 'id',
        optionlabel: 'name',
        fields: [ 'id', 'name' ],
        active: 1,
        all: true,
        select: true,
        params: {
          id__in: branches,
          order_by: 'name'
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(r => {
      if (this._is_mounted) {
        const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
        this.setState({ branches: r.options, branch_options })
      }
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
    let branch_id = this.state.branch_id
    let agent_id = this.state.agent_id
    if (user.agent) {
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && user.agent.branches.length === 1
        && !branch_id
      ) {
        branch_id = user.agent.branches[0]
      }
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && !agent_id
      ) {
        agent_id = user.agent.id
      }
    }
    if (branch_id !== this.state.branch_id && this._is_mounted) {
      this.setState({ branch_id })
    }
    if (agent_id !== this.state.agent_id && this._is_mounted) {
      this.setState({ agent_id })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  render() {
    const { actions, configs, user } = this.props
    const { branch_id, limit, listing_model, agent_id } = this.state
    const [ model, ...listing_type_parts ] = listing_model ? listing_model.split('_') : [ '', '', '' ]
    const listing_type = listing_type_parts.filter(p => p).map(capitalize).join(' ')
    let branches
    if (!hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)) {
      branches = user.agent.branches
    }
    return (
      <Card
        id="expiring-listings-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding expiring-listings-widget"
        background
        header={
          <>
            <h3>Expiring Mandates & Leases</h3>
            <div className="details-section-buttons min-flex tablemeta">
              <div className="filter-branch">
                <InlineSelect
                  id="branch_id"
                  name="branch_id"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                  defaultValue={{ label: 'All Branches', value: '' }}
                  selectedValue={getIn(this.state, 'branch_id')}
                  onChange={e => {
                    this.setState({ branch_id: e.value })
                  }}
                />
              </div>
              <div className="filter-listing-model">
                <InlineSelect
                  id="listing_model"
                  name="listing_model"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Listing Types', value: '' }, ...this.state.listing_options ]}
                  defaultValue={{ label: 'All Listing Types', value: '' }}
                  selectedValue={getIn(this.state, 'listing_model')}
                  onChange={e => {
                    this.setState({ listing_model: e.value })
                  }}
                />
              </div>
              <div className="">
                <AsyncInlineSelect
                  id="agent_id"
                  name="agent_id"
                  className="inline-select"
                  classNamePrefix="inline"
                  defaultValue={{ first_name: 'All', last_name: 'Agents', id: '' }}
                  options={user.agent.id ? [ { first_name: 'All', last_name: 'Agents', id: '' }, { ...user.agent } ] : [ { first_name: 'All', last_name: 'Agents', id: '' } ]}
                  modelname="agents"
                  labelseparator=" "
                  fetchMany={actions.fetchMany}
                  optionlabel={[ 'first_name', 'last_name' ]}
                  noclear
                  params={{
                    branches__overlap: branch_id ? [ branch_id ] : branches || '',
                    active: 1,
                    order_by: 'first_name,last_name',
                    fields: 'id,first_name,last_name'
                  }}
                  field={{
                    value: getIn(this.state, 'agent_id')
                  }}
                  form={{ values: { branches__overlap: branch_id ? [ branch_id ] : branches || '', agent_id } }}
                  onChange={e => {
                    const agentid = e.value
                    this.setState({ agent_id: agentid })
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          <SimpleTable
            paginated
            config={configs.residential}
            action={({ params, resolve, reject }) => {
              const promises = []

              if (!params.listing_type || params.listing_type === 'For Sale') {
                promises.push(new Promise((res, rej) => actions.fetchMany({
                  noloader: true,
                  values: {
                    modelname: 'residential',
                    endpoint: {
                      read: '/mashup/api/v1/listings/expiring-listings'
                    },
                    status: 'Active',
                    fields: [ 'id', 'web_ref', 'agent', 'mandate_end_date', 'mandate_type', 'model', 'property_type', 'listing_type' ],
                    meta_fields: [ 'agent' ],
                    conflict: true,
                    order_by: '-mandate_end_date',
                    signal: this.AbortController.signal,
                    params: {
                      ...params,
                      listing_type: 'For Sale',
                      date_to: limit
                    }
                  },
                  resolve: res,
                  reject: rej
                })).catch(e => {
                  if (e.status !== 408) {
                    console.error(e)
                  }
                }))
              }

              if (!params.listing_type || params.listing_type === 'To Let') {
                promises.push(new Promise((res, rej) => actions.fetchMany({
                  noloader: true,
                  values: {
                    modelname: 'residential',
                    endpoint: {
                      read: '/mashup/api/v1/listings/expiring-listings'
                    },
                    status: 'Active',
                    fields: [
                      'id',
                      'web_ref',
                      'agent',
                      'tenant_lease_ends',
                      'mandate_type',
                      'model',
                      'property_type',
                      'listing_type'
                    ],
                    meta_fields: [ 'agent' ],
                    conflict: true,
                    order_by: '-tenant_lease_ends',
                    signal: this.AbortController.signal,
                    params: {
                      ...params,
                      listing_type: 'To Let',
                      date_to: limit
                    }
                  },
                  resolve: res,
                  reject: rej
                })).catch(e => {
                  if (e.status !== 408) {
                    console.error(e)
                  }
                }))
              }
              Promise.allSettled(promises).then(results => {
                let all_data = []
                results.forEach(result => {
                  if (!getIn(result, 'value')) { return }
                  all_data = [ ...all_data, ...result.value.options.map(o => {
                    if (o.listing_type === 'For Sale') {
                      o.expiry_date = new Date(getIn(o, 'mandate_end_date'))
                    } else {
                      o.expiry_date = new Date(getIn(o, 'tenant_lease_ends'))
                    }
                    return o
                  }) ]
                })
                resolve({ options: sortBy(all_data, 'expiry_date'), hasMore: false })
              }).catch(e => {
                reject(e)
              })
            }}
            params={{
              model,
              listing_type,
              branch: branch_id,
              agents__in: agent_id,
              meta_fields: [ 'agent' ],
              fields: [
                'id',
                'web_ref',
                'agent',
                'mandate_end_date',
                'tenant_lease_ends',
                'mandate_type',
                'model',
                'property_type',
                'listing_type'
              ],
              limit: 100
            }}
            parser={data => {
              data.options = data.options.map(o => {
                if (o.model === 'project') {
                  o.property_types = []
                }
                const days_left = differenceInCalendarDays(new Date(o.expiry_date), new Date())
                let score = 1
                if (days_left <= 0) {
                  score = 3
                } else if (days_left <= 14) {
                  score = 2
                }
                o.score = score
                o.days_left = days_left
                return o
              })
              return data
            }}
            header={[
              {
                label: <svg viewBox="0 0 32 32" className="btmstar"><use href="/images/glyphs.svg#glyph-Star" /></svg>,
                name: 'score',
                orderable: false,
                format: 'score'
              },
              {
                label: 'Expiry Date',
                name: 'expiry_date',
                orderable: false,
                format: 'date'
              },
              {
                label: 'Web Ref',
                name: 'web_ref',
                orderable: false,
                link: '/secure/:model/:id'
              },
              {
                label: 'Type',
                name: 'mandate_type',
                orderable: false
              },
              {
                label: 'Agent',
                name: 'agent',
                modelname: 'agents',
                optionlabel: [ 'first_name', 'last_name' ],
                labelseparator: ' ',
                order_by: 'agent__first_name',
                link: '/secure/:site/users/:agent',
                orderable: false
              }
            ]}
            user={user}
          />
        }
      />
    )
  }
}


ExpiringListingsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const residential = CONFIG(state, 'residential')
  const commercial = CONFIG(state, 'commercial')
  const projects = CONFIG(state, 'projects')
  const holiday = CONFIG(state, 'holiday')
  return ({
    user,
    configs: Map({
      residential,
      commercial,
      projects,
      holiday
    })
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(ExpiringListingsWidget)))
