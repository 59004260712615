import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'

import { logEvent } from '../../../utils'


const Checkbox = ({ name, promise, clickAction, selected, val, modelname, endpoint }) => {
  const handleClick = e => {
    const el = e.target
    const values = {
      modelname,
      endpoint: getIn(endpoint, 'write'),
      id: parseInt(el.value, 10)
    }
    values[name] = !!el.checked
    if (promise) {
      new Promise((resolve, reject) => clickAction({
        values,
        resolve,
        reject
      })).then(() => {
        logEvent('TOGGLE_TABLE_CHECK_SUCCESS', { values })
      }).catch(err => {
        logEvent('TOGGLE_TABLE_CHECK_FAILED', { values, err })
      })
    } else {
      clickAction(values)
    }
  }

  return (
    <label className="checkcontainer">
      <input
        checked={selected}
        value={val}
        type="checkbox"
        onChange={handleClick}
      />
      <span className={`checkmark ${name}`}>
        {selected && selected !== 'false' ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
      </span>
    </label>
  )
}

Checkbox.propTypes = {
  val: PropTypes.number,
  selected: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  name: PropTypes.string,
  promise: PropTypes.bool,
  modelname: PropTypes.string,
  endpoint: PropTypes.object,
  clickAction: PropTypes.func
}

export default Checkbox
