/* eslint-disable new-cap */
import merge from 'deepmerge'
import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import Select, { components } from 'react-select'
import { reduceGroupedOptions } from 'react-select-async-paginate'

import template from 'lodash/template'
import log from '../../../../logging'
import { buildOptionLabel, composeOptionLabel, convertArrayToObject, overwriteMerge, uniqueArray, valueFormat, generateAddress, groupBy } from '../../../../utils'
import Loader from '../../Loader'
import QueryBuilder from '../../QueryBuilder'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import { Button } from '../../../ui/Button'
import ListingFiltersSidebar from '../../../ListingFiltersSidebar'
import P24UrlSidebar from '../../../P24UrlSidebar'
import { withResponsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const ResponsiveAsyncPaginate = withResponsiveSelect(Select)

const CustomOption = props => {
  const compiler = template(props.template, { imports: { valueFormat, getIn, generateAddress } })
  const htmlString = compiler({ record: props.data, settings: props.settings })
  return <components.Option
    {...props}
    isFocused={false}
  >
    <div className="customopt">
      <label className="checkcontainer">
        <input
          type="checkbox"
          checked={props.isSelected}
        />
        <span className={'checkmark'}>
          {props.isSelected && props.isSelected !== 'false' ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
        </span>
      </label>
      {valueFormat('linebreakdomstring', htmlString)}
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object,
  isSelected: PropTypes.bool,
  template: PropTypes.string,
  settings: PropTypes.object,
  selectProps: PropTypes.object,
  selectOption: PropTypes.func,
  value: PropTypes.any,
  hasValue: PropTypes.bool,
  setValue: PropTypes.func
}

class AsyncCheckGroup extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.defaultReduceOptions = this.defaultReduceOptions.bind(this)
    this.loadData = this.loadData.bind(this)
    this.shouldLoadMore = this.shouldLoadMore.bind(this)
    this.loadIndicator = this.loadIndicator.bind(this)
    this.customOption = this.customOption.bind(this)

    let queryparams = {}
    if ((props.external || props.filtered) && props.params) {
      const query = new QueryBuilder(props.params)
      queryparams = query.getAllArgs()
    }
    const { watch, form, field, multi } = props
    const watchvalues = watch ? watch.map(v => form.values[v]) : []
    let cacheuniqs = [ ...watchvalues ]
    if (multi) {
      cacheuniqs = [ field.value ? field.value.length : 0, ...cacheuniqs ]
    } else {
      cacheuniqs = [ field.value || 0, ...cacheuniqs ]
    }
    this.state = {
      options: props.options ? props.options.map(o => buildOptionLabel(props, o)) : [],
      results: [],
      hasMore: false,
      init: false,
      inputValue: '',
      manual: false,
      cacheuniqs: cacheuniqs,
      reset: false,
      indexedResults: {},
      params: queryparams
    }

    this.AsyncSelectRef = React.createRef()
    this.mergeOptions = this.mergeOptions.bind(this)
    this.loadDependentOptions = this.loadDependentOptions.bind(this)
    this.setMetaFieldStatus = this.setMetaFieldStatus.bind(this)
    this.updateParams = this.updateParams.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
    this.fetch = null
  }

  componentDidMount() {
    let queryparams = {}
    if (this.props.params) {
      const query = new QueryBuilder(this.props.params)
      queryparams = query.getAllArgs()
    }
    setTimeout(() => {
      if (this._is_mounted) {
        this.setState({ menuIsOpen: true, params: queryparams, reset: true })
      }
    }, 50)
  }

  componentDidUpdate(prevProps, prevState) {
    const { watch, form, field, multi, metafield, cache, labelgrouper, params,
      optionlabel, labelseparator, optionvalue, modelname, labelformat } = this.props

    if (this.props.options && this.props.options.length && !this.state.options.length && this._is_mounted) {
      const options = this.props.options.map(o => buildOptionLabel(this.props, o))
      this.setState({ options })
    }

    if (field.value && this.props.external) {
      const external_options = this.state.options
      field.value.forEach(o => {
        const {
          property_type,
          bathrooms,
          price,
          garages,
          url,
          floor_size,
          land_size,
          bedrooms,
          title,
          image
        } = o
        const sorted_o = {
          property_type,
          bathrooms,
          price,
          garages,
          url,
          floor_size,
          land_size,
          bedrooms,
          title,
          image
        }
        const value = JSON.stringify(sorted_o)
        const exists = external_options.find(v => v.value === value)
        if (!exists) {
          external_options.push({ ...sorted_o, value: JSON.stringify(sorted_o) })
        }
      })
      if (!isEqual(external_options, this.state.options) && this._is_mounted) {
        this.setState({ options: uniqueArray(external_options, 'value') })
      }
    }

    if (this.props.params && this.props.params !== prevProps.params && this._is_mounted) {// reset options if params change
      const query = new QueryBuilder(this.props.params)
      const queryparams = query.getAllArgs()
      this.setState({ reset: true, params: queryparams })
    }

    if (this.state.reset && this._is_mounted) { // set reset back to false once complete
      this.setState({ reset: false })
    }
    const watchvalues = watch ? watch.map(v => form.values[v]) : []
    let cacheuniqs = [ ...watchvalues ]
    if (multi) {
      cacheuniqs = [ field.value ? field.value.length : 0, ...cacheuniqs ]
    } else {
      cacheuniqs = [ field.value || 0, ...cacheuniqs ]
    }
    if (!isEqual(cacheuniqs, prevState.cacheuniqs) && this._is_mounted) {
      this.setState({ cacheuniqs })
    }

    if (metafield && !isEqual(prevState.cacheuniqs, this.state.cacheuniqs) && this._is_mounted) {
      this.setMetaFieldStatus(field.value)
    }
    if (!isEqual(this.state.results, prevState.results) && this._is_mounted) {
      const indexedResults = convertArrayToObject(this.state.results, this.props.optionvalue || 'id')
      this.setState({ indexedResults })
    }
    let vals
    if (this.state.options.length && field.value) { // handle passing in of default values
      // No need to compose option labels here becuase they're already in the correct format.
      if (Array.isArray(field.value)) {
        vals = this.state.options.filter(o => field.value.find(fv => isEqual(
          fv, this.props.external ? JSON.parse(o.value) : o.value
        ) || isEqual(fv, o)))
      } else {
        vals = this.state.options.filter(o => isEqual(o.value, field.value) || isEqual(o, field.value))
      }
    }
    if (
      (!vals || (Array.isArray(vals) && !vals.length))
      && (Object.keys(this.state.indexedResults).length || (cache && Object.keys(cache).length))
    ) {
      vals = composeOptionLabel({
        ...cache,
        ...this.state.indexedResults
      }, field.value, optionlabel, labelseparator, optionvalue)
    }
    if (
      !vals
      && (
        (!Array.isArray(field.value) && field.value)
        || (Array.isArray(field.value) && field.value.length)
      )
      && !this.props.external
    ) {
      let queryparams = {}
      if (params) {
        const query = new QueryBuilder(params)
        queryparams = query.getAllArgs()
      }
      queryparams[`${optionvalue ? optionvalue : 'id'}__in`] = field.value
      const values = {
        formmodel: form.initialValues.modelname,
        modelname,
        labelseparator,
        labelformat,
        labelgrouper,
        optionlabel,
        optionvalue,
        conflict: true,
        select: true,
        params: queryparams,
        signal: this.AbortController.signal
      }
      new Promise((resolve, reject) =>
        this.props.fetchMany({ values, resolve, reject, noloader: true })).then(r => {
        const delta = { [modelname]: {} }
        r.options.forEach(o => {
          delta[modelname][o.id] = o
        })
        this.props.actions.cacheDelta(delta)
      }).catch(() => {})
    }
    if (!isEqual(vals, this.state.vals) && this._is_mounted) {
      this.setState({ vals })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  setMetaFieldStatus(vals) {
    const { form, statusfield, cache } = this.props
    let meta_vals = null
    if (statusfield) {
      if (Array.isArray(vals)) {
        meta_vals = vals.map(v => getIn(cache, `${v}.${statusfield.field}`, [])).filter(v => (Array.isArray(v) ? v.length : v))
        if (meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield] = meta_vals
        setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      } else if (vals) {
        meta_vals = cache[vals] ? cache[vals][statusfield.field] : []
        if (meta_vals && meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield.key] = meta_vals
        setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      } else {
        const meta = { ...form.status }
        meta[statusfield.key] = null
        setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      }
    }
  }

  handleChange(v) {
    const { multi, form, field, dependents, optionvalue, onchange, modelname } = this.props
    let vals = []
    let key = 'value'
    if (optionvalue) {
      key = optionvalue
    }
    if (v) { // Is there a value? Used for clearing select
      if (Array.isArray(v) && v.length > 0) {
        // Array value with values
        v.forEach(i => {
          if (Array.isArray(getIn(i, key))) {
            vals.push(getIn(i, key)[0])
          } else {
            vals.push(getIn(i, key))
          }
        })
      } else if (multi) {
        if (getIn(v, key)) { vals.push(getIn(v, key)) }
      } else if (getIn(v, key)) {
        vals = getIn(v, key)
      } else if (v !== '') {
        vals = v
      } else {
        vals = null
      }
      if (this.props.external) {
        vals = vals.map(o => (typeof o === 'string' ? JSON.parse(o) : o))
      }
      if (vals) {
        vals = vals.filter(val => val)
      }
      this.setMetaFieldStatus(vals)
      form.setFieldValue(field.name, vals).then(() => {
        form.setFieldTouched(field.name)
      })
      if (dependents && !onchange) { // Unset any dependents on a field without a custom change action
        dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) })
      }
      const delta = { [modelname]: {} }
      if (vals && !Array.isArray(vals)) {
        vals = [ vals ]
      }
      vals.filter(val => this.state.indexedResults[val]).forEach(val => {
        delta[modelname][val] = this.state.indexedResults[val]
      })
      this.props.actions.cacheDelta(delta)
    } else { // Clear the select as there is no value
      this.setMetaFieldStatus(null)
      form.setFieldValue(field.name, null).then(() => {
        form.setFieldTouched(field.name)
      })
    }
  }

  defaultReduceOptions(prevOptions, loadedOptions) {
    const options = prevOptions.concat(loadedOptions)
    this.setState({ options }, () => {
      if (this.props.updateTemplates) {
        this.props.updateTemplates(this.mergeOptions(options))
      }
    })
    return options
  }

  /*
  Function for merging simple options as well as option groups
  */
  mergeOptions(newOptions) {
    const { labelgrouper } = this.props
    if (labelgrouper) {
      const all_options = this.state.options.map(group => {
        const options = newOptions.filter(g => g.label === group.label)
        group.options = merge(this.state.options, options, { arrayMerge: overwriteMerge })
        return group
      })
      return all_options
    }
    return uniqueArray([ ...this.state.options, ...newOptions ], 'value')
  }

  loadDependentOptions() {
    const { form, dependent, labelgrouper, optionvalue } = this.props
    if (dependent && getIn(form.values, dependent)) {
      const options = []
      const results = getIn(form.values, dependent, [])
        .filter(val => val)
        .map(val => ({ ...val, value: getIn(val, optionvalue, val.id) }))
      if (labelgrouper && results.length) { // Labels are grouped
        const groups = groupBy(results, labelgrouper)
        for (const group in groups) {
          if (group !== '') {
            const g = { label: group, options: groups[group] }
            options.push(g)
          }
        }
      } else if (results.length) {
        results.forEach(res => { options.push(res) })
      }
      return options
    }
    return []
  }

  async loadData(search, prevOptions, additional) {
    const { modelname, labelseparator, labelformat, labelgrouper,
      options, optionlabel, optionvalue, form, config, dependent, extraparams } = this.props

    const { params: queryparams } = this.state

    if (this.state.manual || this.props.external) {
      return { options: uniqueArray(this.state.options, 'value'), hasMore: false, additional }
    }
    if (dependent) {
      return { options: this.loadDependentOptions(), hasMore: false, additional }
    }
    let defaultOptions = []
    try {
      if (labelgrouper) { // If the options are grouped, iterate over each group and get the length of the inner options arrays
        queryparams.offset = prevOptions.map(group => group.options.length).reduce((a, b) => a + b, 0) // Get array lengths and reduce them into the final total
      } else {
        queryparams.offset = additional.offset
        if (queryparams.offset === 0 || queryparams.offset < 20) { delete queryparams.offset }
      }
      if (!queryparams.offset && options) {
        defaultOptions = options.map(o => buildOptionLabel(this.props, o))
      }

      if (!getIn(queryparams, 'id__in') && extraparams && extraparams.includes('id__in=')) {
        // don't fetch all if no params provided
        throw Error('No params provided')
      }
      const values = {
        formmodel: form.initialValues.modelname,
        id: form.initialValues.id,
        endpoint: config.endpoint,
        modelname,
        labelseparator,
        labelformat,
        labelgrouper,
        optionlabel,
        optionvalue,
        term: search,
        conflict: true,
        params: queryparams
      }
      const response = await new Promise((resolve, reject) =>
        this.props.fetchMany({ values, resolve, reject, noloader: true })).catch(() =>
        ({ options: [], hasMore: false, additional }))
      let more_params = queryparams
      const r = Object.assign({}, response)
      const results = uniqueArray(merge(this.state.results, r.options), 'id')
      this.setState({ init: true })
      r.options = r.options.map(o => ({ ...buildOptionLabel({
        optionlabel,
        optionvalue,
        labelseparator,
        labelformat,
        labelgrouper
      }, o), ...o }))
      if (defaultOptions.length) { // Apply search to static defaults as well
        const searchLower = search ? search.toLowerCase() : null
        defaultOptions = defaultOptions.filter(option => {
          if (searchLower) {
            return option.value.toLowerCase().includes(searchLower)
          }
          return true
        })
        r.options = uniqueArray([ ...defaultOptions, ...r.options ], 'value')
      }
      if (r.hasMore) {
        const new_query = new QueryBuilder(r.hasMore)
        more_params = new_query.getAllArgs()
      }
      if (!isEqual(this.state.results, results) && this._is_mounted) {
        this.setState({ results: uniqueArray(merge(this.state.results, results), 'id') })
      }
      return { ...{ ...r, hasMore: !!r.hasMore }, additional: more_params }
    } catch (e) {
      if (e.error !== 'The operation was aborted. ') {
        log.error(e)
      }
    }
    if (this._is_mounted) { this.setState({ init: true }) }
    return { options: [], hasMore: false, additional }
  }

  shouldLoadMore(scrollHeight, clientHeight, scrollTop) {
    const bottomBorder = (scrollHeight - clientHeight) / 2
    return bottomBorder < scrollTop
  }

  loadIndicator() {
    return <Loader inline />
  }

  customOption(props) {
    if (props.selectProps.labelformat) {
      return <CustomOption
        {...props}
        settings={this.props.settings}
        template={this.props.optiontemplate}
        value={getIn(props.data, this.props.optionvalue, getIn(props.data, 'value'))}
      />
    }
    return <components.Option {...props} />
  }

  updateParams(params) {
    this.setState({ params, reset: true })
  }

  updateOptions(options) {
    const external_options = options.map(o => {
      const {
        property_type,
        bathrooms,
        price,
        garages,
        url,
        floor_size,
        land_size,
        bedrooms,
        area,
        suburb,
        title,
        image
      } = o
      const sorted_o = {
        property_type,
        bathrooms,
        price,
        garages,
        url,
        floor_size,
        land_size,
        bedrooms,
        area,
        suburb,
        title,
        image
      }
      return {
        ...sorted_o,
        label: o.title,
        value: JSON.stringify(sorted_o)
      }
    })
    if (this._is_mounted) {
      this.setState({ options: external_options, reset: true, manual: true })
    }
  }

  render() {
    const {
      field,
      label,
      form,
      classes,
      labelformat,
      placeholder,
      noclear,
      multi,
      labelgrouper,
      id,
      disabled,
      readonly,
      modelname,
      config,
      showError,
      noOptions
    } = this.props
    let { plural, singular } = this.props
    if (!plural) {
      plural = config.plural
    }
    if (!singular) {
      singular = config.singular
    }
    const { name } = field
    if (!field.name) { return null }
    return (
      <>
        {this.props.external || this.props.filtered ? (
          <div className="form-group asynccheckgroup-filter-buttons">
            {this.props.external ? (
              <>
                <Button
                  type="button"
                  id={`${field.name}-contact-lookup-edit-btn`}
                  tabIndex="-1"
                  onClick={() => this.props.toggleWideSidebar(`show-p24-urls-${field.name}`) }
                  className="btn btn-subtle"
                >
                  Add Properties
                </Button>
                <WideSidebar sidebar={`show-p24-urls-${field.name}`}>
                  <P24UrlSidebar
                    actions={{
                      toggleWideSidebar: this.props.toggleWideSidebar,
                      fetchMany: this.props.fetchMany,
                      fetchP24Urls: this.props.fetchP24Urls,
                      updateParams: this.updateParams,
                      updateOptions: this.updateOptions
                    }}
                    parent={field}
                    data={form.values}
                    max={this.props.max || 4}
                    modelname={modelname}
                  />
                </WideSidebar>
              </>
            ) : null}
            {this.props.filtered ? (
              <>
                <Button
                  type="button"
                  id={`${field.name}-contact-lookup-edit-btn`}
                  tabIndex="-1"
                  onClick={() => this.props.toggleWideSidebar(`show-listing-search-${field.name}`) }
                  className="btn btn-subtle"
                >
                  Filter Properties
                </Button>
                <WideSidebar sidebar={`show-listing-search-${field.name}`}>
                  <ListingFiltersSidebar
                    actions={{
                      toggleWideSidebar: this.props.toggleWideSidebar,
                      fetchMany: this.props.fetchMany,
                      updateParams: this.updateParams,
                      updateOptions: this.updateOptions
                    }}
                    params={this.state.params}
                    data={form.values}
                    modelname={modelname}
                  />
                </WideSidebar>
              </>
            ) : null}
          </div>
        ) : null}
        <div
          id={id}
          className={`selectinput asynccheckgroup form-group ${classes ? classes : ''}`}
          ref={el => { // This may need to be refactored for performance sakes
            if (!el) {return}
            this.el = el
          }}
        >
          {label && label.length > 0 &&
          <Label htmlFor={name}>
            {label}
          </Label>
          }
          <div className="forminput">
            <ResponsiveAsyncPaginate
              key={`as-${name}`}
              debounceTimeout={300}
              className={'react-select'}
              classNamePrefix="react-select"
              isMulti={multi}
              name={field.name}
              inputId={name}
              form={form}
              field={field}
              isClearable={noclear ? false : true}
              isDisabled={readonly || disabled ? true : false}
              loadOptions={this.loadData}
              defaultOptions
              options={this.state.options}
              onChange={this.props.onChange || this.handleChange}
              value={this.state.vals}
              hideSelectedOptions={false}
              menuIsOpen={true}
              labelformat={labelformat}
              shouldLoadMore={this.shouldLoadMore}
              reduceOptions={labelgrouper ? reduceGroupedOptions : this.defaultReduceOptions}
              controlShouldRenderValue={false}
              placeholder={placeholder}
              noOptionsMessage={ () => {
                if (noOptions) {
                  return noOptions
                }
                if (modelname) {
                  if ([ 'residential', 'holiday', 'commercial', 'estate', 'projects' ].includes(modelname)) {
                    return `No ${plural} found`
                  }
                  if (plural.slice(0, plural.length - 1) === singular) { return `No ${plural} found` }
                  if (singular) { return `No ${plural} found in this ${singular}` }
                  return `No ${plural} found`
                }
                return 'No options found'
              }
              }
              cacheUniqs={[ this.state.reset ]}
              components={{
                LoadingIndicator: this.loadIndicator,
                Option: this.customOption,
                Control: () => null,
                DropdownIndicator: () => null,
                ClearIndicator: () => null,
                ValueContainer: () => null
              }}
              additional={{ offset: 0 }}
            />
            <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
            {showError ? <div className="error">{form.errors[field.name]}</div> : null}
          </div>
        </div>
      </>
    )
  }
}

AsyncCheckGroup.propTypes = {
  fetchMany: PropTypes.func.isRequired,
  modelname: PropTypes.string.isRequired,
  config: PropTypes.object,
  settings: PropTypes.object,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  noclear: PropTypes.bool,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  dependents: PropTypes.array,
  dependent: PropTypes.string,
  options: PropTypes.array,
  querymodel: PropTypes.string,
  onchange: PropTypes.string,
  noOptions: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelgrouper: PropTypes.string,
  labelformat: PropTypes.object,
  actions: PropTypes.object,
  watch: PropTypes.array,
  extraparams: PropTypes.string,
  params: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  metafield: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  statusfield: PropTypes.object,
  labelseparator: PropTypes.string,
  optionvalue: PropTypes.string,
  placeholder: PropTypes.string,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  onChange: PropTypes.func,
  singular: PropTypes.string,
  plural: PropTypes.string,
  updateTemplates: PropTypes.func,
  showError: PropTypes.bool,
  optiontemplate: PropTypes.string,
  toggleWideSidebar: PropTypes.func,
  fetchP24Urls: PropTypes.func,
  external: PropTypes.bool,
  filtered: PropTypes.bool,
  max: PropTypes.number
}

export default AsyncCheckGroup
