import ActiveListingsWidget from './ActiveListingsWidget'
import ExpiringListingsWidget from './ExpiringListingsWidget'
import ExpiringSoonWidget from './ExpiringSoonWidget'
import LatestLeadsWidget from './LatestLeadsWidget'
import LeadSourceWidget from './LeadSourceWidget'
import ListingQualityWidget from './ListingQualityWidget'
import ListingValueWidget from './ListingValueWidget'
import NewsletterWidget from './NewsletterWidget'
import NotesWidget from './NotesWidget'
import NotificationsWidget from './NotificationsWidget'
import StockWidget from './StockWidget'
import SyndicationWidget from './SyndicationWidget'
import UAESyndicationWidget from './UAESyndicationWidget'


export default {
  ActiveListingsWidget,
  ExpiringListingsWidget,
  ExpiringSoonWidget,
  LatestLeadsWidget,
  LeadSourceWidget,
  ListingQualityWidget,
  ListingValueWidget,
  NewsletterWidget,
  UAESyndicationWidget,
  NotesWidget,
  NotificationsWidget,
  StockWidget,
  SyndicationWidget
}
