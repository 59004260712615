import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import merge from 'deepmerge'
import { isEqual } from 'date-fns'
import { NavLink } from 'react-router-dom'
import { isConditional, parseURL, slugify, sortBy, uniqueArray, buildOptionLabel, valueFormat } from '../../utils'
import extras from '../../config/extras.json'
import MetaDetail from './MetaDetail'
import MediaGallery from './MediaGallery'
import Tip from './forms/Tip'
import Card from './Card'
import ContactDetails from './ContactDetails'
import SimpleTable from './simpletable/SimpleTable'


const exclude_groups = [
  'Media',
  'Photos',
  'Header Images',
  'Logo',
  'Floor Plans',
  'Videos',
  'Private Documents',
  'Branch Images',
  'Profile Picture',
  'Website Documents',
  'Publish',
  'Google Mapping'
]


class PrintPage extends React.Component {
  constructor(props) {
    super(props)
    this.timers = {}
    this.state = {
      required: false,
      collapsed: false,
      redirect: false,
      scrollTop: null,
      initvals: false,
      showJump: false,
      selectedGroup: {},
      currentGroup: {},
      sticky: false,
      offset: 0,
      active_portals: Object.keys(props.portals).map(pk => getIn(props, `portals.${pk}.meta.portal.slug`)).filter(slug => slug)
    }
    this.isConditional = isConditional.bind(this)
    document.title = props.model.web_ref
  }

  componentDidUpdate() {
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + 66 + 64
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  render() {
    const listingstats = [
      'rfs_ac',
      'rtl_ac',
      'cfs_ac',
      'ctl_ac',
      'rd',
      'cd',
      're',
      'ce',
      'h_ac'
    ]

    const {
      model,
      config,
      cache,
      actions,
      user,
      match
    } = this.props
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = { ...getIn(cache, `settings.${selected_site}.meta.statistics`, {}) }

    const { portals: allportals, region } = cache.settings[user.agent.site.id]

    listingstats.forEach(stat => {
      sitestats[`${stat}_avg`] = sitestats.active_branches ? Math.round(sitestats[stat] / sitestats.active_branches) : 0
    })
    const active = []
    if (model.meta.portals) {
      model.meta.portals.filter(p => p.active === true).forEach(p => {
        const portal = Object.keys(allportals.global).map(gp => allportals.global[gp]).find(gp => gp.id === p.portal)
        let index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portal.slug) // Always put these 3 portals first
        if (index === -1) {
          index = Object.keys(allportals.global).length + portal.order // Put all other portals after
        }
        active.push({ portal: p.portal, index })
      })
    }
    return (
      <>
        <div className="model-details">
          <div className="header-section" style={{
            backgroundColor: cache.settings[user.agent.site.id].brand_logo_bg,
            color: cache.settings[user.agent.site.id].reports_brochure_logo_text_color
          }}>
            <div className="logo">
              <img alt="Print Card Logo" src={valueFormat('image_url', { file: cache.settings[user.agent.site.id].meta.brand_logo.file })} />
            </div>
            <div className="address-date">
              <div className="address">{model.street_number} {model.street_name}</div>
              <div className="datetime">{valueFormat('datetime', getIn(model, 'created')) || '-'}</div>
            </div>
          </div>
          <div className="stepper"></div>
          { Object.keys(config.fieldgroups).filter(group => !exclude_groups.includes(group)).map((group, gidx) => {
            const fields = uniqueArray(
              config.fields.filter(field => field.group === group && ![ null, undefined ].includes(
                getIn(model, field.name, getIn(model.meta, field.name))
              )),
              'name'
            )
            if (fields.length) {
              let heading = group
              const id = slugify(group)
              if (model.listing_type === 'To Let' && group === 'Seller / Landlord Details') {
                heading = 'Landlord Details'
              } else if (model.listing_type === 'For Sale' && group === 'Seller / Landlord Details') {
                heading = 'Seller Details'
              }
              const consent = getIn(model, 'meta.consent')
              if (group === 'Communication Preferences' && !consent) {
                return null
              }
              return (
                <Card
                  header={
                    <h3>{heading}</h3>
                  }
                  classes={this.props.currentGroup.id === id ? 'active' : ''}
                  id={id}
                  background
                  key={`g-${gidx}`}

                  body={() => {
                    if (group === 'Communication Preferences') {
                      return (
                        <SimpleTable
                          action={({ params, resolve, reject }) => {
                            if (params.id) {
                              return new Promise((res, rej) => {
                                actions.fetchActivity(params, res, rej)
                              }).then(results => {
                                const data = [
                                  {
                                    id: 'consent_messages',
                                    communication: 'SMS/Direct Messages',
                                    preference: consent.consent_messages,
                                    consent_given: consent.consent_messages_subscribe_date,
                                    consent_removed: consent.consent_messages_unsubscribe_date,
                                    agent: getIn(results.find(r => isEqual(new Date(getIn(r, 'diff.consent_messages_subscribe_date.1')), new Date(consent.consent_messages_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_emails',
                                    communication: 'Emails',
                                    preference: consent.consent_emails,
                                    consent_given: consent.consent_emails_subscribe_date,
                                    consent_removed: consent.consent_emails_unsubscribe_date,
                                    agent: getIn(results.find(r => isEqual(new Date(getIn(r, 'diff.consent_emails_subscribe_date.1')), new Date(consent.consent_emails_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_calls',
                                    communication: 'Phone Calls',
                                    preference: consent.consent_calls,
                                    consent_given: consent.consent_calls_subscribe_date,
                                    consent_removed: consent.consent_calls_unsubscribe_date,
                                    agent: getIn(results.find(r => isEqual(new Date(getIn(r, 'diff.consent_calls_subscribe_date.1')), new Date(consent.consent_calls_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_information',
                                    communication: 'Hold and Process Data',
                                    preference: consent.consent_information,
                                    consent_given: consent.subscribe_date,
                                    consent_removed: consent.unsubscribe_date,
                                    agent: getIn(results.find(r => isEqual(new Date(getIn(r, 'diff.subscribe_date.1')), new Date(consent.subscribe_date))), 'agent')
                                  }
                                ]
                                const agent_ids = data.filter(r => r.agent).map(r => r.agent)
                                if (agent_ids.length) {
                                  new Promise((res2, rej2) => {
                                    actions.fetchMany({
                                      values: {
                                        conflict: true,
                                        modelname: 'agents',
                                        params: {
                                          id__in: agent_ids,
                                          get_all: 1
                                        }
                                      },
                                      resolve: res2,
                                      reject: rej2
                                    })
                                  }).then(agents => {
                                    const agent_data = data.map(r => {
                                      const agent = agents.options.find(a => a.id === r.agent)
                                      if (!agent) { return null }
                                      return {
                                        ...r,
                                        agent: agent ? (
                                          <NavLink className="has-link" to={`/secure/${model.site}/agents/${model.id}`}>{`${agent.first_name} ${agent.last_name}`}</NavLink>
                                        ) : `${model.first_name} ${model.last_name}`
                                      }
                                    }).filter(f => f)
                                    resolve({ options: agent_data, hasMore: false })
                                  }).catch(e => {
                                    console.error(e)
                                    reject(e.toString())
                                  })
                                } else {
                                  resolve({ options: data.map(r => ({ ...r, agent: `${model.first_name} ${model.last_name}` })), hasMore: false })
                                }
                              }).catch(e => {
                                const data = [
                                  {
                                    id: 'consent_messages',
                                    communication: 'SMS/Direct Messages',
                                    preference: consent.consent_messages,
                                    consent_given: consent.consent_messages_subscribe_date,
                                    consent_removed: consent.consent_messages_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_emails',
                                    communication: 'Emails',
                                    preference: consent.consent_emails,
                                    consent_given: consent.consent_emails_subscribe_date,
                                    consent_removed: consent.consent_emails_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_calls',
                                    communication: 'Phone Calls',
                                    preference: consent.consent_calls,
                                    consent_given: consent.consent_calls_subscribe_date,
                                    consent_removed: consent.consent_calls_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_information',
                                    communication: 'Hold and Process Data',
                                    preference: consent.consent_information,
                                    consent_given: consent.subscribe_date,
                                    consent_removed: consent.unsubscribe_date
                                  }
                                ]
                                console.error(e)
                                resolve({ options: data.map(r => ({ ...r, agent: null })), hasMore: false })
                              })
                            }
                            return reject('No ID supplied')
                          }}
                          params= {{
                            order_by: '-created',
                            modelname: 'contactconsent',
                            id: consent ? consent.id : null,
                            get_all: 1
                          }}
                          config={{
                            modelname: 'consent',
                            singular: 'communication preference',
                            plural: 'communication preferences'
                          }}
                          header={[
                            {
                              label: 'Communication',
                              name: 'communication'
                            },
                            {
                              label: 'Preference',
                              name: 'preference',
                              format: 'yesno'
                            },
                            {
                              label: 'Consent Given',
                              name: 'consent_given',
                              format: 'datetime'
                            },
                            {
                              label: 'Consent Removed',
                              name: 'consent_removed',
                              format: 'datetime'
                            },
                            {
                              label: 'Consent Confirmed By',
                              name: 'agent'
                            }
                          ]}
                        />
                      )
                    }
                    const group_fields = uniqueArray(config.fields.filter(field => {
                      if (field.group === group) {
                        if (field.input === 'Hidden' && ![ 'country_code', 'property_size_total_size', 'property_size_total_cost', 'land_use_total_size' ].includes(field.name)) { return false }
                        if (![ null, undefined ].includes(field.edit) || Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
                          if (!this.isConditional(field, 'edit', false, { values: model }, user)) { return false }
                        }
                        return true
                      }
                      return false
                    }), 'name').map((field, fidx) => {
                      let value = getIn(model, field.name, getIn(model.meta, field.name))
                      let url = false
                      let disable = false
                      if (field.input === 'Extras') {
                        let options = merge([], extras.options)
                        let portals = []
                        if (allportals && allportals.agency && allportals.global) {
                          portals = allportals.agency.map(p => {
                            const pconf = allportals.global.find(pc => pc.id === p.portal)
                            return pconf
                          })
                          portals = sortBy(portals, 'id')
                          portals = portals.map(po => po.slug)
                        }
                        options = options
                          .map(o => {
                            if (o.options) {
                              o.options = [ ...o.options ]
                                .map(so => {
                                  so.group = o.label
                                  return so
                                })
                                .filter(so => (so.portals ? so.portals.some(p => portals.includes(p)) : true))
                            }
                            if (o.portals) {
                              o.portals = o.portals.filter(p => portals.includes(p))
                            }
                            return o
                          })
                          .filter(o => {
                            if (o.portals) {
                              return o.portals.some(p => portals.includes(p))
                            }
                            if (o.options) {
                              return o.options.some(so => so.portals.some(p => portals.includes(p)))
                            }
                            return true
                          })


                        let vals = []
                        if (value) {
                          vals = value.split('\n')
                        }
                        if (vals.length) {
                          return (
                            <div key={`extras-list-${fidx}`} className="meta-detail extras">
                              <label className="heavy">{field.label}</label>
                              <div className="col">
                                {vals.map((v, idx) => {
                                  const extra_val = options.find(o => v === o.value)
                                  const tags = extra_val ? extra_val.portals.map(p => extras.legend[p]) : []
                                  return (
                                    <div key={`extras-${idx}`} className="extras-selected">
                                      <div className="extras-label">
                                        {extra_val ? extra_val.label : v}
                                      </div>
                                      <div className="extras-tags">
                                        {tags.map((t, tidx) => (
                                          <span className={`extras-tag ${t}`} key={`extras-tag-${tidx}`}>{t}</span>
                                        ))}
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        }
                      }

                      if ([ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns' ].includes(field.input)) {
                        const values = field.metafield ? getIn(model.meta, field.name) : getIn(model, field.name)
                        if (!values || !Array.isArray(values)) { return null }
                        const array_fields = values.map((v, vid) => {
                          const portal = allportals.global.find(p => p.id === v.portal)
                          const inner = field.fields.map(f => {
                            url = false
                            let val = v.meta && v.meta[f.name] ? v.meta[f.name] : v[f.name]
                            let label = `${f.label}`
                            if ([ 'id', 'portal' ].includes(f.name)) { return null }
                            if (field.name === 'portals') {
                              label = `${portal.name} ${f.label}`
                            }
                            if (field.activitygroup) {
                              if (f.name === field.activitygroup && field.input === 'SizeBreakdowns') {
                                return <h4 key={`h4-sizebreakdowns-${vid}`} className="col-lg-12">{val}</h4>
                              }
                              if (f.name === field.activitygroup && field.input === 'LandUseBreakdowns') {
                                return <h4 key={`h4-landusebreakdowns-${vid}`} className="col-lg-12">{val}</h4>
                              }
                            }
                            if (f.format === 'currency') { f.currency = cache.settings[user.agent.site.id].default_currency }
                            if ([ null, undefined ].includes(val)) { return null }
                            if (Array.isArray(val) && !val.length) { return null }
                            if (typeof val === 'object') { // Most likely references a meta object
                              const d = buildOptionLabel(f, val)
                              val = d.label
                              if (f.directLink) {
                                url = parseURL(f.directLink, {
                                  id: v[f.name],
                                  site: model.site
                                })
                                if (Array.isArray(url) && url.length === 1) {
                                  url = url[0]
                                }
                              }
                            }
                            return (
                              <MetaDetail
                                className={f.detailCols ? `col-${f.detailCols}` : `${f.cols ? `col-${f.cols}` : 'col-12'}`}
                                key={`g-${gidx}-f${fidx}-o${vid}-${f.name}`}
                                label={label}
                                value={val}
                                format={f.format}
                                field={f}
                                url={url}
                              />
                            )
                          }).filter(node => node)
                          const groups = []
                          let current_group = []
                          let count = 12
                          inner.forEach((node, idx) => {
                            const classes = node.props && node.props.className ? node.props.className : ''
                            const regex = /col(-[a-z]+)?-(\d+)/ig
                            const matches = regex.exec(classes)
                            current_group.push(node)
                            if (matches) {
                              const col = parseInt(matches.pop(), 10)
                              count -= col
                            }
                            if (count <= 0 || idx === inner.length - 1) {
                              groups.push(<div key={`col-${gidx}-${idx}`} className='meta-group'>{current_group}</div>)
                              current_group = []
                              count = 12
                            }
                          })
                          return groups.length ? groups : null
                        }).filter(node => node)
                        const array_groups = []
                        array_fields.forEach((node, idx) => {
                          array_groups.push(<div key={`col-${gidx}-${idx}`} className='meta-group array-group'>{node}</div>)
                        })
                        return array_groups.length ? array_groups : null
                      }

                      if (field.name === 'coordinates' && model.map_x_position && model.map_y_position) {
                        value = `Lat: ${model.map_x_position}, Lng: ${model.map_y_position}`
                      }

                      if (field.modelname && field.optionlabel) {
                        let values = actions.renderRelated(field.modelname, field.name, field.optionlabel)
                        if (!values && model.meta[field.name]) { // Not allowed, but exists in meta
                          values = actions.parseMeta(model, field)
                          disable = true
                        }
                        if (field.multi && field.directLink) {
                          url = model[field.name] && model[field.name].map(v => parseURL(field.directLink, {
                            id: v,
                            site: model.site
                          }))
                          if (Array.isArray(url) && url.length === 1) {
                            url = url[0]
                          }
                        }
                        if (field.multi && field.input === 'ContactLookup') {
                          const inner = value.map((contact, vid) => {
                            let meta_values = []
                            meta_values = actions.parseMeta(model, field)
                            const meta = model.meta[field.name]
                            const val = meta[vid]
                            if (field.read && !this.isConditional(field, 'read', false, { values: model.meta[field.name][vid] }, user)) { disable = true }
                            if (meta_values.includes(val) && !values.includes(val)) {
                              disable = true
                            } else {
                              disable = false
                            }
                            let label = field.label
                            if (label === 'Link Contact') {
                              label = 'Linked Contact'
                            }
                            return <ContactDetails
                              key={`g-${gidx}-f${fidx}-o${vid}`}
                              contact={val}
                              label={`${label} ${vid + 1}`}
                              actions={actions}
                              disabled={disable}
                              region={region}
                              match={match}
                              user={user}
                              model={model}
                            />
                          }).map(node => node)
                          const groups = []
                          let current_group = []
                          let count = 12
                          inner.forEach((node, idx) => {
                            const classes = node.props && node.props.className ? node.props.className : ''
                            const regex = /col(-[a-z]+)?-(\d+)/ig
                            const matches = regex.exec(classes)
                            current_group.push(node)
                            if (matches) {
                              const col = parseInt(matches.pop(), 10)
                              count -= col
                            }
                            if (count <= 0 || idx === inner.length - 1) {
                              groups.push(<div key={`col-${gidx}-${idx}`} className='linked-contact meta-group col-lg-12'>{current_group}</div>)
                              current_group = []
                              count = 12
                            }
                          })
                          return groups.length ? groups : null
                        } else if (field.input === 'ContactLookup') {
                          let meta_values = []
                          meta_values = actions.parseMeta(model, field)
                          const val = model.meta[field.name]
                          if (field.read && !this.isConditional(field, 'read', false, { values: model.meta[field.name] }, user)) { disable = true }
                          if (meta_values.includes(val) && !values.includes(val)) {
                            disable = true
                          } else {
                            disable = false
                          }
                          let label = field.label
                          if (label === 'Link Contact') {
                            label = 'Linked Contact'
                          }
                          return (
                            <div key={`contacts-contact-${field.name}`} className='linked-contact meta-group col-lg-12'>
                              <ContactDetails
                                key={`g-${gidx}-f${fidx}-o${field.name}`}
                                contact={val}
                                label={label}
                                actions={actions}
                                disabled={disable}
                                region={region}
                                match={match}
                                user={user}
                                model={model}
                              />
                            </div>
                          )
                        } else if (values && field.labelseparator && Array.isArray(value) && !url) {
                          if (!Array.isArray(values)) {
                            values = [ values ]
                          }
                          if (!field.directLink) {
                            if (!Array.isArray(field.optionlabel)) {
                              value = values.join(field.labelseparator || ' ')
                            } else {
                              value = values.join(', ')
                            }
                          }
                        } else {
                          value = values
                          if (values && field.directLink && !Array.isArray(values)) {
                            value = values.split(', ')
                          }
                        }
                      }
                      if (field.link && !url && !disable) { url = parseURL(field.link, model) }
                      if (field.format === 'listing_popup') {
                        field.currency = cache.settings[user.agent.site.id].default_currency
                        value = model
                        if (getIn(model, 'meta.listing.web_ref')) {
                          value = getIn(model, 'meta.listing')
                        }
                      }
                      if (field.format === 'measurement_type') {
                        field.measurement_type = model[`${field.name}_measurement_type`]
                      }
                      if (field.name === 'land_size_measurement_type' && !model.land_size) {
                        value = null
                      }
                      if (field.name === 'floor_size_measurement_type' && !model.floor_size) {
                        value = null
                      }
                      if (field.format === 'currency') { field.currency = cache.settings[user.agent.site.id].default_currency }

                      if (field.format === 'domstring' && !value) {
                        value = null
                      }
                      if (field.format === 'absolute_url' && field.input === 'FileDropzone' && !field.multi) {
                        url = getIn(model, `meta.${field.name}.file`)
                        value = getIn(model, `meta.${field.name}.caption`)
                      }
                      if (field.format === 'stage') {
                        if (!Array.isArray(value) && typeof value !== 'object') {
                          value = model
                        }
                      }
                      if (field.multi) {
                        if (!value || (Array.isArray(value) && !value.length)) {
                          value = null
                        }
                      }
                      if (Array.isArray(value) && !field.modelname) {
                        value = value.join(field.labelseparator || ', ')
                      }
                      if ([ 'user' ].includes(field.name)) {
                        value = getIn(model, 'meta.user.email')
                      }
                      if ([ 'pending_user' ].includes(field.name)) {
                        value = getIn(`${getIn(model, 'meta.pending_user.email')} (Pending)`)
                      }
                      if ([ null, undefined, '' ].includes(value)) { return null }
                      if (Array.isArray(value) && !value.length) { return null }
                      return (
                        <MetaDetail
                          key={`g-${gidx}-f${fidx}`}
                          className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-12'}`}
                          label={field.label}
                          value={value}
                          format={field.format}
                          field={field}
                          url={url}
                        />
                      )
                    }).filter(node => node)
                    const groups = []
                    let current_group = []
                    let count = 12
                    group_fields.forEach((node, idx) => {
                      const classes = node.props && node.props.className ? node.props.className : ''
                      const regex = /col(-[a-z]+)?-(\d+)/ig
                      const matches = regex.exec(classes)
                      current_group.push(node)
                      if (matches) {
                        const col = parseInt(matches.pop(), 10)
                        count -= col
                      }
                      if (count <= 0 || idx === group_fields.length - 1) {
                        groups.push(<div key={`col-${gidx}-${idx}`} className='meta-group'>{current_group}</div>)
                        current_group = []
                        count = 12
                      }
                    })
                    return groups.length ? groups : null
                  }}
                />
              )
            }
            return null
          }) }
          <div className="publish">
            <Card
              background
              header={
                <h3>Publication Settings</h3>
              }
              body={
                <div className="input-group publish-group">
                  <MetaDetail
                    className="col-lg-6"
                    label="Website / Mobi Site"
                    value={valueFormat('yesno', model.display_on_website)}
                    url_print={model.meta.url.website}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Branch Microsite"
                    value={valueFormat('yesno', model.display_on_branch)}
                    url_print={model.meta.url.branch_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Team Microsite"
                    value={valueFormat('yesno', model.display_on_team)}
                    url_print={model.meta.url.team_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Agent Microsite"
                    value={valueFormat('yesno', model.display_on_agent)}
                    url_print={model.meta.url.agent_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Agent 2 Microsite"
                    value={valueFormat('yesno', model.display_on_agent_2)}
                    url_print={model.meta.url.agent_2_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Agent 3 Microsite"
                    value={valueFormat('yesno', model.display_on_agent_3)}
                    url_print={model.meta.url.agent_3_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed To Agent 4 Microsite"
                    value={valueFormat('yesno', model.display_on_agent_4)}
                    url_print={model.meta.url.agent_4_site_url}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Feed to Portals"
                    value={valueFormat('yesno', model.feed_to_portals)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Featured Property"
                    value={valueFormat('yesno', model.featured)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Email Newsletter"
                    value={valueFormat('yesno', model.feature_on_newsletter)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Featured On Agent Newsletter"
                    value={valueFormat('yesno', model.feature_on_agent_newsletter)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Disable Bond Repayment Calculator"
                    value={valueFormat('yesno', model.disable_repayment_calulator)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Disable Bond Costs Calculator"
                    value={valueFormat('yesno', model.disable_cost_calulator)}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label="Disable Transfer Costs Calculator"
                    value={valueFormat('yesno', model.disable_transfer_calulator)}
                  />
                </div>
              }
            />
            {model.model !== 'project' ? (
              <>
                <Card
                  background
                  header={
                    <h3>Active Portals</h3>
                  }
                  bodyclass="cardbody portals publish-portals"
                  body={
                    <div className="input-group">
                      { (!model.feed_to_portals && active.length > 0) ? (
                        <Tip heading='Note' text='"Feed to Portals" for this listing is currently set to No' />
                      ) : null }
                      { active.length > 0 ?
                        active.map((pid, pidx) => {
                          const p = model.meta.portals.find(g => g.portal === pid.portal)
                          const portal = allportals.global.find(g => g.id === p.portal)
                          let { slug, name } = portal
                          if (!portal.model_types.includes(model.model)) { return null }
                          if (slug === 'eos3') { return null }
                          if (slug === 'property24') {
                            return (
                              <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                                <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                                  <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                                </div>
                                <div className="portal-fields">
                                  <div className="meta-detail">
                                    <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_feature_notes)}</span>
                                    <span className='portal-field-label'>Publish Feature Notes</span>
                                  </div>
                                  <div className="meta-detail">
                                    <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                                    <span className='portal-field-label'>Publish Street Address</span>
                                  </div>
                                  {p.project_id &&
                                        <div className="meta-detail">
                                          <label className='heavy flex-full'>Project ID</label>
                                          <span className="portal-field-value flex-full">{p.project_id}</span>
                                        </div>
                                  }
                                  {p.expiration_date &&
                                        <div className="meta-detail">
                                          <label className='heavy flex-full'>Expiration Date</label>
                                          <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                        </div>
                                  }
                                </div>
                              </div>
                            )
                          }
                          if (slug === 'private-property') {
                            return (
                              <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                                <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                                  <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                                </div>
                                <div className="portal-fields">
                                  <div className="meta-detail">
                                    <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                                    <span className='portal-field-label'>Publish Street Address</span>
                                  </div>
                                  {p.expiration_date &&
                                        <div className="meta-detail">
                                          <label className='heavy flex-full'>Expiration Date</label>
                                          <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                        </div>
                                  }
                                </div>
                              </div>
                            )
                          }
                          if (slug === 'gumtree') {
                            return (
                              <React.Fragment key={`gumtree-${pidx}`}>
                                <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                                  <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                                    <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                                  </div>
                                  <div className="portal-fields">
                                    <div className="meta-detail">
                                      <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_feature_notes)}</span>
                                      <span className='portal-field-label'>Publish Feature Notes</span>
                                    </div>
                                    <div className="meta-detail">
                                      <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                                      <span className='portal-field-label'>Publish Street Address</span>
                                    </div>
                                    {p.expiration_date ? (
                                      <div className="meta-detail">
                                        <label className='heavy flex-full'>Expiration Date</label>
                                        <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          }
                          if (slug === 'commercial-people' && model.model === 'residential') {
                            slug = 'residential-people'
                            name = 'Residential People'
                          }
                          return (
                            <div key={`portal-active-${p.portal}-${p.id}`} className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                              <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={name} />
                            </div>
                          )
                        }) : (
                          <div key="portal-none">
                                There are no portals active for this listing.
                          </div>
                        )
                      }
                    </div>
                  }
                />
              </>
            ) : null}
          </div>
          {![ 'holiday' ].includes(config.modelname) ?
            <>
              <MediaGallery
                media={[
                  {
                    items: model.meta.listing_images,
                    mediatype: 'image',
                    fieldname: 'listing_images',
                    plural: 'images',
                    label: 'Images'
                  }, {
                    items: model.meta.floor_plans,
                    mediatype: 'image',
                    fieldname: 'floor_plans',
                    plural: 'floor plans',
                    label: 'Floor Plans'
                  }, {
                    items: model.meta.header_images,
                    mediatype: 'image',
                    fieldname: 'header_images',
                    plural: 'header images',
                    label: 'Header Images'
                  }, {
                    items: model.meta.private_documents,
                    mediatype: 'documents',
                    fieldname: 'private_documents',
                    plural: 'private documents',
                    label: 'Private Documents'
                  },
                  {
                    items: model.meta.documents,
                    mediatype: 'documents',
                    fieldname: 'documents',
                    plural: 'website documents',
                    label: 'Website Documents'
                  }
                ]}
                actions={{
                  downloadImages: actions.downloadImages,
                  isConditional: this.isConditional
                }}
                config={config}
                user={user}
                model={model}
                modelname={config.modelname}
                is_print={true}
              />
              <Card
                background
                header={
                  <h3>Multimedia</h3>
                }
                body={() => {
                  const field = config.fields.find(f => f.name === 'video_id')
                  const mockform = {
                    touched: { [field.name]: true },
                    values: { [field.name]: model ? model[field.name] : null }
                  }
                  if (field.show && Array.isArray(field.show)) {
                    field.show.map(s => s.map(condition => (
                      mockform.values[condition.field] = model[condition.field])
                    ))
                  }
                  return (model.video_id || model.virtual_tour || model.eyespy360 || model.matterport_id) ? (
                    <>
                      {model.video_id && model.video_streaming_platform === 'YouTube' &&
                          <React.Fragment>
                            <h4 className="section-underline">Youtube</h4>
                            <div className="mg-collative-img-size">&nbsp;</div>
                            <div className='player-container'>
                              <div className='player-wrapper'>
                                <ReactPlayer
                                  className='react-player'
                                  url={`https://www.youtube.com/watch?v=${model.video_id}`}
                                  controls
                                  width='100%'
                                  height='100%'
                                  config={{
                                    youtube: {
                                      playerVars: {
                                        showinfo: 0,
                                        modestbranding: 1,
                                        rel: 0
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                      }
                      {model.video_id && model.video_streaming_platform === 'Vimeo' &&
                          <React.Fragment>
                            <h4 className="section-underline">Vimeo</h4>
                            <div className='player-container'>
                              <div className='player-wrapper'>
                                <ReactPlayer
                                  className='react-player'
                                  url={`https://www.vimeo.com/${model.video_id}`}
                                  controls
                                  width='100%'
                                  height='100%'
                                  config={{
                                    vimeo: {
                                      playerVars: {
                                        responsive: true
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                      }
                      {model.virtual_tour &&
                          <React.Fragment>
                            <h4 className="section-underline">Virtual Tour</h4>
                            <div className='player-container'>
                              {valueFormat('link', model.virtual_tour, { target: '_blank' })}
                            </div>
                          </React.Fragment>
                      }
                      {model.eyespy360 &&
                          <React.Fragment>
                            <h4 className="section-underline">EyeSpy360</h4>
                            <div className='player-container'>
                              {valueFormat('link', model.eyespy360, { target: '_blank' })}
                            </div>
                          </React.Fragment>
                      }
                      {model.matterport_id &&
                          <React.Fragment>
                            <h4 className="section-underline">Matterport</h4>
                            <div className='player-container'>
                              {valueFormat('link', `https://my.matterport.com/show/?m=${model.matterport_id}`, { target: '_blank' })}
                            </div>
                          </React.Fragment>
                      }
                    </>
                  ) : (
                    <div className="mediatype">
                      <div>
                          There are no videos or virtual tours for this {config.singular}.&nbsp;
                      </div>
                    </div>
                  )
                }}
              />
            </>
            : null }
        </div>
      </>
    )
  }
}

PrintPage.propTypes = {
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  currentGroup: PropTypes.object,
  portals: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object
}

export default PrintPage
