import merge from 'deepmerge'
import { Field, Form, Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import isEqual from 'react-fast-compare'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'


import log from '../../../logging'
import { hasPermission, reorder, uniqueArray } from '../../../utils'
import { Button } from '../../ui/Button'
import { Scrollbar } from '../../ui/Scrollbars'
import Sidebar from '../../ui/sidebar/Sidebar'
import SelectInput from '../forms/inputs/Select'
import TextInput from '../forms/inputs/Text'
import Slider from '../../common/Slider'
import PreferenceList from './preferences/PreferenceList'


const DragHandle = listeners => <Button {...listeners} type="button" icon="#icon24-HandleVertical" className="btn btn-icon-24 btn-icon-only btn-text drag-handle" />

const Item = ({ item }) => {
  let label = item.label
  if (item.tablelabel) { label = item.tablelabel }
  return (
    <div className="table-column-draggable protected">
      <span>
        {label}
      </span>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object
}

const SortableItem = ({ item, removeTableField }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: item.name.toString() })
  const style = {
    transform: CSS.Translate.toString(transform)
  }

  let label = item.label
  if (item.tablelabel) { label = item.tablelabel }
  return (
    <div className="table-column-draggable" ref={setNodeRef} style={style} {...attributes}>
      <DragHandle {...listeners} />
      <span>
        {label}
      </span>
      <Button icon="#icon24-X-Small" type="button" className="btn btn-text btn-icon-24 column-remover" onClick={() => removeTableField(item.name)} />
    </div>
  )
}

SortableItem.propTypes = {
  item: PropTypes.object,
  removeTableField: PropTypes.func
}

const ListItem = props => {
  const [ open, setOpen ] = useState(true)
  const sliderref = useRef()
  const { item, index, removeTableField, onDragEnd } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: item.name.toString() })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  let label = item.label
  if (item.tablelabel) { label = item.tablelabel }
  return (
    <div className={`table-column-draggable has-droppable${open ? ' open' : ''}`} ref={setNodeRef} style={style} {...attributes}>
      <DragHandle {...listeners} />
      <span>{label}</span>
      <Button icon="#icon24-ChevronDown" type="button" className="btn btn-text btn-icon-24 column-remover" onClick={() => setOpen(!open)} />
      <Slider
        closed={!open}
        classes="table-column-dropper"
        id={'slider-listitem-tablemanager'}
        childref={sliderref.current || sliderref}
      >
        <div ref={sliderref}>
          <FieldList
            id={`list-${index}`}
            key={`list-${index}`}
            removeTableField={removeTableField}
            onDragEnd={onDragEnd}
            items={item.children}
          />
        </div>
      </Slider>
    </div>
  )
}

ListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  level: PropTypes.any,
  onDragEnd: PropTypes.func,
  removeTableField: PropTypes.func
}

// const SortableListItem = sortableElement(ListItem)

const FieldList = props => {
  const { items, removeTableField, onDragEnd, id } = props
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )
  try {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          id={id}
          strategy={verticalListSortingStrategy}
          items={items.map(i => i.name.toString())}
        >
          <div>
            {items && items.map((item, index) => {
              if (item.children) {
                return <ListItem key={`list-${index}`} index={index} item={item} onDragEnd={onDragEnd} removeTableField={removeTableField} />
              }
              if (item.name === 'id') {
                return <Item item={item} key={item.name} removeTableField={removeTableField}/>
              }
              return <SortableItem item={item} key={`item-${index}`} index={index} removeTableField={removeTableField}/>
            }) }
          </div>
        </SortableContext>
      </DndContext>
    )
  } catch (e) {
    return null
  }
}

FieldList.propTypes = {
  items: PropTypes.array,
  level: PropTypes.any,
  onDragEnd: PropTypes.func,
  removeTableField: PropTypes.func
}

class TableManager extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      items: {},
      fields: [],
      reset: false
    }
    this.el = document.createElement('div')
    this.el.classList.add('table-manager-dropdown')
    this.root = document.getElementById('content')
    this.onDragEnd = this.onDragEnd.bind(this)
    this.checkFields = this.checkFields.bind(this)
    this.updateTableConfig = this.updateTableConfig.bind(this)
    this.removeTableField = this.removeTableField.bind(this)
    this.renderManager = this.renderManager.bind(this)
  }

  componentDidMount() {
    this.root = document.getElementById('content')
    const { preferences: column_preferences } = this.props
    this.original_preferences = merge({}, column_preferences)
    this.checkFields()
  }

  componentDidUpdate(prevProps) {
    const { modelname, preferences: column_preferences } = this.props
    if (prevProps.modelname !== modelname) {
      this.original_preferences = merge({}, column_preferences)
      this.setState({ reset: false })
    }
    if (!isEqual(this.original_preferences, column_preferences) && !this.state.reset) {
      this.setState({ reset: true })
    } else if (isEqual(this.original_preferences, column_preferences) && this.state.reset) {
      this.setState({ reset: false })
    }
    this.checkFields()
  }

  onDragEnd(event) {
    const oldIndex = event.active.data.current.sortable.index
    const newIndex = event.over.data.current.sortable.index
    const collection = event.over.data.current.sortable.containerId.includes('list-') ? event.over.data.current.sortable.containerId.match(/\d+/)[0] : null
    const { modelname, user, actions } = this.props
    const { column_preferences } = user.preferences[modelname]
    const newstate = merge({}, column_preferences)
    if (!collection) {
      const children = reorder(
        newstate,
        oldIndex,
        newIndex
      )
      actions.updateTableConfig(modelname, children)
    } else {
      const parent = newstate[collection].children
      const children = reorder(
        parent,
        oldIndex,
        newIndex
      )
      newstate[collection].children = children
      actions.updateTableConfig(modelname, newstate)
    }
  }

  updateTableConfig(pref_id) {
    const { modelname, user, actions } = this.props
    if (!modelname) { return false }
    const { column_preferences } = user.availablePreferences[modelname][pref_id]
    const preferences = merge({}, column_preferences)

    // Update view immediately
    actions.updateTableConfig(modelname, preferences)
    // Save selected view
    return new Promise((resolve, reject) => {
      let data = {}
      if (pref_id === 0) {
        const active_pref = user.preferences[modelname].id
        data = {
          modelname,
          id: active_pref,
          active: false
        }
      } else {
        data = {
          modelname,
          id: pref_id,
          active: true
        }
      }
      actions.updatePreference({ ...data, resolve, reject })
    }).catch(e => {
      actions.notifyUser({
        title: 'Unable to update table',
        body: e.toString(),
        type: 'error'
      })
      log.error(e)
    })
  }

  removeTableField(field) {
    const { modelname, actions } = this.props
    return new Promise((resolve, reject) => {
      this.props.actions.removeTableField({ values: { modelname, field }, resolve, reject })
    }).catch(e => {
      actions.notifyUser({
        title: 'Unable to update table',
        body: e.toString(),
        type: 'error'
      })
      log.error(e)
    })
  }

  checkFields() {
    const { config, preferences, user } = this.props
    const { permissions } = user
    let used_fields = []
    preferences.forEach(field => {
      const fe = merge({}, field)
      if (fe.children) {
        used_fields = used_fields.concat(fe.children.map(f => {
          if (Array.isArray(f.name)) {
            return JSON.stringify(f.name)
          }
          return f.name
        }))
      }
      used_fields.push(Array.isArray(fe.name) ? JSON.stringify(fe.name) : fe.name)
    })
    const fields = uniqueArray((config.fields || []).filter(field => {
      const fname = Array.isArray(field.name) ? JSON.stringify(field.name) : field.name
      return !used_fields.includes(fname) && // Only actual fields
        field.label && // No non-labeled fields
        !field.children && // No parents allowed!
        field.name !== 'id' && // ID is not an option
        field.input !== 'SectionHeading' && // No section headings
        field.input !== 'Button' && // No section headings
        !(field.classes && field.classes.includes('grouper')) &&
        !field.protected && // Protected from use in components such as TableManager
        hasPermission(field.permissions, permissions) // Perms?
    }).sort((a, b) => a.label.localeCompare(b.label) // Sort alphabetically
    ).map(field => {
      // stringify values to handle arrays grabbing
      // first matching field value only
      const f = merge({}, field)
      const label = getIn(f, 'tablelabel', f.label)
      const option = {
        value: Array.isArray(f.name) ? JSON.stringify(f.name) : f.name,
        label
      }
      return option
    }), 'value')
    if (!isEqual(fields, this.state.fields)) {
      this.setState({ fields })
    }
  }

  renderManager() {
    const { modelname, user, actions, preferences } = this.props
    const availablePreferences = modelname ? user.availablePreferences[modelname] : []
    /*
    *  Calculate which fields already exist in the table and exclude them from
    *  the list of available options in select
    */
    const column_preferences = preferences
    return (
      <div id="table-manager-sidebar" className="table-manager-sidebar sidebar-right">
        <div className="table-manager-container sidebar-view">
          <div className="table-manager-content">
            <div className="table-manager-pane">
              <div className="table-manager-heading">
                <h3>Manage Columns</h3>
                <Button type="button" icon="#icon24-X-Large" className="btn-table-manager-close btn btn-text btn-icon-24" onClick={this.props.actions.toggleManager} />
              </div>
              <Scrollbar
                style={{ height: 'calc(100vh - 64px - 68px - 64px)' }}
                renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
              >
                <div className="table-column-list">
                  <div className="table-column-dropper" ref={el => {this.container = el}}>
                    <FieldList
                      onDragEnd={this.onDragEnd}
                      removeTableField={this.removeTableField}
                      items={column_preferences}
                    />
                  </div>
                </div>
                <div className="table-manager-actions">
                  {this.state.reset &&
                    <div className="form-group">
                      <Button
                        type="button"
                        title="Reset Columns"
                        className="btn btn-primary btn-reset"
                        onClick={() => {
                          actions.updateTableConfig(modelname, this.original_preferences)
                          this.setState({ reset: false })
                        }}
                      >
                        Reset Columns
                      </Button>
                    </div>
                  }
                  <Formik
                    initialValues={{ field: null }}
                    onSubmit={(values, { setSubmitting, setStatus, setTouched, setFieldValue }) => {
                      values.modelname = modelname
                      if (values.field.indexOf('[') !== -1) {
                        values.field = JSON.parse(values.field)
                      }
                      return new Promise((resolve, reject) => actions.addTableField({ values, resolve, reject }))
                        .then(() => {
                          setSubmitting(false)
                          setFieldValue('field', null)
                          setTouched({})
                        }).catch(() => {
                          setSubmitting(false)
                          actions.notifyUser({
                            type: 'error',
                            title: 'Error',
                            body: 'Unable to add field'
                          })
                          setStatus({ type: 'error', msg: 'Unable to add field' })
                        })
                    }}
                  >{ formik => (
                      <Form className="table-manager-field-form">
                        <div className="input-btn-addon">
                          <Field
                            id="field-field"
                            name="field"
                            placeholder="Add a Column"
                            match={this.props.match}
                            component={SelectInput}
                            suffix={(
                              <Button
                                type="submit"
                                icon="#icon16-Plus"
                                disabled={ !getIn(formik, 'values.field') || formik.isSubmitting || !formik.errors }
                                className="btn-table-manager-close btn-none btn btn-grey btn-icon-16 input-group-addon"
                              />
                            )}
                            options={this.state.fields}
                          />
                        </div>
                        <div className="message">
                          {formik.isSubmitting && <div className="isloading block"><div className="loader"></div></div> }
                          {(formik.status && !formik.isSubmitting) && <div className="msg warn">{formik.status.msg}</div>}
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <Formik
                    initialValues={{ name: '' }}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting, setStatus, setTouched, resetForm }) => {
                      values.data = this.props.user.preferences[modelname].column_preferences
                      values.modelname = modelname
                      values.agent = this.props.user.agent.id || this.props.user.id
                      return new Promise((resolve, reject) => actions.createPreference({ values, resolve, reject }))
                        .then(() => {
                          setSubmitting(false)
                          setTouched({})
                          resetForm({ values: { name: '' } })
                        }).catch(e => {
                          setSubmitting(false)
                          actions.notifyUser({
                            type: 'error',
                            title: 'Error',
                            body: e
                          })
                          setStatus({ type: 'error', msg: e })
                        })
                    }}
                  >{ formik => (
                      <Form>
                        <div className="input-btn-addon">
                          <Field
                            id="field-name"
                            type="text"
                            name="name"
                            placeholder="Name & Save View"
                            suffix={(
                              <Button
                                type="submit"
                                icon="#icon16-Save"
                                disabled={ !getIn(formik, 'values.name') || formik.isSubmitting || !formik.errors }
                                className="btn-table-manager-close btn-none btn btn-grey btn-icon-16 input-group-addon" />
                            )}
                            component={TextInput}
                          />
                        </div>
                        <div className="message">
                          {formik.isSubmitting && <div className="isloading block"><div className="loader"></div></div> }
                          {(formik.status && !formik.isSubmitting) && <div className="msg error">{formik.status.msg}</div>}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={
                    { preference: (user.preferences[modelname]) ? String(user.preferences[modelname].id) : 0 }
                  }
                  onSubmit={(values, { setSubmitting }) => {
                    this.updateTableConfig(values.preference)
                    setSubmitting(false)
                  }}
                >{formik => (
                    <Form>
                      <h3>Saved Views</h3>
                      {availablePreferences &&
                          <PreferenceList
                            form={formik}
                            preferences={availablePreferences}
                            modelname={modelname}
                            updateTableConfig={this.updateTableConfig}
                            deletePreference={actions.deletePreference}
                          />
                      }
                      <div className="message">
                        {formik.isSubmitting && <div className="isloading block"><div className="loader"></div></div> }
                        {(formik.status && !formik.isSubmitting) && <div className="msg warn">{formik.status.msg}</div>}
                      </div>
                    </Form>
                  )}
                </Formik>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <Sidebar sidebar="showmanager">{this.renderManager()}</Sidebar>
  }
}

TableManager.propTypes = {
  modelname: PropTypes.string,
  config: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  preferences: PropTypes.array
}

export default TableManager
