/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Tooltip, Legend, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'

import { differenceInCalendarDays } from 'date-fns'
import { getRandomColor, valueFormat, groupBy, hasPermission, slugify } from '../../utils'
import BarGraph, { longestLabelLength } from '../ui/graphs/BarGraph'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import withDelay from './withDelay'


const renderColorfulLegendText = value => <span style={{ color: '#5A6F89' }}>{value}</span>

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload, dayCount } = this.props
    if (payload.value === 'auto') { return payload.value }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {dayCount === 13 ? valueFormat('monthyear', new Date(payload.value)) : null}
          {dayCount === 14 ? valueFormat('daymonth', new Date(payload.value)) : null}
          {dayCount === 30 || dayCount === 31 ? valueFormat('day', new Date(payload.value)) : null}
        </text>
      </g>
    )
  }
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dayCount: PropTypes.number,
  payload: PropTypes.object
}


class ListingViewsWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      views_source: '',
      views_period: 30,
      statistics: null,
      results: null,
      days: [],
      dataKeys: [],
      portalKeys: [],
      listing_model: '',
      total_views: 0,
      sigma_total_views: 0
    }
    this.fetchListingStats = this.fetchListingStats.bind(this)
    this.filterListingStats = this.filterListingStats.bind(this)

    this.fetchLeadSources = this.fetchLeadSources.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { user } = this.props
    const listing_options = []
    let default_model
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
      if (!default_model) {
        default_model = { value: 'residential_for_sale', label: 'Residential For Sale' }
      }
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
      if (!default_model) {
        default_model = { value: 'residential_to_let', label: 'Residential To Let' }
      }
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
      if (!default_model) {
        default_model = { value: 'commercial_for_sale', label: 'Commercial For Sale' }
      }
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
      if (!default_model) {
        default_model = { value: 'commercial_to_let', label: 'Commercial To Let' }
      }
    }
    if (hasPermission([
      'listings_project_view',
      'listings_project_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'project', label: 'Project Listings' })
      if (!default_model) {
        default_model = { value: 'project', label: 'Project Listings' }
      }
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'holiday', label: 'Holiday Listings' })
      if (!default_model) {
        default_model = { value: 'holiday', label: 'Holiday Listings' }
      }
    }
    if (this.props.model && [ 'residential', 'holiday', 'project', 'commercial' ].includes(this.props.model.model)) {
      if ([ 'residential', 'commercial' ].includes(this.props.model.model)) {
        default_model = listing_options.find(lo => (
          lo.value.includes(this.props.model.model) && lo.value.includes(slugify(this.props.model.listing_type))
        ))
      } else {
        default_model = listing_options.find(lo => lo.value.includes(this.props.model.model))
      }
    }
    this.setState({ listing_options, default_model, listing_model: default_model ? default_model.value : '' }, () => {
      this.fetchListingStats()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.views_period !== this.state.views_period ||
      prevState.listing_model !== this.state.listing_model
    ) {
      this.fetchListingStats()
    }
    if (prevState.views_source !== this.state.views_source) {
      const { statistics, days, dataKeys, total_views } = this.filterListingStats(this.state.results)
      if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(this.props.config.modelname)) {
        this.fetchLeadSources(dataKeys).then(({ dataKeys: updatedKeys, statistics: sourceStatistics }) => {
          this.setState({
            statistics,
            days,
            dataKeys: updatedKeys,
            portalKeys: dataKeys,
            sourceStatistics,
            total_views
          })
        })
      } else {
        this.setState({
          statistics,
          days,
          dataKeys,
          portalKeys: dataKeys,
          total_views
        })
      }
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  fetchLeadSources(dataKeys) {
    const { actions, model } = this.props
    const params = {
      [model.model]: model.id
    }
    const updatedKeys = [ ...dataKeys ]
    return new Promise((resolve, reject) => actions.fetchLeadsBreakdown({
      fields: [ 'leads' ],
      subsets: [ 'leads.source_counts' ],
      params: {
        ...params
      },
      signal: this.AbortController.signal,
      resolve,
      reject
    })).then(r => {
      const statistics = {}
      Object.keys(r.leads.source_counts)
        .filter(k => r.leads.source_counts[k])
        .forEach(k => {
          if (k !== 'Mobi Site' && k !== 'Property 24') {
            if (!updatedKeys.includes(k)) {
              updatedKeys.push(k)
            }
            statistics[k] = parseInt(r.leads.source_counts[k], 10)
          } else if (k === 'Property 24') {
            if (!updatedKeys.includes('Property24')) {
              updatedKeys.push('Property24')
            }
            statistics.Property24 = parseInt(r.leads.source_counts[k], 10)
          } else {
            statistics[k] = parseInt(r.leads.source_counts[k], 10)
          }
        })

      return {
        dataKeys: updatedKeys,
        statistics
      }
    }).catch(e => {
      console.error(e)
    })
  }

  fetchListingStats() {
    const { config, modelid } = this.props
    const { views_period, listing_model } = this.state

    let modelname
    let listing_type

    switch (listing_model) {
      case 'residential_for_sale':
        modelname = 'residential'
        listing_type = 'For Sale'
        break
      case 'residential_to_let':
        modelname = 'residential'
        listing_type = 'To Let'
        break
      case 'commercial_for_sale':
        modelname = 'commercial'
        listing_type = 'For Sale'
        break
      case 'commercial_to_let':
        modelname = 'commercial'
        listing_type = 'To Let'
        break
      case 'project':
        modelname = 'projects'
        break
      case 'holiday':
        modelname = 'holiday'
        break
      default:
        modelname = null
    }
    const created = valueFormat('shortdate', new Date().setDate(new Date().getDate() - views_period))
    if (modelid) {
      new Promise((resolve, reject) => this.props.actions.fetchListingHistory({
        modelname: [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) ? config.modelname : modelname,
        id: [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) ? modelid : null,
        params: {
          branch: [ 'branches' ].includes(config.modelname) ? modelid : null,
          listing_type: [ 'projects', 'holiday' ].includes(config.modelname) || [ 'projects', 'holiday' ].includes(modelname) ? null : listing_type,
          created__gte: created
        },
        signal: this.AbortController.signal,
        resolve,
        reject
      })).then(r => {
        if (this._is_mounted) {
          const { statistics, days, dataKeys, total_views } = this.filterListingStats(r)
          if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname)) {
            this.fetchLeadSources(dataKeys).then(({ dataKeys: updatedKeys, statistics: sourceStatistics }) => {
              this.setState({
                results: r,
                statistics,
                days,
                dataKeys: updatedKeys,
                portalKeys: dataKeys,
                sourceStatistics,
                total_views,
                sigma_total_views: r.sigma_total_views
              })
            })
          } else {
            this.setState({
              results: r,
              statistics,
              days,
              dataKeys,
              portalKeys: dataKeys,
              total_views
            })
          }
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  }

  filterListingStats(results) {
    const { portals } = this.props
    const { views_source: source, views_period } = this.state
    const statistics = Object.keys(results.listing).sort((a, b) => {
      if (new Date(a) > new Date(b)) { return 1 }
      if (new Date(a) < new Date(b)) { return -1 }
      return 0
    }).map(day => {
      const stats = results.listing[day]
      const vals = {
        name: day,
        Website: getIn(stats, 'times_viewed', 0) >= 0 ? getIn(stats, 'times_viewed', 0) : 0
      }
      const stat_portals = Object.keys(results).filter(k => k !== 'listing').map(p => parseInt(p, 10))
      if (portals) {
        Object.keys(portals)
          .map(pid => portals[pid])
          .filter(portal => stat_portals.includes(portal.portal)).
          forEach(portal => {
            const key = `${portal.portal}.${day}.times_viewed`
            vals[portal.meta.portal.name] = getIn(results, key, 0) >= 0 ? getIn(results, key, 0) : 0
          })
      }
      return vals
    })

    let dataKeys = []
    let days = []
    if (statistics) {
      dataKeys = Object.keys(statistics[0]).filter(k => k !== 'name')
      days = statistics.map(day => {
        const new_day = { ...day }
        Object.keys(day).forEach(d => {
          if (d !== 'name') {
            if (source) {
              if (d !== source) {
                delete new_day[d]
              }
            }
          }
        })
        return { ...new_day }
      })
    }
    days = days.sort((a, b) => {
      if (new Date(a.name) > new Date(b.name)) {
        return 1
      }
      if (new Date(a.name) < new Date(b.name)) {
        return -1
      }
      return 0
    })

    let groups = []
    switch (views_period) {
      case 90:
        // Group data by weeks
        groups = groupBy(days, 'name', day => valueFormat('week', day))
        days = Object.keys(groups).sort((a, b) => {
          if (new Date(groups[a][0].name) > new Date(groups[b][0].name)) {
            return 1
          }
          if (new Date(groups[a][0].name) < new Date(groups[b][0].name)) {
            return -1
          }
          return 0
        }).map(k => {
          const week_days = groups[k]
          const week = {
            name: [ ...week_days ].pop().name
          }
          week_days.forEach(day => {
            Object.keys(day).filter(d => d !== 'name').map(d => {
              if (!getIn(week, d)) {
                week[d] = 0
              }
              week[d] += getIn(day, d, 0)
            })
          })
          return week
        })
        break
      case 365:
        // Group data by months
        groups = groupBy(days, 'name', day => valueFormat('monthyear', day))
        days = Object.keys(groups).sort((a, b) => {
          if (new Date(groups[a][0].name) > new Date(groups[b][0].name)) {
            return 1
          }
          if (new Date(groups[a][0].name) < new Date(groups[b][0].name)) {
            return -1
          }
          return 0
        }).map(k => {
          const months = groups[k]
          const month = {
            name: [ ...months ].pop().name
          }
          months.forEach(day => {
            Object.keys(day).filter(d => d !== 'name').map(d => {
              if (!getIn(month, d)) {
                month[d] = 0
              }
              month[d] += getIn(day, d, 0)
            })
          })
          return month
        })
        break
      default:
        break
    }
    let total_views
    if (this.state.views_source) {
      total_views = [ 'Website', '' ].includes(this.state.views_source) ? days.map(d => d.Website).reduce((prev, curr) => prev + curr, 0) : days.map(d => getIn(d, this.state.views_source, 0) || 0).reduce((prev, curr) => prev + curr, 0)
    } else {
      total_views = results.sigma_total_views
    }

    return { statistics, days, dataKeys, total_views }
  }

  render() {
    const { model, config, settings } = this.props
    const { statistics, portals } = model.meta
    const { views_source: source, sourceStatistics } = this.state
    // const selected_portal = portals && source ? portals.find(p => getIn(p, 'meta.portal.name') === source) : null
    const portal_statistics_map = {
      sms_leads: 0,
      tel_leads: 0,
      email_leads: 0,
      alerts_sent: 0,
      times_viewed: 0
    }
    const total_portal_statistics = { ...portal_statistics_map }
    const single_portal_stats = []
    const ppp24gt = [ 1, 2, 3 ]
    ppp24gt.forEach(pid => {
      single_portal_stats[pid] = { ...portal_statistics_map }
      const portal = portals ? portals.find(p => getIn(p, 'meta.portal.id') === pid) : {}
      single_portal_stats[pid].sms_leads += getIn(portal, 'meta.statistics.portal_statistics.sms_leads', 0)
      single_portal_stats[pid].tel_leads += getIn(portal, 'meta.statistics.portal_statistics.tel_leads', 0)
      single_portal_stats[pid].email_leads += getIn(portal, 'meta.statistics.portal_statistics.email_leads', 0)
      single_portal_stats[pid].alerts_sent += getIn(portal, 'meta.statistics.portal_statistics.alerts_sent', 0)
      single_portal_stats[pid].times_viewed += getIn(portal, 'meta.statistics.portal_statistics.times_viewed', 0)
      total_portal_statistics.sms_leads += getIn(portal, 'meta.statistics.portal_statistics.sms_leads', 0)
      total_portal_statistics.tel_leads += getIn(portal, 'meta.statistics.portal_statistics.tel_leads', 0)
      total_portal_statistics.email_leads += getIn(portal, 'meta.statistics.portal_statistics.email_leads', 0)
      total_portal_statistics.alerts_sent += getIn(portal, 'meta.statistics.portal_statistics.alerts_sent', 0)
      total_portal_statistics.times_viewed += getIn(portal, 'meta.statistics.portal_statistics.times_viewed', 0)
    })
    const { days, dataKeys, portalKeys } = this.state
    const days_on_market = differenceInCalendarDays(
      new Date(),
      model.on_market_since ? new Date(model.on_market_since) : new Date(model.created)
    )
    const allviews_cardsize = ![ 'ae' ].includes(settings.region) ? 5 : 6
    const alerts_total_heading = this.state.views_source === '' ? 'Portal Alerts' : `${this.state.views_source} Alerts`
    return (
      <Card
        classes="grid-col-1"
        bodyclass="bar-graph"
        background
        body={
          <div className="stats grid-container grid-5">
            <div className={classNames({ 'grid-col-4': [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname), 'grid-col-5': ![ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) })}>
              <div className="flex-container">
                <h3 style={{ margin: 0 }}>Listing Views</h3>
                <div className="details-section-buttons">
                  <div className="profile-listing-model">
                    <InlineSelect
                      id="views_source"
                      name="views_source"
                      className="inline-select"
                      classNamePrefix="inline"
                      defaultValue={{ label: 'All Sources', value: '' }}
                      selectedValue={getIn(this.state, 'views_source')}
                      options={[ { label: 'All Sources', value: '' }, ...dataKeys.map(k => ({ label: k, value: k })) ]}
                      onChange={e => {
                        this.setState({ views_source: e.value })
                      }}
                    />
                  </div>
                  {![ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) ? (
                    <div className="filter-listing-model">
                      <InlineSelect
                        id="listing_model"
                        name="listing_model"
                        className="inline-select"
                        classNamePrefix="inline"
                        options={this.state.listing_options}
                        defaultValue={this.state.default_model || ''}
                        selectedValue={getIn(this.state, 'listing_model')}
                        onChange={e => {
                          const listing_model = e.value
                          this.setState({ listing_model })
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="filter-date-range">
                    <InlineSelect
                      id="views_period"
                      name="views_period"
                      className="inline-select"
                      classNamePrefix="inline"
                      defaultValue={{ label: 'Last 30 Days', value: 30 }}
                      selectedValue={getIn(this.state, 'views_period')}
                      options={[ { label: 'Last 30 Days', value: 30 }, { label: 'Last 90 Days', value: 90 }, { label: 'Last 12 Months', value: 365 } ]}
                      onChange={e => {
                        this.setState({ views_period: e.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <BarGraph data={days} dataKeys={portalKeys}>{({ width }) => {
                const interval = width < 750 ? parseInt(days.length / parseInt(days.length * (width / 750), 10), 10) : 0
                return (
                  <>
                    <Legend verticalAlign="top" align="left" iconType="square" iconSize={9} formatter={renderColorfulLegendText} />
                    <CartesianGrid vertical={false} stroke="#F3F5F8" />
                    <XAxis interval={interval} axisLine={false} dataKey="name" tick={<CustomizedAxisTick dayCount={days.length} />} />
                    <YAxis width={longestLabelLength(days, portalKeys)} axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
                    <Tooltip cursor={{ fill: '#FAFBFD' }} />
                    {portalKeys.map((key, idx) => (
                      <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={getRandomColor(idx)} radius={[ 10, 10, 0, 0 ]} />
                    ))}
                  </>
                )
              }}
              </BarGraph>
            </div>
            {[ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) ? (
              <div className="flex-stats grid-col-1">
                <div className="flex-container">
                  <div className="days-on-market">
                    <div className="stat-count">{days_on_market}</div>
                    <div className="stat-heading">Days on Market</div>
                  </div>
                  {!this.state.views_source ? (
                    <div className="total-views">
                      <div className="stat-count">{this.state.total_views}</div>
                      <div className="stat-heading">Total Views</div>
                    </div>
                  ) : (
                    <div className="total-views">
                      <div className="stat-count">{this.state.total_views}</div>
                      <div className="stat-heading">{this.state.views_source} Views</div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {source === '' && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className={`grid-container grid-${allviews_cardsize} grid-col-5`}>
                <NavLink to="./leads">
                  <Card
                    classes="no-shadow stats-card-grey"
                    bodyclass="stats-card"
                    body={
                      <>
                        <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                        <h3>{statistics.active_electronic_leads +
                        statistics.inactive_leads}</h3>
                        <div><span>Email Leads</span><span className="view-text">View</span></div>
                      </>
                    }
                  />
                </NavLink>
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{total_portal_statistics.sms_leads}</h3>
                      <div><span>SMS Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{(statistics.active_telephonic_leads + total_portal_statistics.tel_leads)}</h3>
                      <div><span>Telephone Leads</span></div>
                    </>
                  }
                />
                {config.modelname !== 'holiday' ? (
                  <>
                    <NavLink to="./alerts-sent">
                      <Card
                        classes="no-shadow stats-card-grey"
                        bodyclass="stats-card"
                        body={
                          <>
                            <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                            <h3>{(statistics.total_alerts_sent)}</h3>
                            <div><span>Prop Data Alerts</span><span className="view-text">View</span></div>
                          </>
                        }
                      />
                    </NavLink>
                    {![ 'ae' ].includes(settings.region) &&
                    <Card
                      classes="no-shadow stats-card-grey"
                      bodyclass="stats-card"
                      body={
                        <>
                          <h3>{total_portal_statistics.alerts_sent}</h3>
                          <div><span>{alerts_total_heading}</span></div>
                        </>
                      }
                    />
                    }
                  </>
                ) : null }
              </div>
            }

            {source === 'Website' && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className="grid-container grid-4 grid-col-5">
                <NavLink to="./leads">
                  <Card
                    classes="no-shadow stats-card-grey"
                    bodyclass="stats-card"
                    body={
                      <>
                        <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                        <h3>{getIn(sourceStatistics, 'Website', 0) + getIn(sourceStatistics, 'Mobi Site', 0)}</h3>
                        <div><span>Email Leads</span><span className="view-text">View</span></div>
                      </>
                    }
                  />
                </NavLink>
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{statistics.active_whatsapp_leads}</h3>
                      <div><span>Whatsapp Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{statistics.active_telephonic_leads}</h3>
                      <div><span>Telephone Leads</span></div>
                    </>
                  }
                />
                {config.modelname !== 'holiday' ? (
                  <NavLink to="./alerts-sent">
                    <Card
                      classes="no-shadow stats-card-grey"
                      bodyclass="stats-card"
                      body={
                        <>
                          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                          <h3>{(statistics.new_property_alerts_sent +
                              statistics.on_show_alerts_sent +
                              statistics.price_reduced_alerts_sent +
                              statistics.manual_alerts_sent)}</h3>
                          <div><span>Prop Data Alerts</span><span className="view-text">View</span></div>
                        </>
                      }
                    />
                  </NavLink>
                ) : null }
              </div>
            }

            {source === 'Property24' && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className="grid-container grid-4 grid-col-5">
                {config.modelname !== 'holiday' ?
                  <NavLink to="./leads">
                    <Card
                      classes="no-shadow stats-card-grey"
                      bodyclass="stats-card"
                      body={
                        <>
                          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                          <h3>{sourceStatistics[source]}</h3>
                          <div><span>Email Leads</span><span className="view-text">View</span></div>
                        </>
                      }
                    />
                  </NavLink> :
                  <Card
                    classes="no-shadow stats-card-grey"
                    bodyclass="stats-card"
                    body={
                      <>
                        <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                        <h3>{sourceStatistics[source]}</h3>
                        <div><span>Email Leads</span></div>
                      </>
                    }
                  />
                }
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{single_portal_stats[1].sms_leads}</h3>
                      <div><span>SMS Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{single_portal_stats[1].tel_leads}</h3>
                      <div><span>Telephone Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{single_portal_stats[1].alerts_sent}</h3>
                      <div><span>{alerts_total_heading}</span></div>
                    </>
                  }
                />
              </div>
            }

            {source === 'Private Property' && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className="grid-container grid-3 grid-col-5">
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{sourceStatistics[source] || 0}</h3>
                      <div><span>Email Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{single_portal_stats[2].tel_leads}</h3>
                      <div><span>Telephone Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{single_portal_stats[2].alerts_sent}</h3>
                      <div><span>{alerts_total_heading}</span></div>
                    </>
                  }
                />
              </div>
            }

            {source === 'Gumtree' && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className="grid-container grid-3 grid-col-5">
                {config.modelname !== 'holiday' ?
                  <NavLink to="./leads">
                    <Card
                      classes="no-shadow stats-card-grey"
                      bodyclass="stats-card"
                      body={
                        <>
                          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                          <h3>{sourceStatistics[source]}</h3>
                          <div><span>Email Leads</span><span className="view-text">View</span></div>
                        </>
                      }
                    />
                  </NavLink> :
                  <Card
                    classes="no-shadow stats-card-grey"
                    bodyclass="stats-card"
                    body={
                      <>
                        <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                        <h3>{single_portal_stats[3].email_leads}</h3>
                        <div><span>Email Leads</span></div>
                      </>
                    }
                  />
                }
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                      <h3>{single_portal_stats[3].tel_leads}</h3>
                      <div><span>Telephone Leads</span></div>
                    </>
                  }
                />
              </div>
            }

            {![ '', 'Website', 'Property24', 'Private Property', 'Gumtree' ].includes(source) && [ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) &&
              <div className="grid-container grid-3 grid-col-5">
                {config.modelname !== 'holiday' ?
                  <NavLink to="./leads">
                    <Card
                      classes="no-shadow stats-card-grey"
                      bodyclass="stats-card"
                      body={
                        <>
                          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                          <h3>{sourceStatistics[source]}</h3>
                          <div><span>Email Leads</span><span className="view-text">View</span></div>
                        </>
                      }
                    />
                  </NavLink> :
                  <Card
                    classes="no-shadow stats-card-grey"
                    bodyclass="stats-card"
                    body={
                      <>
                        <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-ArrowExpand" /></svg>
                        <h3>{single_portal_stats[3].email_leads}</h3>
                        <div><span>Email Leads</span></div>
                      </>
                    }
                  />
                }
              </div>
            }

            {![ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname) ? (
              <div className="grid-container grid-2 grid-col-5">
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{statistics.active_electronic_leads}</h3>
                      <div><span>Email Leads</span></div>
                    </>
                  }
                />
                <Card
                  classes="no-shadow stats-card-grey"
                  bodyclass="stats-card"
                  body={
                    <>
                      <h3>{statistics.active_electronic_leads + statistics.active_telephonic_leads}</h3>
                      <div><span>Total Leads</span></div>
                    </>
                  }
                />
              </div>
            ) : null}

          </div>
        }
      />
    )
  }
}

export default withDelay(ListingViewsWidget)


ListingViewsWidget.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  index: PropTypes.number,
  model: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  config: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
  portals: PropTypes.object,
  modelid: PropTypes.number
}
