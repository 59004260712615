import React from 'react'
import classNames from 'classnames'


const Tip = tip => (
  (tip.text) ? (
    <div className={classNames('tip', tip.classes, { multi: tip.multi, hot: tip.hot })}>
      <div className="tip-content">
        {tip.heading &&
        <span className={classNames('tipheading', { multi: tip.multi })}>{tip.heading || 'Tip'}:</span>
        }
        <span className={classNames('tiptext', { multi: tip.multi })}>{tip.text}</span>
      </div>
    </div>
  ) : <div />
)

export default Tip
