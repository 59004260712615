import classNames from 'classnames'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import 'whatwg-fetch'

import log from '../../../logging'
import { capitalize, hasAddons, hasPermission, isConditional, parseURL, sortBy, breakpoint } from '../../../utils'
import { Button } from '../../ui/Button'
import ChallengeModal from '../modals/ChallengeModal'
import QueryBuilder from '../QueryBuilder'
import ContextButton from './ContextButton'


class ContextMenu extends React.Component {
  constructor(props) {
    super(props)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.actions = {
      ...this.props.actions,
      dropdownPositionCallback: props.dropdownPositionCallback || this.dropdownPositionCallback,
      refreshList: () => {
        let modelname = this.props.match.params.model
        const { log: logname } = this.props.match.params // For syndication logs
        if (logname) { modelname = `${modelname}${logname}`}
        const { params } = this.props.model
        this.props.actions.selectNone() // Clear selected models
        const refresh = new Promise((resolve, reject) => {
          const qs = new QueryBuilder(this.props.location.search)
          const values = {
            modelname,
            endpoint: this.props.config.endpoint,
            params: { ...params, ...qs.getAllArgs(), offset: 0 },
            modellist: true
          }
          this.props.actions.fetchMany({ values, resolve, reject })
        })
        return refresh
      },
      submitForm: btn => {
        const form = btn.form
        let isDisabled = false
        if (props.form) {
          if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
          if (props.handleSubmit) {
            return props.handleSubmit(btn)
          }
          props.form.submitForm()
          isDisabled = true
        } else if (props.handleSubmit) {
          if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
          return props.handleSubmit()
        }
        if (!form.isValid && Object.keys(form.errors).length) {
          const errs = Object.keys(form.errors)
          let { touched } = form
          const { errors } = form
          touched = { ...touched }
          Object.keys(errors).forEach(f => { // ensure the errors display by marking the fields as touched
            if (!Object.keys(touched).includes(f)) {
              touched[f] = true
            }
          })
          const errorfields = sortBy(errs.map(err => ({
            name: err,
            ordinal: this.props.config.fields.findIndex(c => c.name === err)
          })).filter(e => e.ordinal !== -1), 'ordinal')
          const firsterr = errorfields.length ? errorfields[0].name : errs[0]
          const raw = {}
          raw[firsterr] = errors[firsterr] && Array.isArray(errors[firsterr]) ? errors[firsterr] : [ errors[firsterr] ]
          // find field that's visibile
          const errfield = this.props.config.fields.find(c => c.name === firsterr && this.isConditional(c, 'edit', false, form))
          if (!errfield) {
            this.props.actions.notifyUser({
              title: 'Invalid data',
              body: 'The form data is not valid',
              type: 'error'
            })
            log.error(`Error field not found: ${JSON.stringify(errs)}`)
          }
          return true
        }
        if (form.isSubmitting) { isDisabled = true }
        if (!isDisabled) {
          if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
          form.handleSubmit(btn)
        }
        return true
      },
      deleteModels: () => {
        this.setState({ challenge: true, action: 'deleteModels' })
      },
      mergeModels: () => {
        this.setState({ challenge: true, action: 'mergeModels' })
      },
      downloadDocuments: values => {
        values.filename = `documents_${values.selected ? values.selected.length : 1}_${new Date().getTime()}.zip`
        new Promise((resolve, reject) => props.actions.downloadImages({ values, resolve, reject })).then(r => {
          const blob = new Blob([ r ], { type: 'application/zip' })
          const url = window.URL.createObjectURL(blob)

          const downloadByUrl = () => {
            this.downloadManager.downloadUrl(url, values.filename)
          }
          try {
            this.downloadManager.download(blob, url, values.filename)
          } catch (e) {
            downloadByUrl()
          }
        }).catch(e => {
          console.error(e)
        })
      },
      downloadDocument: values => {
        const selected = getIn(values.selected, '0')
        const file = getIn(this.props.cache, selected)
        if (!file) { return }
        fetch(file.meta.document.file).then(r => r.blob()).then(r => {
          const blob = new Blob([ r ], { type: file.meta.document.content_type })
          const url = window.URL.createObjectURL(blob)
          const filename = file.meta.document.caption ? file.meta.document.caption : file.meta.document.file.split('/').pop()
          const downloadByUrl = () => {
            this.downloadManager.downloadUrl(url, filename)
          }
          try {
            this.downloadManager.download(blob, url, filename)
          } catch (e) {
            console.error(e)
            downloadByUrl()
          }
        }).catch(e => {
          console.error(e)
        })
      },
      toggleNoteCreator: this.toggleNoteCreator.bind(this),
      toggleVacancyPro: this.toggleVacancyPro.bind(this),
      toggleCreditCheck: this.toggleCreditCheck.bind(this),
      goBack: () => {
        if ([ 'modules' ].includes(getIn(this.props, 'match.params.model'))) {
          const model = getIn(this.props.cache, getIn(this.props, 'match.params.id'), null)
          if (model.parent && model.status !== 'Published') {
            this.props.actions.registerRedirect(`/secure/${getIn(this.props, 'match.params.site')}/${getIn(this.props, 'match.params.model')}/${model.parent}/edit/versions/${model.id}`)
          }
        } else {
          const goBacks = this.state.backTo - this.props.history.length - 1
          this.props.history.go(goBacks)
        }
      },
      createListing: () => {
        if (this.props.match.params.model === 'referrals') {
          let model = false
          if (this.props.selected.length) {
            model = getIn(this.props.cache, this.props.selected[0])
          } else if (this.props.form) { // Handle unsaturated page refresh ie. straight to ModelView
            model = this.props.form.values
          }
          if (model) {
            let model_type = 'residential'
            if (model.seller_listing_type.includes('Commercial')) { model_type = 'commercial' }
            this.props.actions.registerRedirect(`/secure/${getIn(this.props, 'match.params.site')}/${model_type}/add?model=referrals&id=${model.id}`)
          } else {
            this.props.actions.registerRedirect(`/secure/${getIn(this.props, 'match.params.site')}/residential/add`)
          }
        }
      },
      createValuation: () => {
        if (this.props.match.params.model === 'referrals') {
          let model = false
          if (this.props.selected.length) {
            model = getIn(this.props.cache, this.props.selected[0])
          } else if (this.props.form) { // Handle unsaturated page refresh ie. straight to ModelView
            model = this.props.form.values
          }
          if (model) {
            this.props.actions.registerRedirect(`/secure/${getIn(this.props, 'match.params.site')}/valuations/add?model=referrals&id=${model.id}`)
          } else {
            this.props.actions.registerRedirect(`/secure/${getIn(this.props, 'match.params.site')}/valuations/add`)
          }
        }
      },
      unassignPrimaryAgent: () => {
        this.setState({ challenge: true, action: 'unassignPrimaryAgent' })
      }
    }

    this.state = {
      showActions: breakpoint.matches,
      note: false,
      challenge: false,
      backTo: history.length
    }
    this.isButtonVisible = this.isButtonVisible.bind(this)
    this.toggleNoteCreator = this.toggleNoteCreator.bind(this)
    this.toggleVacancyPro = this.toggleVacancyPro.bind(this)
    this.toggleCreditCheck = this.toggleCreditCheck.bind(this)
    this.isConditional = isConditional.bind(this)
    this.checkBranches = this.checkBranches.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleActions)
    this.root = document.getElementById('wrapper')
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleNoteCreator() {
    this.props.actions.toggleWideSidebar('show-notes-sidebar')
    this.setState({ note: !this.state.note })
  }

  toggleVacancyPro() {
    this.props.actions.toggleWideSidebar('show-vacancy-sidebar')
  }

  toggleCreditCheck() {
    this.props.actions.toggleWideSidebar('show-credit-check')
  }


  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  dropdownPositionCallback(el) {
    if (!el) { return false }
    const box = el.getBoundingClientRect()
    const parent = el.offsetParent
    const newbox = {
      x: box.x,
      y: box.y,
      bottom: box.bottom,
      top: box.top,
      left: box.left,
      right: box.right
    }
    const cursorX = newbox.x
    const cursorY = parent ? parent.getBoundingClientRect().top + window.scrollY + box.height + 10 : 0
    const updatedStyles = { top: cursorY, left: cursorX, position: 'absolute', display: 'block', minWidth: box.width }
    return updatedStyles
  }

  findButton(el) {
    while (el.parentElement) {
      el = el.parentElement
      if (!el.classList.contains('btn') && !el.classList.contains('has-submenu')) { continue }
      break
    }
    return el
  }

  checkBranches() {
    const { user, match, cache, selected } = this.props
    let { modelid } = this.props
    const { id } = match.params
    if (!modelid) { modelid = parseInt(id, 10) }
    const has_selected = selected.length ? selected : [ modelid ]
    if (user.permissions.includes('apply_to_all_branches') || user.permissions.includes('is_prop_data_user')) {
      return true
    }
    const allowed_records = has_selected.map(sel => {
      const m = cache ? cache[sel] : null
      if (!m) { return null }
      const all_here = user.agent.branches.filter(b => b === sel)
      return all_here.some(perm => perm) ? m.id : null
    })
    if (allowed_records.filter(l => l).length === has_selected.length) {
      return true
    }
    return false
  }

  isButtonVisible(button, buttonaction) {
    let v = null
    const { user, model, match, cache, form, portals, dropdownHover, route: forcedRoute, selected } = this.props
    let { modelid } = this.props
    let route = match.params.action
    if (!route && match.params.id) {
      route = 'view'
    }
    if (!route && match.params.model) {
      route = 'list'
    }
    if (!route && forcedRoute) {
      route = forcedRoute
    }
    let { model: modelname } = match.params
    const { log: logname, id } = match.params
    if (logname) { modelname = `${modelname}${logname}`} // Syndication logs
    if (!modelid) { modelid = parseInt(id, 10) }
    if (button.conditions) {
      if (button.conditions.permissions) { // check non-record specific perms
        let context = selected.length ? cache[selected[0]] : false
        if (modelid && !context) {
          context = getIn(cache, modelid)
        }
        v = hasPermission(
          button.conditions.permissions,
          user.permissions,
          context,
          user.agent.id,
          button.conditions.permission_key,
          button.conditions.preferOwn
        )
      }

      if (v && button.conditions.own) { // check record specific perms for branches
        v = this.checkBranches()
      }

      if (
        user.permissions.includes('apply_to_all_branches') &&
        [
          'branches',
          'agents',
          'teams',
          'residential',
          'commerical',
          'holiday',
          'projects',
          'contacts',
          'profiles',
          'leads',
          'subscribers',
          'referrals',
          'syndication'
        ].includes(modelname)
      ) { v = true }

      if (user.permissions.includes('is_prop_data_user')) {
        if (button.conditions && button.conditions.permissions && button.conditions.permissions.includes('!is_prop_data_user')) {
          v = false
        } else {
          v = true
        }
      }

      if (v || v === null) { // passed perms, or not perms specific
        // Count goes first as it is least important and may conflict with the other conditions like selected
        if (button.conditions.count) { // Are there items in the model at all?
          const model_count = model && Array.isArray(model.index) ? model.index.length : 0
          if (button.conditions.count.hasOwnProperty('min') && button.conditions.count.hasOwnProperty('max')) {
            v = (model_count >= button.conditions.count.min && model_count <= button.conditions.count.max)
          } else if (button.conditions.count.hasOwnProperty('min')) {
            v = (model_count >= button.conditions.count.min)
          } else if (button.conditions.count.hasOwnProperty('max')) {
            v = (model_count <= button.conditions.count.max)
          }
        }

        if (button.conditions.selected) { // Are there items selected in this model?
          let selected_count = selected.length
          if (!selected_count && id) { selected_count = 1 }
          if (!selected_count && modelid) { selected_count = 1 }
          if (button.conditions.selected.hasOwnProperty('min') && button.conditions.selected.hasOwnProperty('max')) {
            v = (selected_count >= button.conditions.selected.min && selected_count <= button.conditions.selected.max)
          } else if (button.conditions.selected.hasOwnProperty('min')) {
            v = (selected_count >= button.conditions.selected.min)
          } else if (button.conditions.selected.hasOwnProperty('max')) {
            v = (selected_count <= button.conditions.selected.max)
          }
        }

        if (button.conditions.rightclick) { // Is this a right click context menu
          if (dropdownHover) { v = button.conditions.rightclick.show }
        }

        if (button.conditions.portal) { // Is the portal configured?
          v = false
          if (cache[modelid] && cache[modelid].meta && cache[modelid].meta.portals) {
            const pidx = Object.keys(portals).find(pk => portals[pk].meta.portal.slug === button.conditions.portal)
            if (pidx) {
              const pcfg = cache[modelid].meta.portals.find(p => p.portal === portals[pidx].portal)
              if (pcfg && pcfg.active) { v = true }
            }
          }
        }
        if (v && button.conditions.active !== undefined && selected) { // Does this button only apply to a specific status
          v = selected.every(item => cache[item].active === !!button.conditions.active)
        }
        if (v && button.conditions.status !== undefined && selected) { // Does this button only apply to a specific status
          v = selected.every(item => cache[item].status === button.conditions.status)
        }

        const visibility = getIn(button, 'conditions.visible')
        if (visibility && Array.isArray(visibility)) { // Visibility based on model data value
          let has_selected = selected
          if ((!Array.isArray(has_selected.length) || !has_selected.length) && modelid) {
            has_selected = [ modelid ]
          }
          if (button.routes && button.routes.includes('add') && !modelid) {
            has_selected = [ true ]
          }

          // handle addon checks when no listings selected
          if (!has_selected.length && button.conditions.selected) { // Are there items selected in this model?
            let selected_count = has_selected.length
            if (!selected_count && id) { selected_count = 1 }
            if (!selected_count && modelid) { selected_count = 1 }
            if (button.conditions.selected.hasOwnProperty('min') && button.conditions.selected.hasOwnProperty('max')) {
              has_selected = [
                (selected_count >= button.conditions.selected.min
                && selected_count <= button.conditions.selected.max)
              ]
            } else if (button.conditions.selected.hasOwnProperty('min')) {
              has_selected = [ (selected_count >= button.conditions.selected.min) ]
            } else if (button.conditions.selected.hasOwnProperty('max')) {
              has_selected = [ (selected_count <= button.conditions.selected.max) ]
            }
          }

          if (!has_selected.length && button.conditions.count) { // Are there items in the model at all?
            const model_count = model && Array.isArray(model.index) ? model.index.length : 0
            if (button.conditions.count.hasOwnProperty('min') && button.conditions.count.hasOwnProperty('max')) {
              const has_enough = (
                model_count >= button.conditions.count.min
                && model_count <= button.conditions.count.max
              )
              has_selected = [ has_enough ]
            } else if (button.conditions.count.hasOwnProperty('min')) {
              has_selected = [ (model_count >= button.conditions.count.min) ]
            } else if (button.conditions.count.hasOwnProperty('max')) {
              has_selected = [ (model_count <= button.conditions.count.max) ]
            }
          }
          let can_see = false
          if (has_selected.length) {
            can_see = has_selected.map(item => this.isConditional(
              button.conditions,
              'visible',
              false,
              { values: { ...getIn(cache, item, {}) }, touched: form ? { ...form.touched } : {} },
              { ...user, selected: selected }
            ))
          } else if (form) {
            can_see = this.isConditional(
              button.conditions,
              'visible',
              false,
              form,
              user
            )
            can_see = [ can_see ]
          } else {
            can_see = [ true ]
          }
          v = (v || v === null) && can_see.length && can_see.every(item => item === true) ? true : false
        }
      }

      if (v === null) { // None of the above checks failed
        v = true
      }
    } else {
      v = true // Show the button if there are no conditions
    }
    if (button.routes && !button.routes.includes(route) && v) {
      v = false
    }
    if (buttonaction === 'list' && match.params.tab) {
      if (!this.state.showActions) {
        v = false
      }
    }

    return v
  }

  render() {
    const inner = []
    const { user, config, cache, portals, form,
      model, addons, extras, app, settings, selected, ui, modelname, match } = this.props
    let modelid = parseInt(match.params.id, 10)
    if (!modelid && this.props.modelid) { modelid = parseInt(this.props.modelid, 10) }
    if (modelname) {
      const modelactions = { ...config.modelactions, ...extras }
      if (modelactions instanceof Object) {
        if (form && modelactions.save) { // This button / menu relates to a form
          let isDisabled = false
          if (form.isSubmitting || Object.keys(form.touched).length === 0) { isDisabled = true }
          let button = { label: 'Save', action: 'submitForm', icon: isDisabled ? 'lock' : 'check' }
          if (modelactions.save) { button = { ...button, ...modelactions.save } }
          if (button.redirect && modelactions.save && typeof modelactions.save.redirect === 'object') {
            if (this.props.match.params.action === 'add') {
              button.redirect = modelactions.save.redirect.add
            } else {
              button.redirect = modelactions.save.redirect.edit
            }
          }
          const cancel = { label: 'Cancel', action: 'goBack', className: 'btn-grey-50 btn-round' }
          modelactions.save = button
          modelactions.cancel = cancel
        }
        for (const action in modelactions) { // Loop over actions in config
          if (modelactions[action]) {
            const button = modelactions[action]
            let visible = false
            let addon = true
            if (button.conditions && button.conditions.addons) { // Does this action require any active addons?
              addon = hasAddons(addons, button.conditions.addons)
            }
            visible = this.isButtonVisible(button, action)
            if (visible && addon) { // Show the button if there are no false conditions and if there is permission
              let link = ''
              if (button.link) { // This button links to a model view
                if (modelid) {
                  if (action === 'list') { // Add the default list params
                    const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
                    link = `${button.link}?${search}`
                  } else {
                    link = parseURL(button.link, cache[modelid]) // Singleton
                  }
                } else if (button.scope === 'selected') {
                  link = parseURL(button.link, { selected }) // List with selection
                } else if (selected.length) {
                  link = parseURL(button.link, cache[selected[0]]) // List with selection
                } else if (action === 'list') { // Add the default list params
                  const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
                  link = `${button.link}?${search}`
                } else {
                  link = button.link
                }
              }
              let el = (
                <ContextButton
                  action={action}
                  match={this.props.match}
                  model={model}
                  modelname={modelname}
                  cache={cache}
                  portals={portals}
                  modelid={modelid}
                  className={action}
                  selected={selected}
                  app={app}
                  key={`${this.props.parent}-${action}`}
                  button={button}
                  parent={this.props.parent}
                  form={form}
                  user={user}
                  website_url={settings.website_url}
                  link={link}
                  dropdownHover={this.props.dropdownHover}
                  stickyProps={this.props.stickyProps}
                  isButtonVisible={this.isButtonVisible}
                  actions={this.actions}
                >{button.label}</ContextButton>
              )
              if (button.render) {
                el = button.render({ key: `${this.props.parent}-${action}`, context: this.props, actions: this.actions, button })
              }
              inner.push(el)
            }
          }
        }
      }
    }

    if (this.root && this.state.challenge && ((selected.length) || modelid)) {
      inner.push(
        ReactDOM.createPortal(
          <Formik
            initialValues={{
              record_ids: selected || [ modelid ],
              modelid
            }}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
          >{ formik => (
              <ChallengeModal
                action={this.state.action}
                isLoading={ui?.isLoading}
                challenges={[
                  {
                    text: (
                      <span>
                        You&apos;re about to delete
                        {formik.values.record_ids.length > 1 ? <><span>{formik.values.record_ids.length.toString()}</span> {config.plural}</> : ` a ${config.singular} `}.
                        Please confirm your action by typing the number of {config.plural} you are about
                        to edit in the field below.
                      </span>
                    ),
                    value: () => {
                      if (formik.values.record_ids.length === 1 || !formik.values.record_ids.length) {
                        return '1'
                      }
                      return formik.values.record_ids.length.toString()
                    },
                    action: 'deleteModels'
                  },
                  {
                    text: (
                      <span>
                        You&apos;re about to delete
                        {formik.values.record_ids.length > 1 ? <><span>{formik.values.record_ids.length.toString()}</span> {config.plural}</> : ` a ${config.singular} `}.
                        Please confirm your action by typing <code>DELETE</code> in the field below.
                      </span>
                    ),
                    value: () => 'DELETE',
                    action: 'deleteModels'
                  },
                  {
                    text: (
                      <span>
                        You are about to merge {formik.values.record_ids.length.toString()} profiles.
                        Your currently viewed profile will be enriched to form the primary profile.
                        All other selected profiles will be archived. <strong>This action cannot be undone.</strong>
                        <br /><br />
                        Confirm your action by typing the number of profiles you are about to merge
                        in the field below. Please be patient while the profiles are merged.
                      </span>
                    ),
                    value: () => formik.values.record_ids.length.toString(),
                    action: 'mergeModels'
                  },
                  {
                    text: (
                      <span>
                        You&apos;re about to edit
                        <span> {formik.values.record_ids.length.toString()} </span> {config.plural}.
                        Please confirm your action by typing <code>EDIT</code> in the field below.
                      </span>
                    ),
                    value: () => 'EDIT',
                    action: 'unassignPrimaryAgent'
                  }
                ]}
                preCheck={modelname === 'agents' ? challenge => {
                  new Promise((resolve, reject) => {
                    this.props.actions.checkDelete({
                      values: {
                        selected: formik.values.record_ids,
                        modelname
                      },
                      resolve,
                      reject
                    })
                  }).then(() => {
                    challenge.setState({ checks_passed: true })
                  }).catch(e => {
                    if (Array.isArray(e)) {
                      const el = challenge.el.querySelector('.challenge-text')
                      e.forEach((error, ind) => {
                        if (ind === 0) {
                          el.innerHTML = `You cannot delete this agent as there are still records assigned to them.
                          Please assign this data to another agent and try again.<br /><br /><a target="_blank" href=${error.url}>${capitalize(error.count)}</a><br />`
                        } else {
                          el.insertAdjacentHTML('beforeend', `<a target="_blank" href=${error.url}>${capitalize(error.count)}</a><br />`)
                        }
                      })
                      const fg = challenge.el.querySelector('.form-group')
                      fg.innerHTML = ''
                    }
                    challenge.setState({ checks_passed: false })
                  })
                } : null}
                visible={this.state.challenge}
                buttons={(({ valid, value }) => (
                  <div className="modal-buttons">
                    <Button className={classNames('btn', 'btn-primary', { disabled: !valid })} type="submit" onClick={valid ? () => {
                      formik.setFieldTouched('challenge', true, false)
                      if (valid) {
                        new Promise((resolve, reject) => {
                          if (this.state.action === 'deleteModels') {
                            return (
                              this.actions.deleteModel({
                                resolve,
                                reject,
                                values: {
                                  modelname,
                                  selected: formik.values.record_ids.length ?
                                    formik.values.record_ids : [ formik.values.modelid ]
                                }
                              })
                            )
                          } else if (this.state.action === 'mergeModels') {
                            return (
                              this.actions.mergeModel({
                                resolve,
                                reject,
                                values: {
                                  modelname,
                                  record_ids: formik.values.record_ids.filter(i => i !== formik.values.modelid),
                                  primary_id: formik.values.modelid
                                }
                              })
                            )
                          } else if (this.state.action === 'unassignPrimaryAgent') {
                            const introduction_agents = formik.values.record_ids.map(
                              c => cache[c].introduction_agent).filter(c => c)
                            return (
                              this.actions.bulkEditModel({
                                resolve,
                                reject,
                                values: {
                                  modelname,
                                  record_ids: formik.values.record_ids.filter(i => i !== formik.values.modelid),
                                  associated_agents: introduction_agents,
                                  introduction_agent: null
                                }
                              })
                            )
                          }
                          return null
                        }).then(() => {
                          this.setState({ challenge: false })
                          this.actions.refreshList()
                          if (modelid) {
                            // if on details view, go back to list view
                            const list = document.querySelector('.navitem.list')
                            if (list) { list.click() }
                          }
                        }).catch(() => {
                          this.setState({ challenge: false })
                        })
                      } else {
                        formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                      }
                    } : null}>Confirm</Button>
                    <Button className="btn btn-white" type="submit" onClick={() => {
                      this.setState({ challenge: false }, () => { formik.setFieldError('challenge', null) })
                    }}>Cancel</Button>
                  </div>
                ))}
              />
            )}
          </Formik>, this.root)
      )
    }
    return inner
  }
}

ContextMenu.propTypes = {
  modelid: PropTypes.number,
  actions: PropTypes.object,
  user: PropTypes.object.isRequired,
  stickyProps: PropTypes.object,
  config: PropTypes.object.isRequired,
  model: PropTypes.object,
  modelname: PropTypes.string,
  addons: PropTypes.array,
  cache: PropTypes.object,
  settings: PropTypes.object,
  form: PropTypes.object,
  portals: PropTypes.object,
  ui: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  extras: PropTypes.object,
  app: PropTypes.object,
  parent: PropTypes.node,
  redirectSchema: PropTypes.func,
  dropdownHover: PropTypes.func,
  dropdownPositionCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  route: PropTypes.string,
  selected: PropTypes.array
}

export default ContextMenu
