/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CACHEDMODELID, CACHE, CONFIGS, ADDONS, PERMISSIONS } from '../selectors'
import { fetchOne, registerRedirect } from '../actions'
import BrochureGenerator from '../components/BrochureGenerator'


const mapStateToProps = (state, ownProps) => {
  const configs = CONFIGS(state)
  const perms = PERMISSIONS(state)
  return {
    modelname: ownProps.match.params.model,
    modelid: ownProps.match.params.id,
    model: CACHEDMODELID(state, ownProps.match.params.model, ownProps.match.params.id),
    config: configs.get(ownProps.match.params.model),
    configs,
    cache: CACHE(state),
    addons: ADDONS(state),
    user: { ...ownProps.user, permissions: perms.toJS() }
  }
}

const mapDispachToProps = dispatch => bindActionCreators({ fetchOne, registerRedirect }, dispatch)

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(BrochureGenerator))
