import PropTypes from 'prop-types'
import React from 'react'

import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import ReferralNotifications from '../common/ReferralNotifications'
import ReferralListings from '../common/ReferralListings'
import MediaGallery from '../common/MediaGallery'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import { isConditional, hasPermission } from '../../utils'


class Referral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: false,
      referral_can_approve: false,
      referral_can_view_contact: false,
      referral_can_finalize: false
    }
    this.computeState = this.computeState.bind(this)
    this.isConditional = isConditional.bind(this)
  }

  componentDidMount() {
    this.computeState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.model.status !== this.props.model.status) {
      this.computeState()
    }
  }


  computeState() {
    let referral_can_approve = false
    let referral_can_view_contact = false
    let referral_can_view_created_contact = false
    let referral_can_finalize = false
    const { model, user } = this.props

    if (user.agent.id) { // No PD users
      if (model.recipient_agent === user.agent.id && model.status === 'Pending') {
        referral_can_approve = true
      }
      if (
        (
          model.recipient_agent === user.agent.id ||
          hasPermission([ 'apply_to_all_branches' ], user.permissions) ||
          (
            model.meta.recipient_branch.branch_managers &&
            model.meta.recipient_branch.branch_managers.includes(user.agent.id)
          ) ||
          model.meta.recipient_branch.leads_manager === user.agent.id ||
          (
            model.meta.recipient_branch.referrals_managers &&
            model.meta.recipient_branch.referrals_managers.includes(user.agent.id)
          )
        ) && model.status === 'Accepted'
      ) {
        referral_can_finalize = true
        referral_can_view_created_contact = true
      }
      if (
        model.status === 'Pending' &&
        model.meta.recipient_branch.branch_managers &&
        model.meta.recipient_branch.branch_managers.includes(user.agent.id)) {
        referral_can_approve = true
      }
      if (
        model.status === 'Pending' &&
        model.meta.recipient_branch.leads_manager === user.agent.id
      ) {
        referral_can_approve = true
      }
      if (
        model.status === 'Pending' &&
        model.meta.recipient_branch.referrals_managers &&
        model.meta.recipient_branch.referrals_managers.includes(user.agent.id)
      ) {
        referral_can_approve = true
      }
      if (
        model.status === 'Pending' &&
        user.permissions.includes('apply_to_all_branches') &&
        user.permissions.includes('referrals_update')
      ) {
        referral_can_approve = true
      }
      if (model.meta.sending_branch.branch_managers &&
        model.meta.sending_branch.branch_managers.includes(user.agent.id)) {
        referral_can_view_contact = true
      }
      if (model.meta.sending_branch.leads_manager === user.agent.id) {
        referral_can_view_contact = true
      }
      if (model.meta.sending_branch.referrals_managers &&
        model.meta.sending_branch.referrals_managers.includes(user.agent.id)) {
        referral_can_view_contact = true
      }
      if (model.sending_agent === user.agent.id) {
        referral_can_view_contact = true
      }
    }
    this.setState({
      referral_can_finalize,
      referral_can_approve,
      referral_can_view_contact,
      referral_can_view_created_contact
    })
  }

  render() {
    const {
      actions,
      model,
      modelid,
      cache,
      user,
      config,
      configs,
      match,
      location,
      routeConfig
    } = this.props

    Object.keys(this.state).forEach(k => {
      if (this.state[k]) {
        user.permissions.push(k)
      }
    })

    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        <Tab tab="details" label="Details" noScroll>
          <DetailsList {...this.props} user={user}/>
        </Tab>

        <Tab tab="email-notifications" label="Email Notifications">
          <ReferralNotifications
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="linked-listings" label="Linked Listings">
          <ReferralListings
            configs={configs}
            actions={{
              fetchReferralListings: actions.fetchReferralListings
            }}
            cache={cache}
            model={model}
            user={user}
            created_contact={model.created_contact}
          />
        </Tab>

        <Tab tab="media" label="Documents">
          <MediaGallery
            media={[
              {
                items: model.meta.documents,
                mediatype: 'documents',
                fieldname: 'documents',
                plural: 'documents',
                label: 'Documents'
              }
            ]}
            actions={{
              downloadImages: actions.downloadImages,
              isConditional: this.isConditional
            }}
            config={config}
            model={model}
            modelname={config.modelname}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Referral.propTypes = {
  selected: PropTypes.array,
  model: PropTypes.object,
  modelid: PropTypes.number,
  actions: PropTypes.object,
  title: PropTypes.string,
  config: PropTypes.object,
  configs: PropTypes.object,
  cache: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Referral
