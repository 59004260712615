import React from 'react'
import PropTypes from 'prop-types'


let timer = null
const isDOM = typeof document !== 'undefined'

const defaultProps = {
  element: 'div'
}

class FocalPoint extends React.Component {
  constructor(props) {
    super(props)
    this.anchor = null
    this.focus = this.focus.bind(this)
    this._onBlur = this._onBlur.bind(this)
    this._setRoot = this._setRoot.bind(this)
  }

  componentDidMount() {
    if (isDOM) {
      this.anchor = document.activeElement
    }

    document.addEventListener('focus', this._onBlur, true)
  }

  componentWillUnmount() {
    document.removeEventListener('focus', this._onBlur, true)

    clearTimeout(timer)

    this.returnFocus()

    this.anchor = null
  }

  contains(element) {
    return this.root.contains(element)
  }

  focus() {
    if (this.contains(document.activeElement) === false) {
      this.root.focus()
    }
  }

  trapFocus() {
    clearTimeout(timer)
    timer = setTimeout(this.focus, 10)
  }

  returnFocus() {
    // When transitioning between pages using hash route state,
    // this anchor is some times lost. Do not attempt to focus
    // on a non-existent anchor.
    if (
      this.anchor &&
      typeof this.anchor === 'object' &&
      typeof this.anchor.focus === 'function'
    ) {
      this.anchor.focus()
    }
  }

  // Private -------------------------------------------------- //

  _setRoot(el) {
    this.root = el
  }

  _onBlur(event) {
    const current = this.anchor

    if (current && current.contains(event.target) === false) {
      event.preventDefault()
      this.trapFocus()
    }
  }

  render() {
    const { children, element, className, focalStyles } = this.props

    return React.createElement(element, {
      ref: this._setRoot,
      tabIndex: 0,
      style: focalStyles,
      className
    },
    children)
  }
}

FocalPoint.defaultProps = defaultProps

FocalPoint.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  focalStyles: PropTypes.object
}

export default FocalPoint
