import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'


const Slider = props => {
  const [ height, setHeight ] = useState(0)
  const [ transition, setTransition ] = useState(null)

  useEffect(() => {
    setTransition('height 0.2s ease-in-out')
  }, [])

  useEffect(() => {
    if (props.childref && !props.closed && props.childref.offsetHeight !== height) {
      setHeight(props.childref.offsetHeight)
    } else if (props.closed && height !== 0) {
      setHeight(0)
    } else if (props.childref?.offsetHeight && props.childref?.offsetHeight === height && height !== 'auto') {
      setHeight('auto')
    }
    return () => { }
  }, [ props.closed, props.childref?.offsetHeight ])

  return (
    <div
      id={props.id}
      className={props.classes}
      onTransitionEnd={e => {
        if (height && e.target.offsetHeight === height) {
          setHeight('auto')
        }
      }}
      style={{
        height,
        overflow: 'hidden',
        transition
      }}>
      {props.children}
    </div>
  )
}

Slider.propTypes = {
  closed: PropTypes.bool,
  classes: PropTypes.string,
  id: PropTypes.string,
  childref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  children: PropTypes.node
}

export default Slider

// Slider.whyDidYouRender = true
