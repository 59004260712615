import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import { parseURL } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ContactLeads = props => {
  const { user, modelid, actions, configs } = props
  return (
    <Card
      bodyclass="leads no-top-padding"
      background
      header={
        <h3>Leads</h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.leads}
          action={actions.fetchLeads}
          params={{
            contact: modelid,
            order_by: '-created',
            meta_fields: [ 'agent', 'residential', 'commercial', 'holiday', 'project', 'interactions' ]
          }}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Source',
              name: 'source',
              orderable: true
            },
            {
              name: 'listing',
              label: 'Listing',
              format: 'listing_popup',
              permissions: [
                'listings_residential_for_sale_view',
                'listings_residential_for_sale_view_own',
                'listings_commercial_for_sale_view',
                'listings_commercial_for_sale_view_own',
                'listings_residential_to_let_view',
                'listings_residential_to_let_view_own',
                'listings_commercial_to_let_view',
                'listings_commercial_to_let_view_own',
                'listings_projects_view',
                'listings_projects_view_own'
              ],
              group: 'Lead Details',
              cols: 'lg-6',
              link: 'meta.listing.link',
              edit: null
            },
            {
              name: [
                'listing.unit_number',
                'listing.complex_name',
                ',',
                'listing.building_name',
                ',',
                'listing.street_number',
                'listing.street_name'
              ],
              link: 'meta.listing.link',
              tooltip: true,
              orderable: false,
              label: 'Address',
              container: 'meta'
            },
            {
              label: 'Agent',
              name: 'agent',
              orderable: true,
              modelname: 'agents',
              optionlabel: [ 'first_name', 'last_name' ],
              link: '/secure/:site/agents/:agent'
            },
            {
              label: 'Stage',
              name: 'stage',
              format: 'stage-expanded',
              classes: 'stage-expanded'
            }
          ]}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={parseURL(`${getIn(data, 'meta.listing.link') ? `${getIn(data, 'meta.listing.link')}/..` : `/secure/${data.site}`}/leads/${data.id}`)} title="View Lead" type="button" />
              {data.status !== 'Inactive' ? (
                <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => props.archiveModel('leads', data.id, () => {
                  data.status = 'Inactive'
                  row.forceUpdate()
                })} title="Archive Lead" type="button" />
              ) : null}
            </>
          )}
        />
      }
    />
  )
}

ContactLeads.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number,
  archiveModel: PropTypes.func
}

export default ContactLeads
