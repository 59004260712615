/* eslint-disable new-cap */
import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import Select from 'react-select'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CONFIG } from '../../../../selectors'
import { sortBy } from '../../../../utils'
import Label from './Label'
import { responsiveSelect } from './ResponsiveSelect'


const ResponsiveSelect = responsiveSelect(Select)

class URLTemplateInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsOpen: false,
      shownoptions: []
    }
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.showOptions = this.showOptions.bind(this)
  }

  componentDidUpdate() {
    this.showOptions(this.props.config)
  }

  showOptions(options) {
    const shownoptions = []
    const additional_fields = [
      'web_ref',
      'portals.property24.reference',
      'portals.private-property.reference',
      'portals.gumtree.reference'
    ]
    if (options && options.fields && options.fields.some(o => o.required)) {
      options = sortBy(options.fields, 'label')
      options.forEach(o => {
        if (o.required || additional_fields.includes(o.name)) {
          const v = { label: o.acronym ? o.acronym : o.label, value: o.name }
          shownoptions.push(v)
        }
      })
    }
    if (!isEqual(shownoptions, this.state.shownoptions)) {
      this.setState({ shownoptions })
    }
  }

  handleSelectChange(v) {
    if (v) {
      let val = ''
      if (this.props.field.value) {
        val = `${this.props.field.value}{{${v.value}}}`
      } else {
        val = `{{${v.value}}}`
      }
      this.props.form.setFieldValue(this.props.field.name, val).then(() => {
        this.props.form.setFieldTouched(this.props.field.name)
      })
    } else {
      this.props.form.setFieldValue(this.props.field.name, null).then(() => {
        this.props.form.setFieldTouched(this.props.field.name)
      })
    }
  }

  render() {
    const {
      field,
      form,
      id,
      classes,
      className
      // label,
    } = this.props
    if (!field.name) { return null }
    return (
      <div id={id} className={`form-group urltemplate ${field.name}`}>
        <Label htmlFor={`input-${id}`} className="formlabel">URL Structure</Label>
        <div className="forminput">
          <input
            id={`input-${id}`}
            name={field.name}
            value={field.value}
            type="text"
            onChange={ e => { form.handleChange(e) }}
            className={classNames('form-control', { 'input-group-prefix': false, 'input-group-suffix': false }, className)}
            defaultValue={field.value === 0 || field.value ? field.value : ''}
          />
        </div>
        <div
          id={`vars-${id}`}
          className={`selectinput ${field.name} form-group ${classes}`}>
          <Label htmlFor={`vars-${field.name}`} className="formlabel">Available Tags</Label>
          <div className="forminput">
            <ResponsiveSelect
              className={'react-select'}
              classNamePrefix="react-select"
              placeholder={''}
              options={this.state.shownoptions}
              getOptionLabel={opt => opt.label }
              getOptionValue={opt => opt.value || ''}
              name={`vars-${field.name}`}
              form={form}
              field={field}
              id={`vars-${field.name}`}
              value={null}
              onChange={this.handleSelectChange}
              closeMenuOnSelect={true}
            />
          </div>
        </div>
        <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
      </div>
    )
  }
}

URLTemplateInput.propTypes = {
  id: PropTypes.string.isRequired,
  modelname: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  classes: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

const mapStateToProps = (state, ownProps) => ({
  config: CONFIG(state, ownProps.modelname)
})

export default connect(mapStateToProps, null)(withImmutablePropsToJS(URLTemplateInput))
