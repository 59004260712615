import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import { Redirect } from 'react-router'
import notesconfig from '../../../config/note.json'
import log from '../../../logging'
import { breakpoint, sortBy } from '../../../utils'
import NotesIndex from './NotesIndex'
import NoteView from './NoteView'


class Notes extends React.Component {
  constructor(props) {
    super(props)
    this.getEventMonth = this.getEventMonth.bind(this)
    this.state = {
      offset: 0,
      loading: false,
      showActions: breakpoint.matches,
      init: false,
      more: false,
      open: false,
      addPerm: [],
      viewPerm: [],
      notes: [],
      term: '',
      searching: false,
      searched: false,
      activity: false,
      note: null
    }
    this.load = null
    this.togglePinNote = this.togglePinNote.bind(this)
    this.togglePrivateNote = this.togglePrivateNote.bind(this)
    this.toggleNoteActivity = this.toggleNoteActivity.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.unsetNote = this.unsetNote.bind(this)
  }

  componentDidMount() {
    const { config } = this.props
    this.root = document.getElementById('wrapper')
    const addPerm = config.permprefix ? [ `${config.permprefix}_add_notes` ] : [ `${config.modelname}_add_notes` ]
    const viewPerm = config.permprefix ? [ `${config.permprefix}_view_notes` ] : [ `${config.modelname}_view_notes` ]
    this.setState({ addPerm, viewPerm })
    breakpoint.addEventListener('change', this.toggleActions)
  }

  componentDidUpdate(prevProps) {
    let { record_id } = this.props.match.params
    const { record_id: old_record_id } = prevProps.match.params
    if (record_id) {
      record_id = parseInt(record_id, 10)
    }
    const notes = sortBy(Object.keys(this.props.cache.notes).map(k => this.props.cache.notes[k]), 'created').reverse()
    if (record_id && !this.state.loading && getIn(this.state, 'note.id') !== record_id) {
      const note = getIn(this.props.cache, `notes.${record_id}`)
      if (note) {
        this.setState({ note, notes, loading: false })
      }
    } else if (record_id) {
      const note = getIn(this.props.cache, `notes.${record_id}`)
      if (!isEqual(note, this.state.note)) {
        this.setState({ note, notes, loading: false })
      }
    } else if (!record_id && old_record_id !== record_id) {
      this.setState({ note: null, notes, loading: false })
    } else if (!record_id && notes.length > 0) {
      this.props.match.params.record_id = notes[0].id
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  toggleNoteActivity(note) {
    this.setState({ note, activity: !this.state.activity })
  }

  togglePinNote(note) {
    const { actions } = this.props

    const values = {
      modelname: notesconfig.config.modelname,
      id: note.id,
      pinned: !note.pinned
    }

    return new Promise((resolve, reject) => {
      actions.updateModel({ values, resolve, reject })
    }).then(() => {
      this.setState({ init: false })
    }).catch(e => {
      log.error(e)
    })
  }

  togglePrivateNote(note) {
    const { actions } = this.props

    const values = {
      modelname: notesconfig.config.modelname,
      id: note.id,
      private: !note.private
    }

    return new Promise((resolve, reject) => {
      actions.updateModel({ values, resolve, reject })
    }).then(() => {
      this.setState({ init: false })
    }).catch(e => {
      log.error(e)
    })
  }

  unsetNote() {
    this.setState({ note: null }, () => {
      const { match } = this.props
      this.props.actions.registerRedirect(`/secure/${match.params.site}/${match.params.model}/${match.params.id}/notes`)
    })
  }

  getEventMonth(date) {
    const newDate = new Date(date)
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format
    const longName = monthName(newDate) // "July 2018"
    return longName
  }

  render() {
    const { match } = this.props
    if (this.state.showActions && !this.props.match.params.record_id && this.state.notes?.length) {
      return <Redirect to={{ pathname: `/secure/${match.params.site}/${match.params.model}/${match.params.id}/notes/${this.state.notes[0].id}` }} />
    }
    return (
      <>
        <NotesIndex
          user={this.props.user}
          actions={{
            ...this.props.actions,
            togglePinNote: this.togglePinNote,
            togglePrivateNote: this.togglePrivateNote
          }}
          config={this.props.config}
          match={this.props.match}
          model={this.props.model}
          notes={this.props.notes}
          cache={this.props.cache}
          visible={((!this.state.showActions && !this.props.match.params.record_id) || this.state.showActions)}
        />
        {this.state.showActions || this.props.match.params.record_id ? (
          <NoteView
            user={this.props.user}
            actions={{
              ...this.props.actions,
              togglePinNote: this.togglePinNote,
              unsetNote: this.unsetNote,
              togglePrivateNote: this.togglePrivateNote
            }}
            config={this.props.config}
            notesconfig={notesconfig}
            match={this.props.match}
            model={this.props.model}
            notesCount={getIn(this.state.notes, 'count', 0)}
            note={this.state.note || (this.state.notes.length ? this.state.notes[0] : null)}
            cache={this.props.cache}
          />
        ) : null}
      </>
    )
  }
}

Notes.propTypes = {
  notes: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  agents: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  actions: PropTypes.object,
  fetchOne: PropTypes.func,
  fetchMany: PropTypes.func,
  fetchNotes: PropTypes.func
}

export default Notes
