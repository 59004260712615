import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'


const Label = ({ children, className, ...props }) => (children ? <label className={classNames('input-label', className)} {...props}>{children}</label> : null)

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Label
