/* eslint-disable new-cap */
import { connect } from 'react-redux'
import React from 'react'

import { CONFIGS, PERMISSIONS, SITE_ID } from '../selectors'

import GlobalAdd from '../components/GlobalAdd'
import { withCustomRouter } from '../components/withCustomRouter'
import { hasPermission, groupBy, capitalize } from '../utils'


const mapStateToProps = state => {
  let groups = {}
  const configs = CONFIGS(state).toJS()
  const permissions = PERMISSIONS(state)
  const { routes } = configs
  if (routes) {
    const add_routes = Object.keys(routes)
      .filter(model => Object.keys(
        routes[model]).includes('add')
        && model !== 'portals'
        && hasPermission(routes[model].add.permissions, permissions.toJS())
      )
      .map(model => {
        let route_name = routes[model].add.name
        if (route_name === 'users') {
          route_name = 'agents'
        } else if (route_name === 'mailing_list') {
          route_name = 'subscribers'
        }
        const config = configs[route_name]
        return {
          domain: config.domain,
          modelname: config.modelname,
          singular: [ 'residential', 'commercial' ].includes(config.modelname) ? capitalize(config.modelname) : capitalize(config.singular)
        }
      })
    groups = groupBy(add_routes, 'domain', capitalize)
  }
  return ({
    groups,
    siteid: SITE_ID(state)
  })
}

export default withCustomRouter(connect(mapStateToProps, null)(React.memo(GlobalAdd)))
