/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import Card from '../common/Card'
import LeadSummary from '../common/lead-interactions/LeadSummary'


const SingleLatestLeadsWidget = props => {
  const { match, model, actions } = props

  const [ leads, setLeads ] = useState([])
  const abortController = useRef(new AbortController())

  const fetchLeads = useCallback(() => {
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: 'leads',
        active: 1,
        limit: 3,
        select: true,
        params: {
          residential: model.id,
          order_by: '-created',
          meta_fields: [ 'contact' ]
        },
        signal: abortController.current.signal
      },
      resolve,
      reject
    })).then(r => {
      setLeads(r.options)
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
  }, [ model ])

  useEffect(() => {
    fetchLeads()
    return () => {
      abortController.current.abort()
    }
  }, [])


  return (
    <Card
      id="latest-leads-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding latest-leads-widget leads-index"
      background
      header={
        <>
          <h3>Latest Leads</h3>
        </>
      }
      body={
        leads.map(lead => <LeadSummary key={`lead-${lead.id}`} lead={lead} match={match} model={model} />)
      }
    />
  )
}

SingleLatestLeadsWidget.propTypes = {
  actions: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object
}


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(null, mapDispatchToProps)(withImmutablePropsToJS(SingleLatestLeadsWidget))
