import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'

import { generateAddress, isConditional, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import Card from '../common/Card'
import ContactAlerts from '../common/ContactAlerts'
import ContactLeads from '../common/ContactLeads'
import ContactListings from '../common/ContactListings'
import ContactProfiles from '../common/ContactProfiles'
import ContactReferrals from '../common/ContactReferrals'
import ContactOffers from '../common/ContactOffers'
import ContactSubscriptions from '../common/ContactSubscriptions'
import ContactApplications from '../common/ContactApplications'
import Marketing from '../common/Marketing'
import DetailsList from '../common/DetailsList'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import MetaDetail from '../common/MetaDetail'
import Notes from '../common/notes/Notes'
import SimpleTable from '../common/simpletable/SimpleTable'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import { Button } from '../ui/Button'
import Valuations from '../common/Valuations'


class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: false,
      alert_count: 0,
      loading_alerts: true,
      listing_model: null,
      options: [
        { value: 'residential', label: 'Residential' },
        { value: 'commercial', label: 'Commercial' }
      ]
    }
    this.archiveModel = this.archiveModel.bind(this)
    this.isConditional = isConditional.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.filterListingModel = this.filterListingModel.bind(this)
    this.listing_models = [
      { value: 'residential', label: 'Residential' },
      { value: 'commercial', label: 'Commercial' }
    ]
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.alerts, this.props.alerts)) {
      this.setState({ alert_count: this.props.alerts.count })
    }
  }

  archiveModel(modelname, id, callback = () => {}) {
    const values = {
      modelname,
      id: id,
      status: 'Inactive'
    }
    return new Promise((resolve, reject) => {
      this.props.actions.updateModel({ values, resolve, reject })
    }).then(callback)
  }

  filterListingModel(listing_model) {
    const value = this.listing_models.find(option => option.value === listing_model.value)
    this.setState({ listing_model: { ...value } })
  }

  getOptions(profiles) {
    let { listing_model } = this.state
    let current_profiles = profiles.filter(profile => profile.listing_model === 'residential' &&
      profile.contact === this.props.modelid)
    if (current_profiles.length && !listing_model) {
      listing_model = { value: 'residential', label: 'Residential' }
    }
    if (!current_profiles.length) {
      current_profiles = profiles.filter(profile => profile.listing_model === 'commercial' &&
        profile.contact === this.props.modelid)
      if (current_profiles.length && !listing_model) {
        listing_model = { value: 'commercial', label: 'Commercial' }
      }
    }
    const models = current_profiles.map(profile => profile.listing_model)
    const active_models = current_profiles.filter(profile => profile.status !== 'Inactive').map(profile => profile.listing_model)

    const options = this.listing_models.filter(option => models.includes(option.value))
    const active_options = this.listing_models.filter(option => active_models.includes(option.value))
    let default_model = getIn(this.state, 'default_model', listing_model)
    if (active_models.length === 1) {
      default_model = active_options[0]
    } else if (options.length) {
      default_model = listing_model
    }
    this.setState({ default_model, listing_model, options })
  }

  render() {
    const {
      model,
      config,
      configs,
      modelid,
      cache,
      actions,
      user,
      match,
      settings
    } = this.props

    const { statistics } = model.meta
    const label_mapping = {
      'consent.consent_emails': 'Emails',
      'consent.consent_messages': 'SMS / Direct Messages',
      'consent.consent_calls': 'Phone Calls',
      'consent.consent_information': 'Hold & Process Information'
    }
    const consent = [ 'consent.consent_emails', 'consent.consent_messages', 'consent.consent_calls', 'consent.consent_information' ].map((f, fidx) => {
      const field = config.fields.find(fe => fe.name === f)
      let val = getIn(model, field.name)
      if (field.modelname || field.metafield || field.container) {
        if (field.optionlabel) {
          val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
        }
        if (field.container) {
          val = getIn(model, `${field.container}.${field.name}`)
        }
      }
      return val ? (
        <MetaDetail
          className={'dashboard-meta subtle-meta col-lg-6'}
          key={`f${fidx}`}
          label={label_mapping[f]}
          value={val}
          field={field}
        />
      ) : null
    }).filter(f => f)
    return (
      <HorizontalTabs
        addons={this.props.addons}
        config={this.props.routeConfig}
        location={this.props.location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >

        <Tab tab="dashboard" label="Dashboard">
          <div className="grid-container">
            <div className="grid-rows grid-col-4 grid-container">
              <Card
                classes="primary-bg grid-col-2 grid-row-2"
                bodyclass="stats-card"
                background
                header={
                  <strong style={{ fontSize: 14 }}>{model.first_name} {model.last_name}</strong>
                }
                body={
                  <div className="input-group gap-20 space-evenly">
                    <div className="contact-details no-wrap">
                      {[ 'cell_number', 'email' ].map((f, fidx) => {
                        const field = config.fields.find(fe => fe.name === f)
                        let val = model[field.name]
                        if (field.modelname || field.metafield) {
                          if (field.optionlabel) {
                            val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                          }
                        }
                        return (
                          <MetaDetail
                            className={'dashboard-meta'}
                            key={`f${fidx}`}
                            label={field.label}
                            value={val}
                            format={field.format}
                            field={field}
                          />
                        )
                      }
                      )}
                    </div>
                  </div>
                }
              />
              { settings.enable_popia ? (
                <Card
                  classes="primary-bg grid-col-2 grid-row-2"
                  bodyclass="stats-card no-top-padding"
                  background
                  header={
                    <strong>Communication Preferences</strong>
                  }
                  body={
                    <div className="input-group gap-20 space-evenly">
                      <div className="contact-details">
                        {consent.length ? (
                          consent
                        ) : (
                          <div className="dashboard-meta subtle-meta col-lg-6">
                            <strong className="heavy">No consent received</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              ) : null }
              <Card
                classes="primary-bg grid-col-1"
                bodyclass={classNames('stats-card-mini', { 'no-top-padding': settings.enable_popia })}
                background
                style={!settings.enable_popia ? { order: 1 } : null}
                header={settings.enable_popia ? (
                  <strong>Leads</strong>
                ) : null}
                body={
                  settings.enable_popia ? (
                    <h1>{statistics.active_electronic_leads}</h1>
                  ) : (
                    <div className="flex-container justify-between">
                      <strong>Leads</strong><h3>{statistics.active_electronic_leads}</h3>
                    </div>
                  )
                }
              />
              <Card
                classes="primary-bg grid-col-1"
                bodyclass={classNames('stats-card-mini', { 'no-top-padding': settings.enable_popia })}
                background
                style={!settings.enable_popia ? { order: 2 } : null}
                header={settings.enable_popia ? (
                  <strong>Buyer Profiles</strong>
                ) : null}
                body={
                  settings.enable_popia ? (
                    <h1>{statistics.profiles_buyer}</h1>
                  ) : (
                    <div className="flex-container justify-between">
                      <strong>Buyer Profiles</strong><h3>{statistics.profiles_buyer}</h3>
                    </div>
                  )
                }
              />
              <Card
                classes="primary-bg grid-col-1"
                bodyclass={classNames('stats-card-mini', { 'no-top-padding': settings.enable_popia })}
                background
                style={!settings.enable_popia ? { order: 4 } : null}
                header={settings.enable_popia ? (
                  <strong>Tenant Profiles</strong>
                ) : null}
                body={
                  settings.enable_popia ? (
                    <h1>{statistics.profiles_tenant}</h1>
                  ) : (
                    <div className="flex-container justify-between">
                      <strong>Tenant Profiles</strong><h3>{statistics.profiles_tenant}</h3>
                    </div>
                  )
                }
              />
              <Card
                classes="primary-bg grid-col-1"
                bodyclass={classNames('stats-card-mini', { 'no-top-padding': settings.enable_popia })}
                background
                style={!settings.enable_popia ? { order: 3 } : null}
                header={settings.enable_popia ? (
                  <strong>Subscriptions</strong>
                ) : null}
                body={
                  settings.enable_popia ? (
                    <h1>{statistics.active_mailing_list}</h1>
                  ) : (
                    <div className="flex-container justify-between">
                      <strong>Subscriptions</strong><h3>{statistics.active_mailing_list}</h3>
                    </div>
                  )
                }
              />
              <Card
                classes="grid-col-4"
                bodyclass="leads no-top-padding"
                background
                header={
                  <h3>Latest Leads</h3>
                }
                body={
                  <SimpleTable
                    config={configs.leads}
                    action={actions.fetchLeads}
                    parser={data => {
                      data.options = data.options ? data.options.map(d => {
                        const lead = { ...d }
                        if (lead.residential) {
                          lead.listing = lead.residential
                          lead.meta.listing = lead.meta.residential
                          lead.meta.listing.model = 'residential'
                          lead.meta.listing.address = generateAddress(lead.meta.listing)
                        }
                        if (lead.commercial) {
                          lead.listing = lead.commercial
                          lead.meta.listing = lead.meta.commercial
                          lead.meta.listing.model = 'commercial'
                          lead.meta.listing.address = generateAddress(lead.meta.listing)
                        }
                        if (lead.project) {
                          lead.listing = lead.project
                          lead.meta.listing = lead.meta.project
                          lead.meta.listing.model = 'project'
                          lead.meta.listing.address = generateAddress(lead.meta.listing)
                        }
                        if (lead.holiday) {
                          lead.listing = lead.holiday
                          lead.meta.listing = lead.meta.holiday
                          lead.meta.listing.model = 'holiday'
                          lead.meta.listing.address = generateAddress(lead.meta.listing)
                        }
                        return lead
                      }) : null
                      return data
                    }}
                    params={{
                      contact: modelid,
                      order_by: '-created',
                      meta_fields: [ 'agent', 'residential', 'commercial', 'project', 'holiday' ],
                      limit: 10
                    }}
                    header={[
                      {
                        label: 'Web Ref',
                        name: 'meta.listing.web_ref',
                        orderable: false,
                        format: 'listing_popup',
                        link: '/secure/:site/:meta.listing.model/:meta.listing.id'
                      },
                      {
                        label: 'Address',
                        name: 'meta.listing.address',
                        orderable: false
                      },
                      {
                        label: 'Source',
                        name: 'source',
                        orderable: true
                      },
                      {
                        label: 'Date Added',
                        name: 'created',
                        orderable: true,
                        format: 'datetime'
                      },
                      {
                        label: 'Enquiry',
                        name: 'message',
                        format: 'domtext'
                      },
                      {
                        label: 'Stage',
                        name: 'stage',
                        format: 'stage',
                        orderable: true,
                        link: ':meta.listing.link/../leads/:id'
                      }
                    ]}
                    user={user}
                    rowActions={(row, data) => (
                      <>
                        <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${data.site}/leads/${data.id}`} title="View Lead" type="button" />
                        {data.status !== 'Inactive' ? (
                          <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.archiveModel('leads', data.id, () => {
                            data.status = 'Inactive'
                            row.forceUpdate()
                          })} title="Archive Lead" type="button" />
                        ) : null}
                      </>
                    )}
                  />
                }
              />
              <Card
                classes="grid-col-4"
                bodyclass="profiles no-top-padding"
                background
                header={
                  <>
                    <h3>Profiles</h3>
                    {this.state.options.length > 1 && this.state.default_model &&
                    <div className="details-section-buttons tablemeta">
                      <div className="profile-listing-model">
                        <InlineSelect
                          id="listing_model"
                          name="listing_model"
                          className="inline-select"
                          classNamePrefix="inline"
                          prefix={<div className="input-group-addon">Listing Type: </div>}
                          defaultValue={this.state.default_model}
                          selectedValue={getIn(this.state, 'listing_model.value')}
                          options={this.state.options}
                          onChange={e => {
                            this.filterListingModel(e)
                          }}
                        />
                      </div>
                    </div>
                    }
                  </>
                }
                body={
                  <SimpleTable
                    config={configs.profiles}
                    action={actions.fetchProfiles}
                    params={{
                      contact: modelid,
                      order_by: '-created',
                      meta_fields: [ 'agent', 'contact' ],
                      limit: 10
                    }}
                    parser={data => {
                      data.options = data.options.map(d => {
                        const profile = { ...d }
                        if (!d.agent) {
                          profile.agent = getIn(profile, 'meta.contact.introduction_agent')
                          profile.meta.agent = getIn(profile, 'meta.contact.meta.introduction_agent')
                        }
                        if (!profile.agent) {
                          profile.agent = getIn(model, 'introduction_agent')
                          profile.meta.agent = getIn(model, 'meta.introduction_agent')
                        }
                        let range = ''
                        const col = { currency: cache.settings[user.agent.site.id].default_currency }
                        if (profile.listing_model.toLowerCase() === 'residential') {
                          range = valueFormat('price_range', `${profile.price_from} ${profile.price_to}`, col)
                        } else {
                          const p_from = profile.floor_size_from
                          const p_to = profile.floor_size_to
                          if (p_from !== p_to) {
                            range = valueFormat('size_range', `${p_from} ${p_to}`)
                          } else {
                            range = 'Any'
                          }
                        }
                        profile.range = range
                        return profile
                      })
                      this.getOptions(data.options)
                      return data
                    }}
                    header={[
                      {
                        label: 'ID',
                        name: 'id',
                        orderable: true
                      },
                      {
                        label: 'Date Added',
                        name: 'created',
                        orderable: true,
                        format: 'datetime'
                      },
                      {
                        label: 'Profile Type',
                        name: 'profile_type',
                        orderable: true
                      },
                      {
                        label: 'Suburbs',
                        name: 'suburbs',
                        orderable: true,
                        modelname: 'location',
                        optionlabel: [ 'suburb' ],
                        labelseparator: ', '
                      },
                      {
                        label: 'Property Types',
                        name: 'property_types',
                        orderable: true
                      },
                      {
                        label: getIn(this.state, 'listing_model.value', 'residential') === 'residential' ? 'Price Range' : 'Size Range',
                        name: 'range',
                        orderable: false
                      },
                      {
                        label: 'Status',
                        name: 'status',
                        orderable: true
                      }
                    ]}
                    user={user}
                    rowActions={(row, data) => (
                      <>
                        <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/profiles/${data.id}`} title="View Profile" type="button" />
                        {data.status !== 'Inactive' ? (
                          <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.archiveModel('leads', data.id, () => {
                            data.status = 'Inactive'
                            row.forceUpdate()
                          })} title="Archive Profile" type="button" />
                        ) : null}
                      </>
                    )}
                  />
                }
              />
            </div>
          </div>
        </Tab>

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="valuations" label="Valuations">
          <Valuations
            configs={configs}
            actions={actions}
            modelid={modelid}
            model={model}
            modelname={config.modelname}
            user={user}
          />
        </Tab>

        <Tab tab="leads" label="Leads">
          <ContactLeads
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
            archiveModel={this.archiveModel}
          />
        </Tab>

        <Tab tab="buyerprofiles" profiletype='buyer' label="Buyer Profiles">
          <ContactProfiles
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
            profileType="buyer"
          />
        </Tab>

        <Tab tab="tenantprofiles" profiletype='tenant' label="Tenant Profiles">
          <ContactProfiles
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
            profileType="tenant"
          />
        </Tab>

        <Tab tab="marketing" label="Marketing">
          <Marketing
            actions={actions}
            modelid={modelid}
            modelname={config.servicename}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="alerts-sent" label="Alerts Sent">
          <ContactAlerts
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="listings" label="Listings">
          <ContactListings
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="subscriptions" label="Subscriptions">
          <ContactSubscriptions
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="referrals" label="Referrals">
          <ContactReferrals
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="offers" label="Offers">
          <ContactOffers
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="applications" label="Applications">
          <ContactApplications
            configs={configs}
            actions={actions}
            modelid={modelid}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Contact.propTypes = {
  addons: PropTypes.array,
  actions: PropTypes.object,
  selected: PropTypes.array,
  model: PropTypes.object,
  routeConfig: PropTypes.object,
  modelid: PropTypes.number,
  location: PropTypes.object,
  title: PropTypes.string,
  tab: PropTypes.string,
  match: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  settings: PropTypes.object,
  cache: PropTypes.object,
  stats: PropTypes.object,
  user: PropTypes.object,
  alerts: PropTypes.object
}

export default Contact
