import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import { components } from 'react-select'
import isEqual from 'react-fast-compare'
import AsyncCreatableSelect from 'react-select/creatable'

import extras from '../../../../config/extras.json'
import { useCustomCompareMemo } from '../../../../utils'
import { responsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const ResponsiveAsyncCreatableSelect = responsiveSelect(AsyncCreatableSelect)

const CustomOption = props => {
  const { label, portals, hidelegend, group } = props.data
  const tags = portals ? portals.map(p => extras.legend[p]) : []
  return (
    <components.Option {...props} >
      <div className="extras-opt">
        <div className="extras-label">
          {label}
        </div>
        {!hidelegend &&
        <div className="extras-tags">
          {group ? <span className="extras-tag group-tag">{group}</span> : null}
          {tags.map((t, idx) => (
            <span className={`extras-tag ${t}`} key={`extras-${idx}`}>{t}</span>
          ))}
        </div>
        }
      </div>
    </components.Option>
  )
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const CustomMultiValueLabel = props => {
  const { label, portals, hidelegend, group } = props.data
  const tags = portals ? portals.map(p => extras.legend[p]) : []
  return (
    <components.MultiValueLabel {...props} >
      <div className="extras-selected">
        <div className="extras-label">
          {label}
        </div>
        {!hidelegend &&
        <div className="extras-tags">
          {group ? <span className="extras-tag group-tag">{group}</span> : null}
          {tags.map((t, idx) => (
            <span className={`extras-tag ${t}`} key={`extras-${idx}`}>{t}</span>
          ))}
        </div>
        }
      </div>
    </components.MultiValueLabel>
  )
}

CustomMultiValueLabel.propTypes = {
  data: PropTypes.object
}

const filterOption = ({ data }, string) => {
  // default search
  if (
    data.label.toLowerCase().includes(string.toLowerCase())
    || data.value.toLowerCase().includes(string.toLowerCase())
    || (data.group && data.group.toLowerCase().includes(string.toLowerCase()))
  ) {
    return true
  }

  return false
}

const Extras = props => {
  const {
    options: shownoptions,
    field,
    form,
    id,
    noclear,
    placeholder,
    closemenuonselect,
    classes,
    label,
    readonly,
    disabled,
    hidelegend
  } = props

  const findValues = (v, options, values) => {
    if ([ null, undefined ].includes(v)) {
      return []
    }
    options.forEach(o => {
      if (o.options) {
        values = [ ...values, ...findValues(v, o.options, []) ]
      }
      // eslint-disable-next-line
      if ((Array.isArray(v) && v.includes(o.value)) || v == o.value) {
        values.push(o)
      }
    })
    return values.sort((a, b) => v.indexOf(a.value) - v.indexOf(b.value))
  }

  const init = field.value ? findValues(field.value.split(/\r?\n/), shownoptions, []) || null : null
  const [ val, setVal ] = useState(init)
  const ref = useRef(null)

  const handleChange = v => {
    if (v) {
      let vals = []
      if (Array.isArray(v) && v.length > 0) { // Array value with values
        vals = v
      } else if (v.value) {
        vals.push(v)
      } else if (v.length) {
        vals = v
      } else {
        vals = null
      }
      vals = vals || []
      if (vals && Array.isArray(vals)) {
        vals = vals.map(opt => opt.value).join('\n')
      }
      props.form.setFieldValue(field.name, vals).then(() => {
        props.form.setFieldTouched(field.name)
      })
      if (props.dependents) { /* Unset any dependents */
        props.dependents.forEach(dependent => {
          props.form.setFieldValue(dependent, null, false)
        })
      }
    } else {
      props.form.setFieldValue(field.name, null).then(() => {
        props.form.setFieldTouched(field.name)
      })
    }
  }

  useEffect(() => {
    if (!isEqual(init, val)) {
      setVal(init)
    }
  }, [ useCustomCompareMemo(init) ])

  if (!field.name) { return null }
  return (
    <>
      {(!hidelegend) && // Legend hidden in adv. search
        <div className="extras-legend">
          {props.portals && props.portals
            .filter(p => Object.keys(extras.legend).includes(p.slug))
            .map((portal, idx) => {
              const tag = extras.legend[portal.slug]
              return (
                <div key={`extras-${idx}`}>
                  <span className={`extras-tag ${tag}`}>{tag}</span>
                  <span className="extras-label">{portal.name}</span>
                </div>
              )
            })}
        </div>
      }
      <div
        id={id}
        className={`selectinput ${field.name} form-group ${classes}`}
        ref={ref}>
        {label &&
            <Label htmlFor={field.name}>{label}</Label>
        }
        <div className="forminput">
          <ResponsiveAsyncCreatableSelect
            className={'react-select'}
            classNamePrefix="react-select"
            options={shownoptions}
            filterOption={filterOption}
            isDisabled={readonly || disabled ? true : false}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value || ''}
            name={'extras-select'}
            id={'extras-select'}
            form={form}
            field={field}
            isMulti={true}
            value={val}
            onChange={props.onChange || handleChange}
            closeMenuOnSelect={closemenuonselect}
            menuShouldScrollIntoView={document.getElementById('wrapper') ? !document.getElementById('wrapper').classList.contains('touch') : null}
            blurInputOnSelect={closemenuonselect}
            onSelectResetsInput={closemenuonselect}
            backspaceRemovesValue={closemenuonselect}
            isSearchable={document.getElementById('wrapper') ? !document.getElementById('wrapper').classList.contains('touch') : true}
            placeholder={placeholder}
            isClearable={noclear ? false : true}
            components={{
              Option: CustomOption,
              MultiValueLabel: CustomMultiValueLabel
            }}
            onBlur={() => form && form.setFieldTouched(field.name)} // is used for error message
          />
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </div>
    </>
  )
}

Extras.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  dependents: PropTypes.array,
  portals: PropTypes.array,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  noclear: PropTypes.bool,
  placeholder: PropTypes.string,
  closemenuonselect: PropTypes.bool,
  hidelegend: PropTypes.bool,
  onChange: PropTypes.func
}

export default Extras
