/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from 'react'
import { FormikContext } from 'formik'
import isEqual from 'react-fast-compare'
import { useCustomCompareMemo } from '../../../utils'


export const formikConnect = WrappedComponent => props => {
  // gets rid of the warning
  const formik = useContext(FormikContext)

  const [ form, setForm ] = useState(formik)

  useEffect(() => {
    if (!form && !formik) { return () => {} }
    const {
      values,
      errors,
      touched,
      isSubmitting,
      submitCount,
      initialErrors,
      initialValues,
      initialTouched,
      isValid,
      dirty,
      validateOnBlur,
      validateOnChange,
      validateOnMount
    } = form
    const {
      values: formikvalues,
      errors: formikerrors,
      touched: formiktouched,
      isSubmitting: formikisSubmitting,
      submitCount: formiksubmitCount,
      initialValues: formikinitialValues,
      initialErrors: formikinitialErrors,
      initialTouched: formikinitialTouched,
      isValid: formikisValid,
      dirty: formikdirty,
      validateOnBlur: formikvalidateOnBlur,
      validateOnChange: formikvalidateOnChange,
      validateOnMount: formikvalidateOnMount
    } = formik
    if (!isEqual({
      values,
      errors,
      touched,
      isSubmitting,
      submitCount,
      initialValues,
      initialErrors,
      initialTouched,
      isValid,
      dirty,
      validateOnBlur,
      validateOnChange,
      validateOnMount
    }, {
      values: formikvalues,
      errors: formikerrors,
      touched: formiktouched,
      isSubmitting: formikisSubmitting,
      submitCount: formiksubmitCount,
      initialValues: formikinitialValues,
      initialErrors: formikinitialErrors,
      initialTouched: formikinitialTouched,
      isValid: formikisValid,
      dirty: formikdirty,
      validateOnBlur: formikvalidateOnBlur,
      validateOnChange: formikvalidateOnChange,
      validateOnMount: formikvalidateOnMount
    })) {
      setForm(formik)
    }
    return () => {}
  }, [ useCustomCompareMemo(formik) ])
  return (
    <WrappedComponent
      {...props}
      form={form}
    />
  )
}
