import * as yup from 'yup'
import messages from './messages.json'


export const login = () => yup.object({
  username: yup.string(),
  password: yup.string()
}).test(
  {
    message: 'User name is required',
    test: schema => {
      const { username, code } = schema
      if (!username && !code) {
        return new yup.ValidationError(messages.required, username, 'username')
      }
      return true
    }
  }
).test(
  {
    message: 'Password is required',
    test: schema => {
      const { password, code } = schema
      if (!password && !code) {
        return new yup.ValidationError(messages.required, password, 'password')
      }
      return true
    }
  }
)
