import PropTypes from 'prop-types'
import React from 'react'

import ModelActions from '../common/ModelActions'
import Step from '../common/Step'


class Content extends React.Component {
  render() {
    const { model, config, selected } = this.props

    return (

      <div className="view branch container-fluid">
        <div className="row">

          <div className="col">
            <div className="viewhead">
              <div className="row">
                <div className="col">
                  <div className="action-bar">
                    <ModelActions
                      modelname={config.modelname}
                      singleton={true}
                      singular={config.singular}
                      modelid={this.props.modelid}
                    />

                    { selected && selected.length > 1 &&
                    <Step
                      stepPage={this.stepPage}
                      next={this.state.next}
                      previous={this.state.previous}
                      selected={selected}
                      modelid={model.id}
                    />
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        {/* <div className="row">
          <div className="col">
            <Card
              background={true}
              classes="tab-container"
              body={
                <HorizontalTabs
                  config={this.props.routeConfig}
                  location={this.props.location}
                  match={this.props.match}
                  model={model}
                  defaultTab="details"
                  user={{ permissions: user.permissions, agent: user.agent }}
                >

                  <Tab tab="dashboard" label="Toolbox">
                    <div className="col dashboard">
                      {Object.keys(this.props.config.dashboard).map((toolgroup, tidx) => <ToolGroup
                        groupname={toolgroup}
                        group={config.dashboard[toolgroup]}
                        key={`tool-${tidx}`}
                        model={model}
                        user={user}
                        match={match}
                        addons={addons}
                        toggleNoteCreator={actions.toggleNoteCreator}
                      />)}
                    </div>
                  </Tab>

                  <Tab tab="details" label="Details" noScroll>
                    <div className="branch-details details">
                      <DetailsSection className="singlemeta">
                        <MetaDetail
                          label={'Record ID'}
                          value={this.props.modelid}
                        />
                        <MetaDetail
                          label={'Created'}
                          value={this.props.model.created}
                          format={'datetime'}
                        />
                        <MetaDetail
                          label={'Modified'}
                          value={this.props.model.modified}
                          format={'datetime'}
                        />
                        <MetaDetail
                          label={'Status'}
                          value={this.props.model.active ? 'Active' : 'Inactive'}
                        />

                      </DetailsSection>


                    </div>
                  </Tab>

                  <Tab tab="media" label="Media">
                    <DetailsSection>
                      <div className="row">
                        <div className="col">
                          { model.images || model.documents ? (
                            <MediaGallery
                              media={[
                                {
                                  items: model.meta.images,
                                  mediatype: 'image',
                                  fieldname: 'images',
                                  plural: 'images',
                                  label: 'Images'
                                }, {
                                  items: model.meta.documents,
                                  mediatype: 'documents',
                                  fieldname: 'documents',
                                  plural: 'documents',
                                  label: 'Documents'
                                }
                              ]}
                              model={model}
                              modelname={config.modelname}
                            />
                          ) : (
                            `This ${config.singular} has no media yet.`
                          )
                          }
                        </div>
                      </div>
                    </DetailsSection>
                  </Tab>

                  <Tab tab="notes" label="Notes / Actions">
                    <Notes
                      config={config}
                      model={model}
                      user={user}
                      match={match}
                      agents={cache && cache.agents ? cache.agents : {}}
                      actions={actions}
                      cache={cache}
                    />
                  </Tab>

                  <Tab tab="activity" label="Activity">
                    <Activity
                      model={model}
                      cache={cache}
                      config={this.props.config}
                      events={model.activity}
                      settings={{ currency: settings[user.agent.site.id].default_currency }}
                      users={this.props.cache && this.props.cache.agents ? this.props.cache.agents : {}}
                      fetchActivity={this.props.actions.fetchActivity}
                      fetchOne={this.props.actions.fetchOne}
                      fetchMany={actions.fetchMany}
                    />
                  </Tab>
                </HorizontalTabs>
              }
            />
          </div>
        </div> */}
      </div>

    )
  }
}

Content.propTypes = {
  selected: PropTypes.array,
  modelid: PropTypes.number,
  model: PropTypes.object,
  title: PropTypes.string,
  config: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object
}

export default Content
