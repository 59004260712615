/* eslint-disable new-cap */
import { getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { Label, Legend, Pie, PieChart, Surface, Symbols, Text } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany, fetchListingCounts } from '../../actions'
import date_options from '../../config/date-options.json'
import { CONFIG, MINUSER } from '../../selectors'
import { getRandomColor, hasPermission, textToDate, title, valueFormat, breakpoint, buildOptionLabel, getTextWidth } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'
import withDelay from './withDelay'


const RADIAN = Math.PI / 180
const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class StockWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'LAST_30_DAYS')
    const period = option.value
    const { start, end } = textToDate(option.value)
    this.state = {
      count: 0,
      negative: false,
      period,
      branches: [],
      branch_options: [],
      agents: [],
      listing_options: [],
      mandate_types: [],
      showActions: breakpoint.matches,
      branch_id: props.branches && props.branches.length === 1 ? props.branches[0] : '',
      agent_id: '',
      show_agents: true,
      listing_model: '',
      statistics: [],
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      loading: true
    }
    this.toggleActions = this.toggleActions.bind(this)
    this.filterListings = this.filterListings.bind(this)
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
    this.renderCustomizedLegend = this.renderCustomizedLegend.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user, branches } = this.props
    const listing_options = []
    let default_model
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
      if (!default_model) {
        default_model = { value: 'residential_for_sale', label: 'Residential For Sale' }
      }
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
      if (!default_model) {
        default_model = { value: 'residential_to_let', label: 'Residential To Let' }
      }
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
      if (!default_model) {
        default_model = { value: 'commercial_for_sale', label: 'Commercial For Sale' }
      }
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
      if (!default_model) {
        default_model = { value: 'commercial_to_let', label: 'Commercial To Let' }
      }
    }
    if (hasPermission([
      'listings_projects_view',
      'listings_projects_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'projects', label: 'Project Listings' })
      if (!default_model) {
        default_model = { value: 'projects', label: 'Project Listings' }
      }
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'holiday', label: 'Holiday Listings' })
      if (!default_model) {
        default_model = { value: 'holiday', label: 'Holiday Listings' }
      }
    }
    this.setState({ listing_options, default_model, listing_model: default_model ? default_model.value : '' })
    if (!branches || branches.length > 1) {
      let agent_branches = []
      if (!hasPermission([
        'apply_to_all_branches'
      ], user.permissions, null, user.agent.id)) {
        agent_branches = [ ...user.agent.branches ]
      }
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'branches',
          optionvalue: 'id',
          optionlabel: 'name',
          fields: [ 'id', 'name' ],
          active: 1,
          all: true,
          select: true,
          params: {
            id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
            order_by: 'name'
          },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => {
        if (this._is_mounted) {
          const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
          this.setState({ branches: r.options, branch_options })
        }
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
    }
    let agent_id = this.state.agent_id
    let branch_id = this.state.branch_id
    if (user.agent) {
      if (!agent_id && getIn(user, 'agent.id')) {
        agent_id = user.agent.id
      }
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && user.agent.branches.length === 1
        && !branch_id
      ) {
        branch_id = user.agent.branches[0]
      }
    }
    if (agent_id !== this.state.agent_id || branch_id !== this.state.branch_id) {
      this.setState({ agent_id, branch_id })
    }
    this.filterListings()
    breakpoint.addEventListener('change', this.toggleActions)
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props
    if (
      prevState.listing_model !== this.state.listing_model
      || prevState.agent_id !== this.state.agent_id
      || prevState.branch_id !== this.state.branch_id
    ) {
      this.filterListings()
    }
    const agent_id = this.state.agent_id
    let branch_id = this.state.branch_id
    if (user.agent) {
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && user.agent.branches.length === 1
        && !branch_id
      ) {
        branch_id = user.agent.branches[0]
      }
    }
    if (agent_id !== this.state.agent_id || branch_id !== this.state.branch_id) {
      this.setState({ agent_id, branch_id })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
    this._is_mounted = false
    this.AbortController.abort()
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  filterListings() {
    const { actions, status_fields, branches } = this.props
    this.setState({ loading: true })
    const { agent_id, listing_model: listing, branch_id } = this.state
    if (!listing) { return }
    let modelname = listing
    let listing_type = null
    try {
      const re = /(?<modelname>.*)_(?<listing_type>for_sale|to_let)/g
      const groups = re.exec(listing).groups
      modelname = groups.modelname
      listing_type = groups.listing_type
    } catch (e) {
      console.error(e)
    }
    if (!modelname) { return }
    const statuses_needed = getIn(status_fields, modelname, { options: [] }).options.map(o => o.value).sort()
    new Promise((resolve, reject) => actions.fetchListingCounts({
      values: {
        params: {
          model: modelname === 'projects' ? 'project' : modelname,
          branch__in: branch_id ? [ branch_id ] : branches,
          listing_type__in: listing_type ? title(listing_type.replace('_', ' ')) : '',
          agents__in: agent_id,
          order_by: '-created'
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(r => {
      const statistics = statuses_needed.map((status, sid) => ({
        label: status,
        value: r[status],
        fill: getRandomColor(sid)
      }))
      if (this._is_mounted) {
        this.setState({ statistics, modelname, listing_type, loading: false })
      }
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
      if (this._is_mounted) {
        this.setState({ loading: false })
      }
    })
  }

  getColour(value) {
    if (value < 65) {
      return '#FF6464'
    }
    if (value < 80) {
      return '#FFA564'
    }
    return '#73C677'
  }

  renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent }) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const value = (percent * 100).toFixed(0)
    let scale = radius / 99.6
    const width = getTextWidth(value, `${12 * scale}px Poppins`)
    const max = (radius - 5) * 2
    if (width > max) {
      const offset = max / width
      scale *= offset
    }
    if (scale < 0.6) {
      return null
    }
    if (value > 3) {
      return (
        <Text x={x} y={y} fontFamily="Poppins" fontSize={12 * scale} fill="white" textAnchor="middle" verticalAnchor='middle'>
          {`${value}%`}
        </Text>
      )
    }
    return null
  }

  renderCustomizedLegend(props) {
    const { payload, chartWidth, chartHeight, layout } = props
    const { user, branches } = this.props
    const { modelname, listing_type, branch_id, agent_id } = this.state
    return (
      <div className="customized-legend-vertical" style={{ width: layout === 'vertical' ? chartWidth - chartHeight : chartWidth }}>
        {
          payload.map((entry, eid) => {
            const { payload: data, color } = entry
            if (data.label === 'No Leads Found') {
              return null
            }
            return (
              <span key={`overlay-${data.label}-${eid}`} className="legend-item">
                <Surface width={10} height={10} viewBox={{
                  x: 0,
                  y: 0,
                  width: 10,
                  height: 10
                }}>
                  <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                </Surface>
                <span className="legend-label"><NavLink to={`/secure/${user.agent.site.id}/${modelname}/?status__in=${data.label}&listing_type__in=${listing_type ? title(listing_type.replace('_', ' ')) : ''}${branch_id ? `&branch__in=${branch_id}` : ''}${!branch_id && branches ? `&branch__in=${branches.join(',')}` : ''}${agent_id ? `&agent__in=${agent_id}` : ''}&order_by=-created`}>{data.label}</NavLink></span>
                <span className="legend-count">{valueFormat('number', data.value)}</span>
              </span>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { user } = this.props
    const { statistics: data, showActions, branch_id, branches } = this.state
    return (
      <Card
        id="stock-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding"
        background
        header={
          <>
            <h3>Stock</h3>
            <div className="details-section-buttons min-flex tablemeta">
              {(!branches || branches.length > 1) ? (
                <div className="filter-branch">
                  <InlineSelect
                    id="branch_id"
                    name="branch_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                    defaultValue={{ label: 'All Branches', value: '' }}
                    selectedValue={getIn(this.state, 'branch_id')}
                    onChange={e => {
                      this.setState({ branch_id: e.value })
                    }}
                  />
                </div>
              ) : null }
              {this.state.show_agents ? (
                <div className="filter-agent">
                  <AsyncInlineSelect
                    id="agent_id"
                    name="agent_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    defaultValue={{ first_name: 'All', last_name: 'Agents', id: '' }}
                    options={user.agent.id ? [ { first_name: 'All', last_name: 'Agents', id: '' }, { ...user.agent } ] : [ { first_name: 'All', last_name: 'Agents', id: '' } ]}
                    form={{}}
                    modelname="agents"
                    labelseparator=" "
                    fetchMany={this.props.actions.fetchMany}
                    optionlabel={[ 'first_name', 'last_name' ]}
                    noclear
                    params={{
                      branches__overlap: branch_id ? [ branch_id ] : branches.map(b => b.id),
                      active: 1,
                      order_by: 'first_name,last_name',
                      fields: 'id,first_name,last_name'
                    }}
                    field={{
                      value: getIn(this.state, 'agent_id')
                    }}
                    onChange={e => {
                      const agent_id = e.value
                      this.setState({ agent_id })
                    }}
                  />
                </div>
              ) : null}
              <div className="filter-listing-model">
                <InlineSelect
                  id="listing_model"
                  name="listing_model"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={this.state.listing_options}
                  defaultValue={this.state.default_model || ''}
                  selectedValue={getIn(this.state, 'listing_model')}
                  onChange={e => {
                    const listing_model = e.value
                    this.setState({ listing_model })
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          !this.state.loading ? (
            <ResponsiveContainer width={'100%'} height={240} debounce={1} legendWrap={!showActions}>
              <PieChart cx="50%" cy="50%" margin={0}>
                <Legend wrapperStyle={{ width: showActions ? null : '100%', height: showActions ? '100%' : null, bottom: showActions ? null : 1 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={showActions ? 'top' : 'bottom'} align="right" content={this.renderCustomizedLegend} data={data} />
                <Pie
                  startAngle={90}
                  endAngle={-270}
                  data={[ { value: 100, label: 'No Leads Found' } ]} dataKey="value" innerRadius="66%" outerRadius="100%" fill="#F3F5F8" isAnimationActive={false} label={false}
                >
                  {!data.length ?
                    <Label fontFamily='Poppins' position="center" content={test => {
                      if (!test.viewBox.innerRadius) { return null }
                      let scale = test.viewBox.innerRadius / 79.2
                      const width = getTextWidth('0', `${34 * scale}px Poppins`)
                      const max = (test.viewBox.innerRadius - 5) * 2
                      if (width > max) {
                        const offset = max / width
                        scale *= offset
                      }
                      return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">0</Text>
                    }}></Label> : null}
                </Pie>
                <Pie
                  angleAxisId={0}
                  minAngle={1}
                  background={{ fill: '#ffffff' }}
                  clockWise
                  startAngle={90}
                  endAngle={-270}
                  innerRadius="66%"
                  outerRadius="100%"
                  isAnimationActive={false}
                  data={data}
                  labelLine={false}
                  strokeWidth={0}
                  label={this.renderCustomizedLabel}
                  dataKey="value"
                >
                  <Label fontFamily='Poppins' position="center" content={test => {
                    if (!test.viewBox.innerRadius) { return null }
                    let scale = test.viewBox.innerRadius / 79.2
                    const total = data.map(d => d.value).reduce((a, b) => a + b, 0)
                    const width = getTextWidth(valueFormat('number', total), `${34 * scale}px Poppins`)
                    const max = (test.viewBox.innerRadius - 5) * 2
                    if (width > max) {
                      const offset = max / width
                      scale *= offset
                    }
                    return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">{valueFormat('number', total)}</Text>
                  }}></Label>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : <div className="empty flex-container" style={{ height: 340 }}><Loader inline className="large" /></div>
        }
      />
    )
  }
}

StockWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  status_fields: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const status_fields = Map({
    residential: CONFIG(state, 'residential').get('fields')?.find(f => f.get('name') === 'status'),
    commercial: CONFIG(state, 'commercial').get('fields')?.find(f => f.get('name') === 'status'),
    projects: CONFIG(state, 'projects').get('fields')?.find(f => f.get('name') === 'status'),
    holiday: CONFIG(state, 'holiday').get('fields')?.find(f => f.get('name') === 'status')
  })

  return ({
    user,
    status_fields
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, fetchListingCounts }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(StockWidget)))
