import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { sendReset } from '../actions'
import Forgot from '../components/Forgot'


const mapStateToProps = state => ({
  ui: state.get('ui')
})

const mapDispatchToProps = dispatch => bindActionCreators({ sendReset }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Forgot))
