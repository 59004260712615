/* eslint-disable new-cap */
import { Field, Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { PERMISSIONS, SITE, MINUSER, LOCATION } from '../../../../selectors'
import { handleSubmitError, updateSearchParms, getSearchParam, useCustomCompareMemo, breakpoint } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SimpleTable from '../../simpletable/SimpleTable'
import CustomForm from '../CustomForm'
import Card from '../../Card'
import FieldGroup from '../FieldGroup'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import AreaGroupsSidebar from '../../../../containers/AreaGroupsSidebar'
import PageJump from '../../datatable/PageJump'
import Step from '../../Step'
import QueryBuilder from '../../QueryBuilder'
import TextInput from './Text'
import InlineSelect from './InlineSelect'


const AreaGroupsManager = ({
  model,
  match,
  user,
  config,
  location,
  fetchMany,
  updateModel,
  createModel,
  deleteModel,
  toggleWideSidebar
}) => {
  const { fields } = config
  const qs = new QueryBuilder(location.search)
  const [ editing, setEditing ] = useState()
  const [ results, setResults ] = useState({
    offset: getIn(qs, 'params.offset', 0),
    limit: getIn(qs, 'params.limit', 20),
    count: 0,
    recordsto: 0,
    recordsfrom: 0,
    next: null,
    previous: null
  })
  const [ desktop ] = useState(breakpoint.matches)
  const area_groups = useRef()

  const calculateRecords = response => {
    let from = 0
    let to = 0
    let newoffset = results.offset
    let newlimit = results.limit
    let newcount = results.count
    if (response.params) {
      newoffset = response.params.offset
      newlimit = response.params.limit
      newcount = Number(response.count)
      from = Number(newoffset) + 1 || 1
      to = (from - 1 + newlimit) > newcount ? newcount : from - 1 + newlimit
    }
    setResults(previousState => (
      {
        ...previousState,
        offset: newoffset,
        limit: newlimit,
        count: newcount,
        recordsto: to,
        recordsfrom: from,
        next: response.next,
        previous: response.previous
      }))
  }

  const fetchGroups = ({ params, signal, resolve, reject }) => fetchMany({
    values: {
      modelname: 'area-groups',
      modellist: true,
      params: {
        ...params,
        domain: model.id
      },
      signal
    },
    resolve,
    reject
  })

  const stepPage = dir => { // Step through result pages
    const { limit, count } = results
    const url = results[dir]
    let newoffset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { newoffset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        newoffset = Math.floor(count / limit) * limit
        break
      default:
        newoffset = 0
    }
    updateSearchParms('offset', newoffset)
  }

  const changelimit = e => {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
  }

  const createGroup = (values, formikBag) => {
    formikBag.setSubmitting(true)
    return new Promise((resolve, reject) => {
      values.modelname = 'area-groups'
      createModel({ values, resolve, reject })
    }).then(() => {
      formikBag.setSubmitting(false)
      area_groups.current?.refreshPage()
    }).catch(e => {
      formikBag.setSubmitting(false)
      handleSubmitError(e, formikBag, { ...formikBag, values })
    })
  }

  const deleteGroup = (area_group, callback) => new Promise((resolve, reject) => {
    const values = {
      modelname: 'area-groups',
      selected: [ area_group.id ]
    }
    // eslint-disable-next-line no-alert
    const confirmation = confirm('Are you sure you want to delete this search group?')
    if (confirmation) {
      return deleteModel({ values, resolve, reject })
    }
    return reject('')
  }).then(r => {
    callback(r)
  })

  const addVerb = () => {
    if (fields) {
      const report_fields = fields.filter(field => field.edit).map((field, ind) => ({
        ...field,
        name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
        key: `adv-${field.name}-${ind}`,
        edit: true
      }))
      return <FieldGroup
        card={false}
        match={match}
        groupname="Record Details"
        config={{
          fields
        }}
        fields={report_fields}
      />
    }
    return null
  }

  const handleReset = () => {
    const params = {
      ...config.params,
      term: 0
    }
    config.advsearch.forEach(group => {
      group.forEach(k => {
        let fname = k.name
        if (k.verb) { fname = `${fname}__${k.verb}` }
        params[fname] = 0
      })
    })
    updateSearchParms(params)
  }

  useEffect(() => {
    if (editing) {
      toggleWideSidebar('show-area-groups-sidebar')
    }
  }, [ useCustomCompareMemo(editing) ])

  return (
    <div className="area-groups-manager">
      <Card
        header={
          <h3>Add New Grouping</h3>
        }
        background
        body={
          <Formik
            initialValues={{
              domain: model.id
            }}
            enableReinitialize={true}
            onSubmit={createGroup}
          >{formik => (
              <CustomForm
                component="div"
                render={() => (
                  <div className="search-fields">
                    {addVerb()}
                    <div className="search-buttons" style={{ paddingRight: 0 }}>
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button"
                        icon="#icon24-Plus"
                        onClick={formik.submitForm}
                        disabled={formik.isSubmitting}
                        className="btn btn-grey btn-icon-16 btn-icon-left"
                      >
                        Add Group
                      </Button>
                    </div>
                  </div>
                )}
              />
            )}
          </Formik>
        }
      />
      <Card
        header={
          <h3>Grouping List</h3>
        }
        background
        body={
          <div className="area-groups-search">
            <Formik
              initialValues={{
                domain: model.id,
                model: 'area-groups',
                term: getSearchParam('term')
              }}
              enableReinitialize={true}
              onSubmit={values => {
                updateSearchParms({
                  ...config.params,
                  term: values.term
                })
              }}
            >{formik => (
                <>
                  <CustomForm
                    component="div"
                    render={() => (
                      <div className="area-groups-search search-fields">
                        <div className="input-group keyword-search">
                          <Field
                            id="area-groups-search"
                            name="term"
                            placeholder="Keyword Search"
                            component={TextInput}
                            defaultValue={getSearchParam('term')}
                            className="term"
                            bounce={true}
                            show_search
                            suffix={<Button icon="#icon24-Search" type="button" onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                          />
                          {getSearchParam('term') ? (
                            <div className="reset-group">
                              <strong>This list is filtered</strong>
                              <Button
                                id="keyword-search-btn"
                                tabIndex="-1"
                                type="button"
                                icon="#icon16-Refresh"
                                onClick={handleReset}
                                className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                              >
                                Reset
                              </Button>
                            </div>
                          ) : null}
                        </div>

                        <div className="page-tools">
                          <PageJump
                            params={{ offset: results.offset, limit: results.limit, count: results.count }}
                            endpoint={getIn(config, 'endpoint')}
                            modelname={getIn(config, 'modelname')}
                            count={results.count}
                          />

                          <div className="page-buttons">
                            <Step
                              stepPage={stepPage}
                              next={results.next}
                              previous={results.previous}
                            />

                            {desktop && <div className="record-count">
                              {results.recordsto ? results.recordsfrom : 0} - {results.recordsto} of {results.count}
                            </div> }
                            {desktop && <InlineSelect
                              id="field-limit"
                              name="limit"
                              className="inline-select meta-limit"
                              classNamePrefix="inline"
                              defaultValue={{ value: results.limit, label: results.limit.toString(10) }}
                              selectedValue={results.params ? Number(results.params.limit) : ''}
                              autosize={true}
                              options={[
                                { value: 20, label: '20' },
                                { value: 50, label: '50' },
                                { value: 100, label: '100' }
                              ]}
                              onChange={e => changelimit(e)}
                            /> }
                          </div>
                        </div>


                      </div>
                    )}
                  />
                </>
              )}
            </Formik>
            <div className="templatesbody">
              <SimpleTable
                user={user}
                config={config}
                header={config.tableconfig.map(f => {
                  const field = config.fields.find(fe => fe.name === f.name)
                  return {
                    ...field,
                    truncate: false,
                    input: null
                  }
                })}
                getClass={el => (area_groups.current = el)}
                updateModel={({ values, resolve, reject, data, callback }) => {
                  const r = data.map(res => ({ ...res }))
                  updateModel({ values, resolve, reject })
                  r.forEach(d => {
                    if (d.front_end) {
                      d.front_end = false
                    }
                  })
                  removeEventListener.forEach(d => {
                    if (d.id === values.id) {
                      d.front_end = values.front_end
                    }
                  })
                  callback(removeEventListener)
                }}
                action={({ params, resolve }) => {
                  new Promise((res1, rej1) => fetchGroups({ params, resolve: res1, reject: rej1 })).then(r => {
                    calculateRecords(r)
                    const rows = r.index.map(p => r.results[p])
                    resolve({ options: rows })
                  })
                }}
                params={{ ...qs.getAllArgs(false) }}
                parser={data => data}
                rowActions={(row, data) => (
                  <>
                    <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => setEditing(data)} title="Edit Group" type="button" />
                    <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteGroup(data, () => {area_groups.current.fetchData()})} title="Delete Group" type="button" />
                  </>
                )}
              />
            </div>
          </div>
        }
      />
      <WideSidebar sidebar={'show-area-groups-sidebar'}>
        <AreaGroupsSidebar
          key="area-groups-sidebar-edit"
          toggleLookup={() => setEditing(false)}
          area_group={editing}
          config={config}
          list={area_groups.current}
          match={match}
        />
      </WideSidebar>
    </div>
  )
}

AreaGroupsManager.propTypes = {
  model: PropTypes.object,
  fields: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  configs: PropTypes.array,
  match: PropTypes.object,
  updatetemplate: PropTypes.func,
  deletetemplate: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  bulkEditModel: PropTypes.func
}

const mapStateToProps = state => { // Pass only minimal data to the FieldGroup component
  const site = SITE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  return {
    location: LOCATION(state),
    user: minuser
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(AreaGroupsManager))
