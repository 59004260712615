import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { buildLambdaURI } from '../../utils'


const UserPhoto = ({ model, small, tooltip, idx = 0, download, link }) => {
  if (!model) { return null }
  let image = model.image
  // Sometimes the SingleUser component is passed meta as the model
  if (model.meta && model.image) { image = model.meta.image }
  let initials = null
  if (model.first_name || model.last_name) {
    initials = `${model.first_name ? model.first_name.substring(0, 1) : ''}${model.last_name ? model.last_name.substring(0, 1) : ''}`
  }
  let cropped = false
  if (image && image.file) {
    const parms = {
      cx: model.profile_picture_coord_x,
      cy: model.profile_picture_coord_y,
      cw: model.profile_picture_width,
      ch: model.profile_picture_height,
      w: 150,
      h: 150
    }
    cropped = buildLambdaURI(image.file, parms)
  }
  const title = tooltip ? tooltip : `${model.first_name} ${model.last_name}`

  return (
    <React.Fragment>
      { download ? (
        <a title={title} href={image ? image.file : '#1'} target="_blank" rel="noopener noreferrer" className={classNames('avatar', { 'avatar-md': small, 'avatar-lg': !small })}>
          {cropped ? (
            <div
              className="thumbimg"
            >
              <img alt={title} src={cropped} />
            </div>
          ) : (
            <div title={title} className="thumbimg initials">
              <svg viewBox="0 0 96 96">
                <circle cx="50%" cy="50%" r="50%" />
                <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fontSize="32" fontFamily="'Poppins', sans-serif">{initials}</text>
              </svg>
            </div>
          )}
        </a>
      ) : (
        <NavLink title={title} key={`user-link-${idx}`} to={link ? link : `/secure/${model.site}/agents/${model.id}`} className={classNames('avatar', { 'avatar-md': small, 'avatar-lg': !small })}>
          {cropped ? (
            <div
              className="thumbimg"
            >
              <img alt={title} src={cropped} />
            </div>
          ) : (
            <div className="thumbimg initials">
              <svg viewBox="0 0 96 96">
                <circle cx="50%" cy="50%" r="50%" />
                <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fontSize="32" fontFamily="'Poppins', sans-serif">{initials}</text>
              </svg>
            </div>
          )}
        </NavLink>
      )}
    </React.Fragment>
  )
}

UserPhoto.propTypes = {
  idx: PropTypes.number,
  details: PropTypes.bool,
  designation: PropTypes.bool,
  small: PropTypes.bool,
  userid: PropTypes.number,
  cache: PropTypes.object,
  model: PropTypes.object,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  fetchOne: PropTypes.func,
  download: PropTypes.bool,
  link: PropTypes.string
}

export default UserPhoto
