import PropTypes from 'prop-types'
import React from 'react'

import log from '../../logging'
import { isConditional, valueFormat } from '../../utils'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import { Button } from '../ui/Button'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'


class Syndication extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      lead_count: 0,
      loading_leads: true,
      alert_count: 0,
      loading_alerts: true,
      p24_syncing: [],
      pp_syncing: [],
      gumtree_syncing: []
    }
    this.isConditional = isConditional.bind(this)
  }


  render() {
    const {
      model,
      // config,
      // configs,
      // cache,
      // modelid,
      actions,
      user,
      match,
      location,
      routeConfig
    } = this.props
    const p24 = model.meta.portals ? model.meta.portals.find(p => p.portal === 1) : null
    const pp = model.meta.portals ? model.meta.portals.find(p => p.portal === 2) : null
    const gumtree = model.meta.portals ? model.meta.portals.find(p => p.portal === 3) : null
    const rightmove = model.meta.portals ? model.meta.portals.find(p => p.portal === 33) : null
    const hamptons = model.meta.portals ? model.meta.portals.find(p => p.portal === 34) : null
    if (!model) {
      return null
    }
    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        {p24 ? (
          <Tab tab="property24" label="Property24">
            <Card
              background
              header={
                <>
                  <h3 className="flex-heading">{p24.meta.portal.name}</h3>
                  <div className="details-section-buttons">
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      disabled={this.state.p24_syncing.includes(model.id)}
                      onClick={() => {
                        this.setState({ p24_syncing: [ ...this.state.syncing, model.id ] })
                        actions.syndicatePortalItem({
                          portal: p24.portal,
                          item_id: model.id,
                          item_type: match.param.log
                        })
                      }}>
                      <span className="label">Sync Again</span>
                    </Button>
                  </div>
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  paginated
                  user={user}
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  params={{
                    modelname: match.params.log,
                    portal: p24.portal,
                    listing_id: model.id,
                    order_by: '-created',
                    meta_fields: [ 'agent' ]
                  }}
                  action={actions.fetchFeedLogs}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      l.meta.listing = model
                      return log
                    }) : null
                    return data
                  }}
                  header={[
                    {
                      label: 'Web Ref',
                      name: 'meta.listing.web_ref',
                      link: '/secure/:site/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Status',
                      name: 'feed_status',
                      format: 'feedstatus'
                    },
                    {
                      label: 'Last Updated',
                      name: 'modified',
                      format: 'datetime'
                    },
                    {
                      label: 'Portal Ref',
                      name: 'reference'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      tooltip: true
                    }
                  ]}
                  rowActions={data => {
                    const link = data.reference ? valueFormat('feedlink', `View on ${p24.meta.portal.name}`, { portal: p24.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                    return link
                  }}
                />
              }
            />
          </Tab>
        ) : null}

        {pp ? (
          <Tab tab="private-property" label="Private Property">
            <Card
              background
              header={
                <>
                  <h3 className="flex-heading">{pp.meta.portal.name}</h3>
                  <div className="details-section-buttons">
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      disabled={this.state.pp_syncing.includes(model.id)}
                      onClick={() => {
                        this.setState({ pp_syncing: [ ...this.state.syncing, model.id ] })
                        actions.syndicatePortalItem({
                          portal: pp.portal,
                          item_id: model.id,
                          item_type: match.param.log
                        })
                      }}>
                      <span className="label">Sync Again</span>
                    </Button>
                  </div>
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  paginated
                  user={user}
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  params={{
                    modelname: match.params.log,
                    portal: pp.portal,
                    listing_id: model.id,
                    order_by: '-created',
                    meta_fields: [ 'agent' ]
                  }}
                  action={actions.fetchFeedLogs}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      l.meta.listing = model
                      return log
                    }) : null
                    return data
                  }}
                  header={[
                    {
                      label: 'Web Ref',
                      name: 'meta.listing.web_ref',
                      link: '/secure/:site/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Status',
                      name: 'feed_status',
                      format: 'feedstatus'
                    },
                    {
                      label: 'Last Updated',
                      name: 'modified',
                      format: 'datetime'
                    },
                    {
                      label: 'Portal Ref',
                      name: 'reference'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      tooltip: true
                    }
                  ]}
                  rowActions={data => {
                    const link = data.reference ? valueFormat('feedlink', `View on ${pp.meta.portal.name}`, { portal: pp.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                    return link
                  }}
                />
              }
            />
          </Tab>
        ) : null}

        {gumtree ? (
          <Tab tab="gumtree" label="Gumtree">
            <Card
              background
              header={
                <>
                  <h3 className="flex-heading">{gumtree.meta.portal.name}</h3>
                  <div className="details-section-buttons">
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      disabled={this.state.gumtree_syncing.includes(model.id)}
                      onClick={() => {
                        this.setState({ gumtree_syncing: [ ...this.state.syncing, model.id ] })
                        actions.syndicatePortalItem({
                          portal: gumtree.portal,
                          item_id: model.id,
                          item_type: match.param.log
                        })
                      }}>
                      <span className="label">Sync Again</span>
                    </Button>
                  </div>
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  paginated
                  user={user}
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  params={{
                    modelname: match.params.log,
                    portal: gumtree.portal,
                    listing_id: model.id,
                    order_by: '-created',
                    meta_fields: [ 'agent' ]
                  }}
                  action={actions.fetchFeedLogs}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      l.meta.listing = model
                      return log
                    }) : null
                    return data
                  }}
                  header={[
                    {
                      label: 'Web Ref',
                      name: 'meta.listing.web_ref',
                      link: '/secure/:site/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Status',
                      name: 'feed_status',
                      format: 'feedstatus'
                    },
                    {
                      label: 'Last Updated',
                      name: 'modified',
                      format: 'datetime'
                    },
                    {
                      label: 'Portal Ref',
                      name: 'reference'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      tooltip: true
                    }
                  ]}
                  rowActions={data => {
                    let link = data.reference ? valueFormat('feedlink', `View on ${gumtree.meta.portal.name}`, { portal: gumtree.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                    if (data.last_message.indexOf('https://www.gumtree.co.za') !== -1) {
                      const gumtree_url = data.last_message.match(/(https:\/\/www.gumtree.co.za\/.+\d)/).pop()
                      link = <Button component="a" className="btn btn-icon-16 btn-icon-only btn-none" href={gumtree_url} target='_blank'>View on Gumtree</Button>
                    }
                    return link
                  }}
                />
              }
            />
          </Tab>
        ) : null}

        {hamptons ? (
          <Tab tab="hamptons-international" label="Hamptons International">
            <Card
              background
              header={
                <>
                  <h3 className="flex-heading">{hamptons.meta.portal.name}</h3>
                  <div className="details-section-buttons">
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      disabled={this.state.hamptons_syncing.includes(model.id)}
                      onClick={() => {
                        this.setState({ hamptons_syncing: [ ...this.state.syncing, model.id ] })
                        actions.syndicatePortalItem({
                          portal: hamptons.portal,
                          item_id: model.id,
                          item_type: match.param.log
                        })
                      }}>
                      <span className="label">Sync Again</span>
                    </Button>
                  </div>
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  paginated
                  user={user}
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  params={{
                    modelname: match.params.log,
                    portal: hamptons.portal,
                    listing_id: model.id,
                    order_by: '-created',
                    meta_fields: [ 'agent' ]
                  }}
                  action={actions.fetchFeedLogs}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      l.meta.listing = model
                      return log
                    }) : null
                    return data
                  }}
                  header={[
                    {
                      label: 'Web Ref',
                      name: 'meta.listing.web_ref',
                      link: '/secure/:site/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Status',
                      name: 'feed_status',
                      format: 'feedstatus'
                    },
                    {
                      label: 'Last Updated',
                      name: 'modified',
                      format: 'datetime'
                    },
                    {
                      label: 'Portal Ref',
                      name: 'reference'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      tooltip: true
                    }
                  ]}
                  rowActions={data => {
                    const link = data.reference ? valueFormat('feedlink', `View on ${hamptons.meta.portal.name}`, { portal: hamptons.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                    return link
                  }}
                />
              }
            />
          </Tab>
        ) : null}

        {rightmove ? (
          <Tab tab="rightmove" label="Rightmove">
            <Card
              background
              header={
                <>
                  <h3 className="flex-heading">{rightmove.meta.portal.name}</h3>
                  <div className="details-section-buttons">
                    <Button
                      type="button"
                      icon="#icon16-Sync"
                      className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                      disabled={this.state.rightmove_syncing.includes(model.id)}
                      onClick={() => {
                        this.setState({ rightmove_syncing: [ ...this.state.syncing, model.id ] })
                        actions.syndicatePortalItem({
                          portal: pp.portal,
                          item_id: model.id,
                          item_type: match.param.log
                        })
                      }}>
                      <span className="label">Sync Again</span>
                    </Button>
                  </div>
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  paginated
                  user={user}
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  params={{
                    modelname: match.params.log,
                    portal: pp.portal,
                    listing_id: model.id,
                    order_by: '-created',
                    meta_fields: [ 'agent' ]
                  }}
                  action={actions.fetchFeedLogs}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      l.meta.listing = model
                      return log
                    }) : null
                    return data
                  }}
                  header={[
                    {
                      label: 'Web Ref',
                      name: 'meta.listing.web_ref',
                      link: '/secure/:site/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Status',
                      name: 'feed_status',
                      format: 'feedstatus'
                    },
                    {
                      label: 'Last Updated',
                      name: 'modified',
                      format: 'datetime'
                    },
                    {
                      label: 'Portal Ref',
                      name: 'reference'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      tooltip: true
                    }
                  ]}
                  rowActions={data => {
                    const link = data.reference ? valueFormat('feedlink', `View on ${rightmove.meta.portal.name}`, { portal: rightmove.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                    return link
                  }}
                />
              }
            />
          </Tab>
        ) : null}

      </HorizontalTabs>
    )
  }
}

Syndication.propTypes = {
  model: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  configs: PropTypes.object,
  modelid: PropTypes.number,
  routeConfig: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  location: PropTypes.object,
  actions: PropTypes.object
}

export default Syndication
