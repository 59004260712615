import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import { sortBy, valueFormat } from '../../utils'
import { Button } from '../ui/Button'


class PortalLinks extends React.Component {
  constructor(props) {
    super(props)
    const { portals } = props
    const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree' ].includes(portals[pk].meta.portal.slug)).map(pk => {
      portals[pk].index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portals[pk].meta.portal.slug)
      return portals[pk]
    })
    const sorted = sortBy(active, 'index')
    this.state = {
      active: sorted
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.portals, this.props.portals)) {
      const { portals } = this.props
      const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree' ].includes(portals[pk].meta.portal.slug)).map(pk => {
        portals[pk].index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portals[pk].meta.portal.slug)
        return portals[pk]
      })
      const sorted = sortBy(active, 'index')
      this.setState({ active: sorted })
    }
  }

  render() {
    const { model } = this.props
    return (
      <div className="portals">
        <h3>Portals</h3>
        <table className="portal-log-table datatable">
          <tbody>
            {this.state.active.map((portal, pidx) => {
              const log = model.meta.portals ? model.meta.portals.find(p => p.portal === portal.portal) : null
              if (!log || !log.last_message) { return null }
              let link = log.reference ? valueFormat('feedlink', 'View', { portal: portal.meta.portal.slug, reference: log.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
              if (log.last_message.indexOf('https://www.gumtree.co.za') !== -1) {
                const gumtree_url = log.last_message.match(/(https:\/\/www.gumtree.co.za\/.+\d)/).pop()
                link = <Button component={'a'} rel="noreferrer" className="has-link" href={gumtree_url} target='_blank'>View</Button>
              }
              return log ? (
                <tr key={`portal-logs-${pidx}`}>
                  <td><span className="tablecell" title={log.last_message}>{portal.meta.portal.name}</span></td>
                  <td className='column-view-on-portal'><span className="tablecell">{link ? link : ''}</span></td>
                </tr>
              ) : (
                null
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

PortalLinks.propTypes = {
  model: PropTypes.object,
  modelname: PropTypes.string,
  cache: PropTypes.object,
  portals: PropTypes.object,
  settings: PropTypes.object,
  site: PropTypes.object,
  syndicatePortalItem: PropTypes.func,
  fetchPortalLogs: PropTypes.func
}

export default PortalLinks
