import classNames from 'classnames'
import merge from 'deepmerge'
import { Formik, getIn, setIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '../components/ui/Button'
import { Scrollbar } from '../components/ui/Scrollbars'
import db from '../db'
import { logEvent } from '../utils'
import * as validate from '../validate'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import { formikUseField } from './common/forms/customFormikUseField'


const DeedsTable = props => (
  <div className="btmatches">
    <div className="datatable contactselector">
      <div className="datatable-headers" data-columns="5">
        <div>Registrar</div>
        <div>Erf/Section</div>
        <div>Street Address</div>
        <div>Owner Name</div>
        <div></div>
      </div>
      <div className="datatable-body" data-columns="5">
        {props.children}
      </div>
    </div>
  </div>
)

DeedsTable.propTypes = {
  children: PropTypes.node
}

const DeedsRow = props => {
  const { result, selectDeedsRow } = props
  return (
    <div className="datatable-row">
      <div><span className="tablecell">{result.registrar}</span></div>
      <div><span className="tablecell">{result.erfNumber}</span></div>
      <div><span className="tablecell">{result.address}</span></div>
      <div><span className="tablecell">{result.ownerDetails}</span></div>
      <div><Button type="button" className="btn btn-none btn-icon btn-icon-16" icon="#icon16-Plus" onClick={() => selectDeedsRow(result)} /></div>
    </div>
  )
}

DeedsRow.propTypes = {
  model: PropTypes.object,
  result: PropTypes.object,
  config: PropTypes.object,
  updateModel: PropTypes.func,
  selectDeedsRow: PropTypes.func
}

class DeedsLookupSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      initvals: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.selectDeedsRow = this.selectDeedsRow.bind(this)
  }

  componentDidMount() {
    const { cache, config, form, field } = this.props
    const initvals = {}

    config.fields.forEach(f => {
      if (f.defaultvalue !== undefined && f.defaultvalue !== null) { initvals[f.name] = f.defaultvalue }
      if (f.twin && form.values[f.name]) {
        initvals[f.name] = form.values[f.name]
      }
    })

    // Populate the default province from listing branch province
    if (form.values.branch &&
      cache.branches[form.values.branch] &&
      cache.branches[form.values.branch].meta.location &&
      cache.branches[form.values.branch].meta.location.province_id) {
      initvals.province = cache.branches[form.values.branch].meta.location.province_id
    }

    if (!initvals.province) {
      initvals.province = ''
    }

    this.setState({ initvals })

    if (this.el && this.props.sidebar !== `show-lookup-sidebar-${field.name}`) {
      setTimeout(() => {
        this.props.actions.toggleWideSidebar(`show-lookup-sidebar-${field.name}`)
      }, 50)
    }
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  selectDeedsRow(row) {
    logEvent('SELECTED_DEEDS_ROW', row)
    const { form, field, actions, config } = this.props
    let mapped_values = merge({}, form.values)
    let group
    if (field.name.includes('.')) {
      const parts = field.name.split('.')
      group = parts.slice(0, parts.length - 1).join('.')
    }
    mapped_values = setIn(mapped_values, field.name, row.propertyId)
    const mapped = config.fields.filter(f => f.mapto)
    if (config.name === 'Lightstone') {
      if (group) {
        mapped_values = setIn(mapped_values, `${group}.lightstone_data`, row)
        mapped_values = setIn(mapped_values, `${group}.address`, row.address)
      } else {
        mapped_values = setIn(mapped_values, 'lightstone_data', row)
      }
    }
    if (!group) {
      mapped.forEach(m => {
        if (m.mapto === 'location') {
          mapped_values[m.mapto] = getIn(this.form.values, m.name)
        } else if (m.mapto === 'street_name') {
          mapped_values[m.mapto] = `${getIn(row, m.name)} ${row.streetType}`
        } else {
          mapped_values[m.mapto] = getIn(row, m.name)
        }
      })
      actions.toggleWideSidebar('show-lookup-sidebar')
      // Do a search on the owner name if not a company / CC
      if (getIn(row, 'owners.0')) { // Lightstone has owner info
        mapped_values.deeds_owner = getIn(row, 'owners.0.buyer_description')
        mapped_values.deeds_id = getIn(row, 'owners.0.buyer_id')
        if (row.propertyType === 'SECTIONAL TITLE') {
          mapped_values.sectional_scheme_name = row.sectionalSchemeName
          mapped_values.section_number = row.sectionalSchemeUnitNumber
          mapped_values.section_plan_number = row.sectionalSchemeNumber
          mapped_values.complex_name = row.sectionalSchemeName
        }
        setTimeout(() => {
          actions.toggleWideSidebar(`show-contact-search-${this.props.ownerfield}`)
        }, 1000)
      }
      if (mapped_values.modelname === 'deals') {
        mapped_values.address = row.address
      }
    } else {
      actions.toggleWideSidebar('show-lookup-sidebar')
    }
    this.props.setInitVals(mapped_values)
    form.setFieldTouched(field.name, true)
  }

  handleSubmit(values, actions) {
    return new Promise((resolve, reject) => {
      const vals = { ...values } // Make a copy as we are mutating
      Object.keys(vals).forEach(k => {
        const field = this.props.config.fields.find(f => f.name === k)
        if (field && field.input === 'Float') { vals[k] = vals[k] ? parseFloat(vals[k]).toFixed(1) : vals[k] }
        if (vals[k] === null || vals[k] === '') { delete vals[k] }
      })
      if (vals.province) {
        db.provinces.get(vals.province).then(r => {
          vals.province = r.province_slug
          if (vals.suburb) {
            db.suburbs.get(vals.suburb).then(s => {
              vals.suburb = s.suburb
              logEvent('DEEDS_LOOKUP', { ...values })
              this.props.actions.fetchDeeds({ vals, resolve, reject }) // Perform the lookup
            }).catch(() => {
              actions.setSubmitting(false)
            })
          } else {
            logEvent('DEEDS_LOOKUP', { ...values })
            this.props.actions.fetchDeeds({ vals, resolve, reject }) // Perform the lookup
          }
        }).catch(() => {
          actions.setSubmitting(false)
        })
      } else {
        logEvent('DEEDS_LOOKUP', { ...values })
        this.props.actions.fetchDeeds({ vals, resolve, reject }) // Perform the lookup
      }
    }).then(r => {
      actions.setSubmitting(false)
      this.setState({ results: r.results ? r.results : [] })
    }).catch(e => {
      console.error(e)
      actions.setSubmitting(false)
    })
  }

  render () {
    return (
      <div id="deeds-lookup-sidebar" ref={el => { this.el = el }} className="deeds-lookup-sidebar wide-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={{
              ...this.state.initvals
            }}
            validationSchema={getIn(validate.default, 'deeds_lookups.lightstone')}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={el => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>Lightstone Lookup</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                            <FieldGroup
                              key={`fs-${gidx}`}
                              groupname={group}
                              group={this.props.config.fieldgroups[group]}
                              gidx={gidx}
                              classes={this.props.config.fieldgroups[group].classes}
                              fields={this.props.config.fields.filter(field => field.group === group && field.input !== 'DeedsLookup').map(f => {
                                const newfield = merge({}, f)
                                if (newfield.twin) { newfield.readonly = true }
                                if (group === 'Keyword Search') {
                                  newfield.form_el = el
                                  newfield.suffix = <Button icon="#icon24-Search" type="button" onClick={() => formik.submitForm() } className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
                                }
                                return newfield
                              })}
                              creator={this.props.field.name}
                              modelname={this.props.deeds}
                              match={this.props.match}
                              required={this.state.required}
                              columns
                              collapsed={this.state.collapsed}
                              render={({ renderFieldGroup, hidden }) => {
                                if (hidden) { return null }
                                return (
                                  <fieldset className={classNames('editgroup', this.props.config.fieldgroups[group].classes)}>
                                    <h5>{group}</h5>
                                    {renderFieldGroup(group)}
                                  </fieldset>
                                )
                              }}
                            />
                          ))}
                          <div className="wide-sidebar-footer">
                            <Button
                              onClick={formik.submitForm} // Required for type="button"
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              disabled={formik.isSubmitting}
                              className="btn btn-primary">Search</Button>
                          </div>
                          {(this.state.results && this.state.results.length) ? (
                            <fieldset className="editgroup">
                              <h5>Results</h5>
                              <DeedsTable results={this.state.results !== false ? this.state.results : false}>
                                {this.state.results.map((result, midx) =>
                                  <DeedsRow
                                    key={`lead-${midx}`}
                                    result={result}
                                    selectDeedsRow={this.selectDeedsRow}
                                  />
                                )}
                              </DeedsTable>
                            </fieldset>
                          ) : null }
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

DeedsLookupSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  toggleLookup: PropTypes.func,
  setInitVals: PropTypes.func,
  sidebar: PropTypes.string,
  ownerfield: PropTypes.string,
  actions: PropTypes.object,
  user: PropTypes.object,
  deeds: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  match: PropTypes.object,
  cache: PropTypes.object
}

export default formikUseField(DeedsLookupSidebar)
