import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import { valueFormat } from '../../utils'


const OnShowHistory = props => {
  const { cache, currency, portal } = props
  if (cache.on_show_events) {
    const table = (
      <div>
        <table className="datatable on_show_history">
          <thead>
            <tr>
              <th><div className="heading-wrapper">Date</div></th>
              <th><div className="heading-wrapper">Start</div></th>
              <th><div className="heading-wrapper">End</div></th>
              <th><div className="heading-wrapper">Price</div></th>
            </tr>
          </thead>
          <tbody>
            {(cache.on_show_events.length > 0) && cache.on_show_events.map(showday => {
              const today = new Date()
              const event_date = new Date(showday.on_show_date)
              if (event_date > today) { return null }
              return (
                <tr key={showday.on_show_date}>
                  <td><span className="tablecell">{valueFormat('date', showday.on_show_date)}</span></td>
                  <td><span className="tablecell">{valueFormat('time', showday.on_show_start_time)}</span></td>
                  <td><span className="tablecell">{valueFormat('time', showday.on_show_end_time)}</span></td>
                  <td><span className="tablecell">{valueFormat('currency', showday.price, { currency })}</span></td>
                </tr>
              )
            })}
            {(!cache.on_show_events.length > 0) &&
              <tr>
                <td colSpan={4}><span className="tablecell">On Show history not available yet</span></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    )
    return portal ? ReactDOM.createPortal(
      table,
      document.getElementById('field-on_show').closest('.input-group')
    ) : table
  }
  return null
}

OnShowHistory.propTypes = {
  cache: PropTypes.object,
  modelid: PropTypes.number,
  modelname: PropTypes.string,
  currency: PropTypes.string,
  form: PropTypes.object,
  portal: PropTypes.bool
}

export default OnShowHistory
