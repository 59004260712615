/* eslint-disable consistent-return */
import {
  closestCorners,
  getFirstCollision,
  MeasuringStrategy,
  defaultDropAnimation,
  KeyboardCode
} from '@dnd-kit/core'

// eslint-disable-next-line no-unused-vars
import { arrayMove } from '@dnd-kit/sortable'


export const iOS = /iPad|iPhone|iPod/.test(navigator.platform)

export const directions = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left
]

export const horizontal = [ KeyboardCode.Left, KeyboardCode.Right ]

export const findItem = (items, itemId) => items.find(({ id }) => id === itemId)

export const findItemDeep = (items, itemId) => {
  for (const item of items) {
    const { id, children } = item

    if (id === itemId) {
      return item
    }

    if (children.length) {
      const child = findItemDeep(children, itemId)

      if (child) {
        return child
      }
    }
  }

  return undefined
}


export const flatten = (items, parentId = null, depth = 0) => items.reduce((acc, item, index) => [
  ...acc,
  { ...item, parentId, depth, index },
  ...flatten(item.children.filter(child => child), item.id, depth + 1)
], [])

export const flattenTree = items => flatten(items.filter(item => item))

export const buildTree = flattenedItems => {
  const root = { id: 'root', children: [] }
  const nodes = { [root.id]: root }
  const items = flattenedItems.map((item, idx) => ({ ...item, children: [], order: idx }))
  for (const item of items) {
    const { id, children } = item
    const parentId = item.parent ?? root.id
    const parent = nodes[parentId] ?? findItemDeep(items, parentId)
    nodes[id] = { id, children }
    parent?.children.push(item)
  }

  return root.children
}

export const getDragDepth = (offset, indentationWidth) => Math.round(offset / indentationWidth)


export const getMaxDepth = ({ previousItem }) => {
  if (previousItem) {
    return previousItem.depth + 1
  }

  return 0
}

export const getMinDepth = ({ nextItem }) => {
  if (nextItem) {
    if (nextItem.parent) {
      return nextItem.depth - 1
    }
    return nextItem.depth
  }

  return 0
}

export const getProjection = (
  items,
  activeId,
  overId,
  dragOffset,
  indentationWidth
) => {
  const overItemIndex = items.findIndex(({ id }) => id === overId)
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = [ ...items ]// arrayMove([ ...items ], activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  const maxDepth = getMaxDepth({
    previousItem
  })
  const minDepth = getMinDepth({ nextItem })
  let depth = projectedDepth

  if (projectedDepth >= maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId
    }

    if (depth > previousItem.depth) {
      return previousItem.id
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find(item => item.depth === depth)?.parent

    return newParent ?? null
  }
  const newParent = getParentId()
  return { depth, maxDepth, minDepth, parentId: newParent !== activeItem.id ? newParent : null }
}

export const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
}

export const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([ { opacity: 0 }, { opacity: 1 } ], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    })
  }
}


export const sortableTreeKeyboardCoordinates = (context, indicator, indentationWidth) => (
  event,
  {
    currentCoordinates,
    context: { active, over, collisionRect, droppableRects, droppableContainers }
  }
) => {
  if (directions.includes(event.code)) {
    if (!active || !collisionRect) {
      return
    }

    event.preventDefault()

    const {
      current: { items, offset }
    } = context

    if (horizontal.includes(event.code) && over?.id) {
      const { depth, maxDepth, minDepth } = getProjection(
        items,
        active.id,
        over.id,
        offset,
        indentationWidth
      )

      // eslint-disable-next-line default-case
      switch (event.code) {
        case KeyboardCode.Left:
          if (depth > minDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x - indentationWidth
            }
          }
          break
        case KeyboardCode.Right:
          if (depth < maxDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x + indentationWidth
            }
          }
          break
      }

      return undefined
    }

    const containers = []

    droppableContainers.forEach(container => {
      if (container?.disabled || container.id === over?.id) {
        return
      }

      const rect = droppableRects.get(container.id)

      if (!rect) {
        return
      }

      // eslint-disable-next-line default-case
      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            containers.push(container)
          }
          break
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            containers.push(container)
          }
          break
      }
    })

    const collisions = closestCorners({
      active,
      collisionRect,
      pointerCoordinates: null,
      droppableRects,
      droppableContainers: containers
    })
    let closestId = getFirstCollision(collisions, 'id')

    if (closestId === over?.id && collisions.length > 1) {
      closestId = collisions[1].id
    }

    if (closestId && over?.id) {
      const activeRect = droppableRects.get(active.id)
      const newRect = droppableRects.get(closestId)
      const newDroppable = droppableContainers.get(closestId)

      if (activeRect && newRect && newDroppable) {
        const newIndex = items.findIndex(({ id }) => id === closestId)
        const newItem = items[newIndex]
        const activeIndex = items.findIndex(({ id }) => id === active.id)
        const activeItem = items[activeIndex]

        if (newItem && activeItem) {
          const { depth } = getProjection(
            items,
            active.id,
            closestId,
            (newItem.depth - activeItem.depth) * indentationWidth,
            indentationWidth
          )
          const isBelow = newIndex > activeIndex
          const modifier = isBelow ? 1 : -1
          const newOffset = indicator
            ? (collisionRect.height - activeRect.height) / 2
            : 0

          const newCoordinates = {
            x: newRect.left + depth * indentationWidth,
            y: newRect.top + modifier * newOffset
          }

          return newCoordinates
        }
      }
    }
  }

  return undefined
}

export function setProperty(
  items,
  id,
  property,
  setter
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property])
      continue
    }

    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter)
    }
  }

  return [ ...items ]
}


export function removeItem(items, id) {
  const newItems = []

  for (const item of items) {
    if (item.id === id) {
      continue
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id)
    }

    newItems.push(item)
  }

  return newItems
}

function countChildren(items, count = 0) {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1)
    }

    return acc + 1
  }, count)
}

export function getChildCount(items, id) {
  const item = findItemDeep(items, id)

  return item ? countChildren(item.children) : 0
}


export function removeChildrenOf(
  items,
  ids
) {
  const excludeParentIds = [ ...ids ]

  return items.filter(item => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id)
      }
      return false
    }

    return true
  })
}
