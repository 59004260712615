import classNames from 'classnames'
import { formatDistanceToNow } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../../../../ui/Button'
import SingleUser from '../../../SingleUser'


const VersionSummary = props => {
  const { version, agent, user, match } = props
  return (
    <Button component={NavLink} to={`/secure/${match.params.model}/${match.params.id}/edit/versions/${version.id}`} className={classNames('versions-summary')}>
      <div className="versions-summary-top">
        <div className="versions-summary-creator">
          {version.created_by !== null && agent &&
            <div className="agents">
              <SingleUser small={true} model={agent} />
              <div className="noteauthor">
                {version.user_id === user.id ? 'You' : `${agent.first_name} ${agent.last_name}`}
              </div>
            </div>
          }
          {version.user_id !== null &&
            <div className="agents" title="System User">
              <div className='avatar avatar-md'>
                <div className="thumbimg">
                  <svg viewBox="0 0 24 24">
                    <use href="/images/icons-24.svg#icon24-Cog" />
                  </svg>
                </div>
              </div>
              <div className="noteauthor">
                System User
              </div>
            </div>
          }
        </div>
        <div className="versions-summary-date">
          {formatDistanceToNow(new Date(version.created).getTime(), { addSuffix: true }).toLocaleUpperCase()}
        </div>
      </div>
      <div className="versions-summary-body">
        <div className="versions-summary-content">
          {version.id} - {version.name}
        </div>
        <div className="versions-summary-status">
          <span className={classNames('tag', 'rounded', version.status)}>{version.status}</span>
        </div>
      </div>
    </Button>
  )
}

VersionSummary.propTypes = {
  version: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  togglePinNote: PropTypes.func
}

export default VersionSummary
