import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import { Editor as MonacoEditor } from '@monaco-editor/react'

import isEqual from 'react-fast-compare'
import { debounce } from '../utils'
import HorizontalTabs from './common/tabs/HorizontalTabs'
import ModelActions from './common/ModelActions'
import Tab from './common/tabs/Tab'
import Loader from './common/Loader'


class CodePreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
      height: 100,
      initvals: {}
    }
    this.editorDidMount = this.editorDidMount.bind(this)
  }

  componentDidMount() {
    const { model, modelid, modelname, actions } = this.props
    if (modelid && !model) {
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        actions.fetchOne(modelname, modelid, resolve, reject)
      })
    }
    this.setState({
      initvals: model
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.model && !isEqual(prevProps.model, this.props.model)) {
      const initvals = merge({}, this.props.model)
      if (!isEqual(initvals, this.state.initvals)) {
        this.setState({ initvals })
      }
    }
  }

  editorDidMount(editor) {
    const tab_content = document.querySelector('.horizontal-tabs-wrapper + div')
    this.setState({ height: tab_content.scrollHeight - 20 })
    editor.focus()
  }

  render() {
    const { config, routeConfig, match, model, user } = this.props
    const options = {
      selectOnLineNumbers: true
    }
    return (
      <Suspense fallback={<Loader inline />}>
        <Formik
          validateOnChange={true}
          initialValues={this.state.initvals}
          enableReinitialize={true}
        >{formik => {
            const children = [
              <Tab key="config" tab="config" label="Config">
                <section></section>
              </Tab>
            ].concat(config.fields.filter(f => f.edit).map(f => (
              <Tab key={f.name} tab={f.name} label={f.label}>
                <section >
                  <MonacoEditor
                    width="100%"
                    height="calc(-238px + 100vh)"
                    language={f.language}
                    theme="vs-dark"
                    value={getIn(formik.values, f.name)}
                    options={options}
                    onChange={newValue => {
                      debounce(() => {
                        formik.setFieldValue(f.name, newValue)
                        formik.setFieldTouched(f.name, true)
                      }, 300)
                    }}
                    editorDidMount={this.editorDidMount}
                    editorWillUnmount={() => {}}
                  />
                </section>
              </Tab>
            )))
            return (
              <div id="content" className="content">
                <div className="viewhead details">
                  <div className="action-bar">
                    <ModelActions
                      touched={formik.touched}
                      errors={formik.errors}
                      isSubmitting={formik.isSubmitting}
                      redirectSchema={this.redirectSchema}
                      form={formik}
                      config={config}
                      statusmsg={formik.status ? formik.status.msg : false}
                    />
                  </div>
                </div>
                <HorizontalTabs
                  config={routeConfig}
                  location={this.props.location}
                  match={match}
                  model={model}
                  defaultTab="html_content"
                  user={{ permissions: user.permissions, agent: user.agent }}
                >
                  {children}
                </HorizontalTabs>
              </div>
            )
          }}
        </Formik>
      </Suspense>
    )
  }
}


export default connect()(withImmutablePropsToJS(CodePreview))

CodePreview.propTypes = {
  model: PropTypes.object,
  modelid: PropTypes.number,
  modelname: PropTypes.string,
  actions: PropTypes.object,
  config: PropTypes.object,
  routeConfig: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object
}
