// import { combineReducers } from 'redux'
import { combineReducers } from 'redux-immutable'

import { routerReducer } from '../store'
import app from './app'
import ui from './ui'
import user from './user'
import models from './models'
import cache from './cache'
import selected from './selected'


const rootReducer = () => combineReducers({
  app,
  ui,
  user,
  models,
  cache,
  selected,
  router: routerReducer
})

export default rootReducer
