import React from 'react'
import Select, { components } from 'react-select'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import { buildOptionLabel, groupBy } from '../../../../utils'
import { responsiveSelect } from './ResponsiveSelect'
import Label from './Label'


class CustomOption extends React.Component {
  render() {
    const { head, sub, img } = this.props.data
    return <components.Option
      {...this.props}
    >
      <div className="customopt">
        {img && <img src={img} alt="" />}
        <div>
          {head}
          <span className="sub">{sub}</span>
        </div>
      </div>
    </components.Option>
  }
}


CustomOption.propTypes = {
  data: PropTypes.object
}

const ResponsiveSelect = responsiveSelect(Select)


class DependentSelectInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.menuPlacement = this.menuPlacement.bind(this)
    this.customOption = this.customOption.bind(this)
    this.setMetaFieldStatus = this.setMetaFieldStatus.bind(this)
    this.placement = 'auto'
    this.state = {
      options: [],
      value: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { field, form, dependent, labelgrouper, cache, metafield, multi } = this.props
    if (form.values[dependent] && form.values[dependent].length) {
      const options = []
      const results = form.values[dependent].map(v => cache[v]).filter(val => val)
      if (labelgrouper && results.length) { // Labels are grouped
        const groups = groupBy(results, labelgrouper)
        for (const group in groups) {
          if (group !== '') {
            const g = { label: group, options: [] }
            groups[group].forEach(res => { g.options.push(buildOptionLabel(this.props, res)) })
            options.push(g)
          }
        }
      } else if (results.length) {
        results.forEach(res => { options.push(buildOptionLabel(this.props, res)) })
      }
      if (!isEqual(prevState.options, options)) {
        let value = []
        if (Array.isArray(field.value)) {
          value = [ ...field.value ]
        } else if (field.value) {
          value = [ field.value ]
        }
        const opts = Object.keys(groupBy(options, 'value'))
        if (opts.length) {
          value = options.filter(o => value.includes(o.value))
        }
        if (!multi && value.length) {
          value = value[0]
        }
        this.setState({ options, value })
      }
    }

    if (!isEqual(prevState.value, this.state.value)) {
      setTimeout(() => {
        this.handleChange(this.state.value)
      })
    }

    if (metafield && !isEqual(prevState.options, this.state.options)) {
      this.setMetaFieldStatus(field.value)
    }
  }

  handleChange(v) {
    const { multi, form, field } = this.props
    if (v) {
      let vals = []
      if (multi) { // Is this a multi-select
        if (Array.isArray(v)) { // Did we select multiple?
          vals = v.map(i => {
            if (Array.isArray(i.value)) {
              return i.value[0]
            }
            return i.value
          })
        } else { // Append value to list of values
          vals.push(v.value)
        }
      } else if (Array.isArray(v)) {
        vals = v.length ? v.pop().value : '' // Only 1 value allowed. Grab latest/last
      } else {
        vals = v.value // Use only value
      }
      this.setMetaFieldStatus(vals)
      form.setFieldValue(field.name, vals).then(() => {
        form.setFieldTouched(field.name)
      })
    } else {
      form.setFieldValue(field.name, null).then(() => {
        form.setFieldTouched(field.name)
      })
    }
    this.setState({ value: v })
  }

  setMetaFieldStatus(vals) {
    const { form, metafield, cache } = this.props
    let meta_vals = null
    if (metafield) {
      if (Array.isArray(vals)) {
        meta_vals = vals.map(v => cache[v] && cache[v][metafield]).filter(v => (Array.isArray(v) ? v.length : v))
        if (meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = {}
        meta[metafield] = meta_vals
        form.setStatus(meta)
      } else if (vals) {
        meta_vals = cache[vals] ? cache[vals][metafield] : []
        if (meta_vals && meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = {}
        meta[metafield] = meta_vals
        form.setStatus(meta)
      }
    }
  }

  customOption(props) {
    if (props.selectProps.labelformat) { return <CustomOption {...props} /> }
    return <components.Option {...props} />
  }

  menuPlacement(place) {
    this.placement = place
  }

  render() {
    const { field, label, labelformat, multi, onBlur, clearable,
      placeholder, disabled, openmenu, id, form } = this.props
    return (
      <div
        id={id}
        className="selectinput dependentselectinput form-group"
        ref={el => { // This may need to be refactored for performance sakes
          if (!el) {return}
          const bottom = el.getBoundingClientRect().bottom
          const body = document.body
          const html = document.documentElement
          const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
          )
          if (height - bottom < 350) { this.menuPlacement('top') }
        }
        }>
        <Label htmlFor={field.name}>
          {label}
        </Label>
        <div className="forminput">
          <ResponsiveSelect
            className="react-select"
            classNamePrefix="react-select"
            isMulti={multi ? 'true' : false}
            name={field.name}
            form={form}
            field={field}
            options={this.state.options}
            onChange={this.handleChange}
            onBlur={onBlur}
            value={this.state.value}
            labelformat={labelformat}
            isClearable={clearable}
            isDisabled={disabled}
            openMenuOnClick={openmenu}
            placeholder={placeholder}
            components={{
              Option: this.customOption
            }}
          />
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </div>
    )
  }
}

DependentSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  labelgrouper: PropTypes.string,
  metafield: PropTypes.string,
  classes: PropTypes.string,
  dependent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  labelformat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  optionvalue: PropTypes.string,
  disabled: PropTypes.bool,
  openmenu: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  cache: PropTypes.object,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  labelseparator: PropTypes.string
}


export default DependentSelectInput
