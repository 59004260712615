import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { handleSubmitError, isConditional } from '../utils'
import validate from '../validate'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


class ContactCreatorSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initvals: { ...props.autofill, active: true }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.isConditional = isConditional.bind(this)
  }

  componentDidMount() {
    const { autofill, siblingform, config } = this.props
    const statusField = config.fields.find(f => f.label === 'Status')
    const initvals = { ...autofill }
    let statuses = statusField.options
    if (statusField.options.some(o => o.show)) {
      statuses = statusField.options.filter(o => this.isConditional(o, 'show', true, this.form))
    }
    config.fields.forEach(field => {
      if (field.twin && siblingform.values[field.name]) {
        initvals[field.name] = siblingform.values[field.name]
      }
    })
    this.setState({ initvals: { ...initvals, status: statuses.find(o => o.value !== 'Inactive').value } })
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  handleSubmit(values, actions) {
    Object.keys(values).forEach(k => {
      const field = this.props.config.fields.find(f => f.name === k)
      if (field && field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }

      if (Array.isArray(values[k]) && !this.props.config.fields.find(f => f.name === k && f.multi) && !k.includes('review_providers')) {
        values[k] = getIn(values, `${k}.0`, null) // Arrays passed in will be mutated to objects if not configed as multi
      }

      if (field && field.input === 'ContactLookup' && field.multi) {
        values[field.name] = getIn(values, field.name, [])
        if (values[field.name]) {
          values[field.name] = values[field.name].filter(f => f)
        }
      }
    })
    const modelAction = this.state.initvals.id ? this.props.actions.updateModel : this.props.actions.createModel
    return new Promise((resolve, reject) => {
      modelAction({
        values: {
          modelname: this.props.config.modelname,
          endpoint: this.props.config.endpoint,
          ...values
        }, resolve, reject })
    }).then(r => {
      const { selectContact } = this.props
      if (!this.state.initvals.id) {
        this.props.actions.notifyUser({ title: 'Success!', body: `Your ${this.props.config.singular} was created and selected.`, type: 'success' })
      }
      actions.setSubmitting(false)
      actions.setTouched({})
      actions.resetForm()
      selectContact(r)
      this.props.actions.toggleWideSidebar()
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }


  render () {
    const { settings, label, siblingform } = this.props
    const validationSchema = getIn(validate, `${settings.region}.contact`, getIn(validate, 'default.contact'))
    return (
      <div id="contact-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar contact-creator-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={this.state.initvals}
            validationSchema={validationSchema ? validationSchema(this.props) : null}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={() => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>{label ? `${label}: ` : ''}{this.state.initvals.id ? 'Edit' : 'Create'} Contact</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                            <FieldGroup
                              key={`fs-${gidx}`}
                              groupname={group}
                              group={this.props.config.fieldgroups[group]}
                              gidx={gidx}
                              classes={this.props.config.fieldgroups[group].classes}
                              fields={this.props.config.fields.filter(field => field.group === group).map(f => {
                                const newfield = merge({}, f)
                                if (newfield.createnew) { newfield.createnew = false }
                                if (!this.state.initvals.id && newfield.input === 'ContactLookup') { newfield.edit = false }
                                if (this.state.initvals.id && newfield.input === 'ContactLookup') {
                                  if (!newfield.multi && !getIn(this.state.initvals, newfield.name)) {
                                    newfield.edit = false
                                  }
                                  if (
                                    newfield.multi
                                    && (
                                      !getIn(this.state.initvals, newfield.name)
                                      || !getIn(this.state.initvals, newfield.name).length
                                    )
                                  ) {
                                    newfield.edit = false
                                  }
                                }
                                if (newfield.twin) { newfield.readonly = true }
                                if (siblingform.values.modelname === 'leads' && f.name === 'introduction_agent') {
                                  newfield.required = true
                                }
                                return newfield
                              })}
                              modelname={this.props.config.modelname}
                              creator={this.props.field.name}
                              match={this.props.match}
                              required={this.state.required}
                              columns
                              collapsed={this.state.collapsed}
                              render={({ renderFieldGroup, hidden }) => {
                                if (hidden) { return null }
                                return (
                                  <fieldset className="editgroup">
                                    <h5>{group}</h5>
                                    {renderFieldGroup(group)}
                                  </fieldset>
                                )
                              }}
                            />
                          ))}
                          <div className="wide-sidebar-footer">
                            <Button
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              onClick={formik.submitForm}
                              disabled={formik.isSubmitting}
                              className="btn btn-primary"
                            >
                                Save
                            </Button>
                          </div>
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

ContactCreatorSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  sidebar: PropTypes.string,
  label: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func
}

export default ContactCreatorSidebar
