/* eslint-disable no-nested-ternary */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { generateAddress, valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import SocialMedia from '../ui/SocialMedia'
import ListingViewsWidget from '../widgets/ListingViewsWidget'
import NotesWidget from '../widgets/NotesWidget'
import LeadSourceWidget from '../widgets/LeadSourceWidget'
import SingleLatestLeadsWidget from '../widgets/SingleLatestLeadsWidget'
import SocialPerformanceWidget from '../widgets/SocialPerformanceWidget'
import Card from './Card'
import FlaggedMatches from './ListingFlaggedMatches'
import PortalLinks from './ListingPortalLinks'
import ContactItem from './ContactItem'


const ListingDashboard = props => {
  const { model, cache, config, configs, user, actions } = props
  const currency = cache.settings[user.agent.site.id].default_currency
  const listing_image = getIn(model.meta, [ 'listing_images', 0, 'file' ])
  return (
    <div className="grid-container grid-5">
      <div className='grid-col-3'>
        <ListingViewsWidget {...props} />
      </div>
      <Card
        classes="grid-col-2 grid-listing-details"
        bodyclass="nopadding"
        background
        body={
          <div className="dashboard-listing-details">
            <img src={valueFormat('image_url', { file: listing_image, width: 640, height: 480 })} />
            <div className="dashboard-listing-buttons">
              {(model.meta.url.website && model.display_on_website) ? (
                <Button component={'a'} href={model.meta.url.website} className="btn btn-round btn-white" target="_blank">
                  View on Website
                </Button>
              ) : null}
              <Button component={NavLink} to="./media" className="btn btn-round btn-white">
                {model.listing_images ? `${model.listing_images.length} Photo${model.listing_images.length ? 's' : ''}` : ''}
              </Button>
            </div>
            <div className="dashboard-listing-meta">
              <div className="dashboard-listing-price">
                {valueFormat('currency', model.price, { currency })}
              </div>
              <div className="dashboard-listing-address">
                <div>
                  {generateAddress(model, cache.locations, {
                    parts: [ 'complex', 'street', 'meta.location.suburb' ]
                  })}
                </div>
                <div>
                  {generateAddress(model, cache.locations, {
                    parts: [ 'meta.location.area', 'meta.location.province' ]
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      />
      <SocialPerformanceWidget className="grid-col-3" model={model} />
      <Card
        classes="grid-col-2"
        background
        header={
          <h3>Share this listing</h3>
        }
        bodyclass="dashboard-sharing"
        body={
          <>
            <SocialMedia
              model={model}
              sharing
            />
            <PortalLinks
              model={model}
              modelname={config.modelname}
              portals={cache.portals}
              settings={cache.settings[user.agent.site.id]}
            />
            <div className="dashboard-listing-buttons">
              <Button component={NavLink} to={`/secure/${model.site}/${model.model}/${model.id}/syndication`} type="button" icon="#icon16-ArrowExpand" className="btn btn-white btn-subtle btn-icon-16 btn-icon-right">
                    Syndication Logs
              </Button>
              <Button icon="#icon16-Download" type="button" className="btn btn-white btn-subtle btn-icon-16 btn-icon-left" onClick={() => actions.exportData({
                modelname: config.modelname,
                id: model.id,
                params: {},
                args: {
                  action: 'report',
                  template: 'listing-sheet',
                  scope: 'selected'
                },
                label: 'Listing Sheet'
              })}>
                    Download Listing Sheet
              </Button>
            </div>
          </>
        }
      />
      <Card
        classes="grid-col-2"
        background
        header={
          <h3>Contacts</h3>
        }
        body={() => {
          const contacts = [
            'agent',
            'agent_2',
            'agent_3',
            'agent_4',
            'owners',
            'tenant'
          ]
          return (
            <div className="listing-contacts-list">
              {contacts.filter(f => model[f] !== null).map((f, fid) => {
                const field = config.fields.find(fe => fe.name === f)
                if (!field) { return null }
                if (f === 'owners' && model.listing_type === 'To Let') {
                  field.label = 'Landlord'
                }
                if (Array.isArray(model[f])) {
                  return model[f].map((v, vid) => <ContactItem
                    key={`lc-${fid}-${vid}`}
                    field={field}
                    value={v}
                    index={vid}
                    model={model}
                    cache={cache}
                  />)
                }
                return <ContactItem
                  key={`lc-${fid}`}
                  field={field}
                  value={model[f]}
                  model={model}
                  cache={cache}
                />
              })}
            </div>
          )
        }}
      />
      <Card
        classes="grid-col-1"
        background
        body={
          <div className="flex-container justify-center">
            <NavLink to={`/secure/${model.site}/${config.modelname}/${model.id}/brochure`} className="listing-brochure-mini" style={{ background: cache.settings[user.agent.site.id].brand_logo_bg }}>
              <div className="listing-brochure-mini-container">
                <div className="listing-brochure-mini-image">
                  <img alt="Generate Brochure" src={valueFormat('image_url', { file: listing_image, width: 320, height: 240 })} />
                </div>
                <div className="listing-brochure-mini-logo">
                  <img alt="Generate Brochure" src={valueFormat('image_url', { file: cache.settings[user.agent.site.id].meta?.brand_logo?.file, width: 320, height: 240 })} />
                </div>
              </div>
            </NavLink>
            <h3>Generate Brochure</h3>
          </div>
        }
      />
      <div className='grid-col-2'>
        <SingleLatestLeadsWidget model={model} match={props.match} />
      </div>
      {model.model !== 'project' ? (
        <Card
          classes="grid-col-5"
          header={
            <h3>Flagged {model.listing_type === 'To Let' ? 'Tenant' : 'Buyer' } Matches</h3>
          }
          background
          body={
            <FlaggedMatches
              user={user}
              model={model}
              cache={cache}
              config={config}
              configs={configs}
              actions={actions}
              currency={cache.settings[user.agent.site.id].default_currency}
              highlightMatch={actions.highlightMatch}
              settings={cache.settings[user.agent.site.id]}
              unhighlightMatch={actions.unhighlightMatch}
              fetchHighlights={actions.fetchHighlights}
              alertAgentPropertyLead={props.actions.alertAgentPropertyLead}
            />
          }
        />
      ) : null}
      <div className='grid-col-3'>
        <LeadSourceWidget model={model} />
      </div>
      <NotesWidget
        obj_model={model.model}
        obj_id={model.id}
      />
    </div>
  )
}

export default ListingDashboard


ListingDashboard.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  index: PropTypes.number,
  model: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
  portals: PropTypes.object,
  modelid: PropTypes.number
}
