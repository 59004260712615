/* eslint-disable new-cap */
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import ModelBulkEdit from '../../components/common/ModelBulkEdit'
import { MINUSER, SELECTED, CACHE, ADDONS, CONFIG, MODEL } from '../../selectors'


const mapStateToProps = (state, ownProps) => {
  const addons = ADDONS(state)
  const config = CONFIG(state, ownProps.match.params.model)
  const model = MODEL(state, ownProps.match.params.model)
  const selected = SELECTED(state, ownProps.match.params.model)
  const user = MINUSER(state)
  const cache = CACHE(state)

  return {
    modelname: ownProps.match.params.model,
    model,
    config,
    selected,
    user,
    cache,
    addons
  }
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ModelBulkEdit))
