import { agents, agent_signature, agents_activity } from './agents'
import { articles } from './articles'
import { residential_bulkedit, commercial_bulkedit } from './bulkedit'
import { branches, branches_archive, branches_activity } from './branches'
import { brochure_generator } from './brochure-generator'
import { cms } from './cms'
import { commercial } from './commercial'
import { contacts } from './contacts'
import { projects } from './projects'
import { documents } from './documents'
import { groups } from './groups'
import { holiday } from './holiday'
import { images } from './images'
import { leads } from './leads'
import { portals } from './portals'
import { profiles, profilematches } from './profiles'
import { regions } from './regions'
import { residential } from './residential'
import { settings } from './settings'
import { subscribers } from './subscribers'
import { teams, teams_activity } from './teams'
import { franchises } from './franchises'
import { valuations } from './valuations'
import { referrals } from './referrals'

import ae from './regions/ae'
import deeds_lookups from './deeds-lookups'


const default_validation = {
  agents,
  agent_signature,
  agents_activity,
  articles,
  residential_bulkedit,
  commercial_bulkedit,
  branches,
  branches_archive,
  branches_activity,
  brochure_generator,
  cms,
  commercial,
  contacts,
  projects,
  documents,
  groups,
  holiday,
  images,
  leads,
  portals,
  profiles,
  profilematches,
  regions,
  residential,
  settings,
  subscribers,
  teams,
  teams_activity,
  franchises,
  valuations,
  referrals
}
export default {
  default: default_validation,
  ae: ae,
  deeds_lookups: deeds_lookups
}
