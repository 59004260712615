import * as yup from 'yup'
import messages from './messages.json'


export const settings = () => yup.object({
  active: yup.string().oneOf([ 'true', 'false' ], messages.invalid).nullable(),
  suspended: yup.string().oneOf([ 'true', 'false' ], messages.invalid).nullable(),
  display_currency_converter: yup.string().oneOf([ 'true', 'false' ], messages.invalid).nullable(),
  gross_monthly_rentals_switch: yup.string().oneOf([ 'true', 'false' ], messages.invalid).nullable(),
  price_reduced_banner_switch: yup.number().oneOf([ 0, 7, 14, 21, 30, 60, 90 ], messages.invalid).nullable(),
  residential_prefix: yup.string().typeError(messages.invalid).max(3),
  commercial_prefix: yup.string().typeError(messages.invalid).max(3),
  project_prefix: yup.string().typeError(messages.invalid).max(3),
  estate_prefix: yup.string().typeError(messages.invalid).max(3),
  holiday_prefix: yup.string().typeError(messages.invalid).max(3),
  sms_notifications: yup.string().oneOf([ 'true', 'false' ], messages.invalid).nullable(),
  vat_number: yup.string().max(35),
  facebook_campaign_key: yup.array().of(yup.string().max(50)),
  linkedin_campaign_key: yup.array().of(yup.string().max(50)),
  adwords_campaign_key: yup.string().max(50),
  trading_name: yup.string().max(100).required(messages.required),
  website_name: yup.string().max(100).required(messages.required),
  website_slogan: yup.string().max(100).required(messages.required),
  noreply_email: yup.string().email(messages.email).max(254).required(messages.required),
  head_office_contact_number: yup.string().max(50).required(messages.required),
  head_office_fax_number: yup.string().max(50),
  company_details_tel_number: yup.string().max(50),
  company_details_fax_number: yup.string().max(50),
  system_admin_name: yup.string().max(50),
  system_admin_surname: yup.string().max(50),
  system_admin_designation: yup.string().max(50),
  system_admin_email: yup.string().email(messages.email).max(254),
  primary_contact_name: yup.string().max(50),
  primary_contact_surname: yup.string().max(50),
  primary_contact_id: yup.string().max(50),
  primary_contact_designation: yup.string().max(50),
  primary_contact_email: yup.string().email(messages.email).max(254),
  account_contact_name: yup.string().max(50),
  account_contact_surname: yup.string().max(50),
  account_contact_designation: yup.string().max(50),
  account_contact_email: yup.string().email(messages.email).max(254),
  privy_seal_id: yup.string().max(100),
  twitter_url: yup.string().url(messages.url).max(100),
  facebook_url: yup.string().url(messages.url).max(100),
  linkedin_url: yup.string().url(messages.url).max(100),
  youtube_url: yup.string().url(messages.url).max(100),
  pinterest_url: yup.string().url(messages.url).max(100),
  instagram_url: yup.string().url(messages.url).max(100),
  private_property_username: yup.string().max(50),
  private_property_password: yup.string().max(50),
  property_24_username: yup.string().max(50),
  property_24_password: yup.string().max(50),
  myproperty_xml_client_id: yup.string().max(50),
  cyberprop_username: yup.string().max(50),
  cyberprop_password: yup.string().max(50),
  property_tube_username: yup.string().max(50),
  propctrl_username: yup.string().max(50),
  propctrl_password: yup.string().max(50),
  fusion_username: yup.string().max(50),
  fusion_password: yup.string().max(50),
  default_email_from: yup.string().email(messages.email).max(254).required(messages.required),
  captcha_secret: yup.string().max(75).nullable(),
  captcha_key: yup.string().max(75).nullable(),
  gmaps_key: yup.string().max(75).nullable(),
  gmaps_secret: yup.string().max(75).nullable(),
  deeds_registry: yup.mixed().when('deeds_integration', deeds_integration => (deeds_integration ? yup.string().required(messages.required) : yup.string())).nullable(),
  privacy_policy: yup.string().url(messages.url).max(100).nullable(),
  subscription_management: yup.string().url(messages.url).max(100).nullable()
})
