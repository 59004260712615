/* eslint-disable no-unused-vars */
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'

import extras from '../../../config/extras.json'
import { hasPermission, isConditional, logEvent, parseClasses, parseURL, sortBy, uniqueArray, updateSearchParms, hasAddons, updateAdditionalParams } from '../../../utils'
import { Button } from '../../ui/Button'
import AsyncSelect from '../forms/inputs/AsyncSelect'
import Check from '../forms/inputs/Check'
import CheckInputGroup from '../forms/inputs/CheckGroup'
import Currency from '../forms/inputs/Currency'
import Date from '../forms/inputs/Date'
import DependentSelect from '../forms/inputs/DependentSelect'
import Extras from '../forms/inputs/Extras'
import Float from '../forms/inputs/Float'
import LocationSelect from '../forms/inputs/LocationSelect'
import Select from '../forms/inputs/Select'
import Text from '../forms/inputs/Text'
import WatcherForm from '../forms/WatcherForm'
import Time from '../forms/inputs/Time'
import { formikUseField } from '../forms/customFormikUseField'
import CustomForm from '../forms/CustomForm'

import FieldGroup from '../forms/FieldGroup'


const AsyncSelectInput = formikUseField(AsyncSelect)
const CheckInput = formikUseField(Check)
const CheckInputGroupInput = formikUseField(CheckInputGroup)
const CurrencyInput = formikUseField(Currency)
const DateInput = formikUseField(Date)
const DependentSelectInput = formikUseField(DependentSelect)
const ExtrasInput = formikUseField(Extras)
const FloatInput = formikUseField(Float)
const LocationSelectInput = formikUseField(LocationSelect)
const SelectInput = formikUseField(Select)
const TextInput = formikUseField(Text)
const TimeInput = formikUseField(Time)

class AdvancedSearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { initvals: {} }
    this.updateCount = this.updateCount.bind(this)
    this.updateLocations = this.updateLocations.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.isConditional = isConditional.bind(this)
  }

  componentDidMount() {
    const { advsearch, modelname } = this.props.config
    const vals = {}
    // let advanced = false
    const bool_fields = []
    const bool_list = []
    advsearch.forEach(group => {
      group.forEach(field => {
        const key = `${field.name}__${field.verb}`
        // Check if an advanced search param already exists at mount
        if (Object.keys(this.props.model.params).includes(key)) {
          vals[key] = this.props.model.params[key]
        }
        if (field.valsasbools) {
          bool_fields.push(field.name)
          field.options.forEach(v => {
            if (Object.keys(this.props.model.params).includes(v.value)) {
              bool_list.push(v.value)
            }
          })
        }
        // if (!this.props.config.params[key] && this.props.model.params[key]) { advanced = true }
      })
    })
    if (this.props.model.params.search) { vals.search = this.props.model.params.search }
    vals.filters = bool_list
    this.setState({ initvals: vals })
    // Toggle advanced search if params already exist
    // if (advanced) { this.props.toggleAdvanced() }
    if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
      // Fetch locations automatically for listings models
      new Promise((resolve, reject) => this.props.actions.fetchLocations({ values: { modelname }, resolve, reject }))
        .then(locations => this.setState({ ...locations }))
    }
    if (this.props.model.params.search) {
      let newparams = { ...this.props.model.params }
      newparams = updateAdditionalParams(newparams, bool_fields)
      newparams.search = false
      updateSearchParms(newparams)
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.model.params, this.props.model.params)) {
      const vals = {}
      const bool_list = []
      const { advsearch } = this.props.config
      advsearch.forEach(group => {
        group.forEach(field => {
          const key = `${field.name}__${field.verb}`
          if (Object.keys(this.props.model.params).includes(key)) {
            vals[key] = this.props.model.params[key]
          }
          if (field.valsasbools) {
            field.options.forEach(v => {
              if (Object.keys(this.props.model.params).includes(v.value)) {
                bool_list.push(v.value)
              }
            })
          }
        })
      })
      if (this.props.model.params.search) { vals.search = this.props.model.params.search }
      vals.filters = bool_list
      this.setState({ initvals: vals })
    }
  }

  switchInput(field = false) { // Need to use old school function here as we bind 'this'
    const s = { ...field }
    switch (field.input) {
      case 'Check':
      case 'CheckNotes':
        s.component = CheckInput
        s.options = field.options
        s.type = 'string'
        break
      case 'CheckGroup':
        s.component = CheckInputGroupInput
        s.options = field.options
        s.type = 'bool'
        break
      case 'Date':
        s.component = DateInput
        break
      case 'Extras': {
        s.component = ExtrasInput
        s.hidelegend = true
        if (this.props.cache) {
          const site_id = this.props.user.agent.site.id
          const settings = this.props.cache.settings
          const portals = getIn(settings, `${site_id}.portals.agency`, []).map(p => {
            const pconf = getIn(settings, `${site_id}.portals.global`, []).find(pc => pc.id === p.portal)
            return pconf.slug
          })
          s.portals = sortBy(getIn(settings, `${site_id}.portals.agency`, []).map(p => {
            const pconf = getIn(settings, `${site_id}.portals.global`, []).find(pc => pc.id === p.portal)
            return pconf
          }), 'id')
          s.options = merge([], extras.options)
            .map(o => {
              if (o.options) {
                o.options = [ ...o.options ]
                  .map(so => {
                    so.group = o.label
                    return so
                  })
                  .filter(so => (so.portals ? so.portals.some(p => portals.includes(p)) : true))
              }
              if (o.portals) {
                o.portals = o.portals.filter(p => portals.includes(p))
              }
              return o
            })
            .filter(o => {
              if (o.portals) {
                return o.portals.some(p => portals.includes(p))
              }
              if (o.options) {
                return o.options.some(so => so.portals.some(p => portals.includes(p)))
              }
              return true
            })
        }
        break
      }
      case 'LocationSelect':
        s.component = LocationSelectInput
        s.cache = {}
        if (field.extraparams && this.form) { // Used for form submission
          s.params = parseURL(
            field.extraparams,
            this.form.values,
            this.props.user ? this.props.user.agent : false,
            this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
          )
        }
        if ([ 'locations', 'suburbs' ].includes(s.modelname) && this.state.areas) {
          s.params = parseURL(
            `${field.extraparams}&id__in=${Object.keys(this.state.locations).join(',')}`,
            { ...this.form.values, area__in: Object.keys(this.state.areas) },
            this.props.user ? this.props.user.agent : false,
            this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
          )
          s.force_filter = this.state.locations ? this.state.locations : null
        }
        if ([ 'areas' ].includes(s.modelname) && this.state.areas) {
          s.params = parseURL(
            `${field.extraparams}&id__in=${Object.keys(this.state.areas).join(',')}`,
            { ...this.form.values },
            this.props.user ? this.props.user.agent : false,
            this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
          )
          s.force_filter = this.state.areas ? this.state.areas : null
        }
        break
      case 'Select':
        s.component = SelectInput
        s.dependents = field.dependents
        s.user = this.props.user
        s.type = 'list'
        break
      case 'AsyncSelect':
        s.component = AsyncSelectInput
        s.endpoint = this.props.configs[s.modelname].endpoint
        s.searchkey = s.optionlabel
        s.fetchMany = this.props.actions.fetchMany
        s.trigram = this.props.configs[s.modelname].search.trigram
        s.cache = { ...this.props.cache[s.modelname] }
        s.params = parseURL(
          s.extraparams,
          this.form.values,
          this.props.user ? this.props.user.agent : false,
          this.props.cache.settings[this.props.user.agent.site.id])
        s.type = 'list'
        s.actions = {
          cacheDelta: this.props.actions.cacheDelta
        }
        break
      case 'DependentSelect':
        s.component = DependentSelectInput
        s.type = 'list'
        break
      case 'Float':
        s.component = FloatInput
        break
      case 'Currency':
        s.component = CurrencyInput
        break
      case 'Time':
        s.component = TimeInput
        break
      default:
        s.component = TextInput
        s.type = 'string'
    }
    if (s.options && this.props.addons) {
      s.options = s.options.filter(o => hasAddons(o.addons, this.props.addons))
    }
    if (s.options && this.props.user) {
      s.user = this.props.user
      s.cache = s.cache ? { ...s.cache, settings: this.props.cache.settings } : { settings: this.props.cache.settings }
      s.options = s.options.filter(o => this.isConditional(o, 'show', true)).filter(o => hasPermission(o.permissions, this.props.user.permissions))
      s.options = uniqueArray(s.options, s.optionvalue || 'value')
    }
    return s
  }

  updateCount(count) {
    this.setState({ count })
  }

  updateLocations(values) {
    new Promise((resolve, reject) => {
      this.props.actions.fetchLocations({ values, resolve, reject })
    }).then(areas_suburbs => {
      // Only set areas if they haven't already been set
      const { locations, areas } = areas_suburbs
      const { locations: prevLocations, areas: prevAreas } = this.state
      if (!isEqual({ locations, areas }, { locations: prevLocations, areas: prevAreas })) {
        if (prevAreas) {
          // merge areas, don't replace
          areas_suburbs.areas = merge(prevAreas, areas)
        }
        this.setState(areas_suburbs)
      }
    })
  }

  renderButtons(formikProps) {
    const cleanedparams = { ...this.props.model.params }
    const defaultparams = { ...this.props.config.params }
    // Remove parameters that do not actually filter
    delete cleanedparams.meta_fields
    delete cleanedparams.order_by
    delete cleanedparams.order_by_related
    delete cleanedparams.offset
    delete cleanedparams.limit
    delete defaultparams.order_by
    delete defaultparams.limit
    return (
      <div className="search-buttons" key="adv-btns">
        { [ 'residential', 'commercial', 'holiday', 'projects' ].includes(this.props.config.modelname) && this.state.count > 0 &&
        <div className="adv-count">{this.state.count} {this.state.count === 1 ? this.props.config.singular : this.props.config.plural}</div>
        }
        <Button
          icon="#icon16-Search"
          type="button"
          disabled={formikProps.isSubmitting || ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(this.props.config.modelname) && !this.state.count) || !formikProps.dirty}
          className="btn btn-primary btn-icon-16 btn-icon-left"
          onClick={this.form.handleSubmit}
        >
          Search
        </Button>
        {formikProps.status && formikProps.status.success &&
        <Button type="button" onClick={e => {
          this.form.resetForm()
          this.props.handleReset(e)
        }} title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
          Reset Filters
        </Button>
        }
        {!isEqual(defaultparams, cleanedparams) &&
          <Button type="button" onClick={e => {
            formikProps.resetForm()
            this.props.handleReset(e)
          }} title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
            Reset Filters
          </Button>
        }
        <Button type="button" onClick={this.props.toggleAdvanced} className="btn btn-sublte">
          Close
        </Button>
      </div>
    )
  }

  addVerb(formikProps) {
    const { advsearch } = this.props.config
    if (advsearch) {
      return (
        <div className="search-fields">
          {advsearch.map((group, gidx) => {
            const fields = group.map(field => {
              const fieldProps = this.props.config.fields.find(f => f.name === field.name)
              let classes = field.classes ? parseClasses(field.classes, this.form.values) : ''
              classes = classes.split(' ')
              let cols = classes.find(c => c.includes('col-'))
              classes = classes.filter(c => !c.includes('col-'))
              cols = cols.replace('col-', '')
              const props = {
                ...fieldProps,
                ...field,
                group: `group-${gidx}`,
                name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
                classes,
                required: false,
                readOnly: false,
                quality: false,
                cols,
                match: this.props.match,
                id: field.id ? `field-${field.id}-${field.name}` : `field-${field.name}`
              }
              if (props.input?.endsWith('Notes')) {
                props.input = props.input.replace('Notes', '')
              }
              if (props.input === 'Extras') {
                props.hidelegend = true
              }
              if (props.input === 'LocationSelect') {
                if (props.extraparams && this.form) { // Used for form submission
                  props.params = parseURL(
                    props.extraparams,
                    this.form.values,
                    this.props.user ? this.props.user.agent : false,
                    this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
                  )
                }
                if ([ 'locations', 'suburbs' ].includes(props.modelname) && this.state.areas) {
                  props.params = parseURL(
                    `${props.extraparams}&id__in=${Object.keys(this.state.locations).join(',')}`,
                    { ...this.form.values, area__in: Object.keys(this.state.areas) },
                    this.props.user ? this.props.user.agent : false,
                    this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
                  )
                  props.force_filter = this.state.locations ? this.state.locations : null
                }
                if ([ 'areas' ].includes(props.modelname) && this.state.areas) {
                  props.params = parseURL(
                    `${props.extraparams}&id__in=${Object.keys(this.state.areas).join(',')}`,
                    { ...this.form.values },
                    this.props.user ? this.props.user.agent : false,
                    this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
                  )
                  props.force_filter = this.state.areas ? this.state.areas : null
                }
              }
              return props
            })
            return (
              <FieldGroup
                card={false}
                groupname={`group-${gidx}`}
                key={`group-${gidx}`}
                match={this.props.match}
                config={{
                  fields
                }}
                fields={fields}
                render={({ renderFieldGroup, hidden }) => {
                  if (hidden) { return null }
                  return renderFieldGroup({
                    fields
                  })
                }}
                buttons={() => {
                  if (gidx === (advsearch.length - 1)) { // Last item in last group
                    return this.renderButtons(formikProps)
                  }
                  return null
                }}
              />
            )
          })}
        </div>
      )
    }
    return null
  }

  render() {
    const { advsearch } = this.props.config
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          limit: this.props.limit,
          ...this.state.initvals
        }}
        onSubmit={(values, form) => { // Remove null values
          const bool_fields = []
          Object.keys(values).forEach(vk => {
            if (values[vk] === null) { delete values[vk]} // Delete null values
            advsearch.forEach(group => {
              group.forEach(field => { // Remove explicit false values
                if (field.name === vk && field.explicit && values[vk] === false) { delete values[vk] }
                if (field.name === vk && field.valsasbools) { bool_fields.push(field.name) }
              })
            })
          })
          if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(this.props.config.modelname)) {
            if (values.agent__in__or) {
              [ 'agent__in__or', 'agent_2__in__or', 'agent_3__in__or', 'agent_4__in__or' ].forEach(v => {
                values[v] = values.agent__in__or
              })
            }
          }
          if ([ 'leads' ].includes(this.props.config.modelname)) {
            if (values.listing__area__in) {
              [ 'residential__area__in__or', 'commercial__area__in__or', 'holiday__area__in__or', 'project__area__in__or' ].forEach(v => {
                values[v] = values.listing__area__in
              })
            }
            if (values.listing__location__in) {
              [ 'residential__location__in__or', 'commercial__location__in__or', 'holiday__location__in__or', 'project__location__in__or' ].forEach(v => {
                values[v] = values.listing__location__in
              })
            }
          }
          // eslint-disable-next-line no-unused-vars
          const { modelname, endpoint, modellist } = values
          let { ...params } = values
          this.props.config.search.key.forEach(k => { params[k] = false }) // Remove simple search term
          params = updateAdditionalParams(params, bool_fields)
          params.offset = 0
          updateSearchParms(params)
          logEvent('ADVANCED_SEARCH', { modelname: this.props.config.modelname, params: values })
          form.setSubmitting(false)
        }}
      >{formik => {
          this.form = formik
          if (this.props.customform) {
            return (
              <CustomForm
                component="div"
                render={() => this.addVerb(formik)}
              />
            )
          }
          return (
            <WatcherForm
              model={this.props.model ? true : false}
              values={formik.values}
              formik={formik}
              config={this.props.config}
              updateCount={this.updateCount}
              updateLocations={this.updateLocations}
              fetchMany={this.props.actions.fetchMany}
              render={() => this.addVerb(formik)}
            />
          )
        }
        }
      </Formik>
    )
  }
}

AdvancedSearch.propTypes = {
  config: PropTypes.object,
  configs: PropTypes.object,
  addons: PropTypes.array,
  model: PropTypes.object,
  match: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  limit: PropTypes.number,
  customform: PropTypes.bool,
  handleReset: PropTypes.func,
  toggleAdvanced: PropTypes.func
}

export default AdvancedSearch
