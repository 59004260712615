/* eslint-disable new-cap */
import { fromJS } from 'immutable'

import config from '../config/config.json'


export default (newstate = fromJS(config.defaultState.app), action) => {
  switch (action.type) {
    default:
      return newstate
  }
}
