import * as yup from 'yup'
import messages from './messages.json'


export const agent_validation = yup.object({
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  branches: yup.array().of(yup.number().typeError('Please select a branch').positive().integer()).required(messages.required),
  display_on_branches: yup.array().of(yup.number().typeError('Please select a valid option').positive().integer()).nullable(),
  first_name: yup.string().trim().max(50).required(messages.required),
  last_name: yup.string().trim().max(50).required(messages.required),
  designation: yup.number().typeError('Please select a valid option').integer().positive().required(messages.required),
  race: yup.number().typeError('Please select a valid option').integer().positive().nullable(),
  email: yup.string().email(messages.email).required(messages.required),
  username: yup.string().email(messages.email).nullable(),
  group: yup.number().typeError('Please select a valid option').when('username', {
    is: username => username,
    then: yup.number().typeError('Please select a valid option').integer().required(messages.required),
    otherwise: yup.number().typeError('Please select a valid option').nullable()
  }),
  country_code: yup.string().nullable().max(10).matches(/^[.\d() +-]+$/, { message: 'Select a valid option' }).required(messages.required),
  cell_number: yup.string().max(20).matches(/^[.\d() +-]+$/, { message: messages.phone }).required(messages.required),
  home_number: yup.string().max(20)
    .matches(/^[.\d() +-]+$/, { message: messages.phone, excludeEmptyString: true }).nullable(),
  tax_office_number: yup.string().max(20)
    .matches(/^[.\d() +-]+$/, { message: messages.phone, excludeEmptyString: true }).nullable(),
  commencement_date: yup.string().matches(/^\d\d\d\d-\d\d-\d\d$/).nullable(),
  user_website: yup.string().url(messages.url).max(50).nullable(),
  facebook_url: yup.string().url(messages.url).max(200).nullable(),
  twitter_url: yup.string().url(messages.url).max(100).nullable(),
  linkedin_url: yup.string().url(messages.url).max(100).nullable(),
  blog_url: yup.string().url(messages.url).max(100).nullable(),
  youtube_url: yup.string().url(messages.url).max(100).nullable(),
  pinterest_url: yup.string().url(messages.url).max(100).nullable(),
  instagram_url: yup.string().url(messages.url).max(100).nullable()
}).test('display_on_branches', // Needs to be present in branches
  schema => {
    const { branches, display_on_branches } = schema
    if (display_on_branches && branches) {
      display_on_branches.forEach(b => {
        if (!branches.includes(b)) {
          return new yup.ValidationError(messages.invalid, display_on_branches, 'display_on_branches')
        }
        return null
      })
      if (display_on_branches.length > branches.length) {
        return new yup.ValidationError(messages.invalid, display_on_branches, 'display_on_branches')
      }
    }
    return true
  }
)

export const agent_signature = () => yup.object({
  template: yup.number().required(messages.required).nullable(),
  branches: yup.array().of(yup.number().integer().min(1)).required(messages.required),
  first_name: yup.string().trim().max(50).required(messages.required),
  last_name: yup.string().trim().max(50).required(messages.required),
  designation: yup.number().integer().positive().required(messages.required),
  email: yup.string().email(messages.email).required(messages.required),
  cell_number: yup.string().max(20).matches(/^[\d() +-]+$/, { message: messages.phone }).required(messages.required),
  home_number: yup.string().max(20)
    .matches(/^[\d() +-]+$/, { message: messages.phone, excludeEmptyString: true }).nullable()
})

export const agents_activity = () => yup.object({
  created__date__gte: yup.string().required(messages.required),
  created__date__lte: yup.string().required(messages.required)
})

export const agents = () => agent_validation
