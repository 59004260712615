import * as yup from 'yup'

import messages from '../messages.json'


export const lightstone_lookup = yup.object({
  propertytype: yup.string().oneOf([ 'Erf', 'Farm', 'Sectional Scheme Unit' ], messages.invalid).nullable(),
  province: yup.number().typeError(messages.invalid).positive().integer().nullable()
})

export const lightstone = () => lightstone_lookup
