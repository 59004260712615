import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

import { Button } from '../../../ui/Button'


const RadioGroupInput = ({
  field: { name }, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="radiogroup">
    {props.options.map((o, oidx) => (
      <div key={`rg-${oidx}`} className="radioinput form-group">
        <label className="radiocontainer">{o.label}
          <input
            id={props.id ? props.id : name}
            name={name}
            value={o.value}
            checked={form.values[name] === o.value}
            onChange={props.handleChange || form.handleChange}
            type="radio"
          />
          <Button component={'span'} className={`radio${form.values[name] === props.value ? ' checked' : ''}`} />
        </label>
        <ErrorMessage component="div" className="error" name={name} />
      </div>
    ))}
  </div>
)

RadioGroupInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string
}

export default RadioGroupInput
