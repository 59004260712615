import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import validate from '../../validate'
import { emailProfileMatches } from '../../actions'
import log from '../../logging'
import { Button } from '../ui/Button'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'


class MatchesForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.fields = [
      {
        name: 'to',
        label: 'To',
        edit: true,
        disabled: true,
        readonly: true
      },
      {
        name: 'from',
        label: 'From',
        input: 'Select',
        options: [
          { value: props.user.agent.id ? props.user.agent.id : 0, label: 'Me' }
        ],
        edit: true,
        required: true
      },
      {
        name: 'subject',
        label: 'Subject',
        edit: true,
        required: true
      },
      {
        name: 'message',
        label: 'Message',
        input: 'TextArea',
        edit: true,
        required: true
      },
      {
        name: 'cc_myself',
        label: 'Send me a copy of the email',
        input: 'Check',
        edit: true
      }
    ]
    const { model, user } = props
    const { contact } = model.meta
    this.fields = this.fields.map(f => {
      if (f.name === 'from') {
        if (contact.introduction_agent && contact.introduction_agent !== user.agent.id) {
          f.options.push({
            value: contact.introduction_agent,
            label: `${contact.meta.introduction_agent.first_name} ${contact.meta.introduction_agent.last_name} <${contact.meta.introduction_agent.email}>`
          })
        }
        if (contact.associated_agents && contact.associated_agents.length) {
          const options = contact.meta.associated_agents.filter(a => a).map(a => ({
            value: a.id,
            label: `${a.first_name} ${a.last_name} <${a.email}>`
          }))
          f.options = [ ...f.options, ...options ]
        }
      }
      return f
    })
    this.state = {
      from: user.agent.id ? user.agent.id : 0,
      subject: 'Properties matching your requirements',
      message: '',
      cc_myself: true
    }
  }

  handleSubmit(values, actions) {
    validate.default.profilematches().validate(values).then(valid => {
      Object.keys(valid).forEach(k => { // Do some post processing
        const field = this.fields.find(f => f.name === k)
        if (Array.isArray(valid[k]) && field && !field.multi) {
          valid[k] = valid[k][0] // Arrays passed in will be mutated to objects if not configed as multi
        }

        if (field && field.parent) { // This is a contained value ie. branch deactivate and listings reassignment
          if (valid[field.parent]) {
            valid[field.parent][k] = valid[k]
          } else {
            valid[field.parent] = {}
            valid[field.parent][k] = valid[k]
          }
          delete valid[k]
        }

        if (field && field.rename) { // User for renaming fields at save time ie. user -> username
          valid[field.rename] = String(valid[k])
          delete valid[k]
        }
      })

      return new Promise((resolve, reject) => {
        this.props.emailProfileMatches({ values: valid, resolve, reject })
      }).then(() => {
        actions.setSubmitting(false)
        this.props.toggleEmailForm(null, true)
      }).catch(e => {
        actions.setSubmitting(false)
        try {
          actions.setErrors({ // set errors in form
            ...JSON.parse(e.body)
          })
          setTimeout(() => { // scroll to first error
            const viewport = document.getElementsByClassName('view')[0]
            if (viewport) {
              const firstError = viewport.getElementsByClassName('error')[0] ? viewport.getElementsByClassName('error')[0].closest('.field') : false
              if (firstError) {
                const box = firstError.getBoundingClientRect()
                const windowTop = window.scrollY + (box.top) - 120
                window.scrollTo(0, windowTop)
              }
            }
          }, 300)
        } catch (f) {
          log.error(e)
          log.error(f)
        }
        actions.setStatus({ type: 'error', msg: 'Error' })
      })
    }).catch(e => {
      actions.setSubmitting(false)
      if (e.path && e.errors.length) {
        const error = {}
        error[e.path] = e.errors.pop()
        actions.setErrors(error)
        log.debug(`Mutation errors: ${e}`, e)
      } else {
        log.error(e)
      }
    })
  }

  render() {
    const { model, selected, user, toggleEmailForm } = this.props
    const { listing_model } = model
    return (
      <Formik
        initialValues={{
          selected,
          listing_model,
          to: model.meta.contact ? `${model.meta.contact.first_name} ${model.meta.contact.last_name} <${model.meta.contact.email}>` : null,
          profile: model.id,
          from: this.state.from,
          agent: user.agent.id ? user.agent.id : 0,
          subject: this.state.subject,
          message: this.state.message,
          cc_myself: this.state.cc_myself
        }}
        enableReinitialize={true}
        validationSchema={validate.default.profilematches}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
      >{ formik => {
          this.form = formik
          return (
            <CustomForm
              model={this.props.model ? true : false}
              onChange={() => {
                this.setState({
                  from: formik.values.from,
                  message: formik.values.message,
                  cc_myself: formik.values.cc_myself,
                  subject: formik.values.subject
                })
              }}
              render={ () => (
                <Fragment>
                  <FieldGroup
                    card={false}
                    match={{ params: { model: 'profiles' } }}
                    fields={this.fields}
                  />
                  <div className="profile-form-footer field">
                    <div className="form-group">
                      <label></label>
                      <Button
                        component='button'
                        type='button'
                        className="btn btn-primary"
                        onClick={formik.handleSubmit}
                      >Send</Button>
                      <Button
                        component='button'
                        type='button'
                        className="btn btn-secondary"
                        onClick={e => { toggleEmailForm(e, false) }}
                      >Cancel</Button>
                    </div>
                  </div>
                </Fragment>
              )}
            />
          )
        }}
      </Formik>
    )
  }
}

MatchesForm.propTypes = {
  model: PropTypes.object,
  selected: PropTypes.array,
  user: PropTypes.object,
  toggleEmailForm: PropTypes.func,
  emailProfileMatches: PropTypes.func
}

const mapDispatchToProps = dispatch => bindActionCreators({
  emailProfileMatches
}, dispatch)

export default connect(null, mapDispatchToProps)(MatchesForm)
