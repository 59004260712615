import PropTypes from 'prop-types'
import React from 'react'

import FieldGroup from './common/forms/FieldGroup'


const ViewInput = ({ groupname, gidx, config, form, model }) => (
  <div className='referral-response-outcome'>
    <FieldGroup
      key='fs-0'
      groupname={groupname}
      gidx={gidx}
      fields={config.fields.filter(field => field.group === groupname)}
      form={form}
      modelname={config.modelname}
      modelid={model.id}
      columns
      collapsable={false}
      render={({ renderFieldGroup, hidden }) => {
        if (hidden) { return null }
        return (
          <fieldset className="editgroup">
            {renderFieldGroup(groupname)}
          </fieldset>
        )
      }}
    />
  </div>
)


ViewInput.propTypes = {
  model: PropTypes.object,
  gidx: PropTypes.number,
  config: PropTypes.object,
  form: PropTypes.object,
  groupname: PropTypes.string.isRequired
}

export default ViewInput
