import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

import { uniqueArray } from '../../../../utils'
import db from '../../../../db'
import responsiveSelect from './ResponsiveSelect'
import Label from './Label'


const ResponsiveSelect = responsiveSelect(Select)

const ConflictDeselectInput = props => {
  /* This component is at the moment only used for showing conflicting
  * serviced locations in the branch model. It can be extended in the future
  * if the developer finds a use case, however for now this is the single
  * place it is used.
  */
  const [ conflicts, setConflicts ] = useState({})

  useEffect(() => {
    if (props.form.values.id) { // Only show conflicts in edit
      const data = {
        modelname: props.conflicts,
        all: true,
        fields: props.fields,
        nonull: 'serviced_suburbs',
        conflicts: true,
        params: {
          active: 1,
          serviced_locations__overlap: props.form.values[props.dependent],
          id__not: props.form.values.id
        }
      }
      new Promise((resolve, reject) => {
        props.fetchMany({ values: data, resolve, reject })
      }).then(r => {
        if (Array.isArray(r)) {
          const new_conflicts = {}
          const promises = []
          r.forEach(i => { // Loop over conflicts
            if (
              i.id !== props.form.values.id
              && i.serviced_suburbs
              && i.serviced_suburbs.some(o => props.form.values[props.dependent].includes(o))
            ) { // Not this branch
              let opts = []
              let vals = []
              const ids = i.serviced_suburbs.filter(o => props.form.values[props.dependent].includes(o))
              let options
              if (ids.length) {
                options = db.suburbs
                  .where('id').anyOf(ids)
              }
              promises.push(
                options.toArray(a => a.sort((x, y) => {
                  const strX = `${x.area} ${x.suburb}`
                  const strY = `${y.area} ${y.suburb}`
                  if (strX < strY) { return -1 }
                  if (strX > strY) { return 1 }
                  return 0
                })).then(results => {
                  results.forEach(o => {
                    const option = { label: o.suburb, value: o.id }
                    opts.push(option)
                    vals.push(o.id)
                  })
                  vals = uniqueArray(vals)
                  opts = uniqueArray(opts, 'value')
                  new_conflicts[i.name] = { options: opts, values: vals }
                  return
                })
              )
            }
          })
          Promise.allSettled(promises).then(() => {
            setConflicts(new_conflicts)
          })
        }
      })
    }
  }, [])

  const { field, form, label, onBlur, placeholder, id } = props

  if (Object.keys(conflicts).length === 0) { return null }
  return (
    <div id={id} className="selectinput dependentselectinput form-group">
      <div className="input-group">
        {
          Object.keys(conflicts).map((conflict, cidx) => (
            <div className="form-group col-lg-12" key={`conflict-${cidx}`}>
              <Label htmlFor={field.name}>
                {label} with {conflict}
              </Label>
              <div className="forminput">
                <ResponsiveSelect
                  inputId={field.name}
                  className="react-select"
                  classNamePrefix="react-select"
                  isMulti={true}
                  form={form}
                  field={field}
                  name={`conflict-select-${conflict}`}
                  options={conflicts[conflict].options}
                  onBlur={onBlur}
                  value={conflicts[conflict].options}
                  isSearchable={false}
                  isDisabled={true}
                  placeholder={placeholder}
                  components={{
                    DropdownIndicator: () => null,
                    MultiValueRemove: () => null
                  }}
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

ConflictDeselectInput.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  classes: PropTypes.string,
  dependent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  onBlur: PropTypes.func,
  fetchMany: PropTypes.func,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  cache: PropTypes.object,
  fields: PropTypes.array,
  conflicts: PropTypes.string
}


export default ConflictDeselectInput
