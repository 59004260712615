/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { getRandomColor, isConditional, valueFormat, hasPermission } from '../../utils'
import Activity from '../common/Activity'
import AgentCard from '../common/AgentCard'
import BranchPartners from '../common/BranchPartners'
import BranchSyndication from '../common/BranchSyndication'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import MetaDetail from '../common/MetaDetail'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import SocialMedia from '../ui/SocialMedia'
import BarGraph from '../ui/graphs/BarGraph'
import ActiveListingsWidget from '../widgets/ActiveListingsWidget'
import AgeAnalysisWidget from '../widgets/AgeAnalysisWidget'
import AlertsSentWidget from '../widgets/AlertsSentWidget'
import LeadSourceWidget from '../widgets/LeadSourceWidget'
import LeadStatusWidget from '../widgets/LeadStatusWidget'
import ListingViewsWidget from '../widgets/ListingViewsWidget'
import SocialPerformanceWidget from '../widgets/SocialPerformanceWidget'
import StockWidget from '../widgets/StockWidget'
import TopAgentsWidget from '../widgets/TopAgentsWidget'


class Branch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: false,
      pie_chart: null
    }
    this.getSourcesStat = this.getSourcesStat.bind(this)
    this.isConditional = isConditional.bind(this)
    this.AbortController = new AbortController()
  }

  async getSourcesStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || Object.keys(data).legth === 0) {
      return null
    }
    const array_data = Object.keys(data)
      .map(k => ({ source: k, value: data[k] }))
      .sort((a, b) => (b.value - a.value))
    this[`${state_key}_fetching`] = true
    const total = array_data.reduce((prevValue, nextValue) => prevValue + nextValue.value, 0)
    const top_9 = array_data.slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = source.source
      const count = source.value
      if (count) {
        slices.push({
          name: `${source_key} (${Math.round(count / total * 100)}%)`,
          value: count,
          percentage: count / top_9_total,
          colour: getRandomColor(slices.length)
        })
      }
    })
    const other_totals = total - top_9_total
    if (other_totals) {
      slices.unshift({
        name: `Other (${Math.round(other_totals / total * 100)}%)`,
        value: other_totals,
        percentage: 0,
        percentage_100: 0,
        colour: getRandomColor(slices.length)
      })
    }
    this.setState({ pie_chart: slices })
    return slices
  }

  render() {
    const {
      model,
      config,
      cache,
      actions,
      user,
      match,
      routeConfig
    } = this.props

    const listingstats = [
      [ 'rfs_ac', 'RFS' ],
      [ 'rtl_ac', 'RTL' ],
      [ 'cfs_ac', 'CFS' ],
      [ 'ctl_ac', 'CTL' ],
      [ 'rd', 'RD' ],
      [ 'cd', 'CD' ],
      [ 're', 'RE' ],
      [ 'ce', 'CE' ],
      [ 'h_ac', 'HL' ]
    ]

    const { statistics } = model.meta
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = getIn(cache, `settings.${selected_site}.meta.statistics`, {})

    const listing_stats = []
    listingstats.forEach(stat => {
      const avg = sitestats.active_branches ? Math.round(sitestats[stat[0]] / sitestats.active_branches) : 0
      listing_stats.push({ name: stat[1], Branch: statistics[stat[0]] || 0, 'Company Avg': avg })
    })

    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >

        <Tab tab="dashboard" label="Dashboard">
          <div className="grid-container">
            <div className="grid-rows grid-col-4 grid-container">
              <ActiveListingsWidget branch={model} />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Leads</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_electronic_leads}</h1>
                    <span>
                      Company Avg: {sitestats.active_branches ? (
                        Math.round(sitestats.active_electronic_leads / sitestats.active_branches)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Subscribers</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_mailing_list}</h1>
                    <span>
                      Company Avg: {sitestats.active_branches ? (
                        Math.round(sitestats.active_mailing_list / sitestats.active_branches)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-1"
                bodyclass="stats-card-mini"
                background
                body={
                  <div className="flex-container justify-between">
                    <strong>Users</strong><h1>{statistics.active_agents}</h1>
                  </div>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-1"
                bodyclass="stats-card-mini"
                background
                body={
                  <div className="flex-container justify-between">
                    <strong>Profiles</strong><h1>{statistics.active_profiles}</h1>
                  </div>
                }
              />
            </div>
            <div className="grid-col-4">
              <ListingViewsWidget {...this.props} />
            </div>
            <Card
              classes="grid-col-4"
              bodyclass="bar-graph"
              background
              header={
                <h3>Listings Breakdown</h3>
              }
              body={
                <BarGraph data={listing_stats} dataKeys={[ 'Branch', 'Company Avg' ]} dataColors={[ getRandomColor(1), getRandomColor(0) ]} />
              }
            />
            <SocialPerformanceWidget className="grid-col-4" model={model} />
            <Card
              classes={classNames({ 'grid-col-4': getIn(cache, getIn(model, 'agent_of_the_month')), 'grid-col-3': !getIn(cache, getIn(model, 'agent_of_the_month')) })}
              bodyclass={classNames({ 'flex-container justify-center': getIn(cache, getIn(model, 'agent_of_the_month')), 'flex-container grid-3': !getIn(cache, getIn(model, 'agent_of_the_month')) })}
              background
              body={() => {
                if (![ ...getIn(model, 'branch_franchisees', []) || [], ...getIn(model, 'branch_managers', []) || [], model.leads_manager ].some(f => f)) {
                  return null
                }
                return (
                  <>
                    {model.branch_franchisees && model.branch_franchisees.map((fid, fidx) => {
                      const agent = cache.agents[fid] || model.meta.branch_franchisees[fidx]
                      return (
                        <AgentCard key={`franchisee-${fidx}`} title="Franchisee" agent={agent} />
                      )
                    })}
                    {model.branch_managers && model.branch_managers.map((fid, fidx) => {
                      const agent = cache.agents[fid] || model.meta.branch_managers[fidx]
                      return (
                        <AgentCard key={`branch-manager-${fidx}`} title="Branch Manager" agent={agent} />
                      )
                    })}
                    {model.leads_manager && [ model.leads_manager ].map((fid, fidx) => {
                      const agent = cache.agents[fid] || model.meta.leads_manager
                      return (
                        <AgentCard key={`leads-manager-${fidx}`} title="Leads Manager" agent={agent} />
                      )
                    })}
                  </>
                )
              }}
            />
            <Card
              classes="primary-bg grid-col-1 no-grow"
              bodyclass="flex-container justify-center"
              background
              body={() => {
                const agent = cache.agents[model.agent_of_the_month] || model.meta.agent_of_the_month
                return agent ? (
                  <AgentCard title="Agent of the Month" agent={agent} />
                ) : null
              }}
            />
            <Card
              classes="grid-col-4"
              background
              header={
                <h3>Contact Details</h3>
              }
              body={() => {
                const { map_image } = model.meta
                return (
                  <div className="input-group gap-20 space-evenly">
                    {map_image && <div className="branchmap">
                      {valueFormat('image', { file: map_image.file, width: 1024, height: 768 })}
                      <div className="marker-dot"></div>
                    </div>}
                    <div className="contact-details no-wrap">
                      {[ 'name', 'fax_number', 'email', 'location' ].map((f, fidx) => {
                        const field = config.fields.find(fe => fe.name === f)
                        let val = model[field.name]
                        if (field.modelname || field.metafield) {
                          if (field.optionlabel) {
                            val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                          }
                        }
                        return (
                          <MetaDetail
                            className={'dashboard-meta'}
                            key={`f${fidx}`}
                            label={field.label}
                            value={val}
                            format={field.format}
                            field={field}
                          />
                        )
                      }
                      )}
                      <div className="big-number">{valueFormat('tel', model.telephone_number)}</div>
                    </div>
                    <div className="address-details">
                      {model.physical_address && <div className="branch-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Physical Address</div>
                          <span>{valueFormat('domstring', model.physical_address)}</span>
                        </div>
                      </div>}
                      {model.postal_address && <div className="branch-postal-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Postal Address</div>
                          <span>{valueFormat('domstring', model.postal_address)}</span>
                        </div>
                      </div>}
                    </div>
                    <SocialMedia model={model} />
                  </div>
                )
              }}
            />
            {hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id) ? (
              <div className="grid-col-4 grid-container grid-1">
                <TopAgentsWidget
                  branches={[ model.id ]}
                />
              </div>
            ) : null }
            <div className="grid-col-4 grid-container grid-1">
              <AgeAnalysisWidget
                branches={[ model.id ]}
              />
            </div>
            <div className="grid-col-4 grid-container grid-2">
              <LeadSourceWidget
                branches={[ model.id ]}
              />
              <LeadStatusWidget
                branches={[ model.id ]}
              />
            </div>
            <div className="grid-col-4 grid-container grid-2">
              <StockWidget
                branches={[ model.id ]}
              />
              <AlertsSentWidget
                branches={[ model.id ]}
              />
            </div>
          </div>
        </Tab>

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="syndication" label="Syndication">
          <Card
            bodyclass="dashboard"
            background
            header={
              <h3>Syndication</h3>
            }
            body={
              <BranchSyndication
                model={model}
                modelname={config.modelname}
                portals={cache.portals}
                settings={cache.settings[user.agent.site.id]}
                fetchPortalLogs={actions.fetchPortalLogs}
                syndicatePortalItem={actions.syndicatePortalItem}
              />
            }
          />
        </Tab>

        <Tab tab="media" label="Media">
          <MediaGallery
            media={[
              {
                items: model.meta.images,
                mediatype: 'image',
                fieldname: 'images',
                plural: 'images',
                label: 'Images'
              }, {
                items: model.meta.documents,
                mediatype: 'documents',
                fieldname: 'documents',
                plural: 'documents',
                label: 'Documents'
              }
            ]}
            actions={{
              downloadImages: actions.downloadImages,
              isConditional: this.isConditional
            }}
            config={config}
            model={model}
            modelname={config.modelname}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="partners" label="Partners">
          <BranchPartners model={model} config={config} />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Branch.propTypes = {
  actions: PropTypes.object,
  selected: PropTypes.array,
  model: PropTypes.object,
  routeConfig: PropTypes.object,
  modelid: PropTypes.number,
  title: PropTypes.string,
  tab: PropTypes.string,
  config: PropTypes.object,
  cache: PropTypes.object,
  stats: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
}

export default Branch
