/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import merge from 'deepmerge'
import FileSaver from 'file-saver'
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import isEqual from 'react-fast-compare'
import { default as parse } from 'date-fns/parse'
import { default as isValid } from 'date-fns/isValid'
import { default as format } from 'date-fns/format'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { enZA } from 'date-fns/locale'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import uuidv4 from 'uuid/v4'

import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import Loader from '../../Loader'
import log from '../../../../logging'
import { buildLambdaURI, checkImageSize, logEvent, reorder, sortBy, uniqueArray, valueFormat, useCustomCompareMemo } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SelectInput from '../inputs/Select'
import Label from './Label'


const newTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: 'auto',
          maxHeight: 'none',
          marginLeft: 0
        }
      }
    }
  }
})

const DragHandle = listeners => <i {...listeners} className="dz-handle" />

export const SAFE_LIST = [
  'audio/aac',
  'application/x-abiword',
  'video/x-msvideo',
  'application/vnd.amazon.ebook',
  'image/bmp',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/epub+zip',
  'image/gif',
  'text/calendar',
  'image/jpeg',
  'application/json',
  'application/ld+json',
  'audio/x-midi',
  'audio/mpeg',
  'video/mpeg',
  'video/quicktime',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'audio/ogg',
  'video/ogg',
  'application/ogg',
  'audio/opus',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'image/svg+xml',
  'image/tiff',
  'video/mp2t',
  'text/plain',
  'application/vnd.visio',
  'audio/wav',
  'audio/webm',
  'video/webm',
  'image/webp',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'video/3gpp',
  'audio/3GPP',
  'video/3gpp2',
  'audio/3gpp2',
  'application/x-mspublisher',
  'application/vnd.ms-office',
  'application/vnd-ms.publisher',
  'application/vnd.ms-publisher',
  '012- application/msword',
  'application/zip',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip',
  ''
]

export const IMAGE_LIST = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/webp',
  'image/svg+xml'
]

export const NO_TYPES = [
  '.pub'
]

export const FILES_LIST = SAFE_LIST.filter(t => !IMAGE_LIST.includes(t))


const BrowserField = React.forwardRef(
  (props, ref) => {
    const {
      // eslint-disable-next-line no-unused-vars
      disabled,
      // eslint-disable-next-line no-unused-vars
      id,
      // eslint-disable-next-line no-unused-vars
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
      // eslint-disable-next-line no-unused-vars
      error,
      // eslint-disable-next-line no-unused-vars
      focused,
      // eslint-disable-next-line no-unused-vars
      ownerState,
      // eslint-disable-next-line no-unused-vars
      sx,
      value,
      className,
      button,
      inline,
      inputProps,
      ...other
    } = props
    const handleRef = useForkRef(containerRef, ref)
    return (
      <div className="flatpickr-wrapper" ref={handleRef}>
        {startAdornment}
        <input
          ref={inputProps.ref}
          {...other}
          value={value}
          className={classNames('form-control', { 'input-group-suffix': value && !button }, className, { hidden: inline || button })}
          type={inline ? 'hidden' : 'text'}
        />
        {endAdornment}
      </div>
    )
  }
)
BrowserField.displayName = 'BrowserField'

const ClearButton = React.forwardRef(
  (props, ref) => {
    const {
      // eslint-disable-next-line no-unused-vars
      disabled,
      // eslint-disable-next-line no-unused-vars
      id,
      // eslint-disable-next-line no-unused-vars
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
      // eslint-disable-next-line no-unused-vars
      error,
      // eslint-disable-next-line no-unused-vars
      focused,
      // eslint-disable-next-line no-unused-vars
      ownerState,
      // eslint-disable-next-line no-unused-vars
      sx,
      value,
      className,
      button,
      inline,
      inputProps,
      ...other
    } = props
    return (
      <Button
        {...other}
        type="button"
        icon={button ? '#icon16-X-Small' : '#icon24-X-Small'}
        className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
        title="clear"
      />
    )
  }
)
ClearButton.displayName = 'ClearButton'

const CalendarButton = React.forwardRef(
  (props, ref) => {
    const {
      // eslint-disable-next-line no-unused-vars
      disabled,
      // eslint-disable-next-line no-unused-vars
      id,
      // eslint-disable-next-line no-unused-vars
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
      // eslint-disable-next-line no-unused-vars
      error,
      // eslint-disable-next-line no-unused-vars
      focused,
      // eslint-disable-next-line no-unused-vars
      ownerState,
      // eslint-disable-next-line no-unused-vars
      sx,
      value,
      className,
      button,
      inline,
      inputProps,
      ...other
    } = props
    return (
      <Button
        {...other}
        type="button"
        icon={button ? '#icon16-Calendar' : '#icon24-Calendar'}
        className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
        title="open"
      />
    )
  }
)
CalendarButton.displayName = 'CalendarButton'

const DateField = props => {
  const { dating, updating, file, onSave, onCancel, field, button, form, name } = props
  const [ open, setOpen ] = useState(false)
  const [ value, setValue ] = useState(isValid(new Date(file.caption)) ? file.caption : null)
  const options = { }
  if (dating && !updating && file.file_type === 'image') {
    if (value && !(value instanceof Date && isFinite(value))) {
      options.value = parse(value, 'yyyy-MM-dd', new Date())
    } else {
      options.value = null
    }
    return (
      <div className="dz-thumb-caption">
        <ThemeProvider theme={newTheme}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classNames([ 'forminput' ])}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ enZA }>
                <DatePicker
                  clearable
                  closeOnSelect
                  format={'yyyy-MM-dd'}
                  showDaysOutsideCurrentMonth
                  reduceAnimations
                  {...options}
                  onAccept={v => {
                    setValue(v)
                  }}
                  onChange={v => {
                    if (isValid(v)) {
                      setValue(v)
                    } else {
                      setValue(null)
                    }
                  }}
                  slotProps={{ field: { clearable: true } }}
                  slots={{
                    openPickerIcon: () => '',
                    openPickerButton: CalendarButton,
                    clearIcon: () => '',
                    clearButton: ClearButton,
                    textField: BrowserField
                  }}
                />
              </LocalizationProvider>
            </div>
          </ClickAwayListener>
        </ThemeProvider>
        <span><button type="button" className="btn btn-subtle" title="Cancel" onClick={onCancel}>Cancel</button></span>
        <span><button type="button" className="btn btn-primary" title="Save" onClick={() => onSave(format(value, 'yyyy-MM-dd'))}>Save</button></span>
      </div>
    )
  }
  return null
}

DateField.propTypes = {
  dating: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  updating: PropTypes.bool,
  file: PropTypes.object,
  site: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func

}

const LinkField = props => {
  const { form, file, linking, updating, onSave, onCancel, handleEditLinkPlan } = props
  const current_pt = form.values.property_types?.find(pt => pt.linked_plans && pt.linked_plans.includes(file.id))
  const [ value, setValue ] = useState(current_pt ? current_pt.plan_name : null)
  if (linking && !updating) {
    const plan_names = []
    form.values.property_types?.forEach(pt => {
      if (pt.plan_name) {
        plan_names.push({ value: pt.plan_name, label: pt.plan_name })
      }
    })
    return (
      <div className="dz-thumb-caption linked_plan">
        <div>
          <SelectInput
            onChange={e => setValue(e.value)}
            className="form-control"
            placeholder="Select plan..."
            id="plan"
            form={form}
            // noclear
            field={{
              name: 'plan',
              value: value ? value : null
            }}
            options={plan_names}
          />
        </div>
        <span><button type="button" className="btn btn-subtle" title="Cancel" onClick={onCancel}>Cancel</button></span>
        <span><button type="button" className="btn btn-primary" title="Save" onClick={() => onSave(value)}>Save</button></span>
      </div>
    )
  }
  return null
}

LinkField.propTypes = {
  file: PropTypes.object,
  linking: PropTypes.bool,
  defaultValue: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleLinkPlan: PropTypes.func
}

const CaptionField = props => {
  const { file, captioning, defaultValue, updating, onSave, onCancel, handleEditCaption } = props
  const [ value, setValue ] = useState(file.caption || defaultValue)
  if (captioning && !updating && file.file_type === 'image') {
    return (
      <div className="dz-thumb-caption">
        <div><input autoFocus type="text" name="amecaption" maxLength="200" value={value || ''} onChange={e => setValue(e.target.value)} className="form-control" /></div>
        <span><button type="button" className="btn btn-subtle" title="Cancel" onClick={onCancel}>Cancel</button></span>
        <span><button type="button" className="btn btn-primary" title="Save" onClick={() => onSave(value)}>Save</button></span>
      </div>
    )
  } else if (file.file_type === 'file') {
    if (captioning && !updating) {
      return (
        <Fragment>
          <div>
            <input autoFocus type="text" name="amecaption" maxLength="200" value={value || ''} onChange={e => setValue(e.target.value)} className="form-control" />
            <span><button type="button" className="btn btn-subtle" title="Cancel" onClick={onCancel}>Cancel</button></span>
            <span><button type="button" className="btn btn-primary" title="Save" onClick={() => onSave(value)}>Save</button></span>
          </div>
        </Fragment>
      )
    }
    return (
      <div className="dz-thumb-caption">
        <span>{file.caption || defaultValue}{file.tagged_date && file.caption ? ' | ' : ''}{file.tagged_date}</span>
        <Button onClick={handleEditCaption} icon="#icon24-Edit" type="button" title="Edit" className="dz-edit-file-name btn btn-none btn-icon btn-icon-16" />
      </div>
    )
  }
  return null
}

CaptionField.propTypes = {
  file: PropTypes.object,
  captioning: PropTypes.bool,
  defaultValue: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleEditCaption: PropTypes.func
}


const ImageItem = props => {
  const [ imgloaded, setImgloaded ] = useState(false)
  const [ file, setFile ] = useState(props.file)
  const ref = props.forwardRef
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: file?.id || file?.uuid })
  const style = {
    transform: CSS.Translate.toString(transform)
  }

  useEffect(() => {
    if (!isEqual(props.file, file)) {
      setFile(props.file)
      if (!isEqual(props.file.file, file.file)) {
        setImgloaded(false)
      }
    }
    return () => {}
  }, [ useCustomCompareMemo(props.file) ])

  const imageLoaded = e => {
    if (!e) { return null }
    setImgloaded(true)
    return e
  }

  if (!file) { return null }

  const {
    captioning,
    linking,
    dating,
    updating,
    modelname,
    fieldname,
    mini,
    handleRemoveImage,
    handleEditDate,
    handleCancelEditDate,
    handleSaveDate,
    handleEditLinkPlan,
    handleCancelLinkPlan,
    handleSaveLinkPlan,
    handleEditCaption,
    handleCancelEditCaption,
    handleSaveCaption,
    handleRotateLeft,
    handleRotateRight,
    toggleSelected
  } = props
  const mediamode = [ 'images', 'documents' ].includes(modelname)
  let filename = file.caption
  if (!filename) {
    if (typeof file.file === 'string') {
      const parts = file.file.split('/').pop().split('.')
      parts.pop()
      filename = parts.join('.')
    } else if (file.filename) {
      const parts = file.filename.split('.')
      parts.pop()
      filename = parts.join('.')
    }
  }

  let icon = ''
  const type = file.content_type ? file.content_type : file.type
  if (FILES_LIST.includes(type)) {
    switch (type) {
      case 'application/x-abiword':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.oasis.opendocument.text':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentDoc" /></svg>
        break
      case 'application/pdf':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentPdf" /></svg>
        break
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentXls" /></svg>
        break
      case 'application/vnd.oasis.opendocument.presentation':
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentPpt" /></svg>
        break
      case 'text/plain':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentTxt" /></svg>
        break
      case 'text/csv':
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentCsv" /></svg>
        break
      default: {
        icon = <svg viewBox="0 0 32" ref={imageLoaded}><use href="/images/glyphs.svg#glyph-DocumentTxt" /></svg>
      }
    }
  }
  return (
    <div ref={el => {
      ref(el)
      setNodeRef(el)
    }} {...attributes} style={style} className={`dz-item${mediamode ? ' mediamode' : ''} dz-item-id-${file.id}`}>
      <div className={`dz-thumb${((captioning === file.id && updating) || file.state === 'uploading' || file.state === 'in-progress' || !imgloaded) ? ' updating' : ''}`}>
        {IMAGE_LIST.includes(file.type) || IMAGE_LIST.includes(file.content_type) ? (
          <div className="dz-thumb-img" {...listeners}>
            {(file.state === 'in-progress' || file.state === 'uploading' || !imgloaded || updating) &&
              <Loader onError={() => {
                const error_file = merge({}, file)
                error_file.state = 'error'
                setImgloaded(true)
                setFile(error_file)
              }} inline />
            }
            {file.state !== 'uploading' && imgloaded ? (
              <div className="dz-thumb-img-size">
                <div>{valueFormat('filesize', file.file_size)}</div>
              </div>
            ) : null
            }
            <img
              alt=""
              src={mediamode ? file.file : file.preview}
              onLoad={imageLoaded}
              onError={() => {
                const error_file = merge({}, file)
                error_file.state = 'error'
                setImgloaded(true)
                setFile(error_file)
              }}
            />
            {(file.caption || file.tagged_date) && !captioning ? (
              <div className="dz-thumb-caption">
                <div>{file.caption}{file.caption && file.tagged_date ? ' | ' : ''}{file.tagged_date}</div>
              </div>
            ) : null
            }
          </div>
        ) : (
          <div title={filename} className="dz-thumb-img icon">
            <a title={filename} href={file.file} target="_blank" rel="noopener noreferrer">
              {file.state !== 'in-progress' && file.state !== 'uploading' && icon}
            </a>
          </div>
        )}
      </div>
      { mini ? (
        (file.state !== 'uploading' && file.state !== 'in-progress' && imgloaded) ? (
          <div className="dz-actions dz-actions-mini">
            { captioning !== file.id && linking !== file.id && dating !== file.id && !mediamode && !icon &&
              <Button title="Rotate Left" className="btn btn-subtle btn-icon btn-icon-24 rotate" type='button' onClick={handleRotateLeft}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-RotateLeft" /></svg></Button>
            }
            { captioning !== file.id && linking !== file.id && dating !== file.id && !mediamode && !icon &&
              <Button title="Rotate Right" className="btn btn-subtle btn-icon btn-icon-24 rotate" type='button' onClick={handleRotateRight}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-RotateRight" /></svg></Button>
            }
            { captioning !== file.id && linking !== file.id && dating !== file.id && file.file && typeof file.file === 'string' && !mediamode &&
              <Button title="Remove" className="btn btn-subtle btn-icon btn-icon-24 delete" type='button' onClick={handleRemoveImage}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Bin" /></svg></Button>
            }
          </div>
        ) : null
      ) : (
        (file.state !== 'uploading' && file.state !== 'in-progress' && imgloaded) ? (
          <div className="dz-actions">
            { captioning !== file.id && linking !== file.id && dating !== file.id && !mediamode &&
              <label className="checkcontainer">
                <input
                  checked={props.selected}
                  type="checkbox"
                  onChange={() => toggleSelected(file.id)} />
                <span className={`checkmark checkmark-${file.id}`}>
                  {props.selected && props.selected !== 'false' ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
                </span>
              </label> }

            { captioning !== file.id && dating !== file.id && linking !== file.id && !mediamode &&
              <Button title="Rotate Left" className="btn btn-subtle btn-icon btn-icon-24 rotate" type='button' onClick={handleRotateLeft}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-RotateLeft" /></svg></Button>
            }
            { captioning !== file.id && dating !== file.id && linking !== file.id && !mediamode &&
              <Button title="Rotate Right" className="btn btn-subtle btn-icon btn-icon-24 rotate" type='button' onClick={handleRotateRight}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-RotateRight" /></svg></Button>
            }
            { captioning !== file.id && dating !== file.id && linking !== file.id && !mediamode &&
              <span><button type="button" className="btn btn-subtle" onClick={handleEditCaption}>Set Caption</button></span>
            }
            { captioning !== file.id && dating !== file.id && linking !== file.id && !mediamode && fieldname === 'progress_tracker' &&
              <span><button type="button" className="btn btn-subtle" onClick={handleEditDate}>Set Date</button></span>
            }
            { captioning !== file.id && dating !== file.id && linking !== file.id && !mediamode && fieldname === 'floor_plans' && modelname === 'projects' &&
              <span><button type="button" className="btn btn-subtle" onClick={handleEditLinkPlan}>Link Plan</button></span>
            }
            { captioning !== file.id && dating !== file.id && linking !== file.id && file.file && typeof file.file === 'string' && !mediamode &&
              <span><button title="Remove" type="button" className="btn btn-subtle btn-icon btn-icon-24 delete" onClick={handleRemoveImage}><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Bin" /></svg></button></span>
            }

            <CaptionField
              file={file}
              captioning={captioning === file.id}
              updating={updating}
              onSave={handleSaveCaption}
              onCancel={handleCancelEditCaption}
            />

            <LinkField
              form={props.form}
              file={file}
              linking={linking === file.id}
              updating={updating}
              onSave={handleSaveLinkPlan}
              onCancel={handleCancelLinkPlan}
            />

            <DateField
              file={file}
              dating={dating === file.id}
              updating={updating}
              onSave={handleSaveDate}
              onCancel={handleCancelEditDate}
            />

          </div>
        ) : null
      )}
      {file.state === 'uploading' &&
        <div className="filehead" ref={ref}></div>
      }
    </div>

  )
}

ImageItem.propTypes = {
  file: PropTypes.object,
  captioning: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  updating: PropTypes.bool,
  selected: PropTypes.bool,
  modelname: PropTypes.string,
  fieldname: PropTypes.string,
  index: PropTypes.number,
  handleRemoveImage: PropTypes.func,
  handleEditCaption: PropTypes.func,
  handleSaveCaption: PropTypes.func,
  handleCancelEditCaption: PropTypes.func,
  handleLinkPlan: PropTypes.func,
  handleCancelLinkPlan: PropTypes.func,
  dating: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  mini: PropTypes.bool,
  handleEditDate: PropTypes.func,
  handleCancelEditDate: PropTypes.func,
  handleSaveDate: PropTypes.func,
  handleRotateLeft: PropTypes.func,
  handleRotateRight: PropTypes.func,
  toggleSelected: PropTypes.func

}

const FileItem = props => {
  const {
    file,
    captioning,
    updating,
    modelname,
    handleRemoveImage,
    handleEditCaption,
    handleCancelEditCaption,
    handleSaveCaption
  } = props
  let filename = file.caption
  const mediamode = [ 'images' ].includes(modelname)
  const ref = props.forwardRef

  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: file.id || file.uuid })
  const style = {
    transform: CSS.Translate.toString(transform)
  }
  if (!filename) {
    if (typeof file.file === 'string') {
      const parts = file.file.split('/').pop().split('.')
      parts.pop()
      filename = parts.join('.')
    } else if (file.filename) {
      const parts = file.filename.split('.')
      parts.pop()
      filename = parts.join('.')
    }
  }
  return (
    <tr
      {...attributes}
      style={style}
      ref={el => {
        ref(el)
        setNodeRef(el)
      }}
      className="dz-file-item"
    >
      {(file.state === 'uploading' || file.state === 'in-progress') &&
        <td colSpan={5}><div className="filehead" ref={ref}></div></td>
      }
      {(file.state !== 'uploading' && file.state !== 'in-progress') &&
        <Fragment>
          <td className="dz-file-handle">
            { !mediamode &&
              <DragHandle {...listeners} />
            }
          </td>
          <td className="dz-file-name">
            <CaptionField
              file={file}
              defaultValue={filename}
              captioning={captioning === file.id}
              updating={updating}
              onSave={handleSaveCaption}
              onCancel={handleCancelEditCaption}
              handleEditCaption={handleEditCaption}
            />
          </td>
          <td className="dz-file-size">
            {valueFormat('filesize', file.file_size)}
          </td>
          <td className="dz-file-type">
            {typeof file.file === 'string' &&
              valueFormat('filetype', file.content_type)
            }
          </td>
          <td className="dz-file-actions">
            <Button onClick={() => {
              const ext = file.file.split('/').pop().split('.').pop()
              FileSaver.saveAs(file.file, filename.indexOf(`.${ext}`) !== -1 ? filename : `${filename}.${ext}`)
            }} icon="#icon16-Download" type="button" title="Download" className="btn btn-none btn-icon btn-icon-16" />
            { !mediamode &&
              <Button onClick={handleRemoveImage} icon="#icon24-Bin" type="button" title="Delete" className="btn btn-none btn-icon btn-icon-16" />
            }
          </td>
        </Fragment>
      }
    </tr>
  )
}

FileItem.propTypes = {
  file: PropTypes.object,
  captioning: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  updating: PropTypes.bool,
  index: PropTypes.number,
  modelname: PropTypes.string,
  handleRemoveImage: PropTypes.func,
  handleEditCaption: PropTypes.func,
  handleCancelEditCaption: PropTypes.func,
  handleSaveCaption: PropTypes.func
}

const SortableDnDContext = ({ children, ...rest }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      {...rest}
    >
      {children}
    </DndContext>
  )
}

class FileDropzoneInput extends React.Component {
  constructor(props) {
    super(props)
    const { value } = props.field
    let fileids = []
    let files = []
    if (value && Array.isArray(value) && props.meta) {
      fileids = value
      files = props.meta
    } else if (value && props.meta) {
      fileids = [ value ]
      files = [ props.meta ]
    }
    this.state = {
      files, // Files uploaded / uploading with all relevant data
      fileids: fileids, // Maps to file IDs that will be saved, order is NB.
      dating: false, // Editing date
      captioning: false, // Editing caption
      linking: false, // Linking a floor plan
      updating: false, // AJAX'ing caption update
      inited: false, // Used to initialise state when initial value is receive from Formik,
      types: [],
      selected: []
    }
    this.imagerefs = {}
    this.dropFile = this.dropFile.bind(this)
    this.changeCaption = this.changeCaption.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.removeFile = this.removeFile.bind(this)

    this.dragEnded = this.dragEnded.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.rotateImage = this.rotateImage.bind(this)
    this.fetchFiles = this.fetchFiles.bind(this)
    this.toggleSelected = this.toggleSelected.bind(this)
    this.removeSelected = this.removeSelected.bind(this)
    this.downloadSelected = this.downloadSelected.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { filetype, mini, types } = this.props
    let allowed_types = []
    switch (filetype) {
      case 'image':
        allowed_types = mini ? SAFE_LIST : IMAGE_LIST
        break
      default:
        allowed_types = SAFE_LIST
    }
    if (types) {
      allowed_types = allowed_types.filter(type => types.includes(type))
    }
    this.setState({ types: allowed_types })
    if (
      !this.props.meta
      && this.props.field.value
    ) {
      this.fetchFiles()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(this.props, nextProps) && isEqual(this.state, nextState)) { return false }
    return true
  }

  componentDidUpdate(prevProps) {
    if (this.props.newfiles && !isEqual(prevProps.newfiles, this.props.newfiles)) {
      this.dropFile(this.props.newfiles).then(() => {
        this.props.setFiles(null)
      })
    }
    if (
      !this.props.meta
      && !isEqual(this.props.field.value, prevProps.field.value) && !this.state.files.length
    ) {
      this.fetchFiles()
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  fetchFiles() {
    const { field, multi } = this.props
    let values
    if (this.state.fileids?.length) {
      values = this.state.fileids
    } else if (field.value) {
      values = multi ? field.value : [ parseInt(field.value, 10) ]
    }
    if (values && values.length) {
      new Promise((resolve, reject) => this.props.fetchMany({
        values: {
          modelname: 'gallery',
          endpoint: {
            read: '/gallery/api/v1/files'
          },
          select: true,
          params: {
            id__in: values,
            get_all: 1
          },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => {
        if (!isEqual(r.options, this.state.files) && this._is_mounted) {
          this.setState({ files: sortBy(r.options, 'id', (a, b) => {
            if (values.indexOf(a.id) > values.indexOf(b.id)) {
              return 1
            }
            if (values.indexOf(a.id) < values.indexOf(b.id)) {
              return -1
            }
            return 0
          }), fileids: values })
        }
        return null
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  }

  rotateImage(fileid, direction) {
    const files = merge([], this.state.files) // return new objects
    const fileidx = files.findIndex(f => f.id === fileid)
    files[fileidx].state = 'uploading'
    this.setState({ files })
    return new Promise((resolve, reject) => {
      const values = {
        id: fileid,
        direction: direction
      }
      return this.props.rotateImage({ values, resolve, reject })
    }).then(r => {
      r.preview = buildLambdaURI(r.file, { w: 640, h: 480 })
      const newfiles = merge([], this.state.files) // return new objects
      newfiles[fileidx] = { ...r, state: '' }
      this.setState({ files: newfiles })
    })
  }

  rotateRight(fileid) {
    this.rotateImage(fileid, 'right')
  }

  rotateLeft(fileid) {
    this.rotateImage(fileid, 'left')
  }

  toggleSelected(id) {
    if (this.state.selected.includes(id)) {
      this.setState({ selected: [ ...this.state.selected.filter(sel => sel !== id) ]
      })
    } else {
      this.setState({ selected: [ ...this.state.selected, id ]
      })
    }
  }

  async dropFile(droppedfiles) {
    // Array of js File objects
    const allowed_files = droppedfiles.filter(f => {
      if (this.state.types.includes(f.type)) {
        return true
      }
      if (NO_TYPES.find(t => f.name.includes(t))) {
        return true
      }
      return false
    })
    const { filetype, field, private_file, multi } = this.props
    let files = merge([], this.state.files) // return new objects
    let fileids = merge([], this.state.fileids) // return new objects
    if (!multi) {
      fileids = []
      files = []
    }
    const okfiles = []
    for (let i = 0; i < allowed_files.length; i++) {
      try {
        if (filetype === 'image' && (this.props.minWidth || this.props.minHeight)) {
          await checkImageSize(
            allowed_files[i],
            this.props.minWidth,
            this.props.minHeight
          )
        }
        okfiles.push(allowed_files[i])
        const rid = uuidv4()
        files.push({
          file: allowed_files[i],
          filename: allowed_files[i].name,
          file_type: filetype,
          private_file: private_file,
          file_size: allowed_files[i].size,
          content_type: allowed_files[i].type,
          modified: allowed_files[i].lastModified,
          state: 'uploading',
          uuid: rid,
          caption: FILES_LIST.includes(allowed_files[i].type) ? allowed_files[i].name : null
        })
        allowed_files[i].uuid = rid
      } catch (e) {
        this.props.notifyUser({
          title: allowed_files[i].name,
          body: 'The image is too small ( < 1024 x 768).',
          type: 'error'
        })
        okfiles.push(allowed_files[i])
        const rid = uuidv4()
        files.push({
          file: allowed_files[i],
          filename: allowed_files[i].name,
          file_type: filetype,
          private_file: private_file,
          file_size: allowed_files[i].size,
          content_type: allowed_files[i].type,
          modified: allowed_files[i].lastModified,
          state: 'uploading',
          uuid: rid,
          caption: FILES_LIST.includes(allowed_files[i].type) ? allowed_files[i].name : null
        })
        allowed_files[i].uuid = rid
      }
    }
    this.setState({ files, inited: true, fileids: files.map(f => f.id || f.uuid) }, () => {
      const promises = okfiles.map(file => {
        const values = {
          file: file,
          filetype: filetype,
          field: field.name,
          model: this.props.group === 'Note' ? 'notes' : this.props.modelname,
          private: private_file ? private_file : false,
          caption: FILES_LIST.includes(file.type) ? file.name : null
        }
        const fidx = files.findIndex(f => f.uuid === file.uuid)
        return new Promise((resolve, reject) => {
          if (!files[fidx]) {
            logEvent('FILE_UPLOAD_ERROR', {
              error: 'Could not find file in list of files',
              files
            })
          }
          let el = this.imagerefs[fidx].querySelector('.filehead')
          this.props.uploadFile({
            values,
            resolve,
            reject,
            progress: complete => {
              if (files[fidx]) {
                files[fidx].state = 'in-progress'
                if (!el) {
                  el = this.imagerefs[fidx].querySelector('.filehead')
                }
                if (!el) { return }
                const style = {
                  width: `${complete}%`
                }
                Object.assign(el.style, style)
              }
            }
          })
        }).then(r => {
          if (files[fidx]) {
            files[fidx].id = r.id
            files[fidx].file = r.file
            files[fidx].preview = buildLambdaURI(r.file, { w: 640, h: 480 })
            files[fidx].state = 'uploaded'
            if (this.props.multi) {
              fileids = files.filter(f => f.id).map(f => f.id)
            } else {
              fileids = [ r.id ]
            }
            fileids = fileids.filter(f => f)
            if (!isEqual(this.state.files, files) || !isEqual(this.state.fileids, fileids)) {
              this.setState({ files, fileids }, () => {
                this.props.form.setFieldValue(this.props.field.name, multi ? fileids : fileids[0]).then(() => {
                  this.props.form.setFieldTouched(this.props.field.name)
                })
              })
            }
          }
          return r
        }).catch(e => {
          console.error(e)
          logEvent('FILE_UPLOAD_REJECTED', { files, fidx })
          let reason = 'Bad file'
          if (typeof e === 'string') {
            reason = e
          } else {
            const errs = JSON.parse(e.body)
            const err_keys = Object.keys(errs)
            reason = errs[err_keys[0]]
          }
          this.props.notifyUser({
            title: 'File rejected',
            body: reason,
            type: 'error'
          })
          if (files[fidx]) {
            files[fidx].filename = typeof e === 'string' ? e : e.toString()
            files[fidx].state = 'error'
            files.splice(fidx, 1)
          }
          if (!isEqual(this.state.files, files)) {
            this.setState({ files })
          }
          log.error('FILE_UPLOAD_REJECTED', e)
        })
      })
      // Update the DropZone once all files have completed upload
      Promise.all(promises).then(() => {
        this.forceUpdate()
      })
    })
  }

  editCaption(fileid) {
    this.setState({ captioning: fileid })
  }

  cancelEditCaption() {
    this.setState({ captioning: false })
  }

  changeCaption(fileid, value) {
    this.setState({ updating: true })
    return new Promise((resolve, reject) => {
      const values = {
        id: fileid,
        caption: value
      }
      return this.props.changeCaption({ values, resolve, reject })
    }).then(r => {
      const files = merge([], this.state.files) // return new objects
      const fileidx = files.findIndex(f => f.id === fileid)
      const file = files[fileidx]
      file.caption = r.caption
      files[fileidx] = file
      this.setState({ files, updating: false, captioning: false })
    })
  }

  editLinkPlan(fileid) {
    this.setState({ linking: fileid })
  }

  cancelLinkPlan() {
    this.setState({ linking: false })
  }

  linkPlan(fileid, plan) {
    this.setState({ updating: true })
    const addidx = this.props.form.values.property_types.findIndex(pt => pt.plan_name === plan)
    const rmidx = this.props.form.values.property_types.findIndex(pt =>
      pt.linked_plans && pt.linked_plans.includes(fileid)
    )
    const pts = [ ...this.props.form.values.property_types ]
    if (!pts[addidx].linked_plans) { pts[addidx].linked_plans = [] }
    if (!pts[addidx].linked_plans.includes(fileid)) { pts[addidx].linked_plans.push(fileid) }
    if (rmidx !== -1) { pts[rmidx].linked_plans = pts[rmidx].linked_plans.filter(p => p !== fileid) }
    this.props.form.setFieldValue('property_types', pts).then(r => {
      this.setState({ updating: false, linking: false })
    })
  }

  editDate(fileid) {
    this.setState({ dating: fileid })
  }

  cancelEditDate() {
    this.setState({ dating: false })
  }

  changeDate(fileid, date) {
    this.setState({ updating: true })
    return new Promise((resolve, reject) => {
      const values = {
        id: fileid,
        tagged_date: date
      }
      return this.props.changeDate({ values, resolve, reject })
    }).then(r => {
      const files = merge([], this.state.files) // return new objects
      const fileidx = files.findIndex(f => f.id === fileid)
      const file = files[fileidx]
      file.tagged_date = r.tagged_date
      files[fileidx] = file
      this.setState({ files, updating: false, dating: false })
    })
  }

  removeSelected() {
    const { selected } = this.state
    let fileids = merge([], this.state.fileids) // return new objects
    let files = merge([], this.state.files) // return new objects
    fileids = fileids.filter(id => !selected.includes(id))
    files = files.filter(file => !selected.includes(file.id))
    this.props.form.setFieldValue(this.props.field.name, this.props.multi ? fileids : fileids[0]).then(() => {
      this.props.form.setFieldTouched(this.props.field.name)
    })
    this.setState({ files, fileids, selected: [] })
  }

  downloadSelected() {
    const { modelname, form, downloadImages } = this.props
    const filename = `${modelname}_${form.values.id}_images.zip`
    if (this.state.selected.length > 1) {
      new Promise((resolve, reject) =>
        downloadImages({ values: { selected: this.state.selected, filename }, resolve, reject }))
        .then(r => {
          const blob = new Blob([ r ], { type: 'application/zip' })
          const url = window.URL.createObjectURL(blob)

          const downloadByUrl = () => {
            this.downloadManager.downloadUrl(url, filename)
          }
          try {
            this.downloadManager.download(blob, url, filename)
          } catch (e) {
            downloadByUrl()
          }
        })
    }
  }


  removeFile(fileid) {
    let fileids = merge([], this.state.fileids) // return new objects
    let last_img_check = false
    if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(this.props.modelname)) {
      if (this.props.field.name === 'listing_images' && this.props.form.values.display_on_website === true && fileids.length === 1) {
        last_img_check = true
        this.props.notifyUser({
          title: 'Photo Removal',
          body: 'You can only remove all images if Display On Website is unchecked',
          type: 'error'
        })
      }
    }

    if (!last_img_check) {
      let files = merge([], this.state.files) // return new objects
      if (this.props.multi) { fileids = fileids.filter(fid => fid !== fileid) } else { fileids = [] }
      files = files.filter(f => f.id !== fileid)
      const fieldfileids = this.props.multi ? fileids : fileids.length ? fileids[0] : null
      this.props.form.setFieldValue(this.props.field.name, fieldfileids).then(() => {
        this.props.form.setFieldTouched(this.props.field.name)
      })
      this.setState({ files, fileids })
    }
  }

  dragEnded(event) {
    const oldIndex = event.active?.data.current.sortable.index
    const newIndex = event.over?.data.current.sortable.index
    if (oldIndex !== newIndex && newIndex >= 0) {
      let fileids = merge([], this.state.fileids) // return new objects
      let files = merge([], this.state.files) // return new objects
      fileids = reorder(fileids, oldIndex, newIndex)
      files = reorder(files, oldIndex, newIndex)
      fileids = fileids.filter(f => f)
      files = files.filter(f => f)
      this.setState({ fileids, files }, () => {
        this.props.form.setFieldValue(this.props.field.name, this.props.multi ? fileids : fileids[0]).then(() => {
          this.props.form.setFieldTouched(this.props.field.name)
        })
      })
    }
  }

  render() {
    const { filetype, multi, mini, dated, modelname, id, label, portals, showLabel, field } = this.props
    const { types } = this.state
    const total_size = this.state.files.length ? (
      this.state.files.reduce((prev_val, next_val) => (prev_val += next_val.file_size), 0)
    ) : 0
    let img_total_size_text = ''
    let total_size_text = ''
    let p24_active = ''
    if ([ 'residential', 'commercial', 'holiday', 'project' ].includes(modelname)) {
      p24_active = portals ? portals.find(p => p.id === 1) : false
      img_total_size_text = p24_active ? <b>Property24 Limit:</b> : <b>Total Size:</b>
      total_size_text = p24_active ? `${valueFormat('filesize', total_size)} / 60MB` : valueFormat('filesize', total_size)
    }
    const icons = uniqueArray(types.map(type => {
      switch (type) {
        case 'application/x-abiword':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
          return '#glyph-DocumentDoc'
        case 'application/pdf':
          return '#glyph-DocumentPdf'
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '#glyph-DocumentXls'
        case 'application/vnd.oasis.opendocument.presentation':
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return '#glyph-DocumentPpt'
        case 'text/plain':
          return '#glyph-DocumentTxt'
        case 'text/csv':
          return '#glyph-DocumentCsv'
        default: {
          return null
        }
      }
    })).map((type, tidx) => <svg key={`icon-${tidx}`} viewBox="0 0 32 32"><use href={`/images/glyphs.svg${type}`} /></svg>)

    return (
      <div className={classNames('form-group', 'dropzoneinput', { 'dropzone-single': !multi })} id={id}>
        {(mini || showLabel) && label ? (
          <Label htmlFor={id} className="formlabel">
            {label}
          </Label>
        ) : null}
        { (multi !== true && this.state.files.length === 1) ? '' : ( // Hide if not multi and image already chosen
          <div className={`${multi ? 'dropzone' : 'dropzone single'}${mini ? ' mini-dropzone' : ''}`}>
            <Dropzone
              ref={node => { if (node) { this.dropzoneRef = node } }}
              onDrop={this.dropFile}
              onClick={e => e.preventDefault()}
              accept={types.join(',')}
              multiple={multi === true}
              style={{ border: 'none' }}
            >{({ getRootProps, isDragActive, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={`dz-dropper${isDragActive ? ' dz-dropper--isActive' : ''}`}
                >
                  { mini ? (
                    <div className="dz-message">
                      <Button type="button" icon="#icon16-Paperclip" className="btn btn-primary btn-icon btn-icon-16 btn-icon-left" onClick={e => { e.preventDefault(); e.stopPropagation(); this.dropzoneRef.open() }}>
                        Attach a File
                        <input {...getInputProps()} />
                      </Button>
                    </div>
                  ) : (
                    <div className="dz-message">
                      <Button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); e.stopPropagation(); this.dropzoneRef.open() }}>{this.props.uploadText ? this.props.uploadText : 'Upload'}
                        <input {...getInputProps()} />
                      </Button>
                      <p>or drag {filetype}{multi && 's'} here.{(this.props.minWidth || this.props.minHeight) ? ` (Minimum image size is ${this.props.minWidth} x ${this.props.minHeight})` : '' }</p>
                      { filetype === 'file' &&
                        <div className="dz-icons">
                          {icons}
                        </div>
                      }
                      { filetype === 'image' &&
                        <div className="dz-icons">
                          <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentPng" /></svg>
                          <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentJpg" /></svg>
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        )}
        <div className="dz-container-above">
          {filetype === 'image' && [
            'residential',
            'commercial',
            'holiday',
            'project'
          ].includes(modelname) && this.props.group !== 'Note' ? <div className="dz-collative-img-size">{img_total_size_text}&nbsp;{total_size_text}</div> : null}
          <div className="dz-buttons-above">
            {filetype === 'image' && this.state.files.length > 0 && this.state.selected.length < 1 && [
              'residential',
              'commercial',
              'holiday',
              'project'
            ].includes(modelname) && this.props.group !== 'Note' ?
              <Button type="button" className="btn btn-subtle btn-icon btn-icon-16 btn-icon-left" icon="#icon16-Check" onClick={() => {
                const ids = this.state.files.filter(i => i).map(i => i.id)
                this.setState({ selected: ids })
              }}>Select All</Button>
              : null
            }
            {filetype === 'image' && this.state.files.length > 0 && this.state.selected.length >= 1 && [
              'residential',
              'commercial',
              'holiday',
              'project'
            ].includes(modelname) && this.props.group !== 'Note' ?
              <Button type="button" className="btn btn-subtle btn-icon btn-icon-16 btn-icon-left" icon="#icon16-Check" onClick={() => {
                this.setState({ selected: [] })
              }}>Unselect All</Button>
              : null
            }

            {filetype === 'image' && this.state.files.length > 0 && this.state.selected.length > 0 && [
              'residential',
              'commercial',
              'holiday',
              'project'
            ].includes(modelname) && this.props.group !== 'Note' ?
              <Button
                onClick={this.removeSelected}
                icon="#icon16-Bin"
                type="button"
                title="Download"
                className="btn btn-subtle btn-icon btn-icon-16 btn-icon-left">
              Delete Selected</Button>
              : null
            }


            {filetype === 'image' && this.state.files.length > 0 && this.state.selected.length > 0 && [
              'residential',
              'commercial',
              'holiday',
              'project'
            ].includes(modelname) && this.props.group !== 'Note' ?
              <Button
                onClick={this.downloadSelected}
                icon="#icon16-Download"
                type="button"
                title="Download"
                className="btn btn-subtle btn-icon btn-icon-16 btn-icon-left">
              Download Selected</Button>
              : null
            }
          </div>
        </div>
        { filetype === 'image' && this.state.files.length > 0 &&
          <SortableDnDContext
            onDragEnd={this.dragEnded}
          >
            <div className={classNames('droppeditems dz-list', { 'mini-items': mini })}>
              <SortableContext
                items={multi ? this.state.fileids : []}
              >
                { this.state.fileids.map((fileid, fidx) => {
                  const file = this.state.files.find(f => f.id === fileid || f.uuid === fileid)
                  if (!file) {
                    return null
                  }
                  if (file && !file.file.uuid && !file.preview) {
                    file.preview = buildLambdaURI(file.file, { w: 640, h: 480 })
                  }
                  return (
                    <ImageItem
                      selected={this.state.selected.includes(file?.id)}
                      toggleSelected={this.toggleSelected}
                      key={`${this.props.field.name}-item-${file.uuid || file.id}`}
                      forwardRef={el => { this.imagerefs[fidx] = el }}
                      form={this.props.form}
                      file={file}
                      index={fidx}
                      modelname={modelname}
                      fieldname={field.name}
                      dated={dated}
                      dating={this.state.dating}
                      captioning={this.state.captioning}
                      linking={this.state.linking}
                      updating={this.state.updating}
                      mini={mini}
                      pressDelay={200}
                      handleRemoveImage={this.removeFile.bind(this, file?.id)}
                      handleEditDate={this.editDate.bind(this, file?.id)}
                      handleCancelEditDate={this.cancelEditDate.bind(this, file?.id)}
                      handleSaveDate={this.changeDate.bind(this, file?.id)}
                      handleEditCaption={this.editCaption.bind(this, file?.id)}
                      handleEditLinkPlan={this.editLinkPlan.bind(this, file?.id)}
                      handleSaveLinkPlan={this.linkPlan.bind(this, file?.id)}
                      handleCancelLinkPlan={this.cancelLinkPlan.bind(this, file?.id)}
                      handleCancelEditCaption={this.cancelEditCaption.bind(this)}
                      handleSaveCaption={this.changeCaption.bind(this, file?.id)}
                      handleRotateLeft={this.rotateLeft.bind(this, file?.id)}
                      handleRotateRight={this.rotateRight.bind(this, file?.id)}
                    />
                  )
                })}
              </SortableContext>
            </div>
          </SortableDnDContext>
        }

        { filetype === 'file' && this.state.files.length > 0 &&
        <SortableDnDContext
          modifiers={[ restrictToVerticalAxis ]}
          onDragEnd={this.dragEnded}
        >
          <div className="table-wrapper">
            <table className="dz-file-list">
              <thead>
                <tr>
                  <th className="dz-file-handle"></th>
                  <th className="dz-file-name">Document Name</th>
                  <th className="dz-file-size">Size</th>
                  <th className="dz-file-type">Type</th>
                  <th className="dz-file-actions"></th>
                </tr>
              </thead>
              <tbody>
                <SortableContext
                  strategy={verticalListSortingStrategy}
                  items={this.state.fileids}
                >
                  { this.state.fileids.map((fileid, fidx) => {
                    const file = this.state.files.find(f => f.id === fileid || f.uuid === fileid)
                    if (!file) {
                      return null
                    }
                    return (
                      <FileItem
                        key={`${this.props.field.name}-item-${file.uuid}`}
                        forwardRef={el => { this.imagerefs[fidx] = el }}
                        file={file}
                        index={fidx}
                        modelname={modelname}
                        captioning={this.state.captioning}
                        updating={this.state.updating}
                        pressDelay={200}
                        handleRemoveImage={this.removeFile.bind(this, file.id)}
                        handleEditCaption={this.editCaption.bind(this, file.id)}
                        handleCancelEditCaption={this.cancelEditCaption.bind(this)}
                        handleSaveCaption={this.changeCaption.bind(this, file.id)}
                      />
                    )
                  }) }
                </SortableContext>
              </tbody>
            </table>
          </div>
        </SortableDnDContext>
        }

      </div>
    )
  }
}

FileDropzoneInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  filetype: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  notifyUser: PropTypes.func.isRequired,
  changeCaption: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  modelname: PropTypes.string.isRequired,
  rotateImage: PropTypes.func,
  private_file: PropTypes.bool,
  configs: PropTypes.object,
  portals: PropTypes.array,
  multi: PropTypes.bool,
  mini: PropTypes.bool,
  dated: PropTypes.bool,
  meta: PropTypes.object,
  newfiles: PropTypes.array,
  setFiles: PropTypes.func,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  group: PropTypes.string,
  types: PropTypes.array,
  showLabel: PropTypes.bool,
  onSave: PropTypes.func,
  handleEditDate: PropTypes.func,
  actions: PropTypes.object,
  handleEditCaption: PropTypes.func,
  downloadImages: PropTypes.func,
  uploadText: PropTypes.string
}


export default FileDropzoneInput

