import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'

import log from '../../../logging'
import { generateWebsiteLink, parseURL, valueFormat } from '../../../utils'
import FocusTrap from '../focus-trap/FocusTrap'
import { Button } from '../../ui/Button'


class ContextDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownStyle: {
        display: 'none',
        position: 'absolute'
      },
      box: false,
      generatedLink: false
    }
    this.parent = props.el
    this.el = document.createElement('div')
    this.el.setAttribute('class', 'context-dropdown')
    this.root = document.getElementById('wrapper')
    this.onClickAway = this.onClickAway.bind(this)
    this.generateMenuLink = this.generateMenuLink.bind(this)
  }

  findButton(el) {
    while (el.parentElement) {
      el = el.parentElement
      if (!el.classList.contains('btn') && !el.classList.contains('has-submenu')) { continue }
      break
    }
    return el
  }

  findSticky(el) {
    while (el.parentElement) {
      el = el.parentElement
      if (!el.classList.contains('actionswrap')) { continue }
      break
    }
    return el
  }

  onClickAway(e) {
    if (this.el && this.el.contains(e.target)) { return }
    this.props.handleClose(e)
  }

  generateMenuLink(element, sid) {
    const { id } = this.props.match.params
    const { form, user, actions, cache, model, portals, modelname, website_url, selected } = this.props
    let { modelid } = this.props
    let link = element.link
    let visible = false
    let m = cache[modelid]
    if (!m && selected.length) { m = cache[selected[0]] }
    visible = this.props.isButtonVisible(element)
    if (visible) {
      if (element.link) {
        if (element.portal) { // This relates to a portal link
          if (m.meta.portals && portals) { // Ensure selected model portal and global portals exist
            const portalidx = Object.keys(portals).find(pk => portals[pk].meta.portal.slug === element.portal)
            if (!portalidx) { return null }
            const portal = m.meta.portals.find(p => p.portal === portals[portalidx].portal)
            if (!portal) { return null }
            link = valueFormat(element.format, portal.reference, {
              portal: portal.meta.portal.slug,
              reference: portal.reference,
              location: m.meta.location,
              listing_type: m.listing_type
            })
          }
        } else if (element.scope === 'selected') {
          link = parseURL(element.link, { selected, site: user.agent.site.id }) // List with selection
        } else if (id) {
          link = parseURL(element.link, cache[id])
        } else if (modelid && !element.linkto) { // Right clicked on a table row for example
          link = parseURL(element.link, cache[modelid])
        } else if (selected.length) {
          link = parseURL(element.link, cache[selected[0]])
        }
        if (element.linkto) { // User for opening new tabs like view on website etc.
          if (m && !m.display_on_website) { return null } // Listing is not set to display
          switch (element.linkto) {
            case 'site':
              if ((!link || link === 'null') && element.link === ':meta.url.website') {
                if (!this.state.generatedLink) {
                  link = false
                  generateWebsiteLink(m, modelname, getIn(user, [ 'agent', 'site' ])).then(l => {
                    if (l) { this.setState({ generatedLink: l }) }
                  }).catch(e => log.error(e))
                } else {
                  link = valueFormat('url', `${website_url}${this.state.generatedLink}`)
                }
              } else {
                if (getIn(cache[modelid], element.link.substring(1))) { // Cater for branch, agent, team microsites as well as main website
                  link = getIn(cache[modelid], element.link.substring(1)) // Remove the colon from the link key and retrieve the value
                }
                if (link === 'null') {link = null}
                link = link && link.replace('./', '')
                if (link && !link.startsWith('http')) {
                  link = valueFormat('url', `${website_url}${link}`)
                }
              }

              break
            case 'facebook':
            case 'twitter':
            case 'linkedin':
            case 'whatsapp':
            case 'pinterest':
              link = link && link.replace('./', '')
              break
            default:
              break
          }
          if (link) {
            return (
              <Button
                component="a"
                key={`action-submenu-${sid}`}
                className={'action-sub-item'}
                rel="noopener noreferrer"
                target="_blank"
                href={link}
              >{element.label}</Button>
            )
          }
          return null // Return nothing if no link
        }
        return ( // Otherwise return React Router Link
          <Button component={Link}
            key={`action-submenu-${sid}`}
            to={link}
            className={'action-sub-item'}
          >{element.label}</Button>
        )
      } else if (element.action) { // Reports, exports etc.
        const action = actions[element.action]
        if (!modelid && id) {
          modelid = id
        } else if (!modelid && selected.length) {
          modelid = selected[0]
        }
        return (
          <Button component='div'
            type="button"
            key={`action-submenu-${sid}`}
            onClick={e => {
              if (action) {
                action({
                  redirect: e.currentTarget.getAttribute('data-redirect'),
                  modelname,
                  id: modelid,
                  params: model.params,
                  args: element.args,
                  label: element.label,
                  site: user.agent.site.domain,
                  form: form,
                  fields: element.fields
                })
              }
              this.props.handleClose(e)
            }}
            className={'action-sub-item'}
          >{element.label}</Button>
        )
      }
    }
    return null
  }

  render() {
    const { menu } = this.props
    if (!menu) { return null }
    if (!this.props.dropdownHover) { // If this is a click-powered button use a Portal
      return ReactDOM.createPortal(
        <FocusTrap
          onExit={this.props.handleClose}
          className="action-submenu-cover"
          dialog={!this.props.dropdownHover}
          focalStyles={this.props.dropdownStyles}
        >
          <div tabIndex="-1" onKeyDown={e => e.keyCode === 27 && this.props.handleClose(e)} className="action-submenu">
            <div className="action-submenu-inner">
              {menu.title &&
                <strong>{menu.title}</strong>
              }
              {menu.options.map((element, sid) => this.generateMenuLink(element, sid))}
            </div>
          </div>
        </FocusTrap>,
        this.root
      )
    }
    return ( // If this is an inline hover-effect button
      <div tabIndex="-1" onKeyDown={e => e.keyCode === 27 && this.props.handleClose(e)} style={this.props.dropdownStyles} className="action-submenu">
        <div className="action-submenu-inner">
          {menu.title &&
            <strong>{menu.title}</strong>
          }
          {menu.options.map((element, sid) => this.generateMenuLink(element, sid))}
        </div>
      </div>
    )
  }
}

ContextDropdown.propTypes = {
  modelname: PropTypes.string,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  menu: PropTypes.object,
  model: PropTypes.object,
  modelid: PropTypes.number,
  match: PropTypes.object,
  isButtonVisible: PropTypes.func,
  dropdownHover: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  dropdownStyles: PropTypes.object,
  handleClose: PropTypes.func,
  form: PropTypes.object,
  el: PropTypes.element,
  portals: PropTypes.object,
  website_url: PropTypes.string,
  selected: PropTypes.array
}

export default ContextDropdown
