import classNames from 'classnames'
import { FieldArray, Field, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Button } from '../../../ui/Button'
import FieldComponent from '../FieldComponent'
import { valueFormat, useCustomCompareMemo } from '../../../../utils'
import FloatInput from './Float'
import useWatched from './useWatched'


const LandUseBreakdownsInput = props => {
  const [ total_size, setTotalSize ] = useState(0)
  const watched = useWatched(props, { fields: props.fields }, true)


  const { allowdelete, id, form } = props

  const { value, name } = props.field
  const { touched } = props.form

  const { floor_size_measurement_type } = form.values


  useEffect(() => {
    if (Array.isArray(value)) {
      let ts = 0
      value.filter(val => val).forEach(val => {
        if (val.size) {
          ts += parseFloat(val.size)
        }
      })
      if (total_size !== ts) {
        setTotalSize(ts)
        if (!touched.land_use_total_size) {
          props.form.setFieldValue('land_use_total_size', ts)
        }
      }
    }
    return () => {}
  }, [ useCustomCompareMemo(value), useCustomCompareMemo(touched) ])


  const renderArrayFields = idx => {
    const { errors, values } = props.form
    const outer = Array.isArray(watched[idx]) ? watched[idx].filter(field => {
      if (props.show_required) { return field._edit === 'true' && field._required === 'true' }
      if (props.required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    }) : null
    let current_group = []
    let count = 12
    if (outer) {
      const outer_hidden = outer.filter(field => field.input === 'Hidden').map((field, fidx) => (
        <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
      ))
      const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, fidx) => {
        if (field.name.indexOf('.price_per') > -1) {
          field.label = `Price Per ${valueFormat('unit', values.floor_size_measurement_type)}`
        }
        if (props.input === 'TranslatableTextArea') {
          if (field.name.indexOf('language') > -1 && field.input === 'Select') {
            const already_selected = getIn(props.form.values, props.field.name, []).map(v => v.language)
            const options = props.settings.meta.allowed_translations
              .map(lang => ({ value: lang.code, label: lang.name, isDisabled: already_selected.includes(lang.code) }))
            field.options = options
          }
          if (field.name.indexOf('content') > -1 && field.input === 'TextArea') {
            const language = getIn(props.form.values, field.name.replace('.content', '.language'), {})
            if (language) {
              const option = props.settings.meta.allowed_translations
                .find(lang => language === lang.code)
              field.label = field.label.replace('Translated', option.name)
              field.lang = option.code
              if (option.rtl) {
                field.rtl = true
              }
            }
          }
        }
        return (
          <div
            key={`field-${props.field.name}-${idx}-${fidx}`}
            className={classNames('field', { error: (field.name in errors), required: field._required === 'true' }, field.cols ? `col-${field.cols}` : '')}
          >
            <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
          </div>
        )
      })
      const groups = [ ...outer_hidden ]
      outer_visible.forEach((node, fidx) => {
        const classes = node.props && node.props.className ? node.props.className : ''
        const regex = /col(-[a-z]+)?-(\d+)/ig
        const matches = regex.exec(classes)
        current_group.push(node)
        if (matches) {
          const col = parseInt(matches.pop(), 10)
          count -= col
        }
        if (count <= 0 || fidx === outer.length - 1) {
          groups.push(<div key={`col-${idx}-${fidx}`} className='input-group'>{current_group}</div>)
          current_group = []
          count = 12
        }
      })
      return groups
    }
    return null
  }

  return (
    <FieldArray
      name={name}
      render={({ insert, remove }) => (
        <div id={id} className={classNames('form-group', 'size_breakdowns', props.classes)}>
          {value && Array.isArray(value) && value.map((f, idx) => (
            <fieldset className="input-group col-lg-12" key={`fset-${idx}` }>
              {(allowdelete && value.length >= 1) ? (
                <Button icon="#icon16-Bin" type="button" className="btn btn-none btn-icon-16 btn-icon-only" onClick={() => {
                  remove(idx)
                }} />
              ) : null}
              {renderArrayFields(idx)}
            </fieldset>
          )) }
          <div className="arrayaction breakdown-totals">
            <div className="btn btn-none btn-icon-16 btn-icon-only"></div>
            <div className="input-group">
              <div className="col-lg-6"></div>
              <div className="breakdown-total col-lg-6">{valueFormat('measurement_type', total_size, { measurement_type: floor_size_measurement_type })}</div>
            </div>
          </div>
          <div className="arrayaction">
            <div className="btn btn-none btn-icon-16 btn-icon-only"></div>
            <div className="input-group">
              <div className="arrayaction-button col-lg-6">
                <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                  insert(!Array.isArray(value) ? 0 : value.length + 1, {})
                }}>Add a Land Use Breakdown</Button>
              </div>
              <div className="col-lg-6">
                <Field component={FloatInput} label="Total Size" name='land_use_total_size' defaultvalue={total_size} />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

LandUseBreakdownsInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  fields: PropTypes.array.isRequired,
  text_suffix: PropTypes.string,
  input: PropTypes.string,
  cache: PropTypes.object,
  settings: PropTypes.object,
  dragEnded: PropTypes.func,
  required: PropTypes.bool,
  allowdelete: PropTypes.bool,
  sortable: PropTypes.bool,
  show_required: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  multi: PropTypes.bool,
  max: PropTypes.number
}

export default LandUseBreakdownsInput
