import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { valueFormat } from '../../../utils'
import { Button } from '../../ui/Button'
import SingleUser from '../SingleUser'
import Tag from '../Tag'


class NoteSummary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: ''
    }
    this.getText = this.getText.bind(this)
  }

  componentDidMount() {
    this.getText()
  }

  componentDidUpdate() {
    this.getText()
  }

  getText() {
    const { note } = this.props
    let content
    if (note.content) {
      try {
        const parser = new DOMParser()
        const dom = parser.parseFromString(note.content, 'text/html')
        content = dom.documentElement.textContent.replace(/[\n\r\t]/gi, '')
      } catch (e) {
        content = ''
      }
      if (content !== this.state.content) {
        this.setState({ content })
      }
    }
  }

  render() {
    const { nidx, note, agent, user, match } = this.props
    const created = valueFormat('datetime', note.created)
    let dated = false
    if (note.note_date) {
      dated = ''
      if (note.status && note.status === 'Scheduled') {
        dated = 'Due '
      }
      dated += valueFormat('date', note.note_date)
      if (note.start) {
        dated += ` ${note.start.slice(0, -3)}`
        if (note.end) { dated += ` - ${note.end.slice(0, -3)}` }
      }
    }
    return (
      <div className={classNames('notes-summary', { 'private-note': note.private }, { active: nidx === 0 })}>
        <div className="notes-summary-top">
          <div className="notes-summary-creator">
            {note.agent_id !== null && agent &&
              <div className="agents">
                <SingleUser small={true} model={agent} />
                <div className="noteauthor">
                  <NavLink to={`/secure/${agent.site}/agents/${agent.id}`}>
                    {note.user_id === user.id ? 'You' : `${agent.first_name} ${agent.last_name}`}
                  </NavLink>
                </div>
              </div>
            }
            {note.user_id !== null && !note.agent_id &&
              <div className="agents" title="System User">
                <div className='avatar avatar-md'>
                  <div className="thumbimg">
                    <svg viewBox="0 0 24 24">
                      <use href="/images/icons-24.svg#icon24-Cog" />
                    </svg>
                  </div>
                </div>
                <div className="noteauthor">
                  System User
                </div>
              </div>
            }
          </div>
          <div className="notes-summary-date">
            {dated ? dated : created}
          </div>
          <div className="notes-summary-pin">
            <Button
              title={`${note.pinned ? 'Unpin Note' : 'Pin Note'}`}
              className={classNames('btn', 'btn-none', 'btn-icon-only', 'btn-icon-16', { pinned: note.pinned })}
              icon="#icon16-PinAlt"
              type='button'
              onClick={() => this.props.togglePinNote(note)}
            />
          </div>
          {note.user_id === user.id ? (
            <div className="notes-summary-private">
              <Button
                title={`${note.private ? 'Make Note Public' : 'Make Note Private'}`}
                className={classNames('btn', 'btn-none', 'btn-icon-only', 'btn-icon-16', { private: note.private })}
                icon="#icon16-EyeClosed"
                type='button'
                onClick={() => this.props.togglePrivateNote(note)}
              />
            </div>
          ) : null}
        </div>
        <Button component={NavLink} to={`/secure/${match.params.model}/${match.params.id}/notes/${note.id}`}>
          <div className="notes-summary-body">
            <div>{this.state.content ? this.state.content : ''}</div>
          </div>
          <div className="notes-summary-bottom">
            {note.comments?.length ? (
              <div className="notes-summary-comments">
                <div className="icon">
                  <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Chat" /></svg>
                </div>
                {note.comments.length}
              </div>
            ) : null}
            {note.files && note.files.length ? (
              <div className="notes-summary-attachments">
                <div className="icon">
                  <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Paperclip" /></svg>
                </div>
                {note.files.length}
              </div>
            ) : null}
            <div className="notes-summary-tag-name">
              <Tag key={`note-${note.category}`} value={`${note.category}`} background="#fff" />
            </div>
          </div>
        </Button>
      </div>
    )
  }
}

NoteSummary.propTypes = {
  note: PropTypes.object,
  agent: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  togglePinNote: PropTypes.func,
  nidx: PropTypes.number,
  togglePrivateNote: PropTypes.func
}

export default NoteSummary
