import React from 'react'
import PropTypes from 'prop-types'


const HiddenInput = ({
  field: { name, value },
  id
}) => (
  <input
    id={id}
    name={name}
    type="hidden"
    defaultValue={value}
  />
)

HiddenInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string
}

export default HiddenInput
