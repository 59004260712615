import { Formik } from 'formik'
import PropTypes from 'prop-types'
import merge from 'deepmerge'
import React from 'react'
import classNames from 'classnames'
import { logEvent } from '../utils'
import CustomForm from './common/forms/CustomForm'
import { Button } from './ui/Button'
import FieldGroup from './common/forms/FieldGroup'
import { Scrollbar } from './ui/Scrollbars'


const ServicedAttributeBranchTable = props => (
  <div className="btmatches">
    <div className="datatable locationselector">
      <div className="datatable-headers" data-columns="5">
        <div>Name</div>
        <div className="centered">Area</div>
        <div className="centered">Suburb</div>
        <div className="centered">Listing Type</div>
        <div></div>
      </div>
      <div className="datatable-body" data-columns="5">
        {props.children}
      </div>
    </div>
  </div>
)

ServicedAttributeBranchTable.propTypes = {
  children: PropTypes.node
}

const BranchRow = props => {
  const { result, selectBranchRow } = props
  return (
    <div className="datatable-row">
      <div><span className="tablecell">{result.name}</span></div>
      <div className="centered"><span className="tablecell">{result.areas ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.suburbs ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.listing_types ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="right"><Button type="button" className="btn btn-none btn-icon btn-icon-16" icon="#icon16-Plus" onClick={() => selectBranchRow(result)} /></div>
    </div>
  )
}

BranchRow.propTypes = {
  model: PropTypes.object,
  result: PropTypes.object,
  config: PropTypes.object,
  updateModel: PropTypes.func,
  selectBranchRow: PropTypes.func
}

const ServicedAttributeAgentTable = props => (
  <div className="btmatches">
    <div className="datatable locationselector">
      <div className="datatable-headers" data-columns="8">
        <div>Agent</div>
        <div>Branch</div>
        <div className="centered">Area</div>
        <div className="centered">Suburb</div>
        <div className="centered">Listing Type</div>
        <div className="centered">Zoning</div>
        <div className="centered">Property Type</div>
        <div></div>
      </div>
      <div className="datatable-body" data-columns="8">
        {props.children}
      </div>
    </div>
  </div>
)

ServicedAttributeAgentTable.propTypes = {
  children: PropTypes.node
}

const AgentRow = props => {
  const { result, selectAgentRow, siblingform, criteria } = props
  const { recipients } = siblingform.values
  const selected = Array.isArray(recipients) &&
    recipients.find(r => r.recipient_branch === result.branch && r.recipient_agent === result.id)
  return (
    <div className="datatable-row">
      <div><span className="tablecell">{result.first_name} {result.last_name}</span></div>
      <div><span className="tablecell">{result.branch_name}</span></div>
      <div className="centered"><span className="tablecell">{result.areas ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.suburbs ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.listing_types ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.property_zoning ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      <div className="centered"><span className="tablecell">{result.property_types ? <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check" /></svg> : null}</span></div>
      {!selected ? (
        <div className="right"><Button type="button" className="btn btn-none btn-icon btn-icon-16" icon="#icon16-Plus" onClick={() => selectAgentRow(result, false, criteria)} /></div>
      ) : (
        <div className="right"><Button type="button" className="btn btn-none btn-icon btn-icon-16" icon="#icon16-Minus" onClick={() => selectAgentRow(result, true)} /></div>
      )}
    </div>
  )
}

AgentRow.propTypes = {
  criteria: PropTypes.object,
  model: PropTypes.object,
  result: PropTypes.object,
  config: PropTypes.object,
  siblingform: PropTypes.object,
  updateModel: PropTypes.func,
  selectAgentRow: PropTypes.func
}

class ServicedAttributeLookupSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: [],
      results: [],
      initvals: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.selectAgentRow = this.selectAgentRow.bind(this)
    this.selectBranchRow = this.selectBranchRow.bind(this)
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  selectBranchRow(row) {
    logEvent('SELECTED_SERVICED_LOCATION_BRANCH_ROW', row)
    const { siblingform, actions } = this.props
    let { recipients } = siblingform.values
    let agent
    siblingform.setFieldValue('recipient_branch', row.id).then(() => {
      siblingform.setFieldTouched('recipient_branch', false, false)
    })
    if (row.referrals_managers) {
      agent = row.referrals_managers[0]
    } else if (row.branch_managers) {
      agent = row.branch_managers[0]
    } else if (row.leads_manager) {
      agent = row.leads_manager
    }
    const exists = recipients
      && recipients.length
      && recipients.filter(r => !(r.recipient_branch === row.id && r.recipient_agent === agent))
    if (!exists) {
      logEvent('SELECTED_SERVICED_LOCATION_BRANCH_ROW', row)
      if (!recipients) { recipients = [] }
      const recipient = { recipient_branch: row.id, recipient_agent: agent }
      recipients.push(recipient)
      recipients = recipients.filter(v => Object.keys(v).length !== 0)
      setTimeout(() => {
        siblingform.setFieldValue('recipients', recipients).then(() => {
          siblingform.setFieldTouched('recipients', false, false)
        })
      }, 500)
    } else {
      logEvent('DUPLICATED_SERVICED_LOCATION_BRANCH_ROW', row)
    }
    actions.toggleWideSidebar('show-serviced-attribute-sidebar')
  }

  selectAgentRow(row, deselect = false, criteria) {
    const { siblingform } = this.props
    let { recipients } = siblingform.values
    if (deselect) {
      logEvent('DESELECTED_SERVICED_LOCATION_AGENT_ROW', row)
      recipients = recipients.filter(r => !(r.recipient_branch === row.branch && r.recipient_agent === row.id))
      setTimeout(() => {
        siblingform.setFieldValue('recipients', recipients).then(() => {
          siblingform.setFieldTouched('recipients', false, false)
        })
      }, 500)
    } else {
      logEvent('SELECTED_SERVICED_LOCATION_AGENT_ROW', row)
      if (!recipients) { recipients = [] }
      const recipient = { recipient_branch: row.branch, recipient_agent: row.id }
      recipients.push(recipient)
      recipients = recipients.filter(v => Object.keys(v).length !== 0)
      const newvals = { }
      let referral_type = false
      if (criteria.listing_types) {
        if (criteria.listing_types[0] === 'For Sale') {
          referral_type = 'Client wants to buy'
          if (criteria.property_zoning && criteria.property_zoning[0] === 'Residential') {
            newvals.buyer_listing_type = 'Residential For Sale'
          }
          if (criteria.property_zoning && criteria.property_zoning[0] === 'Commercial') {
            newvals.buyer_listing_type = 'Commercial For Sale'
          }
        }
        if (criteria.listing_types[0] === 'To Let') {
          referral_type = 'Client wants to rent'
          if (criteria.property_zoning && criteria.property_zoning[0] === 'Residential') {
            newvals.buyer_listing_type = 'Residential To Let'
          }
          if (criteria.property_zoning && criteria.property_zoning[0] === 'Commercial') {
            newvals.buyer_listing_type = 'Commercial To Let'
          }
        }
      }
      if (criteria.property_types) {
        newvals.buyer_property_types = []
        newvals.buyer_property_types.push(criteria.property_types[0])
      }
      if (criteria.areas) {
        newvals.buyer_areas = criteria.areas
      }
      if (criteria.suburbs) {
        newvals.buyer_suburbs = criteria.suburbs
      }
      setTimeout(() => {
        siblingform.setFieldValue('recipients', recipients).then(() => {
          siblingform.setFieldTouched('recipients', true, false)
          setTimeout(() => {
            if (referral_type) {
              siblingform.setFieldValue('referral_type', referral_type).then(() => {
                siblingform.setFieldTouched('referral_type', true, false)
                Object.keys(newvals).forEach(f => {
                  setTimeout(() => {
                    siblingform.setFieldValue(f, newvals[f]).then(() => {
                      siblingform.setFieldTouched(f, true, false)
                    })
                  }, 1000)
                })
              })
            } else {
              Object.keys(newvals).forEach(f => {
                siblingform.setFieldValue(f, newvals[f]).then(() => {
                  siblingform.setFieldTouched(f, true, false)
                })
              })
            }
          }, 1500)
        })
      }, 500)
    }
  }

  handleSubmit(values, actions) {
    const vals = { ...values } // Make a copy as we are mutating
    vals.modelname = this.props.modelname // Used to return the correct type of result
    new Promise((resolve, reject) => {
      logEvent('SERVICED_LOCATION_LOOKUP', { ...vals })
      this.props.actions.fetchServicedAttributes({ values: vals, resolve, reject }) // Perform the lookup
    }).then(r => {
      actions.setSubmitting(false)
      this.setState({ results: r ? r : [] })
    }).catch(e => {
      console.error(e)
      actions.setSubmitting(false)
    })
  }

  render () {
    return (
      <div id="show-serviced-attribute-sidebar" ref={el => { this.el = el }} className="show-serviced-attribute-sidebar wide-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={{
              ...this.state.initvals
            }}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={el => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>Serviced Attribute Lookup</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                            <FieldGroup
                              key={`fs-${gidx}`}
                              groupname={group}
                              group={this.props.config.fieldgroups[group]}
                              gidx={gidx}
                              classes={this.props.config.fieldgroups[group].classes}
                              fields={this.props.config.fields.filter(field => field.group === group).map(f => {
                                const newfield = merge({}, f)
                                if (newfield.twin) { newfield.readonly = true }
                                if (group === 'Keyword Search') {
                                  newfield.form_el = el
                                  newfield.suffix = <Button icon="#icon24-Search" type="button" onClick={() => formik.submitForm() } className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
                                }
                                return newfield
                              })}
                              creator={this.props.field.name}
                              modelname={this.props.config.modelname}
                              match={this.props.match}
                              required={this.state.required}
                              columns
                              collapsed={this.state.collapsed}
                              render={({ renderFieldGroup, hidden }) => {
                                if (hidden) { return null }
                                return (
                                  <fieldset className={classNames('editgroup', this.props.config.fieldgroups[group].classes)}>
                                    <h5>{group}</h5>
                                    {renderFieldGroup(group)}
                                  </fieldset>
                                )
                              }}
                            />
                          ))}
                          <div className="wide-sidebar-footer">
                            <Button
                              onClick={formik.submitForm} // Required for type="button"
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              disabled={formik.isSubmitting}
                              className="btn btn-primary">Search</Button>
                          </div>

                          {(this.state.results && this.state.results.agents && this.state.results.agents.length) ? (
                            <fieldset className="editgroup">
                              <h5>Agents</h5>
                              <ServicedAttributeAgentTable
                                results={this.state.results !== false ? this.state.results : false}
                              >
                                {this.state.results.agents.map((result, midx) =>
                                  <AgentRow
                                    key={`lead-${midx}`}
                                    criteria={this.form.values}
                                    result={result}
                                    selectAgentRow={this.selectAgentRow}
                                    siblingform={this.props.siblingform}
                                  />
                                )}
                              </ServicedAttributeAgentTable>
                            </fieldset>
                          ) : null }

                          {(this.state.results && this.state.results.branches && this.state.results.branches.length) ? (
                            <fieldset className="editgroup">
                              <h5>Branches</h5>
                              <ServicedAttributeBranchTable
                                results={this.state.results !== false ? this.state.results : false}
                              >
                                {this.state.results.branches.map((result, midx) =>
                                  <BranchRow
                                    key={`lead-${midx}`}
                                    result={result}
                                    selectBranchRow={this.selectBranchRow}
                                  />
                                )}
                              </ServicedAttributeBranchTable>
                            </fieldset>
                          ) : null }

                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

ServicedAttributeLookupSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  toggleLookup: PropTypes.func,
  sidebar: PropTypes.string,
  actions: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  cache: PropTypes.object,
  modelname: PropTypes.string,
  target: PropTypes.object
}

export default ServicedAttributeLookupSidebar
