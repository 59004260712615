import classNames from 'classnames'
import isString from 'lodash/isString'
import { default as _debounce } from 'lodash/debounce'
import React, { forwardRef, cloneElement, useState, useImperativeHandle, useRef, useEffect, useCallback } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import logger from '../../../logging'


export const isPercent = value => isString(value) && value.indexOf('%') === value.length - 1

export const ResponsiveContainer = forwardRef((
  // eslint-disable-next-line react/prop-types
  { aspect, width = '100%', height = '100%', minWidth, minHeight, maxHeight, children, debounce = 0, id, className, legendWrap },
  ref
) => {
  const [ sizes, setSizes ] = useState({
    containerWidth: -1,
    containerHeight: -1
  })
  const containerRef = useRef(null)
  useImperativeHandle(ref, () => containerRef, [ containerRef ])

  const getContainerSize = useCallback(() => {
    if (!containerRef.current) {
      return null
    }

    return {
      containerWidth: containerRef.current.clientWidth,
      containerHeight: containerRef.current.clientHeight
    }
  }, [ containerRef.current ])

  const updateDimensionsImmediate = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    const newSize = getContainerSize()

    if (newSize) {
      const { containerWidth: oldWidth, containerHeight: oldHeight } = sizes
      const { containerWidth, containerHeight } = newSize

      if (containerWidth !== oldWidth || containerHeight !== oldHeight) {
        setSizes({ containerWidth, containerHeight })
      }
    }
  }, [ containerRef.current ])

  const handleResize = debounce > 0 ? _debounce(updateDimensionsImmediate, debounce) : updateDimensionsImmediate

  const renderChart = useCallback(() => {
    const { containerWidth, containerHeight } = sizes
    if (containerWidth < 0 || containerHeight < 0) {
      return null
    }

    if (!(!aspect || aspect > 0)) {
      logger.error(`The aspect(${aspect}) must be greater than zero.`)
    }

    let calculatedWidth = isPercent(width) ? containerWidth : width
    let calculatedHeight = isPercent(height) ? containerHeight : height

    if (aspect && aspect > 0) {
      // Preserve the desired aspect ratio
      if (calculatedWidth) {
        // Will default to using width for aspect ratio
        calculatedHeight = calculatedWidth / aspect
      } else if (calculatedHeight) {
        // But we should also take height into consideration
        calculatedWidth = calculatedHeight * aspect
      }

      // if maxHeight is set, overwrite if calculatedHeight is greater than maxHeight
      if (maxHeight && calculatedHeight > maxHeight) {
        calculatedHeight = maxHeight
      }
    }

    if (!(calculatedWidth > 0 || calculatedHeight > 0)) {
      logger.error(
        `The width(${calculatedWidth}) and height(${calculatedHeight}) of chart should be greater than 0,
         please check the style of container, or the props width(${width}) and height(${height}),
         or add a minWidth(${minWidth}) or minHeight(${minHeight}) or use aspect(${aspect}) to control the
         height and width.`
      )
    }

    let new_height = 0
    if (containerRef.current) {
      const legend = containerRef.current.querySelector('.recharts-legend-wrapper')
      const wrapper = containerRef.current.querySelector('.recharts-wrapper')
      if (legend && legend.style.bottom === '0px') {
        new_height = legend.scrollHeight
        wrapper.style.paddingBottom = '0px'
        wrapper.style.borderBottom = `${new_height}px solid transparent`
        wrapper.style.boxSizing = 'content-box'
      }
      if (legend && legendWrap) {
        calculatedHeight += legend.getBoundingClientRect().height + 10
      }
    }
    // eslint-disable-next-line react/prop-types
    if (!children.props) {
      const element = cloneElement(children({
        width: calculatedWidth,
        height: calculatedHeight
      }), {
        width: calculatedWidth,
        height: calculatedHeight
      })
      return element
    }
    const element = cloneElement(children, {
      width: calculatedWidth,
      height: calculatedHeight
    })
    return element
  }, [ sizes, containerRef.current, children ])

  useEffect(() => {
    const timer = setTimeout(() => {
      const size = getContainerSize()

      if (size) {
        setSizes(size)
      }
    })
    return () => {
      clearTimeout(timer)
    }
  }, [ containerRef.current ])


  const style = { width, minWidth, minHeight, maxHeight }
  return (
    <ReactResizeDetector handleWidth handleHeight onResize={handleResize} targetRef={containerRef}>
      <div
        {...(id !== null ? { id: `${id}` } : {})}
        className={classNames('recharts-responsive-container', className)}
        style={style}
        ref={containerRef}
      >
        {renderChart()}
      </div>
    </ReactResizeDetector>
  )
})

ResponsiveContainer.displayName = 'ResponsiveContainer'
