/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
  defaultDropAnimation
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import isEqual from 'react-fast-compare'
import { uniqueArray, updateSearchParms, logEvent, buildOptionLabel } from '../utils'
import InlineSelect from './common/forms/inputs/InlineSelect'
import Card from './common/Card'
import ModelActions from './common/ModelActions'
import SortableTree from './ui/tree/SortableTree'
import SortableTreeItem from './ui/tree/SortableTreeItem'
import { Button } from './ui/Button'
import ChallengeModal from './common/modals/ChallengeModal'
import { buildTree, getProjection, flattenTree, sortableTreeKeyboardCoordinates, getChildCount } from './ui/tree/utils'


const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
}


const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([ { opacity: 0 }, { opacity: 1 } ], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    })
  }
}


const adjustTranslate = ({ transform }) => ({
  ...transform,
  y: transform.y - 25
})

const DragComponent = ({ items, allItems, setItems, onRemove }) => {
  const [ activeId, setActiveId ] = useState(null)
  const [ activeItem, setActiveItem ] = useState(null)
  const [ activeContainer, setActiveContainer ] = useState(null)
  const [ overId, setOverId ] = useState(null)
  const [ offsetLeft, setOffsetLeft ] = useState(0)
  const indentationWidth = 50
  const indicator = false
  const flattenedItems = [ ...allItems ]
  const projected = activeId && overId ? getProjection(
    flattenedItems,
    activeId,
    overId,
    offsetLeft,
    indentationWidth
  ) : null
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  })

  const [ coordinateGetter ] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter
    })
  )


  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    }
  }, [ flattenedItems, offsetLeft ])

  function handleDragStart({ active }) {
    const { id: draggedId } = active
    if (!isNaN(draggedId)) {
      setActiveId(draggedId)
      setOverId(draggedId)
      setActiveItem(flattenedItems.find(item => item.id === draggedId))
    }
    setActiveContainer(getIn(active, 'data.current.sortable.containerId', getIn(active, 'id')))

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)

    document.body.style.setProperty('cursor', '')
  }

  function handleDragMove({ delta, ...props }) {
    const { over } = props
    const overContainer = getIn(over, 'data.current.sortable.containerId', getIn(over, 'id'))
    if (activeContainer !== overContainer && overContainer === 'active') {
      setOffsetLeft(delta.x - props.over.rect.width)
    } else {
      setOffsetLeft(delta.x)
    }
  }

  function handleDragEnd({ active, over }) {
    resetState()

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems = merge([], flattenedItems)

      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeTreeItem = clonedItems[activeIndex]
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parent: parentId }
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
      const overContainer = getIn(over, 'data.current.sortable.containerId', getIn(over, 'id'))
      if (activeContainer !== overContainer) {
        setItems({
          items: clonedItems
        })
      } else {
        setItems({
          items: sortedItems
        })
      }
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function handleDragOver(event) {
    const { active, over } = event
    setOverId(over?.id ?? null)
    const { rect: { current: { initial: draggingRect } } } = active
    const activeList = getIn(active, 'data.current.sortable.containerId', getIn(active, 'id'))
    const overContainer = getIn(over, 'data.current.sortable.containerId', getIn(over, 'id'))

    // Container hasn't changed, just sort
    if (
      !activeList ||
      !overContainer ||
      activeList === overContainer
    ) {
      return
    }

    // Container has changed
    const newItems = allItems.map(item => {
      const new_item = { ...item }
      if (item.id === active.id || item.parent === active.id) {
        new_item.active = overContainer === 'active'
        if (item.active && !new_item.active) {
          new_item.depth = 0
          new_item.parent = null
          new_item.parentId = null
        }
      }
      return new_item
    })
    // Find the indexes for the items

    if (activeItem && projected?.parentId) {
      activeItem.parent = projected.parentId
    }

    // Find the indexes for the items
    const activeIndex = newItems.map(item => item.id).indexOf(active.id)
    const overIndex = newItems.map(item => item.id).indexOf(over.id)

    let newIndex
    if (overId in newItems) {
      // We're at the root droppable of a container
      newIndex = newItems.length + 1
    } else {
      const isBelowLastItem =
        over &&
        overIndex === newItems.length - 1 &&
        draggingRect.offsetTop > over.rect.offsetTop + over.rect.height

      const modifier = isBelowLastItem ? 1 : 0

      // eslint-disable-next-line no-unused-vars
      newIndex = overIndex >= 0 ? overIndex + modifier : newItems.length + 1
    }
    const newSortedItems = arrayMove(newItems, activeIndex, overIndex)
    setItems({
      items: newSortedItems
    })
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <DndContext
        sensors={sensors}
        measuring={measuring}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div className="tree-wrapper col-lg-6">
          <h3>Inactive</h3>
          <SortableTree
            items={items.inactive}
            id="inactive"
            activeId={activeId}
            setActiveId={setActiveId}
            projected={projected}
            indicator
            collapsible
            removable
            onRemove={onRemove}
          />
        </div>
        <div className="tree-wrapper col-lg-6">
          <h3>Active</h3>
          <SortableTree
            items={items.active}
            id="active"
            activeId={activeId}
            setActiveId={setActiveId}
            projected={projected}
            indicator
            collapsible
            removable
            onRemove={onRemove}
          />
        </div>
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [ adjustTranslate ] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                item={activeItem}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(flattenedItems, activeId) + 1}
                value={activeId.toString()}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  )
}

class NavigationManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      items: [],
      active: [],
      inactive: [],
      offset: 0,
      domainname: '',
      domain: {},
      domains: [],
      domainfield: null,
      isHoveringActive: false,
      isHoveringInactive: false,
      isDragging: false
    }
    this.setItems = this.setItems.bind(this)
    this.findDomain = this.findDomain.bind(this)
  }

  componentDidMount() {
    this.props.actions.selectNone()
    window.scrollTo(0, 0)
    window.setTimeout(() => {
      this.props.actions.checkVersion()
    }, 3000)
    if (!isEqual([], this.props.model.index) && this.props.model.index) {
      const items = uniqueArray(flattenTree(buildTree(this.props.model.index.map(id => this.props.cache.navigation[id]))), 'id')
      const active = buildTree(items.filter(item => item.active))
      const inactive = buildTree(items.filter(item => !item.active))
      this.setState({ active: [ ...active ], inactive: [ ...inactive ], items })
    }
    this.root = document.getElementById('wrapper')
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + 66
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    this.initDomains()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.model.index, this.props.model.index)
      || !isEqual(prevProps.cache.navigation, this.props.cache.navigation)
      && this.props.model.index
    ) {
      const items = uniqueArray(flattenTree(buildTree(this.props.model.index.map(id => this.props.cache.navigation[id]))), 'id')
      const active = buildTree(items.filter(item => item.active))
      const inactive = buildTree(items.filter(item => !item.active))
      this.setState({ active: [ ...active ], inactive: [ ...inactive ], items })
    }
    if (!isEqual(prevState.items, this.state.items)) {
      const active = buildTree(this.state.items.filter(item => item.active))
      const inactive = buildTree(this.state.items.filter(item => !item.active))
      this.setState({ active: [ ...active ], inactive: [ ...inactive ] })
    }
    if (!isEqual(prevProps.params, this.props.params) || !isEqual(this.state.domains, prevState.domains)) { // Params have changed
      const domainfield = this.findDomain()
      if (domainfield) {
        const fieldname = `${domainfield.name}__in`
        if (this.props.params[fieldname] !== getIn(this.state, 'domain.value')) { // Check for status variance ie. on page nav backwards
          const primary = this.state.results.find(o => o.primary)
          const selectedDomain = getIn(this.props.params, fieldname, primary[domainfield.optionvalue]).toString()
          const domain = this.state.domains.find(o => (
            o.value.toString() === selectedDomain
          ))
          if (domain && isEqual(this.state.domain, domain)) {
            this.setState({ domain, domainfield }, () => {
              updateSearchParms(fieldname, domain.value)
            })
          }
        }
      }
    }
  }

  setItems(items) {
    this.setState({ ...items })
  }

  findDomain() {
    return this.props.config.domainField
  }

  initDomains() { // Below sets the initial status options for the model
    new Promise((resolve, reject) => (this.props.actions.fetchMany({
      values: {
        modelname: 'domains',
        get_all: 1
      },
      resolve,
      reject
    }))).then(r => {
      const field = this.findDomain()
      if (!field) {
        return
      }
      let domains = r.options.map(o => buildOptionLabel(field, o))
      const fieldname = `${field.name}__in`
      const form = {}
      form[fieldname] = field.defaultValue
      if (field && field.options) { domains = [ ...domains, ...field.options.filter(o => this.isConditional(o, 'show', true, form)) ] } // Include All
      const primary = r.options.find(o => o.primary)
      const selectedDomain = getIn(this.props.params, fieldname, primary[field.optionvalue]).toString()

      const domain = domains.find(o => (
        o.value.toString() === selectedDomain
      ))
      this.setState({
        domainname: fieldname,
        domain: domain,
        results: r.options,
        domains: domains,
        domainfield: field
      })
    })
  }

  changeDomain(e) { // Change the status filter on the page model
    if ([ null, undefined ].includes(e.value)) {
      return
    }
    this.setState({ domain: e })
    const field = this.findDomain()
    updateSearchParms(`${field.name}__in`, e.value)
    logEvent('SELECT_DOMAIN', { modelname: this.props.config.modelname, domain: e })
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) } // Fired on submit via the ContextMenu

  render() {
    const { config, model, modelname, ui, user, actions } = this.props
    const { items, active, inactive, modelid, challenge } = this.state
    const inner = []
    inner.push(
      <div key="nav-manager" className={this.props.className} id={this.props.id}>
        <div className="viewhead">
          <ModelActions
            actions={{ ...actions, saveList: () => {
              const updates = this.state.items.map((item, idx) => ({
                id: item.id,
                order: idx,
                active: item.active,
                parent: item.parent,
                domain: item.domain
              }))
              // eslint-disable-next-line no-new
              new Promise((resolve, reject) => {
                this.props.actions.bulkEditModel({
                  values: {
                    modelname: 'navigation',
                    payload_key: 'updates',
                    updates
                  },
                  resolve,
                  reject
                })
              }).then(() => {
                this.props.actions.refreshList()
              })
            } }}
            model={model}
            ui={ui}
            redirectSchema={this.redirectSchema}
            modelname={modelname}
          />
        </div>
        <div className="view container-fluid">
          <div className="viewcontent">
            <div className="navigation-manager">
              {this.state.domainfield ? (
                <Card
                  background={true}
                  body={
                    <div className="tablemeta">
                      <InlineSelect
                        id={`field-${this.state.domainname}`}
                        name={this.state.domainname}
                        prefix={<div className="input-group-addon">Domain</div>}
                        className="inline-select meta-status"
                        classNamePrefix="inline"
                        autosize={true}
                        search={this.props.params}
                        onChange={e => {
                          this.changeDomain(e)
                        }}
                        optionlabel={this.state.domainfield.optionlabel}
                        noclear
                        form={{
                          setFieldError: async () => {},
                          setFieldTouched: async () => {},
                          setFieldValue: async () => {}
                        }}
                        field={{
                          value: this.state.domain[this.state.domainfield.optionvalue]
                        }}
                        selectedValue={this.state.domain.value}
                        options={this.state.domains}
                      />
                    </div>
                  }
                />
              ) : null}
              {(!config || !model) || !user.preferences[modelname] ? null : (
                <Card
                  background
                  header={null}
                  body={
                    <DragComponent
                      items={{ active, inactive }}
                      onRemove={id => {
                        this.setState({ challenge: true, modelid: id })
                      }}
                      allItems={items}
                      setItems={this.setItems}
                    />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )

    if (this.root && challenge && modelid) {
      inner.push(
        createPortal(
          <Formik
            initialValues={{
              record_ids: [ modelid ]
            }}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
          >{ formik => (
              <ChallengeModal
                isLoading={ui?.isLoading}
                action={'deleteModels'}
                challenges={[
                  {
                    text: (
                      <span>
                        You&apos;re about to delete
                        <span> {formik.values.record_ids.length.toString()} </span> {config.plural}.
                        Please confirm your action by typing the number of {config.plural} you are about
                        to edit in the field below.
                      </span>
                    ),
                    value: () => formik.values.record_ids.length.toString(),
                    action: 'deleteModels'
                  },
                  {
                    text: (
                      <span>
                        You&apos;re about to delete
                        <span> {formik.values.record_ids.length.toString()} </span> {config.plural}.
                        Please confirm your action by typing <code>DELETE</code> in the field below.
                      </span>
                    ),
                    value: () => 'DELETE',
                    action: 'deleteModels'
                  }
                ]}
                visible={challenge}
                buttons={(({ valid, value }) => (
                  <div className="modal-buttons">
                    <Button className={classNames('btn', 'btn-primary', { disabled: !valid })} type="submit" onClick={valid ? () => {
                      formik.setFieldTouched('challenge', true, false)
                      if (valid) {
                        new Promise((resolve, reject) => (this.props.actions.deleteModel({
                          resolve,
                          reject,
                          values: {
                            modelname,
                            selected: formik.values.record_ids
                          }
                        }))).then(() => {
                          this.setState({ challenge: false })
                          this.props.actions.refreshList()
                          if (modelid) {
                            // if on details view, go back to list view
                            const list = document.querySelector('.navitem.list')
                            if (list) {
                              list.click()
                            }
                          }
                        }).catch(() => {
                          this.setState({ challenge: false })
                        })
                      } else {
                        formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                      }
                    } : null}>Confirm</Button>
                    <Button className="btn btn-white" type="submit" onClick={() => {
                      this.setState({ challenge: false }, () => { formik.setFieldError('challenge', null) })
                    }}>Cancel</Button>
                  </div>
                ))}
              />
            )}
          </Formik>, this.root)
      )
    }
    return inner
  }
}

NavigationManager.propTypes = {
  advanced: PropTypes.bool,
  cache: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  selected: PropTypes.array,
  tableconfig: PropTypes.object,
  config: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  log: PropTypes.string,
  configs: PropTypes.object,
  toggleAdvanced: PropTypes.func,
  findStatus: PropTypes.func,
  handleReset: PropTypes.func,
  resetPage: PropTypes.func,
  actions: PropTypes.object,
  meta_cache: PropTypes.object,
  match: PropTypes.object,
  modelname: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
}

export default NavigationManager
