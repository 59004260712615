import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { components } from 'react-select'
import isEqual from 'react-fast-compare'
import { useAsyncPaginate, useComponents, wrapMenuList } from 'react-select-async-paginate'


import { getDisplayName, menuPlacement, calculatePlacement, uniqueArray } from '../../../../utils'

/* ResponsiveSelect HOC which provides a
 * stepping mechanism for the relevant model. The step
 * data is contained in the local component state but
 * is populated from the redux store's selected items
*/

const styleProxy = new Proxy({}, {
  get: () => () => {}
})

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-ChevronDown" /></svg>
  </components.DropdownIndicator>
)

const CrossIcon = props => (
  <components.CrossIcon {...props}>
    <svg {...props} viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-X-Small" /></svg>
  </components.CrossIcon>
)

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-X-Small" /></svg>
  </components.ClearIndicator>
)

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-X-Small" /></svg>
  </components.MultiValueRemove>
)

const CustomMenuList = ({ children, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus } = props.selectProps

  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': props.selectProps['aria-label'],
    'aria-labelledby': props.selectProps['aria-labelledby']
  }

  const fixedChildren = React.Children.map(children, c => {
    if (c.props.innerProps) {
      delete c.props.innerProps.onMouseMove // FIX LAG!!
      delete c.props.innerProps.onMouseOver // FIX LAG!!
    }
    return c
  })

  return (
    <div>
      {document.getElementById('wrapper') && document.getElementById('wrapper').classList.contains('touch') ? (
        <input
          style={{
            width: '100%',
            boxSizing: 'border-box',
            padding: 10,
            border: 'none',
            borderBottom: '1px solid lightgrey'
          }}
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={inputValue}
          onChange={e =>
            onInputChange(e.currentTarget.value, {
              action: 'input-change'
            })
          }
          onMouseDown={e => {
            e.stopPropagation()
            e.target.focus({ preventScroll: true })
          }}
          onTouchEnd={e => {
            e.stopPropagation()
            e.target.focus({ preventScroll: true })
          }}
          onFocus={onMenuInputFocus}
          placeholder="Search..."
          {...ariaAttributes}
        />
      ) : null}
      <components.MenuList
        {...props}
      >
        {fixedChildren}
      </components.MenuList>
    </div>
  )
}

CustomMenuList.propTypes = {
  innerRef: PropTypes.func,
  selectProps: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const scrollIntoView = el => {
  setTimeout(() => {
    if (el && el.scrollIntoView) {
      el.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }, 15)
}

const AsyncCustomMenuList = wrapMenuList(CustomMenuList)

export function withResponsiveSelect(SelectComponent) {
  function WithResponsiveSelect(props) {
    const {
      components: respoComponents,
      isLoading: isLoadingProp,
      cacheUniqs,
      // eslint-disable-next-line no-unused-vars
      form,
      options,
      onMenuClose,
      ...rest
    } = props

    const processedComponents = useComponents({
      DropdownIndicator,
      CrossIcon,
      ClearIndicator,
      MultiValueRemove,
      MenuList: AsyncCustomMenuList,
      ...respoComponents
    })

    const [ menuIsOpen, setMenuIsOpen ] = useState(props.menuIsOpen || false)
    const [ inputValue, setInputValue ] = useState('')
    const [ menuPosition, setMenuPosition ] = useState('auto')
    const [ el, setEl ] = useState(null)
    const [ menu, setMenu ] = useState(el ? el.querySelector('.react-select__menu') : null)
    let timer
    const onDomClick = (e, element, menuEl) => {
      if (!element) { return e }
      const touch_wrapper = document && document.getElementById('wrapper') && document.getElementById('wrapper').classList.contains('touch')
      if (
        !touch_wrapper ||
        !element.contains(e.target) ||
        !menuEl ||
        !menuEl.contains(e.target)
      ) {
        timer = setTimeout(() => {
          if (inputValue) {
            setInputValue('')
          }
        })
      }
      return e
    }

    function handleInputChange(val, e) {
      if (e.action === 'input-change') {
        setInputValue(val)
      }
      if (e.action === 'set-value') {
        setTimeout(() => {
          if (rest.closeMenuOnSelect === false && el && el.querySelector('.react-select__input')) {
            el.querySelector('.react-select__input').select()
          }
        })
      }
    }

    useEffect(() => {
      // subscribe event
      document.addEventListener('mousedown', e => onDomClick(e, el, menu))

      return () => {
        // unsubscribe event
        clearTimeout(timer)
        document.removeEventListener('mousedown', e => onDomClick(e, el, menu))
      }
    }, [ props.field.name, el, menu ])

    useEffect(() => clearTimeout(timer), [])

    const asyncPaginateProps = useAsyncPaginate(
      { ...rest, inputValue, menuIsOpen },
      cacheUniqs
    )

    const allOptions = options ? uniqueArray([ ...options, ...asyncPaginateProps.options ], 'value') : asyncPaginateProps.options

    return (
      <SelectComponent
        {...rest}
        {...asyncPaginateProps}
        options={allOptions}
        styles={styleProxy}
        onInputChange={handleInputChange}
        components={processedComponents}
        onChange={e => {
          const { closeMenuOnSelect } = props
          if (closeMenuOnSelect !== false) {
            setInputValue('')
          }
          props.onChange(e)
        }}
        menuShouldScrollIntoView={false}
        onMenuClose={() => {
          if (!el) { return }
          setMenuIsOpen(false)
          setInputValue('')
          if (onMenuClose) {
            onMenuClose()
          }
        }}
        onMenuOpen={() => {
          if (!el) { return }
          setMenuIsOpen(true)
          setMenuPosition(calculatePlacement(el))
          scrollIntoView(el)
        }}
        onMenuInputFocus={() => setMenuIsOpen(true) }
        menuPlacement={menuPosition}
        menuIsOpen={menuIsOpen}
        inputValue={inputValue}
        isLoading={typeof isLoadingProp === 'boolean' ? isLoadingProp : asyncPaginateProps.isLoading}
        isSearchable={document.getElementById('wrapper') ? !document.getElementById('wrapper').classList.contains('touch') : true}
        ref={rel => {
          const ref = getIn(rel, 'controlRef', getIn(rel, 'select.controlRef', getIn(rel, 'select.select.controlRef')))
          const new_menu = getIn(rel, 'menuListRef', getIn(rel, 'select.menuListRef', getIn(rel, 'select.select.menuListRef')))
          if ((new_menu || menu) && new_menu !== menu) {
            setMenu(getIn(rel, 'menuListRef', getIn(rel, 'select.menuListRef', getIn(rel, 'select.select.menuListRef'))))
          }
          if (!el && ref && el !== ref) {
            setEl(ref)
          }
        }}
      />
    )
  }

  WithResponsiveSelect.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    options: PropTypes.array,
    components: PropTypes.object,
    useComponents: PropTypes.func,
    useAsyncPaginate: PropTypes.func,
    cacheUniqs: PropTypes.array,
    defaultOptions: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    onMenuClose: PropTypes.func,
    menuIsOpen: PropTypes.bool,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    selectRef: PropTypes.object
  }

  WithResponsiveSelect.defaultProps = {
    components: {},
    useComponents,
    useAsyncPaginate
  }
  WithResponsiveSelect.displayName = `withResponsiveSelect(${getDisplayName(SelectComponent)})`

  return WithResponsiveSelect
}

export function responsiveSelect(Select) {
  return class extends React.Component {
    static displayName = `ResponsiveSelect(${getDisplayName(Select)})`

    static propTypes = {
      menuIsOpen: PropTypes.bool,
      closeMenuOnSelect: PropTypes.bool,
      form: PropTypes.object,
      field: PropTypes.object,
      name: PropTypes.string,
      value: PropTypes.any,
      components: PropTypes.object
    }

    constructor(props) {
      super(props)
      this.state = {
        ...this.state,
        menuIsOpen: props.menuIsOpen || false,
        inputValue: ''
      }
      this.onDomClick = this.onDomClick.bind(this)
      this.handleInputChange = this.handleInputChange.bind(this)
      this.onCustomMenuOpen = this.onCustomMenuOpen.bind(this)
      this.onCustomMenuClose = this.onCustomMenuClose.bind(this)
      this.menuPlacement = menuPlacement.bind(this)
      this.calculatePlacement = calculatePlacement.bind(this)
      this.placement = 'auto'
      this._is_mounted = true
      this.changeTimeout = false
      this.touchedTimeout = false
      this.clickedTimeout = false
      this.openedTimeout = false
    }

    componentDidMount() {
      if (document.getElementById('wrapper')) {
        document.addEventListener('mousedown', this.onDomClick)
        document.addEventListener('mousedown', this.menuPlacement)
      }
      return super.componentDidMount && super.componentDidMount() // Check that componentDidMount esists before trying to call it
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.onDomClick)
      document.removeEventListener('mousedown', this.menuPlacement)
      this._is_mounted = false
      if (this.changeTimeout) { clearTimeout(this.changeTimeout) }
      if (this.touchedTimeout) { clearTimeout(this.touchedTimeout) }
      if (this.clickedTimeout) { clearTimeout(this.clickedTimeout) }
      if (this.openedTimeout) { clearTimeout(this.openedTimeout) }
      return super.componentWillUnmount && super.componentWillUnmount() // Check that componentDidMount esists before trying to call it
    }

    handleInputChange(inputValue, e) {
      if (e.action === 'input-change' && this._is_mounted) {
        this.setState({ inputValue })
      }
      if (e.action === 'set-value') {
        this.changeTimeout = setTimeout(() => {
          if (this.props.closeMenuOnSelect === false && this.el && this.el.querySelector('.react-select__input')) {
            this.el.querySelector('.react-select__input').select()
          }
        })
      }
    }

    onCustomMenuOpen() {
      if (!this.el || !this._is_mounted) { return }
      this.setState({ menuIsOpen: true })
      this.menuPlacement(this.calculatePlacement(this.el))
      this.openedTimeout = setTimeout(() => {
        scrollIntoView(this.el)
      }, 15)
    }

    onCustomMenuClose() {
      if (!this.el || !this._is_mounted) { return }
      this.setState({ menuIsOpen: false, inputValue: '' })
    }

    onDomClick(e) {
      if (!this.el && !this.state.menuIsOpen) { return e }
      const { closeMenuOnSelect } = this.props
      const touch_wrapper = document && document.getElementById('wrapper') && document.getElementById('wrapper').classList.contains('touch')
      if (
        !touch_wrapper ||
        !this.el.contains(e.target) ||
        !this.menu ||
        !this.menu.contains(e.target)
      ) {
        this.clickedTimeout = setTimeout(() => {
          let menuIsOpen
          if (closeMenuOnSelect === false && (this.menu && this.menu.contains(e.target))) { // Selected and shouldn't close
            menuIsOpen = true
          } else if (closeMenuOnSelect === false && (this.menu && (this.el && !this.el.contains(e.target)))) { // Clicked outside and should close
            menuIsOpen = false
          } else {
            menuIsOpen = this.state.menuIsOpen
          }
          if (this._is_mounted && !isEqual({ menuIsOpen: this.state.menuIsOpen, inputValue: this.state.inputValue }, { menuIsOpen, inputValue: '' })) {
            this.setState({ menuIsOpen, inputValue: '' })
          }
        })
      }
      return e
    }

    render() {
      const { form, field, ...rest } = this.props
      return <Select
        onBlur={() => {
          if (form) {
            form.setFieldError(field.name, undefined)
            this.touchedTimeout = setTimeout(() => { form.setFieldTouched(field.name) })
          }
        } } // This is used for error message
        {...rest}
        key={`react-select-${this.props.name}-${this.props.value}`}
        styles={styleProxy}
        ref={el => {
          this.el = getIn(el, 'controlRef', getIn(el, 'select.controlRef', getIn(el, 'select.select.controlRef')))
          this.menu = (getIn(el, 'menuListRef', getIn(el, 'select.menuListRef', getIn(el, 'select.select.menuListRef'))))
        }}
        menuShouldScrollIntoView={false}
        onMenuClose={this.onCustomMenuClose}
        onMenuOpen={this.onCustomMenuOpen}
        onMenuInputFocus={() => { this.setState({ menuIsOpen: true })}}
        onInputChange={this.handleInputChange}
        inputValue={this.state.inputValue}
        isSearchable={document.getElementById('wrapper') ? !document.getElementById('wrapper').classList.contains('touch') : true}
        components={{
          DropdownIndicator,
          CrossIcon,
          ClearIndicator,
          MultiValueRemove,
          MenuList: CustomMenuList,
          ...this.props.components
        }}
        menuPlacement={this.placement}
        menuIsOpen={this.state.menuIsOpen}
      />
    }
  }
}

export default responsiveSelect
