import React from 'react'
import ReactDOM from 'react-dom/client'
import config from 'dotenv'

import App from './containers/App'


config.config()

export default ReactDOM.createRoot(document.getElementById('root') || document.createElement('div')).render(<App />)
