import React from 'react'
import PropTypes from 'prop-types'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import { getIn } from 'formik'
import { parseURL } from '../../utils'
import DetailsSection from './DetailsSection'
import Card from './Card'
import MetaDetail from './MetaDetail'


class BranchPartners extends React.Component {
  constructor(props) {
    super(props)
    const { config } = props
    const all_fields = getIn(config, 'fields')
    const fields = all_fields.find(f => f.name === 'partners').fields
    this.state = {
      fields
    }
  }

  render() {
    const { model } = this.props
    const partners = getIn(model, 'meta.partners', [])
    return (
      <Card
        header={
          <h3>Partners</h3>
        }
        collapsed={false}
        background
        bodyclass={`partners ${partners.length ? 'no-padding' : ''}`}
        body={() => (
          <>
            {partners.length ? partners.map((partner, pid) => (
              <DetailsSection heading={`Partner ${pid + 1}`} key={`partner-${pid}`} className="partner">
                <div className="meta-group">
                  {this.state.fields.map((field, fidx) => {
                    const value = getIn(partner, `meta.${field.name}`, getIn(partner, field.name))
                    if ([ 'logo', 'desktop', 'mobile' ].includes(field.name) && value) {
                      value.width = '0'
                      value.height = '0'
                    }
                    let url
                    if (field.link) {
                      url = parseURL(field.link, partner)
                    }
                    return (
                      <MetaDetail
                        key={`partner-f${fidx}`}
                        className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-6'}`}
                        label={field.label}
                        value={value}
                        format={field.format}
                        field={field}
                        url={url}
                      />
                    )
                  })}
                </div>
              </DetailsSection>
            )) : (
              <div className="mediatype">
                <div>
                There are no partners for this branch.&nbsp;
                  <NavLink
                    className="has-link"
                    to={`/secure/${model.site}/branches/${model.id}/edit#partners`}
                    scroll={el => setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'center' }), 3000) }
                  >Add some now.
                  </NavLink>
                </div>
              </div>
            )
            }
          </>
        )}
      />
    )
  }
}

BranchPartners.propTypes = {
  model: PropTypes.object,
  config: PropTypes.object
}

export default BranchPartners
