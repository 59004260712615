/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'

import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'


const Newsletter = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    match,
    routeConfig
  } = props

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>

    </HorizontalTabs>
  )
}

Newsletter.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Newsletter
