import * as yup from 'yup'
import messages from './messages.json'


export const valuations = yup.object({
  template: yup.string().required(messages.required),
  branch: yup.number().strict().typeError('Please select a branch').typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  agent: yup.number().strict().typeError('Please select an agent').typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  owner: yup.number().strict().typeError('Please select a contact').typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  lightstone_id: yup.number(messages.number).integer().positive(messages.positive).typeError('Please enter a valid lightstone ID').nullable(),
  location: yup.number().typeError('Please select a location').typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  model: yup.string().oneOf([ 'residential', 'commercial' ], messages.invalid).typeError(messages.invalid).required(messages.required).nullable()
})
