import React from 'react'
import PropTypes from 'prop-types'
import { Field, Formik, getIn } from 'formik'

import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'
import { Scrollbar } from '../../ui/Scrollbars'
import { Button } from '../../ui/Button'
import CustomForm from '../forms/CustomForm'
import TextInput from '../forms/inputs/Text'
import InlineSelect from '../forms/inputs/InlineSelect'
import DateInput from '../forms/inputs/Date'
import { uniqueArray, sortBy, breakpoint } from '../../../utils'
import { history } from '../../../store'
import LeadCreatorSidebar from '../../../containers/LeadCreatorSidebar'
import WideSidebar from '../../ui/sidebar/WideSidebar'
import Loader from '../Loader'
import LeadSummary from './LeadSummary'


class LeadIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      leads: [],
      offset: 0,
      advanced: false,
      loading: false,
      filtered: false,
      searching: false,
      showActions: breakpoint.matches
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
  }

  componentDidMount() {
    this.form.handleSubmit()
    breakpoint.addEventListener('change', this.toggleActions)
    this._is_mounted = true
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props
    if (prevState.offset !== this.state.offset || prevState.filtered !== this.state.filtered) {
      if (this.form) { this.form.submitForm() }
    }

    const { record_id } = match.params
    if (!this.state.init || !isEqual(prevState.leads, this.state.leads)) {
      this.setState({ init: true })
    }

    if (!isEqual(prevState.leads, this.state.leads)) {
      this.props.actions.setLeads(this.state.leads)
      const lead = this.state.leads.find(l => l.id === parseInt(record_id, 10))
      if (lead) {
        this.props.actions.setLead(lead)
      }
    }

    if (!isEqual(prevProps.match, this.props.match)) {
      const lead = this.state.leads.find(l => l.id === parseInt(record_id, 10))
      if (lead) {
        this.props.actions.setLead(lead)
      }
    }

    if (this.props.fetch && this.props.fetch !== prevProps.fetch) {
      this.handleSubmit({ id__in: record_id }, this.form)
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
    this._is_mounted = false
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  handleSubmit(values, formik) {
    const { config, match } = this.props
    const { fetchMany } = this.props.actions
    formik.setSubmitting(true)
    new Promise((resolve, reject) => {
      this.setState({ loading: true, searching: true })
      const params = {
        modelname: 'leads',
        conflicts: true,
        select: true,
        trigram: true,
        params: {
          offset: this.state.offset,
          meta_fields: [ 'agent', 'contact', 'profile', 'residential', 'commercial', 'holiday', 'project', 'team', 'statistics', 'tags' ],
          order_by: '-created',
          term: values.term,
          limit: 10,
          created__date: values.created__date
        }
      }
      params.params[config.servicename ? config.servicename : config.modelname] = match.params.id
      if (values.stage === 'Archived') {
        params.params.status = 'Inactive'
      }
      if (values.stage && values.stage !== 'Archived') {
        if (values.stage === 'Sold / Rented') {
          params.params.stage = 'Sold'
        } else {
          params.params.stage = values.stage
        }
      }
      fetchMany({ values: params, resolve, reject })
    }).then(r => {
      if (!this._is_mounted) { return }
      formik.setSubmitting(false)
      this.setState({
        searching: false,
        leads: values.term ? sortBy(uniqueArray(r.options, 'id'), 'created').reverse() : sortBy(uniqueArray([ ...r.options, ...this.state.leads ], 'id'), 'created').reverse(),
        more: values.id__in ? this.state.more : r.hasMore,
        searched: !!(values.term || values.created__date || values.stage)
      }, () => {
        if (!getIn(match, 'params.record_id') && this.state.showActions && getIn(this.state, 'leads.0.id')) {
          history.push({
            pathname: `/secure/${match.params.site}/${match.params.model}/${match.params.id}/leads/${getIn(this.state, 'leads.0.id')}`
          })
        }
      })
    }).catch(() => {
      if (!this._is_mounted) { return }
      formik.setSubmitting(false)
      this.setState({ searching: false })
    })
    this.form.setFieldValue('id__in', null)
  }

  render() {
    const { user, visible, match, model } = this.props
    const stages = [
      {
        label: 'New',
        value: 'New'
      },
      {
        label: 'Contacted',
        value: 'Contacted'
      },
      {
        label: 'Viewing',
        value: 'Viewing'
      },
      {
        label: 'Offer',
        value: 'Offer'
      },
      {
        label: 'Sold / Rented',
        value: 'Sold / Rented'
      },
      {
        label: 'Archived',
        value: 'Archived'
      }
    ]
    const listing_modelname = match.params?.model && match.params.model !== 'projects' ? match.params.model : 'project'
    const values = {
      lead_type: 'Buyer',
      branch: getIn(model, 'branch'),
      contact: null,
      agent: getIn(model, 'agent'),
      listing_model: listing_modelname
    }
    values[listing_modelname] = getIn(match, 'params.id')
    let message = 'No leads here yet.'
    if (this.state.searched && !this.state.leads.length) {
      message = 'No leads found.'
    }
    if (this.state.searching && !this.state.leads.length) {
      message = <Loader inline />
    }
    return (
      <div className="leads-index">
        <Formik
          initialValues={{
            offset: 0
          }}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            this.form = formik
            return visible ? (
              <CustomForm
                component={'div'}
                onChange={changes => {
                  if (changes.includes('created__date')) {
                    this.setState({ filtered: formik.values.date, leads: [], offset: 0 })
                  }
                  if (changes.includes('stage')) {
                    this.setState({ filtered: formik.values.stage, leads: [], offset: 0 })
                  }
                }}
                render={() => (
                  <div className="keyword-search" ref={el => { this.el = el }}>
                    <Button
                      type="button" // This cannot be submit otherwise sibling form is submitted
                      icon="#icon16-Search"
                      onClick={() => {
                        this.setState({ advanced: !this.state.advanced })
                      }}
                      disabled={formik.isSubmitting}
                      className="btn btn-grey btn-icon-left btn-icon-16 advanced-toggle"
                    >Search & Filter</Button>
                    {this.state.searched ? (
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button" // This cannot be submit otherwise sibling form is submitted
                        icon="#icon16-Refresh"
                        onClick={() => {
                          formik.resetForm()
                          this.setState({ filtered: false, leads: [], offset: 0 })
                        }}
                        disabled={this.state.searching}
                        className="btn btn-grey btn-icon-left btn-icon-right btn-icon btn-icon-16"
                      />
                    ) : null}
                    <Button
                      type="button" // This cannot be submit otherwise sibling form is submitted
                      icon="#icon16-Plus"
                      onClick={() => {
                        this.props.actions.toggleWideSidebar('show-lead-creator')
                      }}
                      disabled={formik.isSubmitting}
                      className="btn btn-round btn-red btn-icon-left btn-icon-16"
                    >New Lead</Button>
                    {this.state.advanced ? (
                      <>
                        <div className="form-group">
                          <InlineSelect
                            id="stage"
                            name="stage"
                            className="inline-select"
                            classNamePrefix="inline"
                            prefix={<div className="input-group-addon">Stage: </div>}
                            options={[ { label: 'All', value: '' }, ...stages ]}
                            defaultValue={{ label: 'All', value: '' }}
                            selectedValue={formik.values.stage}
                            onChange={e => {
                              formik.setFieldValue('stage', e.value, false).then(() => {
                                formik.setFieldTouched('stage', true)
                              })
                            }}
                          />
                          <Field
                            component={DateInput}
                            name="created__date"
                            id="created__date"
                            button
                            enabled={this.state.enabled}
                            position='below right'
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            name="term"
                            id="term"
                            placeholder="Keyword Search"
                            type="search"
                            _value={formik.values.term} // Enables reseting value back to null
                            form_el={this.el}
                            show_search
                            suffix={(
                              <Button
                                type="button" // This cannot be submit otherwise sibling form is submitted
                                icon="#icon24-Search"
                                // onClick={formik.handleSubmit}
                                onClick={() => this.setState({ filtered: 'term', leads: [], offset: 0 })}
                                disabled={formik.isSubmitting}
                                className="btn btn-none input-group-addon btn-icon btn-icon-24"
                              />
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              />
            ) : null
          }}
        </Formik>
        {visible ? (
          <div className="leads-index-list" ref={el => { this.el = el }}>
            <Scrollbar
              style={{ height: `calc(100vh - ${this.state.offset}px)` }}
              innerRef={el => { this.index = el }}
            >
              {this.state.leads.length ? (
                <div className="leads-index-list-inner">
                  {this.state.leads.map((lead, lidx) => (
                    <LeadSummary
                      key={`lead-${lidx}`}
                      lead={lead}
                      match={this.props.match}
                      model={model}
                      user={user}
                    />
                  ))}
                  {this.state.more && !this.state.searching &&
                  <div className="list-actions">
                    <Button
                      onClick={() => {
                        // this.form.setFieldValue('offset', this.form.values.offset + 10)
                        // this.form.submitForm()
                        this.setState({ offset: this.state.offset + 10 })
                      }}
                      type="button"
                      className="btn btn-grey"
                    >
                      Load More
                    </Button>
                  </div>
                  }
                  { this.state.searching &&
                  <Loader inline />
                  }
                </div>
              ) : (
                <div className="no-leads">
                  <div className="no-leads-wrapper">
                    {message}
                  </div>
                </div>
              )}
            </Scrollbar>
          </div>
        ) : (
          <div className="back-to-leads">
            <Button component={NavLink} to={`/secure/${match.params.site}/${match.params.model}/${match.params.id}/leads`} type="button" className="btn btn-none btn-icon-16 btn-icon-left" icon="#icon16-ChevronLeft">Back to Leads</Button>
          </div>
        )}

        <WideSidebar sidebar={'show-lead-creator'} unmountOnExit={false}>
          <LeadCreatorSidebar
            autofill={{ ...values }}
            siblingform={{ values }}
            selectContact={() => this.handleSubmit({ }, this.form)}
            match={this.props.match}
          />
        </WideSidebar>
      </div>
    )
  }
}

LeadIndex.propTypes = {
  leads: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  modelid: PropTypes.number,
  model: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  visible: PropTypes.bool,
  fetch: PropTypes.bool
}

export default LeadIndex
