/* eslint-disable new-cap */
import classNames from 'classnames'
import merge from 'deepmerge'
import { Field, Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { withCustomRouter } from '../../../withCustomRouter'
import snippetconfig from '../../../../config/snippet.json'
import log from '../../../../logging'
import { CACHE, PERMISSIONS, SETTINGS, SITE, MINUSER, UI } from '../../../../selectors'
import { handleSubmitError, updateSearchParms, hasPermission, breakpoint } from '../../../../utils'
import { Button } from '../../../ui/Button'
import ChallengeModal from '../../modals/ChallengeModal'
import CustomForm from '../CustomForm'
import SimpleTable from '../../simpletable/SimpleTable'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import { Scrollbar } from '../../../ui/Scrollbars'
import PageJump from '../../datatable/PageJump'
import Step from '../../Step'
import QueryBuilder from '../../QueryBuilder'
import InlineSelect from './InlineSelect'
import TextInput from './Text'
import TextAreaInput from './TextArea'
import LocationSelectInput from './LocationSelect'


class SnippetManager extends React.Component {
  constructor(props) {
    super(props)
    const can_add = hasPermission([ 'snippets_add' ], props.user.permissions)
    const can_update = hasPermission([ 'snippets_update' ], props.user.permissions)
    const can_delete = hasPermission([ 'snippets_delete' ], props.user.permissions)
    const qs = new QueryBuilder(props.location.search)
    this.state = {
      searching: false,
      desktop: breakpoint.matches,
      snippets: props.cache.snippets ? Object.keys(props.cache.snippets).map(k => props.cache.snippets[k]) : [],
      fields: snippetconfig.config.fields,
      label: undefined,
      errors: {},
      touched: {},
      can_add,
      can_update,
      can_delete,
      delete: false,
      count: 0,
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0),
      term: getIn(qs, 'params.term', ''),
      input: getIn(qs, 'params.term', '')
    }
    this.isEnter = this.isEnter.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.searchSnippets = this.searchSnippets.bind(this)
    this.setFieldValue = this.setFieldValue.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.updateSnippet = this.updateSnippet.bind(this)
    this.deleteSnippet = this.deleteSnippet.bind(this)
    this.createSnippet = this.createSnippet.bind(this)
    this.stepPage = this.stepPage.bind(this)
    this.toggleDesktop = this.toggleDesktop.bind(this)
  }

  componentDidMount() {
    const can_add = hasPermission([ 'snippets_add' ], this.props.user.permissions)
    const can_update = hasPermission([ 'snippets_update' ], this.props.user.permissions)
    const can_delete = hasPermission([ 'snippets_delete' ], this.props.user.permissions)
    if (can_add !== this.state.can_add) { this.setState({ can_add }) }
    if (can_update !== this.state.can_update) { this.setState({ can_update }) }
    if (can_delete !== this.state.can_update) { this.setState({ can_delete }) }

    breakpoint.addEventListener('change', this.toggleDesktop)
    const qs = new QueryBuilder(this.props.location.search)
    if (!isEqual({
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0)
    }, {
      limit: this.state.limit,
      offset: this.state.offset
    })) {
      this.setState({
        limit: getIn(qs, 'params.limit', 20),
        offset: getIn(qs, 'params.offset', 0)
      })
    }
    this.root = document.getElementById('wrapper')
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.cache.snippets, this.props.cache.snippets)) {
      this.setState({ snippets: Object.keys(this.props.cache.snippets).map(k => this.props.cache.snippets[k]) })
    }
    const can_add = hasPermission([ 'snippets_add' ], this.props.user.permissions)
    const can_update = hasPermission([ 'snippets_update' ], this.props.user.permissions)
    const can_delete = hasPermission([ 'snippets_delete' ], this.props.user.permissions)
    if (can_add !== this.state.can_add) { this.setState({ can_add }) }
    if (can_update !== this.state.can_update) { this.setState({ can_update }) }
    if (can_delete !== this.state.can_update) { this.setState({ can_delete }) }

    const qs = new QueryBuilder(this.props.location.search)
    if (this.state.editing && !isEqual(this.state.editing, prevState.editing)) {
      this.props.toggleWideSidebar('show-snippet-manager')
    }
    if (!isEqual({
      limit: getIn(qs, 'params.limit', 20),
      offset: getIn(qs, 'params.offset', 0)
    }, {
      limit: this.state.limit,
      offset: this.state.offset
    })) {
      this.setState({
        limit: getIn(qs, 'params.limit', 20),
        offset: getIn(qs, 'params.offset', 0)
      })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleDesktop)
  }

  toggleDesktop(e) {
    if (e.matches && !this.state.desktop) {
      this.setState({ desktop: true, show: false })
    } else if (!e.matches && this.state.desktop) {
      this.setState({ desktop: false })
    }
  }

  changelimit(e) {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
  }

  updateSnippet(snippet) {
    const { updateModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'snippets',
        ...snippet
      }
      updateModel({ values, resolve, reject })
    }).then(() => {
      this.setState({ term: '', searching: true, editing: null })
      this.props.toggleWideSidebar()
      this.snippets?.refreshPage()
    }).catch(e => {
      log.error(e)
    })
  }

  createSnippet(values, actions) {
    const { createModel } = this.props
    return new Promise((resolve, reject) => {
      values.modelname = 'snippets'
      createModel({ values, resolve, reject })
    }).then(() => {
      actions.setSubmitting(false)
      this.setState({ searching: true })
      actions.resetForm({
        values: {
          name: '',
          content: '',
          area: null
        }
      })
      this.snippets?.refreshPage()
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  deleteSnippet(snippet, confirmation) {
    const { deleteModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'snippets',
        selected: [ snippet ]
      }
      if (confirmation) {
        return deleteModel({ values, resolve, reject })
      }
      return reject('')
    }).then(() => {
      this.setState({ term: '', searching: true, delete: null })
      this.snippets?.refreshPage()
    }).catch(e => {
      if (e) {
        this.setState({ delete: null })
      }
    })
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.setState({ searching: true, term: this.state.input })
    } // continue typing
    return true
  }

  calculateRecords(response) {
    let from = 0
    let to = 0
    if (response.params) {
      const { offset, limit } = response.params
      const count = Number(response.count)
      from = Number(offset) + 1 || 1
      to = (from - 1 + limit) > count ? count : from - 1 + limit
    }
    return { from, to }
  }

  searchSnippets(params) {
    const { fetchMany } = this.props
    const values = {
      modelname: 'snippets',
      modellist: true,
      term: this.state.term,
      trigram: true,
      meta_fields: [ 'area', 'suburb' ],
      params: {
        related: true,
        order_by: '-created'
      },
      delete: null,
      editing: null
    }
    if (!params) {
      new Promise((resolve, reject) => {
        fetchMany({ values, resolve, reject })
        this.setState({ searching: true })
      }).then(r => {
        this.setState({ searching: false, snippets: r, searched: !!this.state.term })
      }).catch(() => (
        this.setState({ searching: false })
      ))
    } else {
      updateSearchParms('term', this.state.term)
      fetchMany({ values: { ...values, params: params.params }, resolve: params.resolve, reject: params.reject })
    }
    this.setState({ searching: false })
  }

  stepPage(dir) { // Step through result pages
    const { count, params } = this.state.model
    const { limit } = params
    const url = this.state.model[dir]
    let offset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { offset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        offset = Math.floor(count / limit) * limit
        break
      default:
        offset = 0
    }
    updateSearchParms('offset', offset)
  }

  updateInput(e) {
    this.setState({ input: e.target.value })
  }

  setFieldValue(name, value) {
    const state = merge({}, this.state)
    state[name] = value
    this.setState(state)
  }


  render() {
    const { user, cache, ui } = this.props
    const strlimit = this.state.limit.toString(10)
    return (
      <div className="snippet-manager">
        {this.state.can_add &&
          <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={this.createSnippet}
          >{({ values, isSubmitting, handleSubmit }) => (
              <CustomForm
                component="div"
                render={() => (
                  <div key="snippets-form" className="snippets-form tablesearch">
                    <div className="field col-lg-12 required">
                      <Field
                        id="snippets-label"
                        name="name"
                        label="Snippet Name"
                        placeholder=""
                        component={TextInput}
                        className="label"
                        defaultValue={values.name}
                        disabled={isSubmitting ? true : false}
                        bounce={true}
                      />
                    </div>
                    <div className="field col-lg-12 required">
                      <Field
                        id="snippets-content"
                        name="content"
                        label="Snippet Text"
                        defaultValue={values.content}
                        component={TextAreaInput}
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="field">
                        <Field
                          id="snippets-area"
                          name="area"
                          label="Area"
                          cache={cache}
                          modelname="areas"
                          extraparams="order_by=province__country__country,province__province,area&trigram_fields=area&province__country__id__in=:supported_countries"
                          placeholder="Select an area"
                          optionlabel="area"
                          defaultValue={values.area}
                          labelformat={{
                            head: ':area',
                            sub: ':province, :country'
                          }}
                          labelgrouper="province"
                          dependents={[
                            'suburb'
                          ]}
                          component={LocationSelectInput}
                        />
                      </div>
                    </div>
                    { values.area ? (
                      <div className="col-lg-6">
                        <div className="field">
                          <Field
                            id="snippets-suburb"
                            name="suburb"
                            label="Suburb"
                            cache={cache}
                            modelname="suburbs"
                            optionlabel={[ 'area', 'suburb' ]}
                            defaultValue={values.area}
                            labelformat={{
                              head: ':area, :suburb',
                              sub: ':province, :country'
                            }}
                            extraparams="order_by=area__province__country__country,area__province__province,area__area,suburb&trigram_fields=suburb,area__area&area__province__country__id__in=:supported_countriess&area__id__in=:area"
                            placeholder="Select a Suburb"
                            labelseparator=", "
                            component={LocationSelectInput}
                          />
                        </div>
                      </div>
                    ) : null }
                    <div className="search-buttons col-lg-12" style={{ paddingRight: 0 }}>
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button"
                        icon="#icon24-Plus"
                        onClick={handleSubmit}
                        disabled={this.state.searching}
                        className="btn btn-primary btn-icon-16 btn-icon-left"
                      >
                      Add Snippet
                      </Button>
                    </div>
                  </div>
                )}
              />
            )}
          </Formik>
        }
        <div className="snippets-search search-fields">
          <div className="input-group keyword-search">
            <div className="form-group term">
              <div className="forminput">
                <input
                  id="snippet-search"
                  ref={el => { this.el = el }}
                  type="search"
                  placeholder="Keyword Search"
                  className="form-control input-group-suffix"
                  onBlur={this.isEnter}
                  onKeyDown={this.isEnter}
                  onChange={this.updateInput}
                  value={this.state.input}
                />
                <Button icon="#icon24-Search" type="button" disabled={this.state.searching} onClick={() => this.setState({ searching: true, term: this.state.input })} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
              </div>
            </div>
          </div>
          {this.state.searched &&
            <div className="reset-group">
              <Button
                id="keyword-search-btn"
                tabIndex="-1"
                type="button"
                onClick={() => {this.setState({ term: '', input: '', searched: false }, () => this.setState({ searching: true }))}}
                disabled={this.state.searching}
                className="input-group-addon btn btn-none"
              >
                Reset Filters
              </Button>
            </div>
          }
          <div className="page-tools">
            <PageJump
              params={{ offset: this.state.offset, limit: this.state.limit, count: this.state.count }}
              endpoint={getIn(snippetconfig.config, 'endpoint')}
              modelname={getIn(snippetconfig.config, 'modelname')}
              count={getIn(this.state, 'count')}
            />

            <div className="page-buttons">
              <Step
                stepPage={this.stepPage}
                next={getIn(this.state, 'next')}
                previous={getIn(this.state, 'previous')}
              />

              {this.state.desktop && <div className="record-count">
                {getIn(this.state, 'records.to') ? getIn(this.state, 'records.from') : 0} - {getIn(this.state, 'records.to')} of {getIn(this.state, 'count')}
              </div> }
              {this.state.desktop && <InlineSelect
                id="field-limit"
                name="limit"
                className="inline-select meta-limit"
                classNamePrefix="inline"
                defaultValue={{ value: this.state.limit, label: strlimit }}
                selectedValue={(getIn(this.state, 'model.params')) ? Number(getIn(this.state, 'model.params.limit')) : ''}
                autosize={true}
                options={[
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ]}
                onChange={e => this.changelimit(e, getIn(this.props, 'params'))}
              />}
            </div>
          </div>
        </div>
        <div className="snippetsbody">
          <SimpleTable
            user={user}
            config={snippetconfig.config}
            action={({ params, resolve }) => {
              new Promise((res1, rej1) => this.searchSnippets({ params, resolve: res1, reject: rej1 })).then(data => {
                this.setState({
                  model: data,
                  next: data.next,
                  previous: data.previous,
                  count: data.count,
                  records: this.calculateRecords(data)
                })
                const rows = data.index.map(i => getIn(this.props, `cache.snippets.${i}`))
                resolve({ options: rows })
                this.setState({ searching: false, searched: !!this.state.term })
              })
            }}
            params={{
              limit: this.state.limit,
              offset: this.state.offset,
              meta_fields: [ 'area', 'suburb' ],
              term: this.state.term,
              related: true,
              order_by: '-created'
            }}
            getClass={el => {
              this.snippets = el
            }}
            parser={data => data}
            header={[
              {
                label: 'Snippet Name',
                name: 'name',
                orderable: true
              },
              {
                label: 'Created',
                name: 'created',
                format: 'datetime',
                orderable: true
              },
              {
                label: 'Area',
                name: 'meta.area.area'
              },
              {
                label: 'Suburb',
                name: 'meta.suburb.suburb'
              },
              {
                label: 'Snippet Text',
                name: 'content'
              }
            ]}
            rowActions={(row, data) => {
              let can_edit = false
              let can_delete = false
              can_edit = hasPermission([ 'snippets_update' ], user.permissions)
              can_delete = hasPermission([ 'snippets_delete' ], user.permissions)
              return (
                <>
                  {can_edit ? (
                    <Button
                      icon="#icon16-Edit"
                      className="btn btn-none btn-icon-only btn-icon-16"
                      title="Edit Snippet"
                      onClick={() => {
                        this.setState({ editing: data })
                      }} type="button"
                    >
                    </Button>
                  ) : null }
                  {can_delete ? (
                    <Button
                      icon="#icon16-Bin"
                      className="btn btn-none btn-icon-only btn-icon-16"
                      title="Delete Snippet"
                      onClick={() => this.setState({ delete: data.id }) }
                      type="button"
                    >
                    </Button>
                  ) : null }
                </>
              )
            }}
          />

          {(this.root && this.state.delete) ? (
            ReactDOM.createPortal(
              <Formik
                initialValues={{
                  record_id: [ this.state.delete ]
                }}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={true}
              >{ formik => (
                  <ChallengeModal
                    isLoading={ui?.isLoading}
                    action={'deleteModels'}
                    challenges={[
                      {
                        text: (
                          <span>
                        You&apos;re about to delete
                            <span> {formik.values.record_id.length.toString()} </span> snippet.
                        Please confirm your action by typing <code>CONFIRM</code> in the field below.
                          </span>
                        ),
                        value: () => 'CONFIRM',
                        action: 'deleteModels'
                      },
                      {
                        text: (
                          <span>
                        You&apos;re about to delete
                            <span> {formik.values.record_id.length.toString()} </span> snippet.
                        Please confirm your action by typing <code>DELETE</code> in the field below.
                          </span>
                        ),
                        value: () => 'DELETE',
                        action: 'deleteModels'
                      }
                    ]}
                    visible={this.state.delete}
                    buttons={(({ valid, value }) => (
                      <div className="modal-buttons">
                        <Button className={classNames('btn', 'btn-primary', { disabled: !valid })} type="submit" onClick={valid ? () => {
                          formik.setFieldTouched('challenge', true, false)
                          if (valid) {
                            this.deleteSnippet(this.state.delete, valid)
                          } else {
                            formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                          }
                        } : null}>Confirm</Button>
                        <Button className="btn btn-white" type="submit" onClick={() => {
                          this.setState({ delete: false }, () => { formik.setFieldError('challenge', null) })
                        }}>Cancel</Button>
                      </div>
                    ))}
                  />
                )}
              </Formik>, this.root)
          ) : null }
          <WideSidebar sidebar={'show-snippet-manager'}>
            <div id="snippet-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar snippet-creator-sidebar">
              <div className="wide-sidebar-pane">
                <div className="wide-sidebar-heading">
                  <h4>Edit Snippet</h4>
                  <Button
                    type="button"
                    icon="#icon24-X-Large"
                    className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                    onClick={() => {
                      this.setState({ editing: null }, () => {
                        this.props.toggleWideSidebar()
                      })
                    }}
                  />
                </div>
                <Scrollbar
                  style={{ height: 'calc(100vh - 218px)' }}
                  renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                >
                  <div className="wide-sidebar-content">
                    <Formik
                      initialValues={{
                        id: getIn(this.state, 'editing.id'),
                        name: getIn(this.state, 'editing.name'),
                        content: getIn(this.state, 'editing.content'),
                        area: getIn(this.state, 'editing.area'),
                        suburb: getIn(this.state, 'editing.suburb')
                      }}
                      enableReinitialize={true}
                      onSubmit={this.updateSnippet}
                    >{({ values, isSubmitting, handleSubmit }) => (
                        <CustomForm
                          component="div"
                          onChange={() => {
                            this.setState({ ...values })
                          }}
                          render={() => (
                            <div className="editgroup">
                              <div className="input-group">
                                <div className="field col-lg-12 required">
                                  <Field
                                    id="snippet-name"
                                    name="name"
                                    label="Snippet Name"
                                    _value={getIn(values, 'name')}
                                    component={TextInput}
                                    className="label"
                                    disabled={isSubmitting ? true : false}
                                    bounce={true}
                                  />
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="field col-lg-12 required">
                                  <Field
                                    id="snippet-content"
                                    name="content"
                                    label="Snippet Text"
                                    _value={getIn(values, 'content')}
                                    component={TextAreaInput}
                                  />
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="field col-lg-6">
                                  <Field
                                    id="snippets-area"
                                    name="area"
                                    label="Area"
                                    cache={cache}
                                    _value={getIn(values, 'area')}
                                    modelname="areas"
                                    extraparams="order_by=province__country__country,province__province,area&trigram_fields=area&province__country__id__in=:supported_countries"
                                    placeholder="Select an area"
                                    optionlabel="area"
                                    labelformat={{
                                      head: ':area',
                                      sub: ':province, :country'
                                    }}
                                    labelgrouper="province"
                                    dependents={[
                                      'suburb'
                                    ]}
                                    component={LocationSelectInput}
                                  />
                                </div>
                                { values.area ? (
                                  <div className="field col-lg-6">
                                    <Field
                                      id="snippets-suburb"
                                      name="suburb"
                                      label="Suburb"
                                      cache={cache}
                                      _value={getIn(values, 'suburb')}
                                      modelname="suburbs"
                                      optionlabel={[ 'area', 'suburb' ]}
                                      labelformat={{
                                        head: ':area, :suburb',
                                        sub: ':province, :country'
                                      }}
                                      extraparams="order_by=area__province__country__country,area__province__province,area__area,suburb&trigram_fields=suburb,area__area&area__province__country__id__in=:supported_countries&area__id=:area"
                                      placeholder="Select a Suburb"
                                      labelseparator=", "
                                      component={LocationSelectInput}
                                    />
                                  </div>
                                ) : null }
                              </div>
                              <div className="wide-sidebar-footer">
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon24-Save"
                                  onClick={handleSubmit}
                                  disabled={this.state.searching}
                                  className="btn btn-primary btn-icon-16 btn-icon-left"
                                >
                                 Save Snippet
                                </Button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    </Formik>
                  </div>
                </Scrollbar>
              </div>
            </div>
          </WideSidebar>
        </div>
      </div>
    )
  }
}

SnippetManager.propTypes = {
  user: PropTypes.object,
  cache: PropTypes.object,
  fields: PropTypes.object,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  location: PropTypes.object,
  ui: PropTypes.object
}

const mapStateToProps = state => { // Pass only minimal data to the FieldGroup component
  const modelname = 'snippets'
  const site = SITE(state)
  const ui = UI(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site })
  const minuser = Map({
    permissions,
    agent
  })

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
  }
  snippetconfig.config.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        mincache[mn] = cache.get(mn)
      })
    }
    if (field.input === 'LocationSelect') { mincache.set('branches', cache.get('branches')) }
  })

  return {
    cache: mincache,
    user: minuser,
    ui
  }
}


export default withCustomRouter(connect(mapStateToProps, null)(withImmutablePropsToJS(SnippetManager)))
