import PropTypes from 'prop-types'
import React from 'react'
import { Legend, Surface, BarChart, XAxis, YAxis, Bar } from 'recharts'
import classNames from 'classnames'

import { valueFormat } from '../../../utils'
import { ResponsiveContainer } from './ResizeContainer'


const renderColorfulLegendText = value => (<span style={{ color: '#5A6F89' }}>{value}</span>)

const SIZE = 19

const CustomizedLegend = props => {
  const { payload, inactiveColor, layout, align, legend } = props
  const viewBox = { x: 0, y: 0, width: SIZE, height: 2 }
  const svgStyle = { display: 'block', marginTop: 2, marginBottom: 2 }
  const finalStyle = {
    padding: 0,
    margin: 0,
    textAlign: layout === 'horizontal' ? align : 'left'
  }
  return (
    <div className="stacked-legend">
      <ul className="recharts-default-legend" style={finalStyle}>
        {payload.map((entry, i) => {
          const color = entry.inactive ? inactiveColor : entry.color
          const className = classNames({
            'recharts-legend-item': true,
            [`legend-item-${i}`]: true,
            inactive: entry.inactive
          })
          return (
            <li ref={ref => (legend[i] = ref)} className={className} key={`legend-${i}`}>
              <div className="stacked-legend-header">
                <strong>{entry.payload.entry.name}: </strong>
                <span>{entry.payload.entry.value}</span>
              </div>
              <Surface width={SIZE} height={2} viewBox={viewBox} style={svgStyle}>
                <rect
                  stroke="none"
                  fill={color}
                  width={SIZE}
                  height={2}
                  className="recharts-legend-icon"
                />
              </Surface>
              <div className="stacked-legend-percentage">
                {valueFormat('number', entry.payload.entry.percentage)}%
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

CustomizedLegend.propTypes = {
  payload: PropTypes.array,
  formatter: PropTypes.func,
  inactiveColor: PropTypes.string,
  layout: PropTypes.object,
  align: PropTypes.string,
  legend: PropTypes.object
}


class StackedBarGraph extends React.Component {
  constructor(props) {
    super(props)

    this.bars = []
    this.legend = []
    this.point = null
    this.bar = null

    this.onChartMouseOver = this.onChartMouseOver.bind(this)
    this.onChartMouseLeave = this.onChartMouseLeave.bind(this)
    this.updateTooltip = this.updateTooltip.bind(this)
  }

  onChartMouseOver(chart, index, event) {
    const point = this.legend[index]
    this.bars = Array.from(event.target.closest('svg').querySelectorAll('.recharts-rectangle'))
    if (point !== this.point) {
      this.point = point
      this.bar = event.target
      this.updateTooltip()
    }
  }

  onChartMouseLeave() {
    this.point = null
    this.bar = null
    this.updateTooltip()
  }

  updateTooltip() {
    if (this.point) {
      this.legend.map(el => {
        el.style.opacity = '0.25'
      })
      this.bars.map(el => {
        if (el && el.style) {
          el.style.opacity = '0.25'
        }
      })
      this.point.style.opacity = '1'
      this.bar.style.opacity = '1'
    } else {
      this.legend.map(el => {
        el.style.opacity = '1'
      })
      this.bars.map(el => {
        if (el && el.style) {
          el.style.opacity = '1'
        }
      })
    }
  }

  render() {
    const { data } = this.props
    const clean_data = {}
    data.forEach(v => {
      clean_data[v.name] = v.percentage < 1 ? 0.5 + v.percentage : v.percentage
    })
    return (
      <div className="ui-chart">
        <ResponsiveContainer width="100%" height={30} minWidth="0" minHeight="1">
          <BarChart stackOffset="expand" width={750} height={15} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} barGap={0} barCategoryGap={0} data={[ clean_data ]} layout="vertical">
            <Legend iconType="rectangle" height={1} align="left" iconSize={9} content={<CustomizedLegend legend={this.legend} />} formatter={renderColorfulLegendText} />
            <YAxis hide type="category" />
            <XAxis hide type="number" />
            {data.map((entry, idx) => (
              <Bar
                ref={ref => (this.bars[idx] = ref)}
                key={`bar-${idx}`}
                barSize={14}
                entry={entry}
                dataKey={entry.name}
                stackId="a"
                fill={entry.colour}
                onMouseOver={(chart, index, event) => {
                  this.onChartMouseOver(chart, idx, event)
                }}
                onMouseLeave={this.onChartMouseLeave}
              />
            ))}
          )
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

StackedBarGraph.propTypes = {
  data: PropTypes.array
}

export default StackedBarGraph
