/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import { hasPermission, buildOptionLabel } from '../../utils'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import withDelay from './withDelay'


class LatestLeadsWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      negative: false,
      branch_id: '',
      branches: [],
      branch_options: [],
      agents: [],
      listing_options: [],
      agent_id: '',
      status: 'Active',
      show_agents: true,
      listing_model: '',
      loading: false
    }
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user, branches } = this.props
    let agent_id = this.state.agent_id
    let branch_id = this.state.branch_id
    if (!branches || branches.length > 1) {
      let agent_branches = []
      if (!hasPermission([
        'apply_to_all_branches'
      ], user.permissions, null, user.agent.id)) {
        agent_branches = [ ...user.agent.branches ]
      }
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'branches',
          optionvalue: 'id',
          optionlabel: 'name',
          fields: [ 'id', 'name' ],
          active: 1,
          all: true,
          select: true,
          params: {
            id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
            order_by: 'name'
          },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => {
        if (this._is_mounted) {
          const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
          this.setState({ branches: r.options, branch_options })
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
    if (hasPermission([
      'leads_view_own'
    ], user.permissions, null, user.agent.id) && !hasPermission([
      'leads_contacts_asssociated_agents_view', 'leads_view_unassigned'
    ], user.permissions, null, user.agent.id) && this._is_mounted) {
      this.setState({ show_agents: false })
    }
    if (user.agent) {
      if (!agent_id && getIn(user, 'agent.id')) {
        agent_id = user.agent.id
      }
      if (
        !hasPermission([ 'apply_to_all_branches' ], user.permissions, null, user.agent.id)
        && user.agent.branches.length === 1
        && !branch_id
      ) {
        branch_id = user.agent.branches[0]
      }
    }
    if ((agent_id !== this.state.agent_id || branch_id !== this.state.branch_id) && this._is_mounted) {
      this.setState({ agent_id, branch_id })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  render() {
    const { user, config, actions } = this.props
    const { stage, agent_id, branch_id, branches } = this.state
    return (
      <Card
        id="latest-leads-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding latest-leads-widget"
        background
        header={
          <>
            <h3>Latest Leads</h3>
            <div className="details-section-buttons min-flex tablemeta">
              {(!branches || branches.length > 1) ? (
                <div className="filter-branch">
                  <InlineSelect
                    id="branch_id"
                    name="branch_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                    defaultValue={JSON.stringify({ label: 'All Branches', value: '' })}
                    selectedValue={getIn(this.state, 'branch_id')}
                    onChange={e => {
                      if (this._is_mounted) {
                        this.setState({ branch_id: e.value })
                      }
                    }}
                  />
                </div>
              ) : null}
              {this.state.show_agents ? (
                <div className="filter-agent">
                  <AsyncInlineSelect
                    id="agent_id"
                    name="agent_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    defaultValue={JSON.stringify({ first_name: 'All', last_name: 'Agents', id: '' })}
                    options={user.agent.id ? [ { first_name: 'All', last_name: 'Agents', id: '' }, { ...user.agent } ] : [ { first_name: 'All', last_name: 'Agents', id: '' } ]}
                    form={{}}
                    modelname="agents"
                    labelseparator=" "
                    fetchMany={this.props.actions.fetchMany}
                    optionlabel={[ 'first_name', 'last_name' ]}
                    noclear
                    params={{
                      branches__overlap: branch_id ? [ branch_id ] : branches.map(b => b.id),
                      active: 1,
                      order_by: 'first_name,last_name',
                      fields: 'id,first_name,last_name'
                    }}
                    field={{
                      value: getIn(this.state, 'agent_id')
                    }}
                    onChange={e => {
                      if (this._is_mounted) {
                        this.setState({ agent_id: e.value })
                      }
                    }}
                  />
                </div>
              ) : null}
              <div className="filter-stage">
                <InlineSelect
                  id="stage"
                  name="stage"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[
                    { label: 'Stage', value: '' },
                    { label: 'New', value: 'New' },
                    { label: 'Contacted', value: 'Contacted' },
                    { label: 'Viewing', value: 'Viewing' },
                    { label: 'Offer', value: 'Offer' },
                    { label: 'Sold / Rented', value: 'Sold' },
                    { label: 'Inactive', value: 'Inactive' },
                    { label: 'Rental Application', value: 'Rental Application' }
                  ]}
                  defaultValue={JSON.stringify({ label: 'Stage', value: '' })}
                  selectedValue={getIn(this.state, 'stage')}
                  onChange={e => {
                    if (this._is_mounted) {
                      this.setState({ stage: e.value })
                    }
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          <SimpleTable
            config={config}
            action={({ resolve, reject }) => actions.fetchMany({
              noloader: true,
              values: {
                modelname: 'leads',
                signal: this.AbortController.signal,
                params: {
                  contact__branch: branch_id,
                  contact_agents__in: agent_id,
                  status__in: user.agent.site.region !== 'ae' ? [ 'Active', 'No-Consent', 'Non-Compliant' ] : [ 'Active' ],
                  stage,
                  order_by: '-created',
                  limit: 10,
                  fields: [
                    'id',
                    'created',
                    'source',
                    'contact',
                    'residential',
                    'commercial',
                    'holiday',
                    'project',
                    'contact',
                    'stage',
                    'first_name',
                    'last_name',
                    'status'
                  ],
                  meta_fields: [
                    'residential',
                    'commercial',
                    'holiday',
                    'project',
                    'contact'
                  ]
                }
              }, resolve, reject
            })}
            params={{
              stage,
              contact__branch: branch_id,
              contact_agents__in: agent_id
            }}
            header={[
              {
                label: 'Date Added',
                name: 'created',
                orderable: false,
                format: 'datetime'
              },
              {
                label: 'Address',
                name: [
                  'listing.unit_number',
                  'listing.complex_name',
                  ',',
                  'listing.building_name',
                  ',',
                  'listing.street_number',
                  'listing.street_name'
                ],
                orderable: false,
                container: 'meta',
                link: '/secure/:site/:meta.listing.model/:meta.listing.id'
              },
              {
                label: 'Source',
                name: 'source',
                orderable: false
              },
              {
                label: 'Contact',
                name: 'contact',
                orderby: 'contact__first_name',
                modelname: 'contacts',
                placeholder: 'Enter email, first name or last name',
                link: '/secure/:site/contacts/:contact',
                optionlabel: [ 'first_name', 'last_name' ],
                labelseparator: ' ',
                orderable: false
              },
              {
                label: 'Status',
                name: 'status',
                orderable: false
              },
              {
                label: 'Stage',
                name: 'stage',
                orderable: false,
                format: 'stage',
                link: ':meta.listing.link/../leads/:id'
              }
            ]}
            user={user}
          />
        }
      />
    )
  }
}

LatestLeadsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'leads')
  return ({
    user,
    config
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(LatestLeadsWidget)))
