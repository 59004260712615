import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import classNames from 'classnames'
import FieldComponent from '../FieldComponent'
import { isConditional, parseClasses, valueFormat } from '../../../../utils'


class ParkingRatio extends React.Component {
  constructor(props) {
    super(props)
    this.form = props.form
    this.isConditional = isConditional.bind(this)
    this.fieldClasses = this.fieldClasses.bind(this)
    this.renderArrayFields = this.renderArrayFields.bind(this)
    const t = {}
    this.props.fields.forEach(field => { t[field.name] = null })
    this.state = {
      template: t,
      watched: props.fields.map(field => {
        const new_field = {
          ...field,
          _edit: this.isConditional(field, 'edit', false, props.form).toString(),
          _required: this.isConditional(field, 'required', false, props.form).toString(),
          _name: field.name
        }
        if (props.field.value) {
          new_field._value = props.field.value[field.name]
        }
        if (field.watch) { // Fields that should force this component to re-render
          new_field._related = field.watch.map(val => props.form.values[val])
        }
        return new_field
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.form = nextProps.form
    const watched = nextProps.fields.map(field => {
      const new_field = {
        ...field,
        _edit: this.isConditional(field, 'edit', false, this.form).toString(),
        _required: this.isConditional(field, 'required', false, this.form).toString()
      }
      if (this.props.field.value) { new_field._value = this.props.field.value }
      if (field.modelname) { // This field references external field - watch for cache changes
        new_field.cache = nextProps.cache[field.modelname]
      }
      if (field.watch) { new_field._related = field.watch.map(val => nextProps.form.values[val]) }
      return new_field
    })
    if (isEqual(this.state.watched, nextState.watched) && isEqual(this.state.watched, watched)) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    const init = []
    init.push(this.state.template)
    const watched = this.props.fields.map(field => {
      const new_field = {
        ...field,
        _edit: this.isConditional(field, 'edit', false, this.props.form).toString(),
        _required: this.isConditional(field, 'required', false, this.props.form).toString()
      }
      if (field.watch) { // Fields that should force this component to re-render
        new_field._related = field.watch.map(val => this.props.form.values[val])
      }
      return new_field
    })
    if (!isEqual(prevState.watched, watched)) {
      this.setState({ watched })
    }
  }


  fieldClasses(field, errors) {
    let classes = classNames('field')
    const required = field._required === 'true'
    if (required) {
      classes += ' required'
    }
    if (field.name in errors) { classes += ' error' }
    if (field.cols) { classes += ` col-${field.cols}`}
    return classes
  }

  renderArrayFields() { // Ensure that there is at least a initial array
    const { errors } = this.props.form
    const outer = Array.isArray(this.state.watched) ? this.state.watched.filter(field => {
      if (this.props.show_required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    }).map((field, fidx) => { // Only display editable fields
      let inner = []
      const f = { ...field }
      f.classes = parseClasses(field.classes, this.props.form.values)
      if (f.name === 'parking_ratio_per_meter') {
        f.label = valueFormat('unit', field._related[0])
      }
      inner = React.createElement('div', {
        key: `field-${this.props.field.name}-${fidx}`,
        className: this.fieldClasses(field, errors)
      }, React.createElement(FieldComponent, {
        ...this.props,
        key: `fc-${this.props.field.name}-${fidx}`,
        field: f,
        errors })
      )
      return inner
    }) : null
    const groups = []
    let current_group = []
    let count = 12
    if (outer) {
      outer.forEach((node, fidx) => {
        const classes = node.props && node.props.className ? node.props.className : ''
        const regex = /col(-[a-z]+)?-(\d+)/ig
        const matches = regex.exec(classes)
        current_group.push(node)
        if (matches) {
          const col = parseInt(matches.pop(), 10)
          count -= col
        }
        if (count <= 0 || fidx === outer.length - 1) {
          groups.push(React.createElement('div', { key: `col-${fidx}`, className: 'input-group ratio-group' }, current_group))
          current_group = []
          count = 12
        }
      })
    }
    return groups
  }

  render() {
    return this.renderArrayFields()
  }
}

ParkingRatio.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  fields: PropTypes.array,
  cache: PropTypes.object,
  maxLength: PropTypes.number,
  show_required: PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  help: PropTypes.array,
  counter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default React.memo(ParkingRatio)
