import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getIn, ErrorMessage } from 'formik'
import HelpText from '../../HelpText'

import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import VacancyProSidebar from '../../../../containers/VacancyProSidebar'


class VacancyPro extends React.Component {
  /* This component is at the moment only used for showing conflicting
  * serviced locations in the branch model. It can be extended in the future
  * if the developer finds a use case, however for now this is the single
  * place it is used.
  */
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      open: false
    }
    this.toggleLookup = this.toggleLookup.bind(this)
  }

  componentDidUpdate() {
    this.root = document.getElementById('content')
    if (this.state.value !== getIn(this.props.form.initialValues, this.props.field.name)) {
      this.setState({ value: this.props.form.initialValues[this.props.field.name] })
    }
  }

  toggleLookup() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { className, id, ...props } = this.props
    const { field, form, disabled } = props
    const { name, value } = field
    return (
      <Fragment>
        <WideSidebar sidebar="show-vacancy-sidebar">
          <VacancyProSidebar
            field={field}
            key={`dlookup-${field.name}`}
            deeds={props.deeds}
            ownerfield={props.ownerfield}
            toggleLookup={this.toggleLookup}
            siblingform={form}
            match={props.match}
          />
        </WideSidebar>
        <div id={id} className={`form-group deeds-lookup ${props.classes ? props.classes : ''}`}>
          <div className="">
            <input
              id={`input-${id}`}
              name={name}
              type="hidden"
              disabled={disabled}
              readOnly={this.state.value}
              placeholder={props.placeholder}
              className={`form-control input-group-suffix ${className ? className : ''}`}
              {...field}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={value || ''}
              maxLength={props.maxLength}
            />
            <Button tabIndex="-1" type="button" onClick={() => { this.props.toggleWideSidebar('show-vacancy-sidebar') }} disabled={this.state.searching} className="btn btn-grey">
              Vacancy Lookup
            </Button>
            {props.help &&
              <span className="help-text">
                {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
              </span>
            }
            <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
          </div>
        </div>
      </Fragment>
    )
  }
}

VacancyPro.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchVacancyPro: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  ownerfield: PropTypes.string,
  classes: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  create: PropTypes.bool,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  config: PropTypes.object,
  match: PropTypes.object
}


export default VacancyPro
