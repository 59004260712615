import * as yup from 'yup'
import messages from './messages.json'


export const articles = () => yup.object({
  domain: yup.number().typeError(messages.required).positive().integer().required(messages.required),
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  title: yup.string().max(100).required(messages.required),
  author: yup.string().max(100).required(messages.required),
  summary: yup.string().max(160).required(messages.required),
  category: yup.number().typeError(messages.required).positive().integer().required(messages.required),
  content: yup.string().required(messages.required),
  branches: yup.array().of(yup.number().typeError('Please select a branch').positive().integer()),
  images: yup.array().of(yup.number().positive().integer())
})
