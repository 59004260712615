import * as yup from 'yup'
import messages from './messages.json'


export const images = () => yup.object({
  caption: yup.string().max(200).required(messages.required),
  file_type: yup.string().required(messages.required),
  model: yup.string().max(50).nullable(),
  category: yup.number().positive().integer().nullable(),
  tags: yup.array().of(yup.number().positive().integer()).nullable()
})
