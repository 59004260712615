import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import HelpText from '../../HelpText'

import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import ServicedAttributeLookupSidebar from '../../../../containers/ServicedAttributeLookupSidebar'


const ServicedAttributeLookup = props => {
  const [ open, setOpen ] = useState(false)
  const toggleLookup = () => { setOpen(!open) }
  const { id, modelname, field, form } = props
  const { name } = field
  return (
    <Fragment>
      <WideSidebar sidebar="show-serviced-attribute-sidebar" unmountOnExit={false}>
        <ServicedAttributeLookupSidebar
          field={field}
          key={field.name}
          toggleLookup={toggleLookup}
          siblingform={form}
          modelname={modelname}
        />
      </WideSidebar>
      <div id={id} className={`form-group serviced-location-lookup ${props.classes ? props.classes : ''}`}>
        <div className="forminput">
          <Button tabIndex="-1" type="button" onClick={() => { props.toggleWideSidebar('show-serviced-attribute-sidebar') }} className="input-group-addon btn btn-grey">
            Search By Serviced Attributes
          </Button>
          {props.help &&
            <span className="help-text">
              {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
            </span>
          }
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </div>
    </Fragment>
  )
}

ServicedAttributeLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  help: PropTypes.array,
  modelname: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  create: PropTypes.bool,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  config: PropTypes.object
}


export default ServicedAttributeLookup
