import PropTypes from 'prop-types'
import React from 'react'


const EmptyRow = ({ columns, plural }) => {
  const colSpan = columns.length + 1
  return React.createElement(
    'tr', { key: Math.floor((Math.random() * 100) + 1) },
    React.createElement(
      'td',
      { colSpan },
      React.createElement(
        'span',
        { className: 'tablecell' },
        `No ${plural ? plural : 'records'} were found.`
      )
    )
  )
}

EmptyRow.propTypes = {
  modelname: PropTypes.string,
  columns: PropTypes.array,
  plural: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default EmptyRow
