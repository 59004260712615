import * as yup from 'yup'
import messages from './messages.json'


export const groups = () => yup.object({
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  name: yup.string().max(100).required(messages.required),
  leads_view: yup.boolean(),
  leads_edit: yup.boolean(),
  leads_view_own: yup.boolean().nullable(),
  leads_edit_own: yup.boolean().nullable(),
  apply_to_all_branches: yup.boolean().nullable(),
  super_access: yup.boolean().nullable(),
  listings_residential_for_sale_view: yup.boolean(),
  listings_residential_for_sale_view_own: yup.boolean(),
  listings_residential_for_sale_update: yup.boolean(),
  listings_residential_for_sale_update_own: yup.boolean(),
  listings_residential_for_sale_export: yup.boolean(),
  listings_residential_for_sale_reports: yup.boolean(),
  listings_residential_for_sale_display_on_website: yup.boolean(),
  listings_residential_for_sale_feed_to_portals: yup.boolean(),
  listings_residential_for_sale_can_select_featured: yup.boolean(),
  listings_residential_for_sale_select_newsletter_featured: yup.boolean(),
  listings_residential_to_let_view: yup.boolean(),
  listings_residential_to_let_view_own: yup.boolean(),
  listings_residential_to_let_update: yup.boolean(),
  listings_residential_to_let_update_own: yup.boolean(),
  listings_residential_to_let_export: yup.boolean(),
  listings_residential_to_let_reports: yup.boolean(),
  listings_residential_to_let_display_on_website: yup.boolean(),
  listings_residential_to_let_feed_to_portals: yup.boolean(),
  listings_residential_to_let_can_select_featured: yup.boolean(),
  listings_residential_to_let_select_newsletter_featured: yup.boolean(),
  listings_commercial_for_sale_view: yup.boolean(),
  listings_commercial_for_sale_view_own: yup.boolean(),
  listings_commercial_for_sale_update: yup.boolean(),
  listings_commercial_for_sale_update_own: yup.boolean(),
  listings_commercial_for_sale_export: yup.boolean(),
  listings_commercial_for_sale_reports: yup.boolean(),
  listings_commercial_for_sale_display_on_website: yup.boolean(),
  listings_commercial_for_sale_feed_to_portals: yup.boolean(),
  listings_commercial_for_sale_can_select_featured: yup.boolean(),
  listings_commercial_for_sale_select_newsletter_featured: yup.boolean(),
  listings_commercial_to_let_view: yup.boolean(),
  listings_commercial_to_let_view_own: yup.boolean(),
  listings_commercial_to_let_update: yup.boolean(),
  listings_commercial_to_let_update_own: yup.boolean(),
  listings_commercial_to_let_export: yup.boolean(),
  listings_commercial_to_let_reports: yup.boolean(),
  listings_commercial_to_let_display_on_website: yup.boolean(),
  listings_commercial_to_let_feed_to_portals: yup.boolean(),
  listings_commercial_to_let_can_select_featured: yup.boolean(),
  listings_commercial_to_let_select_newsletter_featured: yup.boolean(),
  listings_holiday_view: yup.boolean(),
  listings_holiday_view_own: yup.boolean(),
  listings_holiday_update: yup.boolean(),
  listings_holiday_update_own: yup.boolean(),
  listings_holiday_export: yup.boolean(),
  listings_holiday_reports: yup.boolean(),
  listings_holiday_display_on_website: yup.boolean(),
  listings_holiday_feed_to_portals: yup.boolean(),
  listings_holiday_can_select_featured: yup.boolean(),
  listings_holiday_select_newsletter_featured: yup.boolean(),
  listings_projects_view: yup.boolean(),
  listings_projects_view_own: yup.boolean(),
  listings_projects_update: yup.boolean(),
  listings_projects_update_own: yup.boolean(),
  listings_projects_add: yup.boolean(),
  listings_projects_export: yup.boolean(),
  listings_projects_reports: yup.boolean(),
  listings_projects_display_on_website: yup.boolean(),
  listings_projects_can_select_featured: yup.boolean(),
  listings_projects_select_newsletter_featured: yup.boolean(),
  listings_projects_view_notes: yup.boolean(),
  listings_projects_add_notes: yup.boolean(),
  users_email_stationery: yup.boolean(),
  users_email_stationery_own: yup.boolean(),
  offers_view: yup.boolean(),
  offers_view_own: yup.boolean(),
  mailing_list_view: yup.boolean(),
  mailing_list_view_own: yup.boolean(),
  mailing_list_edit: yup.boolean(),
  contacts_view: yup.boolean(),
  contacts_view_own: yup.boolean(),
  contacts_edit: yup.boolean(),
  profiles_edit: yup.boolean(),
  profiles_view: yup.boolean(),
  profiles_view_own: yup.boolean()
}).test('own_perms', value => { // Ensure own perms and all pers are distinct
  const inner = []
  Object.keys(value).forEach(key => {
    if (key.endsWith('_own')) {
      const own = value[key]
      const all = value[key.replace('_own', '')]
      if (own && all) {
        inner.push({
          path: key,
          message: messages.choice
        })
      }
    }
  })
  if (inner.length) { return new yup.ValidationError({ path: 'groups', inner }) }
  return true
}).test(
  'contacts_view_unassigned',
  schema => {
    const { contacts_view, contacts_view_unassigned } = schema
    if (contacts_view && contacts_view_unassigned) {
      return new yup.ValidationError('Contacts view includes unassigned contacts.', contacts_view, 'contacts_view')
    }
    return true
  }
)
