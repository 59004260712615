/* eslint-disable new-cap */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import Notifier from '../components/Notifier'
import { NOTICES } from '../selectors'
import { dismissNotice, autosaveApply, autosaveDiscard } from '../actions'


const mapStateToProps = state => {
  const notices = NOTICES(state)
  return ({
    notices
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ dismissNotice, autosaveApply, autosaveDiscard }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Notifier))
