import React from 'react'
import PropTypes from 'prop-types'


const Tag = ({ value, className }) => (value ? (
  <span className={`tag ${className ? className : ''}`}>{value}</span>
) : (null))

Tag.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string
}

export default Tag
