import React from 'react'
import PropTypes from 'prop-types'
import FocalPoint from './FocalPoint'


const defaultProps = {
  active: true,
  className: 'focus-trap',
  onExit: () => {}
}


const FocusTrap = ({ active, className, children, element, nobackground, focalStyles, onExit, dialog, forwardRef }) => {
  const triggerOnExit = e => {
    if (dialog) { onExit(e) }
  }

  const keyUp = e => {
    if (e.key === 'Escape') {
      triggerOnExit()
    }
  }

  if (!active) { return null }
  return (
    <div ref={forwardRef} className={`${className}-wrapper`} onKeyUp={keyUp}> {/* This facilitates the escape key function */}
      <div
        aria-hidden="true"
        className={nobackground ? `${className}-backdrop nobackground` : `${className}-backdrop`}
        onClick={triggerOnExit}
        onContextMenu={triggerOnExit}
      /> {/* The backdrop facilitates the click off and close function */}
      <FocalPoint className={className} element={element} focalStyles={focalStyles}>
        {children}
      </FocalPoint>
    </div>
  )
}

FocusTrap.defaultProps = defaultProps

FocusTrap.propTypes = {
  dialog: PropTypes.bool,
  active: PropTypes.bool,
  focalStyles: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  forwardRef: PropTypes.func,
  onExit: PropTypes.func,
  nobackground: PropTypes.bool
}

export default FocusTrap
