import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'

import { breakpoint } from '../../../utils'
import LeadIndex from './LeadIndex'
import LeadInteractions from './LeadInteractions'


class Leads extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      showActions: breakpoint.matches,
      loading: false,
      init: false,
      more: false,
      open: false,
      addPerm: [],
      viewPerm: [],
      notes: [],
      term: '',
      searching: false,
      searched: false,
      activity: false,
      lead: null,
      leads: [],
      fetch: false
    }
    this.load = null
    this.setLead = this.setLead.bind(this)
    this.setLeads = this.setLeads.bind(this)
    this.fetchAgain = this.fetchAgain.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
  }

  componentDidMount() {
    this.root = document.getElementById('wrapper')
    breakpoint.addEventListener('change', this.toggleActions)
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  fetchAgain() {
    this.setState({ fetch: true })
  }

  setLead(lead) {
    this.setState({ lead, fetch: false })
  }

  setLeads(leads) {
    this.setState({ leads })
  }

  render() {
    return (
      <>
        <LeadIndex
          user={this.props.user}
          actions={{ ...this.props.actions, setLeads: this.setLeads, setLead: this.setLead }}
          config={this.props.config}
          match={this.props.match}
          model={this.props.model}
          cache={this.props.cache}
          fetch={this.state.fetch}
          visible={((!this.state.showActions && !getIn(this.props, 'match.params.record_id')) || this.state.showActions)}
        />
        {this.state.showActions || getIn(this.props, 'match.params.record_id') ? (
          <LeadInteractions
            user={this.props.user}
            actions={{
              ...this.props.actions,
              setLead: this.setLead,
              setLeads: this.setLeads,
              fetchAgain: this.fetchAgain
            }}
            config={this.props.config}
            match={this.props.match}
            model={this.props.model}
            cache={this.props.cache}
            lead={getIn(this.props, 'match.params.record_id') && this.state.lead ? this.state.lead : null}
            modelname={this.props.config.modelname}
          />
        ) : null}
      </>
    )
  }
}

Leads.propTypes = {
  leads: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  agents: PropTypes.object,
  match: PropTypes.object,
  model: PropTypes.object,
  actions: PropTypes.object,
  fetchOne: PropTypes.func,
  fetchMany: PropTypes.func,
  fetchLeads: PropTypes.func
}

export default Leads
