import { $applyNodeReplacement, createEditor, DecoratorNode, $getRoot } from 'lexical'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import isEqual from 'react-fast-compare'


import * as React from 'react'
import { Suspense } from 'react'
import { htmlSerializationConfig } from '../config'


const InlineImageComponent = React.lazy(
  // @ts-ignore
  () => import('./InlineImageComponent.js')
)

function convertInlineImageElement(domNode) {
  if (domNode) {
    const img = domNode.querySelector('img')
    const { alt: altText, src } = img
    const caption = domNode.querySelector('figcaption')?.innerHTML

    const width = img.getAttribute('width')
    const height = img.getAttribute('height')

    let position
    if (domNode.classList.contains('position-left')) {
      position = 'left'
    } else if (domNode.classList.contains('position-right')) {
      position = 'right'
    } if (domNode.classList.contains('position-full')) {
      position = 'full'
    }

    // eslint-disable-next-line no-use-before-define
    const node = $createInlineImageNode({ altText, height, src, width, showCaption: !!caption, position })
    if (caption) {
      const nestedEditor = node.__caption
      nestedEditor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(
          `<p>${caption}</p>`,
          'text/html'
        )
        const nodes = $generateNodesFromDOM(nestedEditor, dom)
        const root = $getRoot()
        root.append(...nodes)
      })
    }
    return { node }
  }
  return null
}
export class InlineImageNode extends DecoratorNode {
  static getType() {
    return 'inline-image'
  }

  static clone(node) {
    return new InlineImageNode(
      node.__src,
      node.__altText,
      node.__position,
      node.__width,
      node.__height,
      node.__showCaption,
      node.__caption,
      node.__key
    )
  }

  static importJSON(serializedNode) {
    const {
      altText,
      height,
      width,
      caption,
      src,
      showCaption,
      position
    } = serializedNode
    // eslint-disable-next-line no-use-before-define
    const node = $createInlineImageNode({
      altText,
      height,
      showCaption,
      src,
      width,
      position
    })
    const nestedEditor = node.__caption
    const editorState = nestedEditor.parseEditorState(caption.editorState)
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState)
    }
    return node
  }

  static importDOM() {
    return {
      figure: () => ({
        conversion: element => convertInlineImageElement(element),
        priority: 0
      })
    }
  }

  constructor(
    src,
    altText,
    position,
    width,
    height,
    showCaption,
    caption,
    key
  ) {
    super(key)
    this.__src = src
    this.__altText = altText
    this.__width = width || 'auto'
    this.__height = height || 'auto'
    this.__showCaption = showCaption || false
    this.__caption = caption || createEditor({
      html: htmlSerializationConfig
    })
    this.__position = position
  }

  exportDOM() {
    const img = document.createElement('img')
    img.setAttribute('src', this.__src)
    img.setAttribute('alt', this.__altText)
    img.setAttribute('width', this.__width.toString())
    img.setAttribute('height', this.__height.toString())
    img.setAttribute('style', 'display: block;')

    const caption = document.createElement('figcaption')
    const captionState = this.__caption.getEditorState()
    captionState.read(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(
        $generateHtmlFromNodes(this.__caption, null),
        'text/html'
      )
      caption.append(...(dom.body.firstChild?.childNodes ?? []))
    })

    const element = document.createElement('figure')
    element.className = `inline-image ${this.__position ? `position-${this.__position}` : ''}`
    element.append(img, caption)
    return { element }
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      height: this.__height,
      showCaption: this.__showCaption,
      src: this.getSrc(),
      type: 'inline-image',
      version: 1,
      width: this.__width,
      position: this.__position
    }
  }

  getSrc() {
    return this.__src
  }

  getAltText() {
    return this.__altText
  }

  setAltText(altText) {
    const writable = this.getWritable()
    writable.__altText = altText
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable()
    writable.__width = width
    writable.__height = height
  }

  getWidthAndHeight() {
    return { width: this.getWidth(), height: this.getHeight() }
  }

  getWidth() {
    return this.__width
  }

  getHeight() {
    return this.__height
  }

  getShowCaption() {
    return this.__showCaption
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable()
    writable.__showCaption = showCaption
  }

  getPosition() {
    return this.__position
  }

  setPosition(position) {
    const writable = this.getWritable()
    writable.__position = position
  }

  update(payload) {
    const writable = this.getWritable()
    const { altText, showCaption, position, width, height } = payload
    if (altText !== undefined) {
      writable.__altText = altText
    }
    if (showCaption !== undefined) {
      writable.__showCaption = showCaption
    }
    if (position !== undefined) {
      writable.__position = position
    }
    if (width !== undefined) {
      writable.__width = width || 'auto'
    }
    if (height !== undefined) {
      writable.__height = height || 'auto'
    }
  }

  // View

  createDOM() {
    const element = document.createElement('figure')
    const className = `inline-image ${this.__position ? `position-${this.__position}` : ''}`
    if (className) {
      element.className = className
    }
    return element
  }

  updateDOM(prevNode, dom) {
    const position = this.__position
    if (position !== prevNode.__position) {
      const className = `inline-image ${this.__position ? `position-${this.__position}` : ''}`
      if (className) {
        dom.className = className
      }
    }
    const { width, height } = this.getWidthAndHeight()
    if (!isEqual(prevNode.getWidthAndHeight(), { width, height })) {
      const img = dom.querySelector('img')
      img.setAttribute('width', this.__width.toString())
      img.setAttribute('height', this.__height.toString())
      img.setAttribute('style', 'display: block;')
    }
    return false
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <InlineImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          position={this.__position}
        />
      </Suspense>
    )
  }
}

export function $createInlineImageNode({
  altText,
  position,
  height,
  src,
  width,
  showCaption,
  caption,
  key
}) {
  return $applyNodeReplacement(
    new InlineImageNode(
      src,
      altText,
      position,
      width,
      height,
      showCaption,
      caption,
      key
    )
  )
}

export function $isInlineImageNode(node) {
  return node instanceof InlineImageNode
}
