import PropTypes from 'prop-types'
import React from 'react'

import { valueFormat } from '../../utils'
import UserPhoto from './UserPhoto'


class SingleUser extends React.Component {
  componentDidMount() {
    if (this.props.fetchOne) {
      if (!this.props.model) {
        this.props.fetchOne('agents', this.props.userid) // Get the user model if not defined
      } else if (this.props.model.designation && this.props.cache) { // Sometimes we will not show designation
        if (!this.props.cache[this.props.model.designation]) { // If this agents designation is not cached...
          this.props.fetchOne('designations', this.props.model.designation) // Fetch it
        }
      }
    }
  }

  render() {
    const { model, details } = this.props
    return (
      <div className="single-user agent">
        <div>
          {model &&
            <UserPhoto {...this.props} />
          }
          {details &&
            <div>
              <h6>{model.first_name} {model.last_name}</h6>
              <div>{model.cell_number}</div>
              <div>{valueFormat('mailto', model.email)}</div>
            </div>
          }
        </div>
      </div>
    )
  }
}

SingleUser.propTypes = {
  idx: PropTypes.number,
  details: PropTypes.bool,
  designation: PropTypes.bool,
  small: PropTypes.bool,
  userid: PropTypes.number,
  cache: PropTypes.object,
  model: PropTypes.object,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  fetchOne: PropTypes.func
}

export default SingleUser
