import PropTypes from 'prop-types'
import React from 'react'

import EmptyRow from './EmptyRow'
import Row from './Row'


const Body = ({ model, cache, config, columns, tableconfig, user, portals, domains, contextMenu, doubleClick,
  selected, selectable, selectOne, updateModel, currency, region }) => {
  const outer = []
  const { modelname } = config
  if (model && model.index.length) {
    model.index.forEach((key, ridx) => { // Loop over model items
      const row = cache[key]
      outer.push(React.createElement(Row, {
        key: ridx,
        ridx,
        row,
        config,
        portals,
        domains,
        tableconfig,
        user,
        selected,
        selectable,
        selectOne,
        updateModel,
        currency,
        region,
        contextMenu,
        doubleClick,
        model,
        modelname
      }))
    })
  } else {
    outer.push(
      React.createElement(EmptyRow, {
        modelname: config.modelname,
        columns,
        plural: config.plural,
        key: 'empty-row-key'
      })
    )
  }
  return (
    <tbody>{outer}</tbody>
  )
}

Body.propTypes = {
  selected: PropTypes.array,
  config: PropTypes.object,
  tableconfig: PropTypes.array,
  columns: PropTypes.array,
  cache: PropTypes.object,
  portals: PropTypes.object,
  domains: PropTypes.array,
  model: PropTypes.object,
  selectOne: PropTypes.func,
  updateModel: PropTypes.func,
  user: PropTypes.object,
  selectable: PropTypes.bool,
  redirectSchema: PropTypes.func,
  currency: PropTypes.string,
  contextMenu: PropTypes.bool,
  doubleClick: PropTypes.bool,
  region: PropTypes.string
}

export default Body
