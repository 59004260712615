import * as yup from 'yup'
import messages from './messages.json'


export const franchises = () => yup.object({
  name: yup.string().trim().required(messages.required),
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  email: yup.string().email(messages.email).required(messages.required),
  country_code: yup.string().max(10).matches(/^[.\d() +-]+$/, { message: 'Select a valid option' }).required(messages.required),
  telephone_number: yup.string().trim().matches(/^[\d() +-]+$/,
    { message: messages.phone }).required(messages.required),
  fax_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  physical_address: yup.string().trim().required(messages.required),
  postal_address: yup.string().trim().required(messages.required),
  region: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  location: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  website_url: yup.string().url(messages.url).nullable(),
  blog_url: yup.string().url(messages.url).nullable(),
  twitter_url: yup.string().url(messages.url).nullable(),
  facebook_url: yup.string().url(messages.url).nullable(),
  linkedin_url: yup.string().url(messages.url).nullable(),
  youtube_url: yup.string().url(messages.url).nullable(),
  pinterest_url: yup.string().url(messages.url).nullable(),
  instagram_url: yup.string().url(messages.url).nullable()
})
