import React from 'react'

import packageinfo from '../../package.json'


const Footer = () => (
  <div className="footer">
    <div><a href="https://support.propdata.net/privacy" target="_blank" rel="noopener noreferrer">Privacy</a> /&nbsp;</div>
    <div><a href="https://www.propdata.net/legal/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> /&nbsp;</div>
    <div>v. {packageinfo.version} &copy; Prop Data {new Date().getFullYear()}</div>
  </div>
)


export default Footer
