import classNames from 'classnames'
import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import HelpText from './../../HelpText'
import Label from './Label'


const TimeInput = ({
  field: {
    name,
    value
  },
  form: {
    touched,
    ...form
  },
  label,
  className,
  type,
  disabled,
  readonly,
  id,
  prefix,
  suffix,
  after,
  help,
  // eslint-disable-next-line no-unused-vars
  error,
  autoComplete,
  ...props
}) => {
  const elm = useRef(null)
  const [ formattedValue, setFormattedValue ] = useState('00:00:00')


  const getSecondsFromHHMMSS = val => {
    const [ hrs, mins, secs ] = val.split(':')

    const hours = Number(hrs)
    const minutes = Number(mins)
    const seconds = Number(secs)

    if (!isNaN(hours) && isNaN(minutes) && isNaN(seconds)) {
      return hours
    }

    if (!isNaN(hours) && !isNaN(minutes) && isNaN(seconds)) {
      return hours * 60 + minutes
    }

    if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
      return hours * 60 * 60 + minutes * 60 + seconds
    }

    return 0
  }

  const toHHMMSS = secs => {
    const secNum = parseInt(secs.toString(), 10)
    const hours = Math.floor(secNum / 3600)
    const minutes = Math.floor(secNum / 60) % 60
    const seconds = secNum % 60
    return [ hours, minutes, seconds ]
      .map(val => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== '00' || index > 0)
      .join(':')
      .replace(/^0/, '')
  }

  const onBlur = event => {
    const val = event.target.value
    let seconds = null
    if (val.includes(':')) {
      seconds = Math.max(0, getSecondsFromHHMMSS(val))
    } else {
      seconds = Number(val)
    }
    const time = toHHMMSS(seconds)
    setFormattedValue(time)
    form.setFieldTouched(event.target.name)
  }

  const onChange = event => {
    setFormattedValue(event.target.value)
    let seconds = null
    if (event.target.value.includes(':')) {
      seconds = Math.max(0, getSecondsFromHHMMSS(event.target.value))
    } else {
      seconds = Number(event.target.value)
    }
    form.setFieldValue(event.target.name, seconds).then(() => {
      form.setFieldTouched(event.target.name)
    })
  }

  if (formattedValue === '00:00:00' && value) {
    const val = toHHMMSS(value)
    setFormattedValue(val)
  }

  const input = (
    <div id={id} className={`form-group ${name} ${props.classes ? props.classes : ''}`}>
      <Label htmlFor={`input-${id}`} className='formlabel'>{label}</Label>
      <div className='forminput'>
        {prefix}
        <input
          autoComplete={autoComplete}
          id={`input-${id}`}
          name={name}
          type={type}
          ref={elm}
          disabled={disabled}
          readOnly={readonly}
          placeholder={props.placeholder}
          className={classNames('form-control', { 'input-group-prefix': !!prefix, 'input-group-suffix': !!suffix }, className)}
          onBlur={onBlur}
          onChange={onChange}
          value={formattedValue}
          maxLength={props.maxLength}
        />
        {suffix}
        <div className="input-group-addon btn btn-icon-24 btn-none"><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-Clock" /></svg></div>
      </div>
      {after}
      {help &&
                <span className='help-text'>
                  {help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
                </span>
      }
      { // eslint-disable-next-line no-nested-ternary
        getIn(form.errors, name) ? (
          <ErrorMessage name={name} render={msg => <div className='error'>{msg.replace(name, label)}</div>} />
        ) : (
          error && error[name] && touched[name] ? (
            <div className='error'>{error[name]}</div>
          ) : null
        )}
    </div>
  )
  return input
}

TimeInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  autoComplete: PropTypes.bool,
  after: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  help: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  show_search: PropTypes.bool,
  form_el: PropTypes.any,
  _value: PropTypes.any,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}
export default TimeInput
