
/* eslint-disable new-cap */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { PORTALS, MINUSER } from '../../selectors'
import { hasPermission, valueFormat, buildOptionLabel } from '../../utils'
import Card from '../common/Card'
import AsyncInlineSelect from '../common/forms/inputs/AsyncInlineSelect'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import { Button } from '../ui/Button'
import withDelay from './withDelay'


class CustomOption extends React.Component {
  render() {
    const { head, sub } = this.props.data
    return <components.Option
      {...this.props}
    >
      <div className="customopt">
        <div>
          {head}
          <span className="sub">{sub}</span>
        </div>
      </div>
    </components.Option>
  }
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class UAESyndicationWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      prev_count: 0,
      negative: false,
      branches: [],
      branch_options: [],
      agents: [],
      active_portals: [],
      portal_stats: {},
      show_agents: true,
      branch_id: props.branches && props.branches.length === 1 ? props.branches[0] : '',
      agent_id: '',
      dubizzle: 'dubizzle-res',
      bayut: 'bayut-res',
      property_finder: 'property-finder-res',
      houza: 'houza-res',
      loading: true,
      hide: false
    }
    this.filterLogs = this.filterLogs.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    this._is_mounted = true
    const { actions, user, portals } = this.props
    if (portals) {
      const active_portals = Object.keys(portals).map(pid => portals[pid]).filter(p => [ 'Bayut', 'Property Finder', 'Dubizzle', 'Houza' ].includes(p.meta.portal.name))
      if (!active_portals.length) {
        this.setState({ hide: true })
      } else {
        this.setState({ active_portals })
      }
    }
    let branches
    if (!branches || branches.length > 1) {
      let agent_branches = []
      if (!hasPermission([
        'apply_to_all_branches'
      ], user.permissions, null, user.agent.id)) {
        agent_branches = [ ...user.agent.branches ]
      }
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'branches',
          optionvalue: 'id',
          optionlabel: 'name',
          fields: [ 'id', 'name' ],
          active: 1,
          all: true,
          select: true,
          params: {
            id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
            order_by: 'name'
          },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => {
        if (this._is_mounted) {
          const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
          this.setState({ branches: r.options, branch_options })
        }
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
    }
    let agent_id = this.state.agent_id
    if (user.agent) {
      if (!agent_id && getIn(user, 'agent.id')) {
        agent_id = user.agent.id
      }
    }
    if (agent_id !== this.state.agent_id) {
      this.setState({ agent_id })
    }
    this.filterLogs()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branch_id !== this.state.branch_id
      || prevState.agent_id !== this.state.agent_id
      || !isEqual(prevState.active_portals, this.state.active_portals)) {
      this.filterLogs()
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  filterLogs() {
    const { actions } = this.props
    const { branch_id, active_portals, agent_id } = this.state
    const params = {
      active: 1,
      order_by: '-modified',
      get_count: 1
    }
    if (branch_id) {
      params.listing__branch = branch_id
    }
    if (agent_id) {
      params.listing__agent = agent_id
    }
    const counts = active_portals.map(p => new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'portal-configs',
        endpoint: {
          read: '/listings/api/v1/portal-configs/listing-counts'
        },
        params: {
          ...params,
          portal: p.portal
        },
        signal: this.AbortController.signal
      },
      resolve,
      reject
    })).then(result => ({ portal: p.portal, counts: result })).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }))

    Promise.allSettled(counts.flat()).then(results => {
      const portal_stats = {}
      results.forEach(result => {
        if (!result.value) { return }
        portal_stats[result.value.portal] = result.value.counts
      })
      if (this._is_mounted) {
        this.setState({ portal_stats })
      }
    })
  }

  render() {
    const {
      branches,
      branch_id,
      hide,
      active_portals,
      portal_stats,
      dubizzle,
      bayut,
      property_finder,
      houza
    } = this.state
    const { user } = this.props

    if (hide) {
      return null
    }

    const bt = active_portals.find(p => p.meta.portal.name === 'Bayut')

    const pf = active_portals.find(p => p.meta.portal.name === 'Property Finder')

    const dz = active_portals.find(p => p.meta.portal.name === 'Dubizzle')

    const hz = active_portals.find(p => p.meta.portal.name === 'Houza')

    const init_params = {
      active: 1,
      order_by: '-modified'
    }
    if (branch_id) {
      init_params.residential__branch__or = branch_id
      init_params.commercial__branch__or = branch_id
      init_params.project__branch__or = branch_id
      init_params.holiday__branch__or = branch_id
    }
    return (
      <Card
        id="uae-syndication-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding syndication-widget"
        background
        header={
          <>
            <h3>Syndication</h3>
            <div className="details-section-buttons min-flex tablemeta">
              <div className="filter-branch">
                <InlineSelect
                  id="branch_id"
                  name="branch_id"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                  defaultValue={{ label: 'All Branches', value: '' }}
                  selectedValue={getIn(this.state, 'branch_id')}
                  onChange={e => {
                    this.setState({ branch_id: e.value })
                  }}
                />
              </div>
              {this.state.show_agents ? (
                <div className="filter-agent">
                  <AsyncInlineSelect
                    id="agent_id"
                    name="agent_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    defaultValue={{ first_name: 'All', last_name: 'Agents', id: '' }}
                    options={user.agent.id ? [ { first_name: 'All', last_name: 'Agents', id: '' }, { ...user.agent } ] : [ { first_name: 'All', last_name: 'Agents', id: '' } ]}
                    form={{}}
                    modelname="agents"
                    labelseparator=" "
                    fetchMany={this.props.actions.fetchMany}
                    optionlabel={[ 'first_name', 'last_name' ]}
                    noclear
                    params={{
                      branches__overlap: branch_id ? [ branch_id ] : branches.map(b => b.id),
                      active: 1,
                      order_by: 'first_name,last_name',
                      fields: 'id,first_name,last_name'
                    }}
                    field={{
                      value: getIn(this.state, 'agent_id')
                    }}
                    onChange={e => {
                      const agent_id = e.value
                      this.setState({ agent_id })
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        }
        body={
          <div className={classNames('flex-container', 'flex-4', 'gap-40', 'justify-content-center')}>
            {bt ? (
              <>
                <div className='uae-syndication-summary bayut'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/bayut.png`} alt="Property 24" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-res">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ bayut: 'bayut-res' })
                        }} className={classNames({ active: bayut === 'bayut-res' })}>
                          <strong>Residential</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${bt.portal}.residential`))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-com">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ bayut: 'bayut-com' })
                        }} className={classNames({ active: bayut === 'bayut-com' })}>
                          <strong>Commercial</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${bt.portal}.commercial`))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="uae-list-actions">
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(bt, 'portal')}&portals__active=1`}>View residental</Button>
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(bt, 'portal')}&portals__active=1`}>View commercial</Button>
                  </div>
                </div>
              </>
            ) : null}
            {pf ? (
              <>
                <div className='uae-syndication-summary property-finder'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/property-finder.png`} alt="Bayut" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-res">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ property_finder: 'property-finder-res' })
                        }} className={classNames({ active: property_finder === 'property-finder-res' })}>
                          <strong>Residential</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${pf.portal}.residential`))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-com">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ property_finder: 'property-finder-com' })
                        }} className={classNames({ active: property_finder === 'property-finder-com' })}>
                          <strong>Commercial</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${pf.portal}.commercial`))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="uae-list-actions">
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(pf, 'portal')}&portals__active=1`}>View residental</Button>
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(pf, 'portal')}&portals__active=1`}>View commercial</Button>
                  </div>
                </div>
              </>
            ) : null}
            {dz ? (
              <>
                <div className='uae-syndication-summary dubizzle'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/dubizzle.png`} alt="Dubizzle" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-res">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ dubizzle: 'dubizzle-res' })
                        }} className={classNames({ active: dubizzle === 'dubizzle-res' })}>
                          <strong>Residential</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${dz.portal}.residential`))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-com">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ dubizzle: 'dubizzle-com' })
                        }} className={classNames({ active: dubizzle === 'dubizzle-com' })}>
                          <strong>Commercial</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${dz.portal}.commercial`))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="uae-list-actions">
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(dz, 'portal')}&portals__active=1`}>View residental</Button>
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(dz, 'portal')}&portals__active=1`}>View commercial</Button>
                  </div>
                </div>
              </>
            ) : null}
            {hz ? (
              <>
                <div className='uae-syndication-summary houza'>
                  <div className="syndication-meta">
                    <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/houza.png`} alt="HouZa" /></div>
                    <div className="syndication-counts">
                      <div className="syndication-count syndication-res">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ houza: 'houza-res' })
                        }} className={classNames({ active: houza === 'houza-res' })}>
                          <strong>Residential</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${hz.portal}.residential`))}</div>
                        </Button>
                      </div>
                      <div className="syndication-count syndication-com">
                        <Button type="button" onClick={e => {
                          e.preventDefault()
                          this.setState({ houza: 'houza-com' })
                        }} className={classNames({ active: houza === 'houza-com' })}>
                          <strong>Commercial</strong>
                          <div>{valueFormat('number', getIn(portal_stats, `${hz.portal}.commercial`))}</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="uae-list-actions">
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(hz, 'portal')}&portals__active=1`}>View residental</Button>
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(hz, 'portal')}&portals__active=1`}>View commercial</Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        }
      />
    )
  }
}


UAESyndicationWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  portals: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const portals = PORTALS(state)
  return ({
    user,
    portals
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(UAESyndicationWidget)))
