import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $createParagraphNode
} from 'lexical'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $isTableNode } from '@lexical/table'
import {
  $isCodeNode,
  CODE_LANGUAGE_MAP
} from '@lexical/code'

import PropTypes from 'prop-types'
import {
  $isParentElementRTL,
  $isAtNodeEnd,
  $setBlocksType
} from '@lexical/selection'
import { $getNearestNodeOfType, $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from '@lexical/list'
import { createPortal } from 'react-dom'
import {
  $createHeadingNode,
  $isHeadingNode,
  $createQuoteNode
} from '@lexical/rich-text'

import useModal from '../hooks/useModal'
import { Button } from '../../../../../ui/Button'
import { breakpoint } from '../../../../../../utils'
import { InsertInlineImageDialog } from './InlineImagePlugin'


const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote'
}

const LowPriority = 1

function Divider() {
  return <div className="divider" />
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = '0'
    editor.style.top = '-1000px'
    editor.style.left = '-1000px'
  } else {
    editor.style.opacity = '1'
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`
    editor.style.left = `${
      rect.left + window.pageXOffset
    }px`
    const inner = editor.getBoundingClientRect()
    if (inner.right > window.innerWidth) {
      editor.style.left = `${rect.right - inner.width}px`
    }
  }
}


function getSelectedNode(selection) {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode
}

function FloatingLinkEditor({ editor, toolbar }) {
  const editorRef = useRef(null)
  const inputRef = useRef(null)
  const mouseDownRef = useRef(false)
  const [ linkUrl, setLinkUrl ] = useState('')
  const [ target, setTarget ] = useState('_self')
  const [ isEditMode, setEditMode ] = useState(false)
  const [ lastSelection, setLastSelection ] = useState(null)

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL())
        setTarget(parent.getTarget())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
        setTarget(node.getTarget())
      } else {
        setLinkUrl('')
        setTarget('_self')
      }
    }

    const editorElem = editorRef.current
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (editorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0)
      let rect
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement
        while (inner.firstElementChild !== null) {
          inner = inner.firstElementChild
        }
        rect = inner.getBoundingClientRect()
      } else {
        rect = domRange.getBoundingClientRect()
      }
      if (window.innerWidth < 780 || !rect) {
        rect = toolbar.current.getBoundingClientRect()
      }
      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect)
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null)
      setLastSelection(null)
      setEditMode(false)
      setLinkUrl('')
    }
  }, [ editor ])

  useEffect(() => mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateLinkEditor()
      })
    }),

    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateLinkEditor()
        return true
      },
      LowPriority
    )
  ), [ editor, updateLinkEditor ])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [ editor, updateLinkEditor ])

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [ isEditMode ])

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={event => {
            setLinkUrl(event.target.value)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                    url: linkUrl,
                    target
                  })
                }
                setEditMode(false)
              }
            } else if (event.key === 'Escape') {
              event.preventDefault()
              setEditMode(false)
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <Button
              className="link-edit btn btn-none btn-icon btn-icon-24"
              icon="#icon24-Edit"
              role="button"
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditMode(true)
              }}
            />
          </div>
          <label htmlFor='link-target' className="link-target">
            <input
              id="link-target"
              type='checkbox'
              onChange={event => {
                if (event.target.checked) {
                  setTarget('_blank')
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                    url: linkUrl,
                    target: '_blank'
                  })
                } else {
                  setTarget('_self')
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                    url: linkUrl,
                    target: '_self'
                  })
                }
              }}
              checked={target === '_blank'}
            />
          Open in new tab
          </label>
        </>
      )}
    </div>
  )
}

FloatingLinkEditor.propTypes = {
  editor: PropTypes.object,
  toolbar: PropTypes.object
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

Select.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  config
}) {
  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode())
        }
      })
    }
  }

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h1'))
        }
      })
    } else {
      formatParagraph()
    }
  }

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h2'))
        }
      })
    } else {
      formatParagraph()
    }
  }

  const formatSmallestHeading = () => {
    if (blockType !== 'h3') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h3'))
        }
      })
    } else {
      formatParagraph()
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
  }

  const indentList = () => {
    if ([ 'ul', 'ol' ].includes(blockType)) {
      editor.dispatchCommand(INDENT_CONTENT_COMMAND)
    }
  }

  const outdentList = () => {
    if ([ 'ul', 'ol' ].includes(blockType)) {
      editor.dispatchCommand(OUTDENT_CONTENT_COMMAND)
    }
  }

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createQuoteNode())
      })
    }
  }

  return (
    <>
      <Button
        type="button"
        className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'h1' ? 'active' : ''}`}
        icon="#icon24-Heading1"
        onClick={formatLargeHeading}
      />
      <Button
        type="button"
        className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'h2' ? 'active' : ''}`}
        icon="#icon24-Heading2"
        onClick={formatSmallHeading}
      />
      <Button
        type="button"
        className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'h3' ? 'active' : ''}`}
        icon="#icon24-Heading3"
        onClick={formatSmallestHeading}
      />
      <Button
        type="button"
        className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'ul' ? 'active' : ''}`}
        icon="#icon24-ListAlt"
        onClick={formatBulletList}
      />
      <Button
        type="button"
        className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'ol' ? 'active' : ''}`}
        icon="#icon24-ListNumbers"
        onClick={formatNumberedList}
      />
      <Button
        type="button"
        className={'toolbar-item btn btn-none btn-icon btn-icon-24 spaced'}
        disabled={![ 'ul', 'ol' ].includes(blockType)}
        icon="#icon24-Indent"
        onClick={indentList} />
      <Button
        type="button"
        className={'toolbar-item btn btn-none btn-icon btn-icon-24 spaced'}
        disabled={![ 'ul', 'ol' ].includes(blockType)}
        icon="#icon24-Outdent"
        onClick={outdentList} />
      {config?.blockquote ? (
        <Button
          type="button"
          className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${blockType === 'quote' ? 'active' : ''}`}
          icon="#icon24-Quote"
          onClick={formatQuote} />
      ) : null}
    </>
  )
}

BlockOptionsDropdownList.propTypes = {
  editor: PropTypes.object,
  blockType: PropTypes.string,
  config: PropTypes.object
}

const disableTouchContext = function(event) {
  // disable mobi contextMenu
  if (breakpoint.matches) {
    return true
  }
  event.preventDefault()
  event.stopPropagation()
  const nativeSelection = window.getSelection()
  const range = new Range()

  // find start of selected word
  let wordStart = 0
  for (let i = nativeSelection.anchorOffset; i >= 0; i--) {
    if (nativeSelection.anchorNode.textContent[i] === ' ') {
      break
    }
    wordStart = i
  }

  // selected whole word
  range.setStart(nativeSelection.anchorNode, wordStart)
  range.setEnd(nativeSelection.anchorNode.parentNode, 1)
  nativeSelection.addRange(range)
  return false
}

export default function ToolbarPlugin({ config }) {
  const [ editor ] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [ blockType, setBlockType ] = useState('paragraph')

  const [ activeEditor, setActiveEditor ] = useState(editor)
  const [ modal, showModal ] = useModal()
  const [ isLink, setIsLink ] = useState(false)
  const [ isBold, setIsBold ] = useState(false)
  const [ isItalic, setIsItalic ] = useState(false)
  const [ isUnderline, setIsUnderline ] = useState(false)
  const [ isCode, setIsCode ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ selectedElementKey, setSelectedElementKey ] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [ rootType, setRootType ] = useState('root')
  // eslint-disable-next-line no-unused-vars
  const [ isStrikethrough, setIsStrikethrough ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ isSubscript, setIsSubscript ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ isSuperscript, setIsSuperscript ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ canUndo, setCanUndo ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ canRedo, setCanRedo ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ isRTL, setIsRTL ] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ codeLanguage, setCodeLanguage ] = useState('')

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
            const parent = e.getParent()
            return parent !== null && $isRootOrShadowRoot(parent)
          })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsSubscript(selection.hasFormat('subscript'))
      setIsSuperscript(selection.hasFormat('superscript'))
      setIsCode(selection.hasFormat('code'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      const tableNode = $findMatchingParent(node, $isTableNode)
      if ($isTableNode(tableNode)) {
        setRootType('table')
      } else {
        setRootType('root')
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type)
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage()
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : ''
            )
            return
          }
        }
      }
    }
  }, [ activeEditor ])

  useEffect(() => editor.registerCommand(
    SELECTION_CHANGE_COMMAND,
    (_payload, newEditor) => {
      $updateToolbar()
      setActiveEditor(newEditor)
      return false
    },
    COMMAND_PRIORITY_CRITICAL
  ), [ editor, $updateToolbar ])


  useEffect(() => {
    const rootElement = editor.getRootElement()
    rootElement.addEventListener('contextmenu', disableTouchContext)
    return () => {
      rootElement.removeEventListener('contextmenu', disableTouchContext)
    }
  }, [])

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          setBlockType(type)
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsCode(selection.hasFormat('code'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [ editor ])

  useEffect(() => mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    }),
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar()
        return false
      },
      LowPriority
    )
  ), [ editor, updateToolbar ])


  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [ editor, isLink ])

  return (
    <div className="toolbar" ref={toolbarRef}>
      <>
        <BlockOptionsDropdownList
          editor={editor}
          blockType={blockType}
          config={config}
        />
        <Divider />
      </>
      {
        <>
          <Button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
            }}
            className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${isBold ? 'active' : ''}`}
            icon="#icon24-Bold"
            aria-label="Format Bold"
            title="Format Bold"
          >
          </Button>
          <Button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }}
            className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${isItalic ? 'active' : ''}`}
            icon="#icon24-Italic"
            aria-label="Format Italics"
            title="Format Italics"
          >
          </Button>
          <Button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }}
            className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${isUnderline ? 'active' : ''}`}
            icon="#icon24-Underline"
            aria-label="Format Underline"
            title="Format Underline"
          >
          </Button>
          <Button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')
            }}
            className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${isCode ? 'active' : ''}`}
            icon="#icon24-Code"
            aria-label="Insert Code"
            title="Insert Code"
          >
          </Button>
          <Button
            type="button"
            onClick={insertLink}
            className={`toolbar-item btn btn-none btn-icon btn-icon-24 spaced ${isLink ? 'active' : ''}`}
            icon="#icon24-Hyperlink"
            aria-label="Insert Link"
            title="Insert Link"
          >
          </Button>
          {config?.inline_image ? (
            <Button
              type="button"
              onClick={() => {
                showModal('Insert Inline Image', onClose => (
                  <InsertInlineImageDialog
                    activeEditor={activeEditor}
                    onClose={onClose}
                  />
                ))
              }}
              className={'toolbar-item btn btn-none btn-icon btn-icon-24 spaced'}
              icon="#icon24-Image"
              aria-label="Insert Inline Image"
              title="Insert Inline Image"
            >
            </Button>
          ) : null}
          {isLink &&
            createPortal(<FloatingLinkEditor editor={editor} toolbar={toolbarRef} />, document.body)}
          {' '}
          {modal}
        </>
      }
    </div>
  )
}

ToolbarPlugin.propTypes = {
  config: PropTypes.object
}
