import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Button } from '../../ui/Button'
import { breakpoint } from '../../../utils'
import AdvancedSearch from './AdvancedSearch'
import SimpleSearch from './SimpleSearch'


const Search = props => {
  const [ desktop, setDesktop ] = useState(breakpoint.matches)
  const [ show, setShow ] = useState(false)

  const toggleDesktop = e => {
    if (e.matches) {
      setDesktop(true)
      setShow(false)
    } else {
      setDesktop(false)
    }
  }

  useEffect(() => {
    breakpoint.addEventListener('change', toggleDesktop)
    return () => {
      breakpoint.removeEventListener('change', toggleDesktop)
    }
  }, [ ])

  const classes = `tablesearch-simple${!props.advanced ? ' open' : ''}`
  const advclasses = `tablesearch-advanced${props.advanced ? ' open' : ''}`
  const { modelname } = props.config
  return (
    <div className="tablesearch">
      {desktop || show ? (
        <>
          <div className={classes}>
            <SimpleSearch
              key={`simple-search-${modelname}`}
              user={props.user}
              model={props.model}
              match={props.match}
              config={props.config}
              location={props.location}
              advanced={props.advanced}
              toggleAdvanced={props.toggleAdvanced}
              handleReset={props.handleReset}
            />
          </div>
          {props.config.advsearch && props.advanced &&
          <div className={advclasses}>
            <AdvancedSearch
              key={`adv-search-${modelname}`}
              limit={props.limit}
              model={props.model}
              user={props.user}
              match={props.match}
              config={props.config}
              configs={props.configs}
              cache={props.cache}
              location={props.location}
              toggleAdvanced={props.toggleAdvanced}
              handleReset={props.handleReset}
              actions={props.actions}
            />
          </div>}
          {show ? (
            <div className="search-fields search-buttons">
              <Button
                className="btn btn-grey"
                type="button"
                onClick={() => {
                  setShow(false)
                }}
              >
                Collapse
              </Button>
            </div>
          ) : null }
        </>
      ) : null }
      {!desktop && !show ? (
        <div className="search-fields search-buttons">
          <Button
            className="btn btn-primary btn-icon-16"
            type="button"
            icon="#icon16-Search"
            onClick={() => {
              setShow(true)
            }}
          >
            Search
          </Button>
        </div>
      ) : null}
    </div>
  )
}

Search.propTypes = {
  term: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  limit: PropTypes.number,
  config: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object.isRequired,
  model: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object,
  configs: PropTypes.object,
  doReset: PropTypes.func,
  handleReset: PropTypes.func,
  advanced: PropTypes.bool,
  toggleAdvanced: PropTypes.func
}

export default Search
