/* eslint-disable no-process-env */
import { useFormikContext, Form, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import { getScrollParent, getPaths } from '../../../utils'
import { formikConnect } from './customFormikConnect'

/*
 * Custom wrapper for Formik Form which allows us to set the form context
 * inside the formik render method.
*/


export const ErrorListener = () => {
  const formik = useFormikContext()
  const [ touched, setTouched ] = React.useState(formik.touched)
  const [ submitCount, setSubmitCount ] = React.useState(formik.submitCount)
  const onError = (errors, touch) => {
    if (Object.keys(errors).length) {
      // Show all yup field errors on submit
      const new_touched = { ...touch, ...errors }
      formik.setTouched(new_touched, false)

      // Scroll to first error
      setTimeout(() => {
        let viewport = document.getElementsByClassName('view')[0]
        if (!viewport) {
          viewport = document.getElementsByClassName('wide-sidebar')[0] // Contact creator etc.
        }
        if (viewport) {
          const firstError = viewport.getElementsByClassName('error')[0] ? viewport.getElementsByClassName('error')[0].closest('.field') : false
          if (firstError) {
            const parent = getScrollParent(firstError)
            const box = firstError.getBoundingClientRect()
            const windowTop = parent.scrollTop + (box.top) - 155
            parent.scrollTo(0, windowTop)
          }
        }
      }, 300)
    }
  }
  useEffect(() => {
    if (
      (!formik.isValidating &&
        !formik.isSubmitting &&
        formik.errors &&
        formik.submitCount > submitCount
      ) ||
      (formik.submitCount && !isEqual(formik.touched, touched))
    ) {
      onError(formik.errors, formik.touched)

      setTouched(formik.touched)
      setSubmitCount(formik.submitCount)
    }
  }, [
    formik.errors,
    formik.isSubmitting,
    formik.isValidating,
    formik.submitCount,
    onError,
    submitCount
  ])

  return null
}

export const AutoSaveCheck = props => {
  const { mode, actions, modelname, userid, modelid, form } = props

  useEffect(() => {
    const check_autosave = setTimeout(() => actions.autosaveCheck({
      userid,
      modelname,
      setInitVals: actions.setInitVals,
      form,
      mode,
      modelid
    }),
    4000)
    return () => {
      clearTimeout(check_autosave)
    }
  }, [ ])

  return null
}


AutoSaveCheck.propTypes = {
  form: PropTypes.object,
  mode: PropTypes.string,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  userid: PropTypes.number,
  modelid: PropTypes.number,
  autosave: PropTypes.bool,
  render: PropTypes.func
}

export const OnChangeCheck = props => {
  const { form: formik, onChange } = props
  const [ form, setForm ] = useState(formik)

  useEffect(() => {
    if (!isEqual(form.values, formik.values) || !isEqual(form.touched, formik.touched)) {
      const new_diff = getPaths(formik.values).map(path => path.join('.'))
        .filter(k => !isEqual(getIn(form.values, k), getIn(formik.values, k))
          || !isEqual(getIn(form.touched, k), getIn(formik.touched, k))
        )
      const new_diff_changed = getPaths(formik.values).map(path => path.join('.'))
        .map(k => !isEqual(getIn(form.values, k), getIn(formik.values, k))
          || !isEqual(form.touched, formik.touched)
        ).some(k => k)
      if (onChange && new_diff_changed) {
        onChange(new_diff, formik, form)
      }
      setForm(formik)
    }
  }, [ formik ])

  return null
}

OnChangeCheck.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object
}


const ConnectedAutoSaveCheck = formikConnect(AutoSaveCheck)

const ConnectedOnChangeCheck = formikConnect(OnChangeCheck)

ErrorListener.propTypes = {
  onError: PropTypes.func
}

const CustomForm = props => {
  const Component = props.component || Form

  const triggerKeyboardSave = useCallback(event => {
    if (event.which === 83 && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      if (props.handleSubmit) {
        return props.handleSubmit()
      }
      const save = document.querySelector('.navitem.save')
      save.click()
      return true
    }
    return false
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', triggerKeyboardSave)
    return () => {
      window.removeEventListener('keydown', triggerKeyboardSave)
    }
  }, [])

  const el = useRef(null)
  const formik = useFormikContext()
  return (
    <Component
      id={props.id}
      ref={el}
      className={props.className}
      onSubmit={e => {
        e.stopPropagation()
        e.cancelBubble = true
        if (el.current && el.current.nodeName === 'DIV' && el.current === e.target) {
          setTimeout(() => {
            formik.handleSubmit(e)
          }, 50)
        }
      }}
    >
      { props.actions && props.autosave ? (
        <ConnectedAutoSaveCheck {...props} />
      ) : null
      }
      <ConnectedOnChangeCheck onChange={props.onChange} />
      {props.render(el.current)}
      <ErrorListener />
    </Component>
  )
}

CustomForm.propTypes = {
  onChange: PropTypes.func,
  onError: PropTypes.func,
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  diff: PropTypes.array,
  mode: PropTypes.string,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  component: PropTypes.node,
  userid: PropTypes.number,
  modelid: PropTypes.number,
  autosave: PropTypes.bool,
  render: PropTypes.func
}

export default CustomForm
