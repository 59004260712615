/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'

import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const Marketing = ({ user, modelid, actions, modelname }) => (
  <Card
    bodyclass="alerts-sent no-top-padding"
    background
    header={
      <h3>Marketing</h3>
    }
    body={
      <>
        <SimpleTable
          paginated
          config={{}}
          action={({ params, signal, resolve, reject }) => {
            new Promise((res, rej) => actions.fetchMany({
              noloader: true,
              values: {
                modelname: 'email-message',
                endpoint: {
                  read: `/mashup/api/v1/email-messages/${modelname}/${modelid}/`
                },
                signal,
                params: {
                  ...params,
                  meta_fields: [ 'email', 'category', 'template', 'relations' ]
                }
              },
              resolve: res,
              reject: rej
            })).then(r => {
              resolve(r)
            }).catch(e => {
              reject(e)
            })
          }}
          params={{
            order_by: '-date_sent',
            category__category: 'newsletter'
          }}
          header={[
            {
              label: 'Date Sent',
              name: 'send_date',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Communication Type',
              name: 'meta.category.category',
              orderby: '-category__category',
              orderable: true,
              format: 'title'
            },
            {
              label: 'Template Name',
              name: 'meta.email.meta.template.display_name',
              orderby: '-email__template__display_name',
              orderable: true
            },
            {
              label: 'Branch',
              name: 'meta.relations.branch.0.name',
              link: '/secure/:site/branches/:meta.relations.branch.0.id',
              metafield: true
            },
            {
              label: 'Subject Line',
              name: 'subject',
              orderable: true
            },
            {
              label: 'Status',
              name: 'status',
              classes: [ 'text-right' ],
              orderable: true,
              format: 'title'
            }
          ]}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button
                icon="#icon16-EyeOpen"
                component="a"
                className="btn btn-none btn-icon-only btn-icon-16"
                title="View Details"
                target="_blank"
                href={data.content}
                type="button"
              >
              </Button>
            </>
          )}
        />
      </>
    }
  />
)

Marketing.propTypes = {
  permissions: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  currency: PropTypes.string,
  cache: PropTypes.object,
  alerts: PropTypes.object,
  fetchAlerts: PropTypes.func,
  updateModel: PropTypes.func,
  configs: PropTypes.object,
  user: PropTypes.object,
  modelid: PropTypes.number,
  actions: PropTypes.object,
  settings: PropTypes.object,
  modelname: PropTypes.string
}

export default Marketing
