import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { WhatsappShareButton } from 'react-share'

import country_codes from '../../config/countrycodes.json'
import log from '../../logging'
import { hasPermission, slugify, valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import Loader from './Loader'


const MatchTable = props => (
  <div className="listbody flagged-matches">
    <table className="datatable">
      <tbody>
        {(props.loading) ? (
          <tr>
            <td colSpan="10" align="center">
              <Loader inline />
            </td>
          </tr>
        ) : (
          props.children
        )}
      </tbody>
    </table>
    {props.loading &&
      <div className="text-center">
        <Loader className="large" inline />
      </div>
    }
  </div>
)

MatchTable.propTypes = {
  loading: PropTypes.bool,
  modelname: PropTypes.string,
  children: PropTypes.node
}

class Match extends React.Component {
  constructor(props) {
    super(props)
    this.highlightClicked = this.highlightClicked.bind(this)
    this.alertAgentPropertyLead = this.alertAgentPropertyLead.bind(this)
    this.getContactNumber = this.getContactNumber.bind(this)
  }

  unhighlightClicked(e, profile) {
    if (e.preventDefault) {
      this.props.unhighlightMatch(profile)
    }
  }

  highlightClicked(e, profile) {
    if (e.preventDefault) {
      this.props.highlightMatch(profile)
    }
  }

  alertAgentPropertyLead(event, profile, contactid, modelid, modelname) {
    event.preventDefault()
    const values = {
      profile: profile.id,
      contact: contactid,
      alert_type: 'manual-listing-match',
      status: 'pending'
    }
    const listing_type = `${modelname}`
    values[listing_type] = modelid
    return new Promise((resolve, reject) => {
      this.props.alertAgentPropertyLead({ values, resolve, reject })
    }).then(r => {
      // Notify
      this.props.disableAlerts(profile.meta.contact.email)
      log.debug(`Success: ${r}`)
    }).catch(e => {
      // Notify
      log.error(e)
    })
  }

  getContactNumber() {
    const { profile } = this.props

    let contact_number = profile.meta.contact.cell_number
    if (!contact_number && profile.meta.contact.work_number) {
      contact_number = profile.meta.contact.work_number
    }
    if (!contact_number && profile.meta.contact.home_number) {
      contact_number = profile.meta.contact.home_number
    }
    contact_number = contact_number && contact_number.match(/\d+/g) ? contact_number.match(/\d+/g).join('') : null
    if (contact_number && contact_number.startsWith('0')) {
      const base_country = (profile.meta.areas && profile.meta.areas.length) ? profile.meta.areas[0].country : 'South Africa'
      const country = country_codes.find(c => c.name === base_country)
      contact_number = contact_number.replace('0', country.dial_code)
    }
    return contact_number
  }

  render() {
    const { user, profile, config, model, currency, website_url, enable_popia } = this.props
    const { modelname } = config
    const col = { currency } // Data to pass to valueFormater
    let range_match = false
    let price = false
    let price_from = false
    let price_to = false
    let size = false
    let size_from = false
    let size_to = false
    let range = '-'

    const price_range = valueFormat('price_range', `${profile.price_from} ${profile.price_to}`, col)

    if (modelname === 'residential') {
      price = parseFloat(model.price)
      price_from = parseFloat(profile.price_from)
      price_to = parseFloat(profile.price_to)
      if ((price_from && price_to) && (price >= price_from && price <= price_to)) {
        range_match = true
      } else if (price_from && !price_to && price >= price_from) {
        range_match = true
      } else if (price_to && !price_from && price <= price_to) {
        range_match = true
      }
      range = `${price_range}`
    } else {
      size = parseFloat(model.floor_size)
      size_from = parseFloat(profile.floor_size_from)
      size_to = parseFloat(profile.floor_size_to)
      if ((size_from && size_to) && (size >= size_from && size <= size_to)) {
        range_match = true
      } else if (size_from && !size_to && size >= size_from) {
        range_match = true
      } else if (size_to && !size_from && size <= size_to) {
        range_match = true
      }
      const size_range = valueFormat('size_range', `${size_from} ${size_to}`, col)
      range = `${size_range}`
    }
    let agent = 'Unassigned'
    if (agent === 'Unassigned' && profile.agent && profile.meta.agent) {
      agent = (
        <Button component={NavLink} to={`/secure/agents/${profile.meta.lead.agent}`} title="View Agent">
          {profile.meta.agent.first_name} {profile.meta.agent.last_name}
        </Button>
      )
    }
    if (agent === 'Unassigned' && profile.lead && profile.meta.lead && profile.meta.lead.agent && profile.meta.lead.meta.agent) {
      agent = (
        <Button component={NavLink} to={`/secure/agents/${profile.meta.lead.agent}`} title="View Agent">
          {profile.meta.lead.meta.agent.first_name} {profile.meta.lead.meta.agent.last_name}
        </Button>
      )
    }
    let can_send = false
    let can_view_contact = false
    if (profile.can_send_alert) { // no other alert has been sent to this profile
      if ([ model.agent, model.agent_2, model.agent_3, model.agent_4 ].filter(a => a).includes(user.agent.id)) { // listing belongs to current agent
        if (profile.agent && profile.agent === user.agent.id) { // profile has lead and lead belongs to current agent
          can_send = true
        } else if (profile.lead && profile.meta.lead && profile.meta.lead.agent === user.agent.id) {
          can_send = true
        } else if (
          (!profile.lead || (profile.lead && !profile.meta.lead.agent))
          || (!profile.agent || (profile.agent && !profile.meta.agent))
        ) {
          can_send = true
        }
      } else if (user.permissions.includes('is_prop_data_user')) {
        can_send = true
      } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
        can_send = true
      }
      if (![ 'Active', 'No-Consent' ].includes(profile.status)) {
        can_send = false
      }
    }
    if (profile.lead && profile.meta.lead && profile.meta.lead.agent === user.agent.id) { // profile has lead and lead belongs to current agent
      can_view_contact = true
    } else if (user.permissions.includes('is_prop_data_user')) {
      can_view_contact = true
    } else if ([ 'residential', 'commercial' ].includes(model.model) && hasPermission([ `${config.permprefix}_${slugify(model.listing_type).replace('-', '_')}_view`, 'leads_view' ], user.permissions)) {
      can_view_contact = true
    }
    const contact_number = this.getContactNumber()
    return (
      <tr>
        <td><span className="tablecell">{can_view_contact ? (
          <Button component={NavLink} to={`/secure/contacts/${profile.contact}`} title="View Contact">
            {profile.meta.contact.first_name} {profile.meta.contact.last_name}
          </Button>
        ) : (
          `${profile.meta.contact.first_name} ${profile.meta.contact.last_name}`
        )}</span></td>
        <td><span className="tablecell">{can_view_contact ? (
          valueFormat('tel', profile.meta.contact.cell_number)
        ) : (
          '-'
        )}</span></td>
        <td><span className="tablecell">{range_match ? <svg className="indicator"><use href="/images/icons-16.svg#icon16-Check" /></svg> : null}{range}</span></td>
        <td className="column-actions">
          <div>
            {can_send &&
              <Button
                className="btn btn-grey btn-round btn-icon-16 btn-icon-only"
                icon="#icon16-Send"
                title="Send Alert"
                type="button"
                onClick={e => this.alertAgentPropertyLead(
                  e,
                  profile,
                  profile.meta.contact.id,
                  model.id,
                  profile.listing_model
                )}
              >
              </Button>
            }
            {can_view_contact && contact_number && (enable_popia ? getIn(profile, 'meta.contact.meta.consent.consent_messages') : true) &&
              <Button
                component={WhatsappShareButton}
                icon="#icon16-WhatsApp"
                type="button"
                resetButtonStyle={false}
                className="btn btn-grey btn-round btn-icon-16 btn-icon-only"
                separator=" : "
                url={`${website_url}${model.meta.url.website}`}
                phone={contact_number}
                title={`Property Alert - ${model.web_ref}`}
              >
              </Button>
            }
          </div>
        </td>
      </tr>
    )
  }
}

Match.propTypes = {
  profile: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  highlight: PropTypes.bool,
  highlightMatch: PropTypes.func,
  unhighlightMatch: PropTypes.func,
  disableAlerts: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  currency: PropTypes.string,
  website_url: PropTypes.string,
  enable_popia: PropTypes.bool
}

class FlaggedMatches extends React.Component {
  constructor(props) {
    super(props)
    this.subtractDays = this.subtractDays.bind(this)
    this.disableAlerts = this.disableAlerts.bind(this)
    this.state = {
      offset: 0,
      more: true,
      loading_highlights: true,
      highlights: []
    }
    this._is_mounted = true
  }

  componentDidMount() {
    new Promise((resolve, reject) => this.props.fetchHighlights({ params: { limit: 10 }, resolve, reject }))
      .then(r => {
        if (this._is_mounted) {
          this.setState({ highlights: r.results, loading_highlights: false, more: false })
        }
      })
      .catch(log.error)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  disableAlerts(email) {
    // Any profiles for the same email address should not be allowed to receive
    // another alert for the same listing
    const highlights = this.state.highlights
      .filter(r => r.meta.contact.email.toLowerCase() === email.toLowerCase())
      .map(r => r.id)
    if (this._is_mounted) {
      this.setState({
        highlights: this.state.highlights.map(r => ({ ...r, can_send_alert: !highlights.includes(r.id) }))
      })
    }
  }

  subtractDays(days) {
    const result = new Date()
    result.setDate(result.getDate() - days)
    result.setHours(0, 0, 0, 0)
    return result
  }

  render() {
    const { user, model, settings, config, currency } = this.props
    const { highlights } = this.state
    const webref = config.fields.find(f => f.name === 'web_ref')
    let price = null
    if ([ 'residential', 'commercial' ].includes(config.modelname)) {
      webref.currency = currency
      price = config.fields.find(f => f.name === 'price')
      price.currency = currency
    } else if (config.modelname === 'project') {
      webref.currency = currency
      price = config.fields.find(f => f.name === 'property_types').fields.find(f => f.name === 'priced_from')
      price.currency = currency
    }
    return (
      <div className="btmatches">
        <MatchTable loading={this.state.loading_highlights} modelname={config.modelname}>
          {highlights && highlights.length > 0 ? (
            highlights.map((highlight, midx) =>
              <Match
                key={`highlighted-${midx}`}
                profile={highlight}
                user={user}
                model={model}
                config={config}
                highlight={true}
                enable_popia={settings.enable_popia}
                website_url={settings.website_url}
                unhighlightMatch={this.props.unhighlightMatch}
                alertAgentPropertyLead={this.props.alertAgentPropertyLead}
                disableAlerts={this.disableAlerts}
                currency={currency}
              />
            )
          ) : (
            <tr>
              <td className="no-matches" colSpan={10}><span className="tablecell">You have not highlighted any profiles yet.</span></td>
            </tr>
          )}
        </MatchTable>
      </div>
    )
  }
}

FlaggedMatches.propTypes = {
  currency: PropTypes.string,
  model: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  settings: PropTypes.object,
  cache: PropTypes.object,
  match_count: PropTypes.number,
  loading_matches: PropTypes.bool,
  unhighlightMatch: PropTypes.func,
  highlightMatch: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  fetchProfileMatches: PropTypes.func,
  fetchRecentMatches: PropTypes.func,
  fetchOldMatches: PropTypes.func,
  fetchHighlights: PropTypes.func,
  fetchMatches: PropTypes.func
}

export default FlaggedMatches
