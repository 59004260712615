import * as yup from 'yup'
import messages from './messages.json'


export const branches_archive = () => yup.object({
  action: yup.string().oneOf([ 'reassign', 'archive' ]).required(messages.required),
  assign_to_branch: yup.number().when('action', {
    is: 'reassign',
    then: yup.number().positive().required(messages.required),
    otherwise: yup.number().nullable()
  })
})

export const branches = () => yup.object({
  name: yup.string().trim().required(messages.required),
  active: yup.bool().oneOf([ 1, 0, true, false ], messages.required).nullable().required(messages.required),
  display: yup.bool().oneOf([ 1, 0, true, false, null ], messages.invalid).nullable(),
  email: yup.string().email(messages.email).required(messages.required),
  country_code: yup.string().nullable().max(10).matches(/^[.\d() +-]+$/, messages.invalid).required(messages.required),
  telephone_number: yup.string().trim().matches(/^[\d() +-]+$/,
    { message: messages.phone }).required(messages.required),
  fax_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  coordinates: yup.object().nullable(),
  physical_address: yup.string().trim().required(messages.required),
  postal_address: yup.string().trim().required(messages.required),
  location: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  branch_website: yup.string().url(messages.url).nullable(),
  twitter_url: yup.string().url(messages.url).nullable(),
  facebook_url: yup.string().url(messages.url).nullable(),
  linkedin_url: yup.string().url(messages.url).nullable(),
  youtube_url: yup.string().url(messages.url).nullable(),
  pinterest_url: yup.string().url(messages.url).nullable(),
  instagram_url: yup.string().url(messages.url).nullable(),
  images: yup.array().of(yup.number().positive().integer()).nullable()
})

export const branches_activity = () => yup.object({
  created__date__gte: yup.string().required(messages.required),
  created__date__lte: yup.string().required(messages.required)
})
