import PropTypes from 'prop-types'
import React from 'react'

import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MetaDetail from '../common/MetaDetail'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import AreaGroupsManager from '../common/forms/inputs/AreaGroupsManager'
import RedirectsManager from '../common/forms/inputs/RedirectsManager'
import Tab from '../common/tabs/Tab'
import area_group_config from '../../config/area-group.json'
import redirect_config from '../../config/redirect.json'


const Domain = props => {
  const { model, routeConfig, location, match, user, cache, configs, actions } = props

  return (
    <HorizontalTabs
      config={routeConfig}
      location={location}
      match={match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>

      <Tab tab="search-groups" label="Search Groups" noScroll>
        <AreaGroupsManager
          model={model}
          cache={cache}
          user={user}
          config={area_group_config.config}
          configs={configs}
          location={location}
          fetchMany={actions.fetchMany}
          fetchOne={actions.fetchOne}
          toggleWideSidebar={actions.toggleWideSidebar}
          createModel={actions.createModel}
          updateModel={actions.updateModel}
          deleteModel={actions.deleteModel}
          bulkEditModel={actions.bulkEditModel}
        />
      </Tab>

      <Tab tab="redirects" label="Redirects" noScroll>
        <RedirectsManager
          model={model}
          cache={cache}
          user={user}
          config={redirect_config.config}
          configs={configs}
          location={location}
          fetchMany={actions.fetchMany}
          fetchOne={actions.fetchOne}
          toggleWideSidebar={actions.toggleWideSidebar}
          createModel={actions.createModel}
          updateModel={actions.updateModel}
          deleteModel={actions.deleteModel}
        />
      </Tab>

      <Tab tab="dns" label="DNS" noScroll>
        <div className="model-details">
          <Card
            background
            header={
              <h3>{model.domain}</h3>
            }
            body={
              <>
                {model.enable_staging_site ? (
                  <MetaDetail
                    className="col-lg-12"
                    label={'Staging URL'}
                    value={`${model.domain_slug}.eos4-staging.propdata.net`}
                    url={`https://${model.domain_slug}.eos4-staging.propdata.net`}
                    format='link'
                  />
                ) : null}
                {model.enable_production_site ? (
                  <MetaDetail
                    className="col-lg-12"
                    label={'Production URL'}
                    value={`www.${model.domain}`}
                    url={`https://www.${model.domain}`}
                    format='link'
                  />
                ) : null}
                <MetaDetail
                  className="col-lg-12"
                  label="@ (A Record)"
                  value="107.23.64.217"
                />
                <MetaDetail
                  className="col-lg-12"
                  label={`${model.ssl_validation_name} (CNAME Record)`}
                  value={model.ssl_validation_value}
                />
                <MetaDetail
                  className="col-lg-12"
                  label={`www.${model.domain} (CNAME Record)`}
                  value={model.cloudfront_host}
                />
                {model.meta.theme_id.mobi ? (
                  <MetaDetail
                    className="col-lg-12"
                    label={`m.${model.domain} (CNAME Record)`}
                    value={model.cloudfront_host}
                  />
                ) : null}
                <MetaDetail
                  className="col-lg-12"
                  label={`tracking.${model.domain} (CNAME Record)`}
                  value="api.elasticemail.com"
                />
                <MetaDetail
                  className="col-lg-12"
                  label="SPF (TXT Record)"
                  value="v=spf1 a mx include:_spf.elasticemail.com ~all"
                />
                <MetaDetail
                  className="col-lg-12"
                  label="api._domainkey (TXT Record)"
                  value="k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB"
                />
                <MetaDetail
                  className="col-lg-12"
                  label="_dmarc (TXT Record)"
                  value="v=DMARC1;p=none;"
                />
              </>
            }
          />
          {model.aliases.map(alias => {
            let www_domain = `www.${alias.domain}`
            if (alias.website_url && alias.website_url.startsWith('https://www.')) {
              www_domain = alias.domain
            }
            return (
              <Card
                key={`alias-${alias.domain}`}
                background
                header={
                  <h3>{alias.domain}</h3>
                }
                body={
                  <>
                    <MetaDetail
                      className="col-lg-12"
                      label="@ (A Record)"
                      value="3.231.195.179"
                    />
                    <MetaDetail
                      className="col-lg-12"
                      label={`${www_domain} (CNAME Record)`}
                      value={model.cloudfront_host}
                    />
                    {model.meta.theme_id.mobi ? (
                      <MetaDetail
                        className="col-lg-12"
                        label={`m.${alias.domain} (CNAME Record)`}
                        value={model.cloudfront_host}
                      />
                    ) : null}
                  </>
                }
              />
            )
          })
          }
        </div>
      </Tab>
    </HorizontalTabs>
  )
}

Domain.propTypes = {
  model: PropTypes.object,
  selected: PropTypes.array,
  configs: PropTypes.object,
  config: PropTypes.object,
  routeConfig: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  actions: PropTypes.object
}

export default Domain
