import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import FocusTrap from '../../common/focus-trap/FocusTrap'
import Card from '../../common/Card'
import { Button } from '../../ui/Button'


const Modal = props => {
  const { title, showclose, nobackground, dialog } = props
  const [ visible, setVisible ] = useState(props.visible)
  const [ container, setContainer ] = useState(document.getElementById('root'))

  useEffect(() => {
    if (!container) { setContainer(document.getElementById('root'))}
  }, [])

  useEffect(() => {
    if (props.visible !== visible) { setVisible(props.visible) }
  }, [ props.visible ])

  const closeModal = event => {
    if (props.onClose) {
      props.onClose(event)
    }
    setVisible(false)
  }

  return visible && container ? ReactDOM.createPortal(
    <FocusTrap
      className="modal"
      dialog={dialog}
      nobackground={nobackground}
    >
      <Card
        background={true}
        showtoggles={false}
        body={
          <div>
            { showclose &&
              <Button
                type="button"
                icon="#icon24-X-Large"
                className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                onClick={closeModal}
              />
            }
            {title &&
              <h2>{title}</h2>
            }
            {props.children}
          </div>
        }
        footer={props.buttons}
      />
    </FocusTrap>
    , container) : null
}

Modal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  showclose: PropTypes.bool,
  nobackground: PropTypes.bool,
  dialog: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  buttons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ])
}

export default Modal
