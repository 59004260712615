import React from 'react'
import PropTypes from 'prop-types'

import { generateAddress } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ContactAlerts = ({ modelid, configs, actions, user }) => (
  <Card
    bodyclass="alerts-sent no-top-padding"
    background
    header={
      <h3>Alerts Sent</h3>
    }
    body={
      <SimpleTable
        paginated
        config={configs.alerts}
        action={actions.fetchAlerts}
        params={{
          contact: modelid,
          order_by: '-created',
          limit: 100,
          meta_fields: [ 'residential', 'commercial', 'holiday', 'projects' ]
        }}
        parser={data => {
          data.options = data.options ? data.options.map(d => {
            const alert = { ...d }
            if (alert.residential) {
              alert.listing = alert.residential
              alert.meta.listing = alert.meta.residential
              alert.meta.listing.model = 'residential'
              alert.meta.listing.address = generateAddress(alert.meta.listing)
            }
            if (alert.commercial) {
              alert.listing = alert.commercial
              alert.meta.listing = alert.meta.commercial
              alert.meta.listing.model = 'commercial'
              alert.meta.listing.address = generateAddress(alert.meta.listing)
            }
            if (alert.project) {
              alert.listing = alert.project
              alert.meta.listing = alert.meta.project
              alert.meta.listing.model = 'project'
              alert.meta.listing.address = generateAddress(alert.meta.listing)
            }
            if (alert.holiday) {
              alert.listing = alert.holiday
              alert.meta.listing = alert.meta.holiday
              alert.meta.listing.model = 'holiday'
              alert.meta.listing.address = generateAddress(alert.meta.listing)
            }
            return alert
          }) : null
          return data
        }}
        header={[
          {
            label: 'Date Added',
            name: 'created',
            orderable: true,
            format: 'datetime'
          },
          {
            label: 'Alert Type',
            name: 'alert_type',
            format: 'title'
          },
          {
            label: 'Web Ref',
            name: 'meta.listing.web_ref',
            orderable: false,
            format: 'listing_popup',
            link: '/secure/:site/:meta.listing.model/:meta.listing.id'
          },
          {
            label: 'Address',
            name: 'meta.listing.address',
            orderable: false
          },
          {
            label: 'Profile',
            name: 'profile',
            link: '/secure/:site/profiles/:profile',
            orderable: true
          },
          {
            label: 'On Show Date',
            name: 'on_show_date',
            orderable: true
          },
          {
            label: 'Original Price',
            name: 'original_price',
            classes: [ 'text-right' ],
            orderable: true
          },
          {
            label: 'Reduced Price',
            name: 'reduced_price',
            classes: [ 'text-right' ],
            orderable: true
          }
        ]}
        user={user}
      />
    }
  />
)

ContactAlerts.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number
}

export default ContactAlerts
