import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, getIn } from 'formik'
import classNames from 'classnames'
import isEqual from 'react-fast-compare'
import { Link } from 'react-router-dom'
import { DiffEditor as MonacoDiffEditor } from '@monaco-editor/react'

import Loader from '../../../Loader'
import InlineSelect from '../InlineSelect'
import { Button } from '../../../../ui/Button'
import * as jinja_scss from '../../../../../code-editor-languages/jinja2-scss'
import Card from '../../../Card'


class VersionDiff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: getIn(props.form.values, props.field.name),
      source: false,
      customCSS: null,
      selected_id: parseInt(getIn(props, 'match.params.record_id'), 10),
      side_by_side: true,
      options: {
        selectOnLineNumbers: true,
        renderSideBySide: true
      },
      tab: 'html_content'
    }
    this.editorDidMount = this.editorDidMount.bind(this)
    this.editorWillMount = this.editorWillMount.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    if (getIn(this.props.form.values, this.props.field.name) !== this.state.value) {
      this.setState({ value: getIn(this.props.form.values, this.props.field.name) })
    }
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props
    if (this.props.match.params.record_id && prevProps.match.params.record_id !== this.props.match.params.record_id) {
      this.setState({ selected_id: parseInt(getIn(this.props, 'match.params.record_id'), 10) })
    }

    const valid_tabs = [
      { name: 'head_content', label: 'Head Content' },
      { name: 'html_content', label: 'HTML Content' },
      { name: 'css_content', label: 'CSS Content' },
      { name: 'js_content', label: 'JS Content' }
    ].map(tab => {
      if (isEqual(getIn(model, tab.name), getIn(getIn(model, 'meta.versions', []).find(v => v.id === this.state.selected_id), tab.name))) {
        return false
      }
      return tab.name
    }).filter(a => a)
    if (valid_tabs.length && !valid_tabs.includes(this.state.tab)) {
      this.setState({ tab: valid_tabs[0] })
    }
  }


  editorWillMount(monaco) {
    window.monaco = monaco
    monaco.languages.html.htmlDefaults.setOptions({
      ...monaco.languages.html.htmlDefaults.options,
      format: {
        ...monaco.languages.html.htmlDefaults.options.format,
        wrapAttributes: false,
        wrapLineLength: 0
      }
    })// Register a new language
    monaco.languages.register({ id: 'jinja-scss' })

    // Register a tokens provider for the language
    monaco.languages.setMonarchTokensProvider('jinja-scss', jinja_scss.language)
  }

  editorDidMount(editor) {
    editor.focus()
  }

  onChange(newValue) {
    this.setState({ value: newValue }, () => {
      this.props.form.setFieldValue(this.props.field.name, this.state.value).then(() => {
        this.props.form.setFieldTouched(this.props.field.name)
      })
    })
  }

  render() {
    const { field, classes, id, model, match } = this.props
    let language = 'html'
    if ([ 'css_content' ].includes(this.state.tab)) {
      language = 'jinja-scss'
    }
    const original_model = getIn(model, this.state.tab)
    const version = getIn(model, 'meta.versions', []).find(v => v.id === this.state.selected_id)
    const value = getIn(version, this.state.tab)
    if (!model.meta.versions || !value) {
      return null
    }
    const { model: modelname } = this.props.match.params
    const secure = this.props.match.url.split(modelname)[0]
    return (
      <Suspense fallback={<Loader inline />}>
        <div id={id} className={`${field.name} ${classes}`}>
          <Card
            collapse={false}
            background
            header={
              <div className="tablemeta">
                <div className='form-group version-buttons'>
                  <Button component={Link} className="btn btn-round btn-red" to={`${secure}${modelname}/${version.id}/edit`}>Edit Version</Button>
                  {model.status === 'Published' && <Button type="button" className="btn btn-round btn-red" onClick={() => {
                    // eslint-disable-next-line no-new
                    const values = {
                      modelname,
                      id: this.state.selected_id,
                      status: 'Published'
                    }
                    return new Promise((resolve, reject) => {
                      this.props.actions.updateModel({ values, resolve, reject })
                    }).then(r => {
                      this.props.actions.registerRedirect(`/secure/${match.params.site}/${match.params.model}/${r}/edit`)
                    }).catch(e => console.error(e))
                  }}>{this.state.selected_id !== model.parent ? 'Publish Version' : 'Revert to Previous Version'}</Button>}
                </div>
                <div className='form-group inlineselectinput'>
                  <InlineSelect
                    id="side_by_side"
                    name="side_by_side"
                    label="View Type"
                    prefix={<div className="input-group-addon">View Type:</div>}
                    className="inline-select meta-status view-switch"
                    classNamePrefix="inline"
                    defaultValue={true}
                    selectedValue={getIn(this.state.options, 'renderSideBySide')}
                    options={[
                      {
                        value: true,
                        label: 'Side-by-Side'
                      },
                      {
                        value: false,
                        label: 'Inline'
                      }
                    ]}
                    onChange={e => {
                      this.setState({ options: { ...this.state.options, renderSideBySide: e.value } })
                    }}
                  />
                </div>
              </div>
            }
            bodyclass={'no-top-padding'}
            body={
              <>
                <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
                <div className="forminput form-control monaco-editor">
                  <div className="monaco-editor-tabs">
                    {[
                      { name: 'head_content', label: 'Head Content' },
                      { name: 'html_content', label: 'HTML Content' },
                      { name: 'css_content', label: 'CSS Content' },
                      { name: 'js_content', label: 'JS Content' }
                    ].map(tab => {
                      if (isEqual(getIn(model, tab.name), getIn(getIn(model, 'meta.versions', []).find(v => v.id === this.state.selected_id), tab.name))) {
                        return null
                      }
                      return (
                        <Button key={`version-${tab.name}`} type="button" className={classNames('btn', 'monaco-editor-tab', { active: this.state.tab === tab.name })} onClick={() => {
                          this.setState({ tab: tab.name })
                        }}>
                          {tab.label}
                        </Button>
                      )
                    })}
                  </div>
                  <MonacoDiffEditor
                    width="100%"
                    height="calc(-238px + 100vh)"
                    language={language}
                    theme="vs-dark"
                    original={original_model}
                    modified={value}
                    options={this.state.options}
                    onChange={this.onChange}
                    editorDidMount={this.editorDidMount}
                    editorWillMount={this.editorWillMount}
                    editorWillUnmount={() => {}}
                    automaticLayout
                    readOnly
                  />
                </div>
              </>
            }
          />
        </div>
      </Suspense>
    )
  }
}


export default VersionDiff


VersionDiff.propTypes = {
  form: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  field: PropTypes.object,
  id: PropTypes.string,
  classes: PropTypes.string,
  model: PropTypes.object
}
