/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'

import { SITE } from '../../selectors'
import { generateQR } from '../../actions'


const ButtonComponent = ({
  icon,
  children,
  forwardRef,
  site,
  component: Component,
  // eslint-disable-next-line no-unused-vars
  dispatch,
  ...props
}) => {
  let path = '/images/icons-16.svg'

  if (icon && icon.includes('#icon24')) {
    path = '/images/icons-24.svg'
  } else if (icon && icon.includes('#glyph')) {
    path = '/images/glyphs.svg'
  } else if (icon && icon.includes('#social')) {
    path = '/images/social.svg'
  }
  if (Component) {
    if (props.to) {
      const hasSite = props.to.indexOf(`/secure/${site}`) !== -1
      props.to = props.to.replace('/secure/:site', '/secure').replace('/secure/null', '/secure')
      if (!hasSite) {
        props.to = props.to.replace('/secure', `/secure/${site}`)
      }
    }
    return (
      <Component ref={forwardRef} {...props}>
        {icon ? (
          <div className="icon">
            {props.className.includes('btn-icon-16') ? <svg viewBox="0 0 16 16"><use href={`${path}${icon}`} /></svg> : null}
            {props.className.includes('btn-icon-24') ? <svg viewBox="0 0 24 24"><use href={`${path}${icon}`} /></svg> : null}
          </div>
        ) : null}
        {children}
      </Component>
    )
  }
  return (
    <button ref={forwardRef} {...props}>
      {icon ? (
        <div className="icon">
          {props.className.includes('btn-icon-16') ? <svg viewBox="0 0 16 16"><use href={`${path}${icon}`} /></svg> : null}
          {props.className.includes('btn-icon-24') ? <svg viewBox="0 0 24 24"><use href={`${path}${icon}`} /></svg> : null}
        </div>
      ) : null}
      {children}
    </button>
  )
}

ButtonComponent.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.any,
  forwardRef: PropTypes.func,
  className: PropTypes.string,
  to: PropTypes.string,
  site: PropTypes.number,
  component: PropTypes.any,
  dispatch: PropTypes.func
}


const QRCodeShareButtonComponent = ({ url, generateQR: action, title, className, children, ...props }) => (
  <button
    type="button"
    onClick={() => {
      action({ url, name: title })
    }}
    style={{
      backgroundColor: 'transparent',
      border: 'medium none',
      padding: 0,
      font: 'inherit',
      color: 'inherit',
      cursor: 'pointer'
    }}
    aria-label="qr-code"
    className={classNames('react-share__ShareButton', className)}
    {...props}
  >
    {children}
  </button>
)

QRCodeShareButtonComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  generateQR: PropTypes.func,
  className: PropTypes.string,
  url: PropTypes.string
}

const mapStateToProps = state => {
  const site = SITE(state)
  return {
    site: site.get('id')
  }
}


export const Button = connect(mapStateToProps, null)(ButtonComponent)

const mapDispatchToProps = dispatch => bindActionCreators({
  generateQR
}, dispatch)

export const QRCodeShareButton = connect(null, mapDispatchToProps)(QRCodeShareButtonComponent)

