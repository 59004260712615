/* eslint-disable new-cap */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import TableManager from '../components/common/datatable/TableManager'
import { PREFERENCES, CLEANUSER, CONFIG, UI } from '../selectors'
import * as actions from '../actions'


const mapStateToProps = (state, ownProps) => {
  const user = CLEANUSER(state)
  const preferences = PREFERENCES(state, ownProps.modelname)
  const { modelname } = ownProps
  const config = CONFIG(state, modelname)
  const ui = UI(state)
  return {
    modelname,
    config,
    preferences,
    ui,
    user: Map({
      preferences: user.get('preferences'),
      availablePreferences: user.get('availablePreferences'),
      permissions: user.get('permissions'),
      agent: user.get('agent')
    })
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(TableManager))
