import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import { Button } from '../../../ui/Button'
import TextArea from './TextArea'
import Check from './Check'


class CheckNotesInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: !!(props.form.values[`${props.field.name}_notes`])
    }
  }

  componentDidUpdate() {
    const { form, field, notes_name } = this.props
    const label = `${notes_name ? notes_name : `${field.name}_notes`}`
    if (form.values[label] && !form.touched[label] && !this.state.notes) { // open notes by default, allow collapse after touch
      this.setState({ notes: true })
    }
  }

  render() {
    const { field, label, id, ...props } = this.props
    return (
      <Fragment>
        <div id={id} className={`checkinput checknotesinput form-group ${props.classes ? props.classes : field.name}`}>
          <div className="forminput">
            <Check
              {...this.props}
              suffix={
                <Button tabIndex="-1" type="button" onClick={() => this.setState({ notes: !this.state.notes })} disabled={this.state.searching} className="input-group-addon btn btn-grey">
                  Notes
                </Button>
              }
            />
          </div>
        </div>
        { this.state.notes &&
          <Field
            name={`${props.notes_name ? props.notes_name : `${field.name}_notes`}`}
            label={`${label.endsWith('s') ? label.substr(0, label.length - 1) : label} Notes`}
            classes="not-required checknotesinput notesinput"
            component={TextArea}
            _value={getIn(this.props.form.values, `${this.props.notes_name ? this.props.notes_name : `${field.name}_notes`}`)}
            defaultValue={getIn(this.props.form.initialValues, `${this.props.notes_name ? this.props.notes_name : `${field.name}_notes`}`)}
            counter
          />
        }
      </Fragment>
    )
  }
}

CheckNotesInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  notes_name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default CheckNotesInput
