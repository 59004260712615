/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import React from 'react'

import { AGENT, TOKEN, REDIRECT, CLEANUSER, HUBID } from '../selectors'
import LoadUser from '../components/LoadUser'
import * as actions from '../actions'


const mapStateToProps = state => ({
  redirect: REDIRECT(state),
  statetoken: TOKEN(state),
  user: CLEANUSER(state),
  agent: AGENT(state),
  hub_id: HUBID(state)
})


const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(React.memo(LoadUser)))
