import * as yup from 'yup'

import messages from './messages.json'


export const residential_bulkedit = () => yup.object({
  status: yup.string().oneOf([
    'Active', 'Archived', 'Sold', 'Rented', 'Pending', 'Valuation'
  ], messages.invalid).nullable(),
  branch: yup.number().positive().integer().nullable(),
  unit_number: yup.string().trim().nullable(),
  complex_name: yup.string().nullable(),
  street_number: yup.string().max(10).nullable(),
  street_name: yup.string().max(100).nullable(),
  project: yup.number().positive().integer().nullable(),
  on_show: yup.boolean().nullable(),
  on_show_every_saturday: yup.boolean().nullable(),
  on_show_every_sunday: yup.boolean().nullable(),
  on_show_directions: yup.string().nullable(),
  on_show_date: yup.date().nullable(),
  on_show_start_time: yup.string().when('on_show', (on_show, schema) => (
    on_show ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  on_show_end_time: yup.string().when('on_show', (on_show, schema) => (
    on_show ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  ))
}).test(
  'unit_number', // Cyclicly dependant fields can only be validated after all other fields.
  schema => {
    const { unit_number, complex_name } = schema
    if (!unit_number && !complex_name) { return true } // If neither value is present, pass
    if (unit_number && unit_number.trim() && complex_name &&
      complex_name.trim()) { return true } // If both values are present, pass

    if (unit_number && unit_number.trim() &&
      (!complex_name || !(complex_name && complex_name.trim()))) { // If unit_number is present and complex_name is not, throw an error
      return new yup.ValidationError(messages.required, complex_name, 'complex_name')
    }
    return new yup.ValidationError(messages.required, unit_number, 'unit_number') // If complex_name is present and unit_number is not, throw an error
  }
).test(
  'on_show', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      on_show,
      on_show_every_saturday,
      on_show_every_sunday,
      on_show_date,
      on_show_start_time,
      on_show_end_time
    } = schema
    if (on_show) {
      if (![ on_show_every_saturday, on_show_every_sunday, on_show_date ].some(o => o)) {
        return new yup.ValidationError(messages.required, on_show_date, 'on_show_date')
      }
      if (on_show_every_saturday || on_show_every_sunday || on_show_date) {
        if (!on_show_start_time) {
          return new yup.ValidationError(messages.required, on_show_start_time, 'on_show_start_time')
        }
        if (!on_show_end_time) {
          return new yup.ValidationError(messages.required, on_show_end_time, 'on_show_end_time')
        }
      }
    }
    return true
  }
)

export const commercial_bulkedit = () => yup.object({
  status: yup.string().oneOf([
    'Active', 'Archived', 'Sold', 'Rented', 'Pending', 'Valuation'
  ], messages.invalid).nullable(),
  branch: yup.number().positive().integer().nullable(),
  unit_number: yup.string().trim().nullable(),
  complex_name: yup.string().nullable(),
  street_number: yup.string().max(10).nullable(),
  street_name: yup.string().max(100).nullable(),
  project: yup.number().positive().integer().nullable(),
  on_show: yup.boolean().nullable(),
  on_show_every_saturday: yup.boolean().nullable(),
  on_show_every_sunday: yup.boolean().nullable(),
  on_show_directions: yup.string().nullable(),
  on_show_date: yup.date().nullable(),
  on_show_start_time: yup.string().when('on_show', (on_show, schema) => (
    on_show ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  on_show_end_time: yup.string().when('on_show', (on_show, schema) => (
    on_show ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  ))
}).test(
  'unit_number', // Cyclicly dependant fields can only be validated after all other fields.
  schema => {
    const { unit_number, complex_name } = schema
    if (!unit_number && !complex_name) { return true } // If neither value is present, pass
    if (unit_number && unit_number.trim() && complex_name &&
      complex_name.trim()) { return true } // If both values are present, pass

    if (unit_number && unit_number.trim() &&
      (!complex_name || !(complex_name && complex_name.trim()))) { // If unit_number is present and complex_name is not, throw an error
      return new yup.ValidationError(messages.required, complex_name, 'complex_name')
    }
    return new yup.ValidationError(messages.required, unit_number, 'unit_number') // If complex_name is present and unit_number is not, throw an error
  }
).test(
  'on_show', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      on_show,
      on_show_every_saturday,
      on_show_every_sunday,
      on_show_date,
      on_show_start_time,
      on_show_end_time
    } = schema
    if (on_show) {
      if (![ on_show_every_saturday, on_show_every_sunday, on_show_date ].some(o => o)) {
        return new yup.ValidationError(messages.required, on_show_date, 'on_show_date')
      }
      if (on_show_every_saturday || on_show_every_sunday || on_show_date) {
        if (!on_show_start_time) {
          return new yup.ValidationError(messages.required, on_show_start_time, 'on_show_start_time')
        }
        if (!on_show_end_time) {
          return new yup.ValidationError(messages.required, on_show_end_time, 'on_show_end_time')
        }
      }
    }
    return true
  }
)
