import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'


export const TreeItem = forwardRef(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      item,
      wrapperRef,
      ...props
    },
    ref
  ) => (
    <li
      className={classNames(
        'tree-item--wrapper',
        clone && 'tree-item--clone',
        ghost && 'tree-item--ghost',
        indicator && 'tree-item--indicator',
        disableSelection && 'tree-item--disable-selection',
        disableInteraction && 'tree-item--disable-interaction'
      )}
      ref={wrapperRef}
      style={
        {
          '--spacing': `${indentationWidth * depth}px`
        }
      }
      {...props}
    >
      <div className='tree-item--item' ref={ref} style={style}>
        <i className="dz-handle" {...handleProps} />
        {onCollapse && (
          <div
            onClick={onCollapse}
            className={classNames(
              'tree-item--item--collapse',
              collapsed && 'tree-item--item--collapsed'
            )}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <use href="/images/icons-16.svg#icon16-ChevronDown" />
            </svg>
          </div>
        )}
        <span className='tree-item--text'><NavLink to={`/secure/${item.site}/navigation/${item.id}/edit`}>{item.name}</NavLink></span>
        {!clone && onRemove && <div className='tree-item--item--remove' onClick={onRemove}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <use href="/images/icons-16.svg#icon16-X-Large" />
          </svg>
        </div>}
        {clone && childCount && childCount > 1 ? (
          <span className='tree-item--count'>{childCount}</span>
        ) : null}
      </div>
    </li>
  )
)

TreeItem.displayName = 'TreeItem'

TreeItem.propTypes = {
  childCount: PropTypes.number,
  clone: PropTypes.node,
  depth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disableSelection: PropTypes.bool,
  disableInteraction: PropTypes.bool,
  ghost: PropTypes.node,
  handleProps: PropTypes.object,
  indentationWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  indicator: PropTypes.bool,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  onRemove: PropTypes.func,
  style: PropTypes.object,
  item: PropTypes.object,
  wrapperRef: PropTypes.func
}
