import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import isEqual from 'react-fast-compare'

import country_bounds from '../../../../config/country-bounds.json'
import db from '../../../../db'
import { Button } from '../../../ui/Button'
import Tip from '../Tip'


const MapToButton = props => {
  const { location, clickHandler } = props
  return (
    <div className={`map-button ${location}`}>
      <Button type="button" className="btn btn-primary" onClick={() => clickHandler()}>{props.children}</Button>
    </div>
  )
}

MapToButton.propTypes = {
  location: PropTypes.string,
  clickHandler: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const libraries = [ 'places' ]

const CustomMap = props => {
  let isLoaded
  try {
    isLoaded = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: props.apiKey,
      libraries
    }).isLoaded
  } catch (e) {
    isLoaded = window.google
  }
  const [ map, setMap ] = React.useState(null)
  const callback = () => {
    setMap(null)
  }
  const onUnmount = React.useCallback(callback, [])

  try {
    const { google } = window
    const { form, bounds, address } = props
    if (!isLoaded || !google) { return null }
    const sw = new google.maps.LatLng({ lat: bounds.south, lng: bounds.west })
    const ne = new google.maps.LatLng({ lat: bounds.north, lng: bounds.east })
    const cb = new google.maps.LatLngBounds()
    cb.extend(sw)
    cb.extend(ne)
    const country_center = cb.getCenter()
    const latValue = form.values[props.latInput]
    const lngValue = form.values[props.lngInput]
    const lat = isNaN(latValue) ? country_center.lat() : Number(latValue)
    const lng = isNaN(lngValue) ? country_center.lng() : Number(lngValue)
    const center = { lat, lng }
    if (map && !latValue && !lngValue) {
      map.fitBounds(cb)
      const listener = google.maps.event.addListener(map, 'idle', () => {
        if (map.getZoom() > 16) {
          map.setZoom(16)
        }
        google.maps.event.removeListener(listener)
      })
    }
    return isLoaded && center ? (
      <GoogleMap
        {...props}
        center={center}
        options={{
          fullscreenControl: false,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: true,
          disableDefaultUI: false,
          gestureHandling: 'cooperative',
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.BOTTOM_LEFT
          }
        }}
        onLoad={setMap}
        onUnmount={onUnmount}
      >
        <StandaloneSearchBox
          bounds={cb}
          onLoad={el => {
            if (el && !props.searchBox) {
              props.setSearchBox(el)
            }
          }}
          onPlacesChanged={() => { props.onPlacesChanged(props.searchBox.getPlaces(), map) }}
        >
          <div className="form-group">
            <div className="map-search forminput not-required">
              <input ref={el => {
                if (el && !props.searchInput) {
                  props.setSearchInput(el)
                }
              }} className="form-control" type="text" placeholder="Enter an address" />
            </div>
          </div>
        </StandaloneSearchBox>
        {props.addressField ? (
          <Fragment>
            <div className="map-buttons">
              {address &&
              <MapToButton clickHandler={props.mapToAddress}>Map from address</MapToButton>
              }
              {(latValue && lngValue) &&
              <MapToButton clickHandler={props.copyToAddress}>Copy to address</MapToButton>
              }
            </div>
          </Fragment>
        ) : null}
        {(latValue && lngValue && google) &&
          <Marker
            position={{ lat, lng }}
            draggable
            onDragEnd={props.onMarkerDragEnd}
            options={{
              icon: {
                path: 'M17.5 0C7.83 0 0 7.76 0 17.33C0 20.41 0.84 23.29 2.26 25.8C2.49 26.21 2.74 26.62 3.01 27.02L17.5 52L31.99 27.02C32.21 26.69 32.41 26.34 32.609 26L32.74 25.8C34.16 23.29 35 20.41 35 17.33C35 7.76 27.16 0 17.5 0Z',
                strokeWidth: 0,
                fillOpacity: 1,
                fillColor: '#FC495D',
                strokeColor: 'transparent',
                anchor: new google.maps.Point(17.5, 52),
                size: new google.maps.Size(35, 52),
                scaledSize: new google.maps.Size(32, 47.5)
              }
            }}
          />
        }
      </GoogleMap>
    ) : <></>
  } catch (e) {
    console.warn('Unable to load Google Maps component')
    return <></>
  }
}

CustomMap.propTypes = {
  apiKey: PropTypes.string.isRequired,
  form: PropTypes.object,
  bounds: PropTypes.object,
  address: PropTypes.string,
  latInput: PropTypes.string,
  lngInput: PropTypes.string,
  onPlacesChanged: PropTypes.func,
  mapToAddress: PropTypes.func,
  copyToAddress: PropTypes.func,
  onMarkerDragEnd: PropTypes.func,
  searchBox: PropTypes.object,
  searchInput: PropTypes.object,
  setSearchBox: PropTypes.func,
  setSearchInput: PropTypes.func
}

const MapInput = props => {
  const { field, form, addressField, id, settings } = props

  const [ searchInput, setSearchInput ] = useState()
  const [ searchBox, setSearchBox ] = useState()
  const [ place, setPlace ] = useState(null)
  const [ value, setValue ] = useState({ lat: getIn(form, `values.${props.coordinateFields[0]}`), lng: getIn(form, `values.${props.coordinateFields[1]}`) })

  const timer = useRef(null)

  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    const lat = latLng.lat()
    const lng = latLng.lng()
    form.setFieldValue(props.coordinateFields[0], lat)
    form.setFieldValue(props.coordinateFields[1], lng)
    form.setFieldValue(field.name, { lat, lng })
  }

  const mapToAddress = (no_street = false, no_country = false) => {
    if (!settings.gmaps_key) { return } // Bail if no key
    if (!searchInput) { return } // Bail if no key
    const mapping_option = form.values.mapping_option
    searchInput.blur()
    let address = form.values.physical_address ? form.values.physical_address.split('\n').join(', ') : false
    if (!address) {
      if (form.values.location) { // Lisitng addresses are split into multiple fields
        db.suburbs.get(form.values.location).then(v => {
          if (v) {
            address = [
              [ form.values.unit_number, form.values.complex_name ].join(' '),
              [ form.values.street_number, form.values.street_name ].join(' ')
            ]
            let country = [
              v.province,
              v.country
            ]
            if (no_street || mapping_option === 'Suburb') {
              address = []
            }
            if (no_country) {
              country = []
            }
            address.push(v.suburb)
            address.push(v.area)
            address = [ ...address, ...country ]
            address = address.filter(str => str && str !== '' && str !== ' ').join(', ')
            searchInput.value = address
            searchInput.focus()
            clearTimeout(timer.current)
            timer.current = setTimeout(() => {
              const containers = Array.from(document.querySelectorAll('.pac-container')).filter(container => !(window.getComputedStyle(container).display === 'none'))
              if (!containers.length && !no_country) {
                mapToAddress(true, true)
              }
              containers.forEach(container => {
                const results = container.querySelectorAll('.pac-item')
                if (results.length === 1) {
                  setTimeout(() => {
                    results[0].dispatchEvent(new Event('mouseover'))
                    results[0].dispatchEvent(new Event('click'))
                    searchInput.dispatchEvent(new Event('blur'))
                  }, 10)
                }
              })
            }, 800)
          }
        })
      } else if (form.values.area && !form.values.suburb) { // Area profiles are split into multiple fields
        db.areas.get(form.values.area).then(v => {
          if (v) {
            address.push(v.area)
            address.push(v.province)
            address.push(v.country)
            address = address.filter(str => str && str !== '' && str !== ' ').join(', ')
            searchInput.value = address
            searchInput.focus()
          }
        })
      } else if (form.values.area && form.values.suburb) { // Area profiles are split into multiple fields
        db.suburbs.get(form.values.suburb).then(v => {
          if (v) {
            address.push(v.suburb)
            address.push(v.area)
            address.push(v.province)
            address.push(v.country)
            address = address.filter(str => str && str !== '' && str !== ' ').join(', ')
            searchInput.value = address
            searchInput.focus()
          }
        })
      }
    } else {
      searchInput.value = address
      searchInput.focus()
    }
  }


  const onPlacesChanged = (places, map) => {
    const { google } = window
    if (places.length >= 1 && getIn(places, '0.geometry.location')) {
      const lat = places[0].geometry.location.lat()
      const lng = places[0].geometry.location.lng()
      form.setFieldValue(props.coordinateFields[0], lat)
      form.setFieldValue(props.coordinateFields[1], lng)
      form.setFieldValue(field.name, { lat, lng })
      setValue({ lat, lng })
      setPlace(places[0])
    } else {
      mapToAddress(true)
    }
    const marker_bounds = new google.maps.LatLngBounds()
    marker_bounds.extend(getIn(places, '0.geometry.location'))
    map.fitBounds(marker_bounds)
  }
  const copyToAddress = () => {
    const newAddress = {
      unit_number: '',
      complex_name: '',
      street_number: '',
      street_name: '',
      suburb: '',
      area: '',
      province: '',
      country: ''
    }
    if (place) {
      Object.keys(place.address_components).forEach(part => {
        const types = place.address_components[part].types
        if (typeof addressField === 'object') {
          if (types.includes('country')) {
            if (addressField.country) {
              form.setFieldValue(addressField.country, place.address_components[part].long_name)
            }
          }
          if (types.includes('administrative_area_level_1')) {
            if (addressField.province) {
              form.setFieldValue(addressField.province, place.address_components[part].long_name)
            }
          }
          if (types.includes('administrative_area_level_2')) {
            if (addressField.area) {
              form.setFieldValue(addressField.area, place.address_components[part].long_name)
            }
          }
          if (types.includes('administrative_area_level_3')) {
            if (addressField.suburb) {
              form.setFieldValue(addressField.suburb, place.address_components[part].long_name)
            }
          }
          if (types.includes('route')) {
            if (addressField.street_name) {
              form.setFieldValue(addressField.street_name, place.address_components[part].long_name)
            }
          }
          if (types.includes('street_number')) {
            if (addressField.street_number) {
              form.setFieldValue(addressField.street_number, place.address_components[part].long_name)
            }
          }
        } else {
          Object.keys(newAddress).forEach(k => {
            switch (k) {
              case 'country': {
                const component = place.address_components.filter(c => c.types.includes('country'))[0]
                if (component) {
                  newAddress.country = component.long_name
                }
                break
              }
              case 'province': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_1'))[0]
                if (component) {
                  newAddress.province = component.long_name
                }
                break
              }
              case 'area': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_2'))[0]
                if (component) {
                  newAddress.area = component.long_name
                }
                break
              }
              case 'suburb': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_3'))[0]
                if (component) {
                  newAddress.suburb = component.long_name
                }
                break
              }
              case 'street_name': {
                const component = place.address_components.filter(c => c.types.includes('route'))[0]
                if (component) {
                  newAddress.street_name = component.long_name
                }
                break
              }
              case 'street_number': {
                const component = place.address_components.filter(c => c.types.includes('street_number'))[0]
                if (component) {
                  newAddress.street_number = component.long_name
                }
                break
              }
              default:
                break
            }
          })
        }
      })
      if (typeof addressField !== 'object') {
        const val = [
          [ newAddress.unit_number, newAddress.complex_name ].join(' '),
          [ newAddress.street_number, newAddress.street_name ].join(' '),
          newAddress.suburb,
          newAddress.area,
          newAddress.province,
          newAddress.country
        ].filter(str => str && str.trim())
        form.setFieldValue(addressField, val.join(',\r\n'))
      }
    }
  }

  useEffect(() => {
    if (searchInput && settings) {
      const country_code = settings.region.toUpperCase()
      switch (country_code) {
        case 'AE':
          mapToAddress(true)
          break
        default:
          break
      }
    }
  }, [ form.values.location ])

  useEffect(() => {
    if (!isEqual(field.value, value)) {
      form.setFieldValue(props.coordinateFields[0], value.lat)
      form.setFieldValue(props.coordinateFields[1], value.lng)
      form.setFieldValue(field.name, value)
    }
  }, [ value ])

  useEffect(() => {
    if (form.values.mapping_option === 'Suburb' && settings.gmaps_key) {
      mapToAddress(true)
    }
  }, [ form.values.mapping_option ])

  let address
  let newAddress
  if (typeof addressField === 'object') {
    newAddress = {}
    Object.keys(addressField).forEach(k => {
      newAddress[k] = form.values[addressField[k]]
    })
  } else {
    address = form.values[addressField]
  }

  if (newAddress) { // Listing addresses are split into multiple fields
    address = [
      [ newAddress.unit_number, newAddress.complex_name ].join(' '),
      [ newAddress.street_number, newAddress.street_name ].join(' '),
      newAddress.suburb,
      newAddress.area,
      newAddress.province,
      newAddress.country
    ].filter(str => str && str.trim()).join(', ')
  }
  let country_code = settings.region.toUpperCase()
  if (country_code === 'DEFAULT') {
    country_code = 'ZA'
  }
  const bounds = country_bounds[country_code].bounds
  if (!settings.gmaps_key) {
    return (
      <Tip hot heading="Error" text="No Google Maps key found. Please add one under your site settings." />
    )
  }
  let lat = !isNaN(form.values[props.coordinateFields[0]]) ? Number(form.values[props.coordinateFields[0]]) : null
  let lng = !isNaN(form.values[props.coordinateFields[1]]) ? Number(form.values[props.coordinateFields[1]]) : null
  return (
    <div id={id} className="google-map form-control">
      <CustomMap
        bounds={bounds}
        form={form}
        latInput={props.coordinateFields[0]}
        lngInput={props.coordinateFields[1]}
        apiKey={settings.gmaps_key}
        zoom={(value.lat && value.lng) ? 14 : 5}
        onClick={({ latLng }) => {
          lat = latLng.lat()
          lng = latLng.lng()
          setValue({ lat, lng })
        }}
        addressField={addressField}
        onPlacesChanged={onPlacesChanged}
        mapToAddress={mapToAddress}
        copyToAddress={copyToAddress}
        onMarkerDragEnd={onMarkerDragEnd}
        setSearchInput={setSearchInput}
        setSearchBox={setSearchBox}
        setValue={setValue}
        address={address}
        searchBox={searchBox}
        searchInput={searchInput}
      />
    </div>
  )
}

MapInput.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object,
  classes: PropTypes.string,
  form: PropTypes.object,
  settings: PropTypes.object,
  coordinateFields: PropTypes.array,
  addressField: PropTypes.oneOfType([
    PropTypes.shape({
      unit_number: PropTypes.string,
      complex_name: PropTypes.string,
      street_number: PropTypes.string,
      street_name: PropTypes.string,
      suburb: PropTypes.string,
      area: PropTypes.string,
      province: PropTypes.string,
      country: PropTypes.string
    }),
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default MapInput
