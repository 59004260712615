import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withNoHistoryRouter } from '../../withCustomRouter'
import Modal from './Modal'


const SessionModal = ({ expires, visible, buttons, doLogout, location }) => {
  const [ timeleft, setTimeLeft ] = useState('0 seconds')
  const [ loading, setLoading ] = useState(false)
  const timer = useRef()

  const checkTimeout = useCallback(expiring => {
    const timeout = expiring - Date.now()
    const left = Math.floor(timeout / 1000)
    // when testing using `yarn test`, the mocked user uses an
    // expired token, so let's not do logout when unit testing
    if (left < 0 && !process.env.REACT_APP_TEST === 'unit') {
      doLogout({ url: location })
    }
    setTimeLeft(`${left} seconds`)
  })

  useEffect(() => {
    clearInterval(timer.current)
    timer.current = setInterval(() => {
      checkTimeout(expires)
    }, 5000)
    return () => {
      clearInterval(timer.current)
    }
  }, [ expires ])

  useEffect(() => {
    if (loading) { setLoading(true) }
  }, [ loading ])

  return (
    <Modal
      title="Session Timeout"
      visible={visible}
      buttons={buttons({ setLoading })}
    >
      <div className="challenge">
        <div className="challenge-text">
          We have not detected any activity in your session for a long time.<br />
          You will be logged out automatically in <span className="primary-text">{timeleft}</span>.<br />
          Would you like to extend your session?
        </div>
      </div>
    </Modal>
  )
}

SessionModal.propTypes = {
  onChange: PropTypes.func,
  submitForm: PropTypes.func,
  formik: PropTypes.object,
  mode: PropTypes.string,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  userid: PropTypes.number,
  modelid: PropTypes.number,
  visible: PropTypes.bool,
  buttons: PropTypes.func,
  expires: PropTypes.number,
  doLogout: PropTypes.func,
  location: PropTypes.object
}

export default withNoHistoryRouter(SessionModal)

