import React from 'react'
import PropTypes from 'prop-types'

import { generateAddress } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


class ContactListings extends React.Component {
  render() {
    const { user, modelid, cache, actions, configs } = this.props
    return (
      <Card
        bodyclass="listings no-top-padding"
        background
        header={
          <h3>Listings</h3>
        }
        body={
          <SimpleTable
            paginated
            config={configs.residential}
            action={actions.fetchListings}
            params={{
              contact: modelid,
              order_by: '-created',
              limit: 100
            }}
            parser={data => {
              data.options = data.options ? data.options.map(d => {
                const listing = { ...d }
                if (listing.owners.includes(modelid)) {
                  if (listing.listing_type === 'To Let') {
                    listing.relationship = 'Landlord'
                  } else {
                    listing.relationship = 'Seller'
                  }
                }
                if (listing.tenant === modelid) {
                  listing.relationship = 'Tenant'
                }
                listing.address = generateAddress(listing)
                return listing
              }) : null
              return data
            }}
            header={[
              {
                label: 'Date Added',
                name: 'created',
                orderable: true,
                format: 'datetime'
              },
              {
                label: 'Status',
                name: 'status',
                orderable: true
              },
              {
                label: 'Web Ref',
                name: 'web_ref',
                orderable: true,
                format: 'listing_popup',
                link: '/secure/:site/:model/:id'
              },
              {
                label: 'Address',
                name: [
                  'unit_number',
                  'complex_name',
                  ',',
                  'building_name',
                  ',',
                  'street_number',
                  'street_name'
                ],
                orderable: true,
                link: '/secure/:site/:model/:id'
              },
              {
                label: 'Branch',
                name: 'branch',
                order_by: 'branch__name',
                modelname: 'branches',
                optionlabel: [ 'name' ],
                link: '/secure/:site/branches/:branch',
                orderable: true
              },
              {
                label: 'Agent',
                name: 'agent',
                modelname: 'agents',
                optionlabel: [ 'first_name', 'last_name' ],
                labelseparator: ' ',
                order_by: 'agent__first_name',
                link: '/secure/:site/users/:agent',
                orderable: true
              },
              {
                label: 'Relationship',
                name: 'relationship'
              },
              {
                label: 'Listing Type',
                name: [ 'model', 'listing_type' ],
                labelseparator: ' ',
                orderable: true,
                title: true
              },
              {
                label: 'Price',
                name: 'price',
                format: 'currency',
                classes: [ 'text-right' ],
                orderable: true
              }
            ]}
            user={user}
            currency={cache.settings[user.agent.site.id].default_currency}
          />
        }
      />
    )
  }
}

ContactListings.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number
}

export default ContactListings
