import PropTypes from 'prop-types'
import React from 'react'

import Preference from './Preference'


const PreferenceList = ({ preferences, form, modelname, deletePreference }) => {
  const inner = []
  for (const pid in preferences) {
    if (pid) {
      const preference = preferences[pid]
      inner.push(
        React.createElement(Preference, {
          preference,
          form,
          modelname,
          key: pid,
          deletePreference
        })
      )
    }
  }
  return React.createElement('div', {
    className: 'preference-list'
  }, inner)
}

PreferenceList.propTypes = {
  preferences: PropTypes.object,
  form: PropTypes.object,
  modelname: PropTypes.string,
  deletePreference: PropTypes.func
}
export default PreferenceList
