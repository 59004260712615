
import PropTypes from 'prop-types'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'


export const Scrollbar = ({ children, innerRef, ...props }) => (
  <Scrollbars
    ref={innerRef}
    autoHide
    hideTracksWhenNotNeeded
    autoHideTimeout={1000}
    renderTrackHorizontal={rest => <div {...rest} className="track-horizontal"/>}
    renderTrackVertical={rest => <div {...rest} className="track-vertical"/>}
    renderThumbHorizontal={rest => <div {...rest} className="thumb-horizontal"/>}
    renderThumbVertical={rest => <div {...rest} className="thumb-vertical"/>}
    renderView={rest => <div {...rest} className="scrollview"/>}
    {...props}
  >
    {children}
  </Scrollbars>
)

Scrollbar.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.object
}
