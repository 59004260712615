import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import { Button } from '../../../ui/Button'
import Text from './Text'
import TextArea from './TextArea'


class TextNotesInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: !!(props.form.values[`${props.field.name}_notes`])
    }
  }

  componentDidUpdate() {
    const { form, field, notes_name } = this.props
    const label = `${notes_name ? notes_name : `${field.name}_notes`}`
    if (form.values[label] && !form.touched[label] && !this.state.notes) { // open notes by default, allow collapse after touch
      this.setState({ notes: true })
    }
  }

  render() {
    const {
      field: {
        name
      },
      label,
      // eslint-disable-next-line no-unused-vars, react/prop-types
      className,
      // eslint-disable-next-line no-unused-vars, react/prop-types
      type,
      // eslint-disable-next-line no-unused-vars, react/prop-types
      disabled,
      // eslint-disable-next-line react/prop-types
      id,
      ...props
    } = this.props
    return (
      <Fragment>
        <div id={id} className={`form-group textnotes ${name} ${props.classes ? props.classes : ''}`}>
          <Text
            {...this.props}
            suffix={
              <Button tabIndex="-1" type="button" onClick={() => this.setState({ notes: !this.state.notes })} disabled={this.state.searching} className="input-group-addon btn btn-grey">
                Notes
              </Button>
            }
          />
        </div>
        { this.state.notes &&
          <Field
            name={`${this.props.notes_name ? this.props.notes_name : `${name}_notes`}`}
            label={`${label.endsWith('s') ? label.substr(0, label.length - 1) : label} Notes`}
            classes="not-required textnotesinput notesinput"
            component={TextArea}
            fetchMany={props.fetchMany}
            _value={getIn(this.props.form.values, `${this.props.notes_name ? this.props.notes_name : `${name}_notes`}`)}
            defaultValue={getIn(this.props.form.initialValues, `${this.props.notes_name ? this.props.notes_name : `${name}_notes`}`)}
            counter
          />
        }
      </Fragment>
    )
  }
}

TextNotesInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  notes_name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default TextNotesInput
