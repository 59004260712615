import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'
import isEqual from 'react-fast-compare'

import { Button } from '../../../ui/Button'
import Select from './Select'
import TextArea from './TextArea'
import Label from './Label'


class SelectNotesInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: !!(props.form.values[`${props.field.name}_notes`]),
      val: null
    }
    this.menuPlacement = this.menuPlacement.bind(this)
    this.findValues = this.findValues.bind(this)
    this.placement = 'auto'
    this.val = []
  }

  componentDidUpdate() {
    const v = this.props.field.value
    let newval = []
    this.val = []
    if (Array.isArray(v) && v.length > 0) { // Array value with values
      this.findValues(v, this.props.options)
      newval = this.val
    } else if (typeof v !== 'undefined' && v !== null) { // Value is true, false or a string
      if (this.props.multi) {
        if (v.value) {
          newval = this.props.options.filter(o => o.value === v.value)
        } else {
          newval = this.props.options.filter(o => o.value === v)
        }
      } else if (v.value) {
        newval = this.props.options.find(o => o.value === v.value)
      } else {
        newval = this.props.options.find(o => o.value === v)
      }
    }
    if (!isEqual(newval, this.state.val)) { this.setState({ val: newval }) }
  }

  findValues(v, options) {
    options.forEach(o => {
      if (o.options) {
        this.findValues(v, o.options)
      }
      if (v.includes(o.value)) {
        this.val.push(o)
      }
    })
  }

  menuPlacement(place) {
    this.placement = place
  }

  render() {
    const { field, id, classes, label, fetchMany } = this.props
    if (!field.name) { return null }
    return (
      <Fragment>
        <div id={id} className={`selectinput selectnotes ${field.name} form-group ${classes}`}
          ref={el => { // This may need to be refactored for performance sakes
            if (!el) {return}
            const bottom = el.getBoundingClientRect().bottom
            const body = document.body
            const html = document.documentElement
            const height = Math.max(
              body.scrollHeight,
              body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight
            )
            if (height - bottom < 500) { this.menuPlacement('top') }
          }
          }>
          <Label htmlFor={field.name}>{label}</Label>
          <div className="input-group">
            <Select
              {...this.props}
              inputId={field.name}
              suffix={
                <Button tabIndex="-1" type="button" onClick={() => this.setState({ notes: !this.state.notes })} className="input-group-addon btn btn-grey">
                  Notes
                </Button>
              }
            />
          </div>
        </div>
        { this.state.notes &&
          <Field
            name={`${this.props.notes_name ? this.props.notes_name : `${field.name}_notes`}`}
            label={`${label.endsWith('s') ? label.substr(0, label.length - 1) : label} Notes`}
            classes="not-required selectnotesinput notesinput"
            component={TextArea}
            fetchMany={fetchMany}
            _value={getIn(this.props.form.values, `${this.props.notes_name ? this.props.notes_name : `${field.name}_notes`}`)}
            defaultValue={getIn(this.props.form.initialValues, `${this.props.notes_name ? this.props.notes_name : `${field.name}_notes`}`)}
            counter
          />
        }
      </Fragment>
    )
  }
}

SelectNotesInput.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  id: PropTypes.string,
  multi: PropTypes.bool,
  notes_name: PropTypes.string,
  fetchMany: PropTypes.func,
  options: PropTypes.array.isRequired,
  dependents: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default SelectNotesInput
