/* eslint-disable new-cap */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FieldArray } from 'formik'
import isEqual from 'react-fast-compare'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { uniqueArray, reorder, buildLambdaURI, useCustomCompareMemo } from '../../../../utils'


const ImageItem = props => {
  const { file, selected, path, onChange, className, required, readonly, value, name } = props

  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: file.id })
  const style = {
    transform: CSS.Translate.toString(transform)
  }

  return (
    <div {...attributes} ref={setNodeRef} style={style} className={className}>
      <div {...listeners}>
        <img
          alt=""
          src={file.preview}
        />
      </div>
      <label className={classNames('checkcontainer', { readonly: required })}>
        <input type="checkbox" name={path} value={value} disabled={readonly || required} checked={selected} onChange={onChange}
        />
        <span className={`checkmark ${name}`}>
          {selected && selected !== 'false' ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
        </span>
      </label>
    </div>
  )
}

ImageItem.propTypes = {
  file: PropTypes.object,
  selected: PropTypes.bool,
  path: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  value: PropTypes.number
}

const GallerySelector = props => {
  const { field, form, meta, min: minimum, max: maximum, initialValue } = props
  const [ min, setMin ] = useState(minimum)
  const [ max, setMax ] = useState(maximum)
  const [ items, setItems ] = useState(initialValue || [])
  const [ value, setValue ] = useState(items?.filter((_, i) => i + 1 <= max) || [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    if (minimum !== min) {
      setMin(minimum)
    }
    if (maximum !== max) {
      setMax(maximum)
      setValue(items.filter((_, i) => i + 1 <= maximum))
    }
  }, [ minimum, maximum, form.values?.template ])

  useEffect(() => {
    if (!isEqual(field.value, value)) {
      form.setFieldValue(field.name, value)
    }
  }, [ value ])

  useEffect(() => {
    setItems(initialValue)
    setValue(initialValue?.filter((_, i) => i + 1 <= max) || [])
  }, [ initialValue ])

  useEffect(() => {
    const selected_count = value.length
    setValue(items?.filter((_, i) => min >= i + 1 || i + 1 <= Math.min(max, selected_count)) || [])
  }, [ useCustomCompareMemo(items) ])

  const dragEnded = event => {
    const oldIndex = event.active?.data.current.sortable.index
    const newIndex = event.over?.data.current.sortable.index
    if (oldIndex !== newIndex) {
      let fileids = uniqueArray(items)
      fileids = reorder(fileids, oldIndex, newIndex)
      fileids = fileids.filter(f => f)
      setItems(fileids)
    }
  }
  if (!meta) { return null }
  return (
    <FieldArray name={field.name}>
      {() => (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={dragEnded}
        >
          <div className="gallery-selector">
            <SortableContext
              key={`dnd-${field.name}`}
              items={items || []}
            >
              {items?.map((item, i) => {
                const selected = value.includes(items[i]) || i + 1 <= min
                const val = items[i]
                const file = meta.find(m => m && parseInt(m.id, 10) === parseInt(val, 10))
                if (!file) { return null }
                file.preview = buildLambdaURI(file.file, { w: 320, h: 240 })

                const readonly = i + 1 > max
                const required = i + 1 <= min
                const active = i + 1 <= max
                return (
                  <ImageItem
                    key={val}
                    item={item}
                    className={classNames('gallery-item', { disabled: readonly, selected, required, active })}
                    selected={selected}
                    readonly={readonly}
                    required={required}
                    active={active}
                    value={val}
                    file={file}
                    name={field.name}
                    index={i}
                    onChange={e => {
                      if (!e.target.checked) {
                        setValue(value.filter(v => v !== items[i]))
                      } else {
                        setValue([
                          ...value,
                          items[i]
                        ].sort((a, b) => {
                          if (items.indexOf(b) < items.indexOf(a)) {
                            return 1
                          }
                          if (items.indexOf(b) > items.indexOf(a)) {
                            return -1
                          }
                          return 0
                        }))
                      }
                    }}
                  />
                )
              })}
            </SortableContext>
          </div>
        </DndContext>
      )}
    </FieldArray>
  )
}

export default GallerySelector

GallerySelector.propTypes = {
  meta: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
  initialValue: PropTypes.array,
  max: PropTypes.number,
  min: PropTypes.number
}
