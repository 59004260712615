import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import isEqual from 'react-fast-compare'
import classNames from 'classnames'
import { ChromePicker } from 'react-color'
import { toState } from 'react-color/lib/helpers/color'
import reactCSS from 'reactcss'

import HelpText from '../../HelpText'
import Label from './Label'


const isEnter = (e, form) => {
  if (e.keyCode === 13) { // fire goToPage on enter
    return form.submitForm()
  } // continue typing
  return true
}


class ColorPickerInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      displayColorPicker: false,
      color: null
    }
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.timerA = null
    this.timerB = null
    this.timerC = null
  }

  componentDidMount() {
    let { value } = this.props.field
    if (value && value.endsWith('#')) {
      value = value.replace(/#/gi, '')
    }
    if (value) {
      const color = toState(value)
      this.setState({ value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`, color: color.rgb })
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.field, prevProps.field)) {
      let { value } = this.props.field
      if (value && this.props.field.value.endsWith('#')) {
        value = value.replace(/#/gi, '')
      }
      if (value) {
        const color = toState(value)
        if (!isEqual(this.state.color, color.rgb)) {
          this.setState({ color: color.rgb, value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})` })
        }
      }
    }
  }

  onChange(e) {
    this.setState({ value: ![ undefined, null ].includes(e.target.value) ? e.target.value : '' })
    if (this.timerA) {clearTimeout(this.timerA)}
    // @ts-ignore
    this.timerA = setTimeout(() => {
      if (this.props.onChange) {
        this.props.onChange(e)
      }
    }, 150)
  }

  onBlur(e) {
    this.setState({ value: ![ undefined, null ].includes(e.target.value) ? e.target.value : '' })
    if (this.timerB) {clearTimeout(this.timerB)}
    // @ts-ignore
    this.timerB = setTimeout(() => {
      if (this.props.onBlur) {
        this.props.onBlur(e)
      }
      this.props.form.handleChange(e)
      this.props.form.handleBlur(e)
    }, 150)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange(color) {
    this.setState({ color: color.rgb, value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` })
    const e = new Event('change')
    Object.defineProperty(e, 'target', { writable: false, value: this.el })
    if (this.timerA) {clearTimeout(this.timerA)}
    // @ts-ignore
    this.timerA = setTimeout(() => {
      if (this.props.onChange) {
        this.props.onChange(e)
      }
      this.props.form.handleChange(e)
      this.props.form.handleBlur(e)
    }, 150)
  }

  render() {
    const { name } = this.props.field
    const { label, id, autoComplete, readonly, disabled, className, show_search, form, ...props } = this.props

    const styles = reactCSS({
      default: {
        color: {
          background: this.state.color ? `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})` : 'none'
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          top: '100%'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    })
    return (
      <div id={id} className={`form-group ${name} ${props.classes ? props.classes : ''}`}>
        <div className="forminput colorpicker" id={`${name}-picker`}>
          {label &&
          <Label htmlFor={name} className="formlabel">
            {label}
          </Label>
          }
          <div className='input-group-addon color-swatch' style={styles.color} onClick={ this.handleClick }></div>
          { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <ChromePicker
              color={ this.state.color ? this.state.color : { r: 255, g: 255, b: 255, a: 1 } }
              onChange={ this.handleChange }
            />
          </div> : null }
          <input
            autoComplete={autoComplete}
            id={`input-${id}`}
            name={name}
            type={'text'}
            ref={el => (this.el = el)}
            disabled={disabled}
            readOnly={readonly}
            placeholder={props.placeholder}
            className={classNames('form-control', 'input-group-prefix', className)}
            onFocus={e => {
              if (props.onFocus) {
                props.onFocus(e)
              }
            }}
            onBlur={this.onBlur}
            onKeyDown={show_search ? e => {
              clearTimeout(this.timerC)
              this.timerC = setTimeout(() => {
                if (this.props.form_el && this.el && this.props.form_el.contains(this.el.current)) {
                  isEnter(e, form)
                }
              }, 150)
            } : null}
            onChange={this.onChange}
            value={this.state.value === 0 || this.state.value ? this.state.value : ''} // Cannot set value otherwise debounce breaks
            maxLength={props.maxLength}
          />
        </div>
        {props.help &&
            <span className="help-text">
              {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
            </span>
        }
        <ErrorMessage component="div" className="error" name={name} />
      </div>
    )
  }
}

ColorPickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  form_el: PropTypes.node,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  help: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  counter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  autoComplete: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.bool,
  show_search: PropTypes.bool
}

export default ColorPickerInput
