import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { resetPass, resetSuccess, activateUser } from '../actions'

import Reset from '../components/Reset'


const mapStateToProps = (state, ownProps) => {
  const action = ownProps.match.params.action
  return {
    ui: state.ui,
    action
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    resetPass,
    resetSuccess,
    activateUser
  }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Reset))
