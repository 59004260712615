/* eslint-disable new-cap */
import { Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { PERMISSIONS, SITE, MINUSER, LOCATION } from '../../../../selectors'
import { handleSubmitError, updateSearchParms, useCustomCompareMemo, breakpoint } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SimpleTable from '../../simpletable/SimpleTable'
import CustomForm from '../CustomForm'
import Card from '../../Card'
import FieldGroup from '../FieldGroup'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import RedirectsSidebar from '../../../../containers/RedirectsSidebar'
// import TextInput from './Text'
import PageJump from '../../datatable/PageJump'
import Step from '../../Step'
import QueryBuilder from '../../QueryBuilder'
import InlineSelect from './InlineSelect'


const RedirectsManager = ({
  model,
  match,
  config,
  location,
  user,
  fetchMany,
  updateModel,
  createModel,
  deleteModel,
  toggleWideSidebar
}) => {
  const { fields } = config
  const i = {}
  fields.forEach(f => {
    if (f.defaultvalue) { i[f.name] = f.defaultvalue }
  })
  const qs = new QueryBuilder(location.search)

  const [ editing, setEditing ] = useState(false)
  const [ searching, setSearching ] = useState(false)
  const [ searched, setSearched ] = useState(false)
  const [ results, setResults ] = useState({
    offset: getIn(qs, 'params.offset', 0),
    limit: getIn(qs, 'params.limit', 20),
    count: 0,
    recordsto: 0,
    recordsfrom: 0,
    next: null,
    previous: null
  })

  const [ desktop ] = useState(breakpoint.matches)
  const [ term, setTerm ] = useState(getIn(qs, 'params.term', ''))
  const [ initvals ] = useState(i)
  const redirects = useRef()

  const calculateRecords = response => {
    let from = 0
    let to = 0
    let newoffset = results.offset
    let newlimit = results.limit
    let newcount = results.count
    if (response.params) {
      newoffset = response.params.offset
      newlimit = response.params.limit
      newcount = Number(response.count)
      from = Number(newoffset) + 1 || 1
      to = (from - 1 + newlimit) > newcount ? newcount : from - 1 + newlimit
    }
    setResults(previousState => (
      {
        ...previousState,
        offset: newoffset,
        limit: newlimit,
        count: newcount,
        recordsto: to,
        recordsfrom: from,
        next: response.next,
        previous: response.previous
      }))
  }

  const fetchRedirects = ({ params, signal, resolve, reject }) => fetchMany({
    values: {
      modelname: 'redirects',
      modellist: true,
      params,
      signal
    },
    resolve,
    reject
  })

  const isEnter = e => {
    if (e.keyCode === 13) { // fire goToPage on enter
      setSearching(true)
      setSearched(true)
      setTerm(e.target.value)
    } // continue typing
    return true
  }

  const updateInput = e => {
    if (searched) { setSearched(false) }
    setTerm(e.target.value)
  }

  const stepPage = dir => { // Step through result pages
    const { limit, count } = results
    const url = results[dir]
    let newoffset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { newoffset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        newoffset = Math.floor(count / limit) * limit
        break
      default:
        newoffset = 0
    }
    updateSearchParms('offset', newoffset)
  }

  const changelimit = e => {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
  }

  const createRedirect = (values, formikBag) => {
    formikBag.setSubmitting(true)
    return new Promise((resolve, reject) => {
      values.modelname = 'redirects'
      createModel({ values, resolve, reject })
    }).then(() => {
      formikBag.setSubmitting(false)
      formikBag.resetForm({
        values: {
          priority: '',
          path: '',
          replacement: ''
        }
      })
      redirects.current?.refreshPage()
    }).catch(e => {
      formikBag.setSubmitting(false)
      handleSubmitError(e, formikBag, { ...formikBag, values })
    })
  }

  const deleteRedirect = (redirect, callback) => new Promise((resolve, reject) => {
    const values = {
      modelname: 'redirects',
      selected: [ redirect.id ]
    }
    // eslint-disable-next-line no-alert
    const confirmation = confirm('Are you sure you want to delete this redirect?')
    if (confirmation) {
      return deleteModel({ values, resolve, reject })
    }
    return reject('')
  }).then(r => {
    callback(r)
  })

  const addVerb = () => {
    if (fields) {
      const redirect_fields = fields.filter(field => field.edit).map((field, ind) => ({
        ...field,
        name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
        key: `adv-${field.name}-${ind}`,
        edit: true
      }))
      return <FieldGroup
        card={false}
        match={match}
        groupname="Record Details"
        config={config}
        fields={redirect_fields}
      />
    }
    return null
  }

  useEffect(() => {
    if (searching) {
      updateSearchParms('path__contains', term)
      setSearching(false)
    }
  }, [ searching ])

  useEffect(() => {
    if (editing) { toggleWideSidebar('show-redirect-sidebar') }
  }, [ useCustomCompareMemo(editing) ])

  return (
    <div className="redirects-manager">
      <Card
        header={
          <h3>Add New Redirect</h3>
        }
        background
        body={
          <Formik
            initialValues={{
              domain: model.id,
              ...initvals
            }}
            enableReinitialize={true}
            onSubmit={createRedirect}
          >{formik => (
              <CustomForm
                component="div"
                render={() => (
                  <div className="search-fields">
                    {addVerb(formik)}
                    <div className="search-buttons" style={{ paddingRight: 0 }}>
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button"
                        icon="#icon24-Plus"
                        onClick={formik.submitForm}
                        disabled={formik.isSubmitting}
                        className="btn btn-grey btn-icon-16 btn-icon-left"
                      >
                        Add Redirect
                      </Button>
                    </div>
                  </div>
                )}
              />
            )}
          </Formik>
        }
      />
      <Card
        header={
          <h3>Redirects</h3>
        }
        background
        body={
          <div className="redirects">

            <div className="redirects-search search-fields">
              <div className="input-group keyword-search">
                <div className="form-group term">
                  <div className="forminput">
                    <input
                      id="redirect-search"
                      // ref={el => { setRef(el) }}
                      type="search"
                      placeholder="Path Search"
                      className="form-control input-group-suffix"
                      onBlur={isEnter}
                      onKeyDown={isEnter}
                      onChange={updateInput}
                      value={term}
                    />
                    <Button icon="#icon24-Search" type="button" disabled={searching} onClick={() => {
                      setSearching(true)
                      setSearched(true)
                    }} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
                  </div>
                </div>
                {searched &&
                  <div className="reset-group">
                    <Button
                      id="keyword-search-btn"
                      tabIndex="-1"
                      type="button"
                      onClick={() => {
                        setTerm('')
                        setSearched(false)
                        setSearching(true)
                      }}
                      disabled={searching}
                      className="input-group-addon btn btn-none"
                    >
                      Reset Filters
                    </Button>
                  </div>
                }
              </div>
              <div className="page-tools">
                <PageJump
                  params={{ offset: results.offset, limit: results.limit, count: results.count }}
                  endpoint={getIn(config, 'endpoint')}
                  modelname={getIn(config, 'modelname')}
                  count={results.count}
                />

                <div className="page-buttons">
                  <Step
                    stepPage={stepPage}
                    next={results.next}
                    previous={results.previous}
                  />

                  {desktop && <div className="record-count">
                    {results.recordsto ? results.recordsfrom : 0} - {results.recordsto} of {results.count}
                  </div> }
                  {desktop && <InlineSelect
                    id="field-limit"
                    name="limit"
                    className="inline-select meta-limit"
                    classNamePrefix="inline"
                    defaultValue={{ value: results.limit, label: results.limit.toString(10) }}
                    selectedValue={model.params ? Number(model.params.limit) : ''}
                    autosize={true}
                    options={[
                      { value: 20, label: '20' },
                      { value: 50, label: '50' },
                      { value: 100, label: '100' }
                    ]}
                    onChange={e => changelimit(e)}
                  />}
                </div>
              </div>
            </div>

            <div className="redirectsbody">
              <SimpleTable
                user={user}
                config={config}
                header={config.tableconfig.map(f => {
                  const field = config.fields.find(fe => fe.name === f.name)
                  return {
                    ...field,
                    truncate: false,
                    input: null
                  }
                })}
                getClass={el => (redirects.current = el)}
                updateModel={({ values, resolve, reject, data, callback }) => {
                  const update = data.map(r => ({ ...r }))
                  updateModel({ values, resolve, reject })
                  update.forEach(d => {
                    if (d.front_end) {
                      d.front_end = false
                    }
                  })
                  update.forEach(d => {
                    if (d.id === values.id) {
                      d.front_end = values.front_end
                    }
                  })
                  callback(update)
                }}
                action={({ params, resolve }) => {
                  new Promise((res1, rej1) => fetchRedirects({ params, resolve: res1, reject: rej1 })).then(r => {
                    calculateRecords(r)
                    const rows = r.index.map(p => r.results[p])
                    resolve({ options: rows })
                  })
                }}
                params={{ ...qs.getAllArgs(false) }}
                parser={data => data}
                rowActions={(row, data) => (
                  <>
                    <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => setEditing(data)} title="Edit Redirect" type="button" />
                    <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteRedirect(data, () => {redirects.current.fetchData()})} title="Delete Redirect" type="button" />
                  </>
                )}
              />
            </div>
          </div>
        }
      />
      <WideSidebar sidebar={'show-redirect-sidebar'}>
        <RedirectsSidebar
          key="redirect-sidebar-edit"
          toggleLookup={() => setEditing(false)}
          redirect={editing}
          config={config}
          list={redirects.current}
          match={match}
        />
      </WideSidebar>
    </div>
  )
}

RedirectsManager.propTypes = {
  cache: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object,
  fields: PropTypes.object,
  config: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  configs: PropTypes.object,
  match: PropTypes.object,
  updatetemplate: PropTypes.func,
  deletetemplate: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  bulkEditModel: PropTypes.func
}

const mapStateToProps = state => {
  const site = SITE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  return {
    location: LOCATION(state),
    user: minuser
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(RedirectsManager))
