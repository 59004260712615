import React from 'react'
import PropTypes from 'prop-types'

import { capitalize } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


class ReferralNotifications extends React.Component {
  render() {
    const { modelid, configs, actions, user } = this.props
    return (
      <Card
        bodyclass="alerts-sent no-top-padding"
        background
        header={
          <h3>Notifications Sent</h3>
        }
        body={
          <SimpleTable
            paginated
            config={configs.agentnotifications}
            action={actions.fetchNotifications}
            params={{
              referral: modelid,
              order_by: '-created',
              limit: 100
            }}
            parser={data => {
              data.options = data.options ? data.options.map(d => {
                const alert = { ...d }
                alert.status = capitalize(d.status)
                const notification_type = d.notification_type.replace('referral-', '')
                alert.notification_type = capitalize(notification_type)
                return alert
              }) : null
              return data
            }}
            header={[
              {
                label: 'Date Sent',
                name: 'created',
                orderable: true,
                format: 'datetime'
              },
              {
                label: 'Agent',
                name: [ 'meta.agent.first_name', 'meta.agent.last_name' ]
              },
              {
                label: 'Email',
                name: 'meta.agent.email'
              },
              {
                label: 'Subject',
                name: 'subject'
              },
              {
                label: 'Type',
                name: 'notification_type'
              },
              {
                label: 'Status',
                name: 'status'
              }
            ]}
            user={user}
          />
        }
      />
    )
  }
}

ReferralNotifications.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number
}

export default ReferralNotifications
