/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, fetchMany, notifyUser } from '../actions'
import { CONFIG } from '../selectors'
import ContactSearchSidebar from '../components/ContactSearchSidebar'


function mapStateToProps(state) {
  return {
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache'),
    config: CONFIG(state, 'contacts')
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    createModel,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ContactSearchSidebar))
