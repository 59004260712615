import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { Document, Page, pdfjs } from 'react-pdf'

import log from '../logging'
import { hasAddons, hasPermission, isConditional, overwriteMerge, uniqueArray, sortBy, convertArrayToObject } from '../utils'
import { ReactComponent as Glyphs } from '../images/glyphs.svg'
import { ReactComponent as Icons16 } from '../images/icons-16.svg'
import { ReactComponent as Icons24 } from '../images/icons-24.svg'
import { ReactComponent as Social } from '../images/social.svg'
import validate from '../validate'
import Websocket from '../containers/Websocket'
import Loader from './common/Loader'
import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import SelectInput from './common/forms/inputs/Select'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
class Canvassing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      initvals: false,
      scale: 1.0,
      default: null,
      pageNumber: 1,
      page: 1,
      numPages: 0,
      rotate: null,
      zoom: 'auto',
      scrolling: false,
      highlight: false,
      document: null,
      width: null,
      height: null,
      templates: [],
      template_config: null,
      template_cache: {},
      url: '',
      status: '',
      confirmed: false
    }
    this.isConditional = isConditional.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.goToPrevPage = this.goToPrevPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.isEnter = this.isEnter.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.rePosition = this.rePosition.bind(this)
    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.autoFitScale = this.autoFitScale.bind(this)
    this.setScale = this.setScale.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.download = this.download.bind(this)
    this.updateTemplates = this.updateTemplates.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.toggleScrolling = this.toggleScrolling.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.updateStatus = this.updateStatus.bind(this)
    this.fetchTemplates = this.fetchTemplates.bind(this)
    this.checkForValues = this.checkForValues.bind(this)
    this.zoomLevels = [
      50,
      75,
      100,
      125,
      150,
      200,
      400
    ]
  }

  componentDidMount() {
    this.fetchTemplates()
    window.addEventListener('resizeAutoZoom', this.autoFitScale)
    window.addEventListener('resize', this.rePosition)
    window.addEventListener('status:change', this.updateStatus)
    window.addEventListener('message', this.checkForValues)
    this.rePosition()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.document && !prevState.document) {
      this.rePosition()
    } else if (this.state.document && prevState.document) {
      if (this.state.document._pdfInfo.fingerprint !== prevState.document._pdfInfo.fingerprint) {
        this.rePosition()
      }
    }
    if (this.state.scrolling !== prevState.scrolling) {
      this.toggleScrolling(!!this.state.scrolling)
    }
    if (this.state.rotate !== prevState.rotate) {
      this.rePosition()
    }
    if (this.state.zoom !== prevState.zoom) {
      this.rePosition()
    }
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + document.querySelector('.canvashead').getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('status:change', this.updateStatus)
    window.removeEventListener('resizeAutoZoom', this.autoFitScale)
    window.removeEventListener('resize', this.rePosition)
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
    window.removeEventListener('message', this.checkForValues)
  }

  checkForValues(e) {
    const { initvals } = this.state
    const new_vals = { ...initvals }
    if (e.data.type === 'INITIAL_VALUES') {
      Object.keys(e.data.data).forEach(k => {
        new_vals[k.replace('override-', '')] = e.data.data[k]
      })
      this.setState({ initvals: this.initModel(
        this.state.templates.find(t => this.form.values.template === t.value),
        new_vals
      ) })
    }
    if (e.data.type === 'CONFIRMED') {
      this.setState({ confirmed: true }, () => {
        this.form.submitForm()
      })
    }
  }

  fetchTemplates() {
    const { config } = this.props
    new Promise((resolve, reject) => this.props.actions.fetchMany({
      values: {
        modelname: 'templates',
        conflict: true,
        params: {
          template_type: 'brochure',
          order_by: 'ordinal',
          model__in: config.modelname,
          get_all: 1
        }
      },
      resolve,
      reject
    })).then(r => {
      const templates = r.options.map(template_config => new Promise((
        resolve,
        reject
      ) => this.props.actions.fetchTemplateConfig({
        params: {
          template: template_config.name
        },
        resolve,
        reject
      })).catch(console.error))
      Promise.allSettled(templates).then(results => {
        results.forEach(({ value }, idx) => {
          r.options[idx].fields = value?.fields
        })
        return r.options
      }).then(template_cache => {
        const template_config = template_cache[0]
        this.setState({ template_cache: convertArrayToObject(template_cache, 'name'), template_config }, () => {
          this.setState({ initvals: this.initModel() }, () => {
            window.parent.postMessage({
              type: 'INITIALISED'
            }, this.props.origin)
          })
        })
      })
    })
  }

  fetchTemplateConfigs(template) {
    if (!template) { return }
    if (getIn(this.props, 'match.params.model') === 'pages') {
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => this.props.actions.fetchPageTemplateConfig({
        params: {
          template
        },
        resolve,
        reject
      })).then(r => {
        this.setState({ template_config: r }, () => {
          this.setState({ initvals: this.initModel() }, () => {
            this.form.submitForm()
          })
        })
      }).catch(e => {
        console.error(e)
      })
      return
    }
    new Promise((resolve, reject) => this.props.actions.fetchTemplateConfig({
      params: {
        template
      },
      resolve,
      reject
    })
    ).then(r => {
      this.setState({ template_config: r }, () => {
        this.setState({ initvals: this.initModel() }, () => {
          this.form.submitForm()
        })
      })
    }).catch(e => {
      console.error(e)
    })
  }

  hasPermission() {
    const { user, config, addons } = this.props
    if (config.addons) {
      if (!hasAddons(config.addons, addons)) { return false } // Entire module is disabled
    }
    const requiredPermissions = [ 'canvassing_view' ]
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (hasPermission(hasEditAllPermissions, user.permissions)) { return true }
    return false
  }

  setStatus(status) {
    const event = new CustomEvent('status:change', { detail: status })
    window.dispatchEvent(event)
  }

  updateStatus(e) {
    setTimeout(() => {
      this.setState({ status: e.detail })
    })
  }

  updateTemplates(templates) {
    if (!isEqual(templates, this.state.templates)) {
      this.setState({ templates })
    }
  }

  toggleScrolling(isEnable) {
    if (isEnable) {
      window.addEventListener('mouseup', this.onMouseUp)
      if (!this.state.highlight) {
        window.addEventListener('mousemove', this.onMouseMove)
      }
    } else {
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)
    }
  }

  autoFitScale() {
    if (this.state.document) {
      const originalWidth = 595
      const containerWidth = this.el && this.el.offsetWidth < 1020
        ? this.el.offsetWidth - 56
        : 1019
      let nextScale = -1

      if (this.el) {
        nextScale = Math.abs(containerWidth) / originalWidth
      }

      this.state.document.getPage(1).then(page => {
        const viewport = page.getViewport()
        return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
      }).then(({ width, height }) => {
        if ([ 90, 270 ].includes(this.state.rotate)) {
          this.setState({ height: (this.el.offsetHeight * (width / height)) + 56 })
        } else {
          this.setState({ height: (this.el.offsetWidth * (height / width)) + 25 })
        }
      })
      this.setScale(nextScale * 100, true)
    }
  }

  setScale(scale, auto = false) {
    if (scale < 0) {
      this.autoFitScale()
      return
    }

    const nextScale = scale >= 10 ? scale : 10
    if (nextScale !== this.state.scale) {
      if (!this.state.default) {
        this.setState({ scale: scale / 100, zoom: 'auto', default: scale / 100 })
      } else {
        this.setState({ scale: scale / 100, zoom: auto ? 'auto' : scale })
      }
    }
  }

  zoomIn() {
    const { scale } = this.state
    let newScale
    if (this.state.zoom === 'auto') {
      const nextZoom = this.zoomLevels
        .reduce((prev, curr) => (Math.abs(curr - scale * 100) < Math.abs(prev - scale * 100) ? curr : prev))
      const zoomIdx = this.zoomLevels.indexOf(nextZoom)
      if (zoomIdx + 1 <= this.zoomLevels.length) {
        newScale = this.zoomLevels[zoomIdx + 1]
      }
    } else {
      const zoomIdx = this.zoomLevels.indexOf(scale * 100)
      if (zoomIdx + 1 <= this.zoomLevels.length) {
        newScale = this.zoomLevels[zoomIdx + 1]
      }
    }

    if (newScale > 1000) { return }
    if (newScale) { this.setScale(newScale) }
  }

  zoomOut() {
    const { scale } = this.state
    let newScale
    if (this.state.zoom === 'auto') {
      const nextZoom = this.zoomLevels
        .reduce((prev, curr) => (Math.abs(curr - scale * 100) < Math.abs(prev - scale * 100) ? curr : prev))
      const zoomIdx = this.zoomLevels.indexOf(nextZoom)
      if (zoomIdx - 1 >= 0) {
        newScale = this.zoomLevels[zoomIdx - 1]
      }
    } else {
      const zoomIdx = this.zoomLevels.indexOf(scale * 100)
      if (zoomIdx - 1 >= 0) {
        newScale = this.zoomLevels[zoomIdx - 1]
      }
    }

    if (newScale <= 0) { return }
    if (newScale) { this.setScale(newScale) }
  }

  rotateRight() {
    let currentRotation = this.state.rotate
    if (currentRotation + 90 > 360) {
      currentRotation = 0
    }
    if (currentRotation + 90 === 360) {
      currentRotation = -90
    }
    this.setState({ rotate: currentRotation + 90 })
  }

  rotateLeft() {
    let currentRotation = this.state.rotate
    if (currentRotation - 90 < 0) {
      currentRotation = 360
    }
    this.setState({ rotate: currentRotation - 90 })
  }

  initModel(template_config, values) { // Initialise model data for formik
    const initvals = {}
    let data = {}
    const { search } = this.props
    if (search) {
      const qs = new QueryBuilder(search)
      data = qs.getAllArgs(false)
    }
    // Initialise template options
    const template_list = sortBy(Object.keys(this.state.template_cache).map(k => this.state.template_cache[k]), 'ordinal')
    initvals.template = getIn(template_config, 'name', getIn(template_list, '0.name'))
    this.updateTemplates(uniqueArray(this.state.templates, 'value'))
    const brochure_config = this.state.template_cache[initvals.template]
    if (brochure_config) {
      if (!isEqual(this.state.template_config, brochure_config)) {
        this.setState({ template_config: brochure_config })
      }
      if (Array.isArray(brochure_config.fields)) {
        brochure_config.fields.forEach(f => {
          const name = getIn(f, 'name', f)
          const value = getIn(values, name, getIn(data, name, f.defaultvalue))
          initvals[`override-${name}`] = value
          if (f.input === 'Check') {
            initvals[`override-${name}`] = false
          }
        })
      }
    }

    Object.keys(initvals).forEach(f => {
      const field = this.props.config.brochure.fields.find(fe => fe.name === f.replace('override-', ''))
      if (!field) { return }

      if (field.name === 'template') {
        return
      }
    })
    return initvals
  }


  async download(event, values, template) {
    const pdfDoc = this.state.document
    const { url } = pdfDoc._transport._params
    const filename = `${values.agency_name} - ${template.label} - ${values.lightstone_id}.pdf`

    window.parent.postMessage({
      type: 'DOWNLOADED',
      data: { url, filename }
    }, this.props.origin)

    const downloadByUrl = () => {
      this.downloadManager.downloadUrl(url, filename)
    }

    try {
      const data = await this.state.document.getData()
      const blob = new Blob([ data ], { type: 'application/pdf' })
      this.downloadManager.download(blob, url, filename)
    } catch (e) {
      downloadByUrl()
    }
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) }


  addVerb(bag) {
    const { fields } = this.props.config
    // Merge field configs from model fields and brochure fields.
    // Deepmerge wasn't doing this correctly.
    let template_fields = getIn(this.state, 'template_config.fields', []) || []
    if (!Array.isArray(template_fields)) {
      template_fields = []
    }
    const inferred_fields = template_fields.map(f => {
      if (typeof f === 'string') {
        return fields.find(field => field.name === f)
      }
      const real_field = fields.find(field => isEqual(field.name, f.name))
      if (real_field) {
        return real_field
      }
      return f
    })
    const all_fields = uniqueArray(merge([], [
      {
        name: 'template',
        label: 'Template',
        edit: true,
        required: true,
        noclear: true,
        input: 'AsyncSelect',
        modelname: 'templates',
        optionlabel: 'display_name',
        optionvalue: 'name',
        extraparams: 'template_type=brochure&order_by=ordinal&model=canvassing'
      },
      ...inferred_fields,
      ...fields
    ], { arrayMerge: overwriteMerge }).map(f => {
      const override = this.props.config.brochure.fields.find(bf => bf && f && getIn(bf, 'name') === getIn(f, 'name'))
      if (override) { return merge(f, override, { arrayMerge: overwriteMerge }) }
      return f
    }), 'name')
    if (all_fields) {
      const brochure_fields = all_fields.filter(f => {
        if (!f) { return false }
        if (f.name === 'template') { return true }
        if (this.state.template_config) {
          return this.state.template_config.fields.map(field => getIn(field, 'name', field)).includes(f.name)
        }
        return false
      }).map(field => {
        const rest = this.state.template_config ? this.state.template_config.fields.find(f => getIn(f, 'name') === field.name) || {} : {}
        const props = {
          ...field,
          ...rest,
          cols: 'lg-12',
          required: field.name === 'template' ? true : field.required,
          quality: false,
          name: `${(this.state.template_config && this.state.template_config.fields.map(f => getIn(f, 'name', f)).includes(field.name)) || [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name.replace('override-', '')) ? `override-${field.name}` : field.name}${field.verb ? `__${field.verb}` : ''}`
        }

        if (props.name === 'template') {
          props.updateTemplates = this.updateTemplates
        }
        return props
      })
      return <FieldGroup
        form={bag}
        card={false}
        match={this.props.match}
        config={{
          fields: brochure_fields
        }}
        fields={brochure_fields}
      />
    }
    return null
  }

  async rePosition() {
    const { zoom } = this.state

    if (zoom && zoom !== 'auto') {
      this.setScale(zoom)
    } else {
      await this.autoFitScale()
    }
  }

  onDocumentLoadSuccess(document) {
    const { numPages } = document
    document.getPage(1).then(page => {
      const viewport = page.getViewport()
      return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
    }).then(({ width, height }) => {
      this.setState({
        scale: 1.0,
        rotate: null,
        zoom: 'auto',
        scrolling: false,
        highlight: false,
        numPages,
        page: 1,
        pageNumber: 1,
        document,
        width: width,
        height: (this.el.scrollWidth * (height / width)) + 25
      })
    })
  }

  goToPrevPage() {
    const newpage = parseInt(this.state.pageNumber, 10) - 1
    if (newpage > 0) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToNextPage() {
    const newpage = parseInt(this.state.pageNumber, 10) + 1
    if (newpage <= this.state.numPages) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToPage(page) {
    const newpage = page
    if (newpage <= this.state.numPages && newpage > 0) {
      this.setState({ pageNumber: newpage })
    }
  }

  onMouseDown(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({ scrolling: true, scrollLeft, scrollTop, clientX: event.clientX, clientY: event.clientY })
    return event
  }

  onMouseMove(event) {
    if (!this.el) { return false }
    const { clientX, scrollLeft, scrollTop, clientY } = this.state
    this.el.scrollLeft = scrollLeft - clientX + event.clientX
    this.el.scrollTop = scrollTop - clientY + event.clientY
    return event
  }

  onMouseUp(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({
      scrolling: false,
      scrollLeft,
      scrollTop,
      clientX: event.clientX,
      clientY: event.clientY
    })
    return event
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.goToPage(e.target.value)
    } // continue typing
    return true
  }


  render() {
    const { config, actions, ui } = this.props
    if (!this.state.template_config) { return null }
    return (
      <div id="content" className="canvassing content">
        <Websocket key='websocket' />
        <Icons24 />
        <Icons16 />
        <Glyphs />
        <Social />
        <div className="canvashead details">
          <div className="lightstone-logo"></div>
          <div className='pd-callnow'>
            <div className='pd-powered'>
              Powered by Prop Data<br />
              Need more functionality? <a href="https://go.propdata.net/general-enquiry?utm_campaign=Lightstone%20Canvassing%20Referral&utm_source=lightstone-toolkit&utm_medium=lightstone-toolkit-referral" target="_blank" rel="noreferrer">Contact us</a>
            </div>
            <div className="pd-logo"></div>
          </div>
        </div>
        <Formik
          validationSchema={validate.brochure_generator}
          validateOnChange={true}
          initialValues={{
            ...this.state.initvals,
            model: config.servicename || this.props.modelname
          }}
          enableReinitialize={true}
          onSubmit={(values, form) => {
            if (!this.state.confirmed) {
              window.parent.postMessage({
                type: 'SUBMITTED',
                data: { values }
              }, this.props.origin)
            } else {
              new Promise((resolve, reject) => {
                form.setSubmitting(true)
                const { template, ...cleanvalues } = values
                delete cleanvalues.offset
                const params = {
                  params: cleanvalues,
                  modelname: 'canvassing',
                  args: {
                    action: 'brochure',
                    template
                  },
                  label: 'Brochure',
                  noalert: true,
                  callback: this.setStatus,
                  resolve,
                  reject
                }
                this.setState({ url: '', height: 160 })
                return actions.exportData(params)
              }).then(r => {
                form.setSubmitting(false)
                const url = r.response.file
                form.setSubmitting(false)
                this.setState({ url })
              }).catch(e => {
                if (Object.keys(e).includes('id__in') || Object.keys(e).includes('template')) {
                  form.setErrors(e.response)
                }
                form.setSubmitting(false)
                log.error(e)
              })
            }
          }}
        >{formik => {
            this.form = formik
            const template = this.state.templates.find(e => formik.values.template === e.value)
            return (
              <div className={`view details brochures ${config.modelname}`}>
                <div className="viewcontent">
                  <Scrollbar
                    style={{ height: `calc(100vh - ${this.state.offset}px)` }}
                  >
                    <div className="flex-container">
                      <div className='col-md-4'>
                        <Card
                          background={true}
                          header={
                            <h3>Brochure Generator</h3>
                          }
                          body={
                            <CustomForm
                              ui={ui}
                              onChange={(changes, form) => {
                                if (this.state.initvals.template && this.form && !formik.touched.template) {
                                  formik.setFieldTouched('template') // enable button as soon as templates are loaded
                                }

                                if (changes.includes('suburb') && form.values.suburb) {
                                  this.setState({ confirmed: false })
                                }
                                if (template) {
                                  const template_config = this.state.template_cache[template.value]
                                  if (
                                    (!this.state.template_config && template_config) ||
                                        (
                                          this.state.template_config
                                          && this.state.template_config.name !== template.value
                                        )
                                  ) {
                                    this.setState({
                                      template_config,
                                      initvals: { ...this.initModel(template_config, formik.values) }
                                    })
                                  }
                                  window.parent.postMessage({
                                    type: 'VALUES',
                                    data: formik.values
                                  }, this.props.origin)
                                }
                              }}
                              setContextForm={this.props.actions.setContextForm}
                              render={() => (
                                <div className='row'>
                                  <div className='col'>
                                    {this.addVerb(formik)}
                                  </div>
                                </div>
                              )}
                            />
                          }
                        />
                      </div>
                      <div className='col-md-8'>
                        <Card
                          background={true}
                          header={
                            <>
                              <h3>Brochure Preview</h3>
                              <div className="details-section-buttons tablemeta">
                                {this.state.url ? (
                                  <Button
                                    type="button"
                                    icon="#icon16-Download"
                                    className="btn btn-icon-16 btn-icon-left btn-secondary"
                                    onClick={e => {
                                      this.download(e, formik.values, template)
                                    }}
                                  >Download</Button>
                                ) : null}
                              </div>
                            </>
                          }
                          body={
                            <div className='pdfcontainer'>
                              {this.state.url ? (
                                <nav id='pdfcontainer-nav' className='pdfcontainer-nav'>
                                  <div className="nav-group">
                                    <div className="pagejump">
                                      <div className="form-group page">
                                            Page
                                        <div className="forminput">
                                          <input
                                            type="number"
                                            className="form-control form-control-lg page-jump"
                                            min={1}
                                            value={this.state.page}
                                            onKeyDown={this.isEnter}
                                            onChange={e => this.setState({ page: e.target.value })}
                                          />
                                        </div>
                                            /  {this.state.numPages}
                                      </div>
                                    </div>
                                    <Button icon="#icon16-ChevronLeft" title="Previous Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page <= 1} onClick={this.goToPrevPage} />
                                    <Button icon="#icon16-ChevronRight" title="Next Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page === this.state.numPages} onClick={this.goToNextPage} />
                                  </div>
                                  <div className="nav-group">{ /* We hide this nav-group in mobi view */ }
                                    <SelectInput
                                      id="zoom"
                                      form={formik}
                                      onChange={opt => this.setState({ zoom: opt.value }) }
                                      noclear
                                      field={{
                                        name: 'zoom',
                                        value: this.state.zoom
                                      }}
                                      options={[
                                        {
                                          value: 'auto', label: `Auto Fit (${parseFloat(this.state.default * 100).toFixed(0)}%)`
                                        },
                                        ...this.zoomLevels.map(zoom => ({ value: zoom, label: `${zoom}%` }))
                                      ]}
                                    />
                                    <Button icon="#icon24-Plus" title="Zoom In" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.zoomIn} />
                                    <Button icon="#icon24-Minus" title="Zoom Out" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.zoomOut} />
                                    <Button icon="#icon24-RotateLeft" title="Rotate CCW 90deg" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.rotateLeft} />
                                    <Button icon="#icon24-RotateRight" title="Rotate CW 90deg" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.rotateRight} />
                                    <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>{(formik.dirty && Object.keys(formik.touched).length) ? 'Update Preview' : 'Preview'}</Button>
                                  </div>
                                </nav>
                              ) : null }
                              <div ref={el => { if (el) { this.el = el } }} className={`react-pdf${this.state.highlight ? ' react-pdf__Highlighting' : ''}${(!this.state.highlight && this.state.scrolling) ? ' react-pdf__Scrolling' : ''}`} style={{ height: this.state.height }}>
                                {formik.isSubmitting ? (
                                  <div className="text-center">
                                    <div className="loader inline large"></div><div className="message">{this.state.status ? this.state.status.response.detail : null}</div>
                                  </div>
                                ) : (
                                  <Document
                                    file={this.state.url}
                                    loading={<Loader onError={() => {}} throwViewError={() => {}} />}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    onMouseDown={this.onMouseDown}
                                    onScroll={this.onMouseMove}
                                    rotate={this.state.rotate}
                                    noData={(
                                      <div className="no-pdf">
                                        <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>Preview PDF</Button>
                                      </div>
                                    )}
                                  >
                                    <Page
                                      pageNumber={parseInt(this.state.pageNumber, 10)}
                                      renderTextLayer={this.state.highlight}
                                      scale={this.state.scale}
                                    />
                                  </Document>
                                )}
                              </div>
                            </div>
                          }
                        />
                        <Card
                          background={true}
                          header={
                            <h3>Powered by Prop Data</h3>
                          }
                          bodyclass="nopadding"
                          body={
                            <div className="canvas-footer">
                              <div className="pd-logo"></div>
                              <div className='pd-callnow'>
                                <div className='pd-powered'>
                                  Need more functionality?<br />Want to change the design?<br />
                                  <a href="https://go.propdata.net/general-enquiry?utm_campaign=Lightstone%20Canvassing%20Referral&utm_source=lightstone-toolkit&utm_medium=lightstone-toolkit-referral" target="_blank" rel="noreferrer">Contact us</a>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </Scrollbar>
                </div>
              </div>
            )
          } }
        </Formik>
      </div>
    )
  }
}


Canvassing.propTypes = {
  user: PropTypes.object,
  agent: PropTypes.object,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  origin: PropTypes.string,
  config: PropTypes.object,
  search: PropTypes.string,
  ui: PropTypes.object,
  nav: PropTypes.object,
  match: PropTypes.object,
  addons: PropTypes.array,
  toggleAlerts: PropTypes.func,
  // toggleMenu: PropTypes.func,
  toggleNav: PropTypes.func
}

export default Canvassing
