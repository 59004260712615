/* eslint-disable new-cap */
import { getIn, hasIn, Map, List, fromJS, mergeDeep, is } from 'immutable'

import config from '../config/config.json' // This must be imported separately
import { mergeDeepArrays } from '../utils'
import log from '../logging'


export default (newstate = fromJS(config.defaultState.user), action) => {
  if (newstate.get('token') || [
    'RELOGIN_SUCCESS',
    'LOGIN_SUCCESS',
    'SELECT_AGENT',
    'SELECT_AGENT_SUCCESS',
    'SELECT_STORED_AGENT',
    'MERGED_CONFIGS_SUCCESS',
    'UNREGISTER_WEBSOCKET'
  ].includes(action.type)) { // Only reduce user action if there is a token
    switch (action.type) {
      case 'RELOGIN_SUCCESS':
      case 'LOGIN_SUCCESS': {
        try {
          const token = null
          localStorage.setItem('token', token)
          const perms = []
          const { body } = action
          if (body.is_prop_data_user) { perms.push('is_prop_data_user') }
          const resolved = {
            auth: true,
            token: token,
            ...body,
            permissions: perms
          }
          // Prevent duplicate agents in list
          if (action.type === 'RELOGIN_SUCCESS' && newstate.get('agents')) {
            newstate = newstate.delete('agents')
          }
          return newstate.mergeDeep(fromJS(resolved))
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'MERGED_CONFIGS_SUCCESS':
        try {
          return newstate.setIn([ 'configs' ], Map(action.configs))
        } catch (e) {
          log.error(e)
          break
        }

      case 'UNSELECT_AGENT':
        try {
          localStorage.removeItem('agent')
          localStorage.removeItem('site')
          newstate = newstate.set('token', null)
          newstate = newstate.set('agent', false)
          newstate = newstate.set('preferences', null)
          newstate = newstate.set('configs', Map())
          newstate = newstate.set('alerts', List())
          newstate = newstate.set('selected', Map())
          newstate = newstate.delete('retoke')
          return newstate
        } catch (e) {
          log.error(e)
          break
        }

      case 'SELECT_AGENT_SUCCESS': {
        try {
          const { user, settings } = action
          let { agent } = action
          const configs = getIn(newstate, [ 'configs' ])
          // if (newstate.get('agent')) { agent = newstate.mergeDeepIn(['agent'], agent) }
          if (user.is_prop_data_user) {
            const actual = user.agents.find(a => a.site.domain === 'propdata.net' && a.id) // Find actual agent details for Prop Data user. ID is 0 for all sites except propdata.net
            const preferences = actual.preferences.filter(p => p.site === agent.site.id).map(p => {
              p.column_preferences = p.column_preferences.map(pref => {
                try {
                  pref.name = JSON.parse(pref.name)
                } catch (e) {
                  return pref
                }
                return pref
              })
              return p
            }) // Pull preferences for Prop Data user from actual agent
            agent = fromJS(mergeDeep(agent, {
              first_name: actual.first_name,
              email: actual.email,
              image_url: actual.image_url,
              profile_picture_coord_x: actual.profile_picture_coord_x,
              profile_picture_coord_y: actual.profile_picture_coord_y,
              profile_picture_width: actual.profile_picture_width,
              profile_picture_height: actual.profile_picture_height,
              last_name: actual.last_name,
              preferences
            })) // Decorate selected Super User agent with actual details
          } else {
            const preferences = agent.preferences.filter(p => p.site === agent.site.id).map(p => {
              p.column_preferences = p.column_preferences.map(pref => {
                try {
                  pref.name = JSON.parse(pref.name)
                } catch (e) {
                  return pref
                }
                return pref
              })
              return p
            }) // Pull preferences for Prop Data user from actual agent
            agent = fromJS(mergeDeep(agent, {
              preferences
            }))
            agent = fromJS(agent)
          }

          let defaults = Map()
          configs.keySeq().toArray().forEach(key => { // Does not include defaultState
            if (hasIn(configs, [ key, 'tableconfig' ])) { defaults = defaults.setIn([ key ], getIn(configs, [ key, 'tableconfig' ])) }
          })
          // Find all the available preferences
          const groupedPreferences = agent.get('preferences').groupBy(preference => preference.get('view'))

          // Find all the active preferences
          let activePreferences = agent.get('preferences').filter(item => item.get('active')).groupBy(preference => preference.get('view'))

          let availablePreferences = Map()

          let newPreferences = {}
          defaults.keySeq().toArray().forEach(group => {
            // Set the default preferences
            if (!activePreferences.has(group)) {
              availablePreferences = availablePreferences.set(group, List([ Map({ id: 0, name: 'Default', column_preferences: defaults.getIn([ group ]), active: true }) ]))
              activePreferences = activePreferences.set(group, List([ availablePreferences.getIn([ group, 0 ]) ]))
            } else {
              availablePreferences = availablePreferences.set(group, List([ Map({ id: 0, name: 'Default', column_preferences: defaults.getIn([ group ]) }) ]))
            }
            // Merge grouped preferences and availablePreferences
            if (groupedPreferences.has(group)) {
              availablePreferences = mergeDeepArrays(availablePreferences, groupedPreferences, 'name')
            }
            // Index the preferences
            availablePreferences.get(group).forEach(item => {
              newPreferences[group] = newPreferences[group] || {}
              newPreferences[group][item.get('id')] = item
            })
          })
          newPreferences = fromJS(newPreferences)

          let preferences = defaults
          configs.keySeq().toArray().forEach(key => { // Does not include defaultState
            if (activePreferences.has(key)) {
              preferences = preferences.set(key, activePreferences.getIn([ key, 0 ]))
            }
          })
          // decorate columns with additional props from fields
          preferences.keySeq().toArray().forEach(group => {
            preferences.setIn([ group, 'column_preferences' ], preferences.getIn([ group, 'column_preferences' ]).map(col => {
              // const new_col = new Column(col)
              const fields = configs.getIn([ group, 'fields' ])
              const additional_props = fields.find(
                field => is(col.get('name'), field.get('name')))

              if (col.get('children')) {
                col = col.set('children', col.get('children').map(child => {
                  const child_props = fields.find(
                    field => is(child.get('name'), field.get('name')))
                  return child.merge(child_props)
                }))
              }
              // new_col = new_col.merge(additional_props)
              return col.merge(additional_props)
            }))
          })

          newPreferences.keySeq().toArray().forEach(group => {
            newPreferences.get(group).keySeq().toArray().forEach(id => {
              const column_preferences = newPreferences.getIn([ group, id, 'column_preferences' ]).map(col => {
                // const new_col = new Column(col)
                const fields = configs.getIn([ group, 'fields' ])
                const additional_props = fields.find(
                  field => is(col.get('name'), field.get('name')))

                if (col.get('children')) {
                  col = col.set('children', col.get('children').map(child => {
                    const child_props = fields.find(
                      field => is(child.get('name'), field.get('name')))
                    return child.merge(child_props)
                  }))
                }

                return col.merge(additional_props) // new_col.extend(additional_props)
              })
              newPreferences = newPreferences.setIn([ group, id, 'column_preferences' ], column_preferences)
            })
          })

          let perms = List()
          if (agent.has('permissions')) {
            agent.get('permissions').keySeq().toArray().forEach(k => {
              if (agent.getIn([ 'permissions', k ]) === true) {
                perms = perms.push(k)
              }
            })
          }
          // if (action.user.is_prop_data_user && agent.id === null) { perms.push('is_prop_data_user') }
          if (action.user.is_superuser) { perms = perms.push('is_superuser') }
          if (agent.getIn([ 'permissions', 'apply_to_all_branches' ])) {
            agent = agent.set('branches_allowed', null) // Set to null to allow all
          } else {
            agent = agent.set('branches_allowed', agent.get('branches'))
          }
          localStorage.setItem('token', agent.get('token'))
          localStorage.setItem('agent', agent.get('id'))
          localStorage.setItem('site', agent.getIn([ 'site', 'id' ]))

          agent = agent.setIn([ 'site', 'addons' ], configs.getIn([ 'settings', 'fields' ]).filter(f => f.group === 'Add-ons' && settings.get(f.name)).map(f => f.name))

          newstate = newstate.delete('retoke')

          return newstate.mergeDeep(fromJS({
            agent,
            alerts: [],
            token: agent.get('token'),
            preferences,
            availablePreferences: newPreferences,
            permissions: perms,
            expires: action.decoded.exp * 1000
          }))
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'RENEW_TOKEN_SUCCESS':
      case 'TOKE_SUCCESS': {
        try {
          localStorage.setItem('token', action.r.token)
          const diff = { token: action.r.token, expires: action.decoded.exp * 1000 }
          return newstate.merge(fromJS(diff))
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'LOAD_TOKE_ERROR': {
        try {
          // Renewing token failed - bomb it
          // window.localStorage.removeItem('token')
          // window.localStorage.removeItem('agent')
          // window.localStorage.removeItem('site_id')
          const diff = { ...newstate, token: false }
          return newstate.mergeDeep(Map(fromJS(diff)))
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'FETCH_ONE_SUCCESS':
      case 'CREATE_MODEL_SUCCESS':
      case 'UPDATE_MODEL_SUCCESS':
      case 'UPDATE_HOME_PAGE_SUCCESS':
      case 'CHANGE_CAPTION_SUCCESS':
      case 'FETCH_ACTIVITY_SUCCESS':
      case 'FETCH_MANY_SUCCESS':
      case 'FETCH_PORTAL_LOGS_SUCCESS':
      case 'CREATE_PORTAL_SUCCESS': {
        try {
          localStorage.setItem('token', action.token)
          if (action.delta && action.delta.settings) { // Reduce the site settings
            const siteid = newstate.getIn([ 'agent', 'site', 'id' ])
            if (siteid) {
              const branding = {
                brand_logo: action.delta.settings[siteid].meta.brand_logo || newstate.getIn([ 'agent', 'site', 'logo' ]),
                brand_logo_bg: action.delta.settings[siteid].brand_logo_bg,
                brand_primary: action.delta.settings[siteid].brand_primary,
                brand_secondary: action.delta.settings[siteid].brand_secondary,
                website_url: action.delta.settings[siteid].website_url
              }
              newstate = newstate.mergeDeepIn([ 'agent', 'site' ], fromJS(branding))
            }
          }
          const agentid = newstate.getIn([ 'agent', 'id' ])
          if (action.delta && action.delta.agents &&
            action.delta.agents[agentid] && action.delta.agents[agentid].meta.image) { // Reduce the agent profile pic settings
            newstate = newstate.setIn([ 'agent', 'image_url' ], action.delta.agents[agentid].meta.image.file)
            newstate = newstate.setIn([ 'agent', 'profile_picture_coord_x' ], action.delta.agents[agentid].profile_picture_coord_x)
            newstate = newstate.setIn([ 'agent', 'profile_picture_coord_y' ], action.delta.agents[agentid].profile_picture_coord_y)
            newstate = newstate.setIn([ 'agent', 'profile_picture_width' ], action.delta.agents[agentid].profile_picture_width)
            newstate = newstate.setIn([ 'agent', 'profile_picture_height' ], action.delta.agents[agentid].profile_picture_height)
          }
          return newstate.set('token', action.token)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'SEARCH_SUCCESS':
        try {
          localStorage.setItem('token', action.token)
          newstate = newstate.setIn([ 'selected', action.modelname ], List())
          return newstate.merge(fromJS({
            token: action.token
          }))
        } catch (e) {
          log.error(e)
          break
        }

      case 'UPDATE_TABLE_CONFIG': {
        try {
          let preferences = newstate.get('preferences')
          const configs = getIn(newstate, [ 'configs' ])
          let newprefs = fromJS(action.data)
          newprefs = newprefs.map(col => {
            const fields = configs.getIn([ action.modelname, 'fields' ])
            let additional_props = fields.find(field => is(col.get('name'), field.get('name')))
            if (!additional_props) {
              additional_props = fields.find(
                field => is(col.get('name'), JSON.stringify(field.get('name'))))
            }
            if (col.get('children')) {
              col = col.set('children', col.get('children').map(child => {
                const child_props = fields.find(
                  field => is(child.get('name'), field.get('name')))
                return child.merge(child_props)
              }))
            }
            return col.merge(additional_props)
          })
          preferences = preferences.setIn([ action.modelname, 'column_preferences' ], newprefs)
          return newstate.set('preferences', preferences)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'ADD_TABLE_FIELD_SUCCESS': {
        try {
          let preferences = newstate.get('preferences')
          const configs = getIn(newstate, [ 'configs' ])
          const action_field = fromJS(action.field)
          const field = configs.getIn([ action.modelname, 'fields' ]).find(fld => is(action_field, fld.get('name')) && !fld.get('protected'))
          const parent = configs.getIn([ action.modelname, 'fields' ])
            .find(fld => is(fld.get('name'), field.get('tableparent')))

          let grouper = Map()
          let gidx = -1
          if (parent) {
            grouper = preferences.getIn([ action.modelname, 'column_preferences' ]).find(item => item.get('children') && is(item.get('name'), parent.get('name')))
            gidx = preferences.getIn([ action.modelname, 'column_preferences' ]).indexOf(grouper)
            if (!grouper) {
              grouper = parent
              grouper = grouper.set('children', List())
            }
          }
          if (field) {
            let colprefs = preferences.getIn([ action.modelname, 'column_preferences' ])
            if (parent) {
              let newgroup = grouper.get('children')
              newgroup = newgroup.push(field)
              grouper = grouper.set('children', newgroup)
              colprefs = gidx === -1 ? colprefs.push(grouper) : colprefs.set(gidx, grouper)
              preferences = preferences.setIn([ action.modelname, 'column_preferences' ], colprefs)
            } else {
              colprefs = colprefs.push(field)
              preferences = preferences.setIn([ action.modelname, 'column_preferences' ], colprefs)
            }
          }
          return newstate.set('preferences', preferences)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'REMOVE_TABLE_FIELD_SUCCESS': {
        /* Creates a deep copy of the current preferences.
        * After this is loops over copy and removes the single
        * field. This is due to the preferences having several
        * layers of depth.
        */
        try {
          let column_preferences = newstate.getIn([ 'preferences', `${action.modelname}`, 'column_preferences' ])
          const action_field = fromJS(action.field)
          column_preferences.forEach((field, ind) => {
            if (is(field.get('name'), action_field)) {
              column_preferences = column_preferences.delete(ind)
            } else if (field.has('children')) {
              field.get('children').forEach((child, i) => {
                if (is(child.get('name'), action_field)) {
                  let parent = column_preferences.get(ind)
                  parent = parent.deleteIn([ 'children', i ])
                  column_preferences = column_preferences.set(ind, parent)
                }
              })
              if (column_preferences.getIn([ ind, 'children' ]) && !column_preferences.getIn([ ind, 'children' ]).count()) {
                column_preferences = column_preferences.delete(ind)
              }
            }
          })
          return newstate.setIn([ 'preferences', `${action.modelname}`, 'column_preferences' ], column_preferences)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'CREATE_PREFERENCE_SUCCESS': {
        try {
          let preferences = newstate.get('preferences')
          let availablePreferences = newstate.get('availablePreferences')
          const configs = getIn(newstate, [ 'configs' ])
          let selectedPreference = Map({})
          const data = fromJS(action.data)
          availablePreferences = availablePreferences.setIn([ `${action.modelname}`, `${action.data.id}` ], data)
          if (action.data.active) {
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, `${action.data.id}`, 'active' ], action.data.active)
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, '0', 'active' ], false)
            selectedPreference = availablePreferences.getIn([ `${action.modelname}`, `${action.data.id}` ])
          } else {
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, `${action.data.id}`, 'active' ], false)
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, '0', 'active' ], true)
            selectedPreference = availablePreferences.getIn([ `${action.modelname}`, '0' ])
          }
          const fields = configs.getIn([ action.modelname, 'fields' ])
          selectedPreference = selectedPreference.set('column_preferences', selectedPreference.get('column_preferences').map(col => { // pupulate props from config
            let additional_props
            additional_props = fields.find(
              field => is(col.get('name'), field.get('name')))
            if (!additional_props) {
              additional_props = fields.find(
                field => is(col.get('name'), JSON.stringify(field.get('name'))))
            }
            if (col.get('children')) {
              col = col.set('children', col.get('children').map(child => {
                const child_props = fields.find(
                  field => is(child.get('name'), field.get('name')))
                return child.merge(child_props)
              }))
            }
            return col.merge(additional_props)
          }))

          preferences = preferences.set(action.modelname, selectedPreference)
          localStorage.setItem('token', action.token)
          newstate = newstate.set('preferences', preferences)
          newstate = newstate.set('availablePreferences', availablePreferences)
          return newstate.set('token', action.token)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'UPDATE_PREFERENCE_SUCCESS': {
        try {
          let preferences = newstate.get('preferences')
          let availablePreferences = newstate.get('availablePreferences')
          const configs = getIn(newstate, [ 'configs' ])
          let selectedPreference = Map({})
          if (action.data.active) {
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, `${action.data.id}`, 'active' ], action.data.active)
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, '0', 'active' ], false)
            selectedPreference = selectedPreference.mergeDeep(availablePreferences.getIn([ `${action.modelname}`, `${action.data.id}` ]))
          } else {
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, `${action.data.id}`, 'active' ], false)
            availablePreferences = availablePreferences.setIn([ `${action.modelname}`, '0', 'active' ], true)
            selectedPreference = selectedPreference.mergeDeep(availablePreferences.getIn([ `${action.modelname}`, '0' ]))
          }
          const fields = configs.getIn([ action.modelname, 'fields' ])
          selectedPreference = selectedPreference.set('column_preferences', selectedPreference.get('column_preferences').map(col => { // pupulate props from config
            let additional_props = fields.find(
              field => is(col.get('name'), field.get('name')))
            if (!additional_props) { // Maybe the name is an array of fields ie. address
              additional_props = fields.find(
                field => is(col.get('name'), JSON.stringify(field.get('name'))))
            }
            if (col.get('children')) {
              col = col.set('children', col.get('children').map(child => {
                const child_props = fields.find(
                  field => is(child.get('name'), field.get('name')))
                return child.merge(child_props)
              }))
            }
            return col.merge(additional_props)
          }))

          preferences = preferences.set(action.modelname, selectedPreference)
          localStorage.setItem('token', action.token)
          newstate = newstate.set('preferences', preferences)
          newstate = newstate.set('availablePreferences', availablePreferences)
          return newstate.set('token', action.token)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'DELETE_PREFERENCE_SUCCESS': {
        try {
          let preferences = newstate.get('preferences')
          let availablePreferences = newstate.get('availablePreferences')
          const { modelname, id } = action.data

          if (availablePreferences.hasIn([ `${modelname}`, `${id}` ])) {
            if (availablePreferences.hasIn([ `${modelname}`, `${id}`, 'active' ])) { // switch to default if current is deleted
              availablePreferences = availablePreferences.setIn([ `${modelname}`, '0', 'active' ], true)
              preferences = preferences.setIn([ `${modelname}` ], availablePreferences.getIn([ `${modelname}`, '0' ]))
            }
            availablePreferences = availablePreferences.deleteIn([ `${modelname}`, `${id}` ])
          }

          localStorage.setItem('token', action.token)
          newstate = newstate.set('preferences', preferences)
          newstate = newstate.set('availablePreferences', availablePreferences)
          return newstate.set('token', action.token)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'DISCONNECT_WEBSOCKET':
        try {
          return newstate.merge(fromJS({ ws: -1 }))
        } catch (e) {
          log.error(e)
          break
        }

      case 'UNREGISTER_WEBSOCKET':
        try {
          return newstate.merge(fromJS({ ws: false }))
        } catch (e) {
          log.error(e)
          break
        }

      case 'DISPATCH_WEBSOCKET_REQUEST':
        try {
          return newstate.merge(fromJS({ ws: JSON.stringify(action.payload) }))
        } catch (e) {
          log.error(e)
          break
        }

      case 'PUSH_ALERT': {
        try {
          let messages = newstate.get('messages')
          let alerts = newstate.get('alerts')
          const message_idx = messages.findIndex(a => a && a.get('callback_id') === action.data.payload.callback_id)
          const alert_idx = alerts.findIndex(a => a && a.get('callback_id') === action.data.payload.callback_id)
          if (
            message_idx === -1 &&
            action.data.payload.action !== 'brochure' &&
            action.data.payload.template !== 'leads-analysis-report'
          ) {
            if (alert_idx === -1) { // Initialise new alert
              alerts = alerts.unshift(fromJS(action.data.payload))
              if (action.data.resolve) {
                alerts = alerts.setIn([ 0, 'resolve' ], action.data.resolve)
              }
              if (action.data.callback) {
                alerts = alerts.setIn([ 0, 'callback' ], action.data.callback)
              }
              // Do some initialisation for server initiated alerts
              if (action.data.payload.response && action.data.payload.response.created) {
                alerts = alerts.setIn([ 0, 'created' ], action.data.payload.response.created)
              }
              if (action.data.payload.status === 200) {
                alerts = alerts.setIn([ 0, 'text' ], 'Ready for download')
              }
              if (action.data.payload.name) {
                alerts = alerts.setIn([ 0, 'name' ], action.data.payload.name)
              }
              if (action.data.payload.response && action.data.payload.response.detail) {
                alerts = alerts.setIn([ 0, 'text' ], action.data.payload.response.detail)
              }
            } else if (action.data.payload.response) { // Update an existing alert with response
              if (alerts.has(alert_idx)) {
                alerts = alerts.setIn([ alert_idx, 'response' ], fromJS(action.data.payload.response))
                if (action.data.payload.response.file) {
                  alerts = alerts.setIn([ alert_idx, 'text' ], 'Ready for download')
                }
                if (action.data.payload.text) {
                  alerts = alerts.setIn([ alert_idx, 'text' ], action.data.payload.text)
                }
                if (action.data.payload.response?.detail) {
                  alerts = alerts.setIn([ alert_idx, 'text' ], action.data.payload.response.detail)
                }
                if (action.data.payload.status > 300) {
                  if (action.data.payload.response.detail) {
                    alerts = alerts.setIn([ alert_idx, 'text' ], action.data.payload.response.detail)
                  } else {
                    alerts = alerts.setIn([ alert_idx, 'text' ], 'Error while generating')
                  }
                }
              }
            }
          } else if (message_idx !== -1 && action.data.payload.response) {
            let currentmessage = messages.get(message_idx)
            currentmessage = currentmessage.set('response', fromJS(action.data.payload.response))
            if (action.data.payload.template === 'leads-analysis-report' && action.data.payload.status === 200) {
              currentmessage = currentmessage.set('text', 'Report generated successfully')
              if (currentmessage.has('resolve')) {
                currentmessage.get('resolve')(action.data.payload)
              }
            }
            if (action.data.payload.response.file) {
              currentmessage = currentmessage.set('text', 'Ready for download')
              if (currentmessage.has('resolve')) {
                currentmessage.get('resolve')(action.data.payload)
              }
            }
            if (action.data.payload.text) {
              currentmessage = currentmessage.set('text', action.data.payload.text)
            }
            if (action.data.payload.response?.detail) {
              currentmessage = currentmessage.set('text', action.data.payload.response.detail)
            }
            if (action.data.payload.status > 300) {
              if (action.data.payload.response.detail) {
                currentmessage = currentmessage.set('text', action.data.payload.response.detail)
              } else {
                currentmessage = currentmessage.set('text', 'Error while generating')
              }
              if (currentmessage.has('reject')) {
                currentmessage.get('reject')(action.data.payload)
              }
            }
            if (currentmessage && currentmessage.has('callback')) { currentmessage.get('callback')(action.data.payload) }
            messages = messages.set(message_idx, currentmessage)
          }
          newstate = newstate.set('messages', messages)
          newstate = newstate.set('alerts', alerts)
          return newstate
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'PUSH_MESSAGE': {
        try {
          let messages = newstate.get('messages')
          let currentmessage = messages.find(a => a.get('callback_id') === action.data.payload.callback_id)
          if (!currentmessage) { // Initialise new alert
            messages = messages.unshift(fromJS(action.data.payload))
            if (action.data.callback) {
              messages = messages.setIn([ 0, 'callback' ], action.data.callback)
            }
            if (action.data.payload.template === 'leads-analysis-report') {
              messages = messages.setIn([ 0, 'resolve' ], action.data.resolve)
            }
            // Do some initialisation for server initiated messages
            if (action.data.payload.response && action.data.payload.response.created) {
              messages = messages.setIn([ 0, 'created' ], action.data.payload.response.created)
            }
            if (action.data.payload.status === 200) {
              messages = messages.setIn([ 0, 'text' ], 'Ready for download')
              if (action.data.resolve) { action.data.resolve(action.data.payload) }
            }
            if (action.data.callback) { action.data.callback(action.data.payload) }
          } else if (currentmessage && action.data.payload.response) { // Update an existing alert with response
            currentmessage = currentmessage.set('response', action.data.payload.response)
            if (action.data.payload.response.file) {
              currentmessage = currentmessage.set('text', 'Ready for download')
              if (currentmessage.has('resolve')) { currentmessage.get('resolve')(action.data.payload) }
            }
            if (action.data.payload.status > 300) {
              if (action.data.payload.response.detail) {
                currentmessage = currentmessage.set('text', action.data.payload.response.detail)
              } else {
                currentmessage = currentmessage.set('text', 'Error while generating')
              }
              if (currentmessage.has('reject')) { currentmessage.get('reject')(action.data.payload) }
            }
            if (currentmessage.has('callback') && currentmessage.get('callback')) {
              currentmessage.get('callback')(action.data.payload)
            }
          }
          return newstate.set('messages', messages)
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'DISMISS_ALERT': {
        try {
          let alerts = newstate.get('alerts')
          if (alerts.findIndex(a => a.get('callback_id') === action.data.callback_id) > -1) {
            alerts = alerts.delete(alerts.findIndex(a => a.get('callback_id') === action.data.callback_id))
            newstate = newstate.set('alerts', alerts)
          }
          return newstate
        } catch (e) {
          log.error(e)
          break
        }
      }

      case 'AUTOSAVE_FORM_SUCCESS': {
        try {
          localStorage.setItem('token', action.token)
          return newstate.set('token', action.token)
        } catch (e) {
          log.error(e)
          break
        }
      }
      case 'DO_LOGOUT':
      case 'TOKE_ERROR': { // Remove token from store and return default state
        try {
          localStorage.removeItem('token')
          localStorage.removeItem('agent')
          localStorage.removeItem('site')
          const retoke = newstate.get('retoke')
          newstate = fromJS(config.defaultState.user)
          if (action.type === 'TOKE_ERROR') {
            newstate = newstate.set('retoke', retoke)
          }
          return newstate
        } catch (e) {
          log.error(e)
          break
        }
      }
      default:
        return newstate
    }
  }
  return newstate
}
