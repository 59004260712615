import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'
import { Button } from '../ui/Button'
import { valueFormat, sortBy } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


class Syndication extends React.Component {
  constructor(props) {
    super(props)
    const { portals } = props
    const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree', 'hamptons-international', 'rightmove', 'property24-developments', 'facebook' ].includes(portals[pk].meta.portal.slug)).map(pk => {
      portals[pk].index = [ 'property24', 'private-property', 'gumtree', 'hamptons-international', 'rightmove', 'property24-developments', 'facebook' ].indexOf(portals[pk].meta.portal.slug)
      return portals[pk]
    })
    const sorted = sortBy(active, 'index')
    this.state = {
      active: sorted,
      branches: [],
      syncing: []
    }
  }

  componentDidMount() {
    const { model, modelname, actions } = this.props
    this.state.active.map(portal => {
      const log = model.meta.portals ? model.meta.portals.find(p => p.portal === portal.portal) : null
      if (modelname === 'articles' && log && log.references) {
        const page_ids = []
        log.references.forEach(r => {
          const page_id = r.split('_')[0]
          page_ids.push(page_id)
        })

        if (page_ids.length) {
          /* eslint-disable no-new */
          new Promise((resolve, reject) => {
            actions.fetchMany({
              values: {
                modelname: 'branches',
                select: true,
                fields: [ 'id', 'facebook_profile_id', 'name' ],
                filters: {
                  facebook_profile_id__in: page_ids
                }
              },
              resolve,
              reject
            })
          }).then(r => {
            this.setState({ branches: r.options })
          })
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.portals, this.props.portals)) {
      const { portals } = this.props
      const active = Object.keys(portals).filter(pk => portals[pk].active === true && [ 'property24', 'private-property', 'gumtree', 'hamptons-international', 'rightmove', 'property24-developments', 'facebook' ].includes(portals[pk].meta.portal.slug)).map(pk => {
        portals[pk].index = [ 'property24', 'private-property', 'gumtree', 'hamptons-international', 'rightmove', 'property24-developments', 'facebook' ].indexOf(portals[pk].meta.portal.slug)
        return portals[pk]
      })
      const sorted = sortBy(active, 'index')
      this.setState({ active: sorted })
    }
    if (!isEqual(prevProps.model.meta.portals, this.props.model.meta.portals)) {
      this.props.model.meta.portals.forEach(portal => {
        const { name } = portal.meta.portal
        const prev = prevProps.model.meta.portals.find(p => p.id === portal.id)
        if (prev.last_message !== portal.last_message && this.state.syncing.includes(name)) {
          const syncing = [ ...this.state.syncing ].filter(p => p !== name)
          this.setState({ syncing })
        }
      })
    }
  }

  render() {
    const { user, model, servicename, modelname, actions, permissions, agentid } = this.props
    let config = false
    let hasOwnPerms = false
    if ([ 'residential', 'holiday', 'commercial', 'projects' ].includes(modelname)) {
      if ([ model.agent, model.agent_2, model.agent_3, model.agent_4 ].includes(agentid)) {
        hasOwnPerms = true
      }
    }
    if (model.meta.portals) {
      model.meta.portals.forEach(p => {
        if (this.state.active.find(pa => pa.portal === p.portal)) { config = true}
      })
    }
    if (!config || !model.portals.length) {
      return (
        <div className="portals">
          <Card
            key={'syndication-table'}
            background
            body={
              <div className="cardbody feedlogtbl">
                Syndication is not yet configured.
              </div>
            }
          />
        </div>
      )
    }
    return (
      <div className="portals">
        <div style={{ marginBottom: 15 }}>
          <Button
            type="button"
            icon="#icon16-Refresh"
            className="btn btn-white btn-icon btn-icon-16 btn-icon-left"
            onClick={() => {
              actions.fetchOne(modelname, model.id, () => {}, () => {})
            }}>
            <span className="label">Refresh All</span>
          </Button>
        </div>
        {this.state.active.map((portal, pidx) => {
          const log_ref = model.meta.portals ? model.meta.portals.find(p => p.portal === portal.portal) : null
          if (!log_ref) { return null }
          const log = merge({}, log_ref)
          delete log.meta // Dont need all the other meta as it inhibts isEqual comparison when child SimpleTable updates
          const logs = []
          if (modelname === 'articles' && log.references) {
            log.references.forEach(r => {
              const [ page_id, post_id ] = r.split('_')
              logs.push({
                ...log,
                reference: r,
                page_id,
                post_id,
                meta: {
                  branch: this.state.branches.find(b => b.facebook_profile_id === page_id)
                }
              })
            })
          }
          let header
          if (modelname === 'agents') {
            header = [
              {
                label: 'Branch',
                name: 'branches',
                link: '/secure/:site/branches/:branch',
                modelname: 'agent',
                metafield: 'branch'
              },
              {
                label: 'Status',
                name: 'feed_status',
                format: 'feedstatus',
                classes: [ 'text-center' ]
              },
              {
                label: 'Last Updated',
                name: 'modified',
                format: 'datetime'
              },
              {
                label: 'Portal Ref',
                name: 'reference'
              },
              {
                label: 'Message',
                name: 'last_message',
                tooltip: true
              }
            ]
          } else if (modelname === 'articles') {
            header = [
              {
                label: 'Article ID',
                name: 'meta.article.id',
                link: '/secure/:site/:meta.article.model/:article'
              },
              {
                label: 'Status',
                name: 'feed_status',
                format: 'feedstatus',
                classes: [ 'text-center' ]
              },
              {
                label: 'Last Updated',
                name: 'modified',
                format: 'datetime'
              },
              {
                label: 'Branch Name',
                name: 'meta.branch.name',
                link: '/secure/:site/branches/:meta.branch.id'
              },
              {
                label: 'Page ID',
                name: 'page_id'
              },
              {
                label: 'Post ID',
                name: 'post_id'
              }
            ]
          } else {
            header = [
              {
                label: 'Web Ref',
                name: 'meta.listing.web_ref',
                link: '/secure/:site/:meta.listing.model/:listing'
              },
              {
                label: 'Status',
                name: 'feed_status',
                format: 'feedstatus',
                classes: [ 'text-center' ]
              },
              {
                label: 'Last Updated',
                name: 'modified',
                format: 'datetime'
              },
              {
                label: 'Portal Ref',
                name: 'reference'
              },
              {
                label: 'Message',
                name: 'last_message',
                tooltip: true
              }
            ]
          }

          return (
            <Card
              key={`syndication-table-${pidx}`}
              background
              header={
                <>
                  <h3 className="flex-heading">{portal.meta.portal.name}</h3>
                  {[ 1, 2, 32, 34 ].includes(portal.portal) &&
                    (log.reference || log.active) &&
                    (permissions.includes('is_prop_data_user') ||
                    ([ 'syndication_users_can_sync', 'users_update' ].every(p => permissions.includes(p)) && modelname === 'agents') ||
                    ([ 'syndication_users_can_sync', 'users_update_own' ].every(p => permissions.includes(p)) && modelname === 'agents' && model.id === agentid) ||
                    ([ 'syndication_listings_can_sync', 'listings_commercial_for_sale_update' ].every(p => permissions.includes(p)) && modelname === 'commercial' && model.listing_type === 'For Sale') ||
                    ([ 'syndication_listings_can_sync', 'listings_commercial_for_sale_update_own' ].every(p => permissions.includes(p)) && modelname === 'commercial' && model.listing_type === 'For Sale' && hasOwnPerms) ||
                    ([ 'syndication_listings_can_sync', 'listings_commercial_to_let_update' ].every(p => permissions.includes(p)) && modelname === 'commercial' && model.listing_type === 'To Let') ||
                    ([ 'syndication_listings_can_sync', 'listings_commercial_to_let_update_own' ].every(p => permissions.includes(p)) && modelname === 'commercial' && model.listing_type === 'To Let' && hasOwnPerms) ||
                    ([ 'syndication_listings_can_sync', 'listings_holiday_update' ].every(p => permissions.includes(p)) && modelname === 'holiday') ||
                    ([ 'syndication_listings_can_sync', 'listings_holiday_update_own' ].every(p => permissions.includes(p)) && modelname === 'holiday' && hasOwnPerms) ||
                    ([ 'syndication_listings_can_sync', 'listings_project_update' ].every(p => permissions.includes(p)) && modelname === 'projects') ||
                    ([ 'syndication_listings_can_sync', 'listings_project_update_own' ].every(p => permissions.includes(p)) && modelname === 'projects' && hasOwnPerms) ||
                    ([ 'syndication_listings_can_sync', 'listings_residential_for_sale_update' ].every(p => permissions.includes(p)) && modelname === 'residential' && model.listing_type === 'For Sale') ||
                    ([ 'syndication_listings_can_sync', 'listings_residential_for_sale_update_own' ].every(p => permissions.includes(p)) && modelname === 'residential' && model.listing_type === 'For Sale' && hasOwnPerms) ||
                    ([ 'syndication_listings_can_sync', 'listings_residential_to_let_update' ].every(p => permissions.includes(p)) && modelname === 'residential' && model.listing_type === 'To Let') ||
                    ([ 'syndication_listings_can_sync', 'listings_residential_to_let_update_own' ].every(p => permissions.includes(p)) && modelname === 'residential' && model.listing_type === 'To Let' && hasOwnPerms)) ? (
                      <div className="details-section-buttons">
                        <Button
                          type="button"
                          icon="#icon16-Sync"
                          className="btn btn-primary btn-icon btn-icon-16 btn-icon-left"
                          disabled={this.state.syncing.includes(portal.meta.portal.name)}
                          onClick={() => {
                            this.setState({ syncing: [ ...this.state.syncing, portal.meta.portal.name ] })
                            actions.syndicatePortalItem({
                              portal: portal.portal,
                              item_id: model.id,
                              item_type: servicename
                            })
                          }}>
                          <span className="label">Sync{log.reference && ' Again'}</span>
                        </Button>
                      </div>
                    ) : null }
                </>
              }
              bodyclass="cardbody portals publish-portals"
              body={
                <SimpleTable
                  user={user}
                  paginated
                  config={{
                    modelname: 'feedlog',
                    singular: 'feed log',
                    plural: 'feed logs'
                  }}
                  getClass={ref => {
                    this.table = ref
                  }}
                  parser={data => {
                    data.options = data.options ? data.options.filter(d => d).map(d => {
                      const l = { ...d }
                      l.listing = model.id
                      if (!l.meta) {
                        l.meta = {}
                      }
                      if (modelname === 'agents') {
                        l.meta.agent = model
                      } else if (modelname === 'articles') {
                        l.meta.article = model
                      } else {
                        l.meta.listing = model
                      }
                      return l
                    }) : null
                    return data
                  }}
                  header={header}
                  data={{ options: logs.length ? logs : [ log ], hasMore: false }}
                  rowActions={(row, data) => {
                    if (modelname !== 'agents' && data.reference) {
                      let link = data.reference && ![ 'withdrawn', 'pending', 'disabled' ].includes(data.feed_status) ? valueFormat('feedlink', `View on ${portal.meta.portal.name}`, { portal: portal.meta.portal.slug, reference: data.reference, location: model.meta.location, listing_type: model.listing_type }) : ''
                      if (data.last_message && data.last_message.indexOf('https://www.gumtree.co.za') !== -1) {
                        const gumtree_url = data.last_message.match(/(https:\/\/www.gumtree.co.za\/.+\d)/).pop()
                        link = <Button component="a" rel="noreferrer" className="has-link" href={gumtree_url} target='_blank'>View on Gumtree</Button>
                      }
                      return link
                    }
                    return ''
                  }}
                />
              }
            />
          )
        })}
      </div>
    )
  }
}

Syndication.propTypes = {
  model: PropTypes.object.isRequired,
  modelname: PropTypes.string.isRequired,
  servicename: PropTypes.string.isRequired,
  cache: PropTypes.object,
  portals: PropTypes.object,
  settings: PropTypes.object,
  site: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  permissions: PropTypes.array,
  agentid: PropTypes.oneOfType([
    0, // PD user
    PropTypes.number,
    null
  ])
}

export default Syndication
