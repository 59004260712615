import React from 'react'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'

import { formikConnect } from '../forms/customFormikConnect'
import Loader from '../Loader'
import Modal from './Modal'

/*
 * Custom wrapper for Formik Form which allows us to set the form context
 * inside the formik render method.
*/
class ChallengeModal extends React.Component {
  constructor(props) {
    super(props)
    this.timers = {}
    const actionchallenges = props.challenges.filter(c => c.action === props.action)
    this.state = {
      visible: props.visible,
      has_checks: false,
      checks_passed: true,
      value: '',
      challenges: actionchallenges,
      challenge: actionchallenges[Math.floor(Math.random() * actionchallenges.length)]
    }
  }

  componentDidMount() {
    const challenge = this.state.challenges[Math.floor(Math.random() * this.state.challenges.length)]
    this.setState({ challenge, value: '' })
    if (this.props.preCheck) {
      this.setState({ has_checks: true, checks_passed: false })
      this.props.preCheck(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      const challenge = this.state.challenges[Math.floor(Math.random() * this.state.challenges.length)]
      this.setState({ visible: this.props.visible, challenge, value: '' })
      if (this.props.preCheck && !this.state.has_checks) {
        this.setState({ has_checks: true, checks_passed: false })
        this.props.preCheck(this)
      }
    }
  }

  render() {
    const { buttons, formik, isLoading } = this.props
    let valid = this.state.value === this.state.challenge?.value(formik)
    if (this.state.has_checks && !this.state.checks_passed) { valid = false }
    return (
      <>
        {isLoading ? (
          (<Loader />)
        ) : null }
        <Modal
          title={this.state.checks_passed ? 'Confirm Changes' : 'Unable to delete'}
          visible={this.state.visible}
          buttons={buttons({ valid, value: this.state.value })}
        >
          <div ref={el => { this.el = el }} className="challenge">
            <div className="challenge-text">
              {this.state.checks_passed && this.state.challenge?.text}
            </div>
            <div className="form-group">
              <div className='forminput'>
                <input className="form-control" name="challenge" value={this.state.value} onChange={event => this.setState({ value: event.target.value })} />
                <ErrorMessage component="div" className="error" name="challenge" />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

ChallengeModal.propTypes = {
  onChange: PropTypes.func,
  submitForm: PropTypes.func,
  formik: PropTypes.object,
  challenges: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
  mode: PropTypes.string,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  userid: PropTypes.number,
  modelid: PropTypes.number,
  visible: PropTypes.bool,
  preCheck: PropTypes.func,
  isLoading: PropTypes.bool,
  buttons: PropTypes.func
}

export default formikConnect(ChallengeModal)
