/* eslint-disable new-cap */
import { LOCATION_CHANGE } from 'redux-first-history'
import { Map, List, fromJS, getIn } from 'immutable'
import config from '../config/config.json' // This must be imported separately
import log from '../logging'
// This must be imported separately

export default (newstate = fromJS(config.defaultState.ui), action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { location } = action.payload
      const path_parts = location?.pathname.split('/') || []
      let modelname = getIn(path_parts, [ 3 ], null)
      if (path_parts.includes('secure') && path_parts.length === 3 && !modelname) {
        modelname = 'dashboard'
      }
      let modelid = getIn(path_parts, [ 4 ], null)
      newstate = newstate.set('modelname', modelname)
      if (modelid) {
        modelid = parseInt(modelid, 10)
      }
      if (isNaN(modelid)) {
        modelid = null
      }
      newstate = newstate.set('modelid', modelid)
      let modelaction = null
      if (path_parts.length > 3) {
        modelaction = path_parts.pop()
      }
      newstate = newstate.set('modelaction', modelaction)
      const modelsearch = location.search
      newstate = newstate.set('modelsearch', modelsearch)
      newstate = newstate.set('isLoading', false)
      newstate = newstate.set('awaited', 0)
      newstate = newstate.set('nav', 'mini')
      return newstate
    }

    case 'UNREGISTER_REDIRECT': // Clear the redirect from timeouts etc.
      newstate = newstate.set('redirect', false)
      return newstate

    case 'RESET_SUCCESS':
      newstate = newstate.set('msg', Map({ type: 'success', msg: 'Password successfully changed - please sign in' }))
      return newstate.set('isLoading', false)

    case 'SEND_RESET_SUCCESS':
      newstate = newstate.set('msg', Map({ type: 'success', msg: 'Reset confirmation sent - check your email' }))
      return newstate.set('isLoading', false)

    case 'REMOVE_MODAL':
      return newstate.set('modaled', false)

    case 'HIDE_LOADER':
      return newstate.set('isLoading', false)

    case 'SHOW_LOADER':
      return newstate.set('isLoading', true)

    case 'FETCH_SUCCESS':
    case 'FETCH_ERROR':
    case 'LOGIN_ERROR':
    case 'LOAD_TOKE_ERROR':
    case 'UPDATE_MODEL_SUCCESS':
    case 'UPDATE_MODEL_ERROR':
    case 'CREATE_MODEL_SUCCESS':
    case 'CREATE_PORTAL_SUCCESS':
    case 'CREATE_MODEL_ERROR':
    case 'DELETE_PREFERENCE_SUCCESS':
    case 'DELETE_PREFERENCE_ERROR':
    case 'UPDATE_PREFERENCE_SUCCESS':
    case 'UPDATE_PREFERENCE_ERROR':
    case 'CREATE_PREFERENCE_SUCCESS':
    case 'CREATE_PREFERENCE_ERROR':
    case 'SEND_RESET_ERROR':
    case 'DO_RESET_ERROR':
    case 'CREATE_PORTAL_CONFIG_SUCCESS':
    case 'CREATE_PORTAL_CONFIG_ERROR':
    case 'UPDATE_BRANCH_PORTAL_CONFIG_SUCCESS':
    case 'UPDATE_BRANCH_PORTAL_CONFIG_ERROR':
    case 'CREATE_BRANCH_PORTAL_CONFIG_SUCCESS':
    case 'CREATE_BRANCH_PORTAL_CONFIG_ERROR':
    case 'DELETE_MODEL_SUCCESS':
    case 'DELETE_MODEL_ERROR':
    case 'FETCH_GLOBAL_PORTALS_SUCCESS':
    case 'FETCH_AGENCY_PORTALS_SUCCESS':
    case 'FETCH_BRANCH_PORTALS_SUCCESS':
    case 'SELECT_AGENT_SUCCESS':
    case 'SEND_ACTIVATION_SUCCESS':
    case 'SEND_ACTIVATION_ERROR':
    case 'ALERT_AGENTPROPERTYLEAD_SUCCESS':
    case 'ALERT_AGENTPROPERTYLEAD_ERROR':
    case 'NOTIFY_REFERRALREQUEST_SUCCESS':
    case 'NOTIFY_REFERRALREQUEST_ERROR':
    case 'ACTIVATION_SUCCESS':
    case 'ACTIVATION_ERROR':
    case 'RENEW_TOKEN_SUCCESS':
    case 'EMAIL_PROFILE_MATCHES_SUCCESS':
    case 'EMAIL_PROFILE_MATCHES_ERROR':
    case 'FETCH_DEEDS_SUCCESS':
    case 'FETCH_ACTIVITY_SUCCESS':
    case 'FETCH_ACTIVITY_ERROR':
    case 'MERGE_MODEL_SUCCESS':
    case 'FETCH_DEEDS_ERROR': {
      if (!action.noloader) { newstate = newstate.set('isLoading', false) }
      if (action.redirect) { newstate = newstate.set('redirect', action.redirect) }
      return newstate
    }

    case 'FETCH_ONE_SUCCESS':
    case 'FETCH_ONE_ERROR':
    case 'FETCH_DASHBOARD_SUCCESS':
    case 'FETCH_MANY_SUCCESS':
    case 'FETCH_MANY_COUNT_SUCCESS':
    case 'FETCH_MANY_ERROR':
      if (action.message) {
        const message = JSON.parse(action.message)
        if (message.status && message.status === 408) {
          return newstate
        }
      }
      if (
        (action.select === false && newstate.get('awaited'))
        || (action.wait && !action.noloader && newstate.get('awaited'))
        || (!action.noloader && newstate.get('awaited'))
      ) {
        const awaited = newstate.get('awaited') - 1
        newstate = newstate.set('awaited', awaited)
      }
      if (newstate.get('awaited') === 0 && !action.noloader) { newstate = newstate.set('isLoading', false) }
      return newstate

    case 'FETCH_DASHBOARD': {
      const awaited = newstate.get('awaited') + 1
      newstate = newstate.set('awaited', awaited)
      return newstate
    }

    case 'FETCH_ONE': {
      if (action.wait && !action.noloader) {
        const awaited = newstate.get('awaited') + 1
        newstate = newstate.set('awaited', awaited)
      }
      return newstate
    }

    case 'FETCH_MANY': {
      if (action.data && !action.data.values.select && !action.data.noloader && !action.data.values.noloader) {
        const awaited = newstate.get('awaited') + 1
        newstate = newstate.set('awaited', awaited)
      }
      return newstate
    }

    case 'TOGGLE_NAV':
      newstate = newstate.set('dropdown', null)
      if (newstate.get('nav') === 'full') {
        newstate = newstate.set('nav', 'mini')
      } else {
        newstate = newstate.set('nav', 'full')
        newstate = newstate.set('menu', '')
        newstate = newstate.set('sidebar', '')
      }
      return newstate

    case 'TOGGLE_MENU':
      newstate = newstate.set('dropdown', null)
      if (newstate.get('sidebar') === 'showmenu') {
        newstate = newstate.set('sidebar', '')
      } else {
        newstate = newstate.set('sidebar', 'showmenu')
        newstate = newstate.set('nav', 'mini')
      }
      return newstate

    case 'OPEN_NAV':
      return newstate.set('nav', 'full')

    case 'CLOSE_NAV':
      return newstate.set('nav', 'mini')

    case 'CLOSE_MENU':
      return newstate.set('menu', '')

    case 'TOGGLE_MANAGER': {
      newstate = newstate.set('dropdown', null)
      const sidebar = newstate.get('sidebar') === 'showmanager' ? '' : 'showmanager'
      return newstate.set('sidebar', sidebar)
    }

    case 'TOGGLE_ALERTS':
      newstate = newstate.set('dropdown', null)
      if (newstate.get('sidebar') === 'showalerts') {
        newstate = newstate.set('sidebar', '')
      } else {
        newstate = newstate.set('sidebar', 'showalerts')
        newstate = newstate.set('nav', 'mini')
      }
      return newstate

    case 'PUSH_MESSAGE':
      if (action.data.payload.status !== 202) {
        newstate = newstate.set('isLoading', false)
      }
      return newstate

    case 'TOGGLE_WIDE_SIDEBAR':
      newstate = newstate.set('dropdown', null)
      if (newstate.get('sidebar') === action.name) {
        newstate = newstate.set('sidebar', '')
      } else {
        newstate = newstate.set('sidebar', action.name)
        newstate = newstate.set('nav', 'mini')
      }
      return newstate

    case 'MOUNT_MENU':
      return newstate.set('dropdown', action.menu)

    case 'UNMOUNT_MENU':
      return newstate.set('dropdown', null)

    case 'UNSELECT_AGENT':
      return newstate.set('redirect', fromJS({ pathname: '/login' }))

    case 'REGISTER_REDIRECT': {
      let redirect = action.url
      if (action.url && !action.url?.pathname) {
        redirect = { pathname: action.url, search: '' }
        if (action.url.includes('?')) {
          const [ root, path ] = action.url.split('?')
          redirect.pathname = root
          redirect.search = path || ''
          if (redirect.search.includes('#')) {
            const [ search, hash ] = redirect.search.split('#')
            redirect.search = `?${search}`
            redirect.hash = `#${hash}`
          }
        } else if (action.url.includes('#')) {
          const [ root, hash ] = action.url.split('#')
          redirect.pathname = root
          redirect.hash = `#${hash}`
        }
      }
      return newstate.set('redirect', fromJS(redirect))
    }
    case 'NOTIFY': {
      let notices = newstate.get('notices')
      const newnotice = { ...action.data, idx: notices.size }
      if (notices.findIndex(notice => notice.get('title') === newnotice.title) === -1) {
        notices = notices.push(fromJS(newnotice))
      }
      return newstate.set('notices', notices)
    }

    case 'CONNECT_WEBSOCKET':
      return newstate.merge(fromJS({ ws: true }))

    case 'DISCONNECT_WEBSOCKET':
      return newstate.merge(fromJS({ ws: false }))

    case 'DISMISS_NOTICE':
      if (action.idx) {
        let notices = newstate.get('notices')
        notices = notices.filter(notice => notice.get('idx') !== action.idx)
        newstate = newstate.set('notices', notices)
      } else {
        newstate = newstate.set('notices', List([]))
      }
      return newstate

    case 'LOGIN_SUCCESS':
      return newstate.merge(Map({
        msg: null,
        isLoading: false
      }))

    case 'TOKE_ERROR': {
      newstate = fromJS(config.defaultState.ui)
      switch (action.status) {
        case 408: // Timeout
          newstate = newstate.set('msg', fromJS({ type: 'info', msg: 'Your session expired' }))
          newstate = newstate.set('redirect', fromJS({ pathname: '/login' }))
          break
        case 403: // Forbidden
        case 401: // Unauthorised
          newstate = newstate.set('msg', fromJS({ type: 'error', msg: 'Please sign in to continue' }))
          newstate = newstate.set('redirect', fromJS({ pathname: '/login' }))
          break
        default:
          log.debug(`Status: ${action.status} (fetch error skipped)`)
          break
      }
      return newstate
    }

    case 'DO_LOGOUT': { // Remove token from store and return default state
      newstate = fromJS(config.defaultState.ui)
      let redirect = action.data?.url || ''
      if (action.data?.url && !action.data?.url?.pathname) {
        redirect = { pathname: action.data?.url, search: '' }
        if (action.data?.url.includes('?')) {
          const [ root, path ] = action.data.url.split('?')
          redirect.pathname = root
          redirect.search = path || ''
          if (redirect.search.includes('#')) {
            const [ search, hash ] = redirect.search.split('#')
            redirect.search = `?${search}`
            redirect.hash = `#${hash}`
          }
        } else if (action.data?.url.includes('#')) {
          const [ root, hash ] = action.data.url.split('#')
          redirect.pathname = root
          redirect.hash = `#${hash}`
        }
      }
      newstate = newstate.set('redirect', fromJS(redirect))
      return newstate
    }

    default:
      return newstate
  }
}
