/* eslint-disable no-unused-vars */
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { handleSubmitError } from '../utils'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


class AreaGroupSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initvals: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
  }

  componentDidMount() {
    const initvals = {}
    if (this.props.area_group) {
      this.props.config.fields.forEach(field => {
        initvals[field.name] = getIn(this.props.area_group, field.name)
        if (field.name === 'fields') {
          initvals[field.name] = JSON.stringify(initvals[field.name])
        }
      })
      this.setState({ initvals })
    }
  }

  componentDidUpdate(prevProps) {
    const initvals = {}
    if (!isEqual(prevProps.area_group, this.props.area_group)) {
      this.props.config.fields.forEach(field => {
        initvals[field.name] = getIn(this.props.area_group, field.name)
        if (field.name === 'fields') {
          initvals[field.name] = JSON.stringify(initvals[field.name])
        }
      })
      this.setState({ initvals })
    }
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  handleSubmit(values, actions) {
    Object.keys(values).forEach(k => {
      const field = this.props.config.fields.find(f => f.name === k)
      if (field && field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }
      if (field.name === 'fields') {
        values[k] = JSON.parse(values[k])
      }
    })
    return new Promise((resolve, reject) => {
      this.props.actions.updateModel({
        values: {
          modelname: this.props.config.modelname,
          endpoint: this.props.config.endpoint,
          ...values
        }, resolve, reject })
    }).then(r => {
      const { selectContact } = this.props
      this.props.actions.notifyUser({ title: 'Success!', body: `Your ${this.props.config.singular} was updated.`, type: 'success' })
      actions.setSubmitting(false)
      actions.setTouched({})
      actions.resetForm()
      this.props.list.refreshPage()
      this.props.actions.toggleWideSidebar()
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  render () {
    return (
      <div id="contact-creator-sidebar" ref={el => { this.el = el }} className="wide-sidebar contact-creator-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={this.state.initvals}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={() => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>Edit Group</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          { Object.keys(this.props.config.fieldgroups).map((group, gidx) => (
                            <FieldGroup
                              key={`fs-${gidx}`}
                              groupname={group}
                              group={this.props.config.fieldgroups[group]}
                              gidx={gidx}
                              classes={this.props.config.fieldgroups[group].classes}
                              fields={this.props.config.fields.filter(field => field.group === group).map(f => {
                                const newfield = merge({}, f)
                                if (newfield.createnew) { newfield.createnew = false }
                                if (newfield.twin) { newfield.readonly = true }
                                return newfield
                              })}
                              modelname={this.props.config.modelname}
                              match={this.props.match}
                              required={this.state.required}
                              columns
                              collapsed={this.state.collapsed}
                              render={({ renderFieldGroup, hidden }) => {
                                if (hidden) { return null }
                                return (
                                  <fieldset className="editgroup">
                                    <h5>{group}</h5>
                                    {renderFieldGroup(group)}
                                  </fieldset>
                                )
                              }}
                            />
                          ))}
                          <div className="wide-sidebar-footer">
                            <Button
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              onClick={formik.submitForm}
                              disabled={formik.isSubmitting}
                              className="btn btn-primary"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

AreaGroupSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  area_group: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  list: PropTypes.object,
  sidebar: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func
}

export default AreaGroupSidebar
