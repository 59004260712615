/* eslint-disable no-unused-vars */
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import Immutable, { fromJS } from 'immutable'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import { unstable_batchedUpdates } from 'react-dom'

import rootSaga from './sagas'
import rootReducer from './reducers'
import config from './config/config.json'


const sagaMiddleware = createSagaMiddleware()

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  batch: unstable_batchedUpdates,
  selectRouterState: state => state.get('router')
})

const sentryStateSanitizer = state => { // Remove state as sentry blocks if over 200kb
  let cleaner_user = state.get('user')
  let cleaner_agent = state.getIn([ 'user', 'agent' ])

  cleaner_user = cleaner_user.delete('token')
  cleaner_user = cleaner_user.delete('availablePreferences')
  cleaner_user = cleaner_user.delete('preferences')
  cleaner_user = cleaner_user.delete('agents')
  cleaner_user = cleaner_user.delete('configs')

  if (cleaner_user.has('agent') && cleaner_agent) {
    cleaner_agent = cleaner_agent.delete('token')
    cleaner_agent = cleaner_agent.delete('preferences')
    cleaner_agent = cleaner_agent.delete('permissions')
    cleaner_agent = cleaner_agent.deleteIn([ 'site', 'portals' ])
    cleaner_user = cleaner_user.delete('agent')
  }

  cleaner_user = cleaner_user.set('agent', cleaner_agent)
  const transformedSentryState = {
    app: state.get('app'),
    ui: state.get('ui'),
    router: state.get('router'),
    user: cleaner_user
  }
  return transformedSentryState
}

const stateSanitizer = state => { // Remove large state that slows down dev
  if (state.has('cache')) {
    state = state.set('cache', '<<REMOVED>>')
  }
  if (state.has('user')) {
    state = state.setIn([ 'user', 'configs' ], '<<REMOVED>>')
    state = state.setIn([ 'user', 'agents' ], '<<REMOVED>>')
    state = state.setIn([ 'user', 'token' ], '<<REMOVED>>')
    state = state.setIn([ 'user', 'preferences' ], '<<REMOVED>>')
    state = state.setIn([ 'user', 'availablePreferences' ], '<<REMOVED>>')
  }
  if (state.hasIn([ 'user', 'agent' ])) {
    [ 'token', 'preferences', 'permissions' ].forEach(f => {
      if (state.hasIn([ 'user', 'agent', f ])) {
        state = state.setIn([ 'user', 'agent', f ], '<<REMOVED>>')
      }
    })
    if (state.hasIn([ 'user', 'agent', 'site', 'portals' ])) {
      state = state.setIn([ 'user', 'agent', 'site', 'portals' ], '<<REMOVED>>')
    }
  }
  if (state.has('models')) {
    state.get('models').keySeq().toArray().forEach(key => {
      if (!state.getIn([ 'models', key ]).size) {
        state = state.delete([ 'models', key ])
      }
    })
  }
  return state
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type === 'SELECT_AGENT') {
      return null
    }
    if (action.type.includes('_SUCCESS')) {
      return { type: action.type }
    }
    if (action.token) {
      return { ...action, token: '<<REMOVED>>' }
    }
    return action
  },
  stateTransformer: sentryStateSanitizer
})

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      serialize: { immutable: Immutable },
      stateSanitizer: stateSanitizer
    }) : compose


export const getMinState = state => {
  const minstate = {
    app: fromJS(state.app),
    user: fromJS(state.user),
    ui: fromJS(state.ui),
    cache: fromJS(state.cache),
    selected: fromJS(state.selected)
  }
  return minstate
}

const mappedDefaultState = fromJS(config.defaultState)

const store = createStore(
  rootReducer(),
  mappedDefaultState,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware,
      sagaMiddleware
    ),
    sentryReduxEnhancer
  )
)

export const history = createReduxHistory(store)

sagaMiddleware.run(function* () { yield rootSaga() })

export default store
