/* eslint-disable new-cap */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ErrorMessage } from 'formik'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CONFIG } from '../../../../selectors'
import { Button } from '../../../ui/Button'
import log from '../../../../logging'
import Label from './Label'


class FileUploadInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.showProgress = this.showProgress.bind(this)
    this.chooseFile = this.chooseFile.bind(this)
    this.removeFile = this.removeFile.bind(this)
  }

  showProgress(completed) {
    const el = document.getElementById('inlineloaded')
    const style = { width: `${completed}%` }
    Object.assign(el.style, style)
  }

  chooseFile(e) {
    const { config } = this.props
    try {
      const el = e.target
      const reader = new FileReader()
      reader.readAsDataURL(el.files[0])
      const values = {
        file: el.files,
        config: config,
        filetype: 'file',
        caption: el.files[0].name,
        ordinal: 0
      }
      new Promise((resolve, reject) => {
        this.props.uploadFile({
          values, resolve, reject, progress: function (completed) {
            const iel = document.getElementById('inlineloaded')
            const style = {
              position: 'absolute',
              top: '0',
              zIndex: '3',
              backgroundColor: '#efefef',
              height: '100%',
              width: `${completed}%`
            }
            Object.assign(iel.style, style)
          }
        })
      }).then(r => {
        this.setState({ val: r })
        this.props.form.setFieldValue(this.props.field.name, r).then(() => {
          this.props.form.setFieldTouched(this.props.field.name)
        })
        if (this.props.preview) { this.props.form.setFieldValue(this.props.preview, r) }
      })
    } catch (er) {
      log.error(er)
    }
  }

  removeFile() {
    this.props.form.setFieldValue(this.props.field.name, null)
  }

  render() {
    const { field, form, label, id } = this.props
    const { name, value } = field
    const { errors } = form
    return (
      <div id={id} className="uploadwrap">
        <div>
          <div className="form-group">
            {label &&
              <Label htmlFor={name} error={errors[name]}>
                {label}
              </Label>
            }
            <div className="inlineload">
              <div id="inlineloaded"></div>
              {value &&
                <a href={value.url} alt="" target="_blank" rel="noopener noreferrer">{value.url}</a>
              }
            </div>
          </div>
          <div className="form-group">
            <label></label>
            <div className="uploadwrap">
              <input
                id={name}
                name={name}
                type="file"
                className="btn"
                onChange={this.chooseFile}
                {...this.props.form.field}
              />
              {!value ? (
                <Button type="button" className="btn btn-primary">Upload a file</Button>
              ) : (
                <Button type="button" className="btn btn-primary" onClick={this.removeFile}>Remove file</Button>
              )}
            </div>

            <ErrorMessage render={msg => <div className="error">{msg}</div>} name={name} />
          </div>
        </div>
      </div>
    )
  }
}

FileUploadInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  config: PropTypes.object,
  classes: PropTypes.string,
  modelname: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  preview: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  config: CONFIG(state, ownProps.modelname)
})

export default connect(mapStateToProps, null)(withImmutablePropsToJS(FileUploadInput))
