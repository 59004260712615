import * as yup from 'yup'

import messages from '../../messages.json'
import { residential_listing } from '../../residential'


const ae_residential_listing = yup.object({
  unit_number: yup.string().when([ 'property_type', 'complex_name' ], {
    is: (property_type, complex_name) => [
      'Apartment',
      'Cluster',
      'Duet',
      'Duplex',
      'Flat',
      'Hotel Room',
      'Maisonette',
      'Penthouse',
      'Retirement Unit',
      'Sectional Title',
      'Simplex',
      'Studio Apartment',
      'Townhouse'
    ].includes(property_type) || complex_name,
    then: yup.string().required(messages.required).nullable(),
    otherwise: yup.string().nullable()
  }),
  complex_name: yup.string().when([ 'property_type' ], {
    is: property_type => [
      'Apartment',
      'Cluster',
      'Duet',
      'Duplex',
      'Flat',
      'Hotel Room',
      'Maisonette',
      'Penthouse',
      'Retirement Unit',
      'Sectional Title',
      'Simplex',
      'Studio Apartment',
      'Townhouse'
    ].includes(property_type),
    then: yup.string().required(messages.required).nullable(),
    otherwise: yup.string().nullable()
  }),
  property_permit: yup.number().when('display_on_website', (display_on_website, schema) => (
    display_on_website ? (
      yup.string().max(100).required(messages.required).nullable()
    ) : schema.nullable()
  )),
  flatlet: yup.number().nullable(),
  property_type: yup.string().trim().oneOf([
    'Apartment Block',
    'Apartment',
    'Building',
    'Bungalow',
    'Compound',
    'Duplex',
    'Farm',
    'Full Floor',
    'Half Floor',
    'Hotel Room',
    'House',
    'Labor Camp',
    'Office',
    'Penthouse',
    'Showroom',
    'Staff Accommodation',
    'Studio Apartment',
    'Townhouse',
    'Vacant Land',
    'Villa'
  ], messages.invalid).required(messages.required),
  extras: yup.string().test(
    'extras-required',
    messages.required,
    value => value && value.trim().split('\n').length > 0
  ).nullable(),
  marketing_heading: yup.string().required(messages.required),
  build_completion_status: yup.string().oneOf([
    'Completed',
    'Off Plan',
    'Off Plan Primary',
    'Completed Primary'
  ], messages.invalid).required(messages.required).nullable(),
  floor_size: yup.number().typeError(messages.invalid).min(0)
    .transform(cv => (isNaN(cv) ? undefined : cv)).required(messages.required).nullable(),
  land_size: yup.number().when([ 'property_type' ], {
    is: property_type => [
      'Villa',
      'House',
      'Townhouse',
      'Vacant Land'
    ].includes(property_type),
    then: yup.number().required(messages.required).nullable(),
    otherwise: yup.number().nullable()
  }),
  down_payment: yup.number().when([ 'payment_method' ], {
    is: payment_method => [
      'Instalment',
      'Cash and Instalment'
    ].includes(payment_method),
    then: yup.number().required(messages.required).nullable(),
    otherwise: yup.number().nullable()
  }),
  payment_frequency: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().oneOf([
      null,
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ], messages.invalid).required(messages.required).nullable(),
    otherwise: yup.string().nullable()
  })
})

export const residential = () => residential_listing.concat(ae_residential_listing)
