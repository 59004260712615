/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CONFIGS, ADDONS, PERMISSIONS } from '../selectors'
import { fetchMany, createModel, updateModel, notifyUser, toggleWideSidebar, registerRedirect } from '../actions'
import Integrations from '../components/Integrations'


const mapStateToProps = (state, ownProps) => {
  const configs = CONFIGS(state)
  const perms = PERMISSIONS(state)
  return {
    modelname: ownProps.match.params.model,
    modelid: ownProps.match.params.id,
    config: configs.get(ownProps.match.params.model),
    configs,
    addons: ADDONS(state),
    user: { ...ownProps.user, permissions: perms.toJS() }
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMany,
    createModel,
    updateModel,
    notifyUser,
    toggleWideSidebar,
    registerRedirect
  }, dispatch) })

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(Integrations))
