/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { withCustomRouter } from '../components/withCustomRouter'
import ContextMenu from '../components/common/context-menu/ContextMenu'
import * as actions from '../actions'
import { MODELNAME, MODELACTION, UI, SETTINGS, SITE, CACHE, CACHEDMODEL, MINUSER, MODEL, PORTALS, ADDONS, APP, CONFIG, SELECTED } from '../selectors'
import { formikConnect } from '../components/common/forms/customFormikConnect'


const mapStateToProps = state => {
  let modelname = MODELNAME(state)
  const modelaction = MODELACTION(state)
  if (modelname === 'syndication' && modelaction) {
    modelname = `${modelname}${modelaction}`
  }
  const portals = PORTALS(state)
  const siteid = SITE(state).get('id')
  const settings = SETTINGS(state, siteid)
  const ui = UI(state)
  const user = MINUSER(state)
  const model = MODEL(state, modelname)
  const cache = modelname !== 'dashboard' ? CACHEDMODEL(state, modelname) : CACHE(state)
  const addons = ADDONS(state)
  const app = APP(state)
  const config = CONFIG(state, modelname)
  const selected = SELECTED(state, modelname)

  return {
    modelname,
    model,
    cache,
    user,
    ui,
    portals,
    app,
    config,
    settings,
    addons,
    selected
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  // need to collect actions from passed in props as well
  ({ ...ownProps, ...stateProps, actions: { ...dispatchProps.actions, ...ownProps.actions } })


export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(withImmutablePropsToJS(formikConnect(ContextMenu)))
)
