import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import classNames from 'classnames'

import ViewError from '../components/common/errors/ViewError'
import Sidebar from '../components/ui/sidebar/Sidebar'
import Header from '../containers/Header'
import Notifier from '../containers/Notifier'
import log from '../logging'
import { breakpoint } from '../utils'
import Loader from './common/Loader'
import Alerts from './Alerts'


const Home = props => {
  const [ error, setError ] = useState(false)
  const [ errorcode, setErrorCode ] = useState(0)
  const [ info, setErrorInfo ] = useState('')
  const [ isTouch, setTouch ] = useState('Touch' in window && !breakpoint.matches)
  const [ currentLocation, setCurrentLocation ] = useState(props.location)

  const { nav, menu, sidebar, isLoading, notices } = props.ui
  // eslint-disable-next-line no-unused-vars
  const { user, actions, settings, className, location, match, history, ...rest } = props

  const toggleTouch = () => {
    const t = 'Touch' in window && !breakpoint.matches
    setTouch(t)
  }

  const throwViewError = () => {
    props.actions.hideLoader()
    setError(true)
    setErrorCode(408)
    setErrorInfo('Request timed out')
    log.error('Loader timeout')
  }

  useEffect(() => {
    if (!isEqual(currentLocation, location)) {
      setError(false)
      setCurrentLocation(location)
    }
    return () => {}
  }, [ location ])

  useEffect(() => {
    breakpoint.addEventListener('change', toggleTouch)
    return () => {
      breakpoint.removeEventListener('change', toggleTouch)
    }
  }, [ ])

  if (user.agents.length === 0) { actions.doLogout() }
  if (location.pathname.endsWith('print/') || location.pathname.endsWith('print')) {
    return (
      <>
        <div id="main" className={classNames('main', className, 'print-page')}>
          {(isLoading) &&
          <Loader throwViewError={throwViewError} noblock={(user.agent && settings[user.agent.site])} />
          }
          <div id="wrapper" className={classNames('wrapper', nav, menu, sidebar, { login: !user.agent, touch: isTouch })}>
            {user.agent ? (
              <>
                { error ? (
                  <ViewError error={errorcode} info={info} />
                ) : (
                  React.cloneElement(props.children, { ...props, id: 'content', className: 'content' })
                )}
              </>
            ) : null}
          </div>
        </div>
      </>
    )
  }
  return (
    <div id="main" className={classNames('main', className)}>
      <div id="wrapper" className={classNames('wrapper', nav, menu, sidebar, { login: !user.agent, touch: isTouch })}>
        <Header
          actions={actions}
        />
        {(isLoading) &&
        <Loader throwViewError={throwViewError} noblock={(user.agent && settings[user.agent.site])} />
        }
        {user.agent ? (
          <>
            { error ? (
              <ViewError error={errorcode} info={info} />
            ) : (
              React.cloneElement(props.children, { ...rest, user, actions, settings, id: 'content', className: 'content' })
            )}
            <Sidebar sidebar="showalerts">
              <Alerts
                alerts={props.user.alerts}
                actions={{
                  toggleAlerts: props.actions.toggleAlerts,
                  dismissAlert: props.actions.dismissAlert
                }}
              />
            </Sidebar>
            { notices.length > 0 &&
              <Notifier />
            }
          </>
        ) : null}
      </div>
    </div>
  )
}

Home.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  ui: PropTypes.object,
  user: PropTypes.object,
  settings: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
}

export default Home


// Home.whyDidYouRender = true
