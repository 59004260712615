import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { CONFIG } from '../../../../selectors'
import { checkImageSize } from '../../../../utils'
import { Button } from '../../../ui/Button'


function composeEventHandlers(...fns) {
  return (event, ...args) => {
    fns.some(fn => {
      if (fn) {
        fn(event, ...args)
      }
      return event.defaultPrevented
    })
  }
}

function isIe(userAgent) {
  return userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1
}

function isEdge(userAgent) {
  return userAgent.indexOf('Edge/') !== -1
}

function isIeOrEdge(userAgent = window.navigator.userAgent) {
  return isIe(userAgent) || isEdge(userAgent)
}

class ImageCropInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      src: null,
      val: null,
      width: null,
      height: null,
      ratio: null,
      crop: {}
    }
    this.open = this.open.bind(this)
    this.isFileDialogOpen = false
    this.onFileDialogCancel = this.onFileDialogCancel.bind(this)
    this.chooseFile = this.chooseFile.bind(this)
    this.updatePreview = this.updatePreview.bind(this)
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFileDialogCancel, false)
    const { form, meta, field, preview_h, preview_w, preview_x, preview_y } = this.props
    if (meta && !this.state.src) {
      const i = new Image()
      if (field.value && meta && meta.file && !this.state.src) { // Retrieve the meta
        i.src = this.props.meta.file
        i.onload = () => {
          const ratio = i.width / i.height
          let crop = {
            x: 20,
            y: 20,
            width: 50,
            height: 50 * ratio,
            aspect: 1 / 1
          }
          if (form.values[preview_h]) {
            const cH = Math.trunc(form.values[preview_h] / i.height * 100)
            const cW = Math.trunc(form.values[preview_w] / i.width * 100)
            const cX = Math.trunc(form.values[preview_x] / i.width * 100)
            const cY = Math.trunc(form.values[preview_y] / i.height * 100)
            crop = {
              x: cX,
              y: cY,
              width: cW,
              height: cH,
              aspect: 1 / 1
            }
          }
          this.setState({
            src: meta.file,
            val: field.value,
            width: i.width,
            height: i.height,
            ratio,
            crop
          }, () => {
            this.updatePreview(true)
          })
        }
      } else if (field.value && this.state.src) {
        i.src = this.state.src
        i.onload = () => {
          const ratio = i.width / i.height
          let crop = {
            x: 20,
            y: 20,
            width: 50,
            height: 50 * ratio,
            aspect: 1 / 1
          }
          if (form.values[preview_h]) {
            const cH = Math.trunc(form.values[preview_h] / i.height * 100)
            const cW = Math.trunc(form.values[preview_w] / i.width * 100)
            const cX = Math.trunc(form.values[preview_x] / i.width * 100)
            const cY = Math.trunc(form.values[preview_y] / i.height * 100)
            crop = {
              x: cX,
              y: cY,
              width: cW,
              height: cH,
              aspect: 1 / 1
            }
          }
          this.setState({
            val: field.value,
            width: i.width,
            height: i.height,
            ratio,
            crop
          }, () => {
            this.updatePreview(true)
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { form, meta, field, preview, preview_h, preview_w, preview_x, preview_y } = this.props
    if (!isEqual(field, prevProps.field) || !isEqual(meta, prevProps.meta) || (meta && !this.state.src)) {
      const i = new Image()
      if (field.value && meta && meta.file && !this.state.src) { // Retrieve the meta
        i.src = this.props.meta.file
        i.onload = () => {
          const ratio = i.width / i.height
          let crop = {
            x: 20,
            y: 20,
            width: 50,
            height: 50 * ratio,
            aspect: 1 / 1
          }
          if (form.values[preview_h]) {
            const cH = Math.trunc(form.values[preview_h] / i.height * 100)
            const cW = Math.trunc(form.values[preview_w] / i.width * 100)
            const cX = Math.trunc(form.values[preview_x] / i.width * 100)
            const cY = Math.trunc(form.values[preview_y] / i.height * 100)
            crop = {
              x: cX,
              y: cY,
              width: cW,
              height: cH,
              aspect: 1 / 1
            }
          }
          this.setState({
            src: meta.file,
            val: field.value,
            width: i.width,
            height: i.height,
            ratio,
            crop
          }, () => {
            this.updatePreview(true)
          })
        }
      } else if (field.value && this.state.src) {
        i.src = this.state.src
        i.onload = () => {
          const ratio = i.width / i.height
          let crop = {
            x: 20,
            y: 20,
            width: 50,
            height: 50 * ratio,
            aspect: 1 / 1
          }
          if (form.values[preview_h]) {
            const cH = Math.trunc(form.values[preview_h] / i.height * 100)
            const cW = Math.trunc(form.values[preview_w] / i.width * 100)
            const cX = Math.trunc(form.values[preview_x] / i.width * 100)
            const cY = Math.trunc(form.values[preview_y] / i.height * 100)
            crop = {
              x: cX,
              y: cY,
              width: cW,
              height: cH,
              aspect: 1 / 1
            }
          }
          this.setState({
            val: field.value,
            width: i.width,
            height: i.height,
            ratio,
            crop
          }, () => {
            this.updatePreview(true)
          })
        }
      }
      const fields = [
        `${preview}_coord_x`,
        `${preview}_coord_y`,
        `${preview}_width`,
        `${preview}_height`
      ].map(f => this.props.form.values[f])
      const old_fields = [
        `${preview}_coord_x`,
        `${preview}_coord_y`,
        `${preview}_width`,
        `${preview}_height`
      ].map(f => prevProps.form.values[f])
      if (!isEqual(fields, old_fields)) { form.setFieldTouched(field.name) }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFileDialogCancel, false)
  }

  updatePreview(edit = false) {
    const { crop, width, height } = this.state
    const el_preview = document.getElementById(`${this.props.preview}-preview`)
    if (!el_preview) { return false }
    const preview = el_preview.getElementsByClassName('thumbimg')[0]
    const pH = preview.getBoundingClientRect().width // Should be a square
    const pW = preview.getBoundingClientRect().height
    const cH = Math.trunc(crop.height * height / 100) // Crop values are percentages of the original images
    const cW = Math.trunc(crop.width * width / 100)
    const cX = Math.trunc(crop.x * width / 100) // Crop co-ordinates
    const cY = Math.trunc(crop.y * height / 100)
    const rW = pW / cW // Calculate ratio for preview
    const rH = pH / cH
    const pX = rW * Math.round(cX) * -1 // Positioning
    const pY = rH * Math.round(cY) * -1

    const style = {
      backgroundImage: `url(${this.state.src})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `${pX}px ${pY}px`,
      backgroundSize: `${(width * rW)}px ${(height * rH)}px`
    }
    Object.assign(preview.style, style)
    if (!edit) {
      if (!Object.is(NaN, cX)) { this.props.form.setFieldValue(`${this.props.preview}_coord_x`, cX) }
      if (!Object.is(NaN, cY)) { this.props.form.setFieldValue(`${this.props.preview}_coord_y`, cY) }
      if (!Object.is(NaN, cW)) { this.props.form.setFieldValue(`${this.props.preview}_width`, cW) }
      if (!Object.is(NaN, cH)) { this.props.form.setFieldValue(`${this.props.preview}_height`, cH) }
      // this.props.form.setFieldTouched(this.props.field.name)
    }
    return true
  }

  async chooseFile() {
    const { site, config } = this.props
    try {
      checkImageSize(this.input.files[0], this.props.minWidth, this.props.minHeight).catch(() => {
        this.props.notifyUser({
          title: this.input.files[0].name,
          body: `The image is too small ( < ${this.props.minWidth} x ${this.props.minHeight}).`,
          type: 'error'
        })
      })
      const values = {
        file: this.input.files,
        config: config,
        field: 'preview',
        filetype: 'image'
      }
      let el = null
      if (this.el) { el = this.el.getElementsByClassName('filehead')[0] }
      await new Promise((resolve, reject) => {
        this.setState({ uploading: true })
        this.props.uploadFile({ values, resolve, reject, progress: complete => {
          if (el) {
            const style = { width: `${complete}%`, borderRight: '2px solid #ccc', backgroundColor: site.brand_primary }
            Object.assign(el.style, style)
          }
        } })
      }).then(r => {
        this.setState({ src: r.file, uploading: false })
        this.props.form.setFieldValue(this.props.field.name, r.id).then(() => {
          this.props.form.setFieldTouched(this.props.field.name)
        })
        if (this.props.preview) { this.props.form.setFieldValue(this.props.preview, this.state.val) }
      })
    } catch (e) {
      this.setState({ uploading: false })
      this.props.notifyUser({
        title: this.input.files[0].name,
        body: `The image is too small ( < ${this.props.minWidth} x ${this.props.minHeight}).`,
        type: 'error'
      })
    }
  }

  removeFile() {
    this.setState({
      src: null,
      val: null,
      width: null,
      height: null,
      ratio: null,
      crop: {
        x: 20,
        y: 20,
        aspect: 1 / 1
      }
    })
    const el_preview = document.getElementById(`${this.props.preview}-preview`)
    if (!el_preview) { // There is no preview - crop is false or preview not set in config
      this.props.form.setFieldTouched(this.props.field.name).then(() => {
        this.props.form.setFieldValue(this.props.field.name, null)
      })
      return true
    }
    const preview = el_preview.getElementsByClassName('thumbimg')[0]
    this.props.form.setFieldValue(this.props.field.name, null).then(() => {
      this.props.form.setFieldTouched(this.props.field.name)
    })
    this.props.form.setFieldValue(`${this.props.preview}_coord_x`, null)
    this.props.form.setFieldValue(`${this.props.preview}_coord_y`, null)
    this.props.form.setFieldValue(`${this.props.preview}_width`, null)
    this.props.form.setFieldValue(`${this.props.preview}_height`, null)
    preview.removeAttribute('style')
    return true
  }

  onFileDialogCancel() {
    // execute the timeout only if the FileDialog is opened in the browser
    if (this.isFileDialogOpen) {
      if (this.input !== null) {
        // Returns an object as FileList
        this.isFileDialogOpen = false
      }
    }
  }

  open(e) {
    if (this.input && !this.isFileDialogOpen) {
      e.preventDefault()
      this.isFileDialogOpen = true
      this.input.value = null
      this.input.click()
    }
  }

  render() {
    return (
      <div id={this.props.id} className="uploadinput form-group">
        <div ref={(el => (this.el = el))}>
          <div className="img-container">
            {this.state.src ? (
              <div>
                {this.props.crop !== false ? (
                  <ReactCrop
                    src={this.state.src}
                    crop={this.state.crop}
                    onImageLoaded={ img => {
                      this.image = img
                      this.updatePreview(true)
                    }}
                    onChange={ crop => { this.setState({ crop }) }}
                    onComplete={ () => this.updatePreview()}
                  />
                ) : (
                  <img alt="" src={this.state.src}/>
                ) }
              </div>
            ) : (
              <div className="img-placeholder">
                { this.state.uploading &&
              <div className="loader inline large"></div>
                }
              </div>
            ) }
            { this.state.uploading &&
          <div className="filehead"></div>
            }
          </div>
          <div className="uploadwrap">
            {!this.state.src &&
            <Button type="button" className="btn btn-primary imagecropbtn" onClick={e => {
              if (isIeOrEdge()) { setTimeout(this.open, 0) } else { this.open(e) }
            }}>
              <input
                ref={e => (this.input = e)}
                id={this.props.field.id ? this.props.field.id : this.props.field.name}
                name={this.props.field.name}
                type="file"
                accept="image/*"
                className="btn"
                onClick={composeEventHandlers(this.open, e => { e.stopPropagation() })}
                onChange={this.chooseFile}
              />
              Upload an image
            </Button>
            }
            {this.state.src &&
            <Button type="button" className="btn btn-primary imagecropbtn" onClick={e => this.removeFile(e)}>Remove image</Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

ImageCropInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  meta: PropTypes.object,
  site: PropTypes.object,
  config: PropTypes.object,
  crop: PropTypes.bool,
  preview: PropTypes.string,
  preview_h: PropTypes.string,
  preview_w: PropTypes.string,
  preview_x: PropTypes.string,
  preview_y: PropTypes.string,
  uploadFile: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  notifyUser: PropTypes.func,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number
}

const mapStateToProps = (state, ownProps) => ({
  // eslint-disable-next-line new-cap
  config: CONFIG(state, ownProps.modelname)
})

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ImageCropInput))
