/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, updateModel, fetchMany, notifyUser } from '../actions'
import RedirectSidebar from '../components/RedirectSidebar'


function mapStateToProps(state) {
  return {
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache')
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    updateModel,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(RedirectSidebar))
