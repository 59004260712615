import * as yup from 'yup'

import messages from '../../messages.json'
import { commercial_listing } from '../../commercial'


const ae_commercial_listing = yup.object({
  property_type: yup.string().trim().oneOf([
    'Apartment Block',
    'Building',
    'Business Centre',
    'Co-working Space',
    'Compound',
    'Duplex',
    'Factory',
    'Farm',
    'Full Floor',
    'Half Floor',
    'Hotel Room',
    'Labor Camp',
    'Office',
    'Restaurant',
    'Retail',
    'Showroom',
    'Staff Accommodation',
    'Storage Unit',
    'Vacant Land',
    'Villa',
    'Warehouse'
  ], messages.invalid).required(messages.required).nullable(),
  property_permit: yup.number().when('display_on_website', (display_on_website, schema) => (
    display_on_website ? (
      yup.string().max(100).required(messages.required).nullable()
    ) : schema.nullable()
  )),
  unit_number: yup.string().when([ 'complex_name' ], {
    is: complex_name => complex_name,
    then: yup.string().required(messages.required).nullable(),
    otherwise: yup.string().nullable()
  }),
  extras: yup.string().test(
    'extras-required',
    messages.required,
    value => value && value.trim().split('\n').length > 0
  ).nullable(),
  build_completion_status: yup.string().oneOf([
    'Completed',
    'Completed Primary',
    'Off Plan',
    'Off Plan Primary'
  ], messages.invalid).required(messages.required).nullable(),
  marketing_heading: yup.string().required(messages.required),
  floor_size: yup.number().typeError(messages.invalid).min(0)
    .transform(cv => (isNaN(cv) ? undefined : cv)).required(messages.required).nullable(),
  payment_frequency: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().oneOf([
      null,
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ], messages.invalid).required(messages.required).nullable(),
    otherwise: yup.string().nullable()
  })
})

export const commercial = () => commercial_listing.concat(ae_commercial_listing)
