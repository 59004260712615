/* eslint-disable no-process-env */
import { getIn } from 'formik'
import React from 'react'
import { Route } from 'react-router'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'

import Home from '../containers/Home'
import { parseURL } from '../utils'
import { components } from '../containers/common/ModelRoute'
import SessionModal from './common/modals/SessionModal'
import { Button } from './ui/Button'


class PrivateRoute extends React.PureComponent {
  constructor(props) {
    super(props)
    this.timer = null
    this.checkTimeout = this.checkTimeout.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.checkRetoke = this.checkRetoke.bind(this)
    this.state = {
      show_expiry: false
    }
    this.timeout_minimum = 40
    this.chat_update = null
  }

  componentDidMount() {
    this.retoke = setInterval(this.checkRetoke, 60000) // Log out due to inactivity
    this.startTimer()
    if (window.innerWidth <= 768) { // Close nav of location change
      this.props.closeNav()
      this.props.closeMenu()
    }
    if (isEqual(this.locationParts(this.props.redirect), this.locationParts(this.props.location))) {
      this.props.unregisterRedirect()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.redirect?.pathname === '/login') { this.props.unregisterRedirect() }
    if (isEqual(this.locationParts(this.props.redirect), this.locationParts(this.props.location))) {
      this.props.unregisterRedirect()
    }
    if (!this.timer) { this.startTimer() }
    if (this.props.reset) { this.props.selectNone() } // Select none if model changed
    if (this.props.redirect && this.props.auth) { this.props.unregisterRedirect() } // Deregister redirect once done
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (window.innerWidth <= 768) {
        this.props.closeNav()
        this.props.closeMenu()
      }
    }
    if (!isEqual(this.props.location.pathname, prevProps.location.pathname) ||
    !isEqual(this.props.location.search, prevProps.location.search)) {
      // Hubspot page tracking
      window._hsq.push([ 'setPath', `${this.props.location.pathname}${this.props.location.search ? this.props.location.search : ''}${this.props.location.hash ? this.props.location.hash : ''}` ])
      window._hsq.push([ 'trackPageView' ])
      clearTimeout(this.chat_update)
      this.chat_update = setTimeout(() => {
        window.HubSpotConversations?.widget?.refresh()
      }, 1000)
      this.props.reToke()
    }
    if (!isEqual(this.props.expires, prevProps.expires)) { // On successful navigation as per above
      if ((this.props.expires - Date.now()) / 1000 > this.timeout_minimum && this.state.show_expiry) {
        this.setState({ show_expiry: false })
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.retoke)
    clearInterval(this.timer)
  }

  checkRetoke() {
    if (!this.state.show_expiry) {
      this.props.reToke()
    }
  }

  checkTimeout() {
    if ((this.props.expires - Date.now()) / 1000 < this.timeout_minimum && !this.state.show_expiry) {
      this.setState({ show_expiry: true })
    } else if (this.props.expires <= Date.now()) {
      clearInterval(this.timer)
      this.setState({ show_expiry: false })
      this.props.doLogout()
    } else if ((this.props.expires - Date.now()) / 1000 > this.timeout_minimum && this.state.show_expiry) {
      this.setState({ show_expiry: false })
    }
  }

  startTimer() {
    this.timer = setInterval(this.checkTimeout, 2000)
  }

  stopTimer() {
    clearInterval(this.timer)
    this.timer = null
  }

  locationParts(loc) {
    return {
      pathname: loc?.pathname,
      search: loc?.search || ''
    }
  }

  render() {
    const { auth, agent, component: Component, routeConfig, ...props } = this.props
    if (Component === components.Redirect) {
      return <Component to={parseURL(this.props.routeConfig[getIn(props.computedMatch.params, 'action', 'list')].redirect, props.settings)} />
    }
    if (
      this.props.redirect && // Registered redirect
      this.props.auth && // Auth exists
      this.props.agent && // Auth exists
      !isEqual(
        this.locationParts(props.redirect),
        this.locationParts(props.location)
      )
    ) {
      return <components.Redirect to={this.props.redirect} push /> // Redirect on login to previous position
    }
    return (
      <Route
        {...props}
        render={ renderprops => {
          if (auth && agent && agent.site) {
            return (
              <>
                <Home>
                  <Component {...renderprops} routeConfig={routeConfig} />
                </Home>
                <SessionModal
                  expires={this.props.expires + 1000}
                  visible={this.state.show_expiry}
                  doLogout={this.props.doLogout}
                  buttons={(({ setLoading }) => (
                    <>
                      <Button className="btn btn-primary" type="button" onClick={() => {
                        this.stopTimer()
                        setLoading(true)
                        new Promise((resolve, reject) => this.props.renewToken({
                          resolve,
                          reject
                        })).then(() => {
                          setLoading(false)
                        }).catch(() => {
                          this.stopTimer()
                          setLoading(false)
                        })
                      }}>Yes</Button>
                      <Button className="btn btn-none" type="button" onClick={() => {
                        this.props.doLogout()
                        clearInterval(this.timer)
                      }}>No</Button>
                    </>
                  ))}
                />
              </>
            )
          }
          return null
        }}
      />
    )
  }
}

PrivateRoute.propTypes = {
  auth: PropTypes.bool,
  agent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  model: PropTypes.object,
  permissions: PropTypes.array,
  routeConfig: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.node
  ]),
  closeNav: PropTypes.func,
  closeMenu: PropTypes.func,
  unregisterRedirect: PropTypes.func,
  reToke: PropTypes.func,
  selectNone: PropTypes.func,
  doLogout: PropTypes.func,
  renewToken: PropTypes.func,
  location: PropTypes.object,
  expires: PropTypes.number,
  reset: PropTypes.bool
}

export default PrivateRoute

// PrivateRoute.whyDidYouRender = true
