/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, fetchCreditCheck, loginCreditCheck, fetchCreditCheckModules } from '../actions'
import { CONFIG, CACHE, SIDEBAR, CACHEDMODELID, SELECTED } from '../selectors'
import CreditCheckSidebar from '../components/CreditCheckSidebar'


function mapStateToProps(state, ownProps) {
  let model = CACHEDMODELID(state, ownProps.modelid)
  const selected = SELECTED(state, 'applications').toJS()
  if (!model) {
    model = CACHEDMODELID(state, 'applications', selected[0])
  }
  return {
    model,
    sidebar: SIDEBAR(state),
    cache: CACHE(state),
    config: CONFIG(state, 'credit-checks'),
    siblingform: ownProps.siblingform,
    field: ownProps.field
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    fetchCreditCheck,
    fetchCreditCheckModules,
    loginCreditCheck
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(CreditCheckSidebar))
