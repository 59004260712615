/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, fetchVacancyPro, fetchVacancyProSuburbs } from '../actions'
import { SETTINGS, USER } from '../selectors'
import VacancyProSidebar from '../components/VacancyProSidebar'


const mapStateToProps = state => ({
  sidebar: 'show-vacancy-sidebar',
  settings: SETTINGS(state),
  user: USER(state)
})

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    fetchVacancyPro,
    fetchVacancyProSuburbs
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(VacancyProSidebar))
