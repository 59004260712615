/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import date_options from '../../config/date-options.json'
import { CONFIG, SETTINGS, MINUSER } from '../../selectors'
import { hasPermission, textToDate, valueFormat, buildOptionLabel } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import SimpleTable from '../common/simpletable/SimpleTable'
import withDelay from './withDelay'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

class TopAgentsWidget extends React.Component {
  constructor(props) {
    super(props)
    const option = date_options.find(o => o.value === 'NEXT_30_DAYS')
    const period = option.value
    const { start, end } = textToDate(option.value)
    const { start: previous_start, end: previous_end } = textToDate(period, true)
    this.state = {
      count: 0,
      prev_count: 0,
      negative: false,
      branches: [],
      branch_options: [],
      listing_options: [],
      listing_model: '',
      branch_id: props.branches && props.branches.length === 1 ? props.branches[0] : '',
      loading: true,
      period,
      current: valueFormat('shortdate', start.toString()),
      limit: valueFormat('shortdate', end.toString()),
      previous: valueFormat('shortdate', previous_start.toString()),
      prev_limit: valueFormat('shortdate', previous_end.toString())
    }
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { actions, user, branches } = this.props
    const listing_options = []
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_for_sale', label: 'Residential For Sale' })
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'residential_to_let', label: 'Residential To Let' })
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_for_sale', label: 'Commercial For Sale' })
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id)) {
      listing_options.push({ value: 'commercial_to_let', label: 'Commercial To Let' })
    }
    this.setState({ listing_options, listing_model: listing_options[0].value })
    if (!branches || branches.length > 1) {
      let agent_branches = []
      if (!hasPermission([
        'apply_to_all_branches'
      ], user.permissions, null, user.agent.id)) {
        agent_branches = [ ...user.agent.branches ]
      }
      new Promise((resolve, reject) => actions.fetchMany({
        values: {
          modelname: 'branches',
          optionvalue: 'id',
          optionlabel: 'name',
          fields: [ 'id', 'name' ],
          active: 1,
          all: true,
          select: true,
          params: {
            id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
            order_by: 'name'
          },
          signal: this.AbortController.signal
        },
        resolve,
        reject
      })).then(r => {
        if (this._is_mounted) {
          const branch_options = r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
          this.setState({ branches: r.options, branch_options })
        }
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  render() {
    const { actions, config, user, settings } = this.props
    const { branch_id, listing_model, branches } = this.state
    let order_by
    let sales
    let value
    switch (listing_model) {
      case 'residential_for_sale':
        order_by = '-statistics__rfs_so_value'
        sales = 'meta.statistics.rfs_so'
        value = 'meta.statistics.rfs_so_value'
        break
      case 'residential_to_let':
        order_by = '-statistics__rfs_re_value'
        sales = 'meta.statistics.rfs_re'
        value = 'meta.statistics.rfs_re_value'
        break
      case 'commercial_for_sale':
        order_by = '-statistics__cfs_so_value'
        sales = 'meta.statistics.cfs_so'
        value = 'meta.statistics.cfs_so_value'
        break
      case 'commercial_to_let':
        order_by = '-statistics__cfs_re_value'
        sales = 'meta.statistics.cfs_re'
        value = 'meta.statistics.cfs_re_value'
        break
      default:
        order_by = '-statistics__rfs_so_value,-statistics__cfs_so_value,-statistics__rfs_re_value,-statistics__cfs_re_value'
        break
    }
    return (
      <Card
        id="expiring-listings-widget"
        classes="grid-col-1"
        bodyclass="stats-card no-top-padding expiring-listings-widget"
        background
        header={
          <>
            <h3>Top Agents</h3>
            <div className="details-section-buttons min-flex tablemeta">
              {(!branches || branches.length > 1) ? (
                <div className="filter-branch">
                  <InlineSelect
                    id="branch_id"
                    name="branch_id"
                    className="inline-select"
                    classNamePrefix="inline"
                    options={[ { label: 'All Branches', value: '' }, ...this.state.branch_options ]}
                    defaultValue={{ label: 'All Branches', value: '' }}
                    selectedValue={getIn(this.state, 'branch_id')}
                    onChange={e => {
                      this.setState({ branch_id: e.value })
                    }}
                  />
                </div>
              ) : null}
              <div className="filter-listing-model">
                <InlineSelect
                  id="listing_model"
                  name="listing_model"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ ...this.state.listing_options ]}
                  defaultValue={{ label: 'All Listing Types', value: '' }}
                  selectedValue={getIn(this.state, 'listing_model')}
                  onChange={e => {
                    this.setState({ listing_model: e.value })
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          <SimpleTable
            config={config}
            action={({ params, resolve, reject }) => {
              actions.fetchMany({
                noloader: true,
                values: {
                  modelname: 'agents',
                  conflict: true,
                  signal: this.AbortController.signal,
                  params
                },
                resolve,
                reject
              })
            }}
            params={{
              active: true,
              order_by,
              branches__overlap: branch_id ? [ branch_id ] : branches.map(b => b.id),
              meta_fields: [ 'statistics', 'branches' ],
              conflict: true,
              limit: 10
            }}
            header={[
              {
                label: 'Agent',
                name: [ 'first_name', 'last_name' ],
                order_by: 'first_name',
                link: '/secure/:site/agents/:id',
                orderable: false
              },
              {
                label: listing_model.indexOf('for_sale') !== -1 ? 'Sales' : 'Rentals',
                name: sales,
                format: 'number',
                orderable: false
              },
              {
                label: 'Total Value',
                name: value,
                format: 'currency',
                classes: [ 'text-right' ],
                orderable: false
              }
            ]}
            currency={settings.default_currency}
            user={user}
          />
        }
      />
    )
  }
}


TopAgentsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  settings: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'agents')
  const settings = SETTINGS(state)
  return ({
    user,
    config,
    settings
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(TopAgentsWidget)))
