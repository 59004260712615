import React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'


const PreviewInput = ({
  field: { name, value }, // { name, value, onChange, onBlur }
  label,
  id
}) => (
  <div id={id} className="previewinput form-group">
    <div id={`${name}-preview`} className="single-user">
      <div className="thumb">
        {value ? (
          <div
            className="thumbimg"
            style={{ backgroundImage: `url(${value})` }}>
          </div>
        ) : (
          <div className="thumbimg">
            <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-UserAlt" /></svg>
          </div>
        )}
      </div>
      <input
        id={name}
        name={name}
        type="hidden"
      />
    </div>
    {label &&
    <Label htmlFor={name}>
      {label}
    </Label>
    }
  </div>
)

PreviewInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default PreviewInput
