import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import { parseURL } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const Valuations = ({ configs, user, modelname, model, actions }) => {
  const params = {
    order_by: '-created',
    limit: 100,
    meta_fields: [ 'owner', 'agent', 'branch' ]
  }

  if (modelname === 'contacts') {
    params.owner = getIn(model, 'id')
  } else if ([ 'residential', 'commercial' ].includes(modelname)) {
    // To prevent endpoint returning all valuations, we set search=-11111111 when listing lightstone_id is empty.
    params.search = getIn(model, 'lightstone_id') || -11111111
  }

  const valuation_contact_fields = [
    {
      label: 'Address',
      name: [
        'lightstone_data.propertyDetails.streetNumber',
        ' ',
        'lightstone_data.propertyDetails.streetName',
        ' ',
        'lightstone_data.propertyDetails.streetType',
        ', ',
        'lightstone_data.propertyDetails.suburb'
      ],
      format: 'title'
    },
    {
      label: 'Listing Type',
      name: 'model',
      format: 'title'
    }
  ]

  const valuation_listing_field = {
    label: 'Contact',
    name: 'owner',
    group: 'Record Details',
    input: 'ContactLookup',
    orderby: 'contact__first_name',
    modelname: 'contacts',
    placeholder: 'Enter email, first name or last name',
    link: '/secure/:site/contacts/:owner',
    optionlabel: [ 'first_name', 'last_name' ],
    labelseparator: ' '
  }

  let valuation_common_fields = [
    {
      label: 'Date Added',
      name: 'created',
      orderable: true,
      format: 'datetime'
    },
    {
      label: 'Date Published',
      name: 'modified',
      orderable: true,
      format: 'datetime'
    },
    {
      label: 'Status',
      name: 'status',
      orderable: true
    },
    {
      label: 'Agent',
      name: 'agent',
      modelname: 'agents',
      link: '/secure/:site/agents/:agent',
      optionlabel: [ 'first_name', 'last_name' ],
      orderable: true
    },
    {
      label: 'Branch',
      name: 'branch',
      modelname: 'branches',
      link: '/secure/:site/branches/:branch',
      optionlabel: 'name',
      format: 'title'
    }
  ]

  if (modelname === 'contacts') {
    valuation_common_fields = [ ...valuation_common_fields, ...valuation_contact_fields ]
  } else if ([ 'residential', 'commercial' ].includes(modelname)) {
    valuation_common_fields = [
      ...valuation_common_fields.slice(0, 3), valuation_listing_field, ...valuation_common_fields.slice(3)
    ]
  }
  return (
    <Card
      bodyclass="alerts-sent no-top-padding"
      background
      header={
        <h3></h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.valuations}
          action={actions.fetchValuations}
          params={params}
          header={valuation_common_fields}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={parseURL(`${getIn(data, 'meta.listing.link') ? `${getIn(data, 'meta.listing.link')}/..` : `/secure/${data.site}`}/valuations/${data.id}/edit`)} title="View Valuations" type="button" />
            </>
          )}
        />
      }
    />
  )
}

Valuations.propTypes = {
  permissions: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  currency: PropTypes.string,
  cache: PropTypes.object,
  alerts: PropTypes.object,
  fetchAlerts: PropTypes.func,
  updateModel: PropTypes.func,
  configs: PropTypes.object,
  user: PropTypes.object,
  modelid: PropTypes.number,
  actions: PropTypes.object,
  modelname: PropTypes.string
}

export default Valuations
