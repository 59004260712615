/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import classNames from 'classnames'
import { sub, endOfYear, parse, format as formatDate, isValid, isEqual as isSameDate } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { enZA } from 'date-fns/locale'

import { SITE } from '../../../../selectors'
import { Button } from '../../../ui/Button'
import HelpText from './../../HelpText'
import Label from './Label'


const newTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#78789A'
          },
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontSize: 14,
          fontFamily: '\'Poppins\', sans-serif'
        }
      }
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          fontFamily: '\'Poppins\', sans-serif',
          textTransform: 'none',
          fontSize: 14,
          color: '#78789A',
          fontWeight: 500
        }
      }
    }
  }
})

const BrowserField = React.forwardRef(
  (props, ref) => {
    const {
      // eslint-disable-next-line no-unused-vars
      disabled,
      // eslint-disable-next-line no-unused-vars
      id,
      // eslint-disable-next-line no-unused-vars
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
      // eslint-disable-next-line no-unused-vars
      error,
      // eslint-disable-next-line no-unused-vars
      focused,
      // eslint-disable-next-line no-unused-vars
      ownerState,
      // eslint-disable-next-line no-unused-vars
      sx,
      value,
      className,
      button,
      inline,
      inputProps,
      ...other
    } = props
    const handleRef = useForkRef(containerRef, ref)
    return (
      <div className="flatpickr-wrapper" ref={handleRef}>
        {startAdornment}
        <input
          disabled={disabled}
          ref={inputProps.ref}
          {...other}
          id={ownerState.ownerState.name}
          name={ownerState.ownerState.name}
          value={value}
          className={classNames('form-control', { 'input-group-suffix': value && !button }, className, { hidden: inline || button })}
          type={(inline || button) ? 'hidden' : 'text'}
        />
        {endAdornment}
      </div>
    )
  }
)
BrowserField.displayName = 'BrowserField'

class DateInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleClose = this.handleClose.bind(this)
    const enable = props.enabled && props.enabled.length ? props.enabled.map(d => new Date(d)).sort((a, b) => {
      const dateA = a
      const dateB = b
      if (dateA < dateB) {
        return -1
      }
      if (dateA > dateB) {
        return 1
      }
      return 0
    }) : null
    this.state = {
      enable,
      referenceDate: enable?.slice(-1).pop(),
      open: false
    }
  }

  componentDidMount() {
    if (this.props.enabled) {
      this.setState({
        enable: this.props.enabled.map(d => new Date(d)).sort((a, b) => {
          const dateA = a
          const dateB = b
          if (dateA < dateB) {
            return -1
          }
          if (dateA > dateB) {
            return 1
          }
          return 0
        })
      }, () => {
        if (this.calendar) {
          this.calendar.jumpToDate(this.state.enable[this.state.enable.length - 1], true)
        }
      })
    }
    if (
      !this.props.form.values.id && // This is not an update
      this.props.defaultDate &&
      this.props.defaultDate === 'today' &&
      !this.props.field.value
    ) {
      const defaulted = new Date().toJSON().slice(0, 10)
      this.props.form.setFieldValue(this.props.field.name, defaulted).then(() => {
        this.props.form.setFieldTouched(this.props.field.name, false, false)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.enabled, prevProps.enabled)) {
      const enable = this.props.enabled && this.props.enabled.length ? (
        this.props.enabled.map(d => new Date(d)).sort((a, b) => {
          const dateA = a
          const dateB = b
          if (dateA < dateB) {
            return -1
          }
          if (dateA > dateB) {
            return 1
          }
          return 0
        })) : null
      this.setState({ enable, referenceDate: enable?.slice(-1).pop() })
    }
  }

  handleChange(value, context) {
    const { name } = this.props.field
    const { format, minDateField, maxDateField } = this.props
    const prevValue = this.props.field.value
    if (value && !(value instanceof Date && isFinite(value))) {
      return
    }
    if (!value) {
      this.props.form.setFieldValue(name, null, false).then(() => {
        this.props.form.setFieldTouched(name, true)
      })
    }
    let dateFormat
    switch (format) {
      case 'datetime':
        dateFormat = 'yyyy-MM-dd HH:mm'
        break
      case 'time':
        dateFormat = 'HH:mm'
        break
      case 'monthday':
        dateFormat = 'MM/dd'
        break
      default:
        dateFormat = 'yyyy-MM-dd'
    }
    if (value && !context?.validationError) {
      let datestr = formatDate(value, dateFormat)
      if (format === 'monthday' && datestr) {
        const [ month, day ] = datestr.split('/')
        datestr = { month, day }
      }
      this.props.form.setFieldValue(name, datestr, false).then(() => {
        this.props.form.setFieldTouched(name, true)
      })
    } else {
      let message
      switch (context?.validationError) {
        case 'disablePast':
          message = 'Must be in the future'
          break
        case 'disableFuture':
          message = 'Must be in the past'
          break
        case 'minDate': {
          const minDate = this.props.form.values[minDateField] ? new Date(this.props.form.values[minDateField]) : null
          message = minDate ? `Must be after ${format(minDate, dateFormat)}` : 'This date is invalid'
          break
        }
        case 'maxDate': {
          const maxDate = this.props.form.values[maxDateField] ? new Date(this.props.form.values[maxDateField]) : null
          message = maxDate ? `Must be before ${format(maxDate, dateFormat)}` : 'This date is invalid'
          break
        }
        default:
          message = null
      }
      this.props.form.setFieldError(name, message)
    }
    if (this.props.onChange) {
      let datestr
      if (value) {
        datestr = formatDate(value, dateFormat)
      }
      if (!isEqual(datestr, prevValue)) {
        this.props.onChange()
      }
    }
  }

  handleBlur() {
    this.props.form.setFieldTouched(this.props.field.name)
  }

  handleClose(event, instance, picnode) {
    if (!picnode.contains(event.target)) {
      document.getElementById('wrapper').removeEventListener('mousedown', e => this.handleClose(e, instance, picnode))
      instance.close()
    }
  }

  render() {
    const { field, form, help, label, className, minDateField, maxDateField, format, id, futureonly,
      over18, inline, button, position, disabled } = this.props
    const { name } = field
    const calnode = document.getElementById('wrapper')
    const options = {}
    if (this.props.minDateField) {
      options.minDate = this.props.form.values[minDateField] ? new Date(this.props.form.values[minDateField]) : null
    } else if (futureonly) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      options.disablePast = true
    }
    if (this.props.maxDateField) {
      options.maxDate = this.props.form.values[maxDateField] ? new Date(this.props.form.values[maxDateField]) : null
    }
    if (inline && this.state.enable) {
      options.disableHighlightToday = true
      options.defaultCalendarMonth = this.state.referenceDate
      options.maxDate = this.state.referenceDate
    }
    let dateFormat = 'yyyy-MM-dd'
    if (format === 'datetime') {
      dateFormat = 'yyyy-MM-dd HH:mm'
    }
    if (format === 'time') {
      dateFormat = 'HH:mm'
    }
    if (format === 'monthday') {
      options.views = [ 'month', 'day' ]
      dateFormat = 'MM/dd'
    }
    if (over18) {
      options.maxDate = sub(endOfYear(new Date().getTime()), { years: 18 })
    }
    if (position) {
      options.position = position
    }
    options.positionElement = button ? this.el?.parentElement : null
    options.static = !button // renders inside .forminput instead of body
    if (isValid(parse(field.value, dateFormat, new Date()))) {
      options.value = parse(field.value, dateFormat, new Date())
    } else if (format === 'time' && isValid(parse(field.value, 'HH:mm:ss', new Date()))) {
      options.value = parse(field.value, 'HH:mm:ss', new Date())
    } else if (field.value && isValid(new Date(field.value))) {
      options.value = new Date(field.value)
    } else if (format === 'monthday' && isValid(parse(`${field.value?.month}/${field.value?.day}`, 'MM/dd', new Date()))) {
      options.value = parse(`${field.value?.month}/${field.value?.day}`, 'MM/dd', new Date())
    } else {
      options.value = null
    }
    return (
      <div id={id} ref={el => (this.el = el)} className={`form-group dateinput ${name} ${className}`}>
        {label &&
        <Label htmlFor={name}>
          {label}
        </Label>
        }
        { calnode ? (
          <ThemeProvider theme={newTheme}>
            <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
              <div className={classNames([ 'forminput' ], { 'input-only': button })}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ enZA }>
                  {format !== 'time' && !inline ? (
                    <>
                      <DatePicker
                        open={this.state.open}
                        closeOnSelect
                        disabled={disabled}
                        format={dateFormat}
                        showDaysOutsideCurrentMonth
                        onAccept={value => {
                          this.setState({ open: false })
                          this.handleChange(value)
                        }}
                        id={this.props.id || name}
                        name={name}
                        onChange={this.handleChange}
                        onError={() => {
                          form.setFieldValue(name, null)
                        }}
                        reduceAnimations
                        {...options}
                        slotProps={{
                          textField: {
                            button,
                            inline
                          }
                        }}
                        slots={{
                          openPickerIcon: () => '',
                          openPickerButton: () => '',
                          textField: BrowserField
                        }}
                      />
                      {(field.value && !button && !disabled) ? (
                        <Button
                          type="button"
                          icon={button ? '#icon16-X-Small' : '#icon24-X-Small'}
                          className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
                          title="open"
                          onClick={() => {
                            form.setFieldValue(name, null, false).then(() => {
                              form.setFieldTouched(name, true)
                            })
                          }}
                        />
                      ) : null}
                      {!disabled ? (
                        <Button
                          type="button"
                          icon={button ? '#icon16-Calendar' : '#icon24-Calendar'}
                          className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
                          title="open"
                          onClick={() => {
                            if (!disabled) {
                              this.setState({ open: !this.state.open })
                            }
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {(format !== 'time' && inline) ? (
                    <StaticDatePicker
                      clearable
                      format={dateFormat}
                      disabled={disabled}
                      showDaysOutsideCurrentMonth
                      onChange={this.handleChange}
                      id={this.props.id || name}
                      name={name}
                      shouldDisableDate={day => (this.state.enable?.find(date => isSameDate(date, day)) ? false : true)}
                      {...options}
                      reduceAnimations
                      slotProps={{ actionBar: { actions: [ 'clear' ] } }}
                    />
                  ) : null}
                  {format === 'time' ? (
                    <>
                      <TimePicker
                        open={this.state.open}
                        format={dateFormat}
                        disabled={disabled}
                        onAccept={value => {
                          this.setState({ open: false })
                          this.handleChange(value)
                        }}
                        onChange={this.handleChange}
                        id={this.props.id || name}
                        name={name}
                        reduceAnimations
                        {...options}
                        slots={{
                          openPickerIcon: () => '',
                          openPickerButton: () => '',
                          textField: BrowserField
                        }}
                      />
                      {(field.value && !disabled) ? (
                        <Button
                          type="button"
                          icon={button ? '#icon16-X-Small' : '#icon24-X-Small'}
                          className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
                          title="open"
                          onClick={() => {
                            form.setFieldValue(name, null, false).then(() => {
                              form.setFieldTouched(name, true)
                            })
                          }}
                        />
                      ) : null}
                      {!disabled ? (
                        <Button
                          type="button"
                          icon={button ? '#icon16-Clock' : '#icon24-Clock'}
                          className={classNames([ 'btn input-group-addon btn-icon-only' ], { 'btn-none': !button, 'btn-icon-24': !button, 'btn-subtle': button, 'btn-icon-16': button })}
                          title="open"
                          onClick={() => {
                            if (!disabled) {
                              this.setState({ open: !this.state.open })
                            }
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </LocalizationProvider>
              </div>
            </ClickAwayListener>
            {help &&
            <span className="help-text">
              {help.map((component, ind) => (<HelpText key={`${name}-key-${ind}`} {...component} />))}
            </span>
            }
            <ErrorMessage render={msg => <div className="error">{msg}</div>} name={name} />
          </ThemeProvider>
        ) : null}

      </div>
    )
  }
}

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  enabled: PropTypes.array,
  className: PropTypes.string,
  help: PropTypes.string,
  dependents: PropTypes.array,
  defaultDate: PropTypes.string,
  minDateField: PropTypes.string,
  maxDateField: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  over18: PropTypes.bool,
  futureonly: PropTypes.bool,
  inline: PropTypes.bool,
  button: PropTypes.bool,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  site: PropTypes.object,
  label: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ])
}


/* Special case here. We are unable to directly affect the html node
 * added by flatpickr, so we need to inject styles manually. To do this
 * the component needs to be connected to the store
 */
const mapStateToProps = state => {
  const site = SITE(state)
  return ({
    site
  })
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(DateInput))
