import { getIn } from 'formik'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'
import { Button } from '../ui/Button'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ContactApplications = props => {
  const abortController = useRef(new AbortController())

  const { user, modelid, actions, configs, cache } = props
  return (
    <Card
      bodyclass="applications no-top-padding"
      background
      header={
        <h3>Applications</h3>
      }
      body={
        <SimpleTable
          paginated
          config={configs.applications}
          currency={cache.settings[user.agent.site.id].default_currency}
          action={({ params, resolve, reject }) => {
            new Promise((res, rej) => actions.fetchMany({
              noloader: true,
              values: {
                modelname: 'applications',
                select: true,
                signal: abortController.current.signal,
                params
              },
              resolve: res,
              reject: rej
            })).then(r => {
              resolve(r)
            }).catch(e => {
              reject(e)
            })
          }}
          params={{
            contact: modelid,
            order_by: '-created',
            meta_fields: [ 'residential', 'commercial', 'holiday', 'contact' ]
          }}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              name: 'total_lease_fees',
              label: 'Application Total',
              group: 'General Details',
              classes: [ 'text-right' ],
              input: 'Currency',
              format: 'currency',
              cols: 'lg-6'
            },
            {
              name: 'listing',
              label: 'Listing',
              format: 'listing_popup',
              permissions: [
                'listings_residential_for_sale_view',
                'listings_residential_for_sale_view_own',
                'listings_commercial_for_sale_view',
                'listings_commercial_for_sale_view_own',
                'listings_residential_to_let_view',
                'listings_residential_to_let_view_own',
                'listings_commercial_to_let_view',
                'listings_commercial_to_let_view_own',
                'listings_projects_view',
                'listings_projects_view_own'
              ],
              group: 'Record Details',
              cols: 'lg-6',
              edit: null
            },
            {
              name: [
                'listing.unit_number',
                'listing.complex_name',
                ',',
                'listing.building_name',
                ',',
                'listing.street_number',
                'listing.street_name'
              ],
              link: 'meta.listing.link',
              tooltip: true,
              orderable: false,
              label: 'Address',
              group: 'Record Details',
              container: 'meta'
            },
            {
              name: 'expiry_date',
              label: 'Expiry Date',
              group: 'General Details',
              cols: 'lg-6',
              input: 'Date',
              required: true
            },
            {
              name: 'status',
              label: 'Status',
              group: 'Record Details',
              format: 'application-stage'
            }
          ]}
          parser={data => {
            data.options = data.options.map(d => {
              const application = { ...d }
              let total = 0;
              [
                'deposit',
                'key_deposit',
                'utility_deposit',
                'lease_fee',
                'pro_rata_rent',
                'first_months_rent'
              ].forEach(k => {
                total += parseFloat(getIn(application, k, 0) || 0)
                if (!getIn(application, k) || parseFloat(getIn(application, k)) === 0) {
                  delete application[k]
                }
              })
              application.total_lease_fees = total
              return application
            })
            return data
          }}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/applications/${data.id}`} title="View Subscription" type="button" />
            </>
          )}
        />
      }
    />
  )
}

ContactApplications.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number
}

export default ContactApplications
