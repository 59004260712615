import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getIn, ErrorMessage } from 'formik'
import HelpText from '../../HelpText'

import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import DeedsLookupSidebar from '../../../../containers/DeedsLookupSidebar'
import { capitalize } from '../../../../utils'
import Label from './Label'


class DeedsLookup extends React.Component {
  /* This component is at the moment only used for showing conflicting
  * serviced locations in the branch model. It can be extended in the future
  * if the developer finds a use case, however for now this is the single
  * place it is used.
  */
  constructor(props) {
    super(props)
    this.state = {
      value: props.field.value,
      open: false
    }
    this.toggleLookup = this.toggleLookup.bind(this)
  }

  componentDidMount() {
    this.root = document.getElementById('content')
    if (this.state.value !== getIn(this.props.form.initialValues, this.props.field.name)) {
      this.setState({ value: getIn(this.props.form.initialValues, this.props.field.name) })
    }
  }

  componentDidUpdate() {
    this.root = document.getElementById('content')
    if (this.state.value !== getIn(this.props.form.initialValues, this.props.field.name)) {
      this.setState({ value: getIn(this.props.form.initialValues, this.props.field.name) })
    }
  }

  toggleLookup() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { className, label, id, ...props } = this.props
    const { field, form, disabled, type } = props
    const { name, value } = field
    return (
      <Fragment>
        <WideSidebar sidebar={`show-lookup-sidebar-${field.name}`}>
          <DeedsLookupSidebar
            fieldProps={field}
            name={field.name}
            key={`dlookup-${field.name}`}
            deeds={props.deeds}
            ownerfield={props.ownerfield}
            toggleLookup={this.toggleLookup}
            setInitVals={this.props.setInitVals}
            match={props.match}
          />
        </WideSidebar>
        <div id={id} className={`form-group deeds-lookup ${props.classes ? props.classes : ''}`}>
          <div className="forminput">
            <Label htmlFor={name} className="formlabel input-label">
              {label}
            </Label>
            <input
              id={`input-${id}`}
              name={name}
              type={type}
              disabled={disabled}
              readOnly={this.state.value}
              placeholder={props.placeholder}
              className={`form-control input-group-suffix ${className ? className : ''}`}
              {...field}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={value || ''}
              maxLength={props.maxLength}
            />
            {!disabled ? (
              <Button tabIndex="-1" type="button" onClick={() => { this.props.toggleWideSidebar(`show-lookup-sidebar-${field.name}`) }} disabled={this.state.searching} className="input-group-addon btn btn-grey">
                {capitalize(props.deeds)} Lookup
              </Button>
            ) : null}
            {props.help &&
              <span className="help-text">
                {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
              </span>
            }
            <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
          </div>
        </div>
      </Fragment>
    )
  }
}

DeedsLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  ownerfield: PropTypes.string,
  classes: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  create: PropTypes.bool,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  config: PropTypes.object,
  match: PropTypes.object,
  setInitVals: PropTypes.func
}


export default DeedsLookup
