/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import uuidv4 from 'uuid/v4'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import Activity from '../common/Activity'
import DetailsList from '../common/DetailsList'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import { Button } from '../ui/Button'
import Loader from '../common/Loader'
import Card from '../common/Card'

import { breakpoint, slugify, isInViewport, sortBy } from '../../utils'


const Insights = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    match,
    routeConfig
  } = props

  const [ src, setSrc ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ dimensions, setDimensions ] = useState({ width: '100%', height: 'auto' })
  const [ view, setView ] = useState('Desktop')
  const [ showActions, setShowActions ] = useState(breakpoint.matches)
  const [ currentGroup, setCurrentGroup ] = useState(breakpoint.matches)
  const [ groups, setGroups ] = useState([])
  const [ scale, setScale ] = useState(1)
  const preview = useRef()
  const resize = useRef()
  const scroller = useRef()

  const viewWidth = (view === 'Mobi') ? 360 : 600

  const checkSize = () => {
    clearTimeout(resize.current)
    resize.current = setTimeout(() => {
      const obj = preview.current
      if (
        dimensions.width !== obj.contentWindow.document.body.scrollWidth
        || dimensions.height !== obj.contentWindow.document.body.scrollHeight
      ) {
        setDimensions({
          width: obj.contentWindow.document.body.scrollWidth,
          height: Math.max(
            document.body.scrollHeight,
            obj.contentWindow.document.body.scrollHeight + 15
          )
        })
      }
      setScale(view === 'Mobi' ? viewWidth / 600 : 1)
    }, 150)
  }


  const toggleActions = e => {
    if (e.matches && !showActions) {
      setShowActions(true)
    } else if (e.matches !== undefined && showActions) {
      setShowActions(false)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-new
    setLoading(true)
    new Promise((resolve, reject) => actions.createModel({
      values: {
        modelname: 'insights',
        endpoint: {
          write: '/reports/api/v1/reports/?preview=1'
        },
        noparse: true,
        action: 'report',
        name: 'Insights Report',
        callback_id: uuidv4(),
        model: 'insights',
        filters: {
          id__in: [ model.id ]
        },
        template: 'insights-report'
      },
      resolve,
      reject
    })).then(r => {
      setSrc(r)
    })
    breakpoint.addEventListener('change', toggleActions)
    return () => {
      breakpoint.removeEventListener('change', toggleActions)
    }
  }, [])

  useEffect(() => {
    checkSize()
  }, [ view ])

  let framewidth = !showActions ? dimensions.width : '100%'
  if (view === 'Mobi') {
    framewidth = 600
  }

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      <Tab tab="details" label="Details" noScroll>
        <div
          className={classNames('jumplist', { show: showActions })}
          id={'slider-jumplist-groups'}
        >
          <div className="jumplist-inner">
            { !showActions ? (
              <span className={'cardtoggle-jump'}>
                <Button type="button" className="btn btn-icon-right" onClick={() => this.setState({ showJump: true })}>
                  {getIn(this.state, 'currentGroup.label', Object.keys(config.fieldgroups)[0])}
                </Button>
              </span>
            ) : null }
            {groups.map((group, gidx) => {
              const slug = slugify(group.textContent)
              return (
                <NavLink
                  key={`jl-${gidx}`}
                  aria-current="step"
                  isActive={() => slug === currentGroup.id}
                  to={`#${slug}`}
                  onClick={() => {
                    const box = group.getBoundingClientRect()
                    scroller.current.scrollTo({
                      top: box.top,
                      behavior: 'smooth'
                    })
                    setCurrentGroup({
                      id: group.getAttribute('id'),
                      label: group.textContent,
                      top: group.getBoundingClientRect().top,
                      height: group.getBoundingClientRect().height
                    })
                  }}
                >
                  {group.textContent}
                </NavLink>
              )
            })}
          </div>
        </div>
        <div ref={scroller} className="model-details">
          <div className="stepper"></div>
          <Card
            background={true}
            header={
              <h3>Preview</h3>
            }
            bodyclass="no-padding"
            body={
              <div className='previewcontainer' style={{ width: '100%' }}>
                <nav id='previewcontainer-nav' className='previewcontainer-nav'>
                  {showActions ? (
                    <div className="nav-group">
                      <Button icon="#icon24-Website" title="Desktop" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => setView('Desktop')} />
                      <Button icon="#icon24-Mobile" title="Mobi" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => setView('Mobi', () => {
                        this.checkSize()
                      })} />
                    </div>
                  ) : null }
                </nav>
                <div className='previewcontainer-content' style={{
                  width: view === 'Mobi' ? 360 : '100%',
                  height: dimensions.height * (view === 'Mobi' ? viewWidth / 600 : 1),
                  overflow: 'hidden'
                }}>
                  {loading ? <Loader onError={() => {}} throwViewError={() => {}} /> : null}
                  <iframe
                    key={`iframe-${config.model}`}
                    ref={preview}
                    scrolling={'no'}
                    onLoad={() => {
                      const obj = preview.current
                      setLoading(false)
                      if (src) {
                        const elements = Array.from(obj.contentWindow.document.querySelectorAll('.group-heading'))
                        setGroups(elements)
                      }
                      setDimensions({
                        width: obj.contentWindow.document.body.scrollWidth,
                        height: Math.max(
                          document.body.scrollHeight,
                          obj.contentWindow.document.body.scrollHeight + 15
                        )
                      })
                    }}
                    style={{
                      height: dimensions.height,
                      width: framewidth,
                      transformOrigin: 'top left',
                      transform: `scale(${scale})`
                    }}
                    srcDoc={src}
                  />
                </div>
              </div>
            }
          />
        </div>
      </Tab>

    </HorizontalTabs>
  )
}

Insights.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Insights
