/* eslint-disable no-console */
/* eslint-disable new-cap */
import { createPopper } from '@popperjs/core'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { getIn } from 'formik'
import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import Slider from '../../common/Slider'
import { NAV, PERMISSIONS, ADDONS, CONFIG } from '../../../selectors'
import { hasAddons, hasPermission } from '../../../utils'


const checkPerms = ({ props, addons, permissions, routes }) => {
  const { model, area, log, to } = props
  const route = getIn(routes, model)
  if (!route) { return false }
  let allowed = false
  const excludeSitePath = log ? getIn(route, `${log}.${area}.path`, '').replace('/:site', '') : getIn(route, `${area}.path`, '').replace('/:site', '')
  const excludeSiteTo = to.replace(/(secure)\/(\d+)/, '$1')
  const caddons = log ? getIn(route, `${log}.${area}.addons`) : getIn(route, `${area}.addons`)
  const routePermissions = log ? getIn(route, `${log}.${area}.permissions`) : getIn(route, `${area}.permissions`)
  if (
    excludeSitePath === excludeSiteTo
  ) { // The area has a path
    if (addons && !hasAddons(caddons, addons)) {
      return false
    }
    if (permissions.includes('is_prop_data_user')) {
      allowed = true
    } else if (permissions) {
      allowed = hasPermission(routePermissions, permissions)
    } else {
      allowed = true // No perms on branch and users list (for empty user groups)
    }
  }
  return allowed // Default to disallow
}

const SubMenu = ({
  visible_children,
  collapsed,
  className,
  icon,
  title,
  active
}) => {
  const [ closed, setClosed ] = useState(!active)
  const ref = useRef(null)
  const sliderRef = useRef(null)
  const popperElement = useRef(null)
  const popperElRef = useRef(null)
  const popperInstance = useRef(null)

  const updatePopper = () => {
    if (ref.current) {
      popperInstance.current = createPopper(ref.current, popperElement.current, {
        placement: 'right-start',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              adaptive: false
            }
          }
        ]
      })
    }
  }

  useEffect(() => {
    updatePopper()

    popperInstance.current = createPopper(ref.current, popperElement.current, {
      placement: 'right-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            adaptive: false
          }
        }
      ]
    })
    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy()
        popperInstance.current = null
      }
    }
  }, [])

  useEffect(() => {
    setClosed(!active)
  }, [ active ])

  useEffect(() => {
    updatePopper()
  }, [ collapsed ])

  const handleToggleSubMenu = () => {
    if (!collapsed) {
      setClosed(!closed)
    }
  }

  if (!visible_children.length) { return null }

  let path = '/images/icons-16.svg'

  if (icon && icon.includes('#icon24')) {
    path = '/images/icons-24.svg'
  } else if (icon && icon.includes('#glyph')) {
    path = '/images/glyphs.svg'
  } else if (icon && icon.includes('#social')) {
    path = '/images/social.svg'
  }
  return (
    <li
      className={`menu-item sub-menu${className ? ` ${className}` : ''}${!closed ? ' active' : ''}${(open || !closed) ? ' open' : ''}`}
      aria-current={!closed ? 'parent' : false}
    >
      <div
        ref={ref}
        className="inner-item"
        onClick={handleToggleSubMenu}
        onKeyPress={handleToggleSubMenu}
        role="button"
        tabIndex={0}
      >
        {icon ? (
          <span className="menu-icon-wrapper">
            <span className="menu-icon"><svg viewBox="0 0 24 24"><use href={`${path}${icon}`} /></svg></span>
          </span>
        ) : null}
        <span className="menu-item-content">{title}</span>
        <span className="menu-arrow-wrapper">
          <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-ChevronRight" /></svg>
        </span>
      </div>
      {collapsed ? (
        <div
          ref={popperElement}
          className='list-item popper-element'
        >
          <div className="popper-inner" ref={popperElRef}>
            <ul>{visible_children}</ul>
          </div>
        </div>
      ) : (
        <Slider
          closed={closed}
          classes="list-item"
          id={`submenu-${title}-slider`}
          childref={sliderRef}
        >
          <ul ref={sliderRef}>
            {visible_children}
          </ul>
        </Slider>
      )}
    </li>
  )
}

SubMenu.propTypes = {
  visible_children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  active: PropTypes.bool,
  collapsed: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const match = matchPath(ownProps.location.pathname, { path: ownProps.to })
  const active = !!match
  const { children } = ownProps
  const addons = ADDONS(state).toJS()
  const permissions = PERMISSIONS(state).toJS()
  const routes = CONFIG(state, 'routes').toJS()
  const visible_children = Array.isArray(children) ? (
    children.filter(c => checkPerms({ props: c.props, addons, permissions, routes }))
  ) : (
    [ children ].filter(c => checkPerms({ props: c.props, addons, permissions, routes }))
  )
  const nav = NAV(state)
  return {
    visible_children,
    active,
    collapsed: nav === 'mini'
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // eslint-disable-next-line no-unused-vars
  const { location, children, ...rest } = ownProps
  return ({ ...stateProps, ...rest })
}

export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(React.memo(SubMenu)))

// SubMenu.whyDidYouRender = true
