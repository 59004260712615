import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

import { isEmpty } from '../utils'
import { reset } from '../validate/reset'
import TextInput from './common/forms/inputs/Text'
import Footer from './Footer'
import { Button } from './ui/Button'


class Reset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      activated: false,
      error: false
    }
  }

  componentDidMount() {
    if (this.props.action === 'activate') {
      new Promise((resolve, reject) => {
        const values = { hash: this.props.match.params.hash }
        this.props.actions.activateUser({ values, resolve, reject })
      }).then(r => {
        this.setState({
          activated: true,
          redirect: r.redirect
        })
      }).catch(() => {
        this.setState({
          activated: true,
          error: true
        })
      })
    }
  }

  render() {
    if (this.state.redirect) {
      const redir = this.state.redirect
      this.setState({ redirect: false })
      return <Redirect to={redir} />
    }
    if ((this.props.action === 'activate' && this.state.activated) || this.props.action === 'reset') {
      return (
        <div className="wrapper login">
          <div className="content">
            <div className="content-wrapper">
              <div className="login-box">
                <div className="pd-logo"></div>
                <h3>Confirm your new password below</h3>
                { this.state.error ? (

                  <div className="login-box-body">
                    <div className="box-header">
                      <h3><span>Sorry</span>There was an error activating your account.</h3>
                    </div>
                  </div>

                ) : (

                  <Formik
                    initialValues={{
                      hash: this.props.match.params.hash
                    }}
                    validationSchema={reset}
                    validateOnBlur={true}
                    validateOnChange={false}
                    onSubmit={(values, actions) => new Promise((resolve, reject) => {
                      this.props.actions.resetPass({ values, resolve, reject })
                    }).then(() => {
                      actions.setSubmitting(false)
                      actions.setStatus({
                        type: 'success',
                        msg: 'Password successfully changed - please sign in.'
                      })
                      this.props.actions.resetSuccess()
                      this.setState({ redirect: { pathname: '/login' } })
                    }).catch(e => {
                      actions.setSubmitting(false)
                      if (e.message) { // JS error
                        actions.setStatus({
                          type: 'error',
                          msg: e.message
                        })
                      } else if (e.raw) { // Services error
                        actions.setStatus({
                          type: 'error',
                          msg: e.raw.detail
                        })
                      } else if (e.error) { // Services error
                        actions.setStatus({
                          type: 'error',
                          msg: e.error
                        })
                      }
                    }) }
                  >{({ errors, status, isSubmitting }) => (
                      <Form>
                        <div className="login-box-body">
                          <input
                            type="hidden"
                            name="hash"
                          />
                          <Field
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            component={TextInput}
                          />
                          <Field
                            label="Repeat Password"
                            type="password"
                            name="password_again"
                            placeholder="Password (again)"
                            component={TextInput}
                          />
                          <div className="login-box-buttons">
                            <Link to="/login" className="forgot float-right">Back to login</Link>
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              disabled={ isSubmitting || !isEmpty(errors) }
                            >
                              Reset Password
                            </Button>
                          </div>
                          <div className={status ? `${status.type} message` : 'message'}>
                            <div className="msg warn">{status && status.msg}</div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                )}

                <div className="login-box-footer">
                  <a href="http://support.propdata.net/" target="_blank" rel="noopener noreferrer">Need Help? Click here for Support</a>
                </div>
                <Footer />
              </div>
            </div>
            <div className="overlay"></div>
          </div>
        </div>
      )
    }
    return null
  }
}

Reset.propTypes = {
  actions: PropTypes.object,
  action: PropTypes.string,
  match: PropTypes.object,
  ui: PropTypes.object
}


export default Reset
