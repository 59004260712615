import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import { getDisplayName, useCustomCompareMemo } from '../utils'


export const withCustomRouter = WrappedComponent => withRouter(props => {
  // eslint-disable-next-line no-unused-vars
  const { computedMatch, match, location, history, ...p } = props
  const [ locationProps, setLocationProps ] = useState({ match, location, history })

  useEffect(() => {
    const newLocationProps = {
      match, location, history
    }
    if (!isEqual(locationProps, newLocationProps)) {
      setLocationProps(newLocationProps)
    }
    return () => {}
  }, [ useCustomCompareMemo(match), useCustomCompareMemo(location), useCustomCompareMemo(history) ])
  WrappedComponent.displayName = `withRouter(${getDisplayName(WrappedComponent)})`
  return (
    <WrappedComponent
      {...p}
      {...locationProps}
    />
  )
})


export const withNoHistoryRouter = WrappedComponent => withRouter(props => {
  // eslint-disable-next-line no-unused-vars
  const { computedMatch, match, location: { pathname, search }, ...p } = props
  const [ locationProps, setLocationProps ] = useState({ match, location: { pathname, search } })

  useEffect(() => {
    const newLocationProps = {
      match, location: { pathname, search }
    }
    if (!isEqual(locationProps, newLocationProps)) {
      setLocationProps(newLocationProps)
    }
    return () => {}
  }, [ useCustomCompareMemo(match), useCustomCompareMemo(location) ])

  WrappedComponent.displayName = `withNoHistoryRouter(${getDisplayName(WrappedComponent)})`
  return (
    <WrappedComponent
      {...p}
      {...locationProps}
    />
  )
})

// withNoHistoryRouter.whyDidYouRender = true

