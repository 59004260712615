/* eslint-disable new-cap */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import { getIn } from 'formik'
import { MINUSER, SETTINGS, CACHE, CACHEDMODELID } from '../../selectors'
import { Scrollbar } from '../ui/Scrollbars'
import { Button } from '../ui/Button'
import Activity from './Activity'


class NoteActivity extends React.Component {
  render () {
    const { cache, model, config, actions, user, currency } = this.props
    return (
      <div id="note-activity-sidebar" ref={el => { this.el = el }} className="wide-sidebar note-activity-sidebar">
        <div className="wide-sidebar-container">
          <div className="wide-sidebar-pane">
            <div className="wide-sidebar-heading">
              <h4>Note Activity</h4>
              <Button
                type="button"
                icon="#icon24-X-Large"
                className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                onClick={() => { this.props.actions.toggleWideSidebar() }}
              />
            </div>
            <Scrollbar
              style={{ height: 'calc(100vh - 218px)' }}
              renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
            >
              <div className="wide-sidebar-content">
                <Activity
                  model={model}
                  cache={cache}
                  config={config}
                  user={user}
                  settings={{ currency }}
                  agents={getIn(cache, 'agents', {})}
                  fetchActivity={actions.fetchActivity}
                  fetchOne={actions.fetchOne}
                  fetchMany={actions.fetchMany}
                />
              </div>
            </Scrollbar>
          </div>
        </div>
      </div>
    )
  }
}

NoteActivity.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  sidebar: PropTypes.string,
  currency: PropTypes.string,
  model: PropTypes.object,
  toggleNoteActivity: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.model
  const model = CACHEDMODELID(state, 'notes', id) || Map(ownProps.model)
  const settings = SETTINGS(state)
  const currency = settings.default_currency
  const user = MINUSER(state)
  return {
    user: Map({ agent: user.get('agent') }),
    cache: CACHE(state),
    currency,
    model
  }
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(NoteActivity))
