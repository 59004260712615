import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { getIn } from 'formik'

import { valueFormat, isConditional } from '../../utils'
import Card from './Card'
import UserPhoto from './UserPhoto'


class ListingContacts extends React.Component {
  constructor(props) {
    super(props)
    this.contacts = [
      'agent',
      'agent_2',
      'agent_3',
      'agent_4',
      'owners',
      'tenant',
      'property_sold_rented_to',
      'sold_agent'
    ]
    this.renderModel = this.renderModel.bind(this)
  }

  renderModel (field, value, index) {
    const { model, cache, user } = this.props
    const { region } = cache.settings[user.agent.site.id]
    let related_model = cache[field.modelname][value]
    let disabled = false
    if (!related_model) {
      related_model = getIn(model, `meta.${field.name}.${index}`)
      disabled = true
    }
    if (!related_model || (disabled && [ 'ae' ].includes(region))) {
      return null
    }
    let can_edit = true
    if (field.modelname === 'contacts' && field.edit && !isConditional({ ...field, edit: [
      [ {
        condition: {
          type: 'permissions',
          permissions: [
            'contacts_view',
            'contacts_view_own',
            'contacts_associated_agents_view'
          ],
          permission_key: {
            introduction_agent: [ 'contacts_view_own' ],
            associated_agents: [ 'contacts_associated_agents_view' ]
          }
        }
      } ]
    ] }, 'edit', false, { values: getIn(model, `meta.${field.name}.${index}`, {}) }, user)) {
      can_edit = false
    }
    if (!can_edit) { return null }
    let { label } = field
    if (field.name === 'owners' && field.modelname === 'contacts') {
      label = model.listing_type === 'To Let' ? 'Landlord' : 'Seller'
    }
    if (index === 0) { label = `${label} 1`}
    const active = related_model.status || related_model.active ? 'Active' : 'Inactive'
    return (
      <Card
        key={`listing-contact-${index}`}
        background
        body={
          <div className="listing-contacts-card">
            <UserPhoto model={related_model} tooltip={`${related_model.first_name ? related_model.first_name : ''} ${related_model.last_name ? related_model.last_name : ''}`} />
            <div className='listing-contacts-details'>
              <div className="listing-contacts-label">{label}</div>
              <h3 className="listing-contacts-name"><Link className='has-link' to={`/secure/${model.site}/${field.modelname}/${related_model.id}`}>{`${related_model.first_name ? related_model.first_name : ''} ${related_model.last_name ? related_model.last_name : ''}`}</Link></h3>
              <div className={classNames('listing-contacts-status', { active: related_model.status || related_model.active })}>{active}</div>
              <div className='listing-contacts-meta'>
                {related_model.cell_number && <div><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Phone" /></svg>{valueFormat('tel', related_model.cell_number)}</div>}
                {valueFormat('whatsapp_link_only', related_model, { modelname: field.modelname, model, name: 'cell_number' }, related_model, field.modelname)}
                <div><svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Envelope" /></svg>{valueFormat('mailto', related_model.email)}</div>
              </div>
              {field.name === 'owner' && model.owner_notes &&
                <div className='listing-contacts-meta'>
                  <div className='listing-contacts-label'>Notes</div>
                  <div className='meta-value'>{model.owner_notes}</div>
                </div>
              }
              {field.name === 'tenant' && model.tenant_notes &&
                <div className='listing-contacts-meta'>
                  <div className='listing-contacts-label'>Notes</div>
                  <div className='meta-value'>{model.tenant_notes}</div>
                </div>
              }
            </div>
          </div>
        }
      />
    )
  }

  render() {
    const { model, cache, config } = this.props
    if (cache) {
      return (
        <div className="contact-cards">
          {this.contacts.filter(f => model[f] !== null).map((f, fidx) => {
            const field = config.fields.find(fe => fe.name === f)
            if (!field) { return null }
            if (Array.isArray(model[f])) {
              return model[f].map((v, vid) => this.renderModel(field, v, vid))
            }
            return this.renderModel(field, model[f], fidx)
          })}
        </div>
      )
    } return null
  }
}

ListingContacts.propTypes = {
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  fetchOne: PropTypes.func
}

export default ListingContacts
