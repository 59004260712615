import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '../ui/Button'
import { parseURL } from '../../utils'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const ContactOffers = props => {
  const { user, modelid, actions, configs, cache } = props

  const abortController = useRef(new AbortController())

  return (
    <Card
      bodyclass="leads no-top-padding"
      background
      header={
        <h3>Offers</h3>
      }
      body={
        <SimpleTable
          paginated
          currency={cache.settings[user.agent.site.id].default_currency}
          config={configs.offers}
          action={({ params, resolve, reject }) => {
            new Promise((res, rej) => actions.fetchMany({
              noloader: true,
              values: {
                modelname: 'offers',
                select: true,
                signal: abortController.current.signal,
                params
              },
              resolve: res,
              reject: rej
            })).then(r => {
              resolve(r)
            }).catch(e => {
              reject(e)
            })
          }}
          params={{
            lead__contact: modelid,
            order_by: '-created',
            meta_fields: [ 'lead', 'offer', 'residential', 'commercial', 'holiday', 'project' ]
          }}
          header={[
            {
              label: 'Date & Time',
              name: 'created',
              orderable: true,
              format: 'datetime'
            },
            {
              label: 'Status',
              name: 'meta.offer.status',
              orderable: true
            },
            {
              label: 'Offer Amount',
              name: 'meta.offer.offer_amount',
              orderable: true,
              format: 'currency'
            },
            {
              name: 'listing',
              label: 'Listing',
              format: 'listing_popup',
              permissions: [
                'listings_residential_for_sale_view',
                'listings_residential_for_sale_view_own',
                'listings_commercial_for_sale_view',
                'listings_commercial_for_sale_view_own',
                'listings_residential_to_let_view',
                'listings_residential_to_let_view_own',
                'listings_commercial_to_let_view',
                'listings_commercial_to_let_view_own',
                'listings_projects_view',
                'listings_projects_view_own'
              ],
              group: 'Lead Details',
              cols: 'lg-6',
              edit: null
            },
            {
              name: [
                'listing.unit_number',
                'listing.complex_name',
                ',',
                'listing.building_name',
                ',',
                'listing.street_number',
                'listing.street_name'
              ],
              tooltip: true,
              orderable: false,
              label: 'Address',
              container: 'meta'
            },
            {
              label: 'Expiry Date',
              name: 'meta.offer.offer_expiry_date',
              orderable: true,
              format: 'date'
            }
          ]}
          user={user}
          rowActions={(row, data) => (
            <>
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={parseURL(`${getIn(data, 'meta.listing.link') ? `${getIn(data, 'meta.listing.link')}/..` : `/secure/${data.site}`}/leads/${data.id}`)} title="View Lead" type="button" />
            </>
          )}
        />
      }
    />
  )
}

ContactOffers.propTypes = {
  configs: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  modelid: PropTypes.number,
  archiveModel: PropTypes.func
}

export default ContactOffers
