/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { LISTINGPOPUPFIELDS, SETTINGS } from '../../../selectors'
import { generateAddress, valueFormat } from '../../../utils'
import MetaDetail from '../MetaDetail'
import TooltipContext from './TooltipContext'


const ListingPopup = ({ children, listing, col, fields, currency, site_id }) => {
  col.currency = currency
  const listing_image = getIn(listing.meta, [ 'listing_images', 0, 'file' ])
  let parkings = 0
  if (listing.model === 'commercial') {
    const { covered_parking_bays, open_parking_bays, basement_parking } = listing
    parkings = (
      parseFloat(covered_parking_bays || 0) +
      parseFloat(open_parking_bays || 0) +
      parseFloat(basement_parking || 0)
    ).toString()
  }
  let smallest_size
  let smallest_price
  let smallest_option
  if (listing.model === 'project') {
    smallest_size = Math.min(...listing.property_types.map(pt => parseFloat(pt.size_from)))
    smallest_price = Math.min(...listing.property_types.map(pt => parseFloat(pt.priced_from)))
    smallest_option = listing.property_types.find(pt => parseFloat(pt.size_from) === smallest_size)
  }
  const suburb = getIn(listing, 'meta.location.suburb')
  const url = `/secure/${site_id}/${listing.model === 'project' ? 'projects' : listing.model}/${listing.id}`
  return (
    <TooltipContext
      className="popup listing-popup"
      options={{ followCursor: false, arrow: 'bottom-left', shiftY: -5, shiftX: -80 }}
      title={null}
      content={
        <div className="popup-wrapper">
          {listing_image &&
            <div className="popup-image">
              <img alt={listing.web_ref} src={valueFormat('image_url', listing_image)} />
            </div>
          }
          {listing.model === 'residential' &&
          <div className="popup-data">
            <h5>{listing.property_type} {listing.listing_type} in {suburb ? suburb : ''}</h5>
            <span className="popup-price">{listing.poa ? 'POA' : valueFormat('currency', listing.price, col)}{listing.listing_type === 'To Let' ? ` ${listing.price_term === 'Per Year' ? 'pa' : 'pm'}` : ''}</span>
            <span className="popup-address">{generateAddress(listing, col)}</span>
            <div className="popup-divider" />
            <div className="popup-featured meta-group">
              {[ 'bedrooms', 'floor_size', 'bathrooms', 'land_size', 'garages' ].map((f, idx) => {
                const value = listing[f] || '-'
                const field = fields.find(cf => cf.name === f)
                if (!field || (f !== 'land_size' && listing.property_type === 'Vacant Land')) {
                  return null
                }
                if (field.format === 'measurement_type') {
                  field.measurement_type = listing[`${field.name}_measurement_type`]
                }
                return (
                  <MetaDetail
                    key={`fp-meta-${idx}`}
                    label={field.label}
                    value={value}
                    format={field.format}
                    className="col-lg-6"
                    classes={[ 'floor_size', 'land_size' ].includes(f) ? [ 'text-right' ] : null}
                    field={field}
                  />
                )
              })}
            </div>
          </div>
          }

          {listing.model === 'commercial' &&
          <div className="popup-data">
            <h5>{listing.property_type} {listing.listing_type} in {suburb ? suburb : ''}</h5>
            <span className="popup-price">{listing.poa ? 'POA' : valueFormat('currency', listing.price, col)}{listing.listing_type === 'To Let' ? ` ${listing.price_term === 'Per Year' ? 'pa' : 'pm'}` : ''}</span>
            <span className="popup-address">{generateAddress(listing, col)}</span>
            <div className="popup-divider" />
            <div className="popup-featured">
              {[ 'zoning', 'parkings', 'floor_size', 'land_size' ].map((f, idx) => {
                let value = listing[f] || '-'
                let field = fields.find(cf => cf.name === f)
                if (!field && f !== 'parkings' || (![ 'land_size', 'zoning' ].includes(f) && listing.property_type === 'Vacant Land')) {
                  return null
                }
                if (f === 'parkings') {
                  value = parkings
                  field = {
                    edit: true,
                    name: 'parkings',
                    input: 'Float',
                    group: 'Property Features',
                    label: 'Parkings',
                    format: 'float'
                  }
                }
                if (field.format === 'measurement_type') {
                  field.measurement_type = listing[`${field.name}_measurement_type`]
                }
                return (
                  <MetaDetail
                    key={`fp-meta-${idx}`}
                    label={field.label}
                    value={value}
                    format={field.format}
                    classes={[ 'floor_size', 'land_size' ].includes(f) ? [ 'text-right' ] : null}
                    field={field}
                  />
                )
              })}
            </div>
          </div>
          }

          {listing.model === 'holiday' &&
          <div className="popup-data">
            <h5>{listing.property_type} to let in {suburb ? suburb : ''}</h5>
            <span className="popup-price">Priced from { valueFormat('currency', listing.out_of_season, col)}  - { valueFormat('currency', listing.peak_season, col)} per day</span><br />
            <span className="popup-address">{generateAddress(listing, col)}</span>
            <div className="popup-divider" />
            <div className="popup-featured">
              {[ 'bedrooms', 'bathrooms', 'sleeps', 'pool' ].map((f, idx) => {
                const value = listing[f] || '-'
                const field = fields.find(cf => cf.name === f)
                if (!field) {
                  return null
                }
                return (
                  <MetaDetail
                    key={`fp-meta-${idx}`}
                    label={field.label}
                    value={value}
                    format={field.format}
                    classes={[ 'floor_size', 'land_size' ].includes(f) ? [ 'text-right' ] : null}
                    field={field}
                  />
                )
              })}
            </div>
          </div>
          }

          {listing.model === 'project' &&
          <div className="popup-data">
            <h5>{listing.property_type} in {suburb ? suburb : ''}</h5>
            <span className="popup-price">{listing.poa ? 'POA' : valueFormat('currency', listing.price, col)}{listing.listing_type === 'To Let' ? ' pm' : ''}</span>
            <span className="popup-address">{generateAddress(listing, col)}</span>
            <div className="popup-divider" />
            <span className="popup-property-types">
              <MetaDetail
                label="Property Types"
                value={listing.property_types && listing.property_types.map(pt => pt.property_type).join(', ')}
              />
            </span>
            <div className="popup-featured">
              <MetaDetail
                label="Size From"
                value={smallest_size}
                format="measurement_type"
                field={{ measurement_type: smallest_option ? smallest_option.measurement_type : 'Square Meters' }}
              />
              <MetaDetail
                label="Priced From"
                value={smallest_price}
                format="currency"
                field={{ currency: col.currency }}
              />
            </div>
          </div>
          }

        </div>
      }
    >
      <NavLink to={url} className="has-link">{children}</NavLink>
    </TooltipContext>
  )
}

ListingPopup.propTypes = {
  listing: PropTypes.object,
  col: PropTypes.object,
  currency: PropTypes.string,
  fields: PropTypes.array,
  site_id: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}


const mapStateToProps = (state, ownProps) => {
  const settings = SETTINGS(state)
  const fields = LISTINGPOPUPFIELDS(state, ownProps.listing.model)
  const currency = settings.get('default_currency')
  const id = settings.get('id')
  return ({
    fields: fields,
    currency,
    site_id: id
  })
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ListingPopup))
