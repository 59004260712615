/* eslint-disable no-undefined */
import * as yup from 'yup'

import { decimalPlaces } from '../utils'
import messages from './messages.json'


yup.addMethod(yup.mixed, 'float', function (min, max) { // Make sure we get 1-digit floats, or NaN
  // eslint-disable-next-line
  return this.transform(function (value) {
    return (value !== undefined && String(value).trim() !== '') ? Number(value).toFixed(1) : NaN
  }).test(function (value) {
    if (min !== undefined && Number(value) < min) {
      return new yup.ValidationError(`Enter a valid number greater than ${min}`, value, this.path)
    }
    if (max !== undefined && Number(value) > max) {
      return new yup.ValidationError(`Enter a valid number less than ${max}`, value, this.path)
    }
    return true
  })
})

yup.addMethod(yup.mixed, 'step', function (step = 0.1) { // Make sure value is a multiple of 'step' - can be any number
  return this.test(function (value) {
    if (value && isNaN(value)) { return new yup.ValidationError(messages.number, value, this.path) }
    const places = decimalPlaces(step)
    const multiplier = Math.pow(10, places)
    const inv = 1.0 / step
    if (value && ((value * multiplier) % (step * multiplier) !== 0)) {
      const min = (Math.floor(value * inv) / inv).toFixed(1)
      const max = (Math.ceil(value * inv) / inv).toFixed(1)
      return new yup.ValidationError(`Enter a valid number. The two nearest numbers are ${min} and ${max}`, value, this.path)
    }
    return true
  })
})

const isValidStep = yup.mixed().float(0, 999.5).step(0.5)

export const commercial_listing = yup.object({
  status: yup.string().when([ 'listing_type' ], {
    is: listing_type => listing_type === 'To Let',
    then: yup.string().oneOf([
      'Active',
      'Rented',
      'Archived',
      'Pending',
      'Valuation'
    ], 'Cannot use status type Sold, with listing type To Let').nullable(),
    otherwise: yup.string().nullable()
  }).required(messages.required),
  branch: yup.number().strict().typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  team: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  agent_2: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_3: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_4: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  lightstone_id: yup.number(messages.number).integer().positive(messages.positive).typeError('Please enter a valid lightstone ID').nullable(),
  property_type: yup.string().trim().oneOf([
    'Airport Hanger',
    'Apartment Block',
    'Bar/Pub',
    'Bed & Breakfast',
    'Building',
    'Business Centre',
    'Business',
    'Co-working Space',
    'Factory',
    'Farm',
    'Full Floor',
    'Guesthouse',
    'Half Floor',
    'Hotel',
    'Industrial Yard',
    'Investment',
    'Lodge',
    'Medical Suite',
    'Mini Factory',
    'Office',
    'Place of Worship',
    'Restaurant',
    'Retail',
    'Service Station',
    'Serviced Office',
    'Showroom',
    'Small Holding',
    'Storage Unit',
    'Training Facility',
    'Vacant Land',
    'Warehouse',
    'Workshop'
  ], messages.invalid).required(messages.required).nullable(),
  location: yup.number().typeError(messages.invalid).positive(messages.positive).integer().required(messages.required),
  street_number: yup.mixed().nullable().required(messages.required), // Must be mixed for Lightstone lookup otherwise yupError.inner is not defined
  street_name: yup.string().nullable().required(messages.required),
  floor_number: yup.number(messages.number).integer().positive(messages.positive).nullable(),
  public_tender: yup.boolean(false).nullable(),
  listing_type: yup.string().typeError(messages.invalid).required(messages.required).nullable(),
  no_transfer_cost: yup.boolean(false).nullable(),
  distressed: yup.boolean(false).nullable(),
  bank_repossessed: yup.boolean(false).nullable(),
  bank_name: yup.string().trim().when(
    [ 'bank_repossessed', 'distressed' ], {
      is: (bank_repossessed, distressed) => bank_repossessed || distressed,
      then: yup.string().oneOf([
        'Absa Bank',
        'Nedbank',
        'Standard Bank',
        'FNB',
        'SA Home Loans'
      ], messages.invalid).required(messages.required),
      otherwise: yup.string().nullable()
    }
  ),
  land_size_measurement_type: yup.string().trim().transform(cv => (cv === 'Hectares (Ha)' ? 'Hectares' : cv)).oneOf([
    'Square Metres',
    'Hectares',
    'Acres',
    'Square Feet',
    null
  ], messages.invalid).nullable(),
  land_size: yup.number().moreThan(-1).typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type === 'Vacant Land' ? schema.required(messages.required) : schema.transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
  )),
  floor_size_measurement_type: yup.string().trim().oneOf([
    'Square Metres',
    'Square Feet',
    null
  ], messages.invalid).nullable(),
  floor_size: yup.number().moreThan(-1).typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type !== 'Vacant Land' ? schema.required(messages.required) : schema.transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
  )),
  building_height: yup.number().typeError(messages.invalid).integer().positive(messages.positive).nullable(),
  zoning: yup.string().trim().typeError(messages.invalid).oneOf([
    'Industrial',
    'Commercial',
    'Retail',
    'Agricultural',
    'Mixed Use',
    null
  ]).required(messages.required).nullable(),
  build_year: yup.number().integer().positive(messages.positive).nullable(),
  project: yup.number().positive().typeError(messages.invalid).integer().nullable(),
  description: yup.string().min(1).required(messages.required),
  office_space: yup.number().moreThan(-1).integer().typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  office_space_price_per_sqm: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  office_space_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  retail: yup.number().moreThan(-1).integer().typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  retail_price_per_sqm: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  retail_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  factory_warehouse: yup.number().moreThan(-1).integer().typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  factory_warehouse_price_per_sqm: yup.number().moreThan(-1).typeError(messages.invalid).
    transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  factory_warehouse_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  yard_size: yup.number().moreThan(-1).integer().typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  yard_price_per_sqm: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  yard_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  // this is the 'Amount' label if 'For Sale' listing type
  // this is the 'Amount per m²' label if 'To Let' listing type
  price: yup.number().when('status', (status, schema) => (
    status !== 'Valuation' ? yup.number().moreThan(-1, messages.invalid).typeError(messages.invalid).required(messages.required) : schema.nullable())
  ),
  sold_price: yup.number().when('status', (status, schema) => (
    [ 'Sold', 'Rented' ].includes(status) ? yup.number().moreThan(-1, messages.invalid).typeError(messages.invalid).required(messages.required) : schema.nullable())
  ),
  gross_monthly_rental: yup.number().when('listing_type', listing_type => (listing_type === 'To Let' ?
    (
      yup.number().moreThan(0, messages.invalid).typeError(messages.invalid)
        .transform(cv => (isNaN(cv) ? undefined : cv)).required(messages.required)
    ) : (
      yup.number().transform(() => null)
    ).nullable())
  ).nullable(),
  municipal_value: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  estimated_monthly_income: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  monthly_rates: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  monthly_levy: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  sectional_title_levy: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  special_levy: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  lease_type: yup.string().when('listing_type', (listing_type, schema) => (
    listing_type === 'To Let' ? schema.trim().oneOf([
      'Percentage',
      'Net',
      'Double Net',
      'Triple Net',
      'Fully Serviced Lease Gross',
      null
    ]).nullable() : schema.nullable()
  )),
  pricing_lease_type: yup.string().when('listing_type', (listing_type, schema) => (
    listing_type === 'To Let' ? yup.string().trim().oneOf([
      'Renewal',
      'New',
      null
    ]).nullable() : schema.nullable()
  )),
  lease_period: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().trim().required(messages.required).oneOf([
      '1 month',
      '2 months',
      '3 months',
      '6 months',
      '12 months',
      '24 months',
      '36 months',
      '48 months',
      '60 months',
      '10 year plus',
      '15 year plus',
      '25 year plus',
      '35 year plus',
      'Negotiable',
      null
    ]).nullable(),
    otherwise: yup.string().nullable()
  }),
  deposit: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  available_from: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().trim().required(messages.required).oneOf([
      'Immediately',
      'Negotiable',
      'Specific Date',
      null
    ]).nullable(),
    otherwise: yup.string().nullable()
  }),
  multi_tenanted: yup.boolean(false).nullable(),
  escalation: yup.string().trim().oneOf([
    'Negotiable',
    'Specify %',
    null
  ]).nullable(),
  annual_escalation: yup.number().when('escalation', (escalation, schema) => (
    escalation === 'Specify %' ? (
      yup.number().positive(messages.positive).required(messages.required)
    ) : schema.nullable()
  )),
  open_parking_bays: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  open_parkings_cost_per_bay: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  open_parkings_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  covered_parking_bays: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  covered_parkings_cost_per_bay: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  covered_parkings_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  basement_parking: yup.number().moreThan(-1).integer().typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  basement_parking_cost_per_bay: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  basement_parking_total_cost: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  parking_ratio_bays: yup.number().typeError(messages.invalid).positive()
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  parking_ratio_per_meter: yup.number().typeError(messages.invalid).positive()
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  floor_loading_capacity: yup.number().typeError(messages.invalid).moreThan(-1)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  power_3_phase: yup.boolean(false).nullable(),
  power_amps: yup.number().typeError(messages.invalid).integer().moreThan(-1)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  kitchen: yup.number().typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type !== 'Vacant Land' ? isValidStep.nullable() : schema.transform(() => undefined).nullable()
  )),
  bathrooms: yup.number().typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type !== 'Vacant Land' ? isValidStep.nullable() : schema.transform(() => undefined).nullable()
  )),
  boardrooms: yup.number().typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type !== 'Vacant Land' ? isValidStep.nullable() : schema.transform(() => undefined).nullable()
  )),
  security: yup.boolean(false).nullable(),
  air_conditioning: yup.boolean(false).nullable(),
  mandate_type: yup.string().when('listing_type', (listing_type, schema) => (
    listing_type === 'For Sale' ? schema.trim().typeError(messages.invalid).oneOf([
      'Sole',
      'Open',
      'Company Listing',
      'Shared',
      'Expired',
      'MLS',
      'Referral',
      'Auction',
      'PLN',
      'Exclusive',
      null
    ]).nullable() : schema.nullable()
  )),
  mandate_start_date: yup.string().when('mandate_type', {
    is: mandate_type => [ 'Sole', 'Exclusive' ].includes(mandate_type),
    then: yup.string().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable()
  }),
  mandate_end_date: yup.string().when([ 'mandate_type' ], {
    is: mandate_type => [ 'Sole', 'Exclusive' ].includes(mandate_type),
    then: yup.string().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable() }),
  tenant_lease_ends: yup.string().when([ 'mandate_type', 'tenant' ], {
    is: (mandate_type, tenant) => mandate_type === 'Sole' && tenant,
    then: yup.string().trim().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable() }),
  on_market_since: yup.string().when([ 'mandate_type' ], {
    is: mandate_type => mandate_type === 'Sole',
    then: yup.string().trim().required(messages.required),
    otherwise: yup.string().nullable() }),
  owners: yup.array().of(yup.number().typeError(messages.invalid)
    .positive(messages.positive).integer().nullable()).nullable(),
  tenant: yup.number().typeError(messages.invalid).positive(messages.positive).integer().nullable(),
  tenant_rental: yup.number().typeError(messages.invalid).integer().positive(messages.positive)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  section_number: yup.number().integer().positive(messages.positive).nullable().typeError(messages.number),
  section_plan_number: yup.number().integer().positive(messages.positive).nullable().typeError(messages.number),
  bond_bank_balance: yup.number().positive(messages.positive).integer().nullable(),
  open_hour_date: yup.string().nullable(),
  on_show: yup.boolean().nullable(),
  on_show_directions: yup.string().when('on_show', (on_show, schema) => (
    on_show ? schema.nullable().required(messages.required) : schema.nullable()
  )),
  on_show_events: yup.array().when('on_show', {
    is: true,
    then: yup.array().of(yup.object().shape({
      on_show_date: yup.string().nullable(),
      on_show_start_time: yup.string().required(messages.required).nullable(),
      on_show_end_time: yup.string().required(messages.required).nullable()
    })),
    otherwise: yup.array().nullable()
  }),
  enable_offers: yup.boolean().nullable(),
  offer_status: yup.string().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.string().oneOf([
        'Accepting Offers',
        'Offer Pending',
        'Offer Accepted'
      ], messages.invalid).required(messages.required).nullable()
    ) : schema.nullable()
  )),
  offers_from: yup.number().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required)
        .transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
    ) : schema.nullable()
  )),
  offers_increment: yup.number().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.number().integer().positive().required(messages.required)
        .transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
    ) : schema.nullable()
  )),
  auction: yup.boolean().nullable(),
  auction_lots: yup.number().typeError(messages.invalid).when('auction', (auction, schema) => (
    auction ? (
      yup.number().integer(messages.integer).positive(messages.positive).required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_date: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_start_time: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_end_time: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_venue: yup.string().when('auction', (auction, schema) => (
    auction ? schema.nullable().required(messages.required) : schema.nullable()
  )),
  external_link_url: yup.string().url(messages.url).nullable(),
  virtual_tour: yup.string().url(messages.url).nullable(),
  portals: yup.object().nullable(),
  property_size_total_cost: yup.number().moreThan(-1).typeError(messages.invalid).integer().nullable(),
  payment_frequency: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().oneOf([
      null,
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ], messages.invalid).nullable(),
    otherwise: yup.string().nullable()
  }),
  body_corp_name: yup.string().max(50).nullable(),
  body_corp_chairman: yup.string().max(50).nullable(),
  body_corp_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  supervisor_name: yup.string().max(50).nullable(),
  supervisor_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  managing_agent: yup.string().max(50).nullable(),
  managing_agent_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  open_hour_agent: yup.string().max(50).nullable(),
  open_hour_agent_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  roller_shutter_door: yup.number().typeError(messages.integer).integer().moreThan(-1).nullable(),
  dock_levellers: yup.number().typeError(messages.integer).integer().moreThan(-1).nullable(),
  height_of_dock_levellers: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  farm_types: yup.array(yup.string().oneOf([
    'Arable',
    'Cattle',
    'Chicken',
    'Dairy',
    'Deer',
    'Eco',
    'Eragrostis',
    'Finishing',
    'Forestry',
    'Fruit',
    'Game',
    'Goat',
    'Grazing',
    'Horses',
    'Horticulture',
    'Irrigation',
    'Maize',
    'Ranch',
    'Agricultural Land',
    'Sheep',
    'Sheep & Cattle',
    'Specialist Livestock',
    'Stud',
    'Tourism',
    'Viticulture'
  ], messages.invalid)).nullable()

}
// The below has been removed as it completely screws up the DeedsLookupSidebar when a selection is made
// ).test(
//   'unit_number', // Cyclicly dependant fields can only be validated after all other fields.
//   schema => {
//     const { unit_number, complex_name } = schema
//     if (!unit_number && !complex_name) { return true } // If neither value is present, pass
//     if (unit_number && unit_number.trim() && complex_name &&
//       complex_name.trim()) { return true } // If both values are present, pass

//     if (unit_number && unit_number.trim() &&
//       (!complex_name || !(complex_name && complex_name.trim()))) { // If unit_number is present and complex_name is not, throw an error
//       return new yup.ValidationError(messages.required, complex_name, 'complex_name')
//     }
//     return new yup.ValidationError(messages.required, unit_number, 'unit_number') // If complex_name is present and unit_number is not, throw an error
//   }
).test({
  message: 'Available from date is required',
  test: schema => {
    const { available_from, listing_type } = schema
    if (listing_type === 'To Let' && !available_from) {
      return new yup.ValidationError(messages.required, available_from, 'available_from')
    }
    return true
  }
}).test({
  message: 'Occupation date is required',
  test: schema => {
    const { available_from, listing_type, occupation_date } = schema
    if (listing_type === 'To Let' && available_from === 'Specific Date' && !occupation_date) {
      return new yup.ValidationError(messages.required, occupation_date, 'occupation_date')
    }
    return true
  }
}).test(
  'on_show', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      on_show,
      on_show_directions,
      on_show_events
    } = schema
    if (on_show) {
      if (!on_show_directions) {
        return new yup.ValidationError(messages.required, on_show_directions, 'on_show_directions')
      }
      if (!on_show_events || !on_show_events.length) {
        return new yup.ValidationError(messages.required, on_show_events, 'on_show_events')
      }
      on_show_events.forEach((e, eidx) => {
        const {
          on_show_date,
          on_show_start_time,
          on_show_end_time
        } = schema.on_show_events[eidx]
        if (!on_show_date) {
          return new yup.ValidationError(messages.required, on_show_date, 'on_show_date')
        }
        if (!on_show_start_time) {
          return new yup.ValidationError(messages.required, on_show_start_time, 'on_show_start_time')
        }
        if (!on_show_end_time) {
          return new yup.ValidationError(messages.required, on_show_end_time, 'on_show_end_time')
        }
        return null
      })
    }
    return true
  }
).test(
  'external_link', // Cyclicly dependant fields can only be validated after all other fields.
  schema => {
    const { external_link_name, external_link_url } = schema
    if (!external_link_name && !external_link_url) { return true }
    if (external_link_name && external_link_name.trim() &&
      external_link_url && external_link_url.trim()) { return true }

    if (external_link_url && external_link_url.trim() &&
      (!external_link_name || !(external_link_name && external_link_name.trim()))) {
      return new yup.ValidationError(messages.required, external_link_name, 'external_link_name')
    }
    return new yup.ValidationError(messages.required, external_link_url, 'external_link_url')
  }
).test(
  'display_on_website',
  schema => {
    const {
      listing_images,
      display_on_website
    } = schema
    if (display_on_website) {
      if (!listing_images || (listing_images && !listing_images.length)) {
        return new yup.ValidationError('Listings require at least one image to display on websites.', display_on_website, 'display_on_website')
      }
    }
    return true
  }
)

export const commercial = () => commercial_listing
