import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import { isConditional, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import Syndication from '../common/Syndication'


class Article extends React.Component {
  constructor(props) {
    super(props)
    this.isConditional = isConditional.bind(this)
  }

  render() {
    const {
      model,
      config,
      cache,
      actions,
      user,
      routeConfig
    } = this.props

    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={this.props.match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="media" label="Media">
          <>
            <MediaGallery
              media={[
                {
                  items: model.meta.images,
                  mediatype: 'image',
                  fieldname: 'images',
                  plural: 'images',
                  label: 'Images'
                }
              ]}
              actions={{
                downloadImages: actions.downloadImages,
                isConditional: this.isConditional
              }}
              config={config}
              user={user}
              model={model}
              modelname={config.modelname}
            />
            <Card
              background
              header={
                <h3>Multimedia</h3>
              }
              body={() => {
                const field = config.fields.find(f => f.name === 'video_id')
                let can_edit = true
                const mockform = {
                  touched: { [field.name]: true },
                  values: { [field.name]: model ? model[field.name] : null }
                }
                if (field.show && Array.isArray(field.show)) {
                  field.show.map(s => s.map(condition => (
                    mockform.values[condition.field] = model[condition.field])
                  ))
                  if (!this.isConditional(field, 'show', false, mockform)) { can_edit = false }
                }
                return (model.video_id || model.virtual_tour || model.eyespy360 || model.matterport_id) ? (
                  <>
                    {model.video_id && model.video_streaming_platform === 'YouTube' &&
                      <React.Fragment>
                        <h4 className="section-underline">Youtube</h4>
                        <div className='player-container'>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              className='react-player'
                              url={`https://www.youtube.com/watch?v=${model.video_id}`}
                              controls
                              width='100%'
                              height='100%'
                              config={{
                                youtube: {
                                  playerVars: {
                                    showinfo: 0,
                                    modestbranding: 1,
                                    rel: 0
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {model.video_id && model.video_streaming_platform === 'Vimeo' &&
                      <React.Fragment>
                        <h4 className="section-underline">Vimeo</h4>
                        <div className='player-container'>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              className='react-player'
                              url={`https://www.vimeo.com/${model.video_id}`}
                              controls
                              width='100%'
                              height='100%'
                              config={{
                                vimeo: {
                                  playerVars: {
                                    responsive: true
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {model.virtual_tour &&
                      <React.Fragment>
                        <h4 className="section-underline">Virtual Tour</h4>
                        <div className='player-container'>
                          {valueFormat('link', model.virtual_tour, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                    {model.eyespy360 &&
                      <React.Fragment>
                        <h4 className="section-underline">EyeSpy360</h4>
                        <div className='player-container'>
                          {valueFormat('link', model.eyespy360, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                    {model.matterport_id &&
                      <React.Fragment>
                        <h4 className="section-underline">Matterport</h4>
                        <div className='player-container'>
                          {valueFormat('link', `https://my.matterport.com/show/?m=${model.matterport_id}`, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                  </>
                ) : (
                  <div className="mediatype">
                    <div>
                      There are no videos or virtual tours for this {config.singular}.&nbsp;
                      {can_edit &&
                        <NavLink
                          className="has-link"
                          to={`/secure/${model.site}/${config.modelname}/${model.id}/edit#field-video_id`}
                          scroll={el => setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'center' }), 3000) }
                        >Add some now.
                        </NavLink>
                      }
                    </div>
                  </div>
                )
              }}
            />
          </>
        </Tab>
        <Tab tab="syndication" label="Syndication">
          <Syndication
            model={model}
            servicename={config.servicename}
            modelname={config.modelname}
            portals={cache.portals}
            settings={cache.settings[user.agent.site.id]}
            permissions={user.permissions}
            agentid={user.agent.id}
            user={user}
            actions={actions}
          />
        </Tab>
        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Article.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  routeConfig: PropTypes.object
}

export default Article
