import React from 'react'
import Card from '../common/Card'


const NewsletterWidget = () => (
  <Card
    id="newsletter-widget"
    classes="primary-bg grid-col-1"
    bodyclass="stats-card no-top-padding"
    background
    header={
      <strong>Active Listings</strong>
    }
    body={
      <>
        <h1>1</h1>
      </>
    }
  />
)

export default NewsletterWidget
