import * as yup from 'yup'
import messages from './messages.json'


export const projects = () => yup.object({
  status: yup.string().oneOf([ 'Active', 'Archived', null ]).required(messages.required).nullable(),
  branch: yup.number().strict().typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  name: yup.string().max(100).required(messages.required),
  description: yup.string().required(messages.required),
  listing_type: yup.string().typeError(messages.invalid).oneOf([
    'Commercial Development',
    'Commercial Estate',
    'Residential Development',
    'Residential Estate',
    null
  ], messages.invalid).required(messages.required).nullable(),
  progress: yup.string().typeError(messages.invalid).oneOf([
    'Completed',
    'In Progress',
    'Off Plan',
    null
  ], messages.invalid).required(messages.required).nullable(),
  property_type: yup.string().typeError(messages.invalid).oneOf([
    'Affordable Housing Development',
    'Apartment Block',
    'Building',
    'Business Park',
    'Coastal Estate',
    'Eco Estate',
    'Equestrian Estate',
    'Game Farm Estate',
    'Golf Estate',
    'Hotel Investment',
    'Industrial Development',
    'Industrial Park',
    'Leaseback',
    'Medical Centre',
    'Mixed Use Development',
    'Nature Estate',
    'Office Development',
    'Office Park',
    'Residential Estate',
    'Retail Development',
    'Retail Park',
    'Retirement Estate',
    'Shopping Centre',
    'Wine Estate',
    null
  ], messages.invalid).required(messages.required).nullable(),
  agent: yup.number().typeError(messages.invalid).integer().positive().required(messages.required).nullable(),
  agent_2: yup.number().typeError(messages.invalid).integer().positive().nullable(),
  agent_3: yup.number().typeError(messages.invalid).integer().positive().nullable(),
  agent_4: yup.number().typeError(messages.invalid).integer().positive().nullable(),
  location: yup.number().typeError(messages.invalid).integer().positive().required(messages.required).nullable(),
  marketing_heading: yup.string().max(100).nullable(),
  build_year: yup.number().typeError(messages.invalid).integer().positive().nullable(),
  complex_name: yup.string().max(60).nullable(),
  unit_number: yup.string().max(10).nullable(),
  street_number: yup.string().max(10).nullable().required(messages.required),
  street_name: yup.string().max(100).nullable().required(messages.required),
  on_show: yup.boolean().nullable(),
  on_show_directions: yup.string().when('on_show', (on_show, schema) => (
    on_show ? schema.required(messages.required) : schema.nullable()
  )),
  on_show_events: yup.array().when('on_show', {
    is: true,
    then: yup.array().of(yup.object().shape({
      on_show_date: yup.string().nullable(),
      on_show_start_time: yup.string().required(messages.required).nullable(),
      on_show_end_time: yup.string().required(messages.required).nullable()
    })),
    otherwise: yup.array().nullable()
  }),
  property_types: yup.array().of(yup.object().shape({
    poa: yup.bool().transform(cv => (typeof cv !== 'boolean' ? false : cv)),
    property_type: yup.string().typeError(messages.invalid).oneOf([
      'Apartment',
      'Bar/Pub',
      'Building',
      'Business',
      'Chalet',
      'Compound',
      'Contract',
      'Detached',
      'Duplex',
      'Equestrian Property',
      'Factory',
      'Flat',
      'Full Floor',
      'Half Floor',
      'Hotel Room',
      'House',
      'Investment',
      'Labour Camp',
      'Leaseback',
      'Medical Centre',
      'Mini Factory',
      'Office',
      'Package Home',
      'Restaurant',
      'Retail',
      'Semi Detached',
      'Shopping Centre',
      'Showroom',
      'Simplex',
      'Townhouse',
      'Units',
      'Vacant Land',
      'Villa',
      'Warehouse',
      null
    ], messages.invalid).required(messages.required).nullable(),
    plan_name: yup.string().required(messages.required).nullable(),
    priced_from: yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required).nullable(),
    priced_to: yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required).nullable(),
    number: yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required).nullable(),
    size_measurement_type: yup.string().trim().typeError(messages.invalid).oneOf([
      'Square Feet',
      'Square Metres',
      null
    ], messages.invalid).required(messages.required).nullable(),
    size_from: yup.number().typeError(messages.invalid).integer().positive().required(messages.required).nullable(),
    size_to: yup.number().typeError(messages.invalid).integer().positive().required(messages.required).nullable(),
    listing_type: yup.string().trim().typeError(messages.invalid).oneOf([
      'For Sale',
      'To Let',
      null
    ], messages.invalid).required(messages.required).nullable(),
    bedrooms: yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required).nullable(),
    bathrooms: yup.number().moreThan(-1).typeError(messages.invalid).required(messages.required).nullable()
  }).required(messages.required)),
  team: yup.number().integer().positive().nullable()
}).test(
  'on_show', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      on_show,
      on_show_directions,
      on_show_events
    } = schema
    if (on_show) {
      if (!on_show_directions) {
        return new yup.ValidationError(messages.required, on_show_directions, 'on_show_directions')
      }
      if (!on_show_events || !on_show_events.length) {
        return new yup.ValidationError(messages.required, on_show_events, 'on_show_events')
      }
      on_show_events.forEach((e, eidx) => {
        const {
          on_show_date,
          on_show_start_time,
          on_show_end_time
        } = schema.on_show_events[eidx]
        if (!on_show_date) {
          return new yup.ValidationError(messages.required, on_show_date, 'on_show_date')
        }
        if (!on_show_start_time) {
          return new yup.ValidationError(messages.required, on_show_start_time, 'on_show_start_time')
        }
        if (!on_show_end_time) {
          return new yup.ValidationError(messages.required, on_show_end_time, 'on_show_end_time')
        }
        return null
      })
    }
    return true
  }
).test(
  'external_link', // Cyclicly dependant fields can only be validated after all other fields.
  schema => {
    const { external_link_name, external_link_url } = schema
    if (!external_link_name && !external_link_url) { return true }
    if (external_link_name && external_link_name.trim() &&
      external_link_url && external_link_url.trim()) { return true }

    if (external_link_name && external_link_name.trim() &&
      (!external_link_name || !(external_link_name && external_link_name.trim()))) {
      return new yup.ValidationError(messages.required, external_link_name, 'external_link_name')
    }
    return new yup.ValidationError(messages.required, external_link_url, 'external_link_url')
  }
).test(
  'display_on_website',
  schema => {
    const {
      listing_images,
      display_on_website
    } = schema
    if (display_on_website) {
      if (!listing_images || (listing_images && !listing_images.length)) {
        return new yup.ValidationError('Listings require at least one image to display on websites.', display_on_website, 'display_on_website')
      }
    }
    return true
  }
)
