import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import log from '../../logging'


const Loader = ({ inline, className, onError, throwViewError, ...props }) => {
  const [ isError, setError ] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!inline) { // Don't error on inline loaders, handle with separate error boundaries
        try {
          throw new Error('Timeout')
        } catch (err) {
          if (onError) { onError({ error: err }) }
          if (throwViewError) {
            throwViewError()
          } else {
            setError(() => { throw err }) // Throw ErrorBoundary
          }
        }
      } else {
        if (onError) { onError({ error: 'An element is taking too long to load' }) }
        log.error('An element is taking too long to load')
      }
    }, 60000)
    return () => clearTimeout(timer)
  }, [ isError ])


  let svg = '/images/loader.svg'
  let png = '/images/loader.png'
  if (className && className.includes('white')) {
    svg = '/images/loader-white.svg'
    png = '/images/loader-white.png'
  }
  if (inline) {
    return (<div className={`loader inline${className ? ` ${className}` : ''}`}><svg><image xlinkHref={svg} src={png} /></svg></div>)
  }
  return (<div className={`isloading block${className ? ` ${className}` : ''}${props.noblock ? ' solid' : ''}`}><div className="loader"><svg><image xlinkHref={svg} src={png} /></svg></div></div>)
}

Loader.propTypes = {
  throwViewError: PropTypes.func,
  noblock: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  onError: PropTypes.func
}

export default Loader


