import * as yup from 'yup'
import messages from './messages.json'


export const cms = () => yup.object({
  menu_title: yup.string().max(50).required(messages.required),
  page_title: yup.string().max(100).required(messages.required),
  content: yup.string().required(messages.required),
  caption: yup.string().max(100).nullable()
})
