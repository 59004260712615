import PropTypes from 'prop-types'
import React from 'react'

import FieldGroup from './common/forms/FieldGroup'


const ReferralAction = props => {
  const { model, groupname, config, form, gidx } = props
  if (model.status === 'Pending') {
    return (
      <div className='referral-response-outcome'>
        <FieldGroup
          key='fs-0'
          groupname={groupname}
          gidx={gidx}
          fields={config.fields.filter(field => field.group === groupname)}
          form={form}
          modelname={config.modelname}
          modelid={model.id}
          columns
          collapsed={false}
          render={({ renderFieldGroup, hidden }) => {
            if (hidden) { return null }
            return (
              <fieldset className="editgroup">
                {renderFieldGroup(groupname)}
              </fieldset>
            )
          }}
        />
      </div>
    )
  }
  if (groupname === 'Referral Outcome' && model.status === 'Accepted') {
    if (!model.referral_outcome) {
      return (
        <div className='referral-response-outcome'>
          <FieldGroup
            key='fs-0'
            groupname={groupname}
            gidx={gidx}
            fields={config.fields.filter(field => field.group === groupname)}
            form={form}
            modelname={config.modelname}
            modelid={model.id}
            columns
            collapsed={false}
            render={({ renderFieldGroup, hidden }) => {
              if (hidden) { return null }
              return (
                <fieldset className="editgroup">
                  {renderFieldGroup(groupname)}
                </fieldset>
              )
            }}
          />
        </div>
      )
    }
  }
  return null
}


ReferralAction.propTypes = {
  model: PropTypes.object,
  gidx: PropTypes.number,
  config: PropTypes.object,
  form: PropTypes.object,
  groupname: PropTypes.string.isRequired
}

export default ReferralAction
