import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { getRandomColor, isConditional, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import AgentCard from '../common/AgentCard'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import MetaDetail from '../common/MetaDetail'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import SocialMedia from '../ui/SocialMedia'
import AlertsSentWidget from '../widgets/AlertsSentWidget'
import LeadSourceWidget from '../widgets/LeadSourceWidget'
import LeadStatusWidget from '../widgets/LeadStatusWidget'
import ListingBreakdownWidget from '../widgets/ListingBreakdownWidget'
import ListingValueWidget from '../widgets/ListingValueWidget'
import StockWidget from '../widgets/StockWidget'
import TopAgentsWidget from '../widgets/TopAgentsWidget'
import ActiveListingsWidget from '../widgets/ActiveListingsWidget'


class Franchise extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      map: false,
      pie_chart: null,
      website_stats: 0,
      property_24_stats: 0
    }
    this.getSourcesStat = this.getSourcesStat.bind(this)
    this.isConditional = isConditional.bind(this)
    this.AbortController = new AbortController()
  }

  async getSourcesStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || Object.keys(data).legth === 0) {
      return null
    }
    const array_data = Object.keys(data)
      .map(k => ({ source: k, value: data[k] }))
      .sort((a, b) => (b.value - a.value))
    this[`${state_key}_fetching`] = true
    const total = array_data.reduce((prevValue, nextValue) => prevValue + nextValue.value, 0)
    const top_9 = array_data.slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = source.source
      const count = source.value
      if (count) {
        slices.push({
          name: `${source_key} (${Math.round(count / total * 100)}%)`,
          value: count,
          percentage: count / top_9_total,
          colour: getRandomColor(slices.length)
        })
      }
    })
    const other_totals = total - top_9_total
    if (other_totals) {
      slices.unshift({
        name: `Other (${Math.round(other_totals / total * 100)}%)`,
        value: other_totals,
        percentage: 0,
        percentage_100: 0,
        colour: getRandomColor(slices.length)
      })
    }
    this.setState({ pie_chart: slices })
    return slices
  }

  render() {
    const {
      model,
      config,
      cache,
      actions,
      user,
      match,
      routeConfig
    } = this.props

    const { statistics } = model.meta
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = getIn(cache, `settings.${selected_site}.meta.statistics`, {})
    const listingstats = [
      [ 'rfs_ac', 'RFS' ],
      [ 'rtl_ac', 'RTL' ],
      [ 'cfs_ac', 'CFS' ],
      [ 'ctl_ac', 'CTL' ],
      [ 'rd', 'RD' ],
      [ 'cd', 'CD' ],
      [ 're', 'RE' ],
      [ 'ce', 'CE' ],
      [ 'h_ac', 'HL' ]
    ]

    const listing_stats = []
    listingstats.forEach(stat => {
      const avg = sitestats.active_branches ? Math.round(sitestats[stat[0]] / sitestats.active_branches) : 0
      listing_stats.push({ name: stat[1], Franchise: statistics[stat[0]] || 0, 'Company Avg': avg })
    })
    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >

        <Tab tab="dashboard" label="Dashboard">
          <div className="grid-container">
            <div className="grid-rows grid-col-4 grid-container">
              <ActiveListingsWidget franchise={model} />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Leads</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_electronic_leads}</h1>
                    <span>
                      Company Avg: {sitestats.active_branches ? (
                        Math.round(sitestats.active_electronic_leads / sitestats.active_branches)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Subscribers</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_mailing_list}</h1>
                    <span>
                      Company Avg: {sitestats.active_branches ? (
                        Math.round(sitestats.active_mailing_list / sitestats.active_branches)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-1"
                bodyclass="stats-card-mini"
                background
                body={
                  <div className="flex-container justify-between">
                    <strong>Users</strong><h3>{statistics.active_agents}</h3>
                  </div>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-1"
                bodyclass="stats-card-mini"
                background
                body={
                  <div className="flex-container justify-between">
                    <strong>Profiles</strong><h3>{statistics.active_profiles}</h3>
                  </div>
                }
              />
            </div>
            <div className="grid-col-4 grid-container grid-1">
              <ListingValueWidget
                branches={model.branches}
              />
            </div>
            <div className="grid-col-4 grid-container grid-1">
              <ListingBreakdownWidget
                branches={model.branches}
                sitestats={sitestats}
                statistics={statistics}
              />
            </div>
            <div className="grid-col-4 grid-container grid-1">
              <TopAgentsWidget
                branches={model.branches}
              />
            </div>
            <div className="grid-col-4 grid-container grid-2">
              <LeadSourceWidget
                branches={model.branches}
              />
              <LeadStatusWidget
                branches={model.branches}
              />
            </div>
            <div className="grid-col-4 grid-container grid-2">
              <StockWidget
                branches={model.branches}
              />
              <AlertsSentWidget
                branches={model.branches}
              />
            </div>
            <div className="grid-rows grid-col-4 grid-container">
              <Card
                background
                classes="grid-col-4"
                header={
                  <h3>Franchisees</h3>
                }
                bodyclass="flex-container grid-4"
                body={() => {
                  if (!getIn(model, 'franchisees', []) || !getIn(model, 'franchisees', []).length) {
                    return null
                  }
                  return (
                    <>
                      {model.franchisees && model.franchisees.map((fid, fidx) => {
                        const agent = cache.agents[fid] || model.meta.franchisees[fidx]
                        return (
                          <AgentCard key={`franchisee-${fidx}`} title="Franchisee" agent={agent} />
                        )
                      })}
                    </>
                  )
                }}
              />
              <Card
                classes="grid-col-4"
                background
                header={
                  <h3>Contact Details</h3>
                }
                body={() => {
                  const { map_image } = model.meta
                  return (
                    <div className="input-group gap-20 space-evenly">
                      {map_image && <div className="branchmap">
                        {valueFormat('image', { file: map_image.file, width: 1024, height: 768 })}
                        <div className="marker-dot"></div>
                      </div>}
                      <div className="contact-details no-wrap">
                        {[ 'name', 'fax_number', 'email', 'location' ].map((f, fidx) => {
                          const field = config.fields.find(fe => fe.name === f)
                          let val = model[field.name]
                          if (field.modelname || field.metafield) {
                            if (field.optionlabel) {
                              val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                            }
                          }
                          return (
                            <MetaDetail
                              className={'dashboard-meta'}
                              key={`f${fidx}`}
                              label={field.label}
                              value={val}
                              format={field.format}
                              field={field}
                            />
                          )
                        }
                        )}
                        <div className="big-number">{valueFormat('tel', model.telephone_number)}</div>
                      </div>
                      <div className="address-details">
                        {model.physical_address && <div className="branch-address">
                          <div className="dashboard-meta">
                            <div className="heavy">Physical Address</div>
                            <span>{valueFormat('domstring', model.physical_address)}</span>
                          </div>
                        </div>}
                        {model.postal_address && <div className="branch-postal-address">
                          <div className="dashboard-meta">
                            <div className="heavy">Postal Address</div>
                            <span>{valueFormat('domstring', model.postal_address)}</span>
                          </div>
                        </div>}
                      </div>
                      <SocialMedia model={model} />
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </Tab>

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="media" label="Media">
          <MediaGallery
            media={[ {
              items: model.meta.documents,
              mediatype: 'documents',
              fieldname: 'documents',
              plural: 'documents',
              label: 'Documents'
            } ]}
            actions={{
              downloadImages: actions.downloadImages,
              isConditional: this.isConditional
            }}
            config={config}
            model={model}
            modelname={config.modelname}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Franchise.propTypes = {
  actions: PropTypes.object,
  selected: PropTypes.array,
  model: PropTypes.object,
  routeConfig: PropTypes.object,
  modelid: PropTypes.number,
  title: PropTypes.string,
  tab: PropTypes.string,
  config: PropTypes.object,
  cache: PropTypes.object,
  stats: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

export default Franchise
