/* eslint-disable react/prop-types */
import React from 'react'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $isElementNode, $isDecoratorNode } from 'lexical'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { TRANSFORMERS } from '@lexical/markdown'
import { formikUseField } from '../customFormikUseField'
import InlineImagePlugin from './lexical/plugins/InlineImagePlugin'
import ToolbarPlugin from './lexical/plugins/ToolbarPlugin'
import SnippetsPlugin from './lexical/plugins/SnippetsPlugin'
import ExampleTheme from './lexical/themes/ExampleTheme'
import { InlineImageNode } from './lexical/nodes/InlineImageNode'

import ListMaxIndentLevelPlugin from './lexical/plugins/ListMaxIndentLevelPlugin'
import CodeHighlightPlugin from './lexical/plugins/CodeHighlightPlugin'
import AutoLinkPlugin from './lexical/plugins/AutoLinkPlugin'
import HTMLTransformerPlugin from './lexical/plugins/HTMLTransformerPlugin'
import Label from './Label'
import { useSharedHistoryContext } from './lexical/context/SharedHistoryContext'
import { htmlSerializationConfig } from './lexical/config'


const ConnectedHTMLTransformerPlugin = formikUseField(HTMLTransformerPlugin)
const ConnectedSnippetsPlugin = formikUseField(SnippetsPlugin)


function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>
}

const nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  InlineImageNode
]

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  html: htmlSerializationConfig,
  // Handling of errors during update
  onError(error) {
    throw error
  },
  // Any custom nodes go here
  nodes
}

export default function Editor(props) {
  const { name, label, config } = props
  const { historyState } = useSharedHistoryContext()
  editorConfig.editorState = editor => {
    const parser = new DOMParser()
    const dom = parser.parseFromString(
      props._value,
      'text/html'
    )
    const defaultNodes = $generateNodesFromDOM(editor, dom)
    const root = $getRoot()
    root.clear()
    root.append(
      ...defaultNodes.filter(node => $isElementNode(node) || $isDecoratorNode(node))
    )
  }
  return (
    <div className="form-group editor" key={`editor-${name}`}>
      {label &&
        <Label htmlFor={name} className="formlabel">
          {label}
        </Label>
      }
      <LexicalComposer initialConfig={editorConfig}>
        <div className="form-control forminput editor-container">
          <ToolbarPlugin config={config} />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <ConnectedHTMLTransformerPlugin {...props} key={`editor-transform-${name}`} />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <HistoryPlugin externalHistoryState={historyState} />
            <TabIndentationPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <InlineImagePlugin />
            <AutoFocusPlugin />
            <ConnectedSnippetsPlugin {...props} key={`editor-snippets-${name}`} />
          </div>
        </div>
      </LexicalComposer>
    </div>
  )
}
