/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, fetchMany, notifyUser } from '../actions'
import { CONFIG, MINUSER, SETTINGS } from '../selectors'
import LeadCreatorSidebar from '../components/LeadCreatorSidebar'


function mapStateToProps(state) {
  return {
    user: MINUSER(state),
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache: state.get('cache'),
    config: CONFIG(state, 'leads'),
    settings: SETTINGS(state)
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    createModel,
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(LeadCreatorSidebar))
