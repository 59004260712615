/* eslint-disable new-cap */
import { getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { groupBy, valueFormat, hasPermission } from '../../utils'
import { SETTINGS } from '../../selectors'
import { Button } from '../ui/Button'
import FieldGroup from './forms/FieldGroup'
import Card from './Card'


const has_value = d => {
  let v = 0
  if (d.branch !== null & !d.role.includes('Branch')) {
    v += 1
  }
  if (d.team !== null & !d.role.includes('Team')) {
    v += 1
  }
  if (d.agent !== null & !d.role.includes('Agent')) {
    v += 1
  }
  return v === 0
}

const DealCommissionSlip = props => {
  const [ dealFranchise, setDealFranchise ] = useState()
  const { model, config, form, currency, actions, user } = props

  // eslint-disable-next-line no-unused-vars
  const [ canEdit, setCanEdit ] = useState(hasPermission([ 'deals_update' ], user.permissions))
  const isSeller = hasPermission([ 'deals_update_own' ], user.permissions) && (props.form.values.selling_agents.includes(user.agent.id) || props.form.values.agent === user.agent.id)
  // eslint-disable-next-line no-unused-vars
  const [ isSuper, setIsSuper ] = useState(hasPermission([ 'deals_update_super' ], user.permissions))
  // eslint-disable-next-line no-unused-vars
  const [ isDealFinal, setIsDealFinal ] = useState([ 'Final', 'Closed', 'Cancelled', 'Deleted' ].includes(props.form.values.status))

  const field = config.fields.find(f => f.name === 'commission.relationships')
  const value = getIn(form, `values.${field.name}`)
  const franchise_fee_percentage = getIn(form.values, 'commission.franchise_gross_commission')
  const selling_branch = getIn(form.values, 'branch')

  const groups = value ? groupBy(value, 'commission_type') : []
  const nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
  const external_deductions = getIn(groups, 'External Deductions', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
  const off_the_top_deductions = getIn(groups, 'Off the top Deductions', []).reduce((total, v) => {
    if (v.franchise && v.branch !== selling_branch) {
      return total
    }
    return total + ((nett_commission - external_deductions) * v.percentage / 100)
  }, 0.0)
  const referrals = getIn(groups, 'Internal Deductions', []).filter(has_value)
  const internal_deductions = referrals
    .filter(v => v.deduct_franchise_fee).reduce((total, t) => {
      const fees = t.deduct_franchise_fee ? (
        (parseFloat(t.nett_amount) || 0) * parseFloat(franchise_fee_percentage) / 100
      ) : 0
      return total + parseFloat(t.nett_amount || 0) - fees
    }, 0.0)

  const lead_fees = referrals
    .filter(v => !v.deduct_franchise_fee && v.branch && !v.agent)
    .reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
  const total_splits = (
    getIn(groups, 'Commission Split', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
  )
  const franchise_fees_internal = getIn(groups, 'Off the top Deductions', []).filter(v => v.franchise && v.franchise !== dealFranchise?.id).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
  const franchise_fees_splits = getIn(groups, 'Off the top Deductions', []).filter(v => v.franchise && v.franchise === dealFranchise?.id).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0) - franchise_fees_internal

  useEffect(() => {
    new Promise((resolve, reject) => props.actions.fetchMany({
      values: {
        modelname: 'franchises',
        select: true,
        params: {
          branches__in: props.form.values.branch,
          franchise_fee_payable: true
        }
      },
      resolve,
      reject
    })).then(r => {
      setDealFranchise(r.options[0])
    })
  }, [])

  return (
    <Card
      background={true}
      collapsable={false}
      header={
        <>
          <h3>Commission Slip</h3>
          <div className="details-section-buttons tablemeta">
            {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
              <div className="commission-save">
                <Button type="button" onClick={form.submitForm} className="btn btn-red btn-round">Save Deal</Button>
              </div>
            ) : null}
          </div>
        </>
      }
      bodyclass="no-top-padding"
      body={() => (
        <div className="grid-container grid-1">
          <div className="grid-container grid-3">
            <div className="commission-block primary-bg">
              <div>
                <strong>Total Commission (ex VAT):</strong><strong>{valueFormat('currency', nett_commission, { currency })}</strong>
              </div>
              <div>
                <span>External Fees:</span><span>{valueFormat('currency', -external_deductions, { currency })}</span>
              </div>
              <div>
                <strong>Group Commission:</strong><strong>{valueFormat('currency', nett_commission - external_deductions, { currency })}</strong>
              </div>
              <div>
                <span>Franchise Fees:</span><span>{valueFormat('currency', -off_the_top_deductions, { currency })}</span>
              </div>
              <div>
                <strong>Remaining Commission:</strong><strong>{valueFormat('currency', nett_commission - external_deductions - off_the_top_deductions, { currency })}</strong>
              </div>
            </div>
            <div className="commission-block">
              <div>
                <strong>Remaining Commission:</strong><strong>{valueFormat('currency', nett_commission - external_deductions - off_the_top_deductions, { currency })}</strong>
              </div>
              <div>
                <span>Selling Office:</span><span>{valueFormat('currency', total_splits, { currency })}</span>
              </div>
              <div>
                <span>Internal Referral:</span><span>{valueFormat('currency', internal_deductions, { currency })}</span>
              </div>
              <div>
                <span>Lead Fees:</span><span>{valueFormat('currency', lead_fees, { currency })}</span>
              </div>
            </div>
            <div className="commission-block">
              <div>
                <strong>Franchise Fees:</strong><strong>{valueFormat('currency', franchise_fees_splits + franchise_fees_internal, { currency })}</strong>
              </div>
              <div>
                <span>Selling Office:</span><span>{valueFormat('currency', franchise_fees_splits, { currency })}</span>
              </div>
              <div>
                <span>Internal Referral:</span><span>{valueFormat('currency', franchise_fees_internal, { currency })}</span>
              </div>
            </div>
          </div>
          <div className="grid-container grid-1">
            <FieldGroup
              groupname={'Commission Details'}
              fields={[
                field
              ]}
              key={`comm-${form.submitCount}`}
              form={form}
              card
              actions={actions}
              modelname={config.modelname}
              modelid={model.id}
              columns
              render={({ renderFieldGroup, group }) => (
                <div
                  className="editgroup"
                >
                  { renderFieldGroup(group) }
                </div>
              )}
            />
          </div>
        </div>
      )}
    />
  )
}

DealCommissionSlip.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  form: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  model: PropTypes.object,
  currency: PropTypes.string
}


const mapStateToProps = state => {
  const settings = SETTINGS(state)
  return ({
    currency: settings.get('default_currency')
  })
}

const DealCommission = connect(mapStateToProps, null)(withImmutablePropsToJS(DealCommissionSlip))

export default DealCommission
