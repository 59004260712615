import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React from 'react'

import WideSidebar from '../ui/sidebar/WideSidebar'
import notesconfig from '../../config/note.json'
import VacancyProSidebar from '../../containers/VacancyProSidebar'
import CreditCheckSidebar from '../../containers/CreditCheckSidebar'
import Card from './Card'
import DataTable from './datatable/DataTable'
import Meta from './datatable/Meta'
import ModelActions from './ModelActions'
import Search from './search/Search'
import NoteCreator from './NoteCreator'


class ModelList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      offset: 0
    }
  }

  componentDidMount() {
    this.props.actions.selectNone()
    window.scrollTo(0, 0)
    window.setTimeout(() => {
      this.props.actions.checkVersion()
    }, 3000)
  }

  componentDidUpdate() {
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().bottom
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  redirectSchema(schema) {
    this.setState({ redirect: schema })
  } // Fired on submit via the ContextMenu

  render() {
    const { config, configs, advanced, model, modelname, tableconfig,
      ui, user, actions, models, cache, selected } = this.props
    if ((!config || !model) && ui.awaited) { return null }
    // Fix the race condition when hot reloading
    if (!user.preferences[modelname]) { return null }
    return (
      <div className={this.props.className} id={this.props.id}>
        <div className="viewhead">
          <ModelActions
            actions={actions}
            ui={ui}
            redirectSchema={this.redirectSchema}
            modelname={modelname}
          />
        </div>
        <div className="view container-fluid">
          <div className="viewcontent">
            <div>
              <Card
                background={true}
                body={
                  <>
                    <Search
                      fetchMany={this.props.actions.fetchMany}
                      fetchLocations={actions.fetchLocations}
                      handleReset={this.props.handleReset}
                      {...this.props}
                      models={models}
                      ui={ui}
                      cache={cache}
                      modelname={config.modelname}
                      endpoint={config.endpoint}
                      config={config}
                      configs={configs}
                      limit={model.params.limit}
                      term={(model) ? model.params.term : ''}
                      toggleAdvanced={this.props.toggleAdvanced}
                      advanced={advanced}
                    />
                    <Meta
                      modelname={this.props.config.modelname}
                      toggleManager={this.props.actions.toggleManager}
                      model={model}
                      match={this.props.match}
                      config={this.props.config}
                      params={(this.props.model) ? this.props.model.params : null}
                      count={(this.props.model) ? this.props.model.count : 0}
                      previous={(this.props.model) ? this.props.model.previous : null}
                      next={(this.props.model) ? this.props.model.next : null}
                      advanced={this.props.advanced}
                      user={this.props.user}
                      cache={this.props.meta_cache}
                      fetchMany={this.props.actions.fetchMany}
                      findStatus={this.props.findStatus}
                      findDomain={this.props.findDomain}
                      selectedRecords={selected}
                    />
                  </>
                }
              />
              <Card
                background={true}
                body={
                  <div className="listbody">
                    <DataTable
                      actions={actions}
                      model={model}
                      cache={cache[config.modelname]}
                      currency={cache.settings[user.agent.site.id].default_currency}
                      region={cache.settings[user.agent.site.id].region}
                      portals={cache.portals}
                      match={this.props.match}
                      config={config}
                      tableconfig={tableconfig}
                      selected={selected}
                      resetPage={this.props.resetPage}
                      selectable={true}
                      meta={true}
                      user={{
                        permissions: user.permissions,
                        agent: {
                          id: user.agent.id,
                          branches: user.agent.branches,
                          site: user.agent.site
                        }
                      }}
                      domains={getIn(cache.settings[user.agent.site.id], 'meta.domains')}
                      redirectSchema={this.redirectSchema}
                      advanced={advanced}
                      meta_cache={this.props.meta_cache}
                      findStatus={this.props.findStatus}
                      findDomain={this.props.findDomain}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <WideSidebar sidebar="show-notes-sidebar">
          <NoteCreator
            associations={config.associations || []}
            actions={{
              toggleNoteCreator: () => { actions.toggleWideSidebar('show-notes-sidebar') },
              toggleWideSidebar: actions.toggleWideSidebar,
              createModel: actions.createModel,
              notifyUser: actions.notifyUser
            }}
            modelid={getIn(selected, '0')}
            match={this.props.match}
            modelconfig={config}
            config={notesconfig.config}
          />
        </WideSidebar>
        <WideSidebar sidebar="show-vacancy-sidebar">
          <VacancyProSidebar
            actions={{
              toggleVacancyPro: () => { actions.toggleWideSidebar('show-vacancy-sidebar') },
              toggleWideSidebar: actions.toggleWideSidebar
            }}
            modelconfig={config}
          />
        </WideSidebar>
        <WideSidebar sidebar="show-credit-check">
          <CreditCheckSidebar
            actions={{
              toggleCreditCheck: () => { actions.toggleWideSidebar('show-credit-check') },
              toggleWideSidebar: actions.toggleWideSidebar
            }}
            modelconfig={config}
          />
        </WideSidebar>
      </div>
    )
  }
}

ModelList.propTypes = {
  advanced: PropTypes.bool,
  cache: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  selected: PropTypes.array,
  tableconfig: PropTypes.object,
  config: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  log: PropTypes.string,
  configs: PropTypes.object,
  toggleAdvanced: PropTypes.func,
  findStatus: PropTypes.func,
  findDomain: PropTypes.func,
  handleReset: PropTypes.func,
  resetPage: PropTypes.func,
  actions: PropTypes.object,
  meta_cache: PropTypes.object,
  match: PropTypes.object,
  modelname: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
}

export default ModelList
