import { Component } from 'react'
import PropTypes from 'prop-types'


const isDOMReady = window && window.document && window.document.createElement

export default class ProductFruits extends Component {
  static propTypes = {
    projectCode: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }

  componentDidMount() {
    const {
      projectCode,
      language
    } = this.props

    if (!projectCode || !language || !isDOMReady) {
      // PF - dom is not ready
      // eslint-disable-next-line no-console
      console.info('PF - dom is not ready, projectCode is not set or language is not set')
      return
    }
    if (!window.productFruits) {
      this.setUserConfig(this.props);

      ((w, d, u, c) => {
        const a = d.getElementsByTagName('head')[0]
        const r = d.createElement('script'); r.async = 1
        r.src = `${u}?c=${c}`
        this.scriptElement = r
        a.appendChild(r)
      })(window, document, 'https://app.productfruits.com/static/script.js', projectCode)
    }

    if (window.productFruitsUnmounted && window.productFruitsInit) {
      window.productFruitsInit()

      delete window.productFruitsUnmounted
    }
  }

  componentDidUpdate() {
    if (!isDOMReady) {
      // eslint-disable-next-line no-console
      console.info('PF - dom is not ready')
      return
    }
    this.setUserConfig(this.props)
  }

  componentWillUnmount() {
    if (isDOMReady && window.productFruits && window.productFruits.services) {
      window.productFruits.services.destroy()
      document.body.style.cssText = ''

      delete window.productFruits
      delete window.productFruitsUser

      window.productFruitsUnmounted = true

      if (this.scriptElement) {
        this.scriptElement.remove()
      }
    }
  }

  setUserConfig(reactProps) {
    const {
      projectCode,
      language,
      username,
      email,
      firstname,
      lastname,
      signUpAt,
      role,
      props
    } = reactProps

    if (!window.productFruits || !window.productFruits.identifyUser) {
      window.productFruitsUser = {
        username,
        email,
        firstname,
        lastname,
        signUpAt,
        role,
        props
      }
    } else {
      window.productFruits.identifyUser({
        username,
        email,
        firstname,
        lastname,
        signUpAt,
        role,
        props
      })
    }

    window.productFruits = window.productFruits || {}

    const fireLanguageChangedEvent = window.productFruits.language && window.productFruits.language !== language

    window.productFruits.language = language
    window.productFruits.code = projectCode

    if (fireLanguageChangedEvent) {
      document.dispatchEvent(new CustomEvent('pf:language_changed'))
    }
  }

  render() {
    return false
  }
}
