import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'


const Tab = props => {
  const { className, active, tab, noScroll, children } = props

  const [ offset, setOffset ] = useState(0)

  useEffect(() => {
    const tab_el = document.querySelector('#content')
    if (tab_el) {
      const new_offset = tab_el.getBoundingClientRect().top + 66 + 66
      if (offset !== new_offset) {
        setOffset(new_offset)
      }
    }
  }, [ props ])

  return noScroll ? (
    <div className={`${className ? className : ''} tab-${tab} tab-content ${active ? ' tab-open' : ''}`}>
      {children}
    </div>
  ) : (
    <div className={`${className ? className : ''} tab-${tab} tab-content ${active ? ' tab-open' : ''}`}>
      <div className="stepper"></div>
      {children}
    </div>
  )
}

Tab.propTypes = {
  tab: PropTypes.string.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  noScroll: PropTypes.bool,
  permissions: PropTypes.array,
  user: PropTypes.object,
  children: PropTypes.any
}

export default Tab
