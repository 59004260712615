/* eslint-disable new-cap */

import { Map } from 'immutable'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import OnShowHistory from '../components/common/OnShowHistory'
import { CACHEDMODELID, SETTINGS } from '../selectors'


const mapStateToProps = (state, ownProps) => {
  const model = CACHEDMODELID(state, ownProps.modelname, ownProps.modelid)
  const settings = SETTINGS(state)
  return ({
    cache: model ? model : Map({}),
    currency: settings.get('default_currency')
  })
}

export default connect(mapStateToProps)(withImmutablePropsToJS(OnShowHistory))
