import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { NavLink } from 'react-router-dom'

import FocusTrap from './common/focus-trap/FocusTrap'
import { Button } from './ui/Button'


const GlobalAdd = ({ groups, siteid, setChatOpen }) => {
  const [ isOpen, setOpen ] = useState(false)
  const [ isDropdown, setDropdown ] = useState(false)


  const handleClose = e => {
    if (e) { e.stopPropagation() }
    setOpen(false)
  }

  const setVisibility = e => {
    e.stopPropagation()
    if (!isOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }
  const handleClick = e => {
    const inputEl = isDropdown ? isDropdown.querySelector('.forminput') : null
    setChatOpen(false)
    if (inputEl && inputEl.contains(e.target)) {
      return false
    }
    if (isDropdown) {
      handleClose(e)
    } else {
      setVisibility(e)
    }
    return true
  }

  const root = document.getElementById('header')
  return groups && Object.keys(groups).length ? (
    <div className="global-add" onClick={handleClick}>
      <div className="avatar avatar-md">
        <div className="thumbimg initials">
          <svg viewBox="0 0 32 32">
            <use href="/images/icons-16.svg#icon16-Plus" />
          </svg>
        </div>
      </div>
      {isOpen &&
        ReactDOM.createPortal(<FocusTrap
          forwardRef={el => setDropdown(el)}
          onExit={handleClose}
          className="user-menu-cover"
          dialog={true}
        >
          <div id="global-add-menu" className="user-menu" style={{ maxHeight: `calc(100vh - ${root.getBoundingClientRect().bottom + 20}px)` }}>
            {Object.keys(groups).map(group => (
              <div className="menu-group" key={`global-add-${group}`}>
                <span className="label">{group}</span>
                {groups[group].map(item => (
                  <Button component={NavLink} icon="#icon16-Plus" to={`/secure/${siteid}/${item.modelname}/add`} className="action navitem profile btn btn-text btn-icon-16" key={`global-add-${group}-${item.modelname}`}>
                    <span className="label">{item.singular}</span>
                  </Button>
                ))}
              </div>
            ))}
          </div>
        </FocusTrap>, root)
      }
    </div>
  ) : null
}


GlobalAdd.propTypes = {
  groups: PropTypes.object,
  siteid: PropTypes.number,
  setChatOpen: PropTypes.func
}

export default GlobalAdd
