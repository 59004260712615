import * as yup from 'yup'
import messages from '../../messages.json'

import { contact } from '../../contacts'


const ae_contact = yup.object({
  last_name: yup.string().trim().max(50).required(messages.required),
  cell_number: yup.string().max(20).matches(/^[.\d() +-]+$/, { message: messages.phone }).required(messages.required)
})

export const contacts = props => contact(props).concat(ae_contact)
