import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

import { Button } from '../../../ui/Button'


const RadioInput = ({
  field: { name }, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  ...props
}) => (
  <div className="radioinput form-group">
    <label className="radiocontainer">{label}
      <input
        id={props.id ? props.id : name}
        name={name}
        value={props.value}
        checked={form.values[name] === props.value}
        onChange={props.handleChange || form.handleChange}
        type="radio"
      />
      <Button component={'span'} className={`radio${form.values[name] === props.value ? ' checked' : ''}`} />
    </label>
    <ErrorMessage component="div" className="error" name={name} />
  </div>
)

RadioInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string
}

export default RadioInput
