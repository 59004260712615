import * as yup from 'yup'
import messages from './messages.json'


export const subscribers = () => yup.object({
  status: yup.string().oneOf([ 'Active', 'Inactive', 'Non-Compliant', 'No-Consent', null ], messages.invalid).required(messages.required).nullable(),
  contact: yup.number().integer().min(1).required(messages.required),
  user_type: yup.array().of(yup.string().oneOf([
    'Buyer',
    'Seller',
    'Tenant',
    'Landlord',
    'Property Owner'
  ], messages.invalid)).nullable(),
  agent: yup.number().integer().positive().nullable(),
  source: yup.string().typeError('Please select a source').required(messages.required).min(1).max(75),
  branch: yup.number().integer().positive().nullable(),
  source_ref: yup.string().max(100).nullable()
})
