import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'

import OnShowHistory from '../../../../containers/OnShowHistory'


const OnShowHistoryButton = props => {
  const [ open, setOpen ] = useState(false)
  const { modelname, modelid, form } = props
  return (
    <Fragment>
      <div>
        <button
          type="button"
          className="btn btn-none link on-show"
          onClick={() => { if (open) { setOpen(false) } else { setOpen(true)}} }
        >
          {form.values.on_show_history || !open ? 'View' : 'Hide'} History
        </button>
      </div>
      <div className="onshowhistory">
        {open ? (
          <OnShowHistory
            form={form}
            modelname={modelname}
            modelid={modelid}
            portal
          />
        ) : null }
      </div>
    </Fragment>
  )
}

OnShowHistoryButton.propTypes = {
  modelid: PropTypes.number,
  modelname: PropTypes.string,
  form: PropTypes.object
}

export default OnShowHistoryButton
